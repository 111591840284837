export const CONNECTIONS_TABS = {
	connected: {
		id: "connected",
		value: "Connected"
	},
	apps: {
		id: "apps",
		value: "Apps"
	}
};
