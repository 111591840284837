import { toast } from "react-toastify";

import "../styles/css/common.css";

const ToastService = {
	/**
	 * Presents an information Toast
	 *
	 * @param {String} content
	 */
	info(content, className = "mb-toast") {
		return toast.info(content, {
			position: "bottom-center",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			draggablePercent: 0,
			className
		});
	},

	/**
	 * Update a toast
	 *
	 * @param {Number} toastId
	 * @param {String} content
	 */
	update(toastId, content) {
		toast.update(toastId, {
			render: content
		});
	},

	/**
	 * Dismisses a Toast message using a toast id provided by react-toastify
	 *
	 * @param {String} toastId
	 */
	dismiss(toastId) {
		toast.dismiss(toastId);
	},

	/*
	 * Presents an error Toast
	 * TODO: We are currently using the same styling as the info, we need to update this
	 * @param {String} content
	 */
	error(content, className = "mb-toast mb-toast--error", autoCloseTime = 5000) {
		return toast.error(content, {
			position: "bottom-center",
			autoClose: autoCloseTime,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className
		});
	}
};

export default ToastService;
