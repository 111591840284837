import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import { PoseGroup } from "react-pose";
import moment from "moment";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import NotificationService from "../../services/NotificationService";
import VersionService from "../../services/VersionService";
import PollingService from "../../services/PollingService";
import SupportChatService from "../../services/SupportChatService";
import ToastService from "../../services/ToastService";

import ReferralForm from "./ReferralForm";
import Alert from "./Alert";
import Modal from "./DHModal";

import { CONNECTION_STATUS } from "../../constants/CommonConstants";
import { ContextMenu } from "../../constants/Messenger";

import "../../styles/css/components/commons/user-profile.css";

class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showList: false,
			showReferralModal: false,
			showLogOutModal: false,
			connectionStatus: CONNECTION_STATUS.reconnecting.id,
			isAvailable: UserService.get().is_available
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async componentDidMount() {
		NotificationService.subscribeOnce("socketioStatusUpdate", "userProfileComponent", async connectionStatus => {
			await this.update({
				connectionStatus
			});
		});
		document.addEventListener("mousedown", this.onMouseDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onMouseDown, false);
	}

	onMouseDown = async e => {
		let { showList } = this.state;

		if (this.dropdown && this.dropdown.contains && this.dropdown.contains(e.target)) {
			return;
		}

		if (this.contextContainer && this.contextContainer.contains && this.contextContainer.contains(e.target) && !showList) {
			await this.update({
				showList: true
			});
		} else if (this.dropdown && this.dropdown.contains) {
			await this.update({
				showList: false
			});
		}
	};

	async onSelect(action, hideList = true) {
		await this.update({ showList: !hideList });

		if (typeof action === "function") {
			action();
		}
	}

	Option = ({ icon, title, action, hideList = true }) => {
		let DIcon = icon;
		return (
			<div className="user-profile__options__item" onClick={() => this.onSelect(action, hideList)}>
				<div className="user-profile__options__item__icon">
					<DIcon size={18} />
				</div>
				<div className="user-profile__options__item__title">{title}</div>
			</div>
		);
	};

	onEditProfile = () => {
		this.props.history.push("/user-profile");
	};

	onReferAFriend = async () => {
		await this.update({
			showReferralModal: true
		});
	};

	renderReferralModal() {
		let { showReferralModal } = this.state;
		let { t } = this.props;

		return (
			<Modal show={showReferralModal} onHide={async () => await this.update({ showReferralModal: false })} title={t("Referral Details")}>
				<ReferralForm handleOnCancel={async () => await this.update({ showReferralModal: false })} />
			</Modal>
		);
	}

	onSignOut = () => {
		UserService.clear();
		PollingService.clearAllListeners();
		this.props.history.push("/login");
	};

	renderSignOutModal() {
		let { showLogOutModal } = this.state;
		let { t } = this.props;

		return (
			<Alert
				type="warning"
				show={showLogOutModal}
				title={t("Are you sure?")}
				confirm={t("Yes")}
				cancel={t("No")}
				onClose={async confirmed => {
					await this.update({ showLogOutModal: false });
					if (confirmed) {
						this.onSignOut();
					}
				}}
			>
				<div>{t("Are you sure you would like to sign out?")}</div>
			</Alert>
		);
	}

	onShowSignOutModal = () => {
		this.update({
			showLogOutModal: true
		});
	};

	onSupport = prePopulatedContent => {
		SupportChatService.showNewMessage(prePopulatedContent);
	};

	toggleAvailability = async () => {
		let { isAvailable } = this.state;
		let { t } = this.props;

		let newStatus = !isAvailable;

		await this.update({
			isAvailable: newStatus
		});

		let success = await UserService.setAvailability(newStatus);

		if (!success) {
			ToastService.info(t(`An error occurred trying to update your status.`));
			await this.update({
				isAvailable: !newStatus
			});
			return;
		}

		ToastService.info(`${t("New Status")}: ${newStatus ? t("Available") : t("Away")}`);
	};

	onAskForNotificationPermission = () => {
		let { t } = this.props;

		NotificationService.askNotificationPermission();
		ToastService.error(t("You may need to manually enable notifications in your browser page settings."));
	};

	renderProfilePic() {
		let user = UserService.get();

		if (!user) {
			return null;
		}

		let profilePictureUrl = user.profile_picture_url || "";

		let userFirstNameInitial = user.first_name ? user.first_name[0] : "";
		let userLastNameInitial = user.last_name ? user.last_name[0] : "";
		let userInitials = userFirstNameInitial + userLastNameInitial;

		return (
			<>
				{profilePictureUrl ? (
					<img alt="" src={profilePictureUrl} className="user-profile__img" />
				) : (
					<span className="user-profile__initials">{userInitials}</span>
				)}
				{this.renderStatus(true)}
			</>
		);
	}

	renderStatus(isIndicatorOnly = false) {
		let { isAvailable } = this.state;
		let { t } = this.props;

		let styles = ["user-profile__options__status__indicator"];

		if (isAvailable) {
			styles.push("user-profile__options__status__indicator--available");
		} else {
			styles.push("user-profile__options__status__indicator--away");
		}

		if (isIndicatorOnly) {
			styles.push("user-profile__options__status__indicator--float");

			return <div className={styles.join(" ")} />;
		} else {
			styles.push("user-profile__options__status__indicator--inline");
		}

		return (
			<div className="user-profile__options__status">
				<div className={styles.join(" ")} />
				<div>{isAvailable ? t("Available") : t("Away")}</div>
			</div>
		);
	}

	render() {
		let { showList, connectionStatus, isAvailable } = this.state;
		let { t } = this.props;

		var fullName = UserService.getCurrentUserFullName();

		let isSuperAdminOrCustomerSuccess = UserService.isSuperAdminOrCustomerSuccess();
		let userId = UserService.get().id;

		let hasNotificationPermission = NotificationService.hasNotificationPermission();

		return (
			<>
				<div ref={ref => (this.contextContainer = ref)} className="user-profile">
					{this.renderProfilePic()}
				</div>
				<PoseGroup>
					{showList && (
						<ContextMenu key="container" ref={ref => (this.dropdown = ref)} className="user-profile__options">
							<div className="user-profile__options__name">
								{fullName}
								{isSuperAdminOrCustomerSuccess ? ` (${t("ID")}: ${userId})` : ""}
							</div>
							{this.renderStatus()}
							<div className="user-profile__options__divider" />

							<this.Option
								icon={isAvailable ? Icon.ToggleRight : Icon.ToggleLeft}
								title={isAvailable ? t("Set yourself as away") : t("Set yourself as available")}
								action={this.toggleAvailability}
								hideList={false}
							/>
							<this.Option icon={Icon.User} title={t("Edit Profile")} action={this.onEditProfile} />
							<this.Option icon={Icon.Share} title={t("Refer a friend")} action={this.onReferAFriend} />
							{/* <this.Option Icon={Icon.Star} title="What's New" action={this.onWhatsNew} /> */}
							{!hasNotificationPermission && <this.Option icon={Icon.BellOff} title={t("Enable Notifications")} action={this.onAskForNotificationPermission} />}
							<this.Option icon={Icon.HelpCircle} title={t("Chat with Support")} action={() => this.onSupport()} />
							<this.Option icon={Icon.PenTool} title={t("Suggest a Feature")} action={() => this.onSupport(t("Hi! I have a cool idea. Suggested feature: "))} />
							<this.Option icon={Icon.LogOut} title={t("Sign Out")} action={this.onShowSignOutModal} />

							<div className="user-profile__options__divider" />

							<div className="user-profile__options__copyright">
								<div>DemandHub {VersionService.getLocalVersionName()}</div>
								<div>
									{t("Copyright")} &copy; {moment().format("YYYY")} DemandHub Inc.
								</div>
							</div>
							<div className="user-profile__options__connection">
								<div className={`user-profile__options__connection__indicator user-profile__options__connection__indicator--${connectionStatus}`} />
								<div>{CONNECTION_STATUS[connectionStatus].display}</div>
							</div>
						</ContextMenu>
					)}
				</PoseGroup>
				{this.renderReferralModal()}
				{this.renderSignOutModal()}
			</>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(UserProfile));
