import { EMOJI_POSITIONS } from "../constants/Emojis";

let globalEmojiSelector = null;

let EMOJI_SELECT_HEIGHT = 450;
let EMOJI_SELECT_WIDTH = 374;

const EmojiService = {
	/**
	 * Stores a reference to the emoji selector we will use in this service
	 *
	 * @param {Component} globalEmojiSelectorRef
	 */
	storeReference(globalEmojiSelectorRef) {
		globalEmojiSelector = globalEmojiSelectorRef;
	},

	/**
	 * Opens an global emoji selector
	 *
	 * @param {Component} positionBeside
	 * @param {String} position
	 * @param {Function} onEmojiSelect
	 * @returns
	 */
	openEmojiSelector({ positionBeside, position = EMOJI_POSITIONS.left, onEmojiSelect }) {
		if (!globalEmojiSelector) {
			return;
		}

		this.positionBeside(positionBeside, position);
		this.onEmojiSelect(onEmojiSelect);

		globalEmojiSelector.forceShow();
	},

	/**
	 * Sets the action to perform when an emoji is selected
	 *
	 * @param {Function} onSelectEmojiCallback
	 * @returns
	 */
	onEmojiSelect(onSelectEmojiCallback) {
		globalEmojiSelector.setReactionSelectCallback(onSelectEmojiCallback);
	},

	/**
	 * Positions the emoji selector next to a target component
	 *
	 * @param {Component} targetElement
	 * @param {String} position
	 * @returns
	 */
	positionBeside(targetElement, position = EMOJI_POSITIONS.left) {
		if (!globalEmojiSelector) {
			return;
		}

		// Get x and y coordinates of the target element
		let { x, y, width } = targetElement.getBoundingClientRect();

		// Get edges of the screen as coordinates
		let windowRight = document.body.clientWidth;
		let windowBottom = document.body.clientHeight;

		// We will use some of margin between the emoji selector and the element
		let marginFromElement = 20;

		// We will use some margin from the screen if we accidentally go over
		let marginFromScreen = 20;

		// Center emoji selector verticaly with this element
		let top = y - Math.floor(EMOJI_SELECT_HEIGHT / 2);

		// Prevent going off screen at the top
		if (top < 0) {
			top = marginFromScreen;
		}

		// Prevent going off screen at the bottom
		if (top + EMOJI_SELECT_HEIGHT > windowBottom) {
			top = windowBottom - EMOJI_SELECT_HEIGHT - marginFromScreen;
		}

		let left = 0;
		if (position === EMOJI_POSITIONS.left) {
			left = x - EMOJI_SELECT_WIDTH - marginFromElement;
		} else {
			// If we want to position to the right of the target element
			left = x + width + marginFromElement;
		}

		// Prevent going off screen at the left
		if (left < 0) {
			left = marginFromScreen;
		}

		// Prevent going off screen at the right
		if (left + EMOJI_SELECT_WIDTH > windowRight) {
			left = windowRight - EMOJI_SELECT_WIDTH - marginFromScreen;
		}

		globalEmojiSelector.setPosition({ top, left });
	}
};

export default EmojiService;
