import React from "react";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import WorkflowService from "../../../../services/WorkflowService";
import UserService from "../../../../services/UserService";

import SearchInput from "../../../../components/common/SearchInput";

import { KEYS } from "../../../../constants/Messenger";
import { STATUS } from "../../../../constants/CommonConstants";

import "./workflow-recipes-modal.css";

class WorkflowRecipesModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			workflowRecipes: [],
			searchTerm: ""
		};

		this.workflowRecipesModal = null;
		this.searchInput = null;
	}

	componentDidMount() {
		document.addEventListener("keydown", this.onKey);
		document.addEventListener("mousedown", this.handleClick, false);
		this.resetComponent();
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKey);
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.fetchData();
	};

	fetchData = async () => {
		let { searchTerm } = this.state;

		let workflowRecipes = await WorkflowService.fetchWorkflowRecipes({ companyId: UserService.getActiveCompany().id, searchTerm, status: [STATUS.active] });

		await this.update({ workflowRecipes, searchTerm });
	};

	handleClick = event => {
		if (this.workflowRecipesModal && this.workflowRecipesModal.contains(event.target)) {
			// the click is in this component
			return;
		}

		this.onClose();
	};
	onKey = e => {
		if (e.keyCode === KEYS.esc) {
			e.preventDefault();
			this.onClose();
		}
	};

	onClose = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});

		await this.fetchData();
	};

	render = () => {
		const { show, onSelect, t } = this.props;
		const { workflowRecipes } = this.state;

		return (
			<>
				<div ref={ref => (this.workflowRecipesModal = ref)} className={`wfm ${show ? "wfm--visible" : "wfm--hidden"}`} pose={show ? "visible" : "hidden"}>
					<div className="wfm__header">
						<div className="wfm__header__search">
							<SearchInput
								id="wfm__header__search-input"
								ref={input => {
									this.searchInput = input;
								}}
								placeholder={t("Search recipes...")}
								onChange={this.onSearchChange}
							/>
						</div>
						<div className="wfm__header__x">
							<Icon.X size="18" onClick={this.onClose} />
						</div>
					</div>
					{workflowRecipes &&
						workflowRecipes.map(workflowRecipe => (
							<div key={workflowRecipe.id} className="wfm__item" onClick={() => onSelect(workflowRecipe.workflows)}>
								<div className="wfm__item__icon">{workflowRecipe.company_id ? <Icon.Briefcase size={16} /> : <Icon.Globe size={16} />}</div>
								<div className="wfm__item__content">
									<div className="wfm__item__content__name">{workflowRecipe.name}</div>
									<div className="wfm__item__content__description">{workflowRecipe.description ? workflowRecipe.description : ""}</div>
								</div>
							</div>
						))}
					{(!workflowRecipes || workflowRecipes.length === 0) && (
						<div className="wfm__item wfm__item--none">
							<div className="wfm__item__name">{t("No Workflow Recipes found.")}</div>
						</div>
					)}
				</div>
			</>
		);
	};
}

export default withTranslation(null, { withRef: true })(WorkflowRecipesModal);
