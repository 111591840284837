import React, { Component } from "react";

import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";

import ReviewSitePerformance from "./ReviewSitePerformance";
import ReviewSitesResults from "./ReviewSitesResults";
import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";

import "../../../App.css";
import "../../../styles/css/scenes/analytics.css";

class ReviewSitesAnalytics extends Component {
	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	render() {
		const location = UserService.getActiveLocation();

		return (
			<Page>
				<Header
					title={
						<>
							Analytics<span className="hidden-xxs"> - {location.name}</span>
						</>
					}
				/>
				<br />
				<ReviewSitePerformance isCard={false}></ReviewSitePerformance>
				<br />
				<ReviewSitesResults isCard={false}></ReviewSitesResults>
			</Page>
		);
	}
}

export default ReviewSitesAnalytics;
