import Kichiri from "./KichiriService";
import openSocket from "socket.io-client";
import AppConfig from "../config/app/web-app.config.js";
import NotificationService from "./NotificationService.js";
import UserService from "./UserService";

import { CONNECTION_STATUS } from "../constants/CommonConstants";

const WebsocketsConnection = {
	isConnected: false,
	socket: null,

	connect(connectCallback) {
		this.disconnect();

		console.log("Connecting to Socket.IO Server ...");

		this.socket = openSocket(AppConfig.API_SERVER, {
			reconnection: true,
			transports: ["websocket", "polling"]
		});

		this.socket.on("connect", () => {
			console.log("Successfully connected to Socket.IO Server!");

			this.isConnected = true;
			if (connectCallback) {
				connectCallback();
			}

			NotificationService.publish("socketioStatusUpdate", CONNECTION_STATUS.connected.id);
		});

		this.socket.on("disconnect", () => {
			this.isConnected = false;

			NotificationService.publish("socketioStatusUpdate", CONNECTION_STATUS.reconnecting.id);
		});

		return this.socket;
	},

	disconnect() {
		if (this.socket) {
			this.socket.destroy();
			delete this.socket;
			this.socket = null;
		}
	},

	markDesktopNotificationAsReceived(desktopNotificationPublicId) {
		if (!this.socket) {
			return;
		}

		this.socket.emit("desktopReceiveEvent", desktopNotificationPublicId);
	},

	onMessageMarkedRead(cb) {
		if (!this.socket) {
			return;
		}

		this.socket.off("message_marked_read").on("message_marked_read", message => cb(message));
	},

	onMessageMarkedUnread(cb) {
		if (!this.socket) {
			return;
		}

		this.socket.off("messages_marked_unread").on("messages_marked_unread", payload => cb(payload));
	},

	onTypingEvent(cb) {
		if (!this.socket) {
			return;
		}

		this.socket.off("typing_event").on("typing_event", message => cb(message));
	},

	markMessageRead(message) {
		if (!this.socket) {
			return;
		}
		// If we don't belong to the company, don't mark any messages as read
		if (UserService.isSuperOrCsVisitingAnotherCompany()) {
			return;
		}

		this.socket.emit("markMessageRead", message);
	},

	markInternalConversationRead(conversationId, userId) {
		if (!this.socket) {
			return;
		}

		this.socket.emit("markInternalConversationRead", conversationId, userId);
	},

	onInternalConversationRead(cb) {
		if (!this.socket) {
			return;
		}

		this.socket.off("internal_message_marked_read").on("internal_message_marked_read", message => cb(message));
	},

	onInternalMessageUpdate(cb) {
		if (!this.socket) {
			return;
		}

		this.socket.off("internal_message_updated").on("internal_message_updated", message => cb(message));
	},

	onInternalMessageUserRemoved(cb) {
		if (!this.socket) {
			return;
		}

		this.socket.off("internal_message_user_removed").on("internal_message_user_removed", message => cb(message));
	},

	sendTypingEvent(typingEvent) {
		if (!this.socket) {
			return;
		}

		this.socket.emit("typingEvent", typingEvent);
	},

	subscribeToNewMessages(locationId, authToken, cb) {
		console.log("Subscribing to Customer Chat messages ...");
		if (this.socket) {
			this.socket.off("new_message").on("new_message", message => cb(null, message));
			this.socket.emit("subscribeToNewMessages", locationId, authToken);

			console.log("Succesfully subscribed to Customer Chat messages!");
		}
	},

	subscribeToInternalMessages(userId, cb) {
		console.log("Subscribing to Team Chat messages ...");

		if (!this.socket) {
			return;
		}

		this.socket.off("new_internal_message").on("new_internal_message", message => cb(null, message));
		this.socket.emit("subscribeToInternalMessages", userId);

		console.log("Succesfully subscribed to Team Chat messages!");
	},

	unsubscribeToNewMessages(locationId, authToken, cb) {
		if (this.socket) {
			console.log("Unsubscribing from new messages.");

			this.socket.off("new_message");
			this.socket.emit("unsubscribeToNewMessages", locationId, authToken);
		}
	},

	subscribeToMessageCount(authToken, locationId, cb) {
		if (this.socket) {
			this.socket.off("message_count").on("message_count", messageCount => cb(null, messageCount));
			this.socket.emit("subscribeToMessageCount", authToken, locationId, 5000);
		}
	},

	unsubscribeToMessageCount(locationId, authToken) {
		if (this.socket) {
			this.socket.off("message_count");
			this.socket.emit("unsubscribeToMessageCount", locationId, authToken);
		}
	},

	subscribeToContactCount(locationId, userId, cb) {
		if (!this.socket) {
			return;
		}

		this.socket.off("contact_count").on("contact_count", contactCount => cb(null, contactCount));
		this.socket.emit("subscribeToContactCount", locationId, userId);
	},

	unsubscribeToContactCount() {
		if (!this.socket) {
			return;
		}
		this.socket.off("contact_count");
		this.socket.emit("unsubscribeToContactCount");
	},

	sendMessageUpdated(messageId, reactions) {
		if (!this.socket) {
			return;
		}

		this.socket.emit("messageUpdated", messageId, reactions);
	},

	/**
	 * Fetches all the active Realtime Connections store in memory a target API server
	 *
	 * @param {Number} companyId
	 * @returns {Array}
	 */
	async getConnectionsForCompany({ companyId }) {
		try {
			let response = await Kichiri.admin.fetchConnections({}, { companyId }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
	}
};

export default WebsocketsConnection;
