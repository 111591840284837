import moment from "moment";
import UserService from "./UserService";
import Kichiri from "./KichiriService";

export const InviteService = {
	/**
	 * Fetch review requests
	 *
	 * @param {String} searchTerm
	 * @param {String} status
	 * @param {Boolean} remindersOnly
	 * @param {Boolean} remindableOnly
	 * @param {String} unknownNames
	 * @param {String} sendAfterStartDate
	 * @param {String} sendAfterEndDate
	 * @param {String} sortOrder
	 * @param {String} sortField
	 * @param {Integer} limit
	 * @param {Integer} offset
	 * @param {String} version
	 *
	 * @returns {Array}
	 */
	async fetch({
		searchTerm,
		status,
		remindersOnly,
		remindableOnly,
		unknownNames,
		sendAfterStartDate,
		sendAfterEndDate,
		sortOrder,
		sortField,
		limit,
		offset,
		version
	}) {
		try {
			const response = await Kichiri.review_request.fetch(
				{},
				{
					locationId: UserService.getActiveLocation().id,
					status,
					searchTerm,
					remindersOnly,
					remindableOnly,
					unknownNames,
					sendAfterStartDate,
					sendAfterEndDate,
					sortOrder,
					sortField,
					limit,
					offset,
					version
				},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Cancels a review request
	 *
	 * @param {Integer} reviewRequestId
	 * @param {Boolean} cancelAutoReminder
	 * @param {Boolean} setToAwaitingAction
	 */
	async cancelReviewRequest({ reviewRequestId, cancelAutoReminder, setToAwaitingAction }) {
		try {
			let response = await Kichiri.review_request.cancel({ id: reviewRequestId, cancelAutoReminder, setToAwaitingAction }, {}, UserService.getAuthToken());
			return response;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Cancels a review request
	 *
	 * @param {Integer} reviewRequestId
	 */
	async uncancelReviewRequest({ reviewRequestId }) {
		try {
			let response = await Kichiri.review_request.uncancel({ id: reviewRequestId }, {}, UserService.getAuthToken());
			return response;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Send a review request
	 *
	 * @param {Integer} reviewRequestId
	 */
	async sendReviewRequest({ reviewRequestId }) {
		try {
			let response = await Kichiri.review_request.uncancel({ id: reviewRequestId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Count review requests
	 *
	 * @param {String} searchTerm
	 * @param {String} status
	 * @param {Boolean} remindersOnly
	 * @param {Boolean} remindableOnly
	 * @param {String} unknownNames
	 * @param {String} sendAfterStartDate
	 * @param {String} sendAfterEndDate
	 * @param {String} sortOrder
	 * @param {String} sortField
	 * @param {Integer} limit
	 * @param {Integer} offset
	 * @param {String} version
	 *
	 * @returns {Integer}
	 */
	async countReviewRequests({ searchTerm, status, remindersOnly, remindableOnly, unknownNames, sendAfterStartDate, sendAfterEndDate, version }) {
		try {
			const response = await Kichiri.review_request.count(
				{},
				{
					locationId: UserService.getActiveLocation().id,
					searchTerm,
					status,
					remindersOnly,
					remindableOnly,
					unknownNames,
					sendAfterStartDate,
					sendAfterEndDate,
					version
				},
				UserService.getAuthToken()
			);

			return response.data.count;
		} catch (error) {
			console.log(error);
		}

		return 0;
	},

	/**
	 * Follow up Review Request
	 *
	 * @param {Boolean} name
	 * @param {Boolean} parent
	 * @param {Boolean} medium
	 * @param {Boolean} phoneOrEmail
	 * @param {Boolean} templateId
	 *
	 */
	async followUp({ name, parent, medium, phoneOrEmail, templateId }) {
		try {
			const response = await Kichiri.review_request.followup(
				{ location: UserService.getActiveLocation().id, name: name, parent: parent, [medium]: phoneOrEmail, template: templateId },
				{},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
			throw error;
		}
	}
};

export default InviteService;
