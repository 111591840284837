import React, { Component } from "react";

import "../../styles/css/components/commons/filters.css";

class NewFilters extends Component {
	render() {
		let { onSelect, selected, children, title } = this.props;

		const childrenWithProps = React.Children.map(children, child => {
			if (React.isValidElement(child)) {
				return React.cloneElement(child, { onSelect, selected });
			}
			return child;
		});

		return (
			<div className="dh-filters">
				{title && title.length > 0 && <div className="dh-filters__title">{title}</div>}
				<div className="dh-filters__items">{childrenWithProps}</div>
			</div>
		);
	}
}

export default NewFilters;
