import React from "react";
import * as Icon from "react-feather";

import Action from "../../../../components/common/Action";

import "./teamchat-see-display.css";

class TeamChatSeeDisplay extends React.PureComponent {
	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	render = () => {
		let { show, onClick, textLabel, linkLabel, color } = this.props;

		if (!show) {
			return null;
		}

		let additionalStyles = {};

		if (color) {
			additionalStyles.backgroundColor = color;
		}

		return (
			<div className="mb-thread-list__tcsd" style={additionalStyles}>
				<div className="mb-thread-list__tcsd-text">{textLabel}</div>
				<div onClick={onClick} className="mb-thread-list__tcsd__see-all">
					{linkLabel}
				</div>
				<Action id="close" label="Close" icon={Icon.X} onClick={event => onClick(event, true)} />
			</div>
		);
	};
}

export default TeamChatSeeDisplay;
