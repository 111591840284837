import posed from "react-pose";
import * as Icon from "react-feather";

export const ContextMenu = posed.div({
	enter: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 200
		}
	},
	exit: {
		y: 10,
		x: 0,
		opacity: 0,
		transition: {
			duration: 100
		}
	}
});

export const STATE = {
	open: "active",
	closed: "inactive",
	deleted: "deleted"
};

export const MEDIUM = {
	sms: {
		key: "sms",
		value: "SMS",
		icon: Icon.MessageCircle
	},
	email: {
		key: "email",
		value: "Email",
		icon: Icon.Mail
	},
	facebook: {
		key: "facebook",
		value: "Facebook",
		icon: Icon.Facebook
	},
	instagram: {
		key: "instagram",
		value: "Instagram",
		icon: Icon.Instagram
	},
	google: {
		key: "google",
		value: "Google",
		icon: Icon.Globe
	},
	web: {
		key: "web",
		value: "Web Chat",
		icon: Icon.MessageCircle
	},
	demandhub: {
		key: "demandhub",
		value: "DemandHub TeamChat",
		icon: Icon.MessageCircle
	},
	secure: {
		key: "secure",
		value: "Secure Chat",
		icon: Icon.Lock
	},
	unknown: {
		key: "unknown",
		value: "Unknown Medium",
		icon: Icon.Box
	}
};

export const SWAPPABLE_MEDIUMS = {
	sms: "sms",
	email: "email"
};

export const MODE = {
	customer: "customer",
	team: "team"
};

export const CONVERSATION = {
	channel: "channel",
	dm: "dm"
};

export const CHANNEL_TYPE = {
	general: {
		id: "general",
		label: "General"
	},
	broadcast: {
		id: "broadcast",
		label: "Broadcast"
	}
};

export const CUSTOMER_FILTER = {
	all: "all",
	unassigned: "unassigned",
	assigned_to_me: "assigned_to_me",
	// These are needed for the "All" section
	assigned_to_me_and_unassigned: "assigned_to_me_and_unassigned",
	assigned_to_me_and_others: "assigned_to_me_and_others",
	assigned_to_others_and_unassigned: "assigned_to_others_and_unassigned"
};

export const MEDIA_TYPES_CONVERSION = {
	jpg: "image",
	jpeg: "image",
	png: "image",
	gif: "image",
	webp: "image"
};

export const MEDIA_TYPES = {
	image: "image",
	video: "video",
	file: "file",
	audio: "audio"
};

export const MEDIA_ICON = {
	image: "📷",
	video: "📹",
	file: "📎",
	audio: "🔊"
};

export const MEDIA_FILE_TYPES = {
	video: [
		"3g2",
		"3gp",
		"aaf",
		"asf",
		"avchd",
		"avi",
		"drc",
		"flv",
		"m2v",
		"m4p",
		"m4v",
		"mkv",
		"mng",
		"mov",
		"mp2",
		"mp4",
		"mpe",
		"mpeg",
		"mpg",
		"mpv",
		"mxf",
		"nsv",
		"ogg",
		"ogv",
		"qt",
		"rm",
		"rmvb",
		"roq",
		"svi",
		"vob",
		"webm",
		"wmv",
		"yuv"
	],
	image: [
		"ase",
		"art",
		"bmp",
		"blp",
		"cd5",
		"cit",
		"cpt",
		"cr2",
		"cut",
		"dds",
		"dib",
		"djvu",
		"egt",
		"exif",
		"gif",
		"gpl",
		"grf",
		"icns",
		"ico",
		"iff",
		"jng",
		"jpeg",
		"jpg",
		"jfif",
		"jp2",
		"jps",
		"lbm",
		"max",
		"miff",
		"mng",
		"msp",
		"nitf",
		"ota",
		"pbm",
		"pc1",
		"pc2",
		"pc3",
		"pcf",
		"pcx",
		"pdn",
		"pgm",
		"PI1",
		"PI2",
		"PI3",
		"pict",
		"pct",
		"pnm",
		"pns",
		"ppm",
		"psb",
		"psd",
		"pdd",
		"psp",
		"px",
		"pxm",
		"pxr",
		"qfx",
		"raw",
		"rle",
		"sct",
		"sgi",
		"rgb",
		"int",
		"bw",
		"tga",
		"tiff",
		"tif",
		"vtf",
		"xbm",
		"xcf",
		"xpm",
		"3dv",
		"amf",
		"ai",
		"awg",
		"cgm",
		"cdr",
		"cmx",
		"dxf",
		"e2d",
		"egt",
		"eps",
		"fs",
		"gbr",
		"odg",
		"svg",
		"stl",
		"vrml",
		"x3d",
		"sxd",
		"v2d",
		"vnd",
		"wmf",
		"emf",
		"art",
		"xar",
		"png",
		"webp",
		"jxr",
		"hdp",
		"wdp",
		"cur",
		"ecw",
		"iff",
		"lbm",
		"liff",
		"nrrd",
		"pam",
		"pcx",
		"pgf",
		"sgi",
		"rgb",
		"rgba",
		"bw",
		"int",
		"inta",
		"sid",
		"ras",
		"sun",
		"tga"
	],
	audio: ["mp3", "mpga", "wav", "aac", "flac", "ogg", "aiff", "alac", "wma", "ape", "m4a", "mp4a", "amr", "opus", "webm", "midi", "au", "ra", "dsd", "gsm"]
};

export const MEDIA_EXTENSIONS = {
	mimeType: {
		"application/andrew-inset": "ez",
		"application/applixware": "aw",
		"application/atom+xml": "atom",
		"application/atomcat+xml": "atomcat",
		"application/atomdeleted+xml": "atomdeleted",
		"application/atomsvc+xml": "atomsvc",
		"application/atsc-dwd+xml": "dwd",
		"application/atsc-held+xml": "held",
		"application/atsc-rsat+xml": "rsat",
		"application/calendar+xml": "xcs",
		"application/ccxml+xml": "ccxml",
		"application/cdfx+xml": "cdfx",
		"application/cdmi-capability": "cdmia",
		"application/cdmi-container": "cdmic",
		"application/cdmi-domain": "cdmid",
		"application/cdmi-object": "cdmio",
		"application/cdmi-queue": "cdmiq",
		"application/cu-seeme": "cu",
		"application/dash+xml": "mpd",
		"application/davmount+xml": "davmount",
		"application/docbook+xml": "dbk",
		"application/dssc+der": "dssc",
		"application/dssc+xml": "xdssc",
		"application/ecmascript": "es",
		"application/emma+xml": "emma",
		"application/emotionml+xml": "emotionml",
		"application/epub+zip": "epub",
		"application/exi": "exi",
		"application/express": "exp",
		"application/fdt+xml": "fdt",
		"application/font-tdpfr": "pfr",
		"application/geo+json": "geojson",
		"application/gml+xml": "gml",
		"application/gpx+xml": "gpx",
		"application/gxf": "gxf",
		"application/gzip": "gz",
		"application/hjson": "hjson",
		"application/hyperstudio": "stk",
		"application/inkml+xml": "inkml",
		"application/ipfix": "ipfix",
		"application/its+xml": "its",
		"application/java-archive": "war",
		"application/java-serialized-object": "ser",
		"application/java-vm": "class",
		"application/javascript": "mjs",
		"application/json": "map",
		"application/json5": "json5",
		"application/jsonml+json": "jsonml",
		"application/ld+json": "jsonld",
		"application/lgr+xml": "lgr",
		"application/lost+xml": "lostxml",
		"application/mac-binhex40": "hqx",
		"application/mac-compactpro": "cpt",
		"application/mads+xml": "mads",
		"application/manifest+json": "webmanifest",
		"application/marc": "mrc",
		"application/marcxml+xml": "mrcx",
		"application/mathematica": "nb",
		"application/mathml+xml": "mathml",
		"application/mbox": "mbox",
		"application/mediaservercontrol+xml": "mscml",
		"application/metalink4+xml": "meta4",
		"application/metalink+xml": "metalink",
		"application/mets+xml": "mets",
		"application/mmt-aei+xml": "maei",
		"application/mmt-usd+xml": "musd",
		"application/mods+xml": "mods",
		"application/mp4": "mp4s",
		"application/mp21": "mp21",
		"application/msword": "dot",
		"application/mxf": "mxf",
		"application/n-quads": "nq",
		"application/n-triples": "nt",
		"application/node": "cjs",
		"application/octet-stream": "so",
		"application/oda": "oda",
		"application/oebps-package+xml": "opf",
		"application/ogg": "ogx",
		"application/omdoc+xml": "omdoc",
		"application/onenote": "onetoc2",
		"application/oxps": "oxps",
		"application/p2p-overlay+xml": "relo",
		"application/patch-ops-error+xml": "xer",
		"application/pdf": "pdf",
		"application/pgp-encrypted": "pgp",
		"application/pgp-signature": "sig",
		"application/pics-rules": "prf",
		"application/pkcs7-mime": "p7m",
		"application/pkcs7-signature": "p7s",
		"application/pkcs8": "p8",
		"application/pkcs10": "p10",
		"application/pkix-cert": "cer",
		"application/pkix-crl": "crl",
		"application/pkix-pkipath": "pkipath",
		"application/pkixcmp": "pki",
		"application/pls+xml": "pls",
		"application/postscript": "ps",
		"application/provenance+xml": "provx",
		"application/prs.cww": "cww",
		"application/pskc+xml": "pskcxml",
		"application/raml+yaml": "raml",
		"application/rdf+xml": "rdf",
		"application/reginfo+xml": "rif",
		"application/relax-ng-compact-syntax": "rnc",
		"application/resource-lists+xml": "rl",
		"application/resource-lists-diff+xml": "rld",
		"application/rls-services+xml": "rs",
		"application/route-apd+xml": "rapd",
		"application/route-s-tsid+xml": "sls",
		"application/route-usd+xml": "rusd",
		"application/rpki-ghostbusters": "gbr",
		"application/rpki-manifest": "mft",
		"application/rpki-roa": "roa",
		"application/rsd+xml": "rsd",
		"application/rss+xml": "rss",
		"application/sbml+xml": "sbml",
		"application/scvp-cv-request": "scq",
		"application/scvp-cv-response": "scs",
		"application/scvp-vp-request": "spq",
		"application/scvp-vp-response": "spp",
		"application/sdp": "sdp",
		"application/senml+xml": "senmlx",
		"application/sensml+xml": "sensmlx",
		"application/set-payment-initiation": "setpay",
		"application/set-registration-initiation": "setreg",
		"application/shf+xml": "shf",
		"application/sieve": "siv",
		"application/smil+xml": "smil",
		"application/sparql-query": "rq",
		"application/sparql-results+xml": "srx",
		"application/srgs": "gram",
		"application/srgs+xml": "grxml",
		"application/sru+xml": "sru",
		"application/ssdl+xml": "ssdl",
		"application/ssml+xml": "ssml",
		"application/swid+xml": "swidtag",
		"application/tei+xml": "teicorpus",
		"application/thraud+xml": "tfi",
		"application/timestamped-data": "tsd",
		"application/toml": "toml",
		"application/trig": "trig",
		"application/ttml+xml": "ttml",
		"application/ubjson": "ubj",
		"application/urc-ressheet+xml": "rsheet",
		"application/urc-targetdesc+xml": "td",
		"application/vnd.3gpp2.tcap": "tcap",
		"application/vnd.3gpp.pic-bw-large": "plb",
		"application/vnd.3gpp.pic-bw-small": "psb",
		"application/vnd.3gpp.pic-bw-var": "pvb",
		"application/vnd.3m.post-it-notes": "pwn",
		"application/vnd.1000minds.decision-model+xml": "1km",
		"application/vnd.accpac.simply.aso": "aso",
		"application/vnd.accpac.simply.imp": "imp",
		"application/vnd.acucobol": "acu",
		"application/vnd.acucorp": "atc",
		"application/vnd.adobe.air-application-installer-package+zip": "air",
		"application/vnd.adobe.formscentral.fcdt": "fcdt",
		"application/vnd.adobe.fxp": "fxpl",
		"application/vnd.adobe.xdp+xml": "xdp",
		"application/vnd.adobe.xfdf": "xfdf",
		"application/vnd.age": "age",
		"application/vnd.ahead.space": "ahead",
		"application/vnd.airzip.filesecure.azf": "azf",
		"application/vnd.airzip.filesecure.azs": "azs",
		"application/vnd.amazon.ebook": "azw",
		"application/vnd.americandynamics.acc": "acc",
		"application/vnd.amiga.ami": "ami",
		"application/vnd.android.package-archive": "apk",
		"application/vnd.anser-web-certificate-issue-initiation": "cii",
		"application/vnd.anser-web-funds-transfer-initiation": "fti",
		"application/vnd.antix.game-component": "atx",
		"application/vnd.apple.installer+xml": "mpkg",
		"application/vnd.apple.mpegurl": "m3u8",
		"application/vnd.apple.pkpass": "pkpass",
		"application/vnd.aristanetworks.swi": "swi",
		"application/vnd.astraea-software.iota": "iota",
		"application/vnd.audiograph": "aep",
		"application/vnd.balsamiq.bmml+xml": "bmml",
		"application/vnd.blueice.multipass": "mpm",
		"application/vnd.bmi": "bmi",
		"application/vnd.businessobjects": "rep",
		"application/vnd.chemdraw+xml": "cdxml",
		"application/vnd.chipnuts.karaoke-mmd": "mmd",
		"application/vnd.cinderella": "cdy",
		"application/vnd.citationstyles.style+xml": "csl",
		"application/vnd.claymore": "cla",
		"application/vnd.cloanto.rp9": "rp9",
		"application/vnd.clonk.c4group": "c4u",
		"application/vnd.cluetrust.cartomobile-config": "c11amc",
		"application/vnd.cluetrust.cartomobile-config-pkg": "c11amz",
		"application/vnd.commonspace": "csp",
		"application/vnd.contact.cmsg": "cdbcmsg",
		"application/vnd.cosmocaller": "cmc",
		"application/vnd.crick.clicker": "clkx",
		"application/vnd.crick.clicker.keyboard": "clkk",
		"application/vnd.crick.clicker.palette": "clkp",
		"application/vnd.crick.clicker.template": "clkt",
		"application/vnd.crick.clicker.wordbank": "clkw",
		"application/vnd.criticaltools.wbs+xml": "wbs",
		"application/vnd.ctc-posml": "pml",
		"application/vnd.cups-ppd": "ppd",
		"application/vnd.curl.car": "car",
		"application/vnd.curl.pcurl": "pcurl",
		"application/vnd.dart": "dart",
		"application/vnd.data-vision.rdz": "rdz",
		"application/vnd.dbf": "dbf",
		"application/vnd.dece.data": "uvvf",
		"application/vnd.dece.ttml+xml": "uvvt",
		"application/vnd.dece.unspecified": "uvx",
		"application/vnd.dece.zip": "uvz",
		"application/vnd.denovo.fcselayout-link": "fe_launch",
		"application/vnd.dna": "dna",
		"application/vnd.dolby.mlp": "mlp",
		"application/vnd.dpgraph": "dpg",
		"application/vnd.dreamfactory": "dfac",
		"application/vnd.ds-keypoint": "kpxx",
		"application/vnd.dvb.ait": "ait",
		"application/vnd.dvb.service": "svc",
		"application/vnd.dynageo": "geo",
		"application/vnd.ecowin.chart": "mag",
		"application/vnd.enliven": "nml",
		"application/vnd.epson.esf": "esf",
		"application/vnd.epson.msf": "msf",
		"application/vnd.epson.quickanime": "qam",
		"application/vnd.epson.salt": "slt",
		"application/vnd.epson.ssf": "ssf",
		"application/vnd.eszigno3+xml": "et3",
		"application/vnd.ezpix-album": "ez2",
		"application/vnd.ezpix-package": "ez3",
		"application/vnd.fdf": "fdf",
		"application/vnd.fdsn.mseed": "mseed",
		"application/vnd.fdsn.seed": "seed",
		"application/vnd.flographit": "gph",
		"application/vnd.fluxtime.clip": "ftc",
		"application/vnd.framemaker": "maker",
		"application/vnd.frogans.fnc": "fnc",
		"application/vnd.frogans.ltf": "ltf",
		"application/vnd.fsc.weblaunch": "fsc",
		"application/vnd.fujitsu.oasys": "oas",
		"application/vnd.fujitsu.oasys2": "oa2",
		"application/vnd.fujitsu.oasys3": "oa3",
		"application/vnd.fujitsu.oasysgp": "fg5",
		"application/vnd.fujitsu.oasysprs": "bh2",
		"application/vnd.fujixerox.ddd": "ddd",
		"application/vnd.fujixerox.docuworks": "xdw",
		"application/vnd.fujixerox.docuworks.binder": "xbd",
		"application/vnd.fuzzysheet": "fzs",
		"application/vnd.genomatix.tuxedo": "txd",
		"application/vnd.geogebra.file": "ggb",
		"application/vnd.geogebra.tool": "ggt",
		"application/vnd.geometry-explorer": "gre",
		"application/vnd.geonext": "gxt",
		"application/vnd.geoplan": "g2w",
		"application/vnd.geospace": "g3w",
		"application/vnd.gmx": "gmx",
		"application/vnd.google-apps.document": "gdoc",
		"application/vnd.google-apps.presentation": "gslides",
		"application/vnd.google-apps.spreadsheet": "gsheet",
		"application/vnd.google-earth.kml+xml": "kml",
		"application/vnd.google-earth.kmz": "kmz",
		"application/vnd.grafeq": "gqs",
		"application/vnd.groove-account": "gac",
		"application/vnd.groove-help": "ghf",
		"application/vnd.groove-identity-message": "gim",
		"application/vnd.groove-injector": "grv",
		"application/vnd.groove-tool-message": "gtm",
		"application/vnd.groove-tool-template": "tpl",
		"application/vnd.groove-vcard": "vcg",
		"application/vnd.hal+xml": "hal",
		"application/vnd.handheld-entertainment+xml": "zmm",
		"application/vnd.hbci": "hbci",
		"application/vnd.hhe.lesson-player": "les",
		"application/vnd.hp-hpgl": "hpgl",
		"application/vnd.hp-hpid": "hpid",
		"application/vnd.hp-hps": "hps",
		"application/vnd.hp-jlyt": "jlt",
		"application/vnd.hp-pcl": "pcl",
		"application/vnd.hp-pclxl": "pclxl",
		"application/vnd.hydrostatix.sof-data": "sfd-hdstx",
		"application/vnd.ibm.minipay": "mpy",
		"application/vnd.ibm.modcap": "listafp",
		"application/vnd.ibm.rights-management": "irm",
		"application/vnd.ibm.secure-container": "sc",
		"application/vnd.iccprofile": "icm",
		"application/vnd.igloader": "igl",
		"application/vnd.immervision-ivp": "ivp",
		"application/vnd.immervision-ivu": "ivu",
		"application/vnd.insors.igm": "igm",
		"application/vnd.intercon.formnet": "xpx",
		"application/vnd.intergeo": "i2g",
		"application/vnd.intu.qbo": "qbo",
		"application/vnd.intu.qfx": "qfx",
		"application/vnd.ipunplugged.rcprofile": "rcprofile",
		"application/vnd.irepository.package+xml": "irp",
		"application/vnd.is-xpr": "xpr",
		"application/vnd.isac.fcs": "fcs",
		"application/vnd.jam": "jam",
		"application/vnd.jcp.javame.midlet-rms": "rms",
		"application/vnd.jisp": "jisp",
		"application/vnd.joost.joda-archive": "joda",
		"application/vnd.kahootz": "ktz",
		"application/vnd.kde.karbon": "karbon",
		"application/vnd.kde.kchart": "chrt",
		"application/vnd.kde.kformula": "kfo",
		"application/vnd.kde.kivio": "flw",
		"application/vnd.kde.kontour": "kon",
		"application/vnd.kde.kpresenter": "kpt",
		"application/vnd.kde.kspread": "ksp",
		"application/vnd.kde.kword": "kwt",
		"application/vnd.kenameaapp": "htke",
		"application/vnd.kidspiration": "kia",
		"application/vnd.kinar": "knp",
		"application/vnd.koan": "skt",
		"application/vnd.kodak-descriptor": "sse",
		"application/vnd.las.las+xml": "lasxml",
		"application/vnd.llamagraphics.life-balance.desktop": "lbd",
		"application/vnd.llamagraphics.life-balance.exchange+xml": "lbe",
		"application/vnd.lotus-1-2-3": "123",
		"application/vnd.lotus-approach": "apr",
		"application/vnd.lotus-freelance": "pre",
		"application/vnd.lotus-notes": "nsf",
		"application/vnd.lotus-screencam": "scm",
		"application/vnd.lotus-wordpro": "lwp",
		"application/vnd.macports.portpkg": "portpkg",
		"application/vnd.mapbox-vector-tile": "mvt",
		"application/vnd.mcd": "mcd",
		"application/vnd.medcalcdata": "mc1",
		"application/vnd.mediastation.cdkey": "cdkey",
		"application/vnd.mfer": "mwf",
		"application/vnd.mfmp": "mfm",
		"application/vnd.micrografx.flo": "flo",
		"application/vnd.micrografx.igx": "igx",
		"application/vnd.mif": "mif",
		"application/vnd.mobius.daf": "daf",
		"application/vnd.mobius.dis": "dis",
		"application/vnd.mobius.mbk": "mbk",
		"application/vnd.mobius.mqy": "mqy",
		"application/vnd.mobius.msl": "msl",
		"application/vnd.mobius.plc": "plc",
		"application/vnd.mobius.txf": "txf",
		"application/vnd.mophun.application": "mpn",
		"application/vnd.mophun.certificate": "mpc",
		"application/vnd.mozilla.xul+xml": "xul",
		"application/vnd.ms-artgalry": "cil",
		"application/vnd.ms-cab-compressed": "cab",
		"application/vnd.ms-excel": "xlw",
		"application/vnd.ms-excel.addin.macroenabled.12": "xlam",
		"application/vnd.ms-excel.sheet.binary.macroenabled.12": "xlsb",
		"application/vnd.ms-excel.sheet.macroenabled.12": "xlsm",
		"application/vnd.ms-excel.template.macroenabled.12": "xltm",
		"application/vnd.ms-fontobject": "eot",
		"application/vnd.ms-htmlhelp": "chm",
		"application/vnd.ms-ims": "ims",
		"application/vnd.ms-lrm": "lrm",
		"application/vnd.ms-officetheme": "thmx",
		"application/vnd.ms-outlook": "msg",
		"application/vnd.ms-pki.seccat": "cat",
		"application/vnd.ms-powerpoint": "ppt",
		"application/vnd.ms-powerpoint.addin.macroenabled.12": "ppam",
		"application/vnd.ms-powerpoint.presentation.macroenabled.12": "pptm",
		"application/vnd.ms-powerpoint.slide.macroenabled.12": "sldm",
		"application/vnd.ms-powerpoint.slideshow.macroenabled.12": "ppsm",
		"application/vnd.ms-powerpoint.template.macroenabled.12": "potm",
		"application/vnd.ms-project": "mpt",
		"application/vnd.ms-word.document.macroenabled.12": "docm",
		"application/vnd.ms-word.template.macroenabled.12": "dotm",
		"application/vnd.ms-works": "wps",
		"application/vnd.ms-wpl": "wpl",
		"application/vnd.ms-xpsdocument": "xps",
		"application/vnd.mseq": "mseq",
		"application/vnd.musician": "mus",
		"application/vnd.muvee.style": "msty",
		"application/vnd.mynfc": "taglet",
		"application/vnd.neurolanguage.nlu": "nlu",
		"application/vnd.nitf": "ntf",
		"application/vnd.noblenet-directory": "nnd",
		"application/vnd.noblenet-sealer": "nns",
		"application/vnd.noblenet-web": "nnw",
		"application/vnd.nokia.n-gage.ac+xml": "ac",
		"application/vnd.nokia.n-gage.data": "ngdat",
		"application/vnd.nokia.n-gage.symbian.install": "n-gage",
		"application/vnd.nokia.radio-preset": "rpst",
		"application/vnd.nokia.radio-presets": "rpss",
		"application/vnd.novadigm.edm": "edm",
		"application/vnd.novadigm.edx": "edx",
		"application/vnd.novadigm.ext": "ext",
		"application/vnd.oasis.opendocument.chart": "odc",
		"application/vnd.oasis.opendocument.chart-template": "otc",
		"application/vnd.oasis.opendocument.database": "odb",
		"application/vnd.oasis.opendocument.formula": "odf",
		"application/vnd.oasis.opendocument.formula-template": "odft",
		"application/vnd.oasis.opendocument.graphics": "odg",
		"application/vnd.oasis.opendocument.graphics-template": "otg",
		"application/vnd.oasis.opendocument.image": "odi",
		"application/vnd.oasis.opendocument.image-template": "oti",
		"application/vnd.oasis.opendocument.presentation": "odp",
		"application/vnd.oasis.opendocument.presentation-template": "otp",
		"application/vnd.oasis.opendocument.spreadsheet": "ods",
		"application/vnd.oasis.opendocument.spreadsheet-template": "ots",
		"application/vnd.oasis.opendocument.text": "odt",
		"application/vnd.oasis.opendocument.text-master": "odm",
		"application/vnd.oasis.opendocument.text-template": "ott",
		"application/vnd.oasis.opendocument.text-web": "oth",
		"application/vnd.olpc-sugar": "xo",
		"application/vnd.oma.dd2+xml": "dd2",
		"application/vnd.openblox.game+xml": "obgx",
		"application/vnd.openofficeorg.extension": "oxt",
		"application/vnd.openstreetmap.data+xml": "osm",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
		"application/vnd.openxmlformats-officedocument.presentationml.slide": "sldx",
		"application/vnd.openxmlformats-officedocument.presentationml.slideshow": "ppsx",
		"application/vnd.openxmlformats-officedocument.presentationml.template": "potx",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.template": "xltx",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.template": "dotx",
		"application/vnd.osgeo.mapguide.package": "mgp",
		"application/vnd.osgi.dp": "dp",
		"application/vnd.osgi.subsystem": "esa",
		"application/vnd.palm": "pqa",
		"application/vnd.pawaafile": "paw",
		"application/vnd.pg.format": "str",
		"application/vnd.pg.osasli": "ei6",
		"application/vnd.picsel": "efif",
		"application/vnd.pmi.widget": "wg",
		"application/vnd.pocketlearn": "plf",
		"application/vnd.powerbuilder6": "pbd",
		"application/vnd.previewsystems.box": "box",
		"application/vnd.proteus.magazine": "mgz",
		"application/vnd.publishare-delta-tree": "qps",
		"application/vnd.pvi.ptid1": "ptid",
		"application/vnd.quark.quarkxpress": "qxt",
		"application/vnd.realvnc.bed": "bed",
		"application/vnd.recordare.musicxml": "mxl",
		"application/vnd.recordare.musicxml+xml": "musicxml",
		"application/vnd.rig.cryptonote": "cryptonote",
		"application/vnd.rim.cod": "cod",
		"application/vnd.rn-realmedia": "rm",
		"application/vnd.rn-realmedia-vbr": "rmvb",
		"application/vnd.route66.link66+xml": "link66",
		"application/vnd.sailingtracker.track": "st",
		"application/vnd.seemail": "see",
		"application/vnd.sema": "sema",
		"application/vnd.semd": "semd",
		"application/vnd.semf": "semf",
		"application/vnd.shana.informed.formdata": "ifm",
		"application/vnd.shana.informed.formtemplate": "itp",
		"application/vnd.shana.informed.interchange": "iif",
		"application/vnd.shana.informed.package": "ipk",
		"application/vnd.simtech-mindmapper": "twds",
		"application/vnd.smaf": "mmf",
		"application/vnd.smart.teacher": "teacher",
		"application/vnd.software602.filler.form+xml": "fo",
		"application/vnd.solent.sdkm+xml": "sdkm",
		"application/vnd.spotfire.dxp": "dxp",
		"application/vnd.spotfire.sfs": "sfs",
		"application/vnd.stardivision.calc": "sdc",
		"application/vnd.stardivision.draw": "sda",
		"application/vnd.stardivision.impress": "sdd",
		"application/vnd.stardivision.math": "smf",
		"application/vnd.stardivision.writer": "vor",
		"application/vnd.stardivision.writer-global": "sgl",
		"application/vnd.stepmania.package": "smzip",
		"application/vnd.stepmania.stepchart": "sm",
		"application/vnd.sun.wadl+xml": "wadl",
		"application/vnd.sun.xml.calc": "sxc",
		"application/vnd.sun.xml.calc.template": "stc",
		"application/vnd.sun.xml.draw": "sxd",
		"application/vnd.sun.xml.draw.template": "std",
		"application/vnd.sun.xml.impress": "sxi",
		"application/vnd.sun.xml.impress.template": "sti",
		"application/vnd.sun.xml.math": "sxm",
		"application/vnd.sun.xml.writer": "sxw",
		"application/vnd.sun.xml.writer.global": "sxg",
		"application/vnd.sun.xml.writer.template": "stw",
		"application/vnd.sus-calendar": "susp",
		"application/vnd.svd": "svd",
		"application/vnd.symbian.install": "sisx",
		"application/vnd.syncml+xml": "xsm",
		"application/vnd.syncml.dm+wbxml": "bdm",
		"application/vnd.syncml.dm+xml": "xdm",
		"application/vnd.syncml.dmddf+xml": "ddf",
		"application/vnd.tao.intent-module-archive": "tao",
		"application/vnd.tcpdump.pcap": "pcap",
		"application/vnd.tmobile-livetv": "tmo",
		"application/vnd.trid.tpt": "tpt",
		"application/vnd.triscape.mxs": "mxs",
		"application/vnd.trueapp": "tra",
		"application/vnd.ufdl": "ufdl",
		"application/vnd.uiq.theme": "utz",
		"application/vnd.umajin": "umj",
		"application/vnd.unity": "unityweb",
		"application/vnd.uoml+xml": "uoml",
		"application/vnd.vcx": "vcx",
		"application/vnd.visio": "vsw",
		"application/vnd.visionary": "vis",
		"application/vnd.vsf": "vsf",
		"application/vnd.wap.wbxml": "wbxml",
		"application/vnd.wap.wmlc": "wmlc",
		"application/vnd.wap.wmlscriptc": "wmlsc",
		"application/vnd.webturbo": "wtb",
		"application/vnd.wolfram.player": "nbp",
		"application/vnd.wordperfect": "wpd",
		"application/vnd.wqd": "wqd",
		"application/vnd.wt.stf": "stf",
		"application/vnd.xara": "xar",
		"application/vnd.xfdl": "xfdl",
		"application/vnd.yamaha.hv-dic": "hvd",
		"application/vnd.yamaha.hv-script": "hvs",
		"application/vnd.yamaha.hv-voice": "hvp",
		"application/vnd.yamaha.openscoreformat": "osf",
		"application/vnd.yamaha.openscoreformat.osfpvg+xml": "osfpvg",
		"application/vnd.yamaha.smaf-audio": "saf",
		"application/vnd.yamaha.smaf-phrase": "spf",
		"application/vnd.yellowriver-custom-menu": "cmp",
		"application/vnd.zul": "zirz",
		"application/vnd.zzazz.deck+xml": "zaz",
		"application/voicexml+xml": "vxml",
		"application/wasm": "wasm",
		"application/widget": "wgt",
		"application/winhlp": "hlp",
		"application/wsdl+xml": "wsdl",
		"application/wspolicy+xml": "wspolicy",
		"application/x-7z-compressed": "7z",
		"application/x-abiword": "abw",
		"application/x-ace-compressed": "ace",
		"application/x-apple-diskimage": "dmg",
		"application/x-arj": "arj",
		"application/x-authorware-bin": "x32",
		"application/x-authorware-map": "aam",
		"application/x-authorware-seg": "aas",
		"application/x-bcpio": "bcpio",
		"application/x-bdoc": "bdoc",
		"application/x-bittorrent": "torrent",
		"application/x-blorb": "blorb",
		"application/x-bzip": "bz",
		"application/x-bzip2": "bz2",
		"application/x-cbr": "cbz",
		"application/x-cdlink": "vcd",
		"application/x-cfs-compressed": "cfs",
		"application/x-chat": "chat",
		"application/x-chess-pgn": "pgn",
		"application/x-chrome-extension": "crx",
		"application/x-cocoa": "cco",
		"application/x-conference": "nsc",
		"application/x-cpio": "cpio",
		"application/x-csh": "csh",
		"application/x-debian-package": "udeb",
		"application/x-dgc-compressed": "dgc",
		"application/x-director": "w3d",
		"application/x-doom": "wad",
		"application/x-dtbncx+xml": "ncx",
		"application/x-dtbook+xml": "dtb",
		"application/x-dtbresource+xml": "res",
		"application/x-dvi": "dvi",
		"application/x-envoy": "evy",
		"application/x-eva": "eva",
		"application/x-font-bdf": "bdf",
		"application/x-font-ghostscript": "gsf",
		"application/x-font-linux-psf": "psf",
		"application/x-font-pcf": "pcf",
		"application/x-font-snf": "snf",
		"application/x-font-type1": "pfm",
		"application/x-freearc": "arc",
		"application/x-futuresplash": "spl",
		"application/x-gca-compressed": "gca",
		"application/x-glulx": "ulx",
		"application/x-gnumeric": "gnumeric",
		"application/x-gramps-xml": "gramps",
		"application/x-gtar": "gtar",
		"application/x-hdf": "hdf",
		"application/x-httpd-php": "php",
		"application/x-install-instructions": "install",
		"application/x-iso9660-image": "iso",
		"application/x-iwork-keynote-sffkey": "key",
		"application/x-iwork-numbers-sffnumbers": "numbers",
		"application/x-iwork-pages-sffpages": "pages",
		"application/x-java-archive-diff": "jardiff",
		"application/x-java-jnlp-file": "jnlp",
		"application/x-keepass2": "kdbx",
		"application/x-latex": "latex",
		"application/x-lua-bytecode": "luac",
		"application/x-lzh-compressed": "lzh",
		"application/x-makeself": "run",
		"application/x-mie": "mie",
		"application/x-mobipocket-ebook": "mobi",
		"application/x-ms-application": "application",
		"application/x-ms-shortcut": "lnk",
		"application/x-ms-wmd": "wmd",
		"application/x-ms-xbap": "xbap",
		"application/x-msaccess": "mdb",
		"application/x-msbinder": "obd",
		"application/x-mscardfile": "crd",
		"application/x-msclip": "clp",
		"application/x-msdownload": "msi",
		"application/x-msmediaview": "mvb",
		"application/x-msmetafile": "wmz",
		"application/x-msmoney": "mny",
		"application/x-mspublisher": "pub",
		"application/x-msschedule": "scd",
		"application/x-msterminal": "trm",
		"application/x-mswrite": "wri",
		"application/x-netcdf": "nc",
		"application/x-ns-proxy-autoconfig": "pac",
		"application/x-nzb": "nzb",
		"application/x-perl": "pm",
		"application/x-pilot": "prc",
		"application/x-pkcs7-certificates": "spc",
		"application/x-pkcs7-certreqresp": "p7r",
		"application/x-pkcs12": "pfx",
		"application/x-rar-compressed": "rar",
		"application/x-redhat-package-manager": "rpm",
		"application/x-research-info-systems": "ris",
		"application/x-sea": "sea",
		"application/x-sh": "sh",
		"application/x-shar": "shar",
		"application/x-shockwave-flash": "swf",
		"application/x-silverlight-app": "xap",
		"application/x-sql": "sql",
		"application/x-stuffit": "sit",
		"application/x-stuffitx": "sitx",
		"application/x-subrip": "srt",
		"application/x-sv4cpio": "sv4cpio",
		"application/x-sv4crc": "sv4crc",
		"application/x-t3vm-image": "t3",
		"application/x-tads": "gam",
		"application/x-tar": "tar",
		"application/x-tcl": "tk",
		"application/x-tex": "tex",
		"application/x-tex-tfm": "tfm",
		"application/x-texinfo": "texinfo",
		"application/x-ustar": "ustar",
		"application/x-virtualbox-hdd": "hdd",
		"application/x-virtualbox-ova": "ova",
		"application/x-virtualbox-ovf": "ovf",
		"application/x-virtualbox-vbox": "vbox",
		"application/x-virtualbox-vbox-extpack": "vbox-extpack",
		"application/x-virtualbox-vdi": "vdi",
		"application/x-virtualbox-vhd": "vhd",
		"application/x-virtualbox-vmdk": "vmdk",
		"application/x-wais-source": "src",
		"application/x-web-app-manifest+json": "webapp",
		"application/x-x509-ca-cert": "pem",
		"application/x-xfig": "fig",
		"application/x-xpinstall": "xpi",
		"application/x-xz": "xz",
		"application/x-zmachine": "z8",
		"application/xaml+xml": "xaml",
		"application/xcap-att+xml": "xav",
		"application/xcap-caps+xml": "xca",
		"application/xcap-diff+xml": "xdf",
		"application/xcap-el+xml": "xel",
		"application/xcap-ns+xml": "xns",
		"application/xenc+xml": "xenc",
		"application/xhtml+xml": "xhtml",
		"application/xliff+xml": "xlf",
		"application/xml": "xsd",
		"application/xml-dtd": "dtd",
		"application/xop+xml": "xop",
		"application/xproc+xml": "xpl",
		"application/xslt+xml": "xslt",
		"application/xspf+xml": "xspf",
		"application/xv+xml": "xvml",
		"application/yang": "yang",
		"application/yin+xml": "yin",
		"application/zip": "zip",
		"audio/adpcm": "adp",
		"audio/amr": "amr",
		"audio/basic": "snd",
		"audio/midi": "rmi",
		"audio/mobile-xmf": "mxmf",
		"audio/mp4": "mp4a",
		"audio/mpeg": "mpga",
		"audio/ogg": "spx",
		"audio/s3m": "s3m",
		"audio/silk": "sil",
		"audio/vnd.dece.audio": "uvva",
		"audio/vnd.digital-winds": "eol",
		"audio/vnd.dra": "dra",
		"audio/vnd.dts": "dts",
		"audio/vnd.dts.hd": "dtshd",
		"audio/vnd.lucent.voice": "lvp",
		"audio/vnd.ms-playready.media.pya": "pya",
		"audio/vnd.nuera.ecelp4800": "ecelp4800",
		"audio/vnd.nuera.ecelp7470": "ecelp7470",
		"audio/vnd.nuera.ecelp9600": "ecelp9600",
		"audio/vnd.rip": "rip",
		"audio/webm": "weba",
		"audio/x-aac": "aac",
		"audio/aac": "aac",
		"audio/x-aiff": "aiff",
		"audio/x-caf": "caf",
		"audio/x-flac": "flac",
		"audio/flac": "flac",
		"audio/x-m4a": "m4a",
		"audio/m4a": "m4a",
		"audio/x-matroska": "mka",
		"audio/x-mpegurl": "m3u",
		"audio/x-ms-wax": "wax",
		"audio/x-ms-wma": "wma",
		"audio/x-pn-realaudio": "ram",
		"audio/x-pn-realaudio-plugin": "rmp",
		"audio/x-realaudio": "ra",
		"audio/x-wav": "wav",
		"audio/wav": "wav",
		"audio/vnd.wave": "wav",
		"audio/xm": "xm",
		"chemical/x-cdx": "cdx",
		"chemical/x-cif": "cif",
		"chemical/x-cmdf": "cmdf",
		"chemical/x-cml": "cml",
		"chemical/x-csml": "csml",
		"chemical/x-xyz": "xyz",
		"font/collection": "ttc",
		"font/otf": "otf",
		"font/ttf": "ttf",
		"font/woff": "woff",
		"font/woff2": "woff2",
		"image/aces": "exr",
		"image/apng": "apng",
		"image/avif": "avif",
		"image/cgm": "cgm",
		"image/dicom-rle": "drle",
		"image/emf": "emf",
		"image/fits": "fits",
		"image/g3fax": "g3",
		"image/gif": "gif",
		"image/heic": "heic",
		"image/heic-sequence": "heics",
		"image/heif": "heif",
		"image/heif-sequence": "heifs",
		"image/hej2k": "hej2",
		"image/hsj2": "hsj2",
		"image/ief": "ief",
		"image/jls": "jls",
		"image/jp2": "jpg2",
		"image/jpeg": "jpg",
		"image/jph": "jph",
		"image/jphc": "jhc",
		"image/jpx": "jpx",
		"image/jxr": "jxr",
		"image/jxra": "jxra",
		"image/jxrs": "jxrs",
		"image/jxs": "jxs",
		"image/jxsc": "jxsc",
		"image/jxsi": "jxsi",
		"image/jxss": "jxss",
		"image/ktx": "ktx",
		"image/ktx2": "ktx2",
		"image/png": "png",
		"image/prs.btif": "btif",
		"image/prs.pti": "pti",
		"image/sgi": "sgi",
		"image/svg+xml": "svgz",
		"image/t38": "t38",
		"image/tiff": "tiff",
		"image/tiff-fx": "tfx",
		"image/vnd.adobe.photoshop": "psd",
		"image/vnd.airzip.accelerator.azv": "azv",
		"image/vnd.dece.graphic": "uvvi",
		"image/vnd.djvu": "djvu",
		"image/vnd.dwg": "dwg",
		"image/vnd.dxf": "dxf",
		"image/vnd.fastbidsheet": "fbs",
		"image/vnd.fpx": "fpx",
		"image/vnd.fst": "fst",
		"image/vnd.fujixerox.edmics-mmr": "mmr",
		"image/vnd.fujixerox.edmics-rlc": "rlc",
		"image/vnd.ms-dds": "dds",
		"image/vnd.ms-modi": "mdi",
		"image/vnd.ms-photo": "wdp",
		"image/vnd.net-fpx": "npx",
		"image/vnd.pco.b16": "b16",
		"image/vnd.tencent.tap": "tap",
		"image/vnd.valve.source.texture": "vtf",
		"image/vnd.wap.wbmp": "wbmp",
		"image/vnd.xiff": "xif",
		"image/webp": "webp",
		"image/wmf": "wmf",
		"image/x-3ds": "3ds",
		"image/x-cmu-raster": "ras",
		"image/x-cmx": "cmx",
		"image/x-freehand": "fhc",
		"image/x-icon": "ico",
		"image/x-jng": "jng",
		"image/x-mrsid-image": "sid",
		"image/x-ms-bmp": "bmp",
		"image/x-pcx": "pcx",
		"image/x-pict": "pic",
		"image/x-portable-anymap": "pnm",
		"image/x-portable-bitmap": "pbm",
		"image/x-portable-graymap": "pgm",
		"image/x-portable-pixmap": "ppm",
		"image/x-rgb": "rgb",
		"image/x-tga": "tga",
		"image/x-xbitmap": "xbm",
		"image/x-xpixmap": "xpm",
		"image/x-xwindowdump": "xwd",
		"message/disposition-notification": "disposition-notification",
		"message/global": "u8msg",
		"message/global-delivery-status": "u8dsn",
		"message/global-disposition-notification": "u8mdn",
		"message/global-headers": "u8hdr",
		"message/rfc822": "mime",
		"message/vnd.wfa.wsc": "wsc",
		"model/3mf": "3mf",
		"model/gltf+json": "gltf",
		"model/gltf-binary": "glb",
		"model/iges": "igs",
		"model/mesh": "silo",
		"model/mtl": "mtl",
		"model/obj": "obj",
		"model/step+xml": "stpx",
		"model/step+zip": "stpz",
		"model/step-xml+zip": "stpxz",
		"model/stl": "stl",
		"model/vnd.collada+xml": "dae",
		"model/vnd.dwf": "dwf",
		"model/vnd.gdl": "gdl",
		"model/vnd.gtw": "gtw",
		"model/vnd.mts": "mts",
		"model/vnd.opengex": "ogex",
		"model/vnd.parasolid.transmit.binary": "x_b",
		"model/vnd.parasolid.transmit.text": "x_t",
		"model/vnd.sap.vds": "vds",
		"model/vnd.usdz+zip": "usdz",
		"model/vnd.valve.source.compiled-map": "bsp",
		"model/vnd.vtu": "vtu",
		"model/vrml": "wrl",
		"model/x3d+binary": "x3dbz",
		"model/x3d+fastinfoset": "x3db",
		"model/x3d+vrml": "x3dvz",
		"model/x3d+xml": "x3dz",
		"model/x3d-vrml": "x3dv",
		"text/cache-manifest": "manifest",
		"text/calendar": "ifb",
		"text/coffeescript": "litcoffee",
		"text/css": "css",
		"text/csv": "csv",
		"text/html": "shtml",
		"text/jade": "jade",
		"text/jsx": "jsx",
		"text/less": "less",
		"text/markdown": "md",
		"text/mathml": "mml",
		"text/mdx": "mdx",
		"text/n3": "n3",
		"text/plain": "txt",
		"text/prs.lines.tag": "dsc",
		"text/richtext": "rtx",
		"text/rtf": "rtf",
		"text/sgml": "sgml",
		"text/shex": "shex",
		"text/slim": "slm",
		"text/spdx": "spdx",
		"text/stylus": "stylus",
		"text/tab-separated-values": "tsv",
		"text/troff": "tr",
		"text/turtle": "ttl",
		"text/uri-list": "urls",
		"text/vcard": "vcard",
		"text/vnd.curl": "curl",
		"text/vnd.curl.dcurl": "dcurl",
		"text/vnd.curl.mcurl": "mcurl",
		"text/vnd.curl.scurl": "scurl",
		"text/vnd.dvb.subtitle": "sub",
		"text/vnd.familysearch.gedcom": "ged",
		"text/vnd.fly": "fly",
		"text/vnd.fmi.flexstor": "flx",
		"text/vnd.graphviz": "gv",
		"text/vnd.in3d.3dml": "3dml",
		"text/vnd.in3d.spot": "spot",
		"text/vnd.sun.j2me.app-descriptor": "jad",
		"text/vnd.wap.wml": "wml",
		"text/vnd.wap.wmlscript": "wmls",
		"text/vtt": "vtt",
		"text/x-asm": "s",
		"text/x-c": "hh",
		"text/x-component": "htc",
		"text/x-fortran": "for",
		"text/x-handlebars-template": "hbs",
		"text/x-java-source": "java",
		"text/x-lua": "lua",
		"text/x-markdown": "mkd",
		"text/x-nfo": "nfo",
		"text/x-opml": "opml",
		"text/x-org": "org",
		"text/x-pascal": "pas",
		"text/x-processing": "pde",
		"text/x-sass": "sass",
		"text/x-scss": "scss",
		"text/x-setext": "etx",
		"text/x-sfv": "sfv",
		"text/x-suse-ymp": "ymp",
		"text/x-uuencode": "uu",
		"text/x-vcalendar": "vcs",
		"text/x-vcard": "vcf",
		"text/xml": "xml",
		"text/yaml": "yml",
		"video/3gpp": "3gpp",
		"video/3gpp2": "3g2",
		"video/h261": "h261",
		"video/h263": "h263",
		"video/h264": "h264",
		"video/iso.segment": "m4s",
		"video/jpeg": "jpgv",
		"video/jpm": "jpm",
		"video/mj2": "mjp2",
		"video/mp2t": "ts",
		"video/mp4": "mpg4",
		"video/mpeg": "mpg",
		"video/ogg": "ogv",
		"video/quicktime": "qt",
		"video/vnd.dece.hd": "uvvh",
		"video/vnd.dece.mobile": "uvvm",
		"video/vnd.dece.pd": "uvvp",
		"video/vnd.dece.sd": "uvvs",
		"video/vnd.dece.video": "uvvv",
		"video/vnd.dvb.file": "dvb",
		"video/vnd.fvt": "fvt",
		"video/vnd.mpegurl": "mxu",
		"video/vnd.ms-playready.media.pyv": "pyv",
		"video/vnd.uvvu.mp4": "uvvu",
		"video/vnd.vivo": "viv",
		"video/webm": "webm",
		"video/x-f4v": "f4v",
		"video/x-fli": "fli",
		"video/x-flv": "flv",
		"video/x-m4v": "m4v",
		"video/x-matroska": "mkv",
		"video/x-mng": "mng",
		"video/x-ms-asf": "asx",
		"video/x-ms-vob": "vob",
		"video/x-ms-wm": "wm",
		"video/x-ms-wmv": "wmv",
		"video/x-ms-wmx": "wmx",
		"video/x-ms-wvx": "wvx",
		"video/x-msvideo": "avi",
		"video/x-sgi-movie": "movie",
		"video/x-smv": "smv",
		"x-conference/x-cooltalk": "ice"
	}
};

export const EVENT_TYPES = {
	reviewRequest: "review_request",
	message: "message",
	chatbotActivated: "chatbot_activated",
	chatbotDeactivated: "chatbot_deactivated",
	chatbotForceQuit: "chatbot_force_quit",
	conversationNote: "conversation_note",
	reviewGenerated: "review_generated",
	secureChatStarted: "secure_chat_started",
	secureChatEnded: "secure_chat_ended",
	sharedCustomerMessage: "shared_customer_message",
	contactMerged: "contact_merged",
	callDisconnected: "call_disconnected",
	voicemailCompleted: "voicemail_completed",
	voicemailCaptured: "voicemail_captured",
	getVoiceFeature: "get_voice_feature",
	teamchatAddedMember: "teamchat_added_member",
	teamchatRemovedMember: "teamchat_removed_member",
	teamchatMemberLeft: "teamchat_member_left"
};

export const DISCONNECTED_CAUSES = {
	Hangup: {
		id: "Hangup",
		description: "One party hung up the call and indicates that the call ended normally."
	},
	Busy: {
		id: "Busy",
		description: "The phone line was busy."
	},
	Timeout: {
		id: "Timeout",
		description: "The call wasn't answered"
	},
	Cancel: {
		id: "Cancel",
		description: "The call was cancelled by its originator while it was ringing."
	},
	Rejected: {
		id: "Rejected",
		description: "The call was rejected by the callee."
	},
	["Callback Error"]: {
		id: "Callback Error",
		description: "A callback server error occurred."
	},
	["Invalid BXML"]: {
		id: "Invalid BXML",
		description: "An invalid response was made."
	},
	["Application Error"]: {
		id: "Application Error",
		description: "An unsupported action was tried on the call."
	},
	["Account Limit"]: {
		id: "Account Limit",
		description: "The account rate limits were reached."
	},
	["Node Capacity Exceeded"]: {
		id: "Node Capacity Exceeded",
		description: "The system maximum capacity was reached."
	},
	Error: {
		id: "Error",
		description: "An error occurred on the call."
	},
	Unknown: {
		id: "Unknown",
		description: "An unknown error happened on the call."
	}
};

export const DIRECTION = {
	in: "in",
	out: "out"
};

export const KEYS = {
	up: 38,
	down: 40,
	enter: 13,
	tab: 9,
	esc: 27,
	semiColon: 186,
	backspace: 8
};

export const LANGUAGE_DIRECTION = {
	eng: "ltr",
	arb: "rtl"
};

export const TOURS = {
	messengerBeta: "messenger_beta",
	customerThread: "customer_thread",
	teamchatThread: "teamchat_thread",
	messengerCloseConvos: "messenger_close_convos"
};

export const TOUR_THRESHOLDS = {
	messengerCloseConvos: 7
};

export const INBOX_TYPES = {
	general: "General",
	reviews: "Reviews",
	leads: "Leads"
};

export const DOWNLOAD_STATUS = {
	idle: "idle",
	downloading: "downloading",
	complete: "complete",
	failed: "failed"
};

export const DOWNLOAD_TEXT = {
	idle: "Download Attachment",
	downloading: "Downloading ...",
	complete: "Download Complete!",
	failed: "Download Failed ... Try again."
};

export const CUSTOMER_OPTIONS = {
	addNote: {
		id: "addNote",
		name: "Add Note",
		description: "Add internal note to this conversation"
	},
	contactDetails: {
		id: "contactDetails",
		name: "Details",
		description: "View and edit contact details"
	},
	moveToInbox: {
		id: "moveToInbox",
		name: "Move",
		description: "Move this customer conversation to another Smart Inbox."
	},
	leadSource: {
		id: "leadSource",
		name: "Lead Source"
	},
	sendToCrm: {
		id: "sendToCrm",
		name: "Send To CRM",
		description: "Forward this lead to your integrated CRM."
	}
};

export const CUSTOMER_LIST_LIMIT = 25;

export const MESSAGE_STATES = {
	error: "error",
	sent: "sent",
	waiting: "waiting",
	received: "received",
	queued: "queued",
	sending: "sending"
};

export const MESSAGE_DELIVERY_STATES = {
	waiting: "waiting",
	delivered: "delivered",
	notDelivered: "not-delivered"
};

export const PROVIDER_ERRORS = {
	bandwidth: {
		landline: ["4720"]
	},
	twilio: {
		sms_unsupported: ["30006", "60205", "21614"]
	}
};

export const CONVERSATION_FILTERS = {
	all: {
		id: "all",
		name: "All"
	},
	erroredMessages: {
		id: "erroredMessages",
		name: "Last Message Errored"
	},
	lastMessageUnread: {
		id: "lastMessageUnread",
		name: "Last Message Unread"
	},
	unknownName: {
		id: "unknownName",
		name: "Unknown Name"
	},
	preferredMediumSms: {
		id: "preferredMediumSms",
		name: "Preferred Medium SMS"
	},
	preferredMediumEmail: {
		id: "preferredMediumEmail",
		name: "Preferred Medium Email"
	},
	preferredMediumFacebook: {
		id: "preferredMediumFacebook",
		name: "Preferred Medium Facebook"
	},
	preferredMediumInstagram: {
		id: "preferredMediumInstagram",
		name: "Preferred Medium Instagram"
	},
	preferredMediumGoogle: {
		id: "preferredMediumGoogle",
		name: "Preferred Medium Google"
	},
	birthday: {
		id: "birthday",
		name: "Birthday"
	}
};

export const EDIT_TEAMCHAT_TABS = {
	general: "general",
	notifications: "notifications",
	members: "members",
	media: "media",
	purge: "purge",
	actions: "actions"
};

export const ACTION_CHANNELS = {
	team: {
		scheduledTeamMessages: {
			id: "scheduledTeamMessages",
			name: "Scheduled"
		}
	},
	customer: {
		scheduledCustomerMessages: {
			id: "scheduledCustomerMessages",
			name: "Scheduled"
		}
	}
};

export const LINK_SHORTEN_MAX_CHAR = 70;

export const STATUS_EVENTS = {
	messageEdited: {
		id: "messageEdited",
		label: "(Edited)"
	}
};

export const MANAGE_TEAMCHAT_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 2
	},
	users: {
		id: "users",
		value: "Users",
		sortable: true,
		sortField: "users",
		width: 1
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 1,
		notClickable: true
	}
};

export const MANAGE_TEAMCHAT_TYPE_FILTERS = {
	channel: {
		id: "channel",
		display: "Channel",
		order: 1
	},
	dm: {
		id: "dm",
		display: "Direct Message",
		order: 2
	}
};
