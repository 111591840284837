import React from "react";
import moment from "moment";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import ToastService from "../../services/ToastService";
import MessagesService from "../../services/MessagesService";
import LocationService from "../../services/LocationService";

import Action from "../../components/common/Action";
import DHDropdown from "../../components/common/DHDropdown";
import DHDropdownOption from "../../components/common/DHDropdownOption";
import Media from "../MessengerBeta/Thread/Media/Media";

import { STATUS } from "../../constants/CommonConstants";
import { MEDIA_TYPES } from "../../constants/Messenger";

class Voicemail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			showDropdown: false
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onStatusClick = async newStatus => {
		let { voicemail, onUpdated, t } = this.props;

		let updatedVoicemail = await MessagesService.updateVoicemail({ voicemailId: voicemail.id, status: newStatus });

		if (updatedVoicemail === null) {
			ToastService.error(t("Error updating voicemail. Please try again."));
			return;
		}

		let statusLabel = t(`Voicemail opened`);
		if (newStatus === STATUS.inactive) {
			statusLabel = t(`Voicemail closed`);
		} else if (newStatus === STATUS.deleted) {
			statusLabel = t(`Voicemail deleted`);
		}

		ToastService.info(statusLabel);

		if (onUpdated) {
			onUpdated(updatedVoicemail);
		}
	};

	onExternalLinkClick = () => {
		let { voicemail } = this.props;
		let location = UserService.getActiveLocation();

		let url = `${window.location.origin}/messenger?contactId=${voicemail.Contact.public_id}&locationId=${location.public_id}`;
		window.location.href = url;
	};

	onMediaClicked = url => {};

	hasText = () => {
		let { voicemail } = this.props;

		let textContent = voicemail.Message.content;

		if (!textContent || textContent.includes("🔊")) {
			return false;
		}
		return true;
	};

	renderMedia = mobile => {
		let { voicemail } = this.props;

		let audioMedia = voicemail.Message.media.filter(m => m.type === MEDIA_TYPES.audio);

		if (!audioMedia || audioMedia.length < 1) {
			return null;
		}

		// Only one audio file for now
		audioMedia = audioMedia[0];

		return (
			<div className="voicemail__content__header__soundwave">
				<Media
					media={audioMedia}
					idPrefix={mobile ? "voicemail-mobile" : ""}
					onMediaClicked={url => this.onMediaClicked(url)}
					soundWaveHeight={mobile ? 100 : 40}
					playButtonSize={mobile ? 44 : 28}
				/>
			</div>
		);
	};

	renderTextContent = () => {
		let { voicemail } = this.props;
		let { expanded } = this.state;

		let textContent = voicemail.Message.content;

		if (!this.hasText()) {
			return null;
		}

		return (
			<div className={`voicemail__content__text ${expanded ? "voicemail__content__text--expanded" : ""}`}>
				{textContent}
				<div className="voicemail__content__text__overlay" />
			</div>
		);
	};

	renderActions = () => {
		let { voicemail, index, t } = this.props;
		let { expanded, showDropdown } = this.state;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_voicemail;
		let allowDelete = user.GroupPermission.delete_voicemail;

		let isMessengerPermissible = LocationService.isMessengerPermissible();
		return (
			<div className="voicemail__content__header__actions">
				{this.hasText() && (
					<Action
						id={`voicemail-expand-${index}`}
						key={`voicemail-expand-${index}`}
						label={expanded ? t("Shrink") : t("Expand")}
						icon={expanded ? Icon.ChevronUp : Icon.ChevronDown}
						onClick={() => this.update({ expanded: !expanded })}
					/>
				)}
				{(allowUpdate || allowDelete || isMessengerPermissible) && (
					<DHDropdown
						offset={{
							top: 20
						}}
						show={showDropdown}
						onChange={({ show }) => {
							this.update({ showDropdown: show });
						}}
						trigger={<Action id={`voicemail-more-${index}`} key={`voicemail-more-${index}`} icon={Icon.MoreHorizontal} label={t("More Options")} />}
						options={
							<>
								{allowUpdate && (
									<DHDropdownOption
										icon={voicemail.status === STATUS.active ? Icon.Check : Icon.Inbox}
										title={voicemail.status === STATUS.active ? t("Close") : t("Open")}
										action={() => this.onStatusClick(voicemail.status === STATUS.active ? STATUS.inactive : STATUS.active)}
									/>
								)}
								{allowDelete && <DHDropdownOption icon={Icon.Trash2} title={t("Delete")} action={() => this.onStatusClick(STATUS.deleted)} />}
								{isMessengerPermissible && <DHDropdownOption icon={Icon.ExternalLink} title={t("Go To Messenger")} action={this.onExternalLinkClick} />}
							</>
						}
					/>
				)}
			</div>
		);
	};

	renderMobile = () => {
		let { voicemail, t } = this.props;

		return (
			<div className={`voicemail voicemail--mobile ${!this.hasText() ? "voicemail--no-text" : ""}`}>
				<div className="voicemail__content__header">
					<div className="voicemail__content__header__name">
						<div className="voicemail__contect__header__name__text">
							<span className="dh-tip" tip={`${voicemail.Contact.name ? voicemail.Contact.name : t("Unknown")} (${voicemail.Contact.phone})`}>
								{voicemail.Contact.name ? voicemail.Contact.name : voicemail.Contact.phone}
							</span>
						</div>
					</div>
					{this.renderActions()}
				</div>
				{this.renderMedia(true)}
				<div className="voicemail__content__header__date">{moment(voicemail.created_at).format("ddd, MMM DD @ h:mm a")}</div>
				{this.renderTextContent()}
			</div>
		);
	};

	render = () => {
		let { voicemail, screenWidth, t } = this.props;

		if (!voicemail) {
			return null;
		}

		if (!voicemail.Contact) {
			return null;
		}

		if (!voicemail.Message || !voicemail.Message.media || voicemail.Message.media.length < 1) {
			return null;
		}

		if (screenWidth <= 600) {
			return this.renderMobile();
		}

		return (
			<>
				<div className={`voicemail voicemail--desktop ${!this.hasText() ? "voicemail--no-text" : ""}`}>
					<div className="voicemail__icon">
						<Icon.Voicemail size={42} color={"#BCBCBC"} />
					</div>
					<div className="voicemail__content">
						<div className="voicemail__content__header">
							<div className="voicemail__content__header__name">
								<div className="voicemail__contect__header__name__text">
									<span className="dh-tip" tip={`${voicemail.Contact.name ? voicemail.Contact.name : t("Unknown")} (${voicemail.Contact.phone})`}>
										{voicemail.Contact.name ? voicemail.Contact.name : voicemail.Contact.phone}
									</span>
								</div>
							</div>
							{this.renderMedia()}
							<div className="voicemail__content__header__date dh-tip" tip={moment(voicemail.created_at).format("ddd, MMM DD @ h:mm a")}>
								{moment(voicemail.created_at).format("ddd, MMM DD")}
							</div>
							{this.renderActions()}
						</div>
						{this.renderTextContent()}
					</div>
				</div>
			</>
		);
	};
}

export default withTranslation(null, { withRef: true })(Voicemail);
