import React from "react";
import * as Icon from "react-feather";
import moment from "moment";
import { withTranslation } from "react-i18next";

import List from "./List";

import UserService from "../../services/UserService";
import AppointmentsService from "../../services/AppointmentsService";

import { SORT_ORDER } from "../../constants/CommonConstants";
import { EDIT_CONTACT_APPOINTMENTS_COLUMNS } from "../../constants/Contacts";
import { STATUS } from "../../constants/Appointments";

class ContactAppointments extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			appointments: [],

			loadedAll: true,
			sortField: EDIT_CONTACT_APPOINTMENTS_COLUMNS.booking_at.sortField,
			sortOrder: SORT_ORDER.desc
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.fetchAppointments();
	};

	fetchAppointments = async () => {
		await this.update({ loading: true });
		let { sortField, sortOrder } = this.state;
		let { contactId } = this.props;
		let data = await AppointmentsService.fetchAppointments({
			locationId: UserService.getActiveLocation().id,
			contactId,
			sortField,
			sortOrder
		});
		await this.update({ appointments: data, loading: false });
	};

	getHeaders() {
		let headers = {
			items: EDIT_CONTACT_APPOINTMENTS_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	}

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ sortField, sortOrder });
		await this.fetchAppointments();
	};

	renderRecord = recordData => {
		try {
			if (!recordData) {
				return null;
			}
			var fullName = UserService.createFullName({ firstName: recordData.first_name, lastName: recordData.last_name });

			return [
				fullName,
				STATUS[recordData.state] ? STATUS[recordData.state].display : recordData.state,
				recordData.booking_at ? moment(recordData.booking_at).format("MMM Do YYYY hh:mm a") : ""
			];
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	onRecordClicked = () => {
		// nothing for now
	};

	render = () => {
		let { appointments, loading, loadedAll, sortField, sortOrder } = this.state;
		let { t } = this.props;

		return (
			<List
				items={appointments}
				loading={loading}
				loadedAll={loadedAll}
				sortField={sortField}
				sortOrder={sortOrder}
				headers={this.getHeaders()}
				renderRecord={this.renderRecord}
				onRecordClicked={this.onRecordClicked}
				noDataTitle={t("No appointments found...")}
				noDataIcon={<Icon.AlertCircle />}
			/>
		);
	};
}

export default withTranslation(null, { withRef: true })(ContactAppointments);
