const notificationSound = new Audio("/sounds/notification.wav");
const cashRegisterSound = new Audio("/sounds/cashRegister.wav");

export const TEAMCHAT_EVENT_NOTIFICATIONS = {
	message: {
		id: "message",
		title: ` • Team Chat`,
		sound: notificationSound
	},
	dhCustomerWin: {
		id: "dh_customer_win",
		title: `Cha Ching!`,
		sound: cashRegisterSound
	}
};

export const CUSTOMER_EVENT_NOTIFICATIONS = {
	message: {
		id: "message",
		title: `New Message from `,
		sound: notificationSound
	},
	userAssigned: {
		id: "user_assigned",
		title: `New Message from `,
		sound: notificationSound
	},
	dhCustomerWin: {
		id: "dh_customer_win",
		title: `Cha Ching!`,
		sound: cashRegisterSound
	}
};
