import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Alert from "../../components/common/Alert";
import withLocation from "../../components/common/WithLocation";
import Tabs from "../../components/common/Tabs";
import Tab from "../../components/common/Tab";
import LegacyPage from "../../components/common/LegacyPage";
import LandingPage from "../LandingPage/LandingPage";

import AnalyticsNps from "./AnalyticsNps";
import Suggestions from "./Suggestions";

import SupportChatService from "../../services/SupportChatService";
import LocationService from "../../services/LocationService";
import NotificationService from "../../services/NotificationService";

import { NPS_TABS } from "../../constants/NPS";

import "../../styles/css/scenes/scheduled-messages.css";

class NetPromoterScore extends Component {
	constructor(props) {
		super(props);

		let tab = this.props.location.pathname === NPS_TABS.analytics.route ? NPS_TABS.analytics.id : NPS_TABS.suggestions.id;

		this.state = {
			tab: tab,
			showBlockedAlert: false
		};
	}

	componentDidMount() {
		NotificationService.subscribe("onUrlChange", ({ location, action }) => {
			let tab = location.pathname === NPS_TABS.analytics.route ? NPS_TABS.analytics.id : NPS_TABS.suggestions.id;

			this.update({
				tab
			});
		});
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = async location => {
		// NPS is a bit glitchy since it's old. Use this
		// to prevent errors and still ahve children update on location change
		return;
	};

	onTabSelect = async tab => {
		await this.update({ tab: tab.id });
		this.props.history.push(NPS_TABS[tab.id].route);
	};

	onTriggerContactUsModal = () => {
		this.update({ showBlockedAlert: true });
	};

	onBlockedFeatureAlertClose = async confirm => {
		if (confirm) {
			SupportChatService.showNewMessage();
		}

		await this.update({
			showBlockedAlert: false
		});
	};

	render() {
		let { tab, showBlockedAlert } = this.state;
		let { t } = this.props;

		let isNpsEnabled = LocationService.isNpsPermissible();

		if (!isNpsEnabled) {
			return (
				<Page>
					<LandingPage
						title={t("Net Promoter Score")}
						text={t("Improve your business by gauging customer loyalty, enthusiasm, and satisfaction.")}
						imageUrl="https://cdn.demandhub.co/web-app/assets/nps-banner.svg"
						buttonText={t("Get Started")}
						onGetStartedClicked={this.onTriggerContactUsModal}
						beta={false}
					/>
					<Alert type="info" show={showBlockedAlert} title={t("Upgrade Subscription")} confirm={t("Chat with us")} onClose={this.onBlockedFeatureAlertClose}>
						{t("To enable Net Promoter Score features, please contact support@demandhub.co or chat with us.")}
					</Alert>
				</Page>
			);
		}

		if (!this.props.match.params.tab) {
			return <Redirect to={NPS_TABS.suggestions.route} />;
		}

		return (
			<Page>
				<Header title={t("Net Promoter Score")} />

				<Tabs onSelect={this.onTabSelect} selected={tab}>
					<Tab id={NPS_TABS.suggestions.id} value={NPS_TABS.suggestions.value} />
					<Tab id={NPS_TABS.analytics.id} value={NPS_TABS.analytics.value} />
				</Tabs>

				{NPS_TABS.analytics.id === tab && (
					<LegacyPage>
						<AnalyticsNps />
					</LegacyPage>
				)}
				{NPS_TABS.suggestions.id === tab && (
					<LegacyPage>
						<Suggestions />
					</LegacyPage>
				)}
			</Page>
		);
	}
}

export default withLocation(withTranslation(null, { withRef: true })(NetPromoterScore));
