import React, { Component } from "react";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import TemplateService from "../../services/TemplateService";
import LocationService from "../../services/LocationService";
import UtilityService from "../../services/UtilityService";

import MessageVariablesDropdown from "./MessageVariablesDropdown";
import MiniTemplateSelector from "./MiniTemplateSelector";
import DragAndDropOverlay from "./DragAndDropOverlay/DragAndDropOverlay";

import { MESSAGE_LENGTH_THRESHOLDS } from "../../constants/CommonConstants";
import { MEDIA_TYPES } from "../../constants/Messenger";

import "../../styles/css/components/commons/textarea.css";

class TextArea extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputSelect: 0,
			style: this.props.style ? this.props.style : {},
			type: this.props.type ? this.props.type : "text",
			id: this.props.id ? this.props.id : "dh-textarea",
			name: this.props.name ? this.props.name : "dh-textarea",
			className: this.props.className ? this.props.className : "form-control dh-textarea",
			rounded: this.props.rounded ? this.props.rounded : false,
			containerHeight: this.props.height || null,
			containerBorder: typeof this.props.containerBorder !== "undefined" ? this.props.containerBorder : true,
			greyDisabled: typeof this.props.greyDisabled !== "undefined" ? this.props.greyDisabled : true,
			borderColor: this.props.blueBorder === true ? "dh-textarea-container__blue" : "dh-textarea-container__green", //if false, the border will be green (from form-control)
			autoComplete: this.props.autoComplete ? this.props.autoComplete : "on",
			showFooter: typeof this.props.showFooter !== "undefined" ? this.props.showFooter : true,
			showMessageTemplates: typeof this.props.showMessageTemplates !== "undefined" ? this.props.showMessageTemplates : true,
			showFeedbackFaces: this.props.showFeedbackFaces ? this.props.showFeedbackFaces : false,
			showFeedbackLength: this.props.showFeedbackLength ? this.props.showFeedbackLength : false,
			showFeedbackDescriptionText: this.props.showFeedbackDescriptionText ? this.props.showFeedbackDescriptionText : false,
			showFeedbackDescriptionTooltip: typeof this.props.showFeedbackDescriptionTooltip !== "undefined" ? this.props.showFeedbackDescriptionTooltip : true,
			showScrollbar: this.props.showScrollbar ? "" : "dh-textarea-hide-scroll",
			lengthThresholds:
				this.props.lengthThresholds &&
				this.props.lengthThresholds.happy &&
				this.props.lengthThresholds.meh &&
				this.props.lengthThresholds.sad &&
				this.props.lengthThresholds.alert
					? this.props.lengthThresholds
					: {
							happy: {
								threshold: MESSAGE_LENGTH_THRESHOLDS.happy.threshold,
								icon: <Icon.Smile size="20" />,
								description: MESSAGE_LENGTH_THRESHOLDS.happy.description
							},
							meh: { threshold: MESSAGE_LENGTH_THRESHOLDS.meh.threshold, icon: <Icon.Meh size="20" />, description: MESSAGE_LENGTH_THRESHOLDS.meh.description },
							sad: {
								threshold: MESSAGE_LENGTH_THRESHOLDS.sad.threshold,
								icon: <Icon.Frown size="20" color="#ffc107" />,
								description: MESSAGE_LENGTH_THRESHOLDS.sad.description
							},
							alert: {
								threshold: MESSAGE_LENGTH_THRESHOLDS.alert.threshold,
								icon: <Icon.AlertTriangle size="20" color="#dc3545" />,
								description: MESSAGE_LENGTH_THRESHOLDS.alert.description
							}
					  },
			feedbackDescription: "",
			showVariables: this.props.showVariables ? this.props.showVariables : false,
			showVariableDropdown: false,
			varDropdownWidth: this.props.varDropdownWidth ? this.props.varDropdownWidth : null,
			variables: this.props.variables,
			customFields: [],
			required: typeof this.props.required !== "undefined" ? this.props.required : true
		};

		this.dragAndDropOverlay = null;
	}

	async componentDidMount() {
		this.textAreaRef.addEventListener("paste", this.onFilePaste, false);

		let { showCustomFields } = this.props;

		if (showCustomFields && LocationService.isCustomFieldsEnabled()) {
			let customFields = await LocationService.getCustomFields();
			customFields = customFields.map(cf => {
				return {
					id: cf.field,
					name: cf.name,
					pattern: `:${cf.field}:`
				};
			});
			await this.update({
				customFields
			});
		}

		if (!this.props.variables) {
			await this.update({
				variables: await TemplateService.getMessageVariablesForType()
			});
		}
	}

	componentWillUnmount() {
		this.textAreaRef.removeEventListener("paste", this.onFilePaste);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.variables !== this.props.variables) {
			this.setState({
				variables: this.props.variables
			});
		}
		if (prevProps.showFooter !== this.props.showFooter) {
			this.setState({
				showFooter: this.props.showFooter
			});
		}
		if (prevProps.showVariables !== this.props.showVariables) {
			this.setState({
				showVariables: this.props.showVariables
			});
		}
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onFileAdded = async files => {
		try {
			if (!this.props.attachments) {
				return;
			}

			if (files) {
				if (this.props.onFileAdded) {
					this.props.onFileAdded(files);
				}
			}
		} catch (error) {
			console.error(`TextArea.js - File Drag/Drop Error - ${error}`);
		}
	};

	onFilePaste = async event => {
		try {
			if (!this.props.attachments) {
				return;
			}
			let file = event.clipboardData.items[1].getAsFile();

			if (file) {
				if (this.props.onFileAdded) {
					this.props.onFileAdded(file);
				}
				event.preventDefault();
				event.stopPropagation();
			}
		} catch (error) {
			console.error(`TextArea.js - File Copy/Paste Error - ${error}`);
			try {
				let file = event.clipboardData.items[0].getAsFile();
				if (file) {
					if (this.props.onFileAdded) {
						this.props.onFileAdded(file);
					}
					event.preventDefault();
					event.stopPropagation();
				}
			} catch (error) {
				console.log(`TextArea.js - File Copy/Paste Error - ${error}`);
			}
		}
	};

	onFileUpload = event => {
		let file = event.target.files[0];

		if (file) {
			if (this.props.onFileAdded) {
				this.props.onFileAdded(file);
			}
		}
	};

	onAddAttachment = () => {
		if (this.dragAndDropOverlay) {
			this.dragAndDropOverlay.onClickOpen();
		}
	};

	onInputClick = event => {
		event.target.value = null;
	};

	toggleShowVariableDropdown = () => {
		this.setState({
			showVariableDropdown: !this.state.showVariableDropdown
		});
	};

	closeVariablesDropdown = () => {
		this.setState({
			showVariableDropdown: false
		});
	};

	onSelectVariable = text => {
		let { inputSelect } = this.state;
		let { value } = this.props;

		if (this.props.disabled) {
			this.toggleShowVariableDropdown();
			return;
		}
		if (this.props.onChange) {
			let start = value.substring(0, inputSelect);
			let end = value.substring(inputSelect, value.length);
			value = `${start} ${text} ${end}`;
			value = value.trim();
			let event = {
				target: {
					name: this.props.name,
					value: value
				}
			};
			this.props.onChange(event);
		}
		this.toggleShowVariableDropdown();
		if (this.textAreaRef) {
			this.textAreaRef.focus();
		}
	};

	onKeyDown(e) {
		if (this.props.onKeyDown) {
			this.props.onKeyDown(e);
		}
	}

	onSelect(e) {
		this.update({
			inputSelect: e.target.selectionStart
		});

		if (this.props.onSelect) {
			this.props.onSelect(e);
		}
	}

	onTemplateSelect = async template => {
		let messageInput = template.msg_text;
		let downloadUrl = null;
		let blob = null;

		if (template.Media && template.Media.length > 0) {
			// TODO: Clean up in DH 2404?
			let media = template.Media[0];

			if (media.type === MEDIA_TYPES.video || media.type === MEDIA_TYPES.file || media.type === MEDIA_TYPES.audio) {
				messageInput += messageInput.length > 0 ? ` ${media.short_url}` : media.short_url;
			} else if (media.type === MEDIA_TYPES.image) {
				downloadUrl = UtilityService.appendQueryAuthToken(media.download_url);
				let response = await fetch(downloadUrl, {
					method: "GET"
				});

				blob = await response.blob();
				blob.name = `${media.file_name}.${media.extension}`;
			}
		}

		let event = {
			target: {
				name: this.props.name,
				value: messageInput
			},
			templateId: template.id
		};
		this.props.onChange(event, blob);
	};

	getVariables = () => {
		let { variables, customFields } = this.state;

		if (this.props.hideCustomFields) {
			return variables;
		}

		return [...variables, ...customFields];
	};

	renderFaces(textLength) {
		let faces = this.state.lengthThresholds;
		let icon = "";
		if (textLength > faces.alert.threshold) {
			return faces.alert.icon;
		}
		for (const key in faces) {
			const face = faces[key];
			if (textLength < face.threshold) {
				icon = face.icon;
				break;
			}
		}
		return icon;
	}

	renderDescription(textLength) {
		let faces = this.state.lengthThresholds;
		let description = "";
		if (textLength > faces.alert.threshold) {
			return faces.alert.description;
		}
		for (const key in faces) {
			const face = faces[key];
			if (textLength < face.threshold) {
				description = face.description;
				break;
			}
		}
		return description;
	}

	renderTextArea = () => {
		const { value, onChange, rows, placeholder, disabled, attachments } = this.props;
		const {
			style,
			type,
			id,
			name,
			className,
			rounded,
			containerHeight,
			borderColor,
			autoComplete,
			showFeedbackFaces,
			showFeedbackLength,
			showScrollbar,
			showFeedbackDescriptionText,
			showFeedbackDescriptionTooltip,
			showVariables,
			showVariableDropdown,
			varDropdownWidth,
			containerBorder,
			greyDisabled,
			showFooter,
			showMessageTemplates,
			required
		} = this.state;

		let { varDropdownVisibleY, varDropdownVisibleX, varDropdownVisibleZ, t } = this.props;

		let containerClasses = `dh-textarea-container ${rounded ? "dh-textarea__rounded" : ""} ${containerBorder ? borderColor : ""} ${
			disabled && greyDisabled ? "dh-textarea-container__disabled" : ""
		}`;
		let textAreaClasses = `${className} ${showScrollbar}`;
		let textLength = value && value.length ? value.length : 0;
		let icon = this.renderFaces(textLength);
		let description = this.renderDescription(textLength);
		return (
			<>
				<div className={containerClasses} style={{ height: containerHeight }}>
					<textarea
						ref={ref => {
							if (this.props.setRef) {
								this.props.setRef(ref);
							}
							this.textAreaRef = ref;
						}}
						name={name}
						id={id}
						type={type}
						onChange={e => onChange(e)}
						value={value}
						className={textAreaClasses}
						required={required}
						rows={rows}
						style={style}
						autoComplete={autoComplete}
						disabled={disabled ? true : false}
						placeholder={placeholder ? placeholder : t("Enter a message...")}
						onKeyDown={e => this.onKeyDown(e)}
						onSelect={e => this.onSelect(e)}
					/>
					{/* showFooter can be used to overide */}
					{showFooter && (showFeedbackFaces || showFeedbackLength || showVariables || attachments || showMessageTemplates) && (
						<div className="dh-textarea-feedback-container">
							{(showVariables || showFeedbackLength || attachments || showMessageTemplates) && (
								<div className="dh-textarea-feedback-left-side">
									{showMessageTemplates && (
										<MiniTemplateSelector disabled={disabled} onSelect={this.onTemplateSelect} showEditAction={true} templateType={"general"} />
									)}

									{attachments && (
										<div className="dh-mts" onClick={this.onAddAttachment}>
											<div className="dh-mts__icon">
												<Icon.Paperclip size={16} />
											</div>
										</div>
									)}

									{showVariables && (
										<div
											id="fnctst-template-variables-open"
											className={`dh-textarea-feedback-btn ${showVariableDropdown ? "dh-textarea-feedback-btn--active" : ""}`}
											onMouseDown={this.toggleShowVariableDropdown}
										>
											:a:
										</div>
									)}
									{showFeedbackLength && <div className="dh-textarea-feedback-length">{textLength}</div>}
								</div>
							)}
							{showFeedbackFaces && !showFeedbackLength && !showVariables && <div>&nbsp;</div>}
							{showFeedbackFaces && (
								<div className="dh-textarea-feedback-container__warning">
									{showFeedbackDescriptionTooltip && description && description !== "" && <div>{description}</div>}
									<div className="dh-textarea-feedback-container__warning__icon">{icon}</div>
								</div>
							)}
						</div>
					)}
					{showVariableDropdown && (
						<MessageVariablesDropdown
							show={showVariableDropdown}
							onClose={this.closeVariablesDropdown}
							onSelect={this.onSelectVariable}
							visibleY={varDropdownVisibleY}
							visibleZ={varDropdownVisibleZ}
							visibleX={varDropdownVisibleX}
							width={varDropdownWidth}
							variables={this.getVariables()}
						/>
					)}
				</div>

				{showFeedbackDescriptionText && (
					<div>
						<div className="text-danger dh-textarea-feedback-description">{description}</div>
					</div>
				)}
			</>
		);
	};

	render() {
		const { attachments } = this.props;

		if (attachments) {
			return (
				<DragAndDropOverlay ref={ref => (this.dragAndDropOverlay = ref)} onFileAdded={this.onFileAdded}>
					{this.renderTextArea()}
				</DragAndDropOverlay>
			);
		}

		return this.renderTextArea();
	}
}

// export default withTranslation(null, { withRef: true })(TextArea);
export default withTranslation(null, { withRef: true })(TextArea);
