import React, { Component } from "react";
import "../../styles/css/scenes/manage-user-profile.css";

class Checkbox extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.id ? this.props.id : "",
			checked: this.props.checked,
			float: this.props.float ? this.props.float : "",
			name: this.props.name,
			display: this.props.display ? this.props.display : "",
			disabled: this.props.disabled || false,
			className: this.props.className || ""
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.checked === this.props.checked && prevProps.disabled === this.props.disabled) {
			return;
		}
		this.setState({ checked: this.props.checked, disabled: this.props.disabled });
	}

	handleChange = e => {
		let { disabled } = this.state;
		if (this.props.onChange && !disabled) {
			this.props.onChange(e);
		}
	};

	render() {
		const { disabled } = this.state;
		const { radio } = this.props;
		return (
			<label
				className={`checkbox-container ${disabled ? "checkbox-container-disabled" : ""} ${this.state.className}`}
				style={{ float: this.state.float, display: this.state.display }}
			>
				<input type="checkbox" id={this.state.id} name={this.props.name} checked={this.state.checked} onChange={this.handleChange} disabled={disabled} />
				<span className={`checkmark ${disabled ? "checkmark-disabled" : ""} ${radio ? "checkmark--radio" : ""}`} />
			</label>
		);
	}
}

export default Checkbox;
