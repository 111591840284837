import React from "react";
import "../../styles/css/components/commons/tabs.css";

const Tab = ({ id, value, selected, onSelect, routing }) => {
	let isSelected = id === selected;

	if (routing) {
		return (
			<div key={id} className={`dh-tab dh-tab--routing ${isSelected ? "dh-tab--selected" : ""}`} onClick={() => onSelect({ id, value })}>
				{value}
			</div>
		);
	}

	return (
		<div key={id} className={`dh-tab ${isSelected ? "dh-tab--selected" : ""}`} onClick={() => onSelect({ id, value })}>
			{value}
		</div>
	);
};

export default Tab;
