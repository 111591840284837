import React, { Component } from "react";

import Modal from "../../../../components/common/DHModal";

import InboxService from "../../../../services/InboxService";
import UserService from "../../../../services/UserService";
import ToastService from "../../../../services/ToastService";

import "./new-inbox-modal.css";

class NewInboxModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			inbox: null,
			loading: true,

			name: ""
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			await this.update({
				show,
				name: ""
			});
			this.nameInput.focus();
		}
	}

	saveInbox = async () => {
		let { name } = this.state;

		if (!this.isValid()) {
			return;
		}

		let locationId = await UserService.getActiveLocation().id;

		let inbox = await InboxService.createInbox({
			locationId,
			name,
			type: "general",
			isPublic: false,
			autoDefaultAssignment: false
		});

		if (!inbox) {
			ToastService.info("An error occurred trying to create a new inbox.");
			return;
		}

		ToastService.info("Successfully created inbox!");

		if (this.props.onClose) {
			this.props.onClose(inbox);
		}
	};

	isValid = () => {
		let { name } = this.state;
		return name.length > 0;
	};

	render() {
		let { show, name } = this.state;

		return (
			<Modal show={show} onHide={() => this.props.onClose()} title={"Create an inbox"}>
				<div className="new-inbox-modal">
					<input
						ref={ref => (this.nameInput = ref)}
						className="mb-input mb-input--small"
						id="inbox-name"
						type="text"
						autoComplete="off"
						value={name}
						onChange={e => this.update({ name: e.target.value })}
						placeholder="Leads, Support, Service, etc"
					/>
					<div className="new-inbox-modal__actions">
						<div className={`mb-button ${this.isValid() ? "" : "mb-button--disabled"}`} onClick={this.saveInbox}>
							Save
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

export default NewInboxModal;
