import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import config from "../../config/app/web-app.config";

import SupportChatService from "../../services/SupportChatService";
import UserService from "../../services/UserService";

import Menu from "../../components/common/Menu";
import MenuOption from "../../components/common/MenuOption";

import "../../styles/css/components/commons/help-menu.css";

class HelpMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onChatWithSupport = async () => {
		SupportChatService.showNewMessage();
	};

	onHelpCenter = async () => {
		let helpLink = config.HELP_CENTER;

		let user = UserService.get();
		if (user && user.help_link) {
			helpLink = user.help_link;
		}

		window.open(helpLink, "_blank");
	};

	renderMenuTrigger = () => {
		return (
			<div className="help-menu__trigger">
				<Icon.HelpCircle size="16" />
			</div>
		);
	};

	render() {
		let { t } = this.props;

		return (
			<Menu top={40} right={50} triggerComponent={this.renderMenuTrigger()}>
				<MenuOption icon={Icon.MessageCircle} onSelect={this.onChatWithSupport} title={t("Chat with Support")} />
				<MenuOption icon={Icon.HelpCircle} onSelect={this.onHelpCenter} title={t("Help Center")} />
			</Menu>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(HelpMenu));
