import React from "react";
import * as Icon from "react-feather";
import AsyncSelect from "react-select/lib/Async";
import debounce from "debounce-promise";
import { withTranslation } from "react-i18next";

import ToastService from "../../services/ToastService";
import GAService from "../../services/GAService";
import CompanyService from "../../services/CompanyService";
import TeamChatService from "../../services/TeamChatService";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import List from "../../components/common/List";
import Action from "../../components/common/Action";
import Filters from "../../components/common/Filters";
import Alert from "../../components/common/Alert";
import EditTeamChat from "../MessengerBeta/Thread/EditTeamChat/EditTeamChat";

import { SORT_ORDER, STATUS } from "../../constants/CommonConstants";
import { MANAGE_TEAMCHAT_COLUMNS, MANAGE_TEAMCHAT_TYPE_FILTERS } from "../../constants/Messenger";

import "./manage-teamchat.css";

const asyncSelectCustomStyles = {
	control: (provided, state) => {
		return {
			...provided,
			maxHeight: 150,
			overflow: "auto"
		};
	},
	group: (provided, state) => {
		return {
			...provided,
			height: 40
		};
	}
};

class ManageTeamChat extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			companies: [],
			selectedCompany: null,
			conversations: [],
			selectedConversation: null,
			sortField: MANAGE_TEAMCHAT_COLUMNS.name.sortField,
			sortOrder: SORT_ORDER.asc,
			selectedTypeFilter: MANAGE_TEAMCHAT_TYPE_FILTERS.channel,
			showEditTeamchatModal: false,
			showConfirmDeleteModal: false
		};

		// xxx - can't use lodash for asyncselect
		// https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
		this.onDebouncedSearchCompanies = debounce(this.fetchCompanies.bind(this), 1000);
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		this.fetchCompanies(this.state.searchTerm);
		this.fetchConversations();
	};

	fetchCompanies = async searchTerm => {
		let companies = await CompanyService.fetchCompanies({ searchTerm });
		companies = companies.map(c => {
			return { id: c.id, value: c.id, label: c.name };
		});

		await this.update({ companies });

		return companies;
	};

	fetchConversations = async () => {
		let { selectedTypeFilter, sortField, sortOrder, selectedCompany, loading } = this.state;
		let { t } = this.props;

		if (!selectedCompany) {
			return;
		}

		if (loading) {
			return;
		}

		await this.update({ loading: true });

		let conversations = await TeamChatService.fetchConversations({ companyId: selectedCompany.id, type: selectedTypeFilter.id, sortField, sortOrder });

		if (!conversations) {
			ToastService.info(t("Error fetching conversations. Please try again."));
			await this.update({ loading: false });
			return;
		}

		await this.update({ conversations, loading: false });
	};

	onConfirmDelete = async conversation => {
		this.update({ showConfirmDeleteModal: true, selectedConversation: conversation });
	};

	onDeleteConversation = async confirm => {
		let { selectedConversation } = this.state;
		let { t } = this.props;

		if (!confirm) {
			this.update({ showConfirmDeleteModal: false, selectedConversation: null });
			return;
		}

		let success = await TeamChatService.archiveConversation({ conversationId: selectedConversation.id });

		if (!success) {
			ToastService.error(t("Error archiving the conversation. Please try again."));
			this.update({ showConfirmDeleteModal: false, selectedConversation: null });
			return;
		}

		ToastService.info(t("Archived conversation."));
		this.fetchConversations();

		this.update({ showConfirmDeleteModal: false, selectedConversation: null });
	};

	onCompanyChange = async selectedCompany => {
		await this.update({ selectedCompany });
		this.fetchConversations();
	};

	onEditTeamChatClose = async () => {
		await this.update({ showEditTeamchatModal: false });
	};

	onFilterSelected = async item => {
		await this.update({ selectedTypeFilter: item });
		await this.fetchConversations();
	};

	isFilterSelected = item => {
		return item === this.state.selectedTypeFilter.id;
	};

	getFilters() {
		let { t } = this.props;

		let filterItems = Object.keys(MANAGE_TEAMCHAT_TYPE_FILTERS)
			.map(item => {
				return { id: item, value: MANAGE_TEAMCHAT_TYPE_FILTERS[item].display, order: MANAGE_TEAMCHAT_TYPE_FILTERS[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let filters = {
			filters: {
				title: t("Status"),
				items: filterItems,
				onClick: this.onFilterSelected,
				isSelected: this.isFilterSelected
			}
		};
		return filters;
	}

	onLoadMore = async () => {};

	onRecordClicked = async item => {
		// nothing for now
	};

	getHeaders = () => {
		let headers = {
			items: MANAGE_TEAMCHAT_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ sortField, sortOrder });
		await this.fetchConversations();
	};

	renderRecord = recordData => {
		let { t } = this.props;

		return [
			recordData.name,
			recordData.Users ? recordData.Users.length : 0,
			<div className="dh-list__actions">
				<Action
					key={`edit-${recordData.id}`}
					id={`edit-${recordData.id}`}
					label={t("Edit")}
					icon={Icon.Edit}
					onClick={() => this.update({ showEditTeamchatModal: true, selectedConversation: recordData })}
				/>
				<Action
					key={`delete-${recordData.id}`}
					id={`delete-${recordData.id}`}
					label={t("Delete")}
					icon={Icon.Trash2}
					onClick={() => this.onConfirmDelete(recordData)}
				/>
			</div>
		];
	};

	render = () => {
		let { loading, conversations, selectedCompany, selectedConversation, sortField, sortOrder, showEditTeamchatModal, showConfirmDeleteModal } = this.state;
		let { t } = this.props;

		return (
			<Page>
				<Header title={t("Manage Teamchat")}>{/* Actions */}</Header>

				<div className="manage-tc__search">
					<AsyncSelect
						styles={asyncSelectCustomStyles}
						isDisabled={false}
						isSearchable={true}
						isClearable={true}
						className="sm-modal__search"
						placeholder={t("Select a Company...")}
						loadOptions={this.onDebouncedSearchCompanies}
						value={selectedCompany ? selectedCompany : null}
						onChange={this.onCompanyChange}
					/>
				</div>

				<Filters filters={this.getFilters()} />

				{selectedCompany && conversations.length > 0 && (
					<List
						items={conversations}
						loading={loading}
						loadedAll={true}
						sortField={sortField}
						sortOrder={sortOrder}
						headers={this.getHeaders()}
						renderRecord={this.renderRecord}
						onRecordClicked={this.onRecordClicked}
						onLoadMore={this.onLoadMore}
						noDataTitle={t("No Conversations Found")}
						noDataIcon={<Icon.AlertCircle />}
					/>
				)}

				<EditTeamChat
					show={showEditTeamchatModal}
					conversationId={selectedConversation ? selectedConversation.id : null}
					onClose={this.onEditTeamChatClose}
					manageMode={true}
				/>

				<Alert type="warning" show={showConfirmDeleteModal} title={t("Please confirm")} confirm={t("Yes")} cancel={t("No")} onClose={this.onDeleteConversation}>
					<div>{t("Are you sure you would like to archive the conversation?")}</div>
				</Alert>
			</Page>
		);
	};
}

export default withTranslation(null, { withRef: true })(ManageTeamChat);
