import React from "react";
import ContentLoader from "react-content-loader";
import Select from "react-select";
import queryString from "query-string";

import CrmIntegrationsService from "../../services/CrmIntegrationsService";
import ToastService from "../../services/ToastService";
import UserService from "../../services/UserService";

import Input from "../../components/common/Input";
import Modal from "../../components/common/DHModal";
import Alert from "../../components/common/Alert";

import { STATUS } from "../../constants/CommonConstants";
import { CRM_INTEGRATIONS } from "../../constants/CRMIntegration";

class ConnectClioModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			testingConnection: false,

			selectedIntegration: null,
			selectableIntegrations: [],
			integration: null,
			locations: [],

			isAuthorized: false,

			clientId: "",
			clientSecret: "",
			code: null,

			// alerts
			showAlertFailedToAuthorize: false,
			showAlertIntegrationNotFound: false,
			showAlertTestFailed: false,
			showAlertConnectionComplete: false,
			showDisconnectModal: false
		};
	}

	componentDidMount = async () => {
		await this.resetComponent();

		// Only on component did mount
		const search = window.location.search;

		// Check for clio state
		if (search) {
			let params = new URLSearchParams(search);
			const code = params.get("code");
			const state = params.get("state");
			// If there is a code in the url params
			if (code && code.length > 0 && state && state === "clio") {
				await this.update({ code: code });
			}
		}

		if (this.state.code) {
			window.history.replaceState({}, document.title, "/settings/connections?type=clio");

			await this.fetchIntegrations();

			await this.onIntegrationSelected(this.state.selectableIntegrations[0].value);

			await this.authorizeConnection(this.state.code);
		}
	};

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({
			loading: false,
			integration: null,
			code: null,
			locations: [],

			clientId: "",
			clientSecret: ""
		});

		let { show } = this.props;

		// If we are not showing the dialog, don't fetch anything
		if (!show) {
			return;
		}

		let { integrationId } = this.props;

		if (!integrationId) {
			return;
		}

		await this.fetchIntegrations();
		await this.onIntegrationSelected();
	};

	onClickDisconnect = () => {
		this.update({ showDisconnectModal: true });
	};

	disconnectIntegration = async confirm => {
		if (!confirm) {
			await this.update({ showDisconnectModal: false });
			return;
		}

		if (this.props.disconnectIntegration) {
			this.props.disconnectIntegration();
		}

		await this.update({ showDisconnectModal: false });
	};

	fetchIntegrations = async () => {
		let integrations = await CrmIntegrationsService.fetch(UserService.getActiveLocation().id, {
			type: CRM_INTEGRATIONS.type.clio.name,
			status: [STATUS.active]
		});

		if (!integrations) {
			await this.update({
				selectableIntegrations: []
			});
			return;
		}

		let selectableIntegrations = integrations.map(i => {
			return { value: i.id, label: i.name ? i.name : CRM_INTEGRATIONS[i.crm_type].displayName };
		});

		await this.update({
			selectableIntegrations
		});
	};

	onIntegrationSelected = async integrationOption => {
		let integrationId = null;

		if (integrationOption) {
			integrationId = integrationOption.value;
		}

		if (!integrationId) {
			// Default to the passed in integration id
			integrationId = this.props.integrationId;
		}

		await this.update({ loading: true });
		try {
			let integration = await CrmIntegrationsService.fetchRestIntegration(integrationId);

			if (!integration || integration.status === STATUS.deleted) {
				// Show integration not found dialog
				await this.update({
					loading: false,
					showAlertIntegrationNotFound: true
				});

				return;
			}

			let clientId = "";
			let clientSecret = "";
			if (integration && integration.meta_data && integration.meta_data.clientId && integration.meta_data.clientSecret) {
				clientId = integration.meta_data.clientId;
				clientSecret = integration.meta_data.clientSecret;
			}

			await this.update({
				integration,
				clientId,
				clientSecret,
				isAuthorized: integration.meta_data.accessToken && integration.meta_data.refreshToken,
				selectedIntegration: { value: integration.id, label: integration.name ? integration.name : CRM_INTEGRATIONS[integration.crm_type].displayName }
			});
		} catch (error) {
			console.log(error);
		}
		await this.update({ loading: false });
	};

	onCancel = async event => {
		let { onHide } = this.props;
		let { loading } = this.state;

		if (loading) {
			return;
		}

		await this.update({ showLocationSelector: false });

		if (onHide) {
			onHide();
		}
	};

	onOpenClio = async () => {
		let { clientId, clientSecret, integration, loading, selectedIntegration } = this.state;

		if (loading) {
			return;
		}

		let metadata = integration.meta_data;
		metadata.clientId = clientId;
		metadata.clientSecret = clientSecret;
		metadata = JSON.stringify(metadata);

		await CrmIntegrationsService.update({
			id: selectedIntegration.value,
			crmType: integration.crm_type,
			crmVersion: integration.crm_version,
			integrationType: integration.integration_type,
			status: integration.status,
			direction: integration.direction,
			metadata
		});

		let savedUrl = `${window.location.href}/`;

		let url = `https://app.clio.com/oauth/authorize?response_type=code&client_id=${clientId}&state=clio&redirect_on_decline=true&redirect_uri=${encodeURI(
			savedUrl
		)}`;

		window.open(url, "_self");
	};

	authorizeConnection = async code => {
		await this.update({ loading: true, testingConnection: true });

		let testResponse = await this.testConnection(code);

		if (testResponse && testResponse.status) {
			ToastService.info(`Connection Successful!`);
			await this.update({ isAuthorized: true, showAlertFailedToAuthorize: false, showAlertConnectionComplete: true });
			window.history.replaceState({}, document.title, "/settings/connections?type=clio&success=true");
		} else {
			ToastService.info("Connection Failed.");
			await this.update({ isAuthorized: false, showAlertFailedToAuthorize: true, showAlertConnectionComplete: true });
			window.history.replaceState({}, document.title, "/settings/connections?type=clio&success=false");
		}

		await this.update({ loading: false, testingConnection: false, lastTestStatus: testResponse.status });
	};

	onTestConnection = async () => {
		let { loading } = this.state;

		if (loading) {
			return;
		}

		await this.update({ loading: true, testingConnection: true });

		let testResponse = await this.testConnection();

		if (testResponse.status) {
			ToastService.info(`Connection Successful!`);
			await this.update({ isAuthorized: true, showAlertTestFailed: false, showAlertConnectionComplete: true });
		} else {
			ToastService.info("Connection Failed.");
			await this.update({ isAuthorized: false, showAlertTestFailed: true });
		}

		await this.update({ loading: false, testingConnection: false, lastTestStatus: testResponse.status });
	};

	testConnection = async code => {
		let { selectedIntegration } = this.state;

		if (!selectedIntegration) {
			return;
		}

		let { clientId, clientSecret } = this.state;

		if (!clientId || !clientSecret) {
			ToastService.info("Cannot authorize. Client Id and Secret required.");
			return;
		}

		let data = {
			integrationId: selectedIntegration.value,
			login: clientId,
			password: clientSecret
		};

		if (code) {
			data.code = code;
		}

		let testResponse = await CrmIntegrationsService.testRestIntegration(data);
		return testResponse;
	};

	onInputChange = async event => {
		await this.update({ [event.target.name]: event.target.value });
	};

	onConfirmConnectionComplete = async event => {
		let { onHide } = this.props;

		// If we get here, we're all done
		await this.update({ loading: false, showAlertConnectionComplete: false });

		window.history.replaceState({}, document.title, "/settings/connections");

		if (onHide) {
			onHide();
		}
	};

	renderConnectButton = () => {
		let { isAuthorized, clientId, clientSecret } = this.state;

		let isDisabled = true;

		if (clientId && clientId.length > 0 && clientSecret && clientSecret.length > 0) {
			isDisabled = false;
		}

		return (
			<div className={`mb-button ${isDisabled ? "mb-button--disabled" : ""}`} onClick={this.onOpenClio}>
				{isAuthorized ? "Re-connect" : "Connect"} to Clio
			</div>
		);
	};

	renderTestConnectionButton = () => {
		let { clientId, clientSecret } = this.state;

		let isDisabled = true;

		if (clientId && clientId.length > 0 && clientSecret && clientSecret.length > 0) {
			isDisabled = false;
		}

		return (
			<div className={`mb-button ${isDisabled ? "mb-button--disabled" : ""}`} onClick={this.onTestConnection}>
				Test Connection
			</div>
		);
	};

	renderAlerts = () => {
		let { showAlertFailedToAuthorize, showAlertIntegrationNotFound, showAlertTestFailed, showAlertConnectionComplete, showDisconnectModal } = this.state;

		return (
			<>
				<Alert
					type="error"
					show={showAlertIntegrationNotFound}
					title="Not Found"
					confirm="OK"
					onClose={() => this.update({ showAlertIntegrationNotFound: false })}
				>
					The Clio integration was not found.
				</Alert>
				<Alert
					type="error"
					show={showAlertFailedToAuthorize}
					title="Failed To Authorize"
					confirm="OK"
					onClose={() => this.update({ showAlertFailedToAuthorize: false })}
				>
					Unable to authorize. Please verify credentials and try again.
				</Alert>
				<Alert type="error" show={showAlertTestFailed} title="Connection Test Failed" confirm="OK" onClose={() => this.update({ showAlertTestFailed: false })}>
					The connection failed. Please verify credentials and try again.
				</Alert>
				<Alert type="success" show={showAlertConnectionComplete} title="Connection Complete" confirm="OK" onClose={this.onConfirmConnectionComplete}>
					The Clio connection is all set up.
				</Alert>
				<Alert type="info" show={showDisconnectModal} title={"Disconnect"} confirm={"Yes"} cancel={"No"} onClose={this.disconnectIntegration}>
					<div>{`Are you sure you would like to disconnect the integration?`}</div>
				</Alert>
			</>
		);
	};

	renderForm = () => {
		let { integrationId } = this.props;

		let {
			isAuthorized,
			clientId,
			clientSecret,
			loading,

			selectedIntegration,
			selectableIntegrations
		} = this.state;

		if (loading) {
			return null;
		}

		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();
		let showDisconnectButton = integrationId && isSuperOrCs;

		return (
			<>
				{selectableIntegrations.length > 1 && selectedIntegration && (
					<div className="Connections-modal__select">
						<div>Integration</div>
						<Select
							id={`selectable-integrations`}
							options={selectableIntegrations}
							value={selectedIntegration}
							onChange={this.onIntegrationSelected}
							placeholder={`${CRM_INTEGRATIONS.type.clio.displayName} Integration`}
						/>
					</div>
				)}
				<div className="Connections-modal__input">
					<Input label="Client Id" name="clientId" id="client-id" type="text" onChange={this.onInputChange} value={clientId} required />
				</div>
				<div className="Connections-modal__input">
					<Input label="Client Secret" name="clientSecret" id="client-secret" type="text" onChange={this.onInputChange} value={clientSecret} required />
				</div>
				<div className="modal__actions">
					<button id="clio-cancel" className="mb-button mb-button--cancel" onClick={this.onCancel}>
						Cancel
					</button>
					{showDisconnectButton && (
						<button
							id="clio-disconnect"
							className={`mb-button mb-button--cancel ${this.state.loading ? "mb-button--disabled" : ""}`}
							disabled={this.state.loading}
							onClick={this.onClickDisconnect}
						>
							Disconnect
						</button>
					)}
					{this.renderConnectButton()}
					{this.renderTestConnectionButton()}
				</div>
			</>
		);
	};

	renderLoading = () => {
		let { loading, testingConnection } = this.state;

		if (!loading) {
			return null;
		}

		if (testingConnection) {
			return (
				<div className="modal__flex-container">
					<h2 className="text-center">Testing Connection...</h2>
					<ContentLoader viewBox="0 0 100% 300" height={160} width={"100%"}>
						<rect x="0" y="0" rx="5" ry="5" width="100%" height="40" />
						<rect x="0" y="50" rx="5" ry="5" width="100%" height="40" />
						<rect x="0" y="100" rx="5" ry="5" width="100%" height="40" />
					</ContentLoader>
				</div>
			);
		}

		return (
			<div className="modal__flex-container">
				<h2 className="text-center">Loading...</h2>
				<ContentLoader viewBox="0 0 100% 300" height={160} width={"100%"}>
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="40" />
					<rect x="0" y="50" rx="5" ry="5" width="100%" height="40" />
					<rect x="0" y="100" rx="5" ry="5" width="100%" height="40" />
				</ContentLoader>
			</div>
		);
	};

	render = () => {
		let { show } = this.props;
		return (
			<Modal title="Connect to Clio" show={show} onHide={this.onCancel}>
				{this.renderForm()}
				{this.renderLoading()}
				{this.renderAlerts()}
			</Modal>
		);
	};
}

export default ConnectClioModal;
