import React, { Component } from "react";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import LocationService from "../../services/LocationService";

class AddressAutocomplete extends Component {
	constructor(props) {
		super(props);

		this.state = {
			options: []
		};

		this.onDebouncedInputChange = _.debounce(
			value => {
				this.onInputChange(value);
			},
			1000,
			{
				leading: true,
				trailing: true
			}
		);
	}

	valueRenderer(option) {
		return <span>{option.label.split(", ")[0]}</span>;
	}

	onInputChange = async input => {
		if (input.length === 0) {
			this.setState({ options: [] });
			return;
		}

		this.setState({
			options: await LocationService.fetchGooglePlaceRecommendations(input)
		});
	};

	render() {
		const { value, onChange, required, id, t } = this.props;
		const { options } = this.state;
		return (
			<Select
				id={id}
				options={options}
				onChange={onChange}
				onInputChange={this.onDebouncedInputChange}
				value={value}
				focusedOption={value}
				placeholder={t("Please enter address")}
				required={required || false}
				valueRenderer={this.valueRenderer}
			/>
		);
	}
}
export default withTranslation(null, { withRef: true })(AddressAutocomplete);
