import React, { PureComponent } from "react";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";

import "../../styles/css/scenes/manage-user-profile.css";

class ListHeader extends PureComponent {
	render() {
		const { columns, headers, resolveWidth, renderSort } = this.props;
		return (
			<div className="dh-list-record dh-list-header">
				{columns.map(header => (
					<React.Fragment key={header.id}>
						<div
							id={header.id}
							key={header.id}
							className={`dh-list-record__cell ${resolveWidth(header)} ${header.sortable ? "" : "not-sortable"}`}
							onClick={() => {
								if (header.sortable && headers.sortBy) {
									headers.sortBy(header.sortField);
								}
							}}
							data-tip={header.tooltip ? true : null}
							data-for={header.tooltip ? `list-header__${header.value}__rtt` : null}
						>
							{header.value} {header.tooltip ? <Icon.Info size="14" /> : null} {header.sortable ? renderSort(header.sortField) : ""}
							{header.tooltip && header.tooltip.length > 0 && (
								<ReactTooltip
									id={`list-header__${header.value}__rtt`}
									className="mb-react-tooltip mb-react-tooltip--medium text-left"
									arrowColor="#333"
									type="info"
									effect="solid"
									place="top"
								>
									{header.tooltip}
								</ReactTooltip>
							)}
						</div>
					</React.Fragment>
				))}
			</div>
		);
	}
}

export default ListHeader;
