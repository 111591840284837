import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import ReactSwitch from "react-switch";
import posed, { PoseGroup } from "react-pose";
import ContentLoader from "react-content-loader";

import { WIDGET_NAME } from "../../../constants/WidgetConstants";

import withLocation from "../../../components/common/WithLocation";

import WidgetConfigService from "../../../services/WidgetConfigService";
import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";

import "react-toastify/dist/ReactToastify.css";
import "../../../styles/css/scenes/embedded-reviews-config.css";
import UnsavedChanges from "../../../components/common/UnsavedChanges";
import LocationService from "../../../services/LocationService";

export const Field = posed.div({
	enter: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 200
		}
	},
	exit: {
		y: 0,
		x: 0,
		opacity: 0,
		transition: {
			duration: 100
		}
	}
});

class EmbeddedReviewsConfig extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// Widget Data
			isActive: false,

			// Regular state keys
			widgetId: null,
			changesMade: false,
			loading: true
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = async () => {
		await this.update({ loading: true });
		await this.resetComponent();
		await this.update({ loading: false });
	};

	async componentDidMount() {
		await this.update({ loading: true });
		await this.resetComponent();
		await this.update({ loading: false });
	}

	async resetComponent() {
		try {
			let location = UserService.getActiveLocation();

			let widget = await WidgetConfigService.findWidget({
				locationId: location.id,
				name: WIDGET_NAME.embeddedBookingWidget
			});

			await this.update({
				isActive: widget.status === "active",

				// Regular state keys
				widgetId: widget.id,
				changesMade: false
			});
		} catch (error) {
			console.log(error);
		}
	}

	onSave = async () => {
		const {
			isActive,

			// Regular state keys
			widgetId
		} = this.state;

		let config = {};

		let newStatus = isActive ? "active" : "inactive";

		try {
			await WidgetConfigService.updateWidget({ widgetId, config, status: newStatus });

			await this.resetComponent();

			ToastService.info("Embedded Reviews Config has been updated!");
		} catch (error) {
			console.log(error);
			ToastService.error("Error updating the config!");
		}
	};

	Switch = ({ field, checked, onChange }) => {
		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		return (
			<ReactSwitch
				id={field}
				height={22}
				width={38}
				checked={checked}
				uncheckedIcon={false}
				checkedIcon={false}
				onColor="#60A9FF"
				offColor="#c5c5c5"
				onChange={newValue => {
					onChange(field, newValue);
				}}
				disabled={!allowUpdate}
			/>
		);
	};

	onCancel = () => {
		this.resetComponent();
	};

	onChange = (field, checked) => {
		this.update({
			[field]: checked,
			changesMade: true
		});
	};

	renderContentLoader() {
		let renderField = multiplier => {
			let offset = multiplier * 120;

			return (
				<>
					<rect x="30" y={20 + offset} rx="0" ry="0" width="150" height="10" />
					<rect x="30" y={40 + offset} rx="0" ry="0" width="350" height="10" />
					<rect x="500" y={20 + offset} rx="0" ry="0" width="40" height="20" />
					<rect x="30" y={100 + offset} rx="0" ry="0" width="550" height="2" />
				</>
			);
		};

		return (
			<ContentLoader speed={2} width={1000} height={460} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
				{renderField(0)}
				{renderField(1)}
				{renderField(2)}
				{renderField(3)}
				{renderField(4)}
				<rect x="600" y="20" rx="0" ry="0" width="300" height="250" />
			</ContentLoader>
		);
	}

	render() {
		const {
			// Status indicator for webchat
			isActive,

			// Regular state keys
			changesMade,
			loading
		} = this.state;

		let hideSave = !changesMade;

		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_widgets;

		if (!LocationService.isEmbeddedReviewsWidgetPermissible()) {
			return <Redirect to="/settings" />;
		}

		if (loading) {
			return this.renderContentLoader();
		}

		return (
			<div className="erc">
				<div className="erc__options">
					<PoseGroup>
						{isSuperOrCs && (
							<Field key="isActive" className="erc__options__field">
								<div className="erc__options__field__label">
									<div className="erc__options__field__label__title">Embedded Reviews</div>
									<div className="erc__options__field__label__description">
										Enable Embedded Reviews so your visitors can see your business reviews embedded in a section on your website.
									</div>
								</div>

								<this.Switch field="isActive" checked={isActive} onChange={this.onChange} />
							</Field>
						)}
						<Field key="code" className="erc__options__field erc__options__field--medium">
							<div className="erc__options__field__label">
								<div className="erc__options__field__label__title">Widget Code</div>
								<div className="erc__options__field__label__description">
									To add embedded reviews widget script, add the following code in desired section of the {"<body>"} tag (of that page).
									<br />
									To add the embedded reviews widget to other pages, follow the same steps.
									<br /> <br />
									{'<div id="demandhub-embedded-reviews-widget-root" />'}
								</div>
							</div>
						</Field>
					</PoseGroup>
					<PoseGroup>
						{!hideSave && allowUpdate && (
							<Field key="updateChanges" className="erc__options__save-actions">
								<div className="mb-button" onClick={this.onSave}>
									Save
								</div>
								<div className="mb-button mb-button--cancel" onClick={this.onCancel}>
									Cancel
								</div>
							</Field>
						)}
					</PoseGroup>
				</div>
				{changesMade && <UnsavedChanges />}
			</div>
		);
	}
}

export default withRouter(withLocation(EmbeddedReviewsConfig));
