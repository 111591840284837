import Kichiri from "./KichiriService";
import UserService from "./UserService";

export default {
	/**
	 * Get all subordinate groups for input groupId, if the group id is a system level GroupPermission then return all the group permissions for the specified company
	 *
	 * @returns {Object}
	 */
	async fetchSubordinateAccounts() {
		try {
			let user = UserService.get();
			let companyId = UserService.getActiveCompany().id;

			let response = await Kichiri.user.getSubordinateAccounts({}, { companyId }, user.auth_token);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch group permissions for the currently active company
	 * @returns
	 */
	async fetchGroupPermissions() {
		try {
			let companyId = UserService.getActiveCompany().id;

			let response = await Kichiri.company.getGroupPermissions({ companyId }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch group permission meta data
	 * @returns {Object}
	 */
	async fetchGroupPermissionInfo() {
		try {
			let response = await Kichiri.company.getGroupPermissionInfo({}, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Update a group permission
	 * @param {Object} groupPermission id and type required, other group permission fields optional
	 * @returns {Boolean}
	 */
	async updateGroupPermission(groupPermission) {
		try {
			groupPermission.groupPermissionId = groupPermission.id;
			await Kichiri.company.updateGroupPermission(groupPermission, {}, UserService.getAuthToken());

			return true;
		} catch (error) {
			console.log(error);
		}
		return false;
	}
};
