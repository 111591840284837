import React, { Component } from "react";
import Select from "react-select";
import * as Icon from "react-feather";
import ReactSwitch from "react-switch";
import moment from "moment";
import ContentLoader from "react-content-loader";
import _ from "lodash";
import isEmail from "validator/lib/isEmail";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";

import { EDIT_CONTACT_TABS, EDIT_CONTACT_ADD_NEW_MEDIUM_ERRORS } from "../../constants/Contacts";
import { STATE, MEDIUM, SWAPPABLE_MEDIUMS } from "../../constants/Messenger";
import { CODES_TO_LABEL } from "../../constants/LanguageConstants";
import { TAG_TYPES } from "../../constants/Tags";
import { GA_CATEGORIES, GA_ACTIONS } from "../../constants/GAConstants";
import { CUSTOM_FIELD_TYPES, CUSTOM_FIELD_MAX_FAVORITES } from "../../constants/CustomFields";

import GAService from "../../services/GAService";
import UserService from "../../services/UserService";
import InboxService from "../../services/InboxService";
import MessagesService from "../../services/MessagesService";
import ToastService from "../../services/ToastService";
import SupportChatService from "../../services/SupportChatService";
import ContactService from "../../services/ContactService";
import TagService from "../../services/TagService";
import NotificationService from "../../services/NotificationService";
import LocationService from "../../services/LocationService";
import UtilityService from "../../services/UtilityService";

import Modal from "./DHModal";
import Tabs from "./Tabs";
import Tab from "./Tab";
import Alert from "./Alert";
import LanguageDropdown from "./LanguageDropdown";
import ContactAppointments from "./ContactAppointments";
import MergeContactModal from "./MergeContactModal";
import Checkbox from "./Checkbox";
import DownloadConversationModal from "./DownloadConversationModal";

import CustomerMediaGrid from "../../scenes/MessengerBeta/Thread/Media/CustomerMediaGrid";

import UpdateTagsModal from "../../scenes/Tags/UpdateTagsModal";
import UpdateContactNotesModal from "../../scenes/Notes/UpdateContactNotesModal";

import "../../styles/css/components/commons/edit-contact-modal.css";

class EditContactModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			/* Modal related state data */
			selectedTab: null,
			loading: false,
			showAlertSave: false,
			showAlertCRMSend: false,
			showAlertContactBlock: false,
			showAlertContactDelete: false,
			showModalNotes: false,
			showAlertContactNotFound: false,
			showCreateTagModal: false,
			createTagType: TAG_TYPES.tag,

			/* Savable state data */
			contact: null,
			assignedTags: [],
			assignedGroups: [],

			/* State data used to fill inputs */
			assignableTags: [],
			assignableGroups: [],
			assignableUsers: [],
			assignableInboxes: [],
			assignableStatuses: [],
			assignablePreferredMediums: [],

			/* Relating to custom fields */
			assignedCustomFields: {},
			assignableCustomFields: [],
			favoriteCustomFields: {},
			canFavoriteCustomFields: false,

			/* Relating to notes */
			assignedNotes: [],
			noteUpdatingId: null,
			notesModalCreationMode: true,

			/* Relating to phone numbers */
			assignableNumbers: [],

			/* Relating to merging contact */
			showMergeContactModal: false,

			// Relating
			showDownloadConversationModal: false,

			/* Relating to adding medium data (Either a phone or email) */
			editingEmail: false,
			editingPhone: false,
			newMediumAdded: false,
			isNewMediumDataValid: false,
			isNewMediumDataErrorMessage: "",

			newMediumData: "",

			/* Dirty Bit */
			changesMade: false
		};

		this.deboundedOnCheckMediumData = _.debounce(
			async value => {
				await this.onCheckMediumData(value);
			},
			1000,
			{
				leading: false,
				trailing: true
			}
		);
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	resetComponent = async () => {
		let { contactId, show } = this.props;

		// If we are not showing the dialog, don't fetch anything
		if (!show || !contactId) {
			return;
		}

		// Set loading to true, and reset dirty bit
		await this.update({
			loading: true,
			changesMade: false,
			contact: null,

			editingEmail: false,
			editingPhone: false,
			newMediumAdded: false,
			isNewMediumDataValid: false,
			isNewMediumDataErrorMessage: ""
		});

		// Reset modal tab
		await this.tabReset();

		// Fetch the contact object from the backend
		let contact = await ContactService.getContact(contactId);

		if (!contact || contact.status === STATE.deleted) {
			// Show contact not found dialog
			await this.update({
				showAlertContactNotFound: true
			});

			return;
		}

		// Save this contact in our state
		await this.update({
			contact: contact
		});

		// Fetch the tags
		await this.fetchTags();

		// Fetch the groups
		await this.fetchGroups();

		// Fetch assignable users
		await this.fetchAssignableUsers();

		// Fetch assignable inboxes
		await this.fetchAssignableInboxes();

		// Fetch assignable statuses
		await this.fetchStatuses();

		// Fetch assignable mediums
		await this.fetchPreferredMediums();

		// Fetch notes
		await this.fetchNotes();

		// Fetch a location's custom fields and a contact's custom fields
		await this.fetchCustomFields();

		// Fetch assignable numbers
		await this.fetchAssignableNumbers();

		// Set loading to false
		await this.update({
			loading: false
		});
	};

	async componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	async fetchAssignableInboxes() {
		let { t } = this.props;
		let locationId = UserService.getActiveLocation().id;
		let userId = UserService.get().id;
		let { contact } = this.state;

		// Fetch inboxes for this location
		let inboxes = await InboxService.fetchInboxes(locationId, userId);
		if (!inboxes) {
			return;
		}

		// Create options for the inbox select
		let inboxesObject = inboxes.map(inbox => {
			return {
				value: inbox.id,
				label: inbox.name
			};
		});
		inboxesObject.push({
			label: t("Unassigned"),
			value: 0
		});

		// Get the currently assigned inbox id
		let currentlyAssignedInbox = contact.inbox_id;

		// Try to find the currently assigned inbox among the inboxes we are assigned to
		let userIsAssignedToInbox = inboxesObject.find(currentInbox => {
			if (currentInbox.value === currentlyAssignedInbox) {
				return currentInbox;
			} else if (currentInbox.value === 0 && currentlyAssignedInbox === null) {
				// if the inbox id is null, it is considered to be as "Unassigned"
				return currentInbox;
			} else {
				return null;
			}
		});

		// If the currently assigned inbox is not one that is assigned to us, then atleast add it as an option
		// in the select so the user can see what the currently assigned inbox name is
		if (!userIsAssignedToInbox) {
			// Declare a backup incase this inbox no longer exists (might be deleted)
			let currentlyAssignedInboxName = t("Unknown Inbox");

			// If that inbox exists, then use it's name as the label
			if (currentlyAssignedInbox) {
				// Get the name of the inbox the contact is currently assigned to
				let inbox = await InboxService.fetchInbox(currentlyAssignedInbox);

				if (inbox && inbox.name) {
					currentlyAssignedInboxName = inbox.name;
				}
			}

			// Add this inbox to the assignable options
			inboxesObject.push({
				label: currentlyAssignedInboxName,
				value: currentlyAssignedInbox
			});
		}

		// Update the list of inboxes we could assign to
		this.update({
			assignableInboxes: inboxesObject
		});
	}

	async fetchAssignableUsers() {
		let { t } = this.props;
		let locationId = UserService.getActiveLocation().id;

		// Fetch the list of users we could assign to
		let assignableUsers = await MessagesService.fetchAssignableUsers(locationId);
		if (!assignableUsers) {
			return;
		}

		// Create options for the assigned user select
		let assignableUserOptions = assignableUsers.map(user => {
			var fullName = UserService.createFullName({ firstName: user.first_name, lastName: user.last_name });
			return {
				label: fullName,
				value: user.id
			};
		});
		assignableUserOptions.push({
			label: t("Unassigned"),
			value: 0
		});

		// Update the list of users we could assign to
		this.update({
			assignableUsers: assignableUserOptions
		});
	}

	async fetchTags() {
		let { contactId } = this.props;

		// Get the tags for this contact/location
		let tagData = await TagService.getTagsForSelect(contactId, TAG_TYPES.tag);
		if (!tagData) {
			return;
		}

		// Update the tags we are assigned and can choose from
		this.update({
			assignableTags: tagData.optionTags,
			assignedTags: tagData.assignedTags
		});
	}

	async fetchGroups() {
		let { contactId } = this.props;

		// Get the groups for this contact/location
		let groupsData = await TagService.getTagsForSelect(contactId, TAG_TYPES.group);
		if (!groupsData) {
			return;
		}

		// Update the groups we are assigned and can choose from
		this.update({
			assignableGroups: groupsData.optionTags,
			assignedGroups: groupsData.assignedTags
		});
	}

	fetchCustomFields = async () => {
		let { contactId } = this.props;

		if (!LocationService.isCustomFieldsEnabled()) {
			return;
		}

		let assignableCustomFields = await LocationService.getCustomFields({ sortField: "name", sortOrder: "asc" });
		let customFields = await ContactService.getContactCustomFields({ contactId });
		let assignedCustomFields = {};
		let favoriteCustomFields = {};
		let canFavoriteCustomFields = false;

		if (customFields) {
			for (const customField of customFields) {
				let foundCF = assignableCustomFields.find(f => f.id === customField.custom_field_id);
				let type = CUSTOM_FIELD_TYPES.string;
				if (foundCF) {
					type = foundCF.type;
				}
				let value = customField.value;
				if (type === CUSTOM_FIELD_TYPES.boolean) {
					value = value === "1";
				}
				// Store the custom fields and their value
				assignedCustomFields[customField.custom_field_id] = value;
			}

			// Check if we can favorite other custom fields
			if (assignableCustomFields.filter(cf => cf.favorite).length < CUSTOM_FIELD_MAX_FAVORITES) {
				canFavoriteCustomFields = true;
			}
		}

		// Save all the favorited custom fields
		for (let i = 0; i < assignableCustomFields.length; i++) {
			const assignableCF = assignableCustomFields[i];
			if (assignableCF.favorite) {
				favoriteCustomFields[assignableCF.id] = true;
			}
		}

		this.update({
			assignableCustomFields,
			assignedCustomFields,
			favoriteCustomFields,
			canFavoriteCustomFields
		});
	};

	fetchAssignableNumbers = async () => {
		let locationId = UserService.getActiveLocation().id;
		let locationData = await LocationService.fetchLocation(locationId);
		let assignableNumbers = locationData.numbers;

		assignableNumbers = assignableNumbers.map(number => {
			return {
				label: number.number,
				value: number.number
			};
		});

		await this.update({
			assignableNumbers
		});
	};

	async fetchStatuses() {
		// Get the messenger states possible for a contact
		let statesAssignable = [];

		// Push each state in using Open/Closed as labels, and active/inactive as vals
		for (var key in STATE) {
			let statusName = key;
			let statusValue = STATE[key];

			// Don't include the deleted status
			if (statusName === "deleted") continue;

			// Create a status we can choose from
			statesAssignable.push({
				label: UtilityService.toTitleCase(statusName),
				value: statusValue
			});
		}

		// Update the statuses we can choose from
		this.update({
			assignableStatuses: statesAssignable
		});
	}

	async fetchPreferredMediums() {
		// Get the messenger states possible for a contact
		let mediumsAssignable = [];

		// Push each medium in
		for (var key in SWAPPABLE_MEDIUMS) {
			let mediumName = key;
			let mediumValue = SWAPPABLE_MEDIUMS[key];

			// Create a medium we can choose from
			mediumsAssignable.push({
				label: UtilityService.toTitleCase(mediumName),
				value: mediumValue
			});
		}

		// Update the mediums we can choose from
		await this.update({
			assignablePreferredMediums: mediumsAssignable
		});
	}

	async fetchNotes() {
		let { contactId } = this.props;

		// Fetch the notes for this contact (which are really just messages)
		let messages = await ContactService.fetchContactNotes({ contactId });

		// If no notes found
		if (!messages) {
			return;
		}

		// Create records we can use to display the notes
		let assignedNotes = messages.map(message => ({
			content: MessagesService.formatNote(message.content),
			sender_user_name: message.sender_user_name,
			created_at: message.created_at,
			id: message.id,
			contact_id: message.contact_id
		}));

		// Update the notes for this contact
		this.update({
			assignedNotes: assignedNotes
		});
	}

	// Change the tab we are one
	onTabSelect = async tab => {
		// If we're switching to the notes tab, let's get fresh notes
		if (tab.id === EDIT_CONTACT_TABS.notes) {
			// Wait to fetch notes
			await this.fetchNotes();
		}

		this.update({ selectedTab: tab.id });

		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.name,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
	};

	// Go back to the first tab
	tabReset = () => {
		this.update({ selectedTab: EDIT_CONTACT_TABS.basics });
	};

	// Opens intercom, duh!
	openIntercom = () => {
		SupportChatService.showNewMessage();

		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.name,
			action: GA_ACTIONS.generic.intercomOpen,
			label: GA_ACTIONS.generic.intercomOpen
		});
	};

	onViewConversation = () => {
		let { contact } = this.state;
		let location = UserService.getActiveLocation();

		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.sections.action,
			action: GA_ACTIONS.editContactModal.actionGoToMessenger,
			label: GA_ACTIONS.editContactModal.actionGoToMessenger
		});

		let url = `${window.location.origin}/messenger?contactId=${contact.public_id}&locationId=${location.public_id}`;
		window.location.href = url;
	};

	/*
		Methods related to dom on-changes
	*/

	// For <input> changes
	onChangeInput = event => {
		let { contact } = this.state;

		let contactKey = event.target.name;
		let contactValue = event.target.value;

		contact[contactKey] = contactValue;

		this.update({
			contact,
			changesMade: true
		});

		/* Analytics */
		if (contactKey === "name") {
			GAService.GAEvent({
				category: GA_CATEGORIES.editContactModal.sections.basics,
				action: GA_ACTIONS.editContactModal.changedName,
				label: GA_ACTIONS.editContactModal.changedName
			});
		}
	};

	// For <Select> changes
	onChangeSelect = (field_name, value) => {
		let { contact } = this.state;
		contact[field_name] = value;
		this.update({
			contact,
			changesMade: true
		});

		/* Analytics */
		if (field_name === "language") {
			GAService.GAEvent({
				category: GA_CATEGORIES.editContactModal.sections.basics,
				action: GA_ACTIONS.editContactModal.changedLanguage,
				label: GA_ACTIONS.editContactModal.changedLanguage
			});
		} else if (field_name === "status") {
			GAService.GAEvent({
				category: GA_CATEGORIES.editContactModal.sections.advanced,
				action: GA_ACTIONS.editContactModal.changedStatus,
				label: GA_ACTIONS.editContactModal.changedStatus
			});
		} else if (field_name === "assigned_user_id") {
			GAService.GAEvent({
				category: GA_CATEGORIES.editContactModal.sections.advanced,
				action: GA_ACTIONS.editContactModal.changedAssignedUser,
				label: GA_ACTIONS.editContactModal.changedAssignedUser
			});
		} else if (field_name === "inbox_id") {
			GAService.GAEvent({
				category: GA_CATEGORIES.editContactModal.sections.advanced,
				action: GA_ACTIONS.editContactModal.changedAssignedInbox,
				label: GA_ACTIONS.editContactModal.changedAssignedInbox
			});
		} else if (field_name === "preferred_medium") {
			GAService.GAEvent({
				category: GA_CATEGORIES.editContactModal.sections.advanced,
				action: GA_ACTIONS.editContactModal.changedPreferedMedium,
				label: GA_ACTIONS.editContactModal.changedPreferedMedium
			});
		}
	};

	// For <ReactSwitch> changes
	onChangeReactSwitch = (field_name, value) => {
		let { contact } = this.state;
		contact[field_name] = value;
		this.update({
			contact,
			changesMade: true
		});

		/* Analytics */
		if (field_name === "receive_transactional_emails") {
			if (value) {
				GAService.GAEvent({
					category: GA_CATEGORIES.editContactModal.sections.receive,
					action: GA_ACTIONS.editContactModal.turnedOnTransactionalEmail,
					label: GA_ACTIONS.editContactModal.turnedOnTransactionalEmail
				});
			} else {
				GAService.GAEvent({
					category: GA_CATEGORIES.editContactModal.sections.receive,
					action: GA_ACTIONS.editContactModal.turnedOffTransactionalEmail,
					label: GA_ACTIONS.editContactModal.turnedOffTransactionalEmail
				});
			}
		} else if (field_name === "receive_transactional_sms") {
			if (value) {
				GAService.GAEvent({
					category: GA_CATEGORIES.editContactModal.sections.receive,
					action: GA_ACTIONS.editContactModal.turnedOnTransactionalSms,
					label: GA_ACTIONS.editContactModal.turnedOnTransactionalSms
				});
			} else {
				GAService.GAEvent({
					category: GA_CATEGORIES.editContactModal.sections.receive,
					action: GA_ACTIONS.editContactModal.turnedOffTransactionalSms,
					label: GA_ACTIONS.editContactModal.turnedOffTransactionalSms
				});
			}
		} else if (field_name === "receive_marketing_emails") {
			if (value) {
				GAService.GAEvent({
					category: GA_CATEGORIES.editContactModal.sections.receive,
					action: GA_ACTIONS.editContactModal.turnedOnMarketingEmail,
					label: GA_ACTIONS.editContactModal.turnedOnMarketingEmail
				});
			} else {
				GAService.GAEvent({
					category: GA_CATEGORIES.editContactModal.sections.receive,
					action: GA_ACTIONS.editContactModal.turnedOffMarketingEmail,
					label: GA_ACTIONS.editContactModal.turnedOffMarketingEmail
				});
			}
		} else if (field_name === "receive_marketing_sms") {
			if (value) {
				GAService.GAEvent({
					category: GA_CATEGORIES.editContactModal.sections.receive,
					action: GA_ACTIONS.editContactModal.turnedOnMarketingSms,
					label: GA_ACTIONS.editContactModal.turnedOnMarketingSms
				});
			} else {
				GAService.GAEvent({
					category: GA_CATEGORIES.editContactModal.sections.receive,
					action: GA_ACTIONS.editContactModal.turnedOffMarketingSms,
					label: GA_ACTIONS.editContactModal.turnedOffMarketingSms
				});
			}
		} else if (field_name === "receive_feedback_emails") {
			if (value) {
				GAService.GAEvent({
					category: GA_CATEGORIES.editContactModal.sections.receive,
					action: GA_ACTIONS.editContactModal.turnedOnFeedbackEmail,
					label: GA_ACTIONS.editContactModal.turnedOnFeedbackEmail
				});
			} else {
				GAService.GAEvent({
					category: GA_CATEGORIES.editContactModal.sections.receive,
					action: GA_ACTIONS.editContactModal.turnedOffFeedbackEmail,
					label: GA_ACTIONS.editContactModal.turnedOffFeedbackEmail
				});
			}
		} else if (field_name === "receive_feedback_sms") {
			if (value) {
				GAService.GAEvent({
					category: GA_CATEGORIES.editContactModal.sections.receive,
					action: GA_ACTIONS.editContactModal.turnedOnFeedbackSms,
					label: GA_ACTIONS.editContactModal.turnedOnFeedbackSms
				});
			} else {
				GAService.GAEvent({
					category: GA_CATEGORIES.editContactModal.sections.receive,
					action: GA_ACTIONS.editContactModal.turnedOffFeedbackSms,
					label: GA_ACTIONS.editContactModal.turnedOffFeedbackSms
				});
			}
		}
	};

	onAssignedTagsChanged = assignedTags => {
		this.update({
			assignedTags,
			hangesMade: true
		});

		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.sections.receive,
			action: GA_ACTIONS.editContactModal.changedTags,
			label: GA_ACTIONS.editContactModal.changedTags
		});
	};

	onAssignedGroupsChanged = assignedGroups => {
		this.update({
			assignedGroups,
			changesMade: true
		});

		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.sections.receive,
			action: GA_ACTIONS.editContactModal.changedGroups,
			label: GA_ACTIONS.editContactModal.changedGroups
		});
	};

	// For custom field <input> changes
	onCustomFieldChange = (customFieldId, value) => {
		let { assignedCustomFields } = this.state;
		assignedCustomFields[customFieldId] = value;
		this.update({
			assignedCustomFields,
			changesMade: true
		});

		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.sections.receive,
			action: GA_ACTIONS.editContactModal.changedCustomField,
			label: GA_ACTIONS.editContactModal.changedCustomField
		});
	};

	onCustomFieldFavoriteToggle = async customFieldId => {
		let { t } = this.props;
		let user = UserService.get();
		if (!user.GroupPermission.update_custom_fields) {
			return;
		}

		let { contact, favoriteCustomFields, assignableCustomFields, assignedCustomFields } = this.state;

		// Find the custom field that we want to edit
		let cf = assignableCustomFields.find(f => f.id === customFieldId);

		// Copy object
		let customField = { ...cf, locationId: UserService.getActiveLocation().id };

		// Change the favorite status of this custom field
		if (favoriteCustomFields[customFieldId]) {
			customField.favorite = false;
		} else {
			customField.favorite = true;
		}

		let response = await LocationService.updateCustomField(customField);

		if (!response) {
			ToastService.error(t("Error favoriting field. Please try again."));
			return;
		}

		// Update the states as needed
		if (favoriteCustomFields[customFieldId]) {
			cf.favorite = false;
			delete favoriteCustomFields[customFieldId];
		} else {
			cf.favorite = true;
			favoriteCustomFields[customFieldId] = true;
			if (!assignedCustomFields[customFieldId]) {
				assignedCustomFields[customFieldId] = "";
			}
		}

		NotificationService.publish("customFieldsUpdated", contact.id);

		this.update({
			assignableCustomFields,
			assignedCustomFields,
			favoriteCustomFields,
			canFavoriteCustomFields: Object.keys(favoriteCustomFields).length < CUSTOM_FIELD_MAX_FAVORITES
		});
	};

	// If the contact is not found dialog closes
	onConfirmAlertContactNotFoundClose = confirm => {
		this.update({
			showAlertContactNotFound: false
		});

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onShowCreateTagModal = async tagType => {
		await this.update({ showCreateTagModal: true, createTagType: tagType });
	};

	onHideCreateTagModal = async () => {
		await this.update({ showCreateTagModal: false });
	};

	onShowMergeContactModal = async () => {
		await this.update({ showMergeContactModal: true });
	};

	onHideMergeContactModal = async () => {
		await this.update({ showMergeContactModal: false });
	};

	onMergedContact = async () => {
		this.onHideMergeContactModal();
		this.resetComponent();

		/* Analytics */
		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.sections.action,
			action: GA_ACTIONS.editContactModal.actionMergedContact,
			label: GA_ACTIONS.editContactModal.actionMergedContact
		});
	};

	onShowDownloadConversarionModal = async () => {
		await this.update({ showDownloadConversationModal: true });
	};

	onHideDownloadConversationModal = async () => {
		await this.update({ showDownloadConversationModal: false });
	};

	onCreateTagModalSubmit = async name => {
		let { t } = this.props;
		await this.update({ showCreateTagModal: false });

		let { createTagType } = this.state;
		let tagType = createTagType === TAG_TYPES.tag ? "tag" : "group";

		let locationId = UserService.getActiveLocation().id;

		let response = await TagService.createTag(locationId, name, tagType);

		if (!response) {
			ToastService.error(t("Error trying to create entity"));
			return;
		}

		ToastService.info(t("Successfully created entity"));

		if (createTagType === TAG_TYPES.tag) {
			await this.fetchTags();

			GAService.GAEvent({
				category: GA_CATEGORIES.editContactModal.sections.advanced,
				action: GA_ACTIONS.editContactModal.createdTag,
				label: GA_ACTIONS.editContactModal.createdTag
			});
		} else {
			await this.fetchGroups();

			GAService.GAEvent({
				category: GA_CATEGORIES.editContactModal.sections.advanced,
				action: GA_ACTIONS.editContactModal.createdGroup,
				label: GA_ACTIONS.editContactModal.createdGroup
			});
		}
	};

	/*
		Methods related to saving 
	*/

	onConfirmShowAlertDiscardChanges = confirm => {
		this.update({
			showAlertDiscardChanges: false
		});

		if (confirm) {
			this.onSave();
		}

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onClose = () => {
		let { changesMade } = this.state;

		// If we made changes, ask if they want to keep them
		if (changesMade) {
			this.update({
				showAlertDiscardChanges: true
			});
			return;
		}

		// If no changes were made, just tell the parent to close us
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onShowAlertSave = () => {
		this.update({
			showAlertSave: true
		});
	};

	onConfirmAlertSaveClose = confirm => {
		this.update({
			showAlertSave: false
		});

		if (confirm) {
			this.onSave();
		}
	};

	onSave = async () => {
		let { t } = this.props;

		/*
			Note: In terms of permissions, the front end components should be disabled.
			As a security measure, we double check permissions in the backend.
			We also do some permission checks here to save on doing backend calls.
		*/

		// Get basic savable attributes from the state
		let { contact, assignedTags, assignedGroups, assignedCustomFields, newMediumAdded } = this.state;

		// Get the user performing the save
		let user = UserService.get();

		// The big sledge hammer permission we need is to update contacts
		if (!user.GroupPermission.update_contacts) {
			ToastService.info(t("Invalid permissions to update contacts."));
			return;
		}

		// Grab the contact from the backend so we know what it looks like before the update
		let previousContact = await ContactService.getContact(contact.id);

		/*
			Step 1: Save primitive contact details
		*/

		let contactDetails = {
			userId: user.id,
			locationId: contact.location_id,
			contactId: contact.id,
			name: contact.name,
			status: contact.status,
			receiveMarketingEmails: contact.receive_marketing_emails,
			receiveMarketingSms: contact.receive_marketing_sms,
			receiveTransactionalEmails: contact.receive_transactional_emails,
			receiveTransactionalSms: contact.receive_transactional_sms,
			receiveFeedbackEmails: contact.receive_feedback_emails,
			receiveFeedbackSms: contact.receive_feedback_sms,
			language: contact.language,
			outNumber: contact.out_number,
			preferredMedium: contact.preferred_medium,
			phone: contact.phone,
			dob: contact.dob,
			isDischarged: contact.is_discharged,
			isDeceased: contact.is_deceased,

			homePhone: contact.home_phone,
			workPhone: contact.work_phone,
			address1: contact.address_1,
			address2: contact.address_2,
			city: contact.city,
			stateProv: contact.state_prov,
			zipPostal: contact.zip_postal,
			country: contact.country
		};

		// If a new medium was added
		if (newMediumAdded) {
			if (contact.email && contact.email.length > 0) {
				contactDetails.email = contact.email;
			}
			if (contact.phone && contact.phone.length > 0) {
				contactDetails.phone = contact.phone;
			}
			await this.update({ newMediumAdded: false });
		}

		// Only allow updating the assigned user if we have the permission
		if (user.GroupPermission.assign_customer_messages) {
			contactDetails.assignedUserId = contact.assigned_user_id;
		}

		// Only allow updating the assigned inbox if we have the permission
		if (user.GroupPermission.update_inboxes) {
			contactDetails.inboxId = contact.inbox_id;
		}

		// Update the contact
		let freshContact = await ContactService.updateContact(contactDetails);
		if (!freshContact) {
			ToastService.info(t("Failed to update contact details."));
			return;
		}

		/*
			Step 2: Save groups and tags
		*/

		if (user.GroupPermission.update_tags) {
			// Gather tags and groups
			let tags = assignedTags.map(t => t.tag_id);
			let groups = assignedGroups.map(t => t.tag_id);

			// Use an overwrite call to remove deleted tags and add new ones. We only need to use it for a single contact.
			let overwriteTagsResponse = await TagService.overwriteTagsForContacts(tags, [contact.id], TAG_TYPES.tag);
			if (!overwriteTagsResponse) {
				ToastService.info(t("Failed to update tags"));
				return;
			}

			// Use an overwrite call to remove deleted groups and add new ones. We only need to use it for a single contact.
			let overwriteGroupsResponse = await TagService.overwriteTagsForContacts(groups, [contact.id], TAG_TYPES.group);
			if (!overwriteGroupsResponse) {
				ToastService.info(t("Failed to update groups"));
				return;
			}
		}

		/*
			Step 3: Finishing saving routines
		*/

		NotificationService.publish("contactUpdated", freshContact);

		// If inbox changed, publish the notification
		if (freshContact.inbox_id !== previousContact.inbox_id) {
			NotificationService.publish("contactInboxUpdated", freshContact);
		}

		if (LocationService.isCustomFieldsEnabled()) {
			await ContactService.updateContactCustomFields({
				contactId: contact.id,
				customFields: Object.keys(assignedCustomFields).map(key => {
					return { id: key, value: assignedCustomFields[key] };
				})
			});
		}

		// Give the parent component this updated contact
		if (this.props.onSave) {
			this.props.onSave(freshContact);
		}

		ToastService.info(t("Contact Updated!"));

		/* Analytics */
		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.name,
			action: GA_ACTIONS.editContactModal.savedEditContactModal,
			label: GA_ACTIONS.editContactModal.savedEditContactModal
		});
	};

	/*
		Methods related to crm 
	*/

	onShowAlertCRM = () => {
		this.update({
			showAlertCRMSend: true
		});
	};

	onConfirmAlertCRMClose = confirm => {
		this.update({
			showAlertCRMSend: false
		});

		if (confirm) {
			this.sendToCRM();
		}
	};

	sendToCRM = async () => {
		let { t } = this.props;
		let { contact } = this.state;

		// Send to the crm
		let success = await MessagesService.sendCRMLead(contact.location_id, contact.id);

		// If we failed to send to the crm
		if (!success) {
			ToastService.info(t("An error occured sending to crm."));
			return;
		}

		ToastService.info(t("Contact Information Sent!"));

		/* Analytics */
		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.sections.action,
			action: GA_ACTIONS.editContactModal.actionSentToCRM,
			label: GA_ACTIONS.editContactModal.actionSentToCRM
		});
	};

	/*
		Methods related to blocking a contact 
	*/

	onShowAlertBlock = () => {
		this.update({
			showAlertContactBlock: true
		});
	};

	onConfirmAlertBlockClose = confirm => {
		this.update({
			showAlertContactBlock: false
		});

		if (confirm) {
			this.blockContact();
		}
	};

	blockContact = async () => {
		let { t } = this.props;
		let { contact } = this.state;

		// Get the user performing the save
		let user = UserService.get();

		// Declare blocked status we will save
		const blockingContact = !contact.is_blocked;

		// Declare what we will update with
		let contactDetails = {
			userId: user.id,
			locationId: contact.location_id,
			contactId: contact.id,
			isBlocked: blockingContact
		};

		let successMessage = "";
		let failureMessage = "";
		// Declare success and failure messages
		if (blockingContact) {
			failureMessage = t("Failed to block the contact.");
			successMessage = t("The contact has been blocked.");
		} else {
			failureMessage = t("Failed to unblock the contact.");
			successMessage = t("The contact has been unblocked.");
		}

		// Update the contact
		let freshContact = await ContactService.updateContact(contactDetails);
		if (!freshContact) {
			ToastService.info(failureMessage);
			return;
		}

		ToastService.info(successMessage);

		NotificationService.publish("contactUpdated", freshContact);

		// Give the parent component this updated contact
		if (this.props.onSave) {
			this.props.onSave(freshContact);
		}

		/* Analytics */
		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.sections.action,
			action: GA_ACTIONS.editContactModal.actionBlockedContact,
			label: GA_ACTIONS.editContactModal.actionBlockedContact
		});
	};

	/*
		Methods related to deleting a contact 
	*/

	onShowAlertContactDelete = () => {
		this.update({
			showAlertContactDelete: true
		});
	};

	onConfirmContactDeleteClose = confirm => {
		this.update({
			showAlertContactDelete: false
		});

		if (confirm) {
			this.deleteContact();
		}
	};

	deleteContact = async () => {
		let { t } = this.props;
		let { contact } = this.state;

		// Declare what we will update with
		let success = await ContactService.deleteContact(contact.id);

		if (!success) {
			ToastService.info(t("An error occured while deleting the contact."));
			return;
		}

		ToastService.info(t("Contact deleted successfully."));

		// Get the fresh contact
		let freshContact = await ContactService.getContact(contact.id);

		NotificationService.publish("contactUpdated", freshContact);

		// Give the parent component this updated contact
		if (this.props.onSave) {
			this.props.onSave(freshContact);
		}

		/* Analytics */
		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.sections.action,
			action: GA_ACTIONS.editContactModal.actionDeletedContact,
			label: GA_ACTIONS.editContactModal.actionDeletedContact
		});
	};

	/*
		Methods related to notes
	*/
	onShowNoteCreation = () => {
		this.update({
			showModalNotes: true,
			notesModalCreationMode: true
		});
	};

	onNoteSubmit = async () => {
		let { noteUpdatingId } = this.state;

		// Update the notes
		await this.fetchNotes();

		if (noteUpdatingId) {
			GAService.GAEvent({
				category: GA_CATEGORIES.editContactModal.sections.notes,
				action: GA_ACTIONS.editContactModal.editedNote,
				label: GA_ACTIONS.editContactModal.editedNote
			});
		} else {
			GAService.GAEvent({
				category: GA_CATEGORIES.editContactModal.sections.notes,
				action: GA_ACTIONS.editContactModal.createdNote,
				label: GA_ACTIONS.editContactModal.createdNote
			});
		}

		await this.update({
			showModalNotes: false,
			noteUpdatingId: null
		});
	};

	onHideNoteModal = async () => {
		await this.update({ showModalNotes: false });
	};

	handleDeleteNoteClick = async note => {
		let { t } = this.props;
		let response = await ContactService.deleteContactNote({ noteId: note.id, contactId: note.contact_id });

		if (!response) {
			ToastService.error(t("Error deleting note."));
			return;
		}

		ToastService.info(t("Successfully deleted note."));

		// Update the notes
		this.fetchNotes();

		/* Analytics */
		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.sections.notes,
			action: GA_ACTIONS.editContactModal.deletedNote,
			label: GA_ACTIONS.editContactModal.deletedNote
		});
	};

	handleEditNoteClick = async note => {
		await this.update({
			showModalNotes: true,
			noteUpdatingId: note.id,
			notesModalCreationMode: false
		});
	};

	/*
		Methods related to adding an email or phone to a contact
	*/

	onCheckMediumData = async value => {
		let { editingEmail, editingPhone } = this.state;
		let locationId = UserService.getActiveLocation().id;

		if (editingEmail) {
			let isNewMediumDataValid = true;
			let isNewMediumDataErrorMessage = EDIT_CONTACT_ADD_NEW_MEDIUM_ERRORS.email.invalid;

			if (!isEmail(value)) {
				isNewMediumDataValid = false;
				isNewMediumDataErrorMessage = EDIT_CONTACT_ADD_NEW_MEDIUM_ERRORS.email.invalidFormat;
			} else if (await ContactService.findContact({ locationId, email: value })) {
				isNewMediumDataValid = false;
				isNewMediumDataErrorMessage = EDIT_CONTACT_ADD_NEW_MEDIUM_ERRORS.email.alreadyExists;
			}

			this.update({
				isNewMediumDataValid,
				isNewMediumDataErrorMessage,
				newMediumAdded: isNewMediumDataValid
			});

			return;
		}

		if (editingPhone) {
			let isNewMediumDataValid = true;
			let isNewMediumDataErrorMessage = EDIT_CONTACT_ADD_NEW_MEDIUM_ERRORS.phone.invalid;

			if (!UtilityService.isMobilePhoneValid(value)) {
				isNewMediumDataValid = false;
				isNewMediumDataErrorMessage = EDIT_CONTACT_ADD_NEW_MEDIUM_ERRORS.phone.invalidFormat;
			} else if (await ContactService.findContact({ locationId, phone: value })) {
				isNewMediumDataValid = false;
				isNewMediumDataErrorMessage = EDIT_CONTACT_ADD_NEW_MEDIUM_ERRORS.phone.alreadyExists;
			}

			this.update({
				isNewMediumDataValid,
				isNewMediumDataErrorMessage,
				newMediumAdded: isNewMediumDataValid
			});

			return;
		}

		this.update({
			isNewMediumDataValid: false,
			isNewMediumDataErrorMessage: "",
			newMediumAdded: false
		});
	};

	canAddEmail() {
		let { email, preferred_medium, editingPhone } = this.state.contact;
		return preferred_medium === MEDIUM.sms.key && email === "" && !editingPhone;
	}

	canAddPhone() {
		let { phone, preferred_medium, editingEmail } = this.state.contact;
		return preferred_medium === MEDIUM.email.key && phone === "" && !editingEmail;
	}

	onEditMedium = medium => {
		if (medium === MEDIUM.sms.key && !this.canAddPhone()) {
			return;
		}

		if (medium === MEDIUM.email.key && !this.canAddEmail()) {
			return;
		}

		if (medium === MEDIUM.email.key) {
			this.update({ editingEmail: true });
			return;
		}

		this.update({ editingPhone: true });
	};

	onEmailChange = async event => {
		if (!this.canAddEmail) {
			return;
		}

		let { contact } = this.state;

		contact.email = event.target.value;
		await this.update({ contact, isNewMediumDataValid: false, isNewMediumDataErrorMessage: "" });

		this.deboundedOnCheckMediumData(this.state.contact.email || "");

		/* Analytics */
		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.sections.basics,
			action: GA_ACTIONS.editContactModal.changedEmail,
			label: GA_ACTIONS.editContactModal.changedEmail
		});
	};

	onDateChange = async value => {
		let { contact } = this.state;

		if (!value) {
			contact.dob = moment().format("YYYY-MM-DD");
		} else {
			contact.dob = moment(value).format("YYYY-MM-DD");
		}

		await this.update({ contact });

		/* Analytics */
		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.sections.basics,
			action: GA_ACTIONS.editContactModal.changedDOB,
			label: GA_ACTIONS.editContactModal.changedDOB
		});
	};

	onPhoneChange = async event => {
		if (!this.canAddPhone) {
			return;
		}

		let { contact } = this.state;

		contact.phone = event.target.value;
		await this.update({ contact, isNewMediumDataValid: false, isNewMediumDataErrorMessage: "" });

		this.deboundedOnCheckMediumData(this.state.contact.phone || "");

		/* Analytics */
		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.sections.basics,
			action: GA_ACTIONS.editContactModal.changedPhone,
			label: GA_ACTIONS.editContactModal.changedPhone
		});
	};

	/*
		All tab render methods are below
	*/

	renderBasics() {
		let { t } = this.props;
		let {
			name,
			language,
			email,
			dob,
			phone,
			out_number,
			home_phone,
			work_phone,
			address_1,
			address_2,
			city,
			state_prov,
			zip_postal,
			country
		} = this.state.contact;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_contacts;
		let isAdminOrCS = UserService.isSuperAdminOrCustomerSuccess();
		let { editingPhone, editingEmail, isNewMediumDataValid, isNewMediumDataErrorMessage, assignableNumbers } = this.state;

		return (
			<div className="ecm__details">
				<div className="ecm__details__item">
					<div className="ecm__label">{t("Name")}</div>
					<input
						id="name"
						name="name"
						value={name}
						className="ecm__input"
						placeholder={t("Contact Name")}
						onChange={this.onChangeInput}
						disabled={!allowUpdate}
					/>
				</div>
				<div className="ecm__details__item">
					<div className="ecm__label ecm__label--phone">
						{t("Phone")}
						{!editingPhone && this.canAddPhone() && (
							<Icon.Edit3 className="ecm__label__edit fnctst-edit-phone-control" onClick={() => this.onEditMedium(MEDIUM.sms.key)} />
						)}
					</div>
					<input
						id="phone"
						name="phone"
						value={phone}
						className="ecm__input"
						placeholder={editingPhone ? t("Add Phone...") : t("No phone present")}
						disabled={!editingPhone || !allowUpdate}
						onChange={this.onPhoneChange}
					/>
					{editingPhone && (
						<React.Fragment>
							{!isNewMediumDataValid && (
								<div className="ecm__details__item__input-error">
									{isNewMediumDataErrorMessage.length > 0 && <Icon.XCircle className="ecm__label__plus ecm__details__item__input-error__icon" />}
									{isNewMediumDataErrorMessage}
								</div>
							)}
							{isNewMediumDataValid && (
								<div className="ecm__details__item__input-valid">
									<Icon.CheckCircle className="ecm__label__plus ecm__details__item__input-valid__icon" /> {t("Valid phone.")}
								</div>
							)}
						</React.Fragment>
					)}
				</div>
				<div className="ecm__details__item">
					<div className="ecm__label ecm__label--email">
						{t("Email")}
						{!editingEmail && this.canAddEmail() && (
							<Icon.Edit3 size={16} className="ecm__label__edit fnctst-edit-email-control" onClick={() => this.onEditMedium(MEDIUM.email.key)} />
						)}
					</div>
					<input
						id="email"
						name="email"
						value={email}
						className="ecm__input"
						placeholder={editingEmail ? t("Add Email...") : t("No email present")}
						disabled={!editingEmail || !allowUpdate}
						onChange={this.onEmailChange}
					/>
					{editingEmail && (
						<React.Fragment>
							{!isNewMediumDataValid && (
								<div className="ecm__details__item__input-error">
									{isNewMediumDataErrorMessage.length > 0 && <Icon.XCircle className="ecm__label__plus ecm__details__item__input-error__icon" />}
									{isNewMediumDataErrorMessage}
								</div>
							)}
							{isNewMediumDataValid && (
								<div className="ecm__details__item__input-valid">
									<Icon.CheckCircle className="ecm__label__plus ecm__details__item__input-valid__icon" /> {t("Valid email.")}
								</div>
							)}
						</React.Fragment>
					)}
				</div>
				<div className="ecm__details__item">
					<div className="ecm__label">{t("Date of Birth")} (YYYY-MM-DD)</div>
					<DatePicker
						selected={new Date(moment(dob).toISOString())}
						onChange={e => this.onDateChange(e)}
						dateFormat="yyyy-MM-dd"
						className="Common__datepicker"
						disabled={!allowUpdate}
					/>
				</div>
				<div className="ecm__details__item">
					<div className="ecm__label">{t("Language")}</div>
					<LanguageDropdown
						id="language"
						className="ecm__select"
						onChange={newLanguage => {
							this.onChangeSelect("language", newLanguage.value);
						}}
						value={{ label: CODES_TO_LABEL[language], value: language }}
						disabled={!allowUpdate}
					/>
				</div>
				{isAdminOrCS && (
					<div className="ecm__details__item">
						<div className="ecm__label">{t("Out Number")}</div>
						<Select
							id={"out_number-select"}
							options={assignableNumbers}
							onChange={newNumber => {
								this.onChangeSelect("out_number", newNumber.value);
							}}
							value={{ label: out_number, value: out_number }}
							focusedOption={{ label: out_number, value: out_number }}
							isSearchable={true}
							isClearable={false}
							placeholder={t("Please select a number")}
							required={true}
						/>
					</div>
				)}
				<div className="ecm__details__item">
					<div className="ecm__label">{t("Home Phone")}</div>
					<input
						id="home_phone"
						name="home_phone"
						value={home_phone}
						className="ecm__input"
						placeholder={t("Home Phone")}
						onChange={this.onChangeInput}
						disabled={!allowUpdate}
					/>
				</div>
				<div className="ecm__details__item">
					<div className="ecm__label">{t("Work Phone")}</div>
					<input
						id="work_phone"
						name="work_phone"
						value={work_phone}
						className="ecm__input"
						placeholder={t("Work Phone")}
						onChange={this.onChangeInput}
						disabled={!allowUpdate}
					/>
				</div>
				<div className="ecm__details__item">
					<div className="ecm__label">{t("Address Line 1")}</div>
					<input
						id="address_1"
						name="address_1"
						value={address_1}
						className="ecm__input"
						placeholder={t("Address 1")}
						onChange={this.onChangeInput}
						disabled={!allowUpdate}
					/>
				</div>
				<div className="ecm__details__item">
					<div className="ecm__label">{t("Address Line 2")}</div>
					<input
						id="address_2"
						name="address_2"
						value={address_2}
						className="ecm__input"
						placeholder={t("Address 2")}
						onChange={this.onChangeInput}
						disabled={!allowUpdate}
					/>
				</div>
				<div className="ecm__details__item">
					<div className="ecm__label">{t("City")}</div>
					<input id="city" name="city" value={city} className="ecm__input" placeholder={t("City")} onChange={this.onChangeInput} disabled={!allowUpdate} />
				</div>
				<div className="ecm__details__item">
					<div className="ecm__label">{t("State or Province")}</div>
					<input
						id="state_prov"
						name="state_prov"
						value={state_prov}
						className="ecm__input"
						placeholder={t("State or Province")}
						onChange={this.onChangeInput}
						disabled={!allowUpdate}
					/>
				</div>
				<div className="ecm__details__item">
					<div className="ecm__label">{t("Zip or Postal Code")}</div>
					<input
						id="zip_postal"
						name="zip_postal"
						value={zip_postal}
						className="ecm__input"
						placeholder={t("Zip or Postal Code")}
						onChange={this.onChangeInput}
						disabled={!allowUpdate}
					/>
				</div>
				<div className="ecm__details__item">
					<div className="ecm__label">{t("Country")}</div>
					<input
						id="country"
						name="country"
						value={country}
						className="ecm__input"
						placeholder={t("Country")}
						onChange={this.onChangeInput}
						disabled={!allowUpdate}
					/>
				</div>
			</div>
		);
	}

	renderAdvanced() {
		let { t } = this.props;
		let {
			id,
			public_id,
			status,
			assigned_user_id,
			inbox_id,
			email,
			phone,
			last_message_sent,
			is_discharged,
			is_deceased,
			created_at,
			lead_source,
			preferred_medium
		} = this.state.contact;
		let { assignableTags, assignableGroups, assignedTags, assignedGroups } = this.state; // tags and groups
		let { assignableUsers, assignableInboxes, assignableStatuses, assignablePreferredMediums } = this.state; // others

		// Get the user updating the contact
		let user = UserService.get();

		// Convert the dates to a readable format
		last_message_sent = moment(last_message_sent).format("MMMM Do YYYY, h:mm A");
		created_at = moment(created_at).format("MMMM Do YYYY, h:mm A");

		// Find the current state labels based on the assignables
		// XXX -- kinda yucky since each of these is O(N)

		// Create the readable label for the currently assigned user
		let assignedUserLabel = assignableUsers.find(currentUser => currentUser.value === assigned_user_id);
		assignedUserLabel = typeof assignedUserLabel === "undefined" ? t("Assigned User") : assignedUserLabel.label;

		// Create the readable label for the currently assigned inbox
		let assignableInboxLabel = assignableInboxes.find(inbox => {
			if (inbox.value === inbox_id) {
				return inbox.label;
			} else if (inbox.value === 0 && inbox_id === null) {
				// if the inbox id is null, it is considered to be as "Unassigned"
				return inbox.label;
			} else {
				return null;
			}
		});

		assignableInboxLabel = !assignableInboxLabel ? t("Assigned Inbox") : assignableInboxLabel.label;

		// Create the readable label for the current status
		let assignableStatusLabel = assignableStatuses.find(theStatus => theStatus.value === status);
		assignableStatusLabel = typeof assignableStatusLabel === "undefined" ? t("Assigned Status") : assignableStatusLabel.label;

		// Only show contact id if super user or cs
		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();

		// Find the preferred medium
		let assignablePreferredMediumLabel = assignablePreferredMediums.find(theMedium => theMedium.value === preferred_medium);
		assignablePreferredMediumLabel = typeof assignablePreferredMediumLabel === "undefined" ? t("Assigned Medium") : assignablePreferredMediumLabel.label;

		// Disable changing the preferred medium if it's not sms or email
		let isPreferredMediumChangeDisabled = false;
		if (preferred_medium !== MEDIUM.sms.key && preferred_medium !== MEDIUM.email.key) {
			isPreferredMediumChangeDisabled = true;
		}
		// Also disable if we don't have both sms and email to switch between
		else if (!email || !phone) {
			isPreferredMediumChangeDisabled = true;
		}

		return (
			<>
				<div className="ecm__details">
					{isSuperOrCs && (
						<div className="ecm__details__item">
							<div className="ecm__label">{t("Contact ID")}</div>
							<input id="contact-id" name="contact_id" value={id} className="ecm__input" placeholder={t("Unknown contact id")} disabled={true} />
						</div>
					)}
					{isSuperOrCs && (
						<div className="ecm__details__item">
							<div className="ecm__label">{t("Public ID")}</div>
							<input id="public-id" name="public_id" value={public_id} className="ecm__input" placeholder={t("Unknown public id")} disabled={true} />
						</div>
					)}
					<div className="ecm__details__item">
						<div className="ecm__label">{t("Status")}</div>
						<Select
							id="status"
							name="status"
							options={assignableStatuses}
							value={{ label: assignableStatusLabel, value: status }}
							className="ecm__select"
							placeholder={t("Contact Status")}
							onChange={newStatus => {
								this.onChangeSelect("status", newStatus.value);
							}}
							isDisabled={!user.GroupPermission.update_contacts}
						/>
					</div>
					<div className="ecm__details__item">
						<div className="ecm__label">{t("Assigned User")}</div>
						<Select
							id="assigned-user"
							name="assigned_user_id"
							options={assignableUsers}
							value={{ label: assignedUserLabel, value: assigned_user_id }}
							className="ecm__select"
							placeholder={t("Assigner User")}
							onChange={assignedUser => {
								this.onChangeSelect("assigned_user_id", assignedUser.value);
							}}
							isDisabled={!user.GroupPermission.update_contacts || !user.GroupPermission.assign_customer_messages}
						/>
					</div>
					<div className="ecm__details__item">
						<div className="ecm__label">{t("Assigned Inbox")}</div>
						<Select
							id="assigned-inbox"
							name="inbox_id"
							className="ecm__select"
							value={{ label: assignableInboxLabel, value: inbox_id }}
							placeholder={t("Select a type ...")}
							isSearchable
							options={assignableInboxes}
							onChange={assignedInbox => {
								this.onChangeSelect("inbox_id", assignedInbox.value);
							}}
							isDisabled={!user.GroupPermission.update_contacts || !user.GroupPermission.update_inboxes}
						/>
					</div>
					<div className="ecm__details__item">
						<div className="ecm__label">
							{t("Assigned Tags")}
							{user.GroupPermission.create_tags && (
								<Icon.Plus className="ecm__label__plus fnctst-add-tag-button" onClick={() => this.onShowCreateTagModal(TAG_TYPES.tag)} />
							)}
						</div>
						<Select
							id="tag-select"
							name="assignedTags"
							options={assignableTags}
							value={assignedTags}
							className="ecm__select"
							isMulti
							placeholder={t("Select a tag ...")}
							onChange={assignedTags => {
								this.onAssignedTagsChanged(assignedTags);
							}}
							isDisabled={!user.GroupPermission.update_contacts || !user.GroupPermission.update_tags}
						/>
					</div>
					<div className="ecm__details__item">
						<div className="ecm__label">
							{t("Assigned Groups")}
							{user.GroupPermission.create_tags && (
								<Icon.Plus className="ecm__label__plus fnctst-add-group-button" onClick={() => this.onShowCreateTagModal(TAG_TYPES.group)} />
							)}
						</div>
						<Select
							id="group-select"
							name="assignedGroups"
							options={assignableGroups}
							value={assignedGroups}
							className="ecm__select"
							isMulti
							placeholder={t("Select a group ...")}
							onChange={assignedGroups => {
								this.onAssignedGroupsChanged(assignedGroups);
							}}
							isDisabled={!user.GroupPermission.update_contacts || !user.GroupPermission.update_tags}
						/>
					</div>
					<div className="ecm__details__item">
						<div className="ecm__label">{t("Preferred Medium")}</div>
						<Select
							id="preferred-medium"
							name="preferred_medium"
							options={assignablePreferredMediums}
							value={{ label: assignablePreferredMediumLabel, value: preferred_medium }}
							className="ecm__select"
							placeholder={t("No preferred medium")}
							onChange={newMedium => {
								this.onChangeSelect("preferred_medium", newMedium.value);
							}}
							isDisabled={!user.GroupPermission.update_contacts || isPreferredMediumChangeDisabled}
						/>
					</div>
					<div className="ecm__details__item">
						<div className="ecm__label">{t("Lead Source")}</div>
						<input
							id="lead-source"
							name="lead_source"
							value={UtilityService.toTitleCase(lead_source)}
							className="ecm__input"
							placeholder={t("Unknown lead source")}
							disabled={true}
						/>
					</div>
					<div className="ecm__details__item">
						<div className="ecm__label">{t("Last Message Sent")}</div>
						<input
							id="last-message-sent"
							name="last_message_sent"
							value={last_message_sent}
							className="ecm__input"
							placeholder={t("Unknown last message date")}
							disabled={true}
						/>
					</div>
					<div className="ecm__details__item">
						<div className="ecm__label">{t("Is Discharged")}</div>
						<ReactSwitch
							id="is_discharged"
							height={22}
							width={38}
							checked={is_discharged}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#60A9FF"
							offColor="#c5c5c5"
							onChange={newValue => {
								this.onChangeReactSwitch("is_discharged", newValue);
							}}
						/>
					</div>
					<div className="ecm__details__item">
						<div className="ecm__label">{t("Is Deceased")}</div>
						<ReactSwitch
							id="is_deceased"
							height={22}
							width={38}
							checked={is_deceased}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#60A9FF"
							offColor="#c5c5c5"
							onChange={newValue => {
								this.onChangeReactSwitch("is_deceased", newValue);
							}}
						/>
					</div>
					<div className="ecm__details__item">
						<div className="ecm__label">{t("Created Date")}</div>
						<input id="creation-date" name="created_at" value={created_at} className="ecm__input" placeholder={t("Unknown creation date")} disabled={true} />
					</div>
				</div>
				<div className="ecm__title">{t("Custom Fields")}</div>
				{this.renderCustomFields()}
			</>
		);
	}

	renderNotes() {
		let { assignedNotes } = this.state;
		let { t } = this.props;

		// Get permissions for which tabs we should show
		let user = UserService.get();
		let allowCreate = user.GroupPermission.create_contact_notes;
		let allowUpdate = user.GroupPermission.update_contact_notes;
		let allowDelete = user.GroupPermission.delete_contact_notes;

		return (
			<div className="ecm__notes">
				{allowCreate && (
					<div>
						<div className="mb-button" onClick={this.onShowNoteCreation}>
							+ {t("Add a note")}
						</div>
					</div>
				)}
				{assignedNotes.map((note, index) => {
					return (
						<>
							<div className="ecm__notes__line" />
							<div key={index} className="ecm__notes__row">
								<div className="ecm__notes__author">{UtilityService.toTitleCase(note.sender_user_name)}</div>
								<div className="ecm__notes__author" />
								<div className="ecm__notes__bubble">
									<div className="ecm__notes__quote">”</div>
									<div className="ecm__notes__message">{note.content}</div>
								</div>
								<div className="ecm__notes__bottom">
									<div className="ecm__notes__date">{moment(note.created_at).format("dddd, MMMM Do, GGGG @ h:mm A")}</div>
									<div className="ecm__notes__bottom__spacer" />
									{allowUpdate && (
										<div
											className="ecm__notes__control"
											onClick={() => {
												this.handleEditNoteClick(note);
											}}
										>
											<Icon.Edit2 size="10" />
										</div>
									)}
									{allowDelete && (
										<div
											className="ecm__notes__control"
											onClick={() => {
												this.handleDeleteNoteClick(note);
											}}
										>
											<Icon.Trash2 size="10" />
										</div>
									)}
								</div>
							</div>
						</>
					);
				})}
			</div>
		);
	}

	renderActions() {
		let { is_blocked } = this.state.contact;
		let { t } = this.props;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_contacts;
		let allowDelete = user.GroupPermission.delete_contacts;
		let allowSendToCrm = user.GroupPermission.send_to_crm_integrations;
		let isMessengerEnabled = LocationService.isMessengerPermissible();
		let canViewMessages = ContactService.canViewContactsMessages(this.state.contact);

		return (
			<div className="ecm__action-rows">
				{isMessengerEnabled && (
					<>
						<div className="ecm__action-rows__row">
							<div className="ecm__action-rows__row__text">
								<div className="ecm__action-rows__row__title">{t("Go To Messenger")}</div>
								<div className="ecm__action-rows__row__subtitle">{t("View this contact's conversation in messenger")}</div>
							</div>
							<div className="ecm__action-rows__row__button">
								<div className="mb-button" onClick={this.onViewConversation}>
									{t("View Conversation")}
								</div>
							</div>
						</div>
						<div className="ecm__action-rows__spacer" />
					</>
				)}
				{allowSendToCrm && (
					<div className="ecm__action-rows__row">
						<div className="ecm__action-rows__row__text">
							<div className="ecm__action-rows__row__title">{t("Send to CRM")}</div>
							<div className="ecm__action-rows__row__subtitle">
								{t("The contact will be sent to the integrated CRM.")}
								{/* eslint-disable-next-line */}
								<a className="fnctst-crm-open-intercom" onClick={this.openIntercom}>
									{" "}
									{t("Chat with us to learn more")}
								</a>
								.
							</div>
						</div>
						<div className="ecm__action-rows__row__button">
							<div className="mb-button" onClick={this.onShowAlertCRM}>
								{t("Send to CRM")}
							</div>
						</div>
					</div>
				)}

				{allowDelete && (
					<>
						<div className="ecm__action-rows__spacer" />
						<div className="ecm__action-rows__row">
							<div className="ecm__action-rows__row__text">
								<div className="ecm__action-rows__row__title">{t("Delete Contact")}</div>
								<div className="ecm__action-rows__row__subtitle">{t("This contact will be deleted and removed from your list of contacts.")}</div>
							</div>
							<div className="ecm__action-rows__row__button">
								<div className="mb-button" onClick={this.onShowAlertContactDelete}>
									{t("Delete Contact")}
								</div>
							</div>
						</div>
					</>
				)}
				<div className="ecm__action-rows__spacer" />
				{allowUpdate && (
					<div className="ecm__action-rows__row">
						<div className="ecm__action-rows__row__text">
							<div className="ecm__action-rows__row__title">{t("Block Contact")}</div>
							<div className="ecm__action-rows__row__subtitle">
								{t("The contact will no longer appear in messenger and users will not receive related notifications on inbound messages.")}
							</div>
						</div>

						<div className="ecm__action-rows__row__toggle">
							<div className="mb-button" onClick={this.onShowAlertBlock}>
								{is_blocked ? t("Unblock Contact") : t("Block Contact")}
							</div>
						</div>
					</div>
				)}

				{allowUpdate && (
					<>
						<div className="ecm__action-rows__spacer" />
						<div className="ecm__action-rows__row">
							<div className="ecm__action-rows__row__text">
								<div className="ecm__action-rows__row__title">{t("Merge Contact")}</div>
								<div className="ecm__action-rows__row__subtitle">{t("The contact will be merged with another contact")}</div>
							</div>

							<div className="ecm__action-rows__row__toggle">
								<div className="mb-button" onClick={this.onShowMergeContactModal}>
									{t("Merge Contact")}
								</div>
							</div>
						</div>
					</>
				)}

				{canViewMessages && (
					<>
						<div className="ecm__action-rows__spacer" />
						<div className="ecm__action-rows__row">
							<div className="ecm__action-rows__row__text">
								<div className="ecm__action-rows__row__title">{t("Export Conversation")}</div>
								<div className="ecm__action-rows__row__subtitle">{t("Export the conversation with this contact in PDF format.")}</div>
							</div>

							<div className="ecm__action-rows__row__toggle">
								<div className="mb-button" onClick={this.onShowDownloadConversarionModal}>
									{t("Export PDF")}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		);
	}

	renderCommunication() {
		let { t } = this.props;
		let {
			receive_marketing_emails,
			receive_marketing_sms,
			receive_transactional_emails,
			receive_transactional_sms,
			receive_feedback_emails,
			receive_feedback_sms
		} = this.state.contact;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_contacts;

		if (!allowUpdate) {
			return null;
		}

		return (
			<div className="ecm__receive ecm__action-rows">
				<div className="ecm__action-rows__row">
					<div className="ecm__action-rows__row__text">
						<div className="ecm__action-rows__row__title">{t("Receive Transactional SMS")}</div>
						<div className="ecm__action-rows__row__subtitle">
							{t(
								"Allow this contact to receive SMS messages after interacting with your business, such as review invites, payment notifications, appointment reminders, etc."
							)}
						</div>
					</div>
					<div className="ecm__action-rows__row__toggle">
						<ReactSwitch
							id="receive_transactional_sms"
							height={22}
							width={38}
							checked={receive_transactional_sms}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#60A9FF"
							offColor="#c5c5c5"
							onChange={newValue => {
								this.onChangeReactSwitch("receive_transactional_sms", newValue);
							}}
						/>
					</div>
				</div>
				<div className="ecm__action-rows__spacer" />
				<div className="ecm__action-rows__row">
					<div className="ecm__action-rows__row__text">
						<div className="ecm__action-rows__row__title">{t("Receive Transactional Email")}</div>
						<div className="ecm__action-rows__row__subtitle">
							{t(
								"Allow this contact to receive email messages after interacting with your business, such as review invites, payment notifications, appointment reminders, etc."
							)}
						</div>
					</div>
					<div className="ecm__action-rows__row__toggle">
						<ReactSwitch
							id="receive_transactional_emails"
							height={22}
							width={38}
							checked={receive_transactional_emails}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#60A9FF"
							offColor="#c5c5c5"
							onChange={newValue => {
								this.onChangeReactSwitch("receive_transactional_emails", newValue);
							}}
						/>
					</div>
				</div>
				<div className="ecm__action-rows__spacer" />
				<div className="ecm__action-rows__row">
					<div className="ecm__action-rows__row__text">
						<div className="ecm__action-rows__row__title">{t("Receive Marketing SMS")}</div>
						<div className="ecm__action-rows__row__subtitle">
							{/* Haroon, what shall we write about transactional sms? Maybe where it's sent from in our system? */}
						</div>
					</div>
					<div className="ecm__action-rows__row__toggle">
						<ReactSwitch
							id="receive_marketing_sms"
							height={22}
							width={38}
							checked={receive_marketing_sms}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#60A9FF"
							offColor="#c5c5c5"
							onChange={newValue => {
								this.onChangeReactSwitch("receive_marketing_sms", newValue);
							}}
						/>
					</div>
				</div>
				<div className="ecm__action-rows__spacer" />
				<div className="ecm__action-rows__row">
					<div className="ecm__action-rows__row__text">
						<div className="ecm__action-rows__row__title">{t("Receive Marketing Email")}</div>
						<div className="ecm__action-rows__row__subtitle">
							{/* Haroon, what shall we write about transactional sms? Maybe where it's sent from in our system? */}
						</div>
					</div>
					<div className="ecm__action-rows__row__toggle">
						<ReactSwitch
							id="receive_marketing_emails"
							height={22}
							width={38}
							checked={receive_marketing_emails}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#60A9FF"
							offColor="#c5c5c5"
							onChange={newValue => {
								this.onChangeReactSwitch("receive_marketing_emails", newValue);
							}}
						/>
					</div>
				</div>
				<div className="ecm__action-rows__spacer" />
				<div className="ecm__action-rows__row">
					<div className="ecm__action-rows__row__text">
						<div className="ecm__action-rows__row__title">Receive Feedback Email</div>
						<div className="ecm__action-rows__row__subtitle">
							{/* Haroon, what shall we write about transactional sms? Maybe where it's sent from in our system? */}
						</div>
					</div>
					<div className="ecm__action-rows__row__toggle">
						<ReactSwitch
							id="receive_feedback_emails"
							height={22}
							width={38}
							checked={receive_feedback_emails}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#60A9FF"
							offColor="#c5c5c5"
							onChange={newValue => {
								this.onChangeReactSwitch("receive_feedback_emails", newValue);
							}}
						/>
					</div>
				</div>
				<div className="ecm__action-rows__spacer" />
				<div className="ecm__action-rows__row">
					<div className="ecm__action-rows__row__text">
						<div className="ecm__action-rows__row__title">Receive Feedback SMS</div>
						<div className="ecm__action-rows__row__subtitle">
							{/* Haroon, what shall we write about transactional sms? Maybe where it's sent from in our system? */}
						</div>
					</div>
					<div className="ecm__action-rows__row__toggle">
						<ReactSwitch
							id="receive_feedback_sms"
							height={22}
							width={38}
							checked={receive_feedback_sms}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#60A9FF"
							offColor="#c5c5c5"
							onChange={newValue => {
								this.onChangeReactSwitch("receive_feedback_sms", newValue);
							}}
						/>
					</div>
				</div>
			</div>
		);
	}

	renderCustomFields() {
		let { t } = this.props;
		let { assignedCustomFields, assignableCustomFields, canFavoriteCustomFields, favoriteCustomFields } = this.state;

		if (!assignableCustomFields || assignableCustomFields.length === 0) {
			return <div className="ecm__details">{t("No custom fields found.")}</div>;
		}

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_contacts;

		return (
			<div className="ecm__details">
				{assignableCustomFields.map(customField => (
					<div key={customField.id} className="ecm__details__item">
						<div className="ecm__label">
							{customField.name}
							<Icon.Star
								size={12}
								className={`ecm__label__favorite ${favoriteCustomFields[customField.id] ? "ecm__label__favorite--enabled" : ""} ${
									!favoriteCustomFields[customField.id] && !canFavoriteCustomFields ? "ecm__label__favorite--hidden" : ""
								}`}
								onClick={() => this.onCustomFieldFavoriteToggle(customField.id)}
								data-tip
								data-for={`${customField.field}-favorite-rtt`}
							/>
							<ReactTooltip
								id={`${customField.field}-favorite-rtt`}
								className="mb-react-tooltip mb-react-tooltip--medium"
								arrowColor="#333"
								type="info"
								effect="solid"
								place="bottom"
							>
								{!favoriteCustomFields[customField.id] ? t("Favorite Custom Field") : t("Unfavorite Custom Field")}
								<hr style={{ margin: "4px 0px" }} />
								{t("Favorited custom fields will be available in Messenger in the contact details menu.")}
							</ReactTooltip>
						</div>
						{customField.type === CUSTOM_FIELD_TYPES.string && (
							<input
								id={customField.field}
								name={customField.name}
								value={assignedCustomFields[customField.id] || ""}
								className="ecm__input"
								placeholder={customField.description}
								onChange={event => this.onCustomFieldChange(customField.id, event.target.value)}
								disabled={!allowUpdate}
							/>
						)}
						{customField.type === CUSTOM_FIELD_TYPES.boolean && (
							<Checkbox
								id={customField.field}
								name={customField.name}
								checked={assignedCustomFields[customField.id] || false}
								onChange={event => this.onCustomFieldChange(customField.id, event.target.checked)}
								disabled={!allowUpdate}
							/>
						)}
					</div>
				))}
			</div>
		);
	}

	renderAppointments = () => {
		let { contact } = this.state;
		return <ContactAppointments contactId={contact.id} />;
	};

	renderMedia = () => {
		let { contact } = this.state;
		return <CustomerMediaGrid contactId={contact.id} />;
	};

	renderLoading(title) {
		return (
			<Modal show={true} onHide={this.onClose} title={title}>
				<div className="ecm ecm--centre-content">
					<div className="ecm__loading">
						<ContentLoader height={650} width={"100%"}>
							{/* The tabs */}
							<rect x="0" y="0" rx="5" ry="5" width="75%" height="40" />

							{/* First row */}
							<rect x="0" y="80" rx="5" ry="5" width="230" height="70" />
							<rect x="250" y="80" rx="5" ry="5" width="230" height="70" />
							<rect x="500" y="80" rx="5" ry="5" width="230" height="70" />

							{/* Second row */}
							<rect x="0" y="160" rx="5" ry="5" width="230" height="70" />
							<rect x="250" y="160" rx="5" ry="5" width="230" height="70" />
							<rect x="500" y="160" rx="5" ry="5" width="230" height="70" />

							{/* Third row */}
							<rect x="0" y="240" rx="5" ry="5" width="230" height="70" />
							<rect x="250" y="240" rx="5" ry="5" width="230" height="70" />
							<rect x="500" y="240" rx="5" ry="5" width="230" height="70" />

							{/* Save button */}
							<rect x="700" y="500" rx="5" ry="5" width="70" height="35" />
						</ContentLoader>
					</div>
				</div>
			</Modal>
		);
	}

	render() {
		let { t } = this.props;

		// Get loading state
		let { loading, contact } = this.state;

		// Get the state of the contact being found
		let { showAlertContactNotFound } = this.state;

		// Declare the title for the modal
		let title = t("Contact Details");

		// If we could not find the contact
		if (showAlertContactNotFound) {
			return (
				<Alert type="warning" show={true} title={t("Contact not found")} confirm={t("Okay")} onClose={this.onConfirmAlertContactNotFoundClose}>
					{t("The contact could not be found.")}
				</Alert>
			);
		}

		// If there's no contact, there's nothing to show
		if (!contact) {
			return null;
		}

		// If we're still loading, we should display a spinner
		if (loading) {
			return this.renderLoading(title);
		}

		// After this point, we have a contact and everything about them loaded.

		// Extract critical modal related variables
		let { selectedTab } = this.state;
		let { show } = this.props;

		// Alert dialogs
		let {
			showAlertSave,
			showAlertCRMSend,
			showAlertContactBlock,
			showAlertContactDelete,
			showAlertDiscardChanges,
			showModalNotes,
			showMergeContactModal,
			showDownloadConversationModal
		} = this.state;

		// Related to notes
		let { noteUpdatingId, notesModalCreationMode } = this.state;

		// Related to tags
		let { showCreateTagModal, createTagType } = this.state;

		// Add name to the title
		title += " - " + contact.name;

		// If the user is blocked, we should add it to the title
		if (contact.is_blocked) {
			title += ` (${t("Blocked)")})"`;
		}

		return (
			<>
				<Modal show={show} onHide={this.onClose} title={title}>
					<div className="ecm">
						<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
							<Tab id={EDIT_CONTACT_TABS.basics} value={t("Basics")} />
							<Tab id={EDIT_CONTACT_TABS.advanced} value={t("Advanced")} />
							<Tab id={EDIT_CONTACT_TABS.notes} value={t("Notes")} />
							<Tab id={EDIT_CONTACT_TABS.actions} value={t("Actions")} />
							<Tab id={EDIT_CONTACT_TABS.communication} value={t("Receive")} />
							<Tab id={EDIT_CONTACT_TABS.appointments} value={t("Appointments")} />
							<Tab id={EDIT_CONTACT_TABS.media} value={t("Media")} />
						</Tabs>
						{selectedTab === EDIT_CONTACT_TABS.basics && this.renderBasics()}
						{selectedTab === EDIT_CONTACT_TABS.advanced && this.renderAdvanced()}
						{selectedTab === EDIT_CONTACT_TABS.notes && this.renderNotes()}
						{selectedTab === EDIT_CONTACT_TABS.actions && this.renderActions()}
						{selectedTab === EDIT_CONTACT_TABS.communication && this.renderCommunication()}
						{selectedTab === EDIT_CONTACT_TABS.appointments && this.renderAppointments()}
						{selectedTab === EDIT_CONTACT_TABS.media && this.renderMedia()}

						{(selectedTab === EDIT_CONTACT_TABS.basics || selectedTab === EDIT_CONTACT_TABS.advanced || selectedTab === EDIT_CONTACT_TABS.communication) && (
							<div className="ecm__save">
								<div className="mb-button" onClick={this.onShowAlertSave}>
									{t("Save")}
								</div>
							</div>
						)}
					</div>
				</Modal>

				<UpdateContactNotesModal
					show={showModalNotes}
					contactId={contact.id}
					noteId={noteUpdatingId}
					createMode={notesModalCreationMode}
					onSubmit={this.onNoteSubmit}
					onHide={this.onHideNoteModal}
				/>

				<UpdateTagsModal
					data={""}
					show={showCreateTagModal}
					onHide={() => this.onHideCreateTagModal()}
					createMode={true}
					type={createTagType}
					onSubmit={(createMode, id, name) => this.onCreateTagModalSubmit(name)}
				/>

				<MergeContactModal show={showMergeContactModal} contactId={contact.id} onMerged={this.onMergedContact} onHide={this.onHideMergeContactModal} />

				<DownloadConversationModal
					show={showDownloadConversationModal}
					contactId={contact.id}
					contactName={contact.name}
					onHide={this.onHideDownloadConversationModal}
				/>

				<Alert type="warning" show={showAlertSave} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")} onClose={this.onConfirmAlertSaveClose}>
					{t("Are you sure you would like to save changes for this contact?")}
				</Alert>
				<Alert
					type="warning"
					show={showAlertDiscardChanges}
					title={t("Save or Discard Changes")}
					confirm={t("Save")}
					cancel={t("Discard")}
					onClose={this.onConfirmShowAlertDiscardChanges}
				>
					{t("Would you like to save your changes or discard them?")}
				</Alert>
				<Alert type="warning" show={showAlertCRMSend} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")} onClose={this.onConfirmAlertCRMClose}>
					{t("Are you sure you would like to send this contact's information to your CRM?")}
				</Alert>
				<Alert
					type="warning"
					show={showAlertContactBlock}
					title={t("Are you sure?")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={this.onConfirmAlertBlockClose}
				>
					{contact.is_blocked ? t("Are you sure you would like to unblock this contact?") : t("Are you sure you would like to block this contact?")}
				</Alert>
				<Alert
					type="warning"
					show={showAlertContactDelete}
					title={t("Are you sure?")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={this.onConfirmContactDeleteClose}
				>
					{t("Are you sure you would like to delete this contact?")}
				</Alert>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(EditContactModal);
