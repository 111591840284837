import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import moment from "moment";
import Spinners from "../../components/common/Spinners";
import c3 from "c3";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import * as Icon from "react-feather";
import { CSVLink } from "react-csv";
import { CsvService } from "../../services/CsvService";
import { GMB_STATS } from "../../constants/GmbStats.js";
import ReactTooltip from "react-tooltip";
import { Redirect } from "react-router-dom";
import GmbService from "../../services/GmbService";
import GmbLocationStatsService from "../../services/GmbLocationStatsService";

import withLocation from "../../components/common/WithLocation";

import "../../App.css";
import "react-day-picker/lib/style.css";
import "../../styles/css/scenes/analytics.css";

const SHOW_AFTER_X_MONTHS = 6;

class GmbLocationStatsAnalytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAnalyzedStats: true,
			gmbAnalyzedStats: null,
			gmbLocationStats: null,
			loading: false,
			start: moment()
				.subtract(4, "days")
				.subtract(30, "days")
				.toDate(),
			end: moment()
				.subtract(4, "days")
				.toDate(),
			gmbViewSearchStatsCsv: null,
			gmbActionStatsCsv: null,
			gmbActive: true,
			connectNowClicked: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchData();
	}

	onLocationChanged = location => {
		this.fetchData();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async fetchData() {
		try {
			this.setState({ loading: true });
			await this.fetchGmbActive();
			await this.decideToShowAnalyzedStats();
			let { showAnalyzedStats } = this.state;
			let promises = [this.fetchGmbLocationStats()];
			if (showAnalyzedStats) {
				promises.push(this.fetchGmbStatsAnalysis());
			}

			await Promise.all(promises);
		} catch (error) {
			console.log(error);
		}
		this.setState({ loading: false });
	}

	async fetchGmbLocationStats() {
		try {
			const { start, end } = this.state;
			let locationId = UserService.getActiveLocation().id;
			let gmbLocationStats = await GmbLocationStatsService.getGmbLocationStats(locationId, start, end);
			this.setState({ gmbLocationStats: gmbLocationStats }, () => this.updateChart());
		} catch (error) {
			throw error;
		}
	}

	async fetchGmbStatsAnalysis() {
		try {
			const response = await GmbLocationStatsService.getGmbLocationStatsAnalysis();
			this.setState({ gmbAnalyzedStats: response });
		} catch (error) {
			throw error;
		}
	}

	async decideToShowAnalyzedStats() {
		let location = UserService.getActiveLocation();

		let showAnalyzedStats = false;

		let joinDate = moment(location.join_date).add(SHOW_AFTER_X_MONTHS, "months");
		if (moment().isSameOrAfter(joinDate, "day")) {
			showAnalyzedStats = true;
		}
		await this.update({ showAnalyzedStats });
	}

	async fetchGmbActive() {
		try {
			let gmbAndFbStatus = await GmbService.checkGmbAndFbStatus();
			this.setState({
				gmbActive: gmbAndFbStatus.gmb_active
			});
		} catch (error) {
			throw error;
		}
	}

	updateChart() {
		this.setState({ loading: false }, () => {
			const xAxis = this.state.gmbLocationStats.map(anInbound => anInbound.date);
			xAxis.unshift("x");

			const viewsMapsY = this.state.gmbLocationStats.map(anInbound => anInbound[GMB_STATS.metric.viewsMaps.id]);
			viewsMapsY.unshift(GMB_STATS.metric.viewsMaps.name);

			const viewsSearchY = this.state.gmbLocationStats.map(anInbound => anInbound[GMB_STATS.metric.viewsSearch.id]);
			viewsSearchY.unshift(GMB_STATS.metric.viewsSearch.name);

			const actionsWebsiteY = this.state.gmbLocationStats.map(anInbound => anInbound[GMB_STATS.metric.actionsWebsite.id]);
			actionsWebsiteY.unshift(GMB_STATS.metric.actionsWebsite.name);

			const actionsPhoneY = this.state.gmbLocationStats.map(anInbound => anInbound[GMB_STATS.metric.actionsPhone.id]);
			actionsPhoneY.unshift(GMB_STATS.metric.actionsPhone.name);

			const actionsDrivingDirectionsY = this.state.gmbLocationStats.map(anInbound => anInbound[GMB_STATS.metric.actionsDrivingDirections.id]);
			actionsDrivingDirectionsY.unshift(GMB_STATS.metric.actionsDrivingDirections.name);

			let dateColumns = [...xAxis];
			dateColumns[0] = "Date";

			let csvData = [dateColumns, viewsMapsY, viewsSearchY];
			csvData = csvData[0].map((col, i) => csvData.map(row => row[i])); // transpose the data

			let actionCsvData = [dateColumns, actionsWebsiteY, actionsPhoneY, actionsDrivingDirectionsY];
			actionCsvData = actionCsvData[0].map((col, i) => actionCsvData.map(row => row[i])); // transpose the data

			this.setState({
				gmbViewSearchStatsCsv: csvData,
				gmbActionStatsCsv: actionCsvData
			});

			this.generateChart("#gmb-stats-searches-chart", [xAxis, viewsMapsY, viewsSearchY]);
			this.generateChart("#gmb-stats-actions-chart", [xAxis, actionsWebsiteY, actionsPhoneY, actionsDrivingDirectionsY]);
		});
	}

	generateChart(id, columns) {
		const { start, end } = this.state;
		const isMonth = moment(end).diff(moment(start), "days") >= 90;
		c3.generate({
			bindto: id,
			data: {
				x: "x",
				columns: columns,
				type: "line"
			},
			axis: {
				x: {
					label: "Day",
					type: "timeseries",
					tick: {
						format: function(x) {
							if (isMonth) {
								const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
								return "" + x.getFullYear() + " " + monthString[x.getMonth()];
							}
							//return monthString[x.getMonth()];
							return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
						},
						rotate: window.innerWidth > 768 ? 0 : 75,
						multiline: false
						//format: '%Y' // format string is also available for timeseries data
					}
				},
				y: {
					label: "Counts"
				}
			}
		});
	}

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.setState({ start });
	};
	handleToChange = end => {
		this.setState({ end }, this.showFromMonth);
	};
	showFromMonth() {
		const { start, end } = this.state;
		if (!start) {
			return;
		}
		if (moment(end).diff(moment(start), "months") < 2) {
			this.to.getDayPicker().showMonth(start);
		}
	}
	handleUpdateDate(event) {
		event.preventDefault();
		event.stopPropagation();

		this.fetchData();
	}
	calculatePercentChange(oldNumber, newNumber) {
		oldNumber = parseInt(oldNumber);
		newNumber = parseInt(newNumber);

		let percentage = ((oldNumber - newNumber) / oldNumber) * 100.0;
		return Math.round(percentage);
	}

	handleConfigureConnections = () => {
		this.setState({
			connectNowClicked: true
		});
	};

	statColor(pastData, currentData) {
		if (!pastData || pastData.length < 1 || !currentData || currentData.length < 1) {
			return "";
		}
		pastData = parseInt(pastData);
		currentData = parseInt(currentData);

		if (currentData < pastData * 0.8) {
			return "red";
		} else if (currentData < pastData) {
			return "yellow";
		}

		// if currentData >= pastData
		return "green";
	}

	renderMetricDataCard(data) {
		return (
			<div key={`${data.metric}-${data.withDemandHub.viewsLastMonth}`} className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
				<div className="GmbLocationStats__metric-data-card ">
					<div className="text-center title">
						<span data-tip data-for={`${data.metric}-tooltip`}>
							{GMB_STATS.metric[data.metric].name} <Icon.Info size="18" />
						</span>
						<ReactTooltip id={`${data.metric}-tooltip`} className="mb-react-tooltip GmbLocationStats__tooltip" type="info" effect="solid" place="top">
							{GMB_STATS.metric[data.metric].description}
						</ReactTooltip>
					</div>
					<div>
						{typeof data.withDemandHub.viewsThisMonth !== "undefined" && (
							<div className="gmb-stat">
								<span>This Month</span>
								<span className="statistic-number">
									<span>{data.withDemandHub.viewsThisMonth}</span>
								</span>
							</div>
						)}
						{typeof data.withDemandHub.viewsLastMonth !== "undefined" && (
							<div className="gmb-stat">
								<span>Last Month</span>
								<span className="statistic-number">
									<span>{data.withDemandHub.viewsLastMonth}</span>
								</span>
							</div>
						)}
					</div>
					{data.withDemandHub &&
					(data.withDemandHub.viewsLastMonth || data.withDemandHub.totalViews || data.withDemandHub.avgMonthlyViews || data.withDemandHub.viewsThisMonth) ? (
						<div>
							<div className="text-center before-demandhub">
								<span data-tip data-for={`${data.metric}-monthly-avg-search-direct-veiws`}>
									Monthly Average {data.beforeDemandHub && <Icon.Info size="14" />}
								</span>
								{data.beforeDemandHub && (
									<ReactTooltip
										id={`${data.metric}-monthly-avg-search-direct-veiws`}
										className="mb-react-tooltip GmbLocationStats__tooltip"
										type="info"
										effect="solid"
										place="top"
									>
										The data prior to joining is based on data up to 1 year before joining DemandHub. The data collected ranges from{" "}
										{data.beforeDemandHub.startRange} to {data.beforeDemandHub.endRange}
									</ReactTooltip>
								)}
							</div>
							{data.withDemandHub.avgMonthlyViews && (
								<div className="gmb-stat">
									<span>Since Joining</span>
									<span className="statistic-number">
										<span>{data.withDemandHub.avgMonthlyViews}</span>
										{data.withDemandHub.avgMonthlyViews && data.beforeDemandHub && data.beforeDemandHub.avgPerMonth && (
											<span className={this.statColor(data.beforeDemandHub.avgPerMonth, data.withDemandHub.avgMonthlyViews)}>
												{" "}
												(
												{data.withDemandHub.avgMonthlyViews === 0
													? `- ${data.withDemandHub.avgPerMonth}`
													: `${this.calculatePercentChange(data.withDemandHub.avgMonthlyViews, data.beforeDemandHub.avgPerMonth)}%`}
												)
											</span>
										)}
									</span>
								</div>
							)}
						</div>
					) : null}

					{data.beforeDemandHub && (data.beforeDemandHub.avgPerMonth || data.beforeDemandHub.totalViews) && (
						<div>
							{data.beforeDemandHub.avgPerMonth && (
								<div className="gmb-stat">
									<span>Prior To Joining</span>
									<span className="statistic-number">{data.beforeDemandHub.avgPerMonth}</span>
								</div>
							)}

							{data.beforeDemandHub.totalViews && (
								<div className="gmb-stat">
									<span>Total Views</span>
									<span className="statistic-number">{data.beforeDemandHub.totalViews}</span>
								</div>
							)}
						</div>
					)}

					{typeof data.yearOverYear !== "undefined" &&
					data.yearOverYear.last90Days &&
					data.yearOverYear.lastYearLast90Days &&
					this.calculatePercentChange(data.yearOverYear.last90Days, data.yearOverYear.lastYearLast90Days) ? (
						<div>
							<div className="text-center section-header">Annual</div>
							<div className="gmb-stat">
								<span data-tip data-for={`${data.metric}-year-over-year`}>
									Year over year <Icon.Info size="14" />
								</span>
								<span className="statistic-number">
									<span className={this.statColor(data.yearOverYear.lastYearLast90Days, data.yearOverYear.last90Days)}>
										{" "}
										(
										{data.yearOverYear.last90Days === 0
											? `- ${data.yearOverYear.lastYearLast90Days}`
											: `${this.calculatePercentChange(data.yearOverYear.last90Days, data.yearOverYear.lastYearLast90Days)}%`}
										)
									</span>
								</span>
							</div>
						</div>
					) : null}
					<ReactTooltip id={`${data.metric}-year-over-year`} className="mb-react-tooltip GmbLocationStats__tooltip" type="info" effect="solid" place="top">
						<div>
							Year over year comparison: <br />
							The percentage change of the total <br />
							views/clicks in the last 90 days <br />
							compared to last year over the same <br />
							90 day period.
							<hr style={{ margin: "4px 0" }} />
							Total last 90 Days: {data.yearOverYear.last90Days} <br />
							Total last Year: {data.yearOverYear.lastYearLast90Days} <br />
							Last 90 days: {data.yearOverYear.last90DaysStartDate} - {data.yearOverYear.last90DaysEndDate} <br />
							Last year period: {data.yearOverYear.lastYearStartDate} - {data.yearOverYear.lastYearEndDate} <br />
						</div>
					</ReactTooltip>

					{!data.beforeDemandHub && !data.withDemandHub.totalViews && !data.withDemandHub.viewsLastMonth && (
						<div className="text-center">No data found for this metric...</div>
					)}
				</div>
			</div>
		);
	}

	render() {
		const { loading, gmbLocationStats, start, end, showAnalyzedStats, gmbAnalyzedStats, gmbActive, connectNowClicked } = this.state;
		const modifiers = { start, end };
		const locationName = CsvService.filterActiveLocationName();

		if (connectNowClicked) {
			const locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
			return <Redirect to={`/connections/${locationId}`} />;
		}

		if (!gmbActive) {
			return (
				<div className="row">
					<div className="col-lg-12" style={{ marginTop: "15px" }}>
						<div className="ibox">
							<div className="ibox-content text-center">
								<div className="">
									<h2>
										<span style={{ position: "relative", top: 3 }}>
											<Icon.AlertCircle color="red" size="25" />
										</span>{" "}
										Please connect to GMB now to view this data.
									</h2>
									<h4>By connecting your GMB account you will be able to track progress on:</h4>
								</div>

								<div>
									{Object.values(GMB_STATS.metric).map(
										metric =>
											metric.used && (
												<div key={metric.name} style={{ marginTop: 5 }}>
													{metric.name}
													<span className="hidden-xs">
														<Icon.Info size="16" style={{ position: "relative", top: 3, left: 5 }} data-tip data-for={`${metric.name}-tooltip`} />{" "}
														<ReactTooltip type="info" effect="solid" id={`${metric.name}-tooltip`} place="bottom">
															{metric.description}
														</ReactTooltip>
													</span>
												</div>
											)
									)}
								</div>
								<div style={{ marginTop: 20 }}>
									<button id="connect-now" className="btn btn-primary" onClick={this.handleConfigureConnections}>
										Connect now
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

		if (loading) {
			return (
				<div className="Common__spinnerdiv--center-flex">
					<Spinners type="tail-fade" loading={true} size="120px" />
				</div>
			);
		}

		return (
			<div>
				<div className="row wrapper border-bottom white-bg page-heading">
					<div className="ReviewSitesAnalytics__page--header">
						<div className="col-sm-6 col-md-8 col-lg-9">
							<h2>
								Google My Business Stats<span className="hidden-xxs"> - {UserService.getActiveLocation().name}</span>
							</h2>
						</div>
					</div>
					<div className="form-group ReviewSitesAnalytics__form-datepicker">
						<label>Date Range&nbsp;&nbsp;</label>
						<div className="input-group" id="datepicker">
							<div className="InputFromTo">
								<DayPickerInput
									value={start}
									placeholder=" From"
									format="LL"
									formatDate={formatDate}
									parseDate={parseDate}
									dayPickerProps={{
										selectedDays: [start, { from: start, to: end }],
										disabledDays: { after: end },
										toMonth: end,
										modifiers,
										numberOfMonths: 2,
										onDayClick: () => this.to.getInput().focus()
									}}
									onDayChange={start => this.handleFromChange(start)}
								/>{" "}
								<span className="InputFromTo-to">
									<DayPickerInput
										ref={el => (this.to = el)}
										value={end}
										placeholder=" To"
										format="LL"
										formatDate={formatDate}
										parseDate={parseDate}
										dayPickerProps={{
											selectedDays: [start, { from: start, to: end }],
											disabledDays: { before: start },
											modifiers,
											month: start,
											fromMonth: start,
											numberOfMonths: 2
										}}
										onDayChange={this.handleToChange}
									/>
								</span>
							</div>
						</div>{" "}
						<button id="date-update" className="btn btn-primary btn-sm ReviewSites__update-date--btn" onClick={e => this.handleUpdateDate(e)}>
							Update Date
						</button>
					</div>
					<small className="ReviewSitesAnalytics__form-datepicker">GMB Stats are usually available from Google after 72 hours.</small>
				</div>

				<div className="wrapper wrapper-content">
					<div className="row">
						<div className="col-lg-12">
							{!gmbLocationStats ? (
								<div className="ibox">
									<div className="ibox-content forum-post-container">
										<h2>
											Sorry, no data was found{" "}
											<span role="img" aria-label="sad-face">
												😞
											</span>
										</h2>
									</div>
								</div>
							) : (
								<div>
									<div className="ibox">
										<div className="ibox-content forum-post-container">
											<div className="text-center" style={{ fontSize: "16px", fontWeight: "600" }}>
												Search and View Statistics
											</div>
											<div id="gmb-stats-searches-chart" />
											<div className="Common_flex-pull-right">
												{this.state.gmbViewSearchStatsCsv ? (
													<CSVLink data={this.state.gmbViewSearchStatsCsv} filename={`${locationName}-gmb_views_stats.csv`} target="_self">
														<button className="btn btn-default Common_download-btn">
															<img src={`/img/icons/svg/csv-outline.svg`} alt="download csv" style={{ width: "25px" }} />
														</button>
													</CSVLink>
												) : (
													""
												)}
											</div>
										</div>
									</div>
									<div className="ibox">
										<div className="ibox-content forum-post-container">
											<div className="text-center" style={{ fontSize: "16px", fontWeight: "600" }}>
												Action Statistics
											</div>
											<div id="gmb-stats-actions-chart" />
											<div className="Common_flex-pull-right">
												{this.state.gmbActionStatsCsv ? (
													<CSVLink data={this.state.gmbActionStatsCsv} filename={`${locationName}-gmb_action_stats.csv`} target="_self">
														<button className="btn btn-default Common_download-btn">
															<img src={`/img/icons/svg/csv-outline.svg`} alt="download csv" style={{ width: "25px" }} />
														</button>
													</CSVLink>
												) : (
													""
												)}
											</div>
										</div>
									</div>
									{showAnalyzedStats ? (
										<div className="row GmbLocationStats__metric-data-cards">
											{gmbAnalyzedStats ? gmbAnalyzedStats.map(data => this.renderMetricDataCard(data)) : ""}
										</div>
									) : (
										<div className="GmbLocationStats__more-after">* Additional statistics available after {SHOW_AFTER_X_MONTHS} months * </div>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withLocation(GmbLocationStatsAnalytics));
