import React from "react";
import ReactSwitch from "react-switch";
import ContentLoader from "react-content-loader";
import * as Icon from "react-feather";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

import Checkbox from "../../../components/common/Checkbox";
import Action from "../../../components/common/Action";
import Input from "../../../components/common/Input";
import Alert from "../../../components/common/Alert";

import UserService from "../../../services/UserService";
import GbmService from "../../../services/GbmService";
import ToastService from "../../../services/ToastService";
import UtilityService from "../../../services/UtilityService";
import LocationService from "../../../services/LocationService";

import { GBM, GBM_LANGUAGE_OPTIONS, GBM_NL_CONTACT_OPTIONS, GBM_CONVERSATION_STARTER_SUGGESTION_OPTIONS, GBM_DEFAULTS } from "../../../constants/GBMConstants";

import "../../../styles/css/scenes/widget-config.css";
import "../../../styles/css/scenes/google-business-messaging.css";

class AgentForm extends React.Component {
	constructor(props) {
		super(props);
		const location = UserService.getActiveLocation();
		let welcomeMessage = GBM_DEFAULTS.welcomeMessage(location.name);

		this.state = {
			// Loading and error related states
			loading: false,
			loadingError: false,
			showErrorModal: false,
			errorMessages: [],
			saving: false,
			onShowConfirmSubmitAlert: false,

			gmbDetails: null,
			messengerIntegration: null,
			hasGbmAgent: false,
			isAgentVerified: false,
			locationName: "",

			// GBM Brand and Agent Settings
			brandName: "",
			agentName: "",
			logoUrl: "",
			logoFile: null,
			logoChanged: false,
			phoneNumber: "",
			defaultLanguage: GBM_LANGUAGE_OPTIONS[0],
			// For now, we only do english
			conversationSettings: [
				{ language: GBM.language.en, privacyPolicyUrl: GBM_DEFAULTS.privacyPolicyUrl, welcomeMessage, offlineMessage: "", conversationStarters: [] }
			],
			conversationSettingsOptions: [{ value: GBM.language.en, label: GBM.language.en, conversationStarters: [] }],
			selectedConversationSetting: {
				language: GBM.language.en,
				privacyPolicyUrl: GBM_DEFAULTS.privacyPolicyUrl,
				welcomeMessage,
				offlineMessage: "",
				conversationStarters: []
			},
			selectedConversationStarterIndex: 0,
			isNonLocalEntryPointEnabled: false,
			// Non Local Settings, currently not being used due to automated verification. See PR for DH 3236
			nonLocalPhoneNumber: "",
			enabledDomainInput: "",
			enabledDomains: [],
			regionCodes: ["US", "CA"], // All regions
			// callDeflectionPhoneNumbers: [],
			contactOption: {
				url: "",
				options: []
			},
			selectableContactOptions: GBM_NL_CONTACT_OPTIONS
		};
	}

	async componentDidMount() {
		await this.setGmbDetails();

		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({
			loading: true,
			loadingError: true
		});

		let messengerIntegration = await this.fetchGbmMessengerIntegration();
		if (messengerIntegration !== null && !messengerIntegration) {
			await this.update({ loading: false });
			return;
		}

		let { gmbDetails } = this.state;

		const location = UserService.getActiveLocation();
		let welcomeMessage = GBM_DEFAULTS.welcomeMessage(location.name);

		// If the messenger integration is missing the agent information
		if (!messengerIntegration.properties || !messengerIntegration.properties.agent) {
			this.update({
				messengerIntegration: null,
				hasGbmAgent: false,

				loading: false,
				loadingError: false,

				// GBM Brand and Agent Settings
				brandName: UserService.getActiveCompany().name,
				agentName: gmbDetails ? gmbDetails.name : "",
				locationName: gmbDetails ? gmbDetails.name : "",
				logoUrl: "",
				logoFile: null,
				logoChanged: false,
				phoneNumber: "",
				defaultLanguage: GBM_LANGUAGE_OPTIONS[0],
				// For now, we only do english
				conversationSettings: [
					{ language: GBM.language.en, privacyPolicyUrl: GBM_DEFAULTS.privacyPolicyUrl, welcomeMessage, offlineMessage: "", conversationStarters: [] }
				],
				conversationSettingsOptions: [{ value: GBM.language.en, label: GBM.language.en, conversationStarters: [] }],
				selectedConversationSetting: {
					language: GBM.language.en,
					privacyPolicyUrl: GBM_DEFAULTS.privacyPolicyUrl,
					welcomeMessage,
					offlineMessage: "",
					conversationStarters: []
				},
				selectedConversationStarterIndex: 0,
				isNonLocalEntryPointEnabled: false,
				// Non Local Settings
				nonLocalPhoneNumber: "",
				enabledDomainInput: "",
				enabledDomains: [],
				regionCodes: ["US", "CA"], // All regions
				// callDeflectionPhoneNumbers: [],
				contactOption: {
					url: "",
					options: []
				},
				selectableContactOptions: GBM_NL_CONTACT_OPTIONS
			});
			return;
		}

		// If there is agent information on the messenger integration

		let agent = messengerIntegration.properties.agent.businessMessagesAgent;
		let nonLocalConfig = agent.nonLocalConfig && Object.keys(agent.nonLocalConfig).length > 0 ? agent.nonLocalConfig : null;
		let brand = messengerIntegration.properties.brand;

		let conversationalSettings = [];
		if (agent.conversationalSettings && Object.keys(agent.conversationalSettings).length > 0) {
			for (const key in agent.conversationalSettings) {
				const setting = agent.conversationalSettings[key];

				let conversationStarters = [];
				if (setting.conversationStarters && setting.conversationStarters.length > 0) {
					for (let i = 0; i < setting.conversationStarters.length; i++) {
						const convoStarter = setting.conversationStarters[i];
						const suggestion = convoStarter.suggestion;

						if (!suggestion) {
							continue;
						}

						let obj = {
							type: GBM_CONVERSATION_STARTER_SUGGESTION_OPTIONS[0],
							text: suggestion.reply ? suggestion.reply.text : suggestion.action.text,
							postbackData: suggestion.reply ? suggestion.reply.postbackData : suggestion.action.postbackData
						};
						if (suggestion.action) {
							obj.type = GBM_CONVERSATION_STARTER_SUGGESTION_OPTIONS[1];
							obj.url = suggestion.action.openUrlAction.url;
						}
						conversationStarters.push(obj);
					}
				}

				conversationalSettings.push({
					language: key,
					privacyPolicyUrl: setting.privacyPolicy ? setting.privacyPolicy.url : "",
					welcomeMessage: setting.welcomeMessage ? setting.welcomeMessage.text : "",
					offlineMessage: setting.offlineMessage ? setting.offlineMessage.url : "",
					conversationStarters: conversationStarters
				});
			}
		}

		this.update({
			messengerIntegration,
			hasGbmAgent: GbmService.hasGbmAgent(messengerIntegration),
			isAgentVerified: GbmService.isAgentVerified(messengerIntegration),

			loading: false,
			loadingError: false,

			brandName: brand.displayName,
			agentName: messengerIntegration.properties.agent.displayName,
			locationName: gmbDetails ? gmbDetails.name : "",
			logoUrl: agent.logoUrl || "",
			logoFile: null,
			logoChanged: false,
			phoneNumber: agent.phone ? agent.phone.number : "",
			defaultLanguage: GBM_LANGUAGE_OPTIONS[0], // Default is english for now
			conversationSettings: conversationalSettings,
			selectedConversationSetting: conversationalSettings
				? conversationalSettings[0]
				: { language: GBM.language.en, privacyPolicyUrl: "", welcomeMessage: "", offlineMessage: "", conversationStarters: [] },
			selectedConversationStarterIndex: 0,
			isNonLocalEntryPointEnabled: agent.entryPointConfigs.map(epc => epc.allowedEntryPoint).includes(GBM.entryPoint.nonLocal),
			// // Non Local Settings
			nonLocalPhoneNumber: nonLocalConfig && nonLocalConfig.phoneNumber && nonLocalConfig.phoneNumber.number ? nonLocalConfig.phoneNumber.number : "",
			enabledDomainInput: "",
			enabledDomains: nonLocalConfig && nonLocalConfig.enabledDomains ? nonLocalConfig.enabledDomains : [],
			regionCodes: ["US", "CA"], // All regions
			contactOption: {
				url: nonLocalConfig && nonLocalConfig.contactOption && nonLocalConfig.contactOption.url ? nonLocalConfig.contactOption.url : "",
				options:
					nonLocalConfig && nonLocalConfig.contactOption && nonLocalConfig.contactOption.options
						? nonLocalConfig.contactOption.options.map(option => {
								return { value: option, label: GBM.nonLocalConfig.contactOptions[option] };
						  })
						: []
			}
		});
	};

	setGmbDetails = async () => {
		let gmbDetails = await LocationService.getPlacesDetails(UserService.getActiveLocation().google_place_id);
		this.update({ gmbDetails });
	};

	fetchGbmMessengerIntegration = async () => {
		let integration = await GbmService.fetchGbmIntegration({ locationId: UserService.getActiveLocation().id });
		return integration;
	};

	generateConversationSettings = () => {
		let { selectedConversationSetting } = this.state;

		let settings = {};

		// For now just look at the selected Conversation setting (default is english)
		// Format the conversation starters
		let convoStarters = [];

		if (selectedConversationSetting && selectedConversationSetting.conversationStarters) {
			convoStarters = selectedConversationSetting.conversationStarters.map((cs, index) => {
				let suggestion = {};

				suggestion[cs.type.value] = {
					text: cs.text,
					postbackData: `Conversation Starter #${index + 1}`
				};

				if (cs.type.value === GBM.conversationStarterSuggestion.action) {
					suggestion[cs.type.value].openUrlAction = {
						url: cs.url
					};
				}

				return { suggestion: suggestion };
			});
		}

		settings[selectedConversationSetting.language] = {
			privacyPolicy: { url: selectedConversationSetting.privacyPolicyUrl },
			welcomeMessage: { text: selectedConversationSetting.welcomeMessage },
			offlineMessage: { text: selectedConversationSetting.offlineMessage },
			conversationStarters: convoStarters
		};

		return settings;
	};

	generateAgentObject = (isSubmit, timezone) => {
		let {
			agentName,
			logoUrl,
			defaultLanguage,
			phoneNumber,
			isNonLocalEntryPointEnabled,
			contactOption,
			nonLocalPhoneNumber,
			enabledDomains,
			enabledDomainInput,
			regionCodes
		} = this.state;

		let entryPointConfigs = [{ allowedEntryPoint: GBM.entryPoint.location }];
		if (isNonLocalEntryPointEnabled) {
			entryPointConfigs.push({ allowedEntryPoint: GBM.entryPoint.nonLocal });
		}

		let agentObject = {
			displayName: agentName,
			businessMessagesAgent: {
				customAgentId: UtilityService.uuid(), // Optional
				logoUrl: logoUrl,

				defaultLocale: defaultLanguage.value,
				conversationalSettings: this.generateConversationSettings(),
				entryPointConfigs,
				primaryAgentInteraction: {
					interactionType: GBM.interactionType.human,
					humanRepresentative: {
						// Set availability to "Always"
						humanMessagingAvailability: {
							hours: [
								{
									startTime: {
										hours: 0,
										minutes: 0
									},
									startDay: "MONDAY",
									endDay: "SUNDAY",
									endTime: {
										hours: 23,
										minutes: 59
									},
									timeZone: timezone ? timezone : "America/Los_Angeles"
								}
							]
						}
					}
				}
			}
		};

		if (isNonLocalEntryPointEnabled) {
			let domains = [...enabledDomains];

			if (isSubmit && enabledDomainInput && UtilityService.isValidHttpsUrl(enabledDomainInput)) {
				domains.push(enabledDomainInput);

				this.update({ enabledDomains: domains, enabledDomainInput: "" });
			}

			let formattedNonLocalNumber = UtilityService.formatPhoneNumber(nonLocalPhoneNumber);

			agentObject.businessMessagesAgent.nonLocalConfig = {
				callDeflectionPhoneNumbers: [{ number: formattedNonLocalNumber }],
				contactOption: {
					url: contactOption.url,
					options: contactOption.options.map(op => op.value)
				},
				enabledDomains: enabledDomains,
				phoneNumber: { number: formattedNonLocalNumber },
				regionCodes: regionCodes
			};
		}

		let formattedPhoneNumber = UtilityService.formatPhoneNumber(phoneNumber);
		if (formattedPhoneNumber && formattedPhoneNumber.length > 0) {
			agentObject.businessMessagesAgent.phone = { number: formattedPhoneNumber };
		}

		return agentObject;
	};

	isAllowedToEdit = () => {
		let user = UserService.get();
		return user && user.GroupPermission && user.GroupPermission.connect_locations;
	};

	isInputDisabled = (disabledIfAgentVerified = true) => {
		let { isAgentVerified } = this.state;

		if (disabledIfAgentVerified) {
			return isAgentVerified || !this.isAllowedToEdit();
		}

		return !this.isAllowedToEdit();
	};

	isValid = () => {
		let { brandName, loading, saving } = this.state;

		if (loading || saving) {
			return;
		}

		// Check if the user is allowed to edit the agent information
		if (!this.isAllowedToEdit()) {
			return false;
		}

		let agentObject = this.generateAgentObject();
		let agent = agentObject.businessMessagesAgent;

		if (!agentObject.displayName) {
			// Agent name required.
			return false;
		}

		if (!brandName) {
			// Brand name required.
			return false;
		}

		// Ensure all the required fields are there
		if (!agent.conversationalSettings) {
			// Conversational Settings Required
			return false;
		}

		if (!agent.defaultLocale || agent.defaultLocale.trim().length === 0) {
			// Default Locale Required
			return false;
		}

		if (!agent.primaryAgentInteraction) {
			// Primary Agent Required
			return false;
		}

		// Check if default language is set correctly
		if (agent.defaultLocale !== GBM.language.en && agent.defaultLocale !== GBM.language.fr) {
			// Default locale needs to be english or french
			return false;
		}

		// Check if there is a phone
		if (agent.phone && agent.phone.number && !UtilityService.isMobilePhoneValid(agent.phone.number)) {
			// Invalid Agent Phone Number
			return false;
		}

		// Deeper look into conversationalSettings
		for (const key in agent.conversationalSettings) {
			const setting = agent.conversationalSettings[key];

			// Check welcome message
			if (!setting.welcomeMessage) {
				// Invalid Conversational Settings. Welcome Message required.
				return false;
			}

			if (!setting.welcomeMessage.text) {
				// Conversational Settings - Welcome message text required.
				return false;
			}

			if (setting.welcomeMessage.text.length > 1000) {
				// Conversational Settings - Welcome message is too long. Maximum of 1000 characaters.
				return false;
			}

			// Check privacy policy
			if (!setting.privacyPolicy || !setting.privacyPolicy.url) {
				// Invalid Conversational Settings - Privacy Policy required.
				return false;
			}

			if (!UtilityService.isValidHttpsUrl(setting.privacyPolicy.url)) {
				// Invalid Conversational Settings - Invalid Privacy Policy.
				return false;
			}

			// Check conversation starters
			for (let i = 0; i < setting.conversationStarters.length; i++) {
				const convoStarter = setting.conversationStarters[i];

				if (convoStarter.suggestion.reply) {
					if (!convoStarter.suggestion.reply.text || !convoStarter.suggestion.reply.text.trim()) {
						// Conversational Settings - Conversation starter ${i+1} reply text required.
						return false;
					}
					if (convoStarter.suggestion.reply.text.length > 35) {
						// Conversational Settings - Conversation starter ${i+1} reply text too long. Maximum of 35 characters
						return false;
					}
				} else if (convoStarter.suggestion.action) {
					if (!convoStarter.suggestion.action.text || !convoStarter.suggestion.action.text.trim()) {
						// Conversational Settings - Conversation starter ${i+1} action text required.
						return false;
					}
					if (convoStarter.suggestion.action.text.length > 35) {
						// Conversational Settings - Conversation starter ${i+1} action text too long. Maximum of 35 characters
						return false;
					}
					if (!convoStarter.suggestion.action.openUrlAction || !convoStarter.suggestion.action.openUrlAction.url) {
						// Conversational Settings - Conversation starter ${i+1} action url required.
						return false;
					}
					if (!UtilityService.isValidHttpsUrl(convoStarter.suggestion.action.openUrlAction.url)) {
						// Conversational Settings - Conversation starter ${i+1} action url is invalid.
						return false;
					}
				}
			}
		}

		// Check entry point configs
		if (
			agent.entryPointConfigs &&
			!agent.entryPointConfigs.map(c => c.allowedEntryPoint).includes(GBM.entryPoint.location) &&
			!agent.entryPointConfigs.map(c => c.allowedEntryPoint).includes(GBM.entryPoint.nonLocal)
		) {
			// Entry points invalid
			return false;
		}

		// Check the Primary Agent Interaction
		if (!agent.primaryAgentInteraction.interactionType) {
			// Primary agent interaction - type required.
			return false;
		}

		// For now, we only create human types
		if (agent.primaryAgentInteraction.interactionType !== GBM.interactionType.human) {
			// Primary agent interaction - only human type supported.
			return false;
		}

		if (!agent.primaryAgentInteraction.humanRepresentative.humanMessagingAvailability) {
			// Primary agent interaction - messaging availability required.
			return false;
		}

		if (
			!agent.primaryAgentInteraction.humanRepresentative.humanMessagingAvailability.hours ||
			agent.primaryAgentInteraction.humanRepresentative.humanMessagingAvailability.hours.length === 0
		) {
			// Primary agent interaction - messaging availability hours required.
			return false;
		}

		// If there is a non local config
		if (agent.nonLocalConfig) {
			// Deeper look into nonlocal config
			if (!agent.nonLocalConfig.contactOption) {
				// Non local - Contact us information required.
				return false;
			}

			if (!agent.nonLocalConfig.contactOption.url) {
				// Non local - Contact us url required.
				return false;
			}

			if (!UtilityService.isValidHttpsUrl(agent.nonLocalConfig.contactOption.url)) {
				// Non local - Contact us url required.
				return false;
			}

			if (!agent.nonLocalConfig.contactOption.options || agent.nonLocalConfig.contactOption.options.length === 0) {
				// Non local - Contact options required.
				return false;
			}

			if (!agent.nonLocalConfig.enabledDomains || agent.nonLocalConfig.enabledDomains.length === 0) {
				// Non local - enabled domains required.
				return false;
			}

			if (!agent.nonLocalConfig.phoneNumber || !agent.nonLocalConfig.phoneNumber.number) {
				// Non local - phone number required.
				return false;
			}

			if (!UtilityService.isMobilePhoneValid(agent.nonLocalConfig.phoneNumber.number)) {
				// Non local - phone number invalid. E.164 format required.
				return false;
			}

			if (!agent.nonLocalConfig.regionCodes) {
				// Non local - regions required.
				return false;
			}
			if (!agent.nonLocalConfig.callDeflectionPhoneNumbers || agent.nonLocalConfig.callDeflectionPhoneNumbers.length === 0) {
				// Non local - call deflection numbers required.
				return false;
			}
			for (let i = 0; i < agent.nonLocalConfig.callDeflectionPhoneNumbers.length; i++) {
				const phone = agent.nonLocalConfig.callDeflectionPhoneNumbers[i];
				if (!UtilityService.isMobilePhoneValid(phone.number)) {
					// Non local - call deflection phone number is invalid
					return false;
				}
			}
		}

		return true;
	};

	onConfirmSubmit = () => {
		this.update({ onShowConfirmSubmitAlert: true });
	};

	onConfirmSubmitAlertClose = confirm => {
		this.update({ onShowConfirmSubmitAlert: false });
		if (confirm) {
			this.onSubmit();
		}
	};

	onErrorAlertClose = () => {
		this.update({ showErrorModal: false, errorMessages: [] });
	};

	onSubmit = async () => {
		if (!this.isValid()) {
			return;
		}

		let { brandName, logoUrl, logoFile, logoChanged, hasGbmAgent, agentName, isAgentVerified } = this.state;

		let locationId = UserService.getActiveLocation().id;

		await this.update({ saving: true });

		let timezone = await LocationService.fetchTimeZone();

		let agentObject = this.generateAgentObject(true, timezone);

		if (logoFile && logoChanged) {
			logoUrl = await GbmService.uploadAgentLogo({ locationId, file: logoFile });

			if (!logoUrl) {
				await this.update({ saving: false });
				ToastService.error("Error occurred trying to upload logo. Please try again.");
				return;
			}

			if (logoUrl.errorMessages) {
				ToastService.error(`${logoUrl.errorMessages}`);
				this.update({ saving: false });
				return;
			}

			agentObject.businessMessagesAgent.logoUrl = logoUrl;
		}

		// If we are updating the agent
		if (hasGbmAgent) {
			// TODO: 3236 Set limitations on what properties can be updated or not depending on if the Agent has been verified.

			if (isAgentVerified) {
				delete agentObject.businessMessagesAgent.entryPointConfigs;
				delete agentObject.businessMessagesAgent.nonLocalConfig;
			}

			// technicallly updateable, but we are not exposing this in the UI
			delete agentObject.businessMessagesAgent.defaultLocale; // only english is supported for now
			delete agentObject.businessMessagesAgent.primaryAgentInteraction;
			delete agentObject.businessMessagesAgent.customAgentId;

			// Only if we changed the logo add it to the agent.
			if (!(logoFile && logoChanged)) {
				delete agentObject.businessMessagesAgent.logo_url;
			}

			agentObject = {
				displayName: agentName,
				businessMessagesAgent: agentObject.businessMessagesAgent
			};

			// Updat the gbm agent
			let response = await GbmService.updateAgent({ locationId, agentObject });
			await this.update({ saving: false });

			if (!response) {
				ToastService.error("An error occurred updating agent. Please try again.");
				return;
			}

			if (response.errorMessages && response.errorMessages.length > 0) {
				await this.update({ showErrorModal: true, errorMessages: response.errorMessages, saving: false });
				return;
			}

			ToastService.info("Agent updated successfully.");
			this.resetComponent();
			return;
		}

		ToastService.info("Creating Agent...");

		agentObject.businessMessagesAgent.primaryAgentInteraction.humanRepresentative.humanMessagingAvailability.hours[0].timeZone = await LocationService.fetchTimeZone();

		let response = await GbmService.createAgent({ locationId: UserService.getActiveLocation().id, brandName, agentObject, logoUrl });

		if (!response) {
			ToastService.error("Something went wrong. Please try again.");
			await this.update({ saving: false });
			return;
		}

		if (response.errorMessages && response.errorMessages.length > 0) {
			await this.update({ showErrorModal: true, errorMessages: response.errorMessages, saving: false });
			return;
		}

		ToastService.info("Agent Created!");

		// We will try to automatically add the GMB location to this agent.
		await this.addLocation();

		await this.update({ saving: false });

		if (this.props.onSubmit) {
			this.props.onSubmit();
		}
	};

	addLocation = async () => {
		ToastService.info("Connecting GMB Location to Agent...");

		let response = await GbmService.addLocation({ locationId: UserService.getActiveLocation().id });
		if (!response) {
			ToastService.error("Error occurred adding your GMB location to the agent. Please try again.");
			return;
		}

		if (response.errorMessages) {
			ToastService.error(`${response.errorMessages}`);
			return;
		}

		ToastService.info("GMB Location connected successfully!");
	};

	/**
	 * Form event handlers
	 */
	onDefaultLanguageChange = option => {
		let { conversationSettings } = this.state;

		if (!conversationSettings.map(cs => cs.language).includes(option.value)) {
			conversationSettings.push({
				language: option.value,
				privacyPolicyUrl: "",
				welcomeMessage: "",
				offlineMessage: "",
				conversationStarters: []
			});
		}

		let conversationSettingsOptions = conversationSettings.map(conversationSetting => {
			return { value: conversationSetting.language, label: conversationSetting.language };
		});

		this.update({ defaultLanguage: option, conversationSettings, conversationSettingsOptions });
	};

	onConversationSettingsChange = async (language, event) => {
		let { selectedConversationSetting } = this.state;

		selectedConversationSetting[event.target.name] = event.target.value;

		await this.update({ selectedConversationSetting });
	};

	showInvalidUrlInput = url => {
		return url && url.length > 9 && !UtilityService.isValidHttpsUrl(url);
	};

	Switch = ({ field, checked, onChange, disabled }) => {
		return (
			<ReactSwitch
				id={field}
				height={22}
				width={38}
				checked={checked}
				uncheckedIcon={false}
				checkedIcon={false}
				onColor="#60A9FF"
				offColor="#c5c5c5"
				onChange={newValue => {
					onChange(field, newValue);
				}}
				disabled={disabled}
			/>
		);
	};

	onBackClick = () => {
		if (this.props.onClose) this.props.onClose();
	};

	onBrandNameChange = event => {
		this.update({ brandName: event.target.value });
	};

	onAgentNameChange = event => {
		this.update({ agentName: event.target.value });
	};

	onLogoInputChange = event => {
		if (event.target.files.length === 0) {
			return;
		}
		let imageFile = event.target.files[0];

		if (imageFile.size > GBM.agentLogo.sizeLimit) {
			ToastService.error("There is a file size limit of 50KB");
			return;
		}

		this.update({ logoFile: imageFile, logoChanged: true, logoUrl: URL.createObjectURL(imageFile) });
	};

	onAgentPhoneNumberChange = event => {
		this.update({ phoneNumber: event.target.value });
	};

	onAddConversationStarterClick = () => {
		let { selectedConversationSetting } = this.state;
		if (selectedConversationSetting.conversationStarters.length > 4) {
			ToastService.info("There is a limit of 5 conversation starters.");
			return;
		}
		selectedConversationSetting.conversationStarters.push({
			type: GBM_CONVERSATION_STARTER_SUGGESTION_OPTIONS[0], // Reply by default
			text: "",
			url: ""
		});
		this.update({ selectedConversationSetting, selectedConversationStarterIndex: selectedConversationSetting.conversationStarters.length - 1 });
	};

	onDeleteConversationStarterClick = index => {
		let { selectedConversationSetting } = this.state;
		let starters = [...selectedConversationSetting.conversationStarters];
		starters.splice(index, 1);
		selectedConversationSetting.conversationStarters = starters;
		this.update({ selectedConversationSetting });
	};

	onConversationStarterTypeChange = (convoStarter, option) => {
		let { selectedConversationSetting } = this.state;
		convoStarter.type = option;
		this.update({ selectedConversationSetting });
	};

	onConversationStarterChange = (convoStarter, event) => {
		let { selectedConversationSetting } = this.state;
		convoStarter.text = event.target.value;
		this.update({ selectedConversationSetting });
	};

	onConversationStarterUrlChange = (convoStarter, event) => {
		let { selectedConversationSetting } = this.state;
		convoStarter.url = event.target.value;
		this.update({ selectedConversationSetting });
	};

	onEnableNonLocalChange = () => {
		this.update({ isNonLocalEntryPointEnabled: !this.state.isNonLocalEntryPointEnabled });
	};

	onEnabledDomainChange = event => {
		this.update({ enabledDomainInput: event.target.value });
	};

	onEnabledDomainUpdate = (event, index) => {
		let { enabledDomains } = this.state;
		enabledDomains[index] = event.target.value;
		this.update({ enabledDomains });
	};

	onAddEnabledDomain = () => {
		let { enabledDomains, enabledDomainInput } = this.state;
		if (!UtilityService.isValidHttpsUrl(enabledDomainInput)) {
			ToastService.info("Enter a valid https url.");
			return;
		}
		enabledDomains.unshift(enabledDomainInput);
		this.update({ enabledDomains, enabledDomainInput: "" });
	};

	onDeleteEnabledDomain = index => {
		let { enabledDomains } = this.state;

		let domains = [...enabledDomains];
		domains.splice(index, 1);
		this.update({ enabledDomains: domains });
	};

	onNonLocalPhoneNumberChange = event => {
		this.update({ nonLocalPhoneNumber: event.target.value });
	};

	onNonLocalContactUrlChange = event => {
		let { contactOption } = this.state;
		contactOption.url = event.target.value;
		this.update({ contactOption: contactOption });
	};

	onContactOptionChange = option => {
		let { contactOption } = this.state;
		let cOptions = contactOption.options.slice();
		const index = cOptions.map(op => op.value).indexOf(option.value);

		if (index > -1) {
			cOptions.splice(index, 1);
		} else {
			cOptions.push(option);
		}
		contactOption.options = cOptions;
		this.update({ contactOption });
	};

	renderNonLocalConfig = () => {
		let {
			isNonLocalEntryPointEnabled,
			nonLocalPhoneNumber,
			contactOption,
			selectableContactOptions,
			enabledDomainInput,
			enabledDomains,
			isAgentVerified
		} = this.state;

		return (
			<>
				<div key="enable-non-local" className="gbm__form__options__field">
					<div className="gbm__form__options__field__label">
						<div className="gbm__form__options__field__label__title">Enable Non Local Entry Points (Optional)</div>
						<div className="gbm__form__options__field__label__description">
							Non-local entry points includes all entry points that don't rely on locations such as, Featured snippets, Sitelinks, Google Ads, Phone.
							<Icon.Info size={14} data-tip data-for="rtt-non-local-entry-points" />
							<ReactTooltip
								id="rtt-non-local-entry-points"
								className="mb-react-tooltip mb-react-tooltip--medium"
								type="info"
								effect="solid"
								place="right"
								arrowColor="#333"
							>
								Non Local entry point is not modifiable after agent verification.
							</ReactTooltip>
						</div>
					</div>
					<this.Switch field="enableNonLocal" checked={isNonLocalEntryPointEnabled} onChange={this.onEnableNonLocalChange} disabled={this.isInputDisabled()} />
				</div>
				{isNonLocalEntryPointEnabled && (
					<div key="non-local-config-fields">
						<div className="gbm__form__options__field gbm__field--no-border">
							<div className="gbm__form__options__field__label">
								<div className="gbm__form__options__field__label__title">Enabled Domains</div>
								<div className="gbm__form__options__field__label__description">
									Domains must be unique to each verified agent.
									<Icon.Info size={14} data-tip data-for="rtt-non-local-enabled-domains" />
									<ReactTooltip
										id="rtt-non-local-enabled-domains"
										className="mb-react-tooltip mb-react-tooltip--medium"
										type="info"
										effect="solid"
										place="right"
										arrowColor="#333"
									>
										Domains to enable the Sitelinks entry point. At least 1 valid https url is required.
									</ReactTooltip>
								</div>
							</div>
						</div>
						<div>
							<div className={`gbm__form__nl__domains ${enabledDomains && enabledDomains.length > 0 ? "gbm__form__nl__domains--no-border" : ""}`}>
								<div className="gbm__form__nl__domains__add-input">
									<Input
										name={`enabled-domain`}
										id={`enabled-domain`}
										type="text"
										onChange={this.onEnabledDomainChange}
										value={enabledDomainInput}
										placeholder="https://"
										disabled={this.isInputDisabled()}
										invalid={this.showInvalidUrlInput(enabledDomainInput)}
									/>
								</div>
								{(!isAgentVerified || this.isAllowedToEdit()) && (
									<Action id={`add-enabled-domain`} label="Add Domain" icon={Icon.Plus} onClick={this.onAddEnabledDomain} />
								)}
							</div>

							{enabledDomains && enabledDomains.length > 0 && (
								<div className="gbm__form__nl__domains__list">
									{enabledDomains.map((domain, index) => (
										<div key={domain} className="gbm__form__nl__domains__list__item">
											<input
												name={`enabled-domain`}
												id={`enabled-domain`}
												type="text"
												onChange={event => this.onEnabledDomainUpdate(event, index)}
												value={domain}
												className="Common__input gbm__form__nl__domains__remove-input"
												placeholder="https://"
												disabled={this.isInputDisabled()}
											/>
											{(!isAgentVerified || this.isAllowedToEdit()) && (
												<Action id={`delete-enabled-domain-${index}`} label="Delete" icon={Icon.Trash2} onClick={() => this.onDeleteEnabledDomain(index)} />
											)}
										</div>
									))}
								</div>
							)}
						</div>
						<div key={`non-local-phone-number`} className="gbm__form__options__field">
							<div className="gbm__form__options__field__label">
								<div className="gbm__form__options__field__label__title">Phone Number</div>
								<div className="gbm__form__options__field__label__description">
									Overrides the phone number for conversations started from non-local entry points.
								</div>
							</div>
							<div className="gbm__form__options__field__input">
								<Input
									name={`non-local-phone-number`}
									id={`non-local-phone-number`}
									type="text"
									onChange={this.onNonLocalPhoneNumberChange}
									value={nonLocalPhoneNumber}
									placeholder="Phone ..."
									disabled={this.isInputDisabled()}
									invalid={nonLocalPhoneNumber && nonLocalPhoneNumber.length > 5 && !UtilityService.isMobilePhoneValid(nonLocalPhoneNumber)}
								/>
							</div>
						</div>
						<div key={`non-local-contact-option-url`} className="gbm__form__options__field">
							<div className="gbm__form__options__field__label">
								<div className="gbm__form__options__field__label__title">Contact Us</div>
								<div className="gbm__form__options__field__label__description">
									A URL for your Contact Us page.
									<Icon.Info size={14} data-tip data-for="non-local-contact-us-url" />
									<ReactTooltip
										id="non-local-contact-us-url"
										className="mb-react-tooltip mb-react-tooltip--medium"
										type="info"
										effect="solid"
										place="right"
										arrowColor="#333"
									>
										A valid https url is required.
									</ReactTooltip>
								</div>
							</div>
							<div className="gbm__form__options__field__input">
								<Input
									name={contactOption.url}
									id={contactOption.url}
									type="text"
									onChange={this.onNonLocalContactUrlChange}
									value={contactOption.url}
									placeholder="https://"
									disabled={this.isInputDisabled()}
								/>
							</div>
						</div>
						<div className="gbm__form__options__field gbm__field--no-border">
							<div className="gbm__form__options__field__label">
								<div className="gbm__form__options__field__label__title">Contact Options</div>
								<div className="gbm__form__options__field__label__description">Contact options available for the specified Contact Us page URL.</div>
							</div>
						</div>
						<div className="gbm__form__options__field">
							<div key={`non-local-contact-options`} className="gbm__checkboxes">
								{selectableContactOptions.map(option => (
									<div className="gbm__checkboxes__option" key={option.value}>
										<Checkbox
											id={`contact-option-${option.value}`}
											name={`contact-option-${option.value}`}
											checked={contactOption.options.map(op => op.value).includes(option.value)}
											onChange={() => this.onContactOptionChange(option)}
											disabled={this.isInputDisabled()}
										/>
										<div className="gbm__checkboxes__option__label">{option.label}</div>
									</div>
								))}
							</div>
						</div>
					</div>
				)}
			</>
		);
	};

	renderConversationSettings = () => {
		let { conversationSettings, selectedConversationSetting, selectedConversationStarterIndex } = this.state;

		if (!conversationSettings || conversationSettings.length === 0) {
			return null;
		}

		// For now we will only do English. Technically an agent can have multiple language settings
		return (
			<div key={`conversation-settings-${selectedConversationSetting.language}`}>
				<div className="gbm__form__options__field">
					<div className="gbm__form__options__field__label">
						<div className="gbm__form__options__field__label__title">Welcome Message</div>
						<div className="gbm__form__options__field__label__description">A default welcome message greeting for users who engage with your agent.</div>
					</div>
					<div className="gbm__form__options__field__input">
						<Input
							name="welcomeMessage"
							id={`${selectedConversationSetting.language}-welcome-message`}
							type="textarea"
							onChange={event => this.onConversationSettingsChange(selectedConversationSetting.language, event)}
							value={selectedConversationSetting.welcomeMessage}
							placeholder="Welcome..."
							disabled={this.isInputDisabled(false)}
							maxLength={1000}
						/>
					</div>
				</div>

				<div className="gbm__form__options__field">
					<div className="gbm__form__options__field__label">
						<div className="gbm__form__options__field__label__title">Privacy Policy</div>
						<div className="gbm__form__options__field__label__description">
							The URL of your Privacy Policy.
							<Icon.Info size={14} data-tip data-for={`rtt-${selectedConversationSetting.language}-privacy-policy-url`} />
							<ReactTooltip
								id={`rtt-${selectedConversationSetting.language}-privacy-policy-url`}
								className="mb-react-tooltip mb-react-tooltip--medium"
								type="info"
								effect="solid"
								place="right"
								arrowColor="#333"
							>
								A valid https url is required. If no privacy policy is available from the location, you may use https://www.demandhub.co/privacy/.
							</ReactTooltip>
						</div>
					</div>
					<div className="gbm__form__options__field__input">
						<Input
							name="privacyPolicyUrl"
							id={`${selectedConversationSetting.language}-privacy-policy`}
							type="textarea"
							onChange={event => this.onConversationSettingsChange(selectedConversationSetting.language, event)}
							value={selectedConversationSetting.privacyPolicyUrl}
							placeholder="https://"
							disabled={this.isInputDisabled(false)}
							invalid={this.showInvalidUrlInput(selectedConversationSetting.privacyPolicyUrl)}
						/>
					</div>
				</div>

				{/* By default, we set hours availability to be always, so we actually dont need this message option. */}
				{/* <div className="gbm__form__options__field">
					<div className="gbm__form__options__field__label">
						<div className="gbm__form__options__field__label__title">Offline Message (Optional)</div>
						<div className="gbm__form__options__field__label__description">A Message to be displayed during non-operational hours.</div>
					</div>
					<div className="gbm__form__options__field__input">
						<Input
							name="offlineMessage"
							id={`${selectedConversationSetting.language}-offline-message`}
							type="text"
							onChange={event => this.onConversationSettingsChange(selectedConversationSetting.language, event)}
							value={selectedConversationSetting.offlineMessage}
							placeholder="Offline message..."
							disabled={false}
						/>
					</div>
				</div> */}

				<div className="gbm__form__options__field gbm__field--no-border">
					<div className="gbm__form__options__field__label">
						<div className="gbm__form__options__field__label__title">Conversation Starters (Optional)</div>
						<div className="gbm__form__options__field__label__description">
							Conversation starters suggestions offered to the user when starting a conversation with the agent.
							<Icon.Info size={14} data-tip data-for="rtt-agent-conversation-starters" />
							<ReactTooltip
								id="rtt-agent-conversation-starters"
								className="mb-react-tooltip mb-react-tooltip--medium"
								type="info"
								effect="solid"
								place="right"
								arrowColor="#333"
							>
								A maximum of 5 conversation starters are available. Suggestions appear as vertically-stacked list of options.
							</ReactTooltip>
						</div>
					</div>
					<div className="gbm__form__options__field__input">
						{this.isAllowedToEdit() && (
							<Action id={`add-conversation-starter`} label="Add Conversation Starter" icon={Icon.Plus} onClick={this.onAddConversationStarterClick} />
						)}
					</div>
				</div>
				<div className="gbm__form__conversation-starters-list">
					{selectedConversationSetting &&
						selectedConversationSetting.conversationStarters &&
						selectedConversationSetting.conversationStarters.map((convoStarter, index) => (
							<div className="gbm__form__conversation-starters-list__item" key={index} onClick={() => this.update({ selectedConversationStarterIndex: index })}>
								<div className="gbm__form__conversation-starters-list__item__header">
									<div className="gbm__form__conversation-starters-list__item__header__title">Conversation Starter #{index + 1}</div>
									<div className="gbm__form__conversation-starters-list__item__header__actions">
										{this.isAllowedToEdit() && (
											<Action
												id={`delete-conversation-starter-${index}`}
												label="Delete"
												icon={Icon.Trash2}
												onClick={() => this.onDeleteConversationStarterClick(index)}
											/>
										)}
									</div>
								</div>
								{selectedConversationStarterIndex !== index && (
									<div className="gbm__form__conversation-starters-list__item__preview">
										{convoStarter.type.label} - {convoStarter.text}
									</div>
								)}
								{selectedConversationStarterIndex === index && (
									<>
										<div>Type</div>
										<Select
											id={`conversation-starter-${index + 1}`}
											className="gbm__form__conversation-starters-list__item__select"
											onChange={option => this.onConversationStarterTypeChange(convoStarter, option)}
											value={convoStarter.type}
											isClearable={false}
											options={GBM_CONVERSATION_STARTER_SUGGESTION_OPTIONS}
											isDisabled={this.isInputDisabled(false)}
										/>
										<div>Text</div>
										<Input
											id={`conversation-starter-${index + 1}-text`}
											name="conversation-starter-text"
											type="text"
											value={convoStarter.text}
											onChange={event => this.onConversationStarterChange(convoStarter, event)}
											placeholder="Hello ..."
											maxLength={35}
											disabled={this.isInputDisabled(false)}
										/>
										{convoStarter.type.value === GBM.conversationStarterSuggestion.action && (
											<>
												<div>
													Url <Icon.Info size={14} data-tip data-for={`rtt-conversation-starter-${index + 1}-url`} />
													<ReactTooltip
														id={`rtt-conversation-starter-${index + 1}-url`}
														className="mb-react-tooltip mb-react-tooltip--medium"
														type="info"
														effect="solid"
														place="right"
														arrowColor="#333"
													>
														A valid https url is required.
													</ReactTooltip>
												</div>
												<Input
													id={`conversation-starter-${index + 1}-url`}
													name="conversation-starter-url"
													type="text"
													value={convoStarter.url}
													onChange={event => this.onConversationStarterUrlChange(convoStarter, event)}
													placeholder="https://"
													invalid={this.showInvalidUrlInput(convoStarter.url)}
													disabled={this.isInputDisabled(false)}
												/>
											</>
										)}
									</>
								)}
							</div>
						))}
				</div>
			</div>
		);
	};

	renderBrandAndAgentSettings = () => {
		let { brandName, agentName, locationName, logoUrl, messengerIntegration, phoneNumber, isAgentVerified } = this.state;

		return (
			<>
				<div className="gbm__form__options__field">
					<div className="gbm__form__options__field__label">
						<div className="gbm__form__options__field__label__title">Brand Name</div>
						<div className="gbm__form__options__field__label__description">A brand name.</div>
					</div>
					<div className="gbm__form__options__field__input">
						<Input
							name={`brand-name`}
							id={`brand-name`}
							type="text"
							onChange={this.onBrandNameChange}
							value={brandName}
							placeholder="Brand name..."
							disabled={true}
						/>
					</div>
				</div>
				<div className="gbm__form__options__field">
					<div className="gbm__form__options__field__label">
						<div className="gbm__form__options__field__label__title">Agent Display Name</div>
						<div className="gbm__form__options__field__label__description">
							An agent name that is visible to users that interact with your agent. <Icon.Info size={14} data-tip data-for="rtt-agent-display-name" />
							<ReactTooltip
								id="rtt-agent-display-name"
								className="mb-react-tooltip mb-react-tooltip--medium"
								type="info"
								effect="solid"
								place="right"
								arrowColor="#333"
							>
								Display name must contain '{locationName}', the GMB location name. It is important for verification.
								<hr />
								Not modifiable after agent verification.
							</ReactTooltip>
						</div>
					</div>
					<div className="gbm__form__options__field__input">
						<Input
							name={`agent-name`}
							id={`agent-name`}
							type="text"
							onChange={this.onAgentNameChange}
							value={agentName}
							placeholder="Agent name..."
							invalid={agentName && !agentName.includes(locationName)}
							disabled={this.isInputDisabled()}
						/>
					</div>
				</div>
				<div className={`gbm__form__options__field ${logoUrl ? "gbm__field--no-border" : ""}`}>
					<div className="gbm__form__options__field__label">
						<div className="gbm__form__options__field__label__title">Agent Logo (Optional)</div>
						<div className="gbm__form__options__field__label__description">
							Logo visible to users that interact with your agent. <Icon.Info size={14} data-tip data-for="rtt-agent-logo" />
							<ReactTooltip
								id="rtt-agent-logo"
								className="mb-react-tooltip mb-react-tooltip--medium"
								type="info"
								effect="solid"
								place="right"
								arrowColor="#333"
							>
								A square logo (1024px x 1024px is optimal). A maximum of 50KB. Only jpeg and png images accepted. The logo should be discoverable or be similar
								to a logo withing your website. Once created or updated. It may take a minute or two to show the updated image.
								<hr />
								Not modifiable after agent verification.
							</ReactTooltip>
						</div>
					</div>
					<div className="gbm__form__options__field__input">
						<Input
							name={`agent-logo`}
							id={`agent-logo`}
							type="file"
							accept="image/jpeg, image/png"
							onChange={this.onLogoInputChange}
							disabled={this.isInputDisabled()}
							fileLabel={"Upload Image"}
						/>
					</div>
					<br />
				</div>
				{logoUrl && (
					<div className="gbm__form__agent-logo">
						<img alt="" src={logoUrl} className="gbm__form__agent-logo__img" />
					</div>
				)}
				<div className="gbm__form__options__field">
					<div className="gbm__form__options__field__label">
						<div className="gbm__form__options__field__label__title">Phone Number (Optional)</div>
						<div className="gbm__form__options__field__label__description">The phone number for the agent to display.</div>
					</div>
					<div className="gbm__form__options__field__input">
						<Input
							name={`phone-number`}
							id={`phone-number`}
							type="text"
							onChange={this.onAgentPhoneNumberChange}
							value={phoneNumber}
							placeholder="Phone number..."
							disabled={this.isInputDisabled(false)}
							invalid={phoneNumber && phoneNumber.length > 7 && !UtilityService.isMobilePhoneValid(phoneNumber)}
						/>
					</div>
				</div>
			</>
		);
	};

	renderActions = () => {
		let { hasGbmAgent, saving } = this.state;

		let saveText = "Save";

		if (hasGbmAgent && saving) {
			saveText = "Saving ...";
		} else if (!hasGbmAgent && saving) {
			saveText = "Creating ...";
		} else if (!hasGbmAgent) {
			saveText = "Create";
		}

		return (
			<div className="gbm__actions">
				<div className={`mb-button mb-button--fit ${this.isValid() ? "" : "mb-button--disabled"}`} onClick={this.onConfirmSubmit}>
					{saveText}
				</div>
			</div>
		);
	};

	renderForm = () => {
		return (
			<div className="gbm__form__options">
				{this.renderBrandAndAgentSettings()}
				<div key="conversation-settings">
					<h2 className="text-center">Conversation Settings</h2>
				</div>
				{this.renderConversationSettings()}
				{/* Currently disabled due to automated verification */}
				{/* <div>
					<h2 className="text-center">Non Local Entry Point</h2>
				</div>
				{this.renderNonLocalConfig()} */}
				{this.renderActions()}
			</div>
		);
	};

	renderLoading = () => {
		return (
			<div className="gbm__form__loading">
				<ContentLoader height={1000} width={"500"}>
					{/* Form sections */}
					<rect x="0" y="20" rx="5" ry="5" width="500" height="80" />
					<rect x="0" y="140" rx="5" ry="5" width="500" height="80" />
					<rect x="0" y="260" rx="5" ry="5" width="500" height="80" />
					<rect x="0" y="380" rx="5" ry="5" width="500" height="80" />
					<rect x="0" y="500" rx="5" ry="5" width="500" height="80" />
					<rect x="0" y="620" rx="5" ry="5" width="500" height="80" />
					<rect x="0" y="740" rx="5" ry="5" width="500" height="80" />
					<rect x="0" y="860" rx="5" ry="5" width="500" height="80" />
					{/* Save button */}
					<rect x="410" y="960" rx="5" ry="5" width="90" height="40" />
				</ContentLoader>
			</div>
		);
	};

	render = () => {
		let { hasGbmAgent, loading, loadingError, showErrorModal, errorMessages, onShowConfirmSubmitAlert } = this.state;

		if (!loading && loadingError) {
			return <div className="text-center">An error occurred fetching the agent data. Please try again.</div>;
		}

		return (
			<>
				<div className="gbm__form">
					<div className="gbm__form__header">
						<div className="gbm__form__header__back">
							{!loading && hasGbmAgent && <Action id="back" name="back" icon={Icon.ArrowLeft} label="Back" onClick={this.onBackClick} />}
						</div>
						<h1 className="gbm__form__header__text">Agent Information</h1>
					</div>
					{loading && this.renderLoading()}
					{!loading && !hasGbmAgent && <div className="Common__color--dh-blue text-center">Complete setting up your agent!</div>}
					{!loading && this.renderForm()}
				</div>
				<Alert type="error" show={showErrorModal} title="Error saving Agent." confirm="OK" onClose={this.onErrorAlertClose}>
					<div>There was an error saving the agent information. See the following errors:</div>
					<br />
					<ul>{errorMessages && errorMessages.length > 0 && errorMessages.map((message, index) => <li key={index}>{message}</li>)}</ul>
				</Alert>
				<Alert
					type="info"
					show={onShowConfirmSubmitAlert}
					title={`Confirm ${!hasGbmAgent ? "Create" : "Update"}`}
					confirm="Yes"
					cancel="No"
					onClose={this.onConfirmSubmitAlertClose}
				>
					Are you sure you would like to {!hasGbmAgent ? "create" : "update"} this agent? Please confirm that the information is correct.
				</Alert>
			</>
		);
	};
}

export default AgentForm;
