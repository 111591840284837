import React, { Component } from "react";
import UserService from "../../services/UserService";

import "../../styles/css/components/commons/profile-picture-legacy.css";

class ProfilePictureLegacy extends Component {
	state = {
		profilePictureUrl: UserService.get() ? UserService.get().profile_picture_url : ""
	};

	handleUpdateProfilePictureOnChange(e) {
		if (e.target.files.length === 0) {
			return;
		}
		if (this.props.handleProfilePictureOnChange) {
			this.props.handleProfilePictureOnChange(e.target.files[0]);
		}
		this.setState({ profilePictureUrl: URL.createObjectURL(e.target.files[0]) });
	}
	handleDeleteProfilePictureOnChange(e) {
		if (this.props.handleProfilePictureOnChange) {
			this.props.handleProfilePictureOnChange("");
		}
		this.setState({ profilePictureUrl: "" });
	}
	render() {
		const { profilePictureUrl } = this.state;
		let user = UserService.get();
		var firstNameInitial = user.first_name ? user.first_name.slice(0, 1) : "";
		var lastNameInitial = user.last_name ? user.last_name.slice(0, 1) : "";

		const userInitials = firstNameInitial + lastNameInitial;
		return (
			<div className="ProfilePicture__container">
				<div className="circle">
					{profilePictureUrl === "" ? (
						<div className="profile-pic-initials">{userInitials}</div>
					) : (
						<img className="profile-pic" src={profilePictureUrl} alt="Profile" />
					)}
				</div>
				<div className="p-image">
					{profilePictureUrl === "" ? (
						<React.Fragment>
							<label htmlFor="image-upload">
								<i className="fa fa-pencil upload-button" />
							</label>
							<input className="file-upload" id="image-upload" onChange={e => this.handleUpdateProfilePictureOnChange(e)} type="file" accept="image/*" />
						</React.Fragment>
					) : (
						<React.Fragment>
							<label htmlFor="image-upload" onClick={e => this.handleDeleteProfilePictureOnChange(e)}>
								<i className="fa fa-times upload-button" />
							</label>
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}
}

export default ProfilePictureLegacy;
