import React, { Component } from "react";
import * as Icon from "react-feather";
import posed from "react-pose";
import { withTranslation } from "react-i18next";

import { KEYS } from "../../constants/Messenger";

import "../../styles/css/components/commons/message-variables-dropdown.css";

const Box = posed.div({
	visible: {
		y: props => props.visibleY || 0,
		x: props => props.visibleX || 0,
		z: props => props.visibleZ || 0
	},
	hidden: {
		y: props => props.hiddenY || -250
	}
});

class MessageVariablesDropdown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchInput: "",
			selectedIndex: 0,
			variables: this.props.variables,
			filteredVariables: this.props.variables,
			showAbove: this.props.showAbove ? this.props.showAbove : false,
			width: this.props.width ? this.props.width : "280px"
		};

		this.searchInput = null;
		this.current = null;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		this.resetComponent();
		document.addEventListener("keydown", this.onKey);
		document.addEventListener("mousedown", this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKey);
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	async componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (JSON.stringify(prevProps.variables) !== JSON.stringify(this.props.variables)) {
			await this.update({
				variables: this.props.variables
			});
		}
		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	handleClick = e => {
		if (this.variableDropdown && this.variableDropdown.contains(e.target)) {
			// the click is in the component
			return;
		}
		// If you clicked outside of the dropdown. Close the dropdown
		this.onClose();
	};

	resetComponent = () => {
		// If it's open focus on the search input
		if (this.props.show) {
			if (this.searchInput) {
				this.searchInput.focus();
			}
			this.setState({ searchInput: "", selectedIndex: 0, filteredVariables: this.state.variables });
		} else {
			this.onClose();
		}
	};

	onClose = () => {
		this.setState({
			searchInput: "",
			filteredVariables: this.state.variables,
			selectedIndex: 0
		});
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onKey = e => {
		if (e.keyCode === KEYS.esc) {
			e.preventDefault();
			this.onClose();
		}
	};

	onSearchInput = async e => {
		await this.update({ searchInput: e.target.value });
		const filteredArray = this.state.variables.filter(vars => {
			if (vars.name.toLowerCase().includes(this.state.searchInput.toLowerCase())) {
				return true;
			}
			return false;
		});
		this.setState({
			filteredVariables: filteredArray,
			selectedIndex: 0
		});
	};

	onEnter = async e => {
		if (this.props.show && (e.keyCode === KEYS.up || e.keyCode === KEYS.down || e.keyCode === KEYS.enter || e.keyCode === KEYS.tab)) {
			e.preventDefault();
			this.triggerKeyboardEvent(e.keyCode);
		}
	};

	async triggerKeyboardEvent(keyCode) {
		let { selectedIndex, filteredVariables } = this.state;

		// *** Scrapped for now ***
		// if you press semicolon or backspace when the seaerch bar is empty, add a ":" to the text
		// This is our way of escaping ":"
		// if (searchInput === "" && (keyCode == KEYS.semiColon || keyCode == KEYS.backspace)) {
		// 	this.onVariableSelected(":");
		// 	this.onClose();
		// 	return;
		// }

		// For selecting a message vairable
		if (keyCode === KEYS.enter || keyCode === KEYS.tab) {
			let variable = filteredVariables[selectedIndex];
			if (variable) {
				this.onVariableSelected(variable.pattern);
			}
			return;
		}

		// Navigating the dropdown with arrow keys
		if (keyCode === KEYS.down) {
			selectedIndex++;
		} else if (keyCode === KEYS.up) {
			selectedIndex--;
		}

		if (selectedIndex < 0) {
			selectedIndex = 0;
		} else if (selectedIndex >= filteredVariables.length) {
			selectedIndex = filteredVariables.length - 1;
		}

		await this.update({
			selectedIndex
		});
		if (this.current) {
			this.current.scrollIntoView(false);
		}
	}

	onVariableSelected(pattern) {
		if (this.props.onSelect) {
			this.props.onSelect(pattern);
		}
	}

	render() {
		const { show, t } = this.props;
		const { variables, searchInput, filteredVariables, selectedIndex, width } = this.state;
		if (!show) {
			return <span />;
		}

		let { visibleY, visibleX, visibleZ, hiddenY } = this.props;

		return (
			<Box
				ref={ref => (this.variableDropdown = ref)}
				className={`dh-variables-dropdown-container ${show ? "dh-variables-dropdown-container--collapsed" : "dh-variables-dropdown-container--expanded"}`}
				pose={show ? "visible" : "hidden"}
				visibleY={visibleY}
				visibleX={visibleX}
				visibleZ={visibleZ}
				hiddenY={hiddenY}
				style={{ width: width, minWidth: width, maxWidth: width }}
			>
				<div className="dh-variables-dropdown-search-box">
					<div
						className="dh-variables-dropdown-search-box-container"
						onClick={() => {
							if (this.searchInput) {
								this.searchInput.focus();
							}
						}}
					>
						<div>
							<input
								ref={input => {
									this.searchInput = input;
								}}
								type="text"
								className="dh-variables-dropdown-search-box-input"
								placeholder={t("Search variables...")}
								value={searchInput}
								onChange={this.onSearchInput}
								onKeyDown={this.onEnter}
							/>
						</div>
						<div
							className="dh-variables-dropdown-messenger-search-close"
							onClick={() => {
								this.setState({
									searchInput: "",
									filteredVariables: variables,
									selectedIndex: 0
								});
							}}
						>
							{searchInput ? <Icon.X size={18} /> : <Icon.Search size={18} />}
						</div>
					</div>
				</div>
				<div className="dh-variables-dropdown-content">
					{filteredVariables &&
						filteredVariables.map((variable, index) => (
							<div
								key={variable.id}
								ref={ref => {
									if (selectedIndex === index) {
										this.current = ref;
									}
								}}
								className={`dh-variables-dropdown-item ${selectedIndex === index ? "dh-variables-dropdown-item--selected" : ""}`}
								onClick={() => this.onVariableSelected(variable.pattern)}
							>
								<div data-tip data-for={`${variable.id}-tooltip`}>
									<b>{variable.name}</b>
									<br />
									{/* TODO: somehow allow the user to see a description of the variable? */}
									{/* {selectedIndex === index && <small>{variable.description}</small>} */}
								</div>
								<div>{variable.pattern}</div>
							</div>
						))}
				</div>
			</Box>
		);
	}
}

export default withTranslation(null, { withRef: true })(MessageVariablesDropdown);
