import React from "react";

import "../../styles/css/components/commons/card.css";

class DHCard extends React.PureComponent {
	render() {
		return <div className="dh-card">{this.props.children}</div>;
	}
}
export default DHCard;
