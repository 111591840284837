import React, { Component } from "react";
import UserService from "../../services/UserService";
import moment from "moment";
import Select from "react-select";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

import TagService from "../../services/TagService";
import MessagesService from "../../services/MessagesService";

import { OPERATORS, STATUS } from "../../constants/CommonConstants";
import { CONTACT_STATES_OPTIONS, CONTACT_BLOCK_OPTIONS } from "../../constants/Contacts";

import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

import "../../styles/css/scenes/contact-selector.css";

const INCLUDE_OPTIONS = [
	{ label: "Contact IN last activity range", value: "include" },
	{ label: "Contact NOT IN last activity range", value: "exclude" }
];

const LAST_ACTIVITY_OPTIONS = [
	{ label: "All Time", value: "all" },
	{ label: "Yesterday", value: "yesterday" },
	{ label: "Past 7 Days", value: "last_7_days" },
	{ label: "This Month", value: "this_month" },
	{ label: "Custom Date Range", value: "custom" }
];

class ContactSelectorFilters extends Component {
	constructor(props) {
		super(props);

		let {
			selectBlocked,
			selectedContactStatus,
			selectedTagsFilter,
			selectedGroupsFilter,
			selectedAssignedUsers,
			selectedLastActivityDate,
			lastActivityInclude,
			lastActivityStartDate,
			lastActivityEndDate
		} = this.getUrlParams();

		this.state = {
			tagFilterOptions: [],
			selectedTagsFilter,
			groupFilterOptions: [],
			selectedGroupsFilter,
			userFilterOptions: [],
			selectedAssignedUsers,
			lastActivityFilterOptions: LAST_ACTIVITY_OPTIONS,
			selectedLastActivityDate,
			lastActivityIncludeOptions: INCLUDE_OPTIONS,
			lastActivityInclude,
			lastActivityStartDate,
			lastActivityEndDate,
			showHeader: this.props.showHeader ? this.props.showHeader : false,
			selectedContactStatus: selectedContactStatus,
			selectBlocked: selectBlocked
		};
	}

	async componentDidMount() {
		await this.fetchAssignableUsers();
		await this.fetchTags();
		await this.setFilterStates();
		this.refreshContacts();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentWillUpdate(nextProps) {
		if (this.props.tagsChange !== nextProps.tagsChange) {
			this.fetchTags();
		}
	}

	async fetchTags() {
		try {
			let tags = await TagService.getTags();
			let groups = await TagService.getTags(null, {
				type: "group"
			});
			tags = tags.map(t => {
				return { label: t.name, value: t.id };
			});
			groups = groups.map(g => {
				return { label: g.name, value: g.id };
			});
			this.setState({ tagFilterOptions: tags, groupFilterOptions: groups });
		} catch (error) {
			console.log(error);
		}
	}

	async setFilterStates() {
		let { selectedAssignedUsers, selectedTagsFilter, selectedGroupsFilter, tagFilterOptions, groupFilterOptions, userFilterOptions } = this.state;

		let selectedTags = tagFilterOptions.filter(option => selectedTagsFilter.includes(option.value));
		let selectedGroups = groupFilterOptions.filter(option => selectedGroupsFilter.includes(option.value));
		let selectedUsers = userFilterOptions.filter(option => selectedAssignedUsers.includes(option.value));

		await this.update({
			selectedTagsFilter: selectedTags,
			selectedGroupsFilter: selectedGroups,
			selectedAssignedUsers: selectedUsers
		});
	}

	async fetchAssignableUsers() {
		try {
			this.update({ loading: true });
			let locationId = UserService.getActiveLocation().id;
			let assignableUsers = await MessagesService.fetchAssignableUsers(locationId);
			if (assignableUsers) {
				assignableUsers = assignableUsers.map(u => {
					var fullName = UserService.createFullName({ firstName: u.first_name, lastName: u.last_name });
					return {
						label: `${fullName} - ${u.email}`,
						value: u.id
					};
				});
				await this.update({ userFilterOptions: assignableUsers });
			}
			this.update({ loading: false });
		} catch (error) {
			console.log(error);
		}
	}

	refreshContacts() {
		let {
			selectedTagsFilter,
			selectedGroupsFilter,
			selectedAssignedUsers,
			selectedLastActivityDate,
			lastActivityStartDate,
			lastActivityEndDate,
			lastActivityInclude,
			selectedContactStatus,
			selectBlocked
		} = this.state;

		let lastActivityFilter = null;
		if (selectedLastActivityDate) {
			lastActivityFilter = { type: selectedLastActivityDate.value };
			if (selectedLastActivityDate.value === "custom" && lastActivityStartDate && lastActivityEndDate) {
				lastActivityFilter.startDate = lastActivityStartDate;
				lastActivityFilter.endDate = lastActivityEndDate;
			}
			lastActivityFilter.include = lastActivityInclude ? lastActivityInclude.value : "include";
		}

		let contactStatus = [selectedContactStatus.value];

		if (contactStatus.includes("all")) {
			contactStatus = [STATUS.active, STATUS.inactive];
		}

		let newFilterConditions = [
			{
				op: OPERATORS.in,
				field: "status",
				value: contactStatus
			}
		];

		if (selectedAssignedUsers && selectedAssignedUsers.length > 0) {
			newFilterConditions.push({
				op: OPERATORS.in,
				field: "assigned_user_id",
				value: selectedAssignedUsers.map(t => t.value)
			});
		}

		if (lastActivityFilter && lastActivityFilter.type === "all" && lastActivityInclude && lastActivityInclude.value === "exclude") {
			newFilterConditions.push({
				op: OPERATORS.and,
				conditions: [
					{
						op: OPERATORS.is,
						field: "last_message_sent",
						value: null
					}
				]
			});
		} else if (lastActivityFilter && lastActivityFilter.type === "all" && lastActivityInclude && lastActivityInclude.value === "include") {
			newFilterConditions.push({
				op: OPERATORS.and,
				conditions: [
					{
						op: OPERATORS.isNot,
						field: "last_message_sent",
						value: null
					}
				]
			});
		} else if (lastActivityFilter && lastActivityFilter.type !== "all") {
			lastActivityFilter.include = lastActivityInclude ? lastActivityInclude.value : "include";

			if (lastActivityInclude && lastActivityInclude.value === "exclude") {
				newFilterConditions.push({
					op: OPERATORS.and,
					conditions: [
						{
							op: OPERATORS.lt,
							field: "last_message_sent",
							value: lastActivityStartDate
						},
						{
							op: OPERATORS.gt,
							field: "last_message_sent",
							value: lastActivityEndDate
						}
					]
				});
			} else {
				newFilterConditions.push({
					op: OPERATORS.and,
					conditions: [
						{
							op: OPERATORS.gte,
							field: "last_message_sent",
							value: lastActivityStartDate
						},
						{
							op: OPERATORS.lte,
							field: "last_message_sent",
							value: lastActivityEndDate
						}
					]
				});
			}
		}

		if (selectBlocked && selectBlocked.value && selectBlocked.value !== "all") {
			newFilterConditions.push({
				op: OPERATORS.eq,
				field: "is_blocked",
				value: selectBlocked.value === "blocked" ? true : false
			});
		}

		if (selectedTagsFilter && selectedTagsFilter.length > 0) {
			newFilterConditions.push({
				op: OPERATORS.in,
				field: "tag_id",
				value: selectedTagsFilter.map(t => t.value)
			});
		}

		if (selectedGroupsFilter && selectedGroupsFilter.length > 0) {
			newFilterConditions.push({
				op: OPERATORS.in,
				field: "group_id",
				value: selectedGroupsFilter.map(g => g.value)
			});
		}

		let filters = {
			op: OPERATORS.and,
			conditions: newFilterConditions
		};

		if (this.props.refreshContacts) {
			this.props.refreshContacts(filters);
		}
	}

	handleFromChange = async start => {
		// Change the from date and focus the "to" input field
		await this.update({ lastActivityStartDate: start });
		this.refreshContacts();
	};
	handleToChange = async end => {
		await this.update({ lastActivityEndDate: end }, this.showFromMonth);
		this.refreshContacts();
	};
	showFromMonth() {
		const { lastActivityStartDate, lastActivityEndDate } = this.state;
		if (!lastActivityStartDate) {
			return;
		}
		if (moment(lastActivityEndDate).diff(moment(lastActivityStartDate), "months") < 2) {
			this.to.getDayPicker().showMonth(lastActivityStartDate);
		}
	}

	// Function to parse URL parameters and initialize state
	getUrlParams() {
		// Initialize variables for filter options
		let selectBlocked = null;
		let selectedTagsFilter = [];
		let selectedGroupsFilter = [];
		let selectedAssignedUsers = [];
		let selectedContactStatus = CONTACT_STATES_OPTIONS[0];
		let selectedLastActivityDate = null;
		let lastActivityInclude = INCLUDE_OPTIONS[0];
		let lastActivityStartDate = moment()
			.subtract(30, "days")
			.toDate();
		let lastActivityEndDate = moment().toDate();

		// Parse URL parameters
		let { query } = queryString.parseUrl(window.location.href);
		let filters = null;

		// Check if filters are provided in URL
		if (query && query.filters) {
			filters = JSON.parse(query.filters);
		}

		// Check if filters are not provided or empty
		if (!filters || !filters.conditions || filters.conditions.length < 1) {
			// Return default values if no filters are provided
			return {
				selectBlocked,
				selectedContactStatus,
				selectedTagsFilter,
				selectedGroupsFilter,
				selectedAssignedUsers,
				selectedLastActivityDate,
				lastActivityInclude,
				lastActivityStartDate,
				lastActivityEndDate
			};
		}

		// Initialize index variables for different filter types
		let blockedIndexOf = filters.conditions.findIndex(c => c.field === "is_blocked");
		let tagsIndexOf = filters.conditions.findIndex(c => c.field === "tag_id");
		let groupsIndexOf = filters.conditions.findIndex(c => c.field === "group_id");
		let assignedUsersIndexOf = filters.conditions.findIndex(c => c.field === "assigned_user_id");
		let lastActivityIndexOf = filters.conditions.findIndex(c => c.op === OPERATORS.and);

		// Process 'is_blocked' filter
		if (blockedIndexOf >= 0) {
			let condition = filters.conditions[blockedIndexOf];
			if (typeof condition.value !== "undefined") {
				selectBlocked = condition.value ? CONTACT_BLOCK_OPTIONS[1] : CONTACT_BLOCK_OPTIONS[2];
			}
		}

		// Process 'tag_id' filter
		if (tagsIndexOf >= 0) {
			let condition = filters.conditions[tagsIndexOf];
			if (typeof condition.value !== "undefined") {
				selectedTagsFilter = condition.value;
			}
		}

		// Process 'group_id' filter
		if (groupsIndexOf >= 0) {
			let condition = filters.conditions[groupsIndexOf];
			if (typeof condition.value !== "undefined") {
				selectedGroupsFilter = condition.value;
			}
		}

		// Process 'assigned_user_id' filter
		if (assignedUsersIndexOf >= 0) {
			let condition = filters.conditions[assignedUsersIndexOf];
			if (typeof condition.value !== "undefined") {
				selectedAssignedUsers = condition.value;
			}
		}

		// Process 'last_message_sent' filter
		if (lastActivityIndexOf >= 0) {
			let conditions = filters.conditions[lastActivityIndexOf].conditions;
			if (conditions.findIndex(c => c.op === OPERATORS.gt) >= 0) {
				lastActivityInclude = INCLUDE_OPTIONS[1];
			}
			lastActivityStartDate = moment(conditions[0].value).toDate();
			lastActivityEndDate = moment(conditions[1].value).toDate();
			selectedLastActivityDate = LAST_ACTIVITY_OPTIONS[4];
		}

		// Return processed filter options
		return {
			selectBlocked,
			selectedContactStatus,
			selectedTagsFilter,
			selectedGroupsFilter,
			selectedAssignedUsers,
			selectedLastActivityDate,
			lastActivityInclude,
			lastActivityStartDate,
			lastActivityEndDate
		};
	}

	// Function to set URL parameters based on state
	setUrlParams() {
		// Check if history object is available
		if (!this.props.history) {
			return;
		}

		// Parse URL parameters
		let { query } = queryString.parseUrl(window.location.href);
		let filters = {
			op: OPERATORS.and,
			conditions: []
		};

		// Check if filters are provided in URL
		if (query && query.filters) {
			filters = JSON.parse(query.filters);
		}

		// Initialize filter options from state
		let {
			selectBlocked,
			selectedContactStatus,
			selectedTagsFilter,
			selectedGroupsFilter,
			selectedAssignedUsers,
			selectedLastActivityDate,
			lastActivityInclude,
			lastActivityStartDate,
			lastActivityEndDate
		} = this.state;

		// Process 'is_blocked' filter
		let blockedIndexOf = filters.conditions.findIndex(c => c.field === "is_blocked");
		if (selectBlocked && selectBlocked.value === "all" && blockedIndexOf >= 0) {
			filters.conditions.splice(blockedIndexOf, 1);
		} else if (selectBlocked) {
			let condition = {
				op: OPERATORS.eq,
				field: "is_blocked",
				value: selectBlocked.value === "blocked" ? true : false
			};
			if (blockedIndexOf >= 0) {
				filters.conditions[blockedIndexOf] = condition;
			} else {
				filters.conditions.push(condition);
			}
		}

		// Process 'status' filter
		let statusIndexOf = filters.conditions.findIndex(c => c.field === "status");
		if (!selectedContactStatus && statusIndexOf >= 0) {
			filters.conditions.splice(statusIndexOf, 1);
		} else if (selectedContactStatus && selectedContactStatus.value === "all") {
			let condition = {
				op: OPERATORS.in,
				field: "status",
				value: [STATUS.active, STATUS.inactive]
			};
			filters.conditions[statusIndexOf] = condition;
		} else if (selectedContactStatus) {
			let condition = {
				op: OPERATORS.in,
				field: "status",
				value: [selectedContactStatus.value]
			};
			if (statusIndexOf >= 0) {
				filters.conditions[statusIndexOf] = condition;
			} else {
				filters.conditions.push(condition);
			}
		}

		// Process 'tag_id' filter
		let tagIndexOf = filters.conditions.findIndex(c => c.field === "tag_id");
		// Remove 'tag_id' filter condition if no tags selected and condition exists
		if ((!selectedTagsFilter || selectedTagsFilter.length < 1) && tagIndexOf >= 0) {
			filters.conditions.splice(tagIndexOf, 1);
		} else if (selectedTagsFilter && selectedTagsFilter.length > 0) {
			// Add or update 'tag_id' filter condition if tags selected
			let condition = {
				op: OPERATORS.in,
				field: "tag_id",
				value: selectedTagsFilter.map(t => t.value)
			};
			if (tagIndexOf >= 0) {
				filters.conditions[tagIndexOf] = condition;
			} else {
				filters.conditions.push(condition);
			}
		}

		// Find index of 'group_id' filter condition
		let groupIndexOf = filters.conditions.findIndex(c => c.field === "group_id");
		// Remove 'group_id' filter condition if no groups selected and condition exists
		if ((!selectedGroupsFilter || selectedGroupsFilter.length < 1) && groupIndexOf >= 0) {
			filters.conditions.splice(groupIndexOf, 1);
		} else if (selectedGroupsFilter && selectedGroupsFilter.length > 0) {
			// Add or update 'group_id' filter condition if groups selected
			let condition = {
				op: OPERATORS.in,
				field: "group_id",
				value: selectedGroupsFilter.map(t => t.value)
			};
			if (groupIndexOf >= 0) {
				filters.conditions[groupIndexOf] = condition;
			} else {
				filters.conditions.push(condition);
			}
		}

		// Find index of 'assigned_user_id' filter condition
		let userIndexOf = filters.conditions.findIndex(c => c.field === "assigned_user_id");
		// Remove 'assigned_user_id' filter condition if no users selected and condition exists
		if ((!selectedAssignedUsers || selectedAssignedUsers.length < 1) && userIndexOf >= 0) {
			filters.conditions.splice(userIndexOf, 1);
		} else if (selectedAssignedUsers && selectedAssignedUsers.length > 0) {
			// Add or update 'assigned_user_id' filter condition if users selected
			let condition = {
				op: OPERATORS.in,
				field: "assigned_user_id",
				value: selectedAssignedUsers.map(t => t.value)
			};
			if (userIndexOf >= 0) {
				filters.conditions[userIndexOf] = condition;
			} else {
				filters.conditions.push(condition);
			}
		}

		// Find index of 'last_message_sent' filter condition
		let lastActivityIndexOf = filters.conditions.findIndex(c => c.op === OPERATORS.and);

		// Remove 'last_message_sent' filter condition if not specified and condition exists
		if (!selectedLastActivityDate && lastActivityIndexOf >= 0) {
			filters.conditions.splice(lastActivityIndexOf, 1);
		} else if (selectedLastActivityDate && selectedLastActivityDate.value === "all") {
			// Add or update 'last_message_sent' filter condition if last activity date is set to 'all'
			let condition = {
				op: OPERATORS.and,
				conditions: [
					{
						op: lastActivityInclude.value === "exclude" ? OPERATORS.is : OPERATORS.isNot,
						field: "last_message_sent",
						value: null
					}
				]
			};
			if (lastActivityIndexOf >= 0) {
				filters.conditions[lastActivityIndexOf] = condition;
			} else {
				filters.conditions.push(condition);
			}
		} else {
			// Add or update 'last_message_sent' filter condition if last activity date range specified
			let condition = {
				op: OPERATORS.and,
				conditions: [
					{
						op: OPERATORS.gte,
						field: "last_message_sent",
						value: lastActivityStartDate
					},
					{
						op: OPERATORS.lte,
						field: "last_message_sent",
						value: lastActivityEndDate
					}
				]
			};

			// Adjust condition if last activity date should be excluded from range
			if (lastActivityInclude && lastActivityInclude.value === "exclude") {
				condition = {
					op: OPERATORS.and,
					conditions: [
						{
							op: OPERATORS.lt,
							field: "last_message_sent",
							value: lastActivityStartDate
						},
						{
							op: OPERATORS.gt,
							field: "last_message_sent",
							value: lastActivityEndDate
						}
					]
				};
			}

			if (lastActivityIndexOf >= 0) {
				filters.conditions[lastActivityIndexOf] = condition;
			} else {
				filters.conditions.push(condition);
			}
		}

		// Serialize filter object to URL query string
		let params = new URLSearchParams();
		if (filters) {
			params.set("filters", JSON.stringify(filters));
		}

		// Update URL with new filter parameters
		if (Object.keys(filters).length < 1) {
			// If there are no filters, remove filters from URL
			params = new URLSearchParams();
			this.props.history.replace({
				pathname: this.props.location.pathname,
				search: params.toString()
			});
			return;
		}

		this.props.history.replace({
			pathname: this.props.location.pathname,
			search: params.toString()
		});
	}

	render() {
		let {
			tagFilterOptions,
			selectedTagsFilter,
			groupFilterOptions,
			selectedGroupsFilter,
			userFilterOptions,
			selectedAssignedUsers,
			lastActivityFilterOptions,
			lastActivityIncludeOptions,
			lastActivityInclude,
			selectedLastActivityDate,
			lastActivityStartDate,
			lastActivityEndDate,
			showHeader,
			selectedContactStatus,
			selectBlocked
		} = this.state;

		const modifiers = { lastActivityStartDate, lastActivityEndDate };

		return (
			<div className="contact-selector__filters">
				{showHeader && (
					<div className="contact-selector__filters-header">
						<div>Filter Contacts</div>
					</div>
				)}
				<div className="contact-selector__filters-column">
					<div>
						<label>Conversation Status</label>
						<Select
							id="contact-status-filter"
							options={CONTACT_STATES_OPTIONS}
							value={selectedContactStatus}
							placeholder="Contact Status."
							onChange={async selectedContactStatus => {
								await this.update({ selectedContactStatus });
								this.setUrlParams();
								this.refreshContacts();
							}}
						/>
					</div>
					<div>
						<label>Is Blocked</label>
						<Select
							id="contact-blocked-filter"
							options={CONTACT_BLOCK_OPTIONS}
							value={selectBlocked}
							placeholder="Blocked"
							onChange={async selectBlocked => {
								await this.update({ selectBlocked });
								this.setUrlParams();
								this.refreshContacts();
							}}
						/>
					</div>
					<div>
						<label>Tags</label>
						<Select
							id="tag-filter"
							options={tagFilterOptions}
							value={selectedTagsFilter}
							isMulti
							placeholder="Tags"
							onChange={async selectedTagsFilter => {
								await this.update({ selectedTagsFilter });
								this.setUrlParams();
								this.refreshContacts();
							}}
						/>
					</div>
					<div>
						<label>Groups</label>
						<Select
							id="group-filter"
							options={groupFilterOptions}
							value={selectedGroupsFilter}
							isMulti
							placeholder="Groups"
							onChange={async selectedGroupsFilter => {
								await this.update({ selectedGroupsFilter });
								this.setUrlParams();
								this.refreshContacts();
							}}
						/>
					</div>
					<div>
						<label>Assigned To User</label>
						<Select
							id="assignable-users-filter"
							options={userFilterOptions}
							value={selectedAssignedUsers}
							isMulti
							placeholder="Users"
							onChange={async selectedAssignedUsers => {
								await this.update({ selectedAssignedUsers });
								this.setUrlParams();
								this.refreshContacts();
							}}
						/>
					</div>
					<div>
						<label>Last Message Sent/Received</label>
						<Select
							id="last-activity-date-filter"
							options={lastActivityFilterOptions}
							value={selectedLastActivityDate}
							placeholder="Date Range"
							isClearable
							onChange={async selectedLastActivityDate => {
								let startDateTime = null;
								let endDateTime = moment().toDate();
								if (selectedLastActivityDate && selectedLastActivityDate.value === "yesterday") {
									startDateTime = moment()
										.subtract(1, "day")
										.toDate();
								} else if (selectedLastActivityDate && selectedLastActivityDate.value === "last_7_days") {
									startDateTime = moment()
										.subtract(7, "day")
										.toDate();
								} else if (selectedLastActivityDate && selectedLastActivityDate.value === "this_month") {
									startDateTime = moment()
										.startOf("month")
										.toDate();
								} else if (selectedLastActivityDate && selectedLastActivityDate.value === "custom") {
									startDateTime = moment()
										.subtract(30, "days")
										.toDate();
								}

								await this.update({ selectedLastActivityDate, lastActivityStartDate: startDateTime, lastActivityEndDate: endDateTime });
								this.setUrlParams();
								this.refreshContacts();
							}}
						/>
						{selectedLastActivityDate && selectedLastActivityDate.value !== "all" && (
							<div className="text-center">
								<small>
									From {moment(lastActivityStartDate).format("YYYY-MM-DD")} to {moment(lastActivityEndDate).format("YYYY-MM-DD")}
								</small>
							</div>
						)}
					</div>
					{selectedLastActivityDate && (
						<div>
							<label>Last Activity Include/Exclude</label>
							<Select
								id="last-activity-date-filter"
								options={lastActivityIncludeOptions}
								value={lastActivityInclude}
								placeholder="Include or Exclude Contacts"
								isClearable
								onChange={async lastActivityInclude => {
									await this.update({ lastActivityInclude });
									this.setUrlParams();
									this.refreshContacts();
								}}
							/>
						</div>
					)}
					{selectedLastActivityDate && selectedLastActivityDate.value === "custom" && (
						<div>
							<label>Last Activity Range</label>
							<div className="input-group" id="datepicker">
								<div className="InputFromTo">
									<DayPickerInput
										value={lastActivityStartDate}
										placeholder=" From"
										format="LL"
										formatDate={formatDate}
										parseDate={parseDate}
										dayPickerProps={{
											selectedDays: [lastActivityStartDate, { from: lastActivityStartDate, to: lastActivityEndDate }],
											disabledDays: { after: lastActivityEndDate },
											toMonth: lastActivityEndDate,
											modifiers,
											numberOfMonths: 2,
											onDayClick: () => this.to.getInput().focus()
										}}
										onDayChange={startDate => this.handleFromChange(startDate)}
									/>{" "}
									<div className="InputFromTo-to">
										<DayPickerInput
											ref={el => (this.to = el)}
											value={lastActivityEndDate}
											placeholder=" To"
											format="LL"
											formatDate={formatDate}
											parseDate={parseDate}
											dayPickerProps={{
												selectedDays: [lastActivityStartDate, { from: lastActivityStartDate, to: lastActivityEndDate }],
												disabledDays: { before: lastActivityStartDate },
												modifiers,
												month: lastActivityStartDate,
												fromMonth: lastActivityStartDate,
												numberOfMonths: 2
											}}
											onDayChange={this.handleToChange}
										/>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}
export default withRouter(ContactSelectorFilters);
