import React from "react";
import { withTranslation } from "react-i18next";

class NoDataComponent extends React.Component {
	render() {
		const { loading, t } = this.props;

		if (!loading) {
			return (
				<div className="rt-noData">
					<h2>
						{t("Sorry, no data was found")}{" "}
						<span role="img" aria-label="sad-face">
							😞
						</span>
					</h2>
				</div>
			);
		} else {
			return null; // Return null instead of an empty string for no render
		}
	}
}

export default withTranslation(null, { withRef: true })(NoDataComponent);
