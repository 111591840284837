import UserService from "./UserService";

import Notify from "notifyjs";
import { TEAMCHAT_EVENT_NOTIFICATIONS, CUSTOMER_EVENT_NOTIFICATIONS } from "../constants/Notifications";

// Built a basic internal PubSub for Internal Messaging
let bridge = {};
let once = {};

const NotificationService = {
	/**
	 * Ability to subscribe once to a channel where the subscribe could be executed multiple times, to avoid duplication
	 *
	 * @param {String} channel
	 * @param {String} id
	 * @param {Function} cb
	 */
	subscribeOnce(channel, id, cb) {
		if (typeof once[channel] === "undefined") {
			once[channel] = {
				[id]: cb
			};
		} else {
			once[channel][id] = cb;
		}
	},

	/**
	 * Basic Subscribe function to allow Components to listen to data on certain channels
	 *
	 * @param {String} channel
	 * @param {Function} cb
	 */
	subscribe(channel, cb) {
		if (typeof bridge[channel] === "undefined") {
			bridge[channel] = [cb];
		} else {
			bridge[channel].push(cb);
		}
	},

	/**
	 * Basic Publish function allow components to write data to a channel and fire listeners
	 *
	 * @param {String} channel
	 * @param {Object} data
	 */
	publish(channel, data) {
		if (bridge[channel]) {
			for (let subscriber of bridge[channel]) {
				if (typeof subscriber !== "function") {
					continue;
				}

				try {
					subscriber(data);
				} catch (error) {
					console.log(error);
					console.warn(`An error occurred trying to call a subscriber with channel ${channel}`);
				}
			}
		}

		let oneTimeSubscriptions = once[channel] || {};

		for (let id of Object.keys(oneTimeSubscriptions)) {
			if (typeof once[channel][id] !== "function") {
				continue;
			}

			try {
				once[channel][id](data);
			} catch (error) {
				console.warn(`An error occurred trying to call a one time subscriber with channel - ${channel}`);
			}
		}
	},

	/**
	 * Unsubscribe a function from a channel
	 *
	 * @param {String} channel
	 * @param {Function} cb
	 */
	unsubscribe(channel, cb) {
		try {
			if (typeof cb !== "function") {
				return;
			}

			let subs = bridge[channel];
			let instances = [];

			for (let i = 0; i < subs.length; i++) {
				let sub = subs[i];

				if (typeof sub === "function" && sub.toString() === cb.toString()) {
					instances.push(i);
				}
			}

			for (let i of instances) {
				subs.splice(i, 1);
			}
		} catch (error) {
			console.warn(`An error occurred trying to unsubscribe from ${channel}`);
		}
	},

	/**
	 * Open a message desktop notification
	 *
	 * @param {Object} message
	 */
	openMessageNotification(message, onClick) {
		let title = "";
		let content = "";
		let eventType = message.event_type;

		// Teamchat message
		if (message.conversation_id != null) {
			if (eventType === TEAMCHAT_EVENT_NOTIFICATIONS.dhCustomerWin.id) {
				title = TEAMCHAT_EVENT_NOTIFICATIONS.dhCustomerWin.title;
				content = message.content;
			} else {
				title = `${message.sender_user_name}${TEAMCHAT_EVENT_NOTIFICATIONS.message.title}`;
				content = message.content;
			}

			this.openDesktopNotification({ title, content, onClick, eventType });
			return;
		}

		// Customer Message
		if (eventType === CUSTOMER_EVENT_NOTIFICATIONS.message.id) {
			title = `${CUSTOMER_EVENT_NOTIFICATIONS.message.title}${message.Contact.name || message.Contact.phone || message.Contact.email || "an unknown contact!"}`;
			content = message.content;
		} else if (eventType === CUSTOMER_EVENT_NOTIFICATIONS.userAssigned.id) {
			title = CUSTOMER_EVENT_NOTIFICATIONS.userAssigned.title;
			content = message.content;
		}

		this.openDesktopNotification({ title, content, onClick, eventType });
	},

	/**
	 * Asks the user for desktop notification permissions
	 *
	 */
	askNotificationPermission() {
		if (!Notify.needsPermission) {
			return;
		}

		Notify.requestPermission(
			() => {
				console.log("DemandHub has been granted access to desktop notifications.");
			},
			() => {
				console.log("DemandHub has not been granted access to desktop notifications.");
			}
		);
	},

	/**
	 * Check if we can ask for permission
	 *
	 * @returns Boolean
	 */
	hasNotificationPermission() {
		if (!Notify.needsPermission) {
			return true;
		} else {
			return false;
		}
	},

	/**
	 * Opens a new desktop notification
	 *
	 * @param {String} title
	 * @param {String} content
	 * @param {Function} onClick
	 * @param {String} eventType
	 */
	openDesktopNotification({ title, content, onClick, eventType }) {
		var note = new Notify(title, {
			body: content,
			icon: `${window.location.origin}/android-chrome-512x512.png`,
			notifyClick: onClick,
			silent: true
		});

		let isAvailable = UserService.get().is_available;

		if (!Notify.needsPermission) {
			if (eventType === TEAMCHAT_EVENT_NOTIFICATIONS.dhCustomerWin.id && isAvailable) {
				TEAMCHAT_EVENT_NOTIFICATIONS.dhCustomerWin.sound.play();
			} else if (isAvailable) {
				CUSTOMER_EVENT_NOTIFICATIONS.message.sound.play();
			}

			note.show();
		} else if (Notify.isSupported()) {
			this.askNotificationPermission();
		}
	}
};

export default NotificationService;
