import React, { Component } from "react";
import * as Icon from "react-feather";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import queryString from "query-string";

import withLocation from "../../../components/common/WithLocation";
import List from "../../../components/common/List";
import Action from "../../../components/common/Action";
import Alert from "../../../components/common/Alert";
import Filters from "../../../components/common/NewFilters";
import Filter from "../../../components/common/Filter";
import SearchInput from "../../../components/common/SearchInput";
import DHDropdown from "../../../components/common/DHDropdown";
import DHDropdownOption from "../../../components/common/DHDropdownOption";
import NewConversationModal from "../../MessengerBeta/List/NewConversationModal/NewConversationModal";
import PaymentRequest from "../../MessengerBeta/Thread/PaymentRequest/PaymentRequest";

import UserService from "../../../services/UserService";
import PaymentService from "../../../services/PaymentService";
import UtilityService from "../../../services/UtilityService";
import ContactService from "../../../services/ContactService";

import { PAYMENT_STATUS, PAYMENT_TYPES, SORT_FIELD, SORT_ORDER, PAYMENT_COLUMNS } from "../../../constants/Payments";

import "./payment-request-list.css";

class PaymentRequestList extends Component {
	constructor(props) {
		super(props);

		let { statusSelected, searchTerm, sortField, sortOrder } = queryString.parse(this.props.location.search);

		this.state = {
			loading: true,
			paymentRequests: [],
			statusSelected: statusSelected ? statusSelected : Object.keys(PAYMENT_STATUS)[0],
			searchTerm: searchTerm ? searchTerm : "",
			sortField: sortField ? sortField : SORT_FIELD.createdAt,
			sortOrder: sortOrder ? sortOrder : SORT_ORDER.desc,
			limit: 50,
			pageSize: 50,
			loadedAll: true,
			error: false,
			errorDescription: "",

			showCreateDropdown: false,
			showNewConversationModal: false,
			showPaymentRequestModal: false,
			showPaymentMethodExistsAlert: false,
			showNoPaymentMethodExistsAlert: false,

			currentType: PAYMENT_TYPES.general.id,
			selectedContactId: null,
			selectedContactName: ""
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	onLocationChanged = async location => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.update({ loading: true });
		await this.fetchPaymentRequests();
		await this.update({ loading: false });
	};

	fetchPaymentRequests = async () => {
		let { statusSelected, searchTerm, sortField, sortOrder, limit } = this.state;

		let location = UserService.getActiveLocation();
		let paymentRequests = await PaymentService.fetchPaymentRequests({
			locationId: location.id,
			status: statusSelected,
			searchTerm,
			sortField,
			sortOrder,
			limit
		});

		this.update({
			paymentRequests,
			loadedAll: paymentRequests.length < limit
		});
	};

	onStatusSelect = async item => {
		await this.update({ loading: true, statusSelected: item.id });
		this.setUrlParams();
		await this.fetchPaymentRequests();
		await this.update({ loading: false });
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		this.setUrlParams();
		await this.fetchPaymentRequests();
	};

	setUrlParams() {
		if (this.props.history) {
			let { statusSelected, searchTerm, sortField, sortOrder } = this.state;

			let params = {};

			if (statusSelected) {
				params.statusSelected = statusSelected;
			}

			if (searchTerm) {
				params.searchTerm = searchTerm;
			}

			if (sortField) {
				params.sortField = sortField;
			}

			if (sortOrder) {
				params.sortOrder = sortOrder;
			}

			params = new URLSearchParams(params);
			this.props.history.replace({
				pathname: this.props.location.pathname,
				search: params.toString()
			});
		}
	}

	onRequestClicked = paymentRequest => {
		this.props.history.push(`/payments/request/${paymentRequest.id}`);
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ loading: true, sortField, sortOrder });
		this.setUrlParams();
		await this.fetchPaymentRequests();
		await this.update({ loading: false });
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchPaymentRequests();
	};

	onNewConversationModalClose = () => {
		this.update({
			showNewConversationModal: false
		});
	};

	onContactSelected = async (contact, createMode) => {
		let { currentType } = this.state;

		// Fetch the contact with more details (has the payment method on it)
		let contactDetails = await ContactService.getContact(contact.contact_id);

		await this.update({
			selectedContactName: contactDetails.name,
			selectedContactId: contactDetails.id
		});

		// If a payment method already exists for this contact, open a confirmation modal confirming that the user wants to request a new payment method
		if (currentType === PAYMENT_TYPES.credit_card.id && contactDetails.PaymentMethods && contactDetails.PaymentMethods.length > 0) {
			await this.update({
				showPaymentMethodExistsAlert: true
			});

			return;
		}

		if (currentType === PAYMENT_TYPES.charge.id && (!contactDetails.PaymentMethods || contactDetails.PaymentMethods.length === 0)) {
			await this.update({
				showNoPaymentMethodExistsAlert: true
			});

			return;
		}

		// If there is no payment method on file, open the PaymentRequest modal
		await this.update({
			showNewConversationModal: false,
			showPaymentRequestModal: true
		});
	};

	onPaymentMethodExistsConfirm = async confirm => {
		if (confirm) {
			await this.update({
				showNewConversationModal: false,
				showPaymentRequestModal: true
			});
		}

		await this.update({
			showPaymentMethodExistsAlert: false
		});
	};

	onNoPaymentMethodExistsConfirm = async () => {
		await this.update({
			showNoPaymentMethodExistsAlert: false
		});
	};

	onNewRequestClicked = async type => {
		await this.update({
			currentType: type,
			showNewConversationModal: true,
			showCreateDropdown: false
		});
	};

	onPaymentRequestModalClosed = async () => {
		await this.update({
			showPaymentRequestModal: false,
			loading: true
		});

		await this.fetchPaymentRequests();
		await this.update({ loading: false });
	};

	onCloseStripeError = () => {
		this.update({ error: false, errorDescription: "" });
	};

	renderActionDropdown() {
		let { t } = this.props;
		let { showCreateDropdown } = this.state;

		const user = UserService.get();

		let isCreatePaymentAllowed = user.GroupPermission.create_payments;

		if (!isCreatePaymentAllowed) {
			return null;
		}

		return (
			<DHDropdown
				offset={{
					top: 30
				}}
				show={showCreateDropdown}
				onChange={({ show }) => {
					this.update({ showCreateDropdown: show });
				}}
				trigger={
					<div className="mb-button payment-request-list__search__actions__dropdown-action">
						<div className="payment-request-list__search__actions__dropdown-action__text">{t("Create")}</div>
						{showCreateDropdown && <Icon.ChevronUp size="16" />}
						{!showCreateDropdown && <Icon.ChevronDown size="16" />}
					</div>
				}
				options={
					<>
						<DHDropdownOption icon={Icon.DollarSign} title={t("Request a Payment")} action={() => this.onNewRequestClicked(PAYMENT_TYPES.general.id)} />
						<DHDropdownOption icon={Icon.CreditCard} title={t("Request credit card")} action={() => this.onNewRequestClicked(PAYMENT_TYPES.credit_card.id)} />
						<DHDropdownOption icon={Icon.Activity} title={t("Charge a Contact")} action={() => this.onNewRequestClicked(PAYMENT_TYPES.charge.id)} />
						<DHDropdownOption icon={Icon.List} title={t("Send an Estimate")} action={() => this.onNewRequestClicked(PAYMENT_TYPES.estimate.id)} />
					</>
				}
			/>
		);
	}

	renderSearch() {
		let { t } = this.props;

		return (
			<div className="payment-request-list__search">
				<SearchInput initValue={this.state.searchTerm} placeholder={t("Search ...")} onChange={this.onSearchChange} leading={false} />
				<div className="payment-request-list__search__actions">{this.renderActionDropdown()}</div>
			</div>
		);
	}

	renderFilters() {
		let { statusSelected } = this.state;

		// Convert the Payment STATUS keys into a list for easier renderings
		let statuses = Object.keys(PAYMENT_STATUS).map(item => {
			return { id: item, value: PAYMENT_STATUS[item].display };
		});

		return (
			<Filters onSelect={this.onStatusSelect} selected={statusSelected}>
				{statuses.map(item => (
					<Filter key={item.id} id={item.id}>
						{item.value}
					</Filter>
				))}
			</Filters>
		);
	}

	renderRecord = pr => {
		let { t } = this.props;
		let { localTimeZoneString } = UtilityService.getTimeZoneHelpers();

		return [
			pr.Contact.name + " • " + pr.Contact.phone,
			PAYMENT_TYPES[pr.type].display,
			pr.type === PAYMENT_TYPES.credit_card.id ? " - " : pr.Invoice.crm_invoice_id || pr.Invoice.id,
			pr.type === PAYMENT_TYPES.credit_card.id ? " - " : PAYMENT_STATUS[pr.status].display,
			pr.type === PAYMENT_TYPES.credit_card.id ? " - " : PaymentService.formatCharge(pr.Invoice.total_amount),
			pr.type === PAYMENT_TYPES.credit_card.id ? " - " : PaymentService.formatCharge(pr.Invoice.customer_paid_amount),
			pr.type === PAYMENT_TYPES.credit_card.id ? " - " : PaymentService.formatCharge(pr.Invoice.insurance_amount),
			pr.Contact.PaymentMethods.length > 0 ? <Icon.Check /> : "",
			<span className="dh-tip" tip={localTimeZoneString}>
				{moment(pr.charge_date).format("MMM Do, YYYY")}
			</span>,
			<span className="dh-tip" tip={localTimeZoneString}>
				{moment(pr.created_at).format("ddd, MMM Do YYYY, h:mm a")}
			</span>,
			<Action transparent id={`view-scheduled-message-${pr.id}`} label={t("View")} icon={Icon.Info} onClick={() => this.onRequestClicked(pr)} />
		];
	};

	render() {
		let {
			paymentRequests,
			loading,
			loadedAll,
			error,
			errorDescription,
			sortOrder,
			sortField,
			showNewConversationModal,
			showPaymentRequestModal,
			selectedContactId,
			selectedContactName,
			currentType,
			showPaymentMethodExistsAlert,
			showNoPaymentMethodExistsAlert
		} = this.state;
		let { t } = this.props;

		return (
			<>
				{this.renderSearch()}
				{this.renderFilters()}
				<List
					items={paymentRequests}
					loading={loading}
					loadedAll={loadedAll}
					headers={{
						items: PAYMENT_COLUMNS,
						sortBy: this.sortBy
					}}
					renderRecord={this.renderRecord}
					onLoadMore={this.onLoadMore}
					sortOrder={sortOrder}
					sortField={sortField}
					noDataTitle={t("You don't have any payment requests or estimates! Create a new payment request or estimate in DemandHub!")}
					noDataIcon={
						<img alt="Payment Requests" className="scheduled-messages__no-data-img" src="https://cdn.demandhub.co/web-app/assets/payment_requests.svg" />
					}
				/>

				<NewConversationModal
					show={showNewConversationModal}
					title={t("Select a contact ...")}
					actionText={t("Next")}
					showPaymentMethod={true}
					onCreate={this.onContactSelected}
					onClose={this.onNewConversationModalClose}
				/>
				<PaymentRequest type={currentType} show={showPaymentRequestModal} onClose={this.onPaymentRequestModalClosed} contactId={selectedContactId} />
				<Alert
					type="warning"
					show={showPaymentMethodExistsAlert}
					title={t("Payment Method exists.")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={this.onPaymentMethodExistsConfirm}
				>
					{t("A valid payment method already exists for {{selectedContactName}}. Are you sure you would like to request a new one?", {
						selectedContactName: selectedContactName
					})}
				</Alert>
				<Alert
					type="warning"
					show={showNoPaymentMethodExistsAlert}
					title={t("Payment Method does not exist.")}
					confirm={t("OK")}
					onClose={this.onNoPaymentMethodExistsConfirm}
				>
					{t("A valid payment method does not exist for {{selectedContactName}}. Please try requesting Credit Card information for {{selectedContactName}}.", {
						selectedContactName: selectedContactName
					})}
				</Alert>

				<Alert type="info" show={error} title={t("Strip Error")} confirm={t("Okay")} onClose={this.onCloseStripeError}>
					{errorDescription}
				</Alert>
			</>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(PaymentRequestList)));
