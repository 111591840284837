import React, { Component } from "react";

import ConfirmBlock from "../../../components/common/ConfirmBlock";
import ContactSelectorUpdate from "../../../scenes/ContactSelector/ContactSelectorUpdate";

import { TAG_TYPES } from "../../../constants/Tags";

import "../../MessengerBeta/Thread/Message/message.css";
import "../../../styles/css/components/commons/bulk-actions.css";

const UPLOAD_LIMIT = 2000;

class UploadContactsConfirmBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTagsFilter: [],
			selectedGroupsFilter: [],
			allTagsFilter: []
		};
	}

	onTagsChange = selectedTagsFilter => {
		this.setState({ selectedTagsFilter, allTagsFilter: [...this.state.selectedGroupsFilter, ...selectedTagsFilter] });
	};

	onGroupsChange = selectedGroupsFilter => {
		this.setState({ selectedGroupsFilter, allTagsFilter: [...this.state.selectedTagsFilter, ...selectedGroupsFilter] });
	};

	render() {
		let { allTagsFilter } = this.state;
		let {
			bulkLoading,
			bulkActionSuccess,
			bulkActionError,
			bulkActionPartialSuccess,
			errorFeedback,
			successFeedback,
			onConfirm,
			onNext,
			back,
			csvContacts
		} = this.props;

		return (
			<>
				<ConfirmBlock
					header={"Are you sure you want to upload contacts?"}
					loading={bulkLoading}
					submitSuccess={bulkActionSuccess || bulkActionPartialSuccess}
					submitError={bulkActionError}
					successMessage="Contacts uploaded"
					errorMessage="Error uploading contacts"
					confirm={() => onConfirm(allTagsFilter)}
					next={onNext}
					back={() => {
						if (back) {
							back();
						}
					}}
					showFeedback={true}
					successFeedback={successFeedback}
					errorFeedback={errorFeedback}
					backLabel="Map Csv"
					successFeedbackLabel="Successfully processed"
					errorFeedbackLabel="Failed to create"
					successBtnLabel="Done"
					showNextButton={!csvContacts || csvContacts.length > UPLOAD_LIMIT ? false : true}
				>
					<div className="mb-message-box mb-message-box--out" style={{ maxWidth: "400px", margin: "Auto" }}>
						<p>Up to {csvContacts && `${csvContacts.length} contacts will be created.`}</p>
						{csvContacts && csvContacts.length > UPLOAD_LIMIT && (
							<p className="text-danger">
								A maximum of {UPLOAD_LIMIT} contacts can be uploaded at a time. Please change the csv to have a maximum of {UPLOAD_LIMIT} rows.
							</p>
						)}
						<div>
							<span>Place contacts into a group or tag:</span>
						</div>
						<br />
						<ContactSelectorUpdate showHeader={true} numSelectedContacts={0} onSelectChange={this.onTagsChange} type={TAG_TYPES.tag} showButtons={false} />
						<ContactSelectorUpdate showHeader={true} numSelectedContacts={0} onSelectChange={this.onGroupsChange} type={TAG_TYPES.group} showButtons={false} />
					</div>
				</ConfirmBlock>
			</>
		);
	}
}

export default UploadContactsConfirmBlock;
