import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import * as Icon from "react-feather";
import moment from "moment";
import ContentLoader from "react-content-loader";

import UserService from "../../services/UserService";
import TagService from "../../services/TagService";
import ToastService from "../../services/ToastService";

import withLocation from "../../components/common/WithLocation";
import List from "../../components/common/List";
import Action from "../../components/common/Action";
import SearchInput from "../../components/common/SearchInput";
import Alert from "../../components/common/Alert";

import UpdateTagsModal from "../Tags/UpdateTagsModal";

import { GROUP_COLUMNS } from "../../constants/Groups";
import { SORT_ORDER } from "../../constants/CommonConstants";
import { TAG_TYPES } from "../../constants/Tags";

class Groups extends Component {
	constructor(props) {
		super(props);

		this.state = {
			groups: [],
			loading: true,
			createMode: false,
			searchTerm: "",
			limit: 50,
			pageSize: 50,
			sortField: GROUP_COLUMNS.name.id,
			sortOrder: SORT_ORDER.asc,
			showModal: false,
			selectedGroup: null,
			deleteConfirmModal: false,
			deleteConfirmModalLoading: false,
			warningData: {}
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	fetchGroups = async () => {
		let locationId = UserService.getActiveLocation().id;

		let { searchTerm, sortField, sortOrder, limit } = this.state;
		let groups = await TagService.getTags(locationId, { searchTerm, sortField, sortOrder, limit, type: "group" });

		if (!groups) {
			ToastService.info("An error occurred trying to get groups.");
			return;
		}

		await this.update({
			groups,
			loadedAll: groups.length < limit
		});
	};

	onCreateTagModalSubmit = async (createMode, id, name) => {
		await this.update({ showModal: false, selectedGroup: null });

		if (createMode) {
			let locationId = UserService.getActiveLocation().id;
			let response = await TagService.createTag(locationId, name, TAG_TYPES.group);

			if (!response) {
				ToastService.error(`Error trying to create group "${name}"`);
				return;
			}

			ToastService.info(`Successfully created group "${name}"`);
			await this.fetchGroups();
			return;
		}

		let response = await TagService.updateTag(id, name);

		if (!response) {
			ToastService.error(`Error trying to update group "${name}"`);
			return;
		}

		ToastService.info(`Successfully updated group "${name}"`);

		await this.fetchGroups();
	};

	async componentDidMount() {
		await this.fetchGroups();
		this.update({ loading: false });
	}

	onLocationChanged = () => {
		this.fetchGroups();
	};

	onSearch = async value => {
		const searchTerm = value;
		await this.update({ searchTerm });
		await this.fetchGroups();
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchGroups();
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ loading: true, sortField, sortOrder });
		await this.fetchGroups();
		await this.update({ loading: false });
	};

	getHeaders = () => {
		let columns = GROUP_COLUMNS;

		let headers = {
			items: columns,
			sortBy: this.sortBy
		};

		return headers;
	};

	showModal(group = null, createMode = true) {
		this.update({
			showModal: true,
			selectedGroup: group,
			createMode
		});
	}

	onConfirmDelete = async recordData => {
		await this.update({ deleteConfirmModal: true, selectedGroup: recordData, deleteConfirmModalLoading: true });
		let data = await TagService.getTagUsageData(recordData.id);
		await this.update({ deleteConfirmModalLoading: false, warningData: data });
	};

	onCloseConfirmDelete = confirmed => {
		if (confirmed) {
			this.onDelete();
			return;
		}
		this.update({ deleteConfirmModal: false });
	};

	onDelete = async () => {
		let { selectedGroup } = this.state;

		if (!selectedGroup) {
			return;
		}

		let response = await TagService.deleteTag(selectedGroup.id);
		if (!response) {
			ToastService.info(`An error occurred trying to deleting the group`);
			await this.update({
				showUpdateModal: false,
				selectedGroup: null,
				deleteConfirmModal: false
			});
			return;
		}

		ToastService.info(`Successfully deleted group`);
		await this.update({
			showUpdateModal: false,
			selectedGroup: null,
			deleteConfirmModal: false
		});

		await this.fetchGroups();
	};

	renderRecord = recordData => {
		try {
			let user = UserService.get();

			return [
				recordData.name,
				recordData.created_at ? moment(recordData.created_at).format("MMM Do YYYY, h:mm a") : "",
				recordData.updated_at ? moment(recordData.updated_at).format("MMM Do YYYY, h:mm a") : "",
				<div className="dh-list__actions">
					{user.GroupPermission.update_tags && (
						<Action id={`edit-group-${recordData.id}`} label="Edit" icon={Icon.Edit} onClick={() => this.showModal(recordData, false)} />
					)}
					{user.GroupPermission.delete_tags && (
						<Action id={`delete-group-${recordData.id}`} label="Delete" icon={Icon.Trash2} onClick={() => this.onConfirmDelete(recordData)} />
					)}
				</div>
			];
		} catch (error) {
			console.log(error.message);
		}
		return null;
	};

	onModalHide = async refresh => {
		await this.update({ showModal: false });

		if (refresh) {
			await this.update({ loading: true });
			await this.fetchGroups();
			await this.update({ loading: false });
		}
	};

	render() {
		let {
			groups,
			loading,
			loadedAll,
			sortField,
			sortOrder,
			showModal,
			createMode,
			selectedGroup,
			deleteConfirmModal,
			deleteConfirmModalLoading,
			warningData
		} = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let createGroupsPermission = user.GroupPermission.create_tags;

		return (
			<React.Fragment>
				<div className="contacts-search">
					<SearchInput placeholder={t("Search ...")} onChange={this.onSearch} />
					{createGroupsPermission && <Action id="create-tag" label={t("Create Group")} icon={Icon.Plus} onClick={() => this.showModal()} />}
				</div>
				<List
					items={groups}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("Looks like there are groups yet...")}
					noDataIcon={<Icon.AlertCircle />}
				/>
				<UpdateTagsModal
					data={selectedGroup}
					show={showModal}
					onHide={this.onModalHide}
					createMode={createMode}
					type={TAG_TYPES.group}
					onSubmit={this.onCreateTagModalSubmit}
				/>
				<Alert type="warning" show={deleteConfirmModal} title={t("Please confirm")} confirm={t("Yes")} cancel={t("No")} onClose={this.onCloseConfirmDelete}>
					<div>
						{deleteConfirmModalLoading && (
							<ContentLoader height={160} width={500}>
								<rect x="0" y="0" rx="5" ry="5" width="100%" height="70" />
								<rect x="0" y="90" rx="5" ry="5" width="100%" height="70" />
							</ContentLoader>
						)}
						{!deleteConfirmModalLoading && (
							<>
								<div>{t("Are you sure you would like to delete this group?")}</div>
								<br />
								{warningData && warningData.scheduledMessages ? (
									<div>{t("This group is being used in {{number}} pending scheduled messages.", { number: warningData.scheduledMessages })}</div>
								) : null}
								{warningData && warningData.contacts ? (
									<div>{t("This group is assigned {{number}} to contacts.", { number: warningData.contacts })}</div>
								) : null}
							</>
						)}
					</div>
				</Alert>
			</React.Fragment>
		);
	}
}

export default withLocation(withTranslation(null, { withRef: true })(Groups));
