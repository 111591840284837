import React, { Component } from "react";

import "../../styles/css/components/commons/legacy-page.css";

class LegacyPage extends Component {
	render() {
		return <div className="dh-legacy-page">{this.props.children}</div>;
	}
}

export default LegacyPage;
