import React, { Component } from "react";

import Modal from "../../../components/common/DHModal";
import UtilityService from "../../../services/UtilityService";

class EditCustomPositioningModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			url: "",
			originalUrl: "",
			canSave: false
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidUpdate = async prevProps => {
		if (prevProps.url !== this.props.url || prevProps.show !== this.props.show) {
			await this.update({ url: this.props.url, originalUrl: this.props.url });
		}
	};

	onSave = () => {
		if (!this.state.canSave) {
			return;
		}
		let url = this.modifyUrlForMatching(this.state.url);

		if (this.props.onSave) {
			this.props.onSave(url, this.state.originalUrl);
		}
	};

	modifyUrlForMatching = url => {
		try {
			if (!url) {
				return url;
			}
			url = new URL(url);

			// Enforce https, avoid query params, keep it simple
			// The https:// is actually not necessary since on the front end we don't account for it
			return `https://${url.hostname}${url.pathname}`;
		} catch (error) {
			console.log(`Url error - ${error}`);
		}

		// Ensure that it is https
		if (url.includes("http://")) {
			url.replace("http://", "https://");
		}
		// Ensure there is an https
		if (!url.includes("https://")) {
			url = `https://${url}`;
		}

		return url;
	};

	handleInputChange = async event => {
		try {
			let url = event.target.value.toLowerCase();

			await this.update({ url, canSave: UtilityService.isValidHttpsUrl(url) });
		} catch (error) {
			console.log(error);
		}
	};

	onHide = () => {
		this.update({ canSave: false });

		if (this.props.onHide) {
			this.props.onHide();
		}
	};

	renderTitle = () => {
		const { url } = this.props;
		return url ? "Add Custom Positioning by URL" : "Update Custom Positioning URL";
	};

	render() {
		let { show, createMode } = this.props;
		const { url, canSave } = this.state;

		return (
			<Modal show={show} onHide={this.onHide} title={this.renderTitle()}>
				<>
					<div className="modal__flex-container">
						<input
							name="url"
							id="url-input"
							type="text"
							onChange={this.handleInputChange}
							value={url}
							className="Common__input"
							autoComplete="off"
							placeholder="https://..."
						/>

						<div className="modal__actions">
							<div id={createMode ? "create" : "update"} className={`mb-button ${!canSave ? "mb-button--disabled" : ""}`} onClick={this.onSave}>
								Save
							</div>
						</div>
					</div>
				</>
			</Modal>
		);
	}
}
export default EditCustomPositioningModal;
