import React from "react";

import "../../styles/css/components/commons/card.css";

class DHCardHeader extends React.PureComponent {
	render() {
		return <div className="dh-card__header">{this.props.children}</div>;
	}
}
export default DHCardHeader;
