import React, { Component } from "react";
import TemplateService from "../../services/TemplateService";

import "../../styles/css/common.css";

class TextHighlighter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			highlightClass: this.props.highlightClass ? this.props.highlightClass : "badge Common_highlight-text",
			highlightStyle: this.props.highlightStyle ? this.props.highlightStyle : {},
			highlightRegex: this.props.highlightRegex
		};
	}

	async componentDidMount() {
		if (!this.props.highlightRegex) {
			let messageReplacements = await TemplateService.getMessageVariables();
			let excludeReplacements = [messageReplacements.contactLastName.id];
			let replacemenetVars = Object.values(messageReplacements).filter(r => !excludeReplacements.includes(r.id));
			this.setState({
				highlightRegex: new RegExp(`${replacemenetVars.map(r => r.allPatterns).join("|")}`, "gi")
			});
		}
	}

	async componentDidUpdate(prevProps) {
		let { highlightRegex } = this.props;

		if (prevProps.highlightRegex !== highlightRegex) {
			this.setState({ highlightRegex });
		}
	}

	render() {
		let { highlightClass, highlightStyle, highlightRegex } = this.state;
		let { text } = this.props;
		const parts = text.split(highlightRegex);
		return (
			<span>
				{" "}
				{parts.map((part, i) => (
					<span key={i} className={`${part.match(highlightRegex) ? `${highlightClass}` : ""}`} style={part.match(highlightRegex) ? highlightStyle : {}}>
						{part}
					</span>
				))}{" "}
			</span>
		);
	}
}

export default TextHighlighter;
