import UserService from "./UserService";
import Kichiri from "./KichiriService";
import NotificationService from "./NotificationService";
import { MAX_RECENT_COMPANIES_TO_STORE } from "../constants/CompanyConstants";

let locations = [];

const CompanyService = {
	/**
	 * Retrieves a location from the local cache, usually updated on refresh
	 *
	 * @param {Number} locationId
	 * @return {Object}
	 */
	getLocation(locationId) {
		return locations.find(l => {
			return l.id === locationId;
		});
	},

	/**
	 * Fetches all the locations for a specific company
	 *
	 * @param {Number} companyId
	 * @return {Promise}
	 */
	async fetchLocations({ companyId, searchTerm, sortField, sortOrder, limit }) {
		let authToken = UserService.getAuthToken();

		if (!authToken) {
			console.log("CompanyService: No valid auth token.");
			return;
		}

		if (!companyId) {
			companyId = UserService.getActiveCompany().id;
		}

		let data = { company: companyId };
		if (searchTerm) {
			data.searchTerm = searchTerm;
		}
		if (sortField) {
			data.sortField = sortField;
		}
		if (sortOrder) {
			data.sortOrder = sortOrder;
		}
		if (limit) {
			data.limit = limit;
		}

		let locations = null;

		try {
			let response = await Kichiri.location.fetch({}, data, authToken);

			locations = response.data;
		} catch (error) {
			console.log(error);
		}

		return locations;
	},
	/**
	 * Set location and company as admin
	 * @param {number} companyId
	 * @param {string} companyName
	 */
	async setCompanyAndLocationAsAdmin(companyId, companyName) {
		try {
			UserService.setActiveCompany(companyId, companyName);

			const data = await this.fetchLocations({ companyId, limit: 1 });

			UserService.unsetActiveLocation();

			if (data && data.length > 0) {
				// Set the active location to be the first location that we found for the active company (if one is available)
				UserService.setActiveLocation(data[0]);
			}

			NotificationService.publish("companyChanged", companyId);
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Fetches a company
	 *
	 * @param {Number} companyId
	 * @return {Promise}
	 */
	async fetchCompany(companyId) {
		try {
			let response = await Kichiri.company.getCompany(
				{
					companyId
				},
				{},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Create a company
	 * @param {String} name
	 * @param {String} report_sender_name
	 * @param {String} report_sender_email
	 * @param {Boolean} is_reseller
	 * @param {Boolean} enable_teamchat
	 * @param {String} status
	 * @param {Boolean} has_shared_website
	 * @returns {Object}
	 */
	async createCompany({ name, report_sender_name, report_sender_email, is_reseller, enable_teamchat, status, has_shared_website }) {
		try {
			let isTestCompany = UserService.get().Company.is_test_company;

			let response = await Kichiri.company.createCompany(
				{
					name,
					report_sender_name,
					report_sender_email,
					is_reseller,
					enable_teamchat,
					status,
					has_shared_website,
					is_test_company: isTestCompany
				},
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a company
	 * @param {String} name
	 * @param {String} report_sender_name
	 * @param {String} report_sender_email
	 * @param {Boolean} is_reseller
	 * @param {Boolean} enable_teamchat
	 * @param {String} status
	 * @param {Boolean} has_shared_website
	 * @returns {Object}
	 */
	async updateCompany({ companyId, name, report_sender_name, report_sender_email, is_reseller, enable_teamchat, status, has_shared_website }) {
		try {
			await Kichiri.company.updateCompany(
				{
					companyId,
					name,
					report_sender_name,
					report_sender_email,
					is_reseller,
					enable_teamchat,
					status,
					has_shared_website
				},
				{},
				UserService.getAuthToken()
			);

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update company Status
	 * @param {Number} companyId
	 * @param {String} status
	 * @returns {Promise}
	 */
	async updateCompanyStatus(companyId, status) {
		try {
			await Kichiri.company.updateCompany({ companyId, status }, {}, UserService.getAuthToken());
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Invite users to DemandHub, send an email invite
	 * @param {Number} companyId
	 * @param {Array/Object} users has group, locationIds, email, team chat channels etc.
	 */
	async inviteUsers(companyId, users) {
		let user = UserService.get();

		try {
			let response = await Kichiri.company.inviteUsers({ companyId, users }, {}, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Resend a user invite
	 *
	 * @param {Number} companyId
	 * @param {Number} inviteId
	 * @returns {Promise}
	 */
	async resendUserInvite({ companyId, inviteId }) {
		try {
			let response = await Kichiri.company.resendUserInvite({ companyId, inviteId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Cancel a user invite
	 *
	 * @param {Number} companyId
	 * @param {Number} inviteId
	 * @returns
	 */
	async cancelUserInvite({ companyId, inviteId }) {
		try {
			let response = await Kichiri.company.cancelUserInvite({ companyId, inviteId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * PUBLIC - Get a user Invite
	 * @param {String} guid
	 */
	async getUserInvite(guid) {
		try {
			let response = await Kichiri.company.getUserInvite({ guid }, {});
			let userInvite = response.data;
			return userInvite;
		} catch (error) {
			console.log(error);

			if (error && error.response.data && error.response.data.showError) {
				return {
					error: true,
					message: error.response.data.error
				};
			}
		}

		return null;
	},

	/**
	 * PUBLIC - Create a user from a user invite
	 * @param {Object} userDetails
	 */
	async createUser(userDetails) {
		try {
			let response = await Kichiri.company.createUserFromInvite(userDetails, {}, {});
			return response.data;
		} catch (error) {
			console.log(error);
			if (error && error.response.data && error.response.data.showError) {
				return {
					error: true,
					message: error.response.data.error
				};
			}
		}

		return null;
	},

	/**
	 * Fetch all user invites for a particular company
	 *
	 * @param {Number} companyId
	 * @param {String} sortField
	 * @param {String} sortOrder
	 * @param {Number} limit
	 * @returns
	 */
	async fetchUserInvites({ companyId, sortField, sortOrder, limit }) {
		let user = UserService.get();
		try {
			let response = await Kichiri.company.fetchUserInvites({}, { companyId, sortField, sortOrder, limit }, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch companies
	 * @param {Number} favoritesOnly
	 * @param {String} searchTerm
	 * @param {Array} status
	 * @param {String} sortField
	 * @param {String} sortOrder
	 * @param {Number} limit
	 * @param {Number} companyId
	 * @param {Number} locationId
	 * @param {Boolean} isAdvanceFetch if true we can search based on companyId or locationId
	 *
	 * @return {Array}
	 */
	async fetchCompanies({ favoritesOnly, searchTerm, status, sortField, sortOrder, limit, companyId, isAdvanceFetch = false, locationId }) {
		try {
			let data = {};

			if (favoritesOnly) {
				data.favorites = 1;
			}
			if (searchTerm) {
				data.searchTerm = searchTerm;
			}
			if (status) {
				data.status = status;
			}
			if (sortField) {
				data.sortField = sortField;
			}
			if (sortOrder) {
				data.sortOrder = sortOrder;
			}
			if (limit) {
				data.limit = limit;
			}
			if (companyId) {
				data.companyId = companyId;
			}

			if (isAdvanceFetch) {
				data.isAdvanceFetch = isAdvanceFetch;

				if (locationId) {
					data.locationId = locationId;
				}
			}
			let response = await Kichiri.company.fetchCompanies({}, data, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch all companies for the maps view
	 * @param {Number} limit
	 *
	 * @return {Array}
	 */
	async fetchAllCompanies({ limit }) {
		try {
			let response = await Kichiri.admin.companies({}, { limit }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Mark the specified company id as a favorite in the backend
	 * @param {Number} companyId
	 * @returns {Promise}
	 */
	async markFavorite(companyId) {
		try {
			let response = await Kichiri.company.markFavorite(
				{
					companyId
				},
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Unmark the specified company id as a favorite in the backend
	 * @param {Number} companyId
	 * @returns {Promise}
	 */
	async unmarkFavorite(companyId) {
		try {
			await Kichiri.company.unmarkFavorite(
				{
					companyId
				},
				{},
				UserService.getAuthToken()
			);
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Get recently used company from local storage
	 * @returns Array
	 */
	getRecentlyUsedCompanies() {
		try {
			let localStorageRecentTemplates = localStorage.getItem("dh_recently_used_companies");

			if (typeof localStorageRecentTemplates === "undefined" || localStorageRecentTemplates === null) {
				localStorageRecentTemplates = [];
				localStorage.setItem("dh_recently_used_companies", JSON.stringify(localStorageRecentTemplates));
			} else {
				return JSON.parse(localStorageRecentTemplates);
			}

			return localStorageRecentTemplates;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Registers a recently used company in local storage
	 * @param companyId
	 * @param companyName
	 * @returns Array
	 */
	registerRecentlyUsedCompany(companyId, companyName) {
		try {
			let recentlyUsedCompanies = this.getRecentlyUsedCompanies();

			// If we are not allowed to store any recently used templates
			if (MAX_RECENT_COMPANIES_TO_STORE === 0) {
				localStorage.setItem("dh_recently_used_companies", JSON.stringify([]));
				return;
			}

			// Check if the company already exists and remove it
			for (let i = 0; i < recentlyUsedCompanies.length; i++) {
				if (recentlyUsedCompanies[i].id === companyId) {
					recentlyUsedCompanies.splice(i, 1);
					break;
				}
			}

			// Add the company at the end of the list
			recentlyUsedCompanies.push({ id: companyId, name: companyName });

			// If the list exceeds the max limit, remove the oldest entry (from the start of the list)
			if (recentlyUsedCompanies.length > MAX_RECENT_COMPANIES_TO_STORE) {
				recentlyUsedCompanies.shift(); // Remove the first element
			}

			// Store the updated list in local storage
			localStorage.setItem("dh_recently_used_companies", JSON.stringify(recentlyUsedCompanies));
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Clears recently used companies in local storage
	 * @returns
	 */
	clearRecentlyUsedCompanies() {
		try {
			localStorage.removeItem("dh_recently_used_companies");
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Trigger a cron task to run
	 * @param {String} name
	 * @returns {Object}
	 */
	async runCronTask({ name }) {
		try {
			let response = await Kichiri.admin.runCronTask(
				{
					name: name
				},
				{},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Fetch a list of cron tasks
	 * @returns {Array}
	 */
	async fetchCronTasks() {
		try {
			let response = await Kichiri.admin.fetchCronTasks({}, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Fetch all compare tool leads
	 * @returns {Array}
	 */
	async fetchAllCompareToolLeads() {
		try {
			let response = await Kichiri.crm.fetchAllCompareToolLeads({}, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch a compare tool lead
	 * @param {Integer} compareToolLeadId
	 * @returns {Object}
	 */
	async fetchCompareToolLead(compareToolLeadId) {
		try {
			let response = await Kichiri.crm.fetchCompareToolLead({}, { compareToolLeadId }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create Compare Tool Lead
	 * @param {Object} data
	 * @returns {Boolean/null}
	 */
	async createCompareToolLead(data) {
		try {
			await Kichiri.crm.createCompareToolLead({ data }, {}, UserService.getAuthToken());

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Send a Compare Tool Lead
	 * @param {Object} data
	 * @returns {Boolean/null}
	 */
	async sendCompareToolLeadReport(data) {
		try {
			await Kichiri.crm.sendCompareToolLeadReport({ data }, {}, UserService.getAuthToken());

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	}
};

export default CompanyService;
