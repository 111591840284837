import React, { Component } from "react";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import UtilityService from "../../services/UtilityService";

import PasswordRequirements from "./PasswordRequirements";

import "../../styles/css/components/commons/dh-input.css";
import "../../styles/css/components/commons/input-password.css";

class PasswordInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showPassword: false,
			showRequirements: false
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	passwordMeetsRequirements = value => {
		let { requirements } = this.props;
		return UtilityService.isValidPassword({ password: value, requirements: requirements });
	};

	onChange = event => {
		this.update({ showRequirements: true });

		let value = event.target.value;

		// Check if this new password satisfies the requirements
		let passed = this.passwordMeetsRequirements(value);

		if (this.props.onChange) {
			this.props.onChange({ value, passed, event });
		}
	};

	renderRequirements() {
		let { showRequirements } = this.state;
		let { requirements, value } = this.props;

		if (!showRequirements || !requirements || Object.keys(requirements).length === 0 || !value) {
			return null;
		}

		return <PasswordRequirements requirements={requirements} value={value} />;
	}

	render() {
		let { showPassword } = this.state;
		let {
			id,
			name,
			value,
			disabled = false,
			required,
			// XXX Works better than "off" https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/
			autoComplete = "new-password",
			requirements,
			placeholder,
			showToggleView = true,
			onKeyDown,
			maxLength,
			t
		} = this.props;

		return (
			<>
				<div className="input input--password">
					<input
						id={id}
						name={name}
						value={value}
						onChange={e => this.onChange(e)}
						onKeyDown={onKeyDown}
						type={showPassword ? "text" : "password"}
						className="input__input input__input--password"
						placeholder={placeholder || t("Password")}
						required={required}
						autoComplete={autoComplete}
						disabled={disabled}
						maxLength={maxLength}
					/>
					{showToggleView && (
						<div className="input__toggle" onClick={() => this.setState({ showPassword: !showPassword })}>
							{showPassword ? <Icon.Eye size={17} /> : <Icon.EyeOff size={17} />}
						</div>
					)}
				</div>
				{requirements && this.renderRequirements()}
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(PasswordInput);
