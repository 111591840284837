import React, { Component } from "react";
import * as Icon from "react-feather";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import UtilityService from "../../services/UtilityService";
import CSVParsingService from "../../services/CSVParsingService";

import { COLUMN_HEADING_OPTION, INVALID_EMAIL_THRESHOLD, COLUMN_HEADING_OPTION_MAP } from "../../constants/BulkActionsConstants";
import { CsvMappingConstants } from "../../constants/CsvMappingConstants";

import "../../styles/css/scenes/csv-parsing-modal.css";

class BulkCsvMapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showFirsXRows: this.props.showFirsXRows ? this.props.showFirsXRows : 10,
			columnMappingErrorMsg: [],
			maxColumnLength: -1,

			duplicateNameColumn: "",
			duplicateFirstNameColumn: "",
			duplicateLastNameColumn: "",
			duplicateEmailColumn: "",
			duplicatePhoneColumn: "",
			duplicateNamingColumns: "",

			isValidColumnMapping: false,

			invalidPhoneColumn: "",
			invalidEmailColumn: "",

			columnStateTracker: [],
			columnDataTracker: [],
			csvData: [],
			csvString: "",
			csvHeaders: [],
			csvHasHeader: false,

			nextButtonLabel: this.props.nextButtonLabel ? this.props.nextButtonLabel : "Next",
			backButtonLabel: this.props.backButtonLabel ? this.props.backButtonLabel : "Back",
			selectedNameFormat: CsvMappingConstants.FORMAT.NAMES.auto.value
		};
	}

	componentDidMount() {
		this.initializeCSVParsingStateAttributes();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.csvData !== this.props.csvData) {
			this.initializeCSVParsingStateAttributes();
		}
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	getHeadersList = () => {
		let columns = this.state.columnStateTracker;
		let headers = [];
		columns.forEach(col => {
			let val = this.state[col] ? this.state[col] : "skip";
			headers.push(val);
		});
		return headers;
	};

	/**
	 * Evaluate Bulk review invite validity states to ensure that all criteria are met.
	 * Only once this function sets `isValidColumnMapping` to true can the user package and
	 * send bulk review invites
	 * @returns Boolean indicating validity
	 */
	validateColumnMappings() {
		let columns = this.state.columnStateTracker;

		//Ensure these match with the 'csvFields' state variable
		let nameColumn = COLUMN_HEADING_OPTION.name.value;
		let firstNameColumn = COLUMN_HEADING_OPTION.firstName.value;
		let lastNameColumn = COLUMN_HEADING_OPTION.lastName.value;
		let phoneColumn = COLUMN_HEADING_OPTION.phone.value;
		let emailColumn = COLUMN_HEADING_OPTION.email.value;

		let seenHeadings = {};
		var eachColumnSeenOnce = true;

		columns.forEach(col => {
			let val = this.state[col];

			if (val !== "skip") {
				if (seenHeadings[val]) {
					eachColumnSeenOnce = false;
					seenHeadings[val] += 1;
				} else if (val) {
					seenHeadings[val] = 1;
				}
			}
		});

		// Make sure that phone or email exist, and atleast one of the names
		let validMinimalMapping = true;

		if (!seenHeadings[nameColumn] && !seenHeadings[firstNameColumn] && !seenHeadings[lastNameColumn]) {
			validMinimalMapping = false;
		}
		if (seenHeadings[nameColumn] && (seenHeadings[firstNameColumn] || seenHeadings[lastNameColumn])) {
			validMinimalMapping = false;
		}
		if (seenHeadings[lastNameColumn] && !seenHeadings[firstNameColumn]) {
			validMinimalMapping = false;
		}

		if (!seenHeadings[phoneColumn] && !seenHeadings[emailColumn]) {
			validMinimalMapping = false;
		}

		let validColumnMapping = eachColumnSeenOnce && validMinimalMapping && this.state.columnMappingErrorMsg.length === 0;

		this.update({
			isValidColumnMapping: validColumnMapping
		});
	}

	/**
	 * Resets any invalid states previously identified for a newly validated column
	 * @param {Integer} columnId
	 */
	async resetInvalidStatesForColumn(columnId) {
		if (this.state.invalidEmailColumn === columnId) {
			await this.update({
				invalidEmailColumn: ""
			});
			this.updateErrorMessages();
		}
		if (this.state.invalidPhoneColumn === columnId) {
			await this.update({
				invalidPhoneColumn: ""
			});
			this.updateErrorMessages();
		}
	}

	/**
	 * Clear all states that indicate duplicate column mapping defined
	 */
	async clearDuplicateStates() {
		await this.update({
			duplicateEmailColumn: "",
			duplicateNameColumn: "",
			duplicateFirstNameColumn: "",
			duplicateLastNameColumn: "",
			duplicatePhoneColumn: "",
			duplicateNamingColumns: ""
		});
		this.updateErrorMessages();
	}

	onClickBack = () => {
		let { csvData, csvString } = this.state;

		if (this.props.updateMappedData) {
			this.props.updateMappedData([], csvString, csvData);
		}
		if (this.props.back) {
			this.props.back();
		}
	};

	/**
	 * Returns the maximum number of columns across all rows in a 2d Array
	 * @param {Array[Array[String]]} table
	 * @returns {Integer} Integer
	 */
	getMaxColumnLength(table) {
		if (table.length === 0) return 0;
		let maxColumnLength = table[0].length;
		for (let i = 0; i < table.length; i++) {
			if (table[i].length > maxColumnLength) {
				maxColumnLength = table[i].length;
			}
		}
		return maxColumnLength;
	}

	processCsvMapping = () => {
		let { isValidColumnMapping, csvData, csvString, csvHasHeader } = this.state;

		if (!isValidColumnMapping) {
			return;
		}

		let headers = this.getHeadersList();

		let processedCsvData = JSON.parse(JSON.stringify(csvData));
		let processedHeaders = JSON.parse(JSON.stringify(headers));

		// Use object to make things easier
		let processedCsvStringObject = csvString.split("\n");

		// Check if "first_name" or "last_name" is present in the headers
		const hasFirstName = processedHeaders.includes(COLUMN_HEADING_OPTION.firstName.value);
		const hasLastName = processedHeaders.includes(COLUMN_HEADING_OPTION.lastName.value);

		// If either "first_name" or "last_name" is present and "name" is not,
		// add "name" to the beginning of header, and process data rows (and data string)
		if ((hasFirstName || hasLastName) && !processedHeaders.includes("name")) {
			let nonHeaderFirstNameIndex = processedHeaders.indexOf(COLUMN_HEADING_OPTION.firstName.value);
			let nonHeaderLastNameIndex = processedHeaders.indexOf(COLUMN_HEADING_OPTION.lastName.value);

			// Add "name" to the beginning of headers
			processedHeaders.unshift("name");

			// Add name to the begining of the string data if first row was header
			if (csvHasHeader) {
				processedCsvStringObject[0] = `"FULL_NAME", ` + processedCsvStringObject[0];
			}

			// Process data rows
			for (let i = 0; i < processedCsvData.length; i++) {
				const row = processedCsvData[i];

				let firstName = hasFirstName ? row[nonHeaderFirstNameIndex] : "";
				let lastName = hasLastName ? row[nonHeaderLastNameIndex] : "";

				const fullName = `${firstName} ${lastName}`.trim();
				row.unshift(fullName);
				processedCsvData[i] = row;

				// Skip the first row if it has a header
				if (csvHasHeader) {
					processedCsvStringObject[i + 1] = `"${fullName}", ` + processedCsvStringObject[i + 1];
				} else {
					processedCsvStringObject[i] = `"${fullName}", ` + processedCsvStringObject[i];
				}
			}
		}

		let dataForCsvString = [...processedCsvData];
		// Add the header row
		if (csvHasHeader) {
			dataForCsvString.unshift(processedHeaders);
		}

		let processedCsvString = CSVParsingService.dataToCsvString(dataForCsvString);

		// Update mapped data and call the next function if applicable
		if (this.props.updateMappedData) {
			this.props.updateMappedData(processedHeaders, processedCsvString, processedCsvData);
		}

		if (this.props.next) {
			this.props.next(this.state.selectedNameFormat.value);
		}
	};

	/*
	 * Initialize all relevant state parameters to handle parsing and mapping CSV columns
	 * for bulk review invites
	 * @param {Array[Array[String]]} result csv data that is to be used to initialize relevant states
	 */
	initializeCSVParsingStateAttributes() {
		let { csvData, csvString, csvHasHeader, csvHeaders } = this.props;

		if (!csvData) {
			return;
		}

		let processedCsvData = JSON.parse(JSON.stringify(csvData));
		let processedHeaders = JSON.parse(JSON.stringify(csvHeaders));
		// Use object to make things easier
		let processedCsvStringObject = csvString.split("\n");

		const hasFirstName = processedHeaders.includes(COLUMN_HEADING_OPTION.firstName.value);
		const hasLastName = processedHeaders.includes(COLUMN_HEADING_OPTION.lastName.value);

		if (hasFirstName || hasLastName) {
			processedHeaders.shift();

			for (let i = 0; i < processedCsvData.length; i++) {
				const row = processedCsvData[i];

				row.shift();
				processedCsvData[i] = row;

				let stringRow = processedCsvStringObject[i].split(",");
				stringRow.shift();
				stringRow = stringRow.join(",");
				processedCsvStringObject[i] = stringRow;
			}
		}

		let numColumns = this.getMaxColumnLength(processedCsvData);
		this.setState({ maxColumnLength: numColumns });
		let columnTracker = [];
		let columnDataTracker = [];
		for (let i = 0; i < numColumns; i++) {
			let columnData = [];
			let columnDataStateName = "columnData_" + i;
			columnTracker.push("columnSelect_" + i);
			columnDataTracker.push(columnDataStateName);
			for (let j = 0; j < processedCsvData.length; j++) {
				if (processedCsvData[j][i]) {
					columnData.push(processedCsvData[j][i].trim());
				} else {
					columnData.push("");
				}
			}
			this.setState({
				[columnDataStateName]: columnData
			});
		}

		let processedCsvString = processedCsvStringObject.join("\n");
		this.setState({
			columnStateTracker: columnTracker,
			columnDataTracker: columnDataTracker,
			csvData: processedCsvData,
			csvString: processedCsvString,
			csvHeaders: processedHeaders,
			csvHasHeader: csvHasHeader
		});
	}

	getValue = columnSelectorName => {
		let value = columnSelectorName;
		if (typeof value == "undefined") {
			return { label: "Skip", value: "skip" };
		}

		return COLUMN_HEADING_OPTION[COLUMN_HEADING_OPTION_MAP[value]];
	};

	/**
	 * Update Bulk review invite validity states based on what category the user assigns to
	 * specific column from CSV data
	 */
	async handleCSVFieldSelection(event, id) {
		let selection = event.value;
		let columnId = id;
		let targetData = "columnData_" + columnId;
		let data = this.state[targetData];

		let nameColumn = COLUMN_HEADING_OPTION.name.value;
		let firstNameColumn = COLUMN_HEADING_OPTION.firstName.value;
		let lastNameColumn = COLUMN_HEADING_OPTION.lastName.value;

		let phoneColumn = COLUMN_HEADING_OPTION.phone.value;
		let emailColumn = COLUMN_HEADING_OPTION.email.value;

		// To keep track of number of valid pieces of data in the column
		let validCount = 0;
		let totalCount = data.length;
		// Check for duplicate column mappings
		let columnHeadings = this.state.columnStateTracker;
		let seenColumns = [];
		this.clearDuplicateStates();

		for (const colName of columnHeadings) {
			let col = this.state[colName];
			if (!seenColumns.includes(col)) seenColumns.push(col);
			else {
				if (col === nameColumn) {
					await this.update({
						duplicateNameColumn: columnId
					});
					this.updateErrorMessages();
				}
				if (col === firstNameColumn) {
					await this.update({
						duplicateFirstNameColumn: columnId
					});
					this.updateErrorMessages();
				}
				if (col === lastNameColumn) {
					await this.update({
						duplicateLastNameColumn: columnId
					});
					this.updateErrorMessages();
				}
				if (col === phoneColumn) {
					await this.update({
						duplicatePhoneColumn: columnId
					});
					this.updateErrorMessages();
				}
				if (col === emailColumn) {
					await this.update({
						duplicateEmailColumn: columnId
					});
					this.updateErrorMessages();
				}
			}
		}

		// Additional error checks
		if (
			(seenColumns.includes(COLUMN_HEADING_OPTION.firstName.value) && seenColumns.includes("name")) ||
			(seenColumns.includes(COLUMN_HEADING_OPTION.lastName.value) && seenColumns.includes("name"))
		) {
			await this.update({
				duplicateNamingColumns: 1
			});
			this.updateErrorMessages();
		}

		if (selection === nameColumn || selection === firstNameColumn || selection === lastNameColumn) {
			this.resetInvalidStatesForColumn(columnId);
			return;
		}

		if (selection === emailColumn) {
			let invalidFound = false;
			data.forEach(email => {
				if (email === "" || UtilityService.isEmail(email)) {
					validCount++;
				}
			});
			if (validCount <= INVALID_EMAIL_THRESHOLD * totalCount) {
				invalidFound = true;
				let update = { invalidEmailColumn: columnId };
				if (this.state.invalidPhoneColumn === columnId) {
					update.invalidPhoneColumn = "";
				}
				await this.update(update);
				this.updateErrorMessages();
			}

			if (!invalidFound) {
				//If target column was invalid before, clear the invalid states
				this.resetInvalidStatesForColumn(columnId);
				return;
			}
		}

		if (selection === phoneColumn) {
			let invalidFound = false;
			data.forEach(phone => {
				//Invalid if phone includes letter
				if (phone === "" || (UtilityService.isMobilePhoneValid(phone) && !/[a-zA-Z]/.test(phone))) {
					validCount++;
				}
			});

			if (validCount <= INVALID_EMAIL_THRESHOLD * totalCount) {
				invalidFound = true;
				let update = { invalidPhoneColumn: columnId };
				if (this.state.invalidEmailColumn === columnId) {
					update.invalidEmailColumn = "";
				}
				await this.update(update);
				this.updateErrorMessages();
			}
			if (!invalidFound) {
				//If target column was invalid before, clear the invalid states
				this.resetInvalidStatesForColumn(columnId);
				return;
			}
		}
		// Reset any invalid states assigned to current column if it is set to 'Skip'
		if (selection === "skip") {
			this.resetInvalidStatesForColumn(columnId);
		}
	}

	/**
	 * Enables the user to configure the bulk invite request. Generates and returns array of HTML elements  with
	 * csv column heading selection capabilities and corresponding raw data from csv file.
	 */
	renderCSVMappingColumns() {
		if (!this.state.csvData || !this.state.csvData[0]) {
			return;
		}
		var columns = this.state.maxColumnLength;
		var DOMelements = [];
		var globalKeyIndex = 0;

		const filteredCsvData = this.state.csvData;
		for (let i = 0; i < columns; i++) {
			let rowCount = 0;
			let columnSelectorName = "columnSelect_" + i;
			let optionsObj = { ...COLUMN_HEADING_OPTION };

			let options = Object.values(optionsObj);
			options.unshift({ label: "Skip", value: "skip" });
			DOMelements.push(
				<div key={globalKeyIndex++} id={"upload-" + i} className="csv-column">
					<Select
						id={"header-" + i}
						options={options}
						className="csv-mapper-property-selector"
						styles={{ background: "aliceBlue" }}
						onChange={async event => {
							await this.update({
								[columnSelectorName]: event.value
							});
							this.handleCSVFieldSelection(event, i);
						}}
						value={this.state[columnSelectorName] ? this.getValue(this.state[columnSelectorName]) : { label: "Skip", value: "skip" }}
					/>
					{// eslint-disable-next-line
					filteredCsvData.map(item => {
						if (rowCount < this.state.showFirsXRows) {
							rowCount++;
							return (
								<div key={globalKeyIndex++} className={"column_" + i} id="csv-data-cell">
									{item[i] ? item[i].trim() : " "}
								</div>
							);
						}

						return null;
					})}
				</div>
			);
		}
		return DOMelements;
	}

	/**
	 * Check all states that describe invalid or duplicate column mappings and
	 * update error messages accordingly
	 */
	async updateErrorMessages() {
		let currErrorMsg = [];

		// Invalid column values
		if (this.state.invalidEmailColumn !== "") {
			currErrorMsg.push("Invalid Email Column. Please ensure that the emails are properly formatted. eg 'johndoe@email.com'");
		}
		if (this.state.invalidPhoneColumn !== "") {
			currErrorMsg.push("Invalid Phone Column. Please ensure that the phone numbers are properly formatted. eg '18009714547'");
		}

		// Duplicate column errors
		if (this.state.duplicateEmailColumn !== "") {
			currErrorMsg.push("Please select ONLY 1 column for email of the contact");
		}
		if (this.state.duplicatePhoneColumn !== "") {
			currErrorMsg.push("Please select ONLY 1 column for cell phone of the contact");
		}
		if (this.state.duplicateNameColumn !== "") {
			currErrorMsg.push("Please select ONLY 1 column for name of the contact and ensure it includes the first name");
		}
		if (this.state.duplicateFirstNameColumn !== "") {
			currErrorMsg.push("Please select ONLY 1 column for the first name of the contact");
		}
		if (this.state.duplicateLastNameColumn !== "") {
			currErrorMsg.push("Please select ONLY 1 column for last name of the contact");
		}
		if (this.state.duplicateNamingColumns !== "") {
			currErrorMsg.push("Please select ONLY columns first name and last name, or just name.");
		}

		await this.update({
			columnMappingErrorMsg: currErrorMsg
		});
		this.validateColumnMappings();
	}

	render() {
		let { showFirsXRows, columnMappingErrorMsg, isValidColumnMapping, nextButtonLabel, backButtonLabel, selectedNameFormat, csvData } = this.state;

		return (
			<div className="csv-mapper">
				<div className="csv-mapper--instructions">
					<h3>Instructions</h3>
					<div className="csv-mapper--instructions__list">
						<div>
							Please select the type of data for each column. Ensure that you select{" "}
							<strong>
								one column for the name of the contact and one column for their corresponding contact information - either email or phone number (or both).
							</strong>
						</div>
						<div>
							For the <strong>name</strong> column, please ensure that it includes the first name of the contact.
						</div>

						<div>
							If you select both an email and phone number for a contact, the phone number will take precedence and invites will be sent by SMS. Leave as 'skip'
							if you want to ignore the column.
						</div>
					</div>
				</div>
				<div className="csv-mapper--name-format-selector">
					<label data-tip data-for="name-info-tooltip">
						Name Format{" "}
						<span className="csv-mapper--name-format-selector-icon">
							<Icon.Info size="15" />
						</span>
					</label>
					<ReactTooltip id="name-info-tooltip" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="right">
						<div>
							Auto will automatically detect: <br />
							"FirstName" <br />
							"FirstName LastName" <br />
							"LastName, FirstName" <br />
						</div>
					</ReactTooltip>
					<Select
						id="name-format"
						options={Object.values(CsvMappingConstants.FORMAT.NAMES)}
						value={selectedNameFormat}
						placeholder="Auto"
						onChange={selectedNameFormat => {
							this.setState({ selectedNameFormat });
						}}
					/>
				</div>
				<div className="csv-mapper--show-first-rows">
					Showing the first {csvData.length > showFirsXRows ? showFirsXRows : csvData.length} row(s) of the CSV file.
				</div>

				<div className="csv-column-mapping-wrapper">{this.renderCSVMappingColumns()}</div>
				<div className="csv-column-mapping-errorMsgs">
					{columnMappingErrorMsg.map(error => {
						return (
							<div key={error}>
								<span className="csv-column-mapping-errorMsg">{error}</span>
								<br />
							</div>
						);
					})}
				</div>
				<div className="invites-bulkUploading-buttons">
					<div className="mb-button mb-button--fit" onClick={this.onClickBack}>
						{backButtonLabel}
					</div>
					<div
						className={`mb-button mb-button--fit ${!isValidColumnMapping ? "mb-button--disabled" : ""} invites__sendbulkinvites--button`}
						onClick={this.processCsvMapping}
					>
						{nextButtonLabel}
					</div>
				</div>
			</div>
		);
	}
}

export default BulkCsvMapper;
