import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import "../../styles/css/scenes/connections.css";

class ConnectionsCard extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		let { t } = this.props;
		let { crmIntegrationId, title, logoUrl, description, status, actionText, onClick } = this.props;

		let hasStatus = typeof status !== "undefined" && status !== null;

		return (
			<div className="connections__section-list">
				<div className="connections__section-list-item">
					<div className="connections__section-list-item__icon">
						<img src={logoUrl} alt="Connected Apps" />
					</div>
					<div className="connections__section-list-item__information">
						<div className="connections__section-list-item__information_title">{title}</div>
						<div className="connections__section-list-item__information_description">{description}</div>
					</div>
					<div className="connections__section-list-item__spacer" />
					<div className="connections__section-list-item__status_indicator">
						{hasStatus && (
							<>
								<div
									className={`connections__section-list-item__status_indicator_circle ${
										status ? "connections__item__status--ok" : "connections__item__status--bad"
									}`}
									data-tip
									data-for={`connection-card-tooltip-${crmIntegrationId}`}
								/>
								<ReactTooltip
									id={`connection-card-tooltip-${crmIntegrationId}`}
									className="mb-react-tooltip"
									arrowColor="#333"
									type="info"
									effect="solid"
									place="bottom"
								>
									{status ? "Connected" : "Not Connected"}
								</ReactTooltip>
							</>
						)}
					</div>
					<div className="connections__section-list-item__action">
						{onClick && (
							<div className="mb-button mb-button--fit" onClick={onClick}>
								{actionText}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(ConnectionsCard);
