import React from "react";
import { Star, StarOff } from "lucide-react";

class StarRating extends React.Component {
	render() {
		let stars = [];

		for (let i = 0; i < this.props.maxRating; i++) {
			if (i < this.props.rating) {
				stars.push({ rating: i + 1, full: true });
			} else {
				stars.push({ rating: i + 1, full: false });
			}
		}

		return (
			<div>
				{stars.map(star => {
					if (star.full) {
						return <Star className="fnctst-star-full" key={star.rating} color="#ffdc57" fill="#ffdc57" width={20} height={20} />;
					} else {
						return <Star key={star.rating} color="#ffdc57" width={20} height={20} />;
					}
				})}
			</div>
		);
	}
}

export default StarRating;
