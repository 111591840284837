import React from "react";
import { withTranslation } from "react-i18next";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";

import TwilioA2P from "./TwilioA2P";

import GAService from "../../../services/GAService";

import { NUMBERS_TABS } from "../../../constants/Settings";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";
class Numbers extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedTab: NUMBERS_TABS.twilioA2P.id
		};
	}

	componentDidMount = async () => {
		GAService.GAPageView({ page: this.props.location.pathname });
	};

	onTabSelect = tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.settings.sections.numbers,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		this.setState({ selectedTab: tab.id });
	};

	render() {
		let { selectedTab } = this.state;
		let { t } = this.props;

		return (
			<Page>
				<Header title={t("Numbers")} />
				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					<Tab id={NUMBERS_TABS.twilioA2P.id} value={NUMBERS_TABS.twilioA2P.value} />
				</Tabs>
				{NUMBERS_TABS.twilioA2P.id === selectedTab && <TwilioA2P />}
			</Page>
		);
	}
}

export default withTranslation(null, { withRef: true })(Numbers);
