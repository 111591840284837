import React from "react";
import moment from "moment";
import ContentLoader from "react-content-loader";
import ReactSwitch from "react-switch";
import Select from "react-select";
import { withTranslation } from "react-i18next";

import Modal from "../../../components/common/DHModal";
import Input from "../../../components/common/Input";
import DHSelect from "../../../components/common/DHSelect";
import Toggle from "../../../components/common/Toggle";

import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import A2PService from "../../../services/A2PService";

class EditSubmissionModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			config: null,

			selectableSubmissionTypes: [],
			selectablePreviousBrandSubmissions: [],
			selectableBrands: [],
			selectableCampaigns: [],

			// Submission data
			submissionType: "brand",
			mock: true,
			dhBrandId: 0,
			dhCampaignId: 0,
			dhPreviousReferenceSubmissionId: 0
		};
	}

	componentDidUpdate = async prevProps => {
		if (prevProps.show !== this.props.show) {
			await this.resetComponent();
		}
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({ loading: true });

		await this.update({
			// Modal Data
			config: null,

			selectableSubmissionTypes: [],
			selectablePreviousBrandSubmissions: [],
			selectableBrands: [],
			selectableCampaigns: [],

			// Submission Data
			submissionType: "brand",
			mock: true,
			dhBrandId: 0,
			dhCampaignId: 0,
			dhPreviousReferenceSubmissionId: 0
		});

		await this.fetchConfig();
		await this.fetchSubmission();
		await this.buildFormOptions();

		await this.update({ loading: false });
	};

	fetchConfig = async () => {
		let config = await A2PService.fetchConfig();
		await this.update({ config });
	};

	fetchSubmission = async () => {
		let { t } = this.props;

		if (!this.props.submissionId) {
			return;
		}

		let submission = await A2PService.fetchSubmission({ submissionId: this.props.submissionId });

		if (!submission) {
			ToastService.error(t("Could not fetch submission."));
			return;
		}

		await this.update({
			submissionType: submission.submission_type,
			mock: submission.mock,
			dhBrandId: submission.dh_brand_id,
			dhCampaignId: submission.dh_campaign_id,
			dhPreviousReferenceSubmissionId: submission.dh_previous_reference_submission_id
		});
	};

	buildFormOptions = async () => {
		let { config } = this.state;
		let { t } = this.props;

		let locationId = UserService.getActiveLocation().id;
		let companyId = UserService.getActiveCompany().id;

		let selectableSubmissionTypes = [];
		let selectablePreviousBrandSubmissions = [];
		let selectableBrands = [];
		let selectableCampaigns = [];

		let previousBrandSubmissions = await A2PService.fetchSubmissions({ companyId });
		let brands = await A2PService.fetchBrands({ companyId });
		let campaigns = await A2PService.fetchCampaigns({ locationId });

		selectableSubmissionTypes.push({ label: t("Brand"), value: config.dhEnums.submissionTypes.brand });
		selectableSubmissionTypes.push({ label: t("Campaign"), value: config.dhEnums.submissionTypes.campaign });

		for (let i = 0; i < previousBrandSubmissions.length; i++) {
			let previousBrandSubmission = previousBrandSubmissions[i];
			let isBrandSubmission = previousBrandSubmission.submission_type === config.dhEnums.submissionTypes.brand;
			let isVerified = previousBrandSubmission.tcr_brand_status === config.enums.brandIdentityStatuses.verified;
			let isMock = previousBrandSubmission.mock;

			if (isBrandSubmission && isVerified) {
				selectablePreviousBrandSubmissions.push({
					value: previousBrandSubmission.id,
					label: `${t("ID")}: ${previousBrandSubmission.id} -  ${isMock ? t("Mock") : t("Real")} ${t("verified")} ${
						previousBrandSubmission.submission_type
					} ${t("submission")} (${t("Updated")} ${moment(previousBrandSubmission.updated_at).format("MMM Do hh:mm a")})`
				});
			}
		}

		selectablePreviousBrandSubmissions.unshift({ label: t("Choose a previous brand submission"), value: 0 });

		selectableBrands = brands.map(brand => {
			return {
				value: brand.id,
				label: `${t("ID")}: ${brand.id} - ${brand.company_name} (${t("Updated")} ${moment(brand.updated_at).format("MMM Do hh:mm a")})`
			};
		});

		selectableBrands.unshift({ label: t("Choose a brand"), value: 0 });

		selectableCampaigns = campaigns.map(campaign => {
			return {
				value: campaign.id,
				label: `${t("ID")}: ${campaign.id} - ${campaign.usecase} (${t("Updated")} ${moment(campaign.updated_at).format("MMM Do hh:mm a")})`
			};
		});

		selectableCampaigns.unshift({ label: t("Choose a campaign"), value: 0 });

		await this.update({
			selectableSubmissionTypes,
			selectablePreviousBrandSubmissions,
			selectableBrands,
			selectableCampaigns
		});
	};

	canSave = () => {
		let { config, submissionType, dhBrandId, dhCampaignId, dhPreviousReferenceSubmissionId } = this.state;

		if (submissionType === config.dhEnums.submissionTypes.brand && !dhBrandId) {
			return false;
		}

		if (submissionType === config.dhEnums.submissionTypes.campaign && (!dhCampaignId || !dhPreviousReferenceSubmissionId)) {
			return false;
		}

		return true;
	};

	onSave = async () => {
		let { submissionId, t } = this.props;
		let { config, submissionType, mock, dhBrandId, dhCampaignId, dhPreviousReferenceSubmissionId } = this.state;

		if (!this.canSave()) {
			return;
		}

		let submissionData = {
			submissionType,
			mock
		};

		if (submissionType === config.dhEnums.submissionTypes.brand) {
			submissionData.dhBrandId = dhBrandId;
		} else {
			submissionData.dhCampaignId = dhCampaignId;
			submissionData.dhPreviousReferenceSubmissionId = dhPreviousReferenceSubmissionId;
		}

		let locationId = UserService.getActiveLocation().id;

		let response = null;
		if (submissionId) {
			response = await A2PService.updateSubmission({ submissionId, submissionData });
		} else {
			response = await A2PService.createSubmission({ locationId, submissionData });
		}

		if (response) {
			ToastService.info(submissionId ? t("Submission updated.") : t("Successfully created."));
		} else {
			ToastService.error(t("Failed to save. Please try again."));
			return;
		}

		if (this.props.onSave) {
			await this.props.onSave();
		}
	};

	// For <input> changes
	onChangeInput = ({ fieldName, value }) => {
		this.update({
			[fieldName]: value
		});
	};

	onSwitchChange = fieldName => {
		this.update({
			[fieldName]: !this.state[fieldName]
		});
	};

	onHide = () => {
		if (this.props.onHide) {
			this.props.onHide();
		}
	};

	render = () => {
		let { show, submissionId, onHide, t } = this.props;
		let { loading, config } = this.state;
		let { selectableBrands, selectableCampaigns, selectablePreviousBrandSubmissions, selectableSubmissionTypes } = this.state;
		let { submissionType, mock, dhBrandId, dhCampaignId, dhPreviousReferenceSubmissionId } = this.state;

		if (loading || !config) {
			return (
				<Modal show={show} onHide={onHide} title={submissionId ? t("Update Submission") : t("Create Submission")}>
					<div className="a2p-modal">
						<ContentLoader height={650} width={"100%"}>
							{/* The tabs */}
							<rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />

							{/* Rows */}
							<rect x="0" y="70" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="150" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="230" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="310" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="390" rx="5" ry="5" width="100%" height="50" />
						</ContentLoader>
					</div>
				</Modal>
			);
		}

		// Find the usecase
		let brandLabel = selectableBrands.find(brand => brand.value === dhBrandId);
		brandLabel = typeof brandLabel === "undefined" ? t("Unknown Brand") : brandLabel.label;

		// Find the usecase
		let campaignLabel = selectableCampaigns.find(campaign => campaign.value === dhCampaignId);
		campaignLabel = typeof campaignLabel === "undefined" ? t("Unknown Campaign") : campaignLabel.label;

		// Find the usecase
		let previousBrandSubmissionLabel = selectablePreviousBrandSubmissions.find(
			previousSubmission => previousSubmission.value === dhPreviousReferenceSubmissionId
		);
		previousBrandSubmissionLabel =
			typeof previousBrandSubmissionLabel === "undefined" ? t("Unknown Previous Brand Submission") : previousBrandSubmissionLabel.label;

		// Find the usecase
		let submissionTypeLabel = selectableSubmissionTypes.find(curentSubmissionType => curentSubmissionType.value === submissionType);
		submissionTypeLabel = typeof submissionTypeLabel === "undefined" ? t("Unknown Submission Type") : submissionTypeLabel.label;

		// Is brand submission
		let isBrandSubmission = submissionType === config.dhEnums.submissionTypes.brand;
		let isCampaignSubmission = submissionType === config.dhEnums.submissionTypes.campaign;

		return (
			<Modal show={show} onHide={onHide} title={submissionId ? t("Update Submission") : t("Create Submission")}>
				<div style={{ width: "100%" }}>
					<DHSelect
						label={t("Submission Type")}
						name="submission_type"
						id="submission_type"
						onChange={option => {
							// Clear the fields
							this.update({ dhBrandId: 0, dhCampaignId: 0, dhPreviousReferenceSubmissionId: 0 });
							this.onChangeInput({ fieldName: "submissionType", value: option.value });
						}}
						value={{ label: submissionTypeLabel, value: submissionType }}
						options={selectableSubmissionTypes}
						required
					/>
					<Toggle id="mock" label={t("Mock")} checked={mock} onChange={() => this.onSwitchChange("mock")} />

					{isBrandSubmission && (
						<DHSelect
							label={t("DH Brand ID")}
							name="dh_brand_id"
							id="dh_brand_id"
							onChange={option => {
								this.onChangeInput({ fieldName: "dhBrandId", value: option.value });
							}}
							value={{ label: brandLabel, value: dhBrandId }}
							options={selectableBrands}
							required
						/>
					)}

					{isCampaignSubmission && (
						<>
							<div className="dh-details-item">
								<label>{t("Previous Brand Submission")}</label>
							</div>
							<Select
								id="previous_brand_submission_label"
								name="previous_brand_submission_label"
								options={selectablePreviousBrandSubmissions}
								value={{ label: previousBrandSubmissionLabel, value: dhPreviousReferenceSubmissionId }}
								placeholder={t("Previous Brand Submission Label")}
								onChange={option => {
									this.onChangeInput({ fieldName: "dhPreviousReferenceSubmissionId", value: option.value });
								}}
							/>

							<div className="dh-details-item">
								<label>{t("DH Campaign ID")}</label>
							</div>
							<Select
								id="dh_campaign_id"
								name="dh_campaign_id"
								options={selectableCampaigns}
								value={{ label: campaignLabel, value: dhCampaignId }}
								placeholder={t("Campaign")}
								onChange={option => {
									this.onChangeInput({ fieldName: "dhCampaignId", value: option.value });
								}}
							/>
						</>
					)}

					<div className="modal__actions">
						<div className={`mb-button mb-button--fit ${!this.canSave() ? "mb-button--disabled" : ""}`} onClick={this.onSave}>
							{t("Save")}
						</div>
					</div>
				</div>
			</Modal>
		);
	};
}

export default withTranslation(null, { withRef: true })(EditSubmissionModal);
