import React, { Component } from "react";

class DashboardCard extends Component {
	render() {
		const { className, height } = this.props;
		return <div className={`dashboard__card ${height ? `dashboard__card--h-${height}` : ""} ${className || ""}`}>{this.props.children}</div>;
	}
}

export default DashboardCard;
