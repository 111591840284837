export const CALENDAR_EVENT_TYPES = {
	calendarEvents: {
		value: "calendarEvents",
		label: "Calendar Events"
	},
	syncedAppointments: {
		value: "syncedAppointments",
		label: "CRM Appointments"
	},
	bookingRequests: {
		value: "bookingRequests",
		label: "Booking Requests"
	},
	external: {
		value: "external",
		label: "External"
	}
};

export const CALENDAR_TYPES = {
	generic: "generic"
};

export const CALENDAR_EVENT_MODAL_TABS = {
	general: {
		id: "general",
		value: "General"
	},
	guests: {
		id: "guests",
		value: "Guests"
	},
	schedule: {
		id: "schedule",
		value: "Schedule"
	},
	settings: {
		id: "settings",
		value: "Settings"
	}
};

export const CALENDAR_EVENT_ATTENDANCE_TYPES = {
	yes: {
		value: "yes",
		label: "Yes"
	},
	no: {
		value: "no",
		label: "No"
	},
	maybe: {
		value: "maybe",
		label: "Maybe"
	}
};
