import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import ReactSwitch from "react-switch";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import ToastService from "../../services/ToastService";
import CompanyService from "../../services/CompanyService";
import Spinners from "../../components/common/Spinners";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Alert from "../../components/common/Alert";

import "../../styles/css/scenes/companies.css";

class ManageCompany extends Component {
	constructor(props) {
		super(props);

		this.state = {
			company: null,
			companyName: "",
			reportSenderName: "DemandHub",
			reportSenderEmail: "support@demandhub.co",
			isReseller: false,
			saveSuccess: false,
			saveError: false,
			loadingFailed: false,
			loading: true,
			enableTeamchat: true,
			hasSharedWebsite: false
		};
	}

	handleCompanyNameChange = event => {
		this.setState({ companyName: event.target.value });
	};

	handleReportSenderNameChange = event => {
		this.setState({ reportSenderName: event.target.value });
	};

	handleReportSenderEmailChange = event => {
		this.setState({ reportSenderEmail: event.target.value });
	};

	handleSaveCompanyClick = async () => {
		let { t } = this.props;

		if (this.isSaveDisabled()) {
			return;
		}
		const companyId = this.props.match.params.companyId ? this.props.match.params.companyId : 0;
		this.update({ loading: true });

		if (companyId === "create") {
			try {
				const company = await CompanyService.createCompany({
					name: this.state.companyName,
					report_sender_name: this.state.reportSenderName,
					report_sender_email: this.state.reportSenderEmail,
					is_reseller: this.state.isReseller ? 1 : 0,
					enable_teamchat: this.state.enableTeamchat,
					has_shared_website: this.state.hasSharedWebsite
				});

				this.update({ saveSuccess: true, loading: false });
				ToastService.info(t("Company created!"));
			} catch (error) {
				console.log(error);
				this.update({ saveError: true, loading: false });
			}
			return;
		}

		let success = await CompanyService.updateCompany({
			companyId: companyId,
			name: this.state.companyName,
			report_sender_name: this.state.reportSenderName,
			report_sender_email: this.state.reportSenderEmail,
			is_reseller: this.state.isReseller ? 1 : 0,
			enable_teamchat: this.state.enableTeamchat,
			has_shared_website: this.state.hasSharedWebsite
		});

		if (success) {
			this.update({ saveSuccess: true, loading: false });
			ToastService.info(t("Company updated!"));
		} else {
			this.update({ saveError: true, loading: false });
		}
	};

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		const companyId = this.props.match.params.companyId;
		if (companyId === "create") {
			await this.update({ loading: false });
			return;
		}
		let company = await CompanyService.fetchCompany(companyId);

		if (!company) {
			await this.update({ loadingFailed: true, loading: false });
			return;
		}

		await this.update({
			company: company,
			companyName: company.name,
			reportSenderName: company.report_sender_name,
			reportSenderEmail: company.report_sender_email,
			isReseller: company.is_reseller,
			enableTeamchat: company.enable_teamchat,
			hasSharedWebsite: company.has_shared_website,
			loading: false
		});
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	isCreate() {
		const companyId = this.props.match.params.companyId;
		return companyId === "create";
	}

	isSaveDisabled = () => {
		let { companyName } = this.state;

		if (companyName && companyName.trim().length > 0) {
			return false;
		}

		return true;
	};

	renderForm() {
		let { companyName } = this.state;
		let { t } = this.props;
		const user = UserService.get();

		return (
			<div className="manage-company__form">
				<div className="manage-company__form__group">
					<div>
						{t("Name")} <span className="text-danger">*</span>
					</div>
					<div>
						<input name="company_name" id="company_name" type="text" onChange={this.handleCompanyNameChange} value={companyName} className="form-control" />
					</div>
				</div>
				<div className="manage-company__form__group">
					<div>{t("Report Sender Name")}</div>
					<div>
						<input
							name="report_sender_name"
							id="report_sender_name"
							type="text"
							onChange={this.handleReportSenderNameChange}
							value={this.state.reportSenderName}
							className="form-control"
						/>
					</div>
				</div>
				<div className="manage-company__form__group">
					<div>{t("Report Sender Email")}</div>
					<div>
						<input
							name="report_sender_email"
							id="report_sender_email"
							type="text"
							onChange={this.handleReportSenderEmailChange}
							value={this.state.reportSenderEmail}
							className="form-control"
						/>
					</div>
				</div>
				{UserService.isSuperAdminOrCustomerSuccess() && (
					<div className="manage-company__form__group">
						<div>{t("Is Reseller?")}</div>
						<div>
							<ReactSwitch
								id="is_reseller"
								onChange={() => this.setState({ isReseller: !this.state.isReseller })}
								checked={this.state.isReseller}
								uncheckedIcon={false}
								checkedIcon={false}
								onColor="#4A90E2"
								disabled={user.GroupPermission.update_resellers ? false : true}
							/>
						</div>
					</div>
				)}
				<div className="manage-company__form__group">
					<div>{t("Enable TeamChat")}</div>
					<div>
						<ReactSwitch
							id="team_chat_state"
							onChange={() => this.setState({ enableTeamchat: !this.state.enableTeamchat })}
							checked={this.state.enableTeamchat}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#4A90E2"
							disabled={user.GroupPermission.update_companies ? false : true}
						/>
					</div>
				</div>

				<div className="manage-company__form__group">
					<div>{t("Has Shared Websites")}</div>
					<div>
						<ReactSwitch
							id="has_shared_website"
							onChange={() => this.setState({ hasSharedWebsite: !this.state.hasSharedWebsite })}
							checked={this.state.hasSharedWebsite}
							uncheckedIcon={false}
							checkedIcon={false}
							onColor="#4A90E2"
							disabled={user.GroupPermission.update_companies ? false : true}
						/>
					</div>
				</div>

				<div className="hr-line-dashed" />
				<div>
					<div className="manage-company__form__buttons">
						<div id="save" className={`mb-button ${this.isSaveDisabled() ? "mb-button--disabled" : ""}`} onClick={this.handleSaveCompanyClick}>
							{t("Save")}
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const user = UserService.get();
		let { t } = this.props;

		if (this.isCreate() && !user.GroupPermission.create_companies) {
			return <Redirect to="/dashboard" />;
		}

		let { loading, saveSuccess, loadingFailed, saveError } = this.state;

		if (loading) {
			return (
				<Page>
					<Header title={t("User")} backUrl="/users" />
					<div className="Common__spinnerdiv--center">
						<Spinners loading={true} type="tail-fade" size="120px" />
					</div>
				</Page>
			);
		}
		if (saveSuccess) {
			return <Redirect to="/companies" />;
		}

		return (
			<Page>
				<Header title={t("Company")} backUrl="/companies" />
				{loadingFailed && (
					<div className="forum-post-container">
						<h2>
							{t("Sorry, no data was found")}{" "}
							<span role="img" aria-label="sad-face">
								😞
							</span>
						</h2>
						<Link to="/companies" className="btn btn-warning">
							{t("Go Back")}
						</Link>
					</div>
				)}
				{!loadingFailed && this.renderForm()}
				<Alert type="error" show={saveError} title={t("Saving Error")} confirm={t("OK")} onClose={() => this.setState({ saveError: false })}>
					<div>{t("Sorry, an error was encountered while saving this company. Please try again.")}</div>
				</Alert>
			</Page>
		);
	}
}

export default withTranslation(null, { withRef: true })(ManageCompany);
