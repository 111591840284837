import React, { Component } from "react";
import posed from "react-pose";
import { withTranslation } from "react-i18next";

import { KEYS } from "../../constants/Messenger";

import Templates from "../../components/common/Templates";

const Box = posed.div({
	visible: {
		y: -5,
		x: -350,
		opacity: 1
	},
	hidden: {
		y: 20,
		x: -350,
		opacity: 0
	}
});

class ReplyTemplatesButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showTemplates: false,
			editTemplatesModal: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		document.addEventListener("keydown", this.onKey);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKey);
	}

	onKey = e => {
		if (e.keyCode === KEYS.esc && this.state.showTemplates) {
			e.preventDefault();
			this.update({
				showTemplates: !this.state.showTemplates
			});
		}
	};

	handleTemplateMessageOnClick = template => {
		if (this.props.handleTemplateMessageOnClick) {
			this.props.handleTemplateMessageOnClick(template);
		}

		this.update({
			showTemplates: !this.state.showTemplates
		});
	};

	onTemplatesHide = () => {
		this.update({ showTemplates: false });
	};

	onTemplateSelected = template => {
		if (this.props.onSelect) {
			this.props.onSelect(template);
		}
	};

	onToggleEditTemplateModal = async isShowing => {
		await this.update({ editTemplatesModal: isShowing });
	};

	render() {
		const { showTemplates } = this.state;
		const { activeContactId, activeContactName, templateType, t } = this.props;

		if (activeContactId === 0) {
			return (
				<div className="dropup">
					<span
						className="mb-button fnctst-open-template-selector"
						onClick={() => {
							this.update({
								showTemplates: !this.state.showTemplates
							});
						}}
					>
						{t("Templates")}
					</span>
				</div>
			);
		}

		return (
			<div className="dropup">
				<span
					className="mb-button fnctst-open-template-selector"
					onClick={() => {
						this.update({
							showTemplates: !this.state.showTemplates
						});
					}}
				>
					{t("Templates")}
				</span>
				<Box className={`dh-mini-templates ${showTemplates ? "" : "dh-mini-templates--hidden"}`} pose={showTemplates ? "visible" : "hidden"}>
					{/* We now only fetch message templates once we want to show the templates */}
					{showTemplates && (
						<Templates
							show={showTemplates}
							templateType={templateType}
							onClose={this.onTemplatesHide}
							onSelect={this.handleTemplateMessageOnClick}
							contactId={activeContactId}
							replaceMessageVariables={true}
							showEditAction={true}
							typeEditable={false}
							overwriteReplacements={activeContactName ? { contactFull: activeContactName } : null}
							onToggleEditTemplateModal={this.onToggleEditTemplateModal}
						/>
					)}
				</Box>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(ReplyTemplatesButton);
