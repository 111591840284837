import React, { Component } from "react";
import * as Icon from "react-feather";
import posed, { PoseGroup } from "react-pose";
import { Picker } from "emoji-mart";

import { KEYS } from "../../../../constants/Messenger";

import "./emojis.css";
import "emoji-mart/css/emoji-mart.css";

const Box = posed.div({
	enter: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 200
		}
	},
	exit: {
		y: 10,
		x: 0,
		opacity: 0,
		transition: {
			duration: 100
		}
	}
});

class Emojis extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			leftPos: 0,
			topPos: 0,
			onSelectEmojiCallback: null
		};

		this.emojiBox = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		document.addEventListener("keydown", this.onKey);
		document.addEventListener("mousedown", this.onHandleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKey);
		document.removeEventListener("mousedown", this.onHandleClick, false);
	}

	onHandleClick = e => {
		if (this.emojiBox && this.emojiBox.contains(e.target)) {
			// the click is in the component
			return;
		}
		this.onClose();
	};

	async componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			await this.update({ show });
		}
	}

	onKey = e => {
		if (e.keyCode === KEYS.esc) {
			e.preventDefault();
			this.onClose();
		}
	};

	onClose = async () => {
		await this.update({ show: false });

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onSelect = async emoji => {
		let { onSelectEmojiCallback } = this.state;

		await this.update({ show: false });

		if (onSelectEmojiCallback) {
			onSelectEmojiCallback(emoji);
		} else if (this.props.onSelect) {
			this.props.onSelect(emoji.native);
		}
	};

	forceShow = async () => {
		await this.update({ show: true });
	};

	setPosition = async ({ left, top }) => {
		let update = {};
		if (typeof left !== "undefined") update.leftPos = left;
		if (typeof top !== "undefined") update.topPos = top;
		await this.update(update);
	};

	setReactionSelectCallback = async onSelectEmojiCallback => {
		await this.update({ onSelectEmojiCallback });
	};

	render() {
		let { show, leftPos, topPos } = this.state;

		let style = {};

		if (topPos || leftPos) {
			if (topPos) {
				style.top = `${topPos}px`;
			}
			if (leftPos) {
				style.left = `${leftPos}px`;
			}
		} else {
			style.bottom = "20px";
		}

		return (
			<PoseGroup>
				{show && (
					<Box key="container" ref={ref => (this.emojiBox = ref)} className="mb-emojis" style={style}>
						<div className="mb-emojis-x" onClick={this.onClose}>
							<Icon.X size="18" />
						</div>

						<Picker title="Select an emoji…" emoji="point_up" onSelect={this.onSelect} darkMode={false} />
					</Box>
				)}
			</PoseGroup>
		);
	}
}

export default Emojis;
