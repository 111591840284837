/**
 * Clears localStorage, sessionStorage or cookies based on the query params
 * if name is undefined or "all", clear everything
 */
import React from "react";
import { Redirect } from "react-router-dom";
import StringService from "../../services/StringService";
import PollingService from "../../services/PollingService";

export default props => {
	const { name } = StringService.parseQueryString(props.location.search);
	if (!name || name === "all") {
		clearSessionStorage();
		clearCookies();
		PollingService.clearAllListeners();
		return clearLocalStorage();
	}
	if (name === "localStorage") {
		PollingService.clearAllListeners();
		return clearLocalStorage();
	}
	if (name === "sessionStorage") {
		return clearSessionStorage();
	}
	if (name === "cookies") {
		return clearCookies();
	}
};
const clearLocalStorage = () => {
	localStorage.clear();
	return <Redirect to="/login" />;
};
const clearSessionStorage = () => {
	sessionStorage.clear();
};
const clearCookies = () => {
	var cookies = document.cookie.split(";");
	for (var i = 0; i < cookies.length; i++) {
		var cookie = cookies[i];
		var eqPos = cookie.indexOf("=");
		var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
	}
};
