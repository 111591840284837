import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { GoogleOAuthProvider, GoogleLogin, hasGrantedAllScopesGoogle } from "@react-oauth/google";
import { withTranslation } from "react-i18next";

import CompanyService from "../../services/CompanyService";
import UserService from "../../services/UserService";
import PollingService from "../../services/PollingService";
import SupportChatService from "../../services/SupportChatService";
import GAService from "../../services/GAService";
import UtilityService from "../../services/UtilityService";
import ToastService from "../../services/ToastService";

import Spinners from "../../components/common/Spinners";
import Alert from "../../components/common/Alert";
import PasswordRequirements from "../../components/common/PasswordRequirements";
import Input from "../../components/common/Input";

import { LOGIN_METHOD } from "../../constants/Users";
import config from "../../config/app/web-app.config";

import "../../styles/css/scenes/user-sign-up.css";

class UserSignUp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// User invite related data
			userInviteGuid: this.props.match.params.guid,
			userInvite: null,

			// Loading, errors, and redirecting
			goToLogin: false,
			error: null,
			knownError: null,
			loading: true,
			disableAll: false,

			// Form inputs
			email: "",
			firstName: "",
			lastName: "",
			phone: "",
			password: "",
			confirmPassword: "",

			// Account creation state flow
			loginMethod: null,
			accountCreated: false,
			showGoogleSignIn: true
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		document.body.classList.add("gray-bg");
		UtilityService.checkAndSetDarkBackground();
		this.fetchUserInvite();
	}

	componentWillUnmount() {
		document.body.classList.remove("gray-bg");
		document.body.classList.remove("Common-bg--dark");
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handleGenericEventHandler = event => {
		let { loading } = this.state;
		if (loading) {
			return;
		}
		this.update({ [event.target.name]: event.target.value });
	};

	fetchUserInvite = async () => {
		await this.update({ loading: true });

		let { userInviteGuid } = this.state;

		let userInvite = await CompanyService.getUserInvite(userInviteGuid);

		if (!userInvite) {
			await this.update({
				error: true,
				loading: false
			});
			return;
		}

		if (userInvite.error && userInvite.message) {
			await this.update({
				error: true,
				knownError: userInvite.message,
				loading: false,
				disableAll: true
			});
			return;
		}

		await this.update({
			userInvite,
			email: userInvite.email,
			phone: userInvite.phone,
			loading: false,
			error: null,
			knownError: null,
			disableAll: false
		});
	};

	createAccount = async () => {
		if (this.isCreateDisabled()) {
			return;
		}

		await this.update({
			loading: true
		});

		let { userInviteGuid, email, phone, firstName, lastName, password, userInvite, loginMethod } = this.state;
		let user = {
			guid: userInviteGuid,
			companyId: userInvite.company_id,
			groupId: userInvite.group_id,
			email,
			phone,
			firstName,
			lastName,
			password,
			loginMethod
		};

		// Create a new user
		let response = await CompanyService.createUser(user);

		if (!response) {
			await this.update({
				error: true,
				loading: false
			});
			return;
		}

		if (response.error && response.message) {
			await this.update({
				error: true,
				knownError: response.message,
				loading: false
			});
			return;
		}

		await this.update({
			loading: false,
			accountCreated: true
		});

		// When a user creates a new account, we will log out any other users on the same browser
		await this.logOut();

		// In 3 seconds redirect the user to the login page
		setTimeout(() => {
			this.update({ goToLogin: true });
		}, 3000);
	};

	logOut = async () => {
		UserService.clear();
		PollingService.clearAllListeners();
	};

	onGooglFailure = response => {
		let { t } = this.props;

		ToastService.error(t("Google Sign In Failed."));
	};

	onGoogleSignIn = async response => {
		try {
			let { email } = this.state;
			let { t } = this.props;

			let profile = jwt_decode(response.credential);

			let googleEmail = profile.email;

			if (!email || !googleEmail) {
				throw new Error(t("No Google email found."));
			}

			// Ensure that the User Invite email and the google email are the same.
			if (email.toLowerCase().trim() !== googleEmail.toLowerCase().trim()) {
				let errorMessage = t(`Google email does not match the invited email. The email address "{{email}}" was invited.`, { email: email });

				await this.update({ error: true, knownError: errorMessage });
				return;
			}

			await this.update({
				firstName: profile.given_name || "",
				lastName: profile.family_name || "",
				loginMethod: LOGIN_METHOD.google.id
			});
		} catch (error) {
			console.log(error);
			await this.update({ error: true, knownError: `Google sign in failed.` });
		}
	};

	isValidNumber() {
		let { phone } = this.state;
		if (phone.length === 0) {
			return true;
		}
		let reg = new RegExp("[0-9]{8,}");
		return reg.test(phone);
	}

	doesPasswordPassRequirements = () => {
		let { password } = this.state;

		return UtilityService.isValidPassword({ password });
	};

	isCreateDisabled() {
		let { userInvite, email, firstName, lastName, password, confirmPassword, loading, loginMethod, disableAll } = this.state;
		let isDisabled = true;

		if (disableAll) {
			return isDisabled;
		}

		if (loading) {
			return isDisabled;
		}

		// If any of the required fields are empty
		if (!userInvite || !email || !firstName || !lastName) {
			return isDisabled;
		}

		// If we are creating a regular email account
		if (loginMethod === LOGIN_METHOD.email.id) {
			// If the passwords is empty
			if (!password || !confirmPassword) {
				return isDisabled;
			}

			// If the password does not meet requirements
			if (!this.doesPasswordPassRequirements()) {
				return isDisabled;
			}
		}

		// If the passwords don't match
		if (password !== confirmPassword) {
			return isDisabled;
		}

		// If the phone number is not valid
		if (!this.isValidNumber()) {
			return isDisabled;
		}

		return false;
	}

	onOptionClick = loginMethod => {
		let { disableAll } = this.state;
		if (disableAll) {
			return;
		}
		this.update({ loginMethod });
	};

	renderForm() {
		let { loginMethod, email, firstName, lastName, phone, password, confirmPassword, userInvite } = this.state;
		let { t } = this.props;

		return (
			<>
				<div className="user-invite__header">
					<img className="user-invite__header__img" src="https://cdn.demandhub.co/img/logo/final-light-png.png" width="80%" alt="login-logo" />
					<h1 className="text-center">{t("Welcome")}</h1>
					<div className="user-invite__header__sub-header">
						<div className="user-invite__header__sub-header__text">{t("Finish setting up your account.")}</div>
					</div>
				</div>
				<div className="user-invite__email">{email}</div>
				<div className="user-invite__inputs">
					<Input
						id="firstName"
						name="firstName"
						type="text"
						label={t("First Name")}
						placeholder={t("First Name")}
						value={firstName}
						onChange={this.handleGenericEventHandler}
						required
					/>
					<Input
						id="lastName"
						name="lastName"
						type="text"
						label={t("Last Name")}
						placeholder={t("Last Name")}
						value={lastName}
						onChange={this.handleGenericEventHandler}
						required
					/>
					<Input
						id="phone"
						name="phone"
						type="text"
						label={
							<span>
								{t("Phone")}
								{!this.isValidNumber(phone) && <span className="dh__red-text">{t("Enter a valid number")}</span>}
							</span>
						}
						placeholder={t("Phone Number")}
						value={phone}
						onChange={this.handleGenericEventHandler}
					/>
					{loginMethod === LOGIN_METHOD.email.id && (
						<>
							<Input
								id="password"
								name="password"
								type="password"
								label={t("Password")}
								placeholder={t("Password")}
								value={password}
								onChange={this.handleGenericEventHandler}
								required
							/>
							<PasswordRequirements value={password} showRequirements={true} />
						</>
					)}
					{loginMethod === LOGIN_METHOD.email.id && (
						<Input
							id="confirmPassword"
							name="confirmPassword"
							type="password"
							label={
								<span>
									{t("Confirm Password")} <span className="input__label__required">*</span>
									{confirmPassword !== password && confirmPassword !== "" && <span className="dh__red-text">{t("Must match Password")}</span>}
								</span>
							}
							Confirm
							Password
							placeholder={t("Confirm Password")}
							value={confirmPassword}
							onChange={this.handleGenericEventHandler}
						/>
					)}
				</div>
				{userInvite && (
					<div className="user-invite__create-btn">
						<button
							className={`mb-button mb-button--slim ${this.isCreateDisabled() ? "mb-send--disabled" : ""}`}
							disabled={this.isCreateDisabled()}
							onClick={this.createAccount}
						>
							{t("Create Account")}
						</button>
					</div>
				)}
			</>
		);
	}

	renderLoading() {
		let { userInvite, accountCreated } = this.state;
		let { t } = this.props;

		let loadingHeader = t("Loading ...");

		if (accountCreated) {
			loadingHeader = t("Creating Account ...");
		} else if (!userInvite) {
			loadingHeader = t("Looking for your account ...");
		}

		return (
			<>
				<div className="user-invite__header">
					<img className="user-invite__header__img" src="https://cdn.demandhub.co/img/logo/final-light-png.png" width="80%" alt="login-logo" />
					<h1 className="text-center">{t("Welcome")}</h1>
					<div className="user-invite__header__sub-header">
						<div className="user-invite__header__sub-header__text">{loadingHeader}</div>
					</div>
				</div>
				<div className="user-invite__loading">
					<div className="Common__spinnerdiv--center">
						<Spinners loading={true} type="circle" size="5px" />
					</div>
				</div>
			</>
		);
	}

	renderCreateAccountWithOptions = () => {
		let { disableAll, showGoogleSignIn } = this.state;
		let { t } = this.props;

		return (
			<>
				<div className="user-invite__header">
					<img className="user-invite__header__img" src="https://cdn.demandhub.co/img/logo/final-light-png.png" width="80%" alt="login-logo" />
					<h1 className="text-center">{t("Welcome")}</h1>
					<div className="user-invite__header__sub-header">
						<div className="user-invite__header__sub-header__text">{t("You've been invited to join DemandHub!")}</div>
					</div>
				</div>
				<div className="user-invite__options">
					<button
						className={`user-invite__options__option user-invite__options__option--email mb-button mb-button--slim mb-button--square mb-button--full-width ${
							disableAll ? "mb-button--disabled" : ""
						}`}
						onClick={() => this.onOptionClick(LOGIN_METHOD.email.id)}
					>
						<i className="fa fa-envelope" />
						<span>{t("Continue with email")}</span>
					</button>

					{showGoogleSignIn && (
						<>
							<div className="user-invite__options__or">{t("or")}</div>
							<div className="user-invite__options__option">
								<GoogleOAuthProvider
									clientId={`${config.GOOGLE.GMB_API_CLIENT.CLIENT_ID}`}
									onScriptLoadError={error => {
										console.log(error);
										this.setState({ showGoogleSignIn: false });
									}}
								>
									<GoogleLogin
										onSuccess={this.onGoogleSignIn}
										onFailure={this.onGooglFailure}
										text="continue_with"
										context="signin"
										shape="rectangular"
										logo_alignment="center"
										width={228}
										cookiePolicy="single_host_origin"
									/>
								</GoogleOAuthProvider>
							</div>
						</>
					)}
				</div>
			</>
		);
	};

	renderBody() {
		let { loading, loginMethod, accountCreated } = this.state;
		let { t } = this.props;

		if (loading) {
			return this.renderLoading();
		}

		if (accountCreated) {
			return (
				<>
					<div className="user-invite__header user-invite__header--account-created">
						<img className="user-invite__header__img" src="https://cdn.demandhub.co/img/logo/final-light-png.png" width="80%" alt="login-logo" />
						<h1 className="text-center">{t("Account created!")}</h1>
						<div className="user-invite__header__sub-header">
							<div className="user-invite__header__sub-header__text">{t("You will be redirected to the login page.")}</div>
						</div>
					</div>
				</>
			);
		}

		if (!loginMethod) {
			return this.renderCreateAccountWithOptions();
		}

		return this.renderForm();
	}

	render() {
		let { goToLogin, error, knownError } = this.state;
		let { t } = this.props;

		if (goToLogin && !error) {
			return <Redirect to="/forcereset" />;
		}

		return (
			<div className="user-invite__container">
				<div className="user-invite Common__card animated fadeIn">{this.renderBody()}</div>
				<Alert type="error" show={error} title="" confirm={t("OK")} onClose={() => this.update({ error: false, knownError: "" })}>
					<div className="text-center">
						<p>
							{t("Sorry!")} {knownError ? knownError : t("Something went wrong.")}
						</p>
						<br />
						<p>
							{t("Please try again.")}
							<br />
							{t("Contact support@demandhub.co for assistance.")}
						</p>
					</div>
				</Alert>
			</div>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(UserSignUp));
