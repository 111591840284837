import React, { Component } from "react";
import { useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import FacebookProvider, { Login } from "react-facebook";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import LocationService from "../../services/LocationService";
import SupportChatService from "../../services/SupportChatService";
import ToastService from "../../services/ToastService";

import { PAGE_PERMISSIONS, LOGIN_RESPONSE_STATUS } from "../../constants/Facebook";
import { STATUS } from "../../constants/CommonConstants";

import Alert from "../../components/common/Alert";

import ConnectionsCard from "./ConnectionsCard";
import FacebookIntegration from "./FacebookIntegration";
import SelectGmbLocationsModal from "../../components/common/SelectGmbLocationsModal";
import withLocation from "../../components/common/WithLocation";

import config from "../../config/app/web-app.config";

import "../../styles/css/scenes/connections.css";

const GoogleLoginHandler = React.forwardRef(({ onSuccess, onError }, ref) => {
	// XXX -- Hacky way to get the login function
	// Since they require us to use hooks
	const login = useGoogleLogin({
		onSuccess,
		onError,
		flow: "auth-code",
		scope: "https://www.googleapis.com/auth/business.manage",
		enable_granular_consent: true
	});

	useEffect(() => {
		if (ref) {
			ref.current = login;
		}
	}, [login, ref]);

	// This component does not render anything
	return null;
});

class ConnectionsCoreServices extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// Core services
			googlePlaceId: null,

			// GMB basics
			isGmbActive: false,
			showGmbLocations: false,
			gmbLocations: [],

			// GMB Location selection
			gmbLocationSelectionSuccess: false,
			gmbLocationSelectionFailure: false,
			gmbLocationSelectionText: false,

			// Gmb Disconnect modal
			showDisconnectGoogleModal: false,

			// Ids
			fbMessengerIntegrationId: 0,
			gmbMessengerIntegrationId: 0,

			// Facebook
			isFbActive: false,
			fbNewConnectionStatus: "",
			fbPagesResponse: null
		};

		this.googleLoginRef = React.createRef();
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = location => {
		this.resetComponent();
	};

	resetComponent = async () => {
		await this.update({
			fbNewConnectionStatus: "",
			fbPagesResponse: null
		});

		await this.fetchLocationData();
		await this.fetchMessengerIntegration();
	};

	fetchLocationData = async () => {
		let location = await UserService.getActiveLocation();
		await this.update({ googlePlaceId: location.googlePlaceId });
	};

	fetchMessengerIntegration = async () => {
		let integrations = await LocationService.hasIntegrations(UserService.getActiveLocation().id);
		if (!integrations) {
			return;
		}

		await this.update({
			isFbActive: integrations.facebook,
			isGmbActive: integrations.gmb,
			fbMessengerIntegrationId: integrations.facebookIntegrationId,
			gmbMessengerIntegrationId: integrations.googleIntegrationId
		});
	};

	onSupportOpen = chatText => {
		SupportChatService.showNewMessage(chatText);
	};

	openGmbPage = () => {
		window.open("https://business.google.com", "_blank");
	};

	onGoogleSignInSuccess = async response => {
		ToastService.info("Successfully connected to Google My Business!");

		// Create a google integration
		let data = await LocationService.connectGMB(response);

		// All the locations that belong to the user that signed in
		let locations = await LocationService.getGMBLocations(data.id);

		await this.update({ gmbLocations: locations, showGmbLocations: true });
	};

	onGoogleSignInError = error => {
		console.log("Google sign in error:", error);
		ToastService.error("There was an error logging in to Google. Please try again");
	};

	handleFacebookLoginResponse = response => {
		// If there is a response from FacebookLogin an there is no status indication it means that the login and connection to the app is successful
		if (response && !response.status) {
			response.status = LOGIN_RESPONSE_STATUS.authorized;
			let locationId = UserService.getActiveLocation().id;
			// Once we know that the login and connection to our Facebook app is successful, fetch all the Facebook Pages the user owns
			LocationService.fetchFacebookPages({ facebookAccessToken: response.tokenDetail.accessToken, locationId: locationId }).then(pagesResponse => {
				// Along side the Facebook pages, we also retrieve a new long-living userAccessToken, that lasts up to 90 days
				this.update({
					fbPagesResponse: {
						longAccessToken: pagesResponse.longAccessToken,
						pages: pagesResponse.pages,
						enabledFbPages: pagesResponse.pages.filter(
							p => p.integrations && p.integrations.facebook.status === STATUS.active && p.integrations.facebook.location_id === locationId
						).length,
						loadingFbPages: pagesResponse.pages.filter(p => p.loading).length
					}
				});
			});
		}

		// Update the state with the new response and status
		this.update({
			fbNewConnectionStatus: response.status
		});
	};

	handleFacebookLoginError = response => {
		console.log(`Facebook login error: `, response);
		ToastService.error("There was an error logging in to Facebook. Please try again");

		this.update({
			fbNewConnectionStatus: LOGIN_RESPONSE_STATUS.notAuthorized
		});
	};

	onGMBLocationSelected = async location => {
		try {
			// Add The connect demandhub user to the specified location
			let response = await LocationService.addDHToGMBLocation(location);

			let connected = response.connected || false;

			if (response.message) {
				console.log(response.message);
			}

			// Did not connect
			if (!connected) {
				await this.update({
					gmbLocationSelectionFailure: true,
					gmbLocationSelectionSuccess: false,
					showGmbLocations: false
				});
				return;
			}

			// Connected ok
			await this.update({
				gmbLocationSelectionSuccess: true,
				gmbLocationSelectionFailure: false
			});

			await this.fetchMessengerIntegration();
		} catch (error) {
			console.log(error);
			await this.update({
				gmbLocationSelectionFailure: true
			});
		}

		await this.update({ showGmbLocations: false });
	};

	onClickGmbDisconnect = () => {
		this.update({ showDisconnectGoogleModal: true });
	};

	onGmbDisconnect = async confirm => {
		let { gmbMessengerIntegrationId } = this.state;

		if (!confirm) {
			await this.update({ showDisconnectGoogleModal: false });
			return;
		}

		if (!gmbMessengerIntegrationId) {
			return;
		}

		let success = await LocationService.updateMessengerIntegration({
			id: gmbMessengerIntegrationId,
			status: STATUS.deleted
		});

		if (!success) {
			ToastService.info(`Failed to disconnect Google My Business integration. Please try again.`);
			await this.update({ showDisconnectGoogleModal: false });
			return;
		}

		ToastService.info(`Disconnected Google My Business integration.`);

		await this.update({ showDisconnectGoogleModal: false });
		await this.resetComponent();
	};

	renderGmbConnection = () => {
		let { isGmbActive } = this.state;

		let user = UserService.get();
		let allowConnecting = user.GroupPermission.connect_locations;

		let gmbActionText = "";
		let gmbDescription = "";
		let gmbAction = () => {};

		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();

		// Only supers and cs see the disconnect button
		if (isGmbActive && isSuperOrCs) {
			gmbActionText = "Disconnect";
			gmbDescription = "Your GMB connection is online! Please maintain this status in order to fetch Google reviews and ratings!";
			gmbAction = this.onClickGmbDisconnect;
		} else if (isGmbActive) {
			gmbActionText = "View Details";
			gmbDescription = "Your GMB connection is online! Please maintain this status in order to fetch Google reviews and ratings!";
			gmbAction = this.openGmbPage;
		} else {
			gmbDescription = "Login to your GMB account and add connect@demandhub.co as a Manager of your business page.";

			// If we can connect, use the google button to do so
			if (allowConnecting) {
				gmbActionText = "Connect";
				gmbAction = () => {
					this.googleLoginRef.current();
				};
			} else {
				// If we can't connect, atleast give them the option to request help
				gmbActionText = "Request Help";
				gmbAction = () => {
					this.onSupportOpen("Hello! I'm interested in connecting my GMB account. Can you help me with that?");
				};
			}
		}

		return (
			<GoogleOAuthProvider
				clientId={`${config.GOOGLE.GMB_API_CLIENT.CLIENT_ID}`}
				onScriptLoadError={error => {
					console.log(error);
				}}
			>
				<GoogleLoginHandler ref={this.googleLoginRef} onSuccess={this.onGoogleSignInSuccess} onError={this.onGoogleSignInError} />
				<ConnectionsCard
					crmIntegrationId="gmb"
					title="Google My Business"
					description={gmbDescription}
					logoUrl={`${config.CDN_URL}img/logo/google-my-business-logo.svg`}
					status={isGmbActive}
					actionText={gmbActionText}
					onClick={gmbAction}
				/>
			</GoogleOAuthProvider>
		);
	};

	renderFbConnection = () => {
		let { isFbActive } = this.state;

		let user = UserService.get();
		let allowConnecting = user.GroupPermission.connect_locations;

		let fbActionText = "";
		let fbDescription = "";

		if (isFbActive) {
			fbDescription = "Your Facebook connection is online! Please maintain this status in order to fetch Facebook reviews and ratings!";
		} else {
			fbActionText = "Connect";
			fbDescription = "Login to your Facebook account and connect it with our app to fetch Facebook reviews and ratings!";
		}

		let canConnect = allowConnecting && !isFbActive;

		return (
			<FacebookProvider appId={config.FACEBOOK_APP_ID}>
				<Login
					onError={this.handleFacebookLoginError}
					onResponse={this.handleFacebookLoginResponse}
					scope={PAGE_PERMISSIONS.join(",")}
					render={({ isLoading, isWorking, onClick }) => (
						<ConnectionsCard
							crmIntegrationId="facebook"
							title="Facebook & Instagram"
							description={fbDescription}
							logoUrl={`${config.CDN_URL}web-app/assets/logos/facebook-logo.png`}
							status={isFbActive}
							actionText={fbActionText}
							onClick={canConnect ? onClick : null}
						/>
					)}
				/>
			</FacebookProvider>
		);
	};

	render() {
		let {
			showGmbLocations,
			gmbLocations,
			googlePlaceId,
			gmbLocationSelectionSuccess,
			gmbLocationSelectionFailure,
			gmbLocationSelectionText,
			fbPagesResponse,
			showDisconnectGoogleModal
		} = this.state;

		if (fbPagesResponse && fbNewConnectionStatus === LOGIN_RESPONSE_STATUS.authorized) {
			return (
				<div className="connections__section">
					<div className="connections__section-title">INSTAGRAM SETUP</div>
					<FacebookIntegration pagesResponse={fbPagesResponse} status={fbNewConnectionStatus} />
				</div>
			);
		}

		return (
			<>
				<div className="connections__section">
					<div className="connections__section-title">CORE SERVICES</div>
					<div className="connections__section-list">
						{this.renderGmbConnection()}
						{this.renderFbConnection()}
					</div>
				</div>
				<SelectGmbLocationsModal
					show={showGmbLocations}
					locations={gmbLocations}
					onHide={() => this.update({ showGmbLocations: false })}
					onLocationSelected={location => this.onGMBLocationSelected(location)}
					googlePlaceId={googlePlaceId}
				/>
				<Alert
					type="success"
					show={gmbLocationSelectionSuccess}
					title="Success"
					confirm="OK"
					onClose={() => {
						this.update({ gmbLocationSelectionSuccess: false, gmbLocationSelectionText: false });
					}}
				>
					<div>{gmbLocationSelectionText ? gmbLocationSelectionText : `The user connect@demandhub.co has connnected with your GMB location`}</div>
				</Alert>
				<Alert
					type="error"
					show={gmbLocationSelectionFailure}
					title="Error"
					confirm="OK"
					onClose={() => {
						this.update({ gmbLocationSelectionFailure: false, gmbLocationSelectionText: false });
					}}
				>
					<div>
						{gmbLocationSelectionText ? gmbLocationSelectionText : "There was an error trying to connect DemandHub to the selected location. Please try again."}
					</div>
				</Alert>
				<Alert type="info" show={showDisconnectGoogleModal} title={"Disconnect"} confirm={"Yes"} cancel={"No"} onClose={this.onGmbDisconnect}>
					<div>{`Are you sure you would like to disconnect Gmb?`}</div>
				</Alert>
			</>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(ConnectionsCoreServices)));
