import React, { Component } from "react";

import "../../styles/css/components/commons/filters.css";

class Filters extends Component {
	render() {
		let { filters } = this.props;
		let sections = Object.keys(filters);
		return sections.map(key => {
			let filter = filters[key];
			return (
				<div key={key} className="dh-filters">
					{filter.title && filter.title.length > 0 && <div className="dh-filters__title">{filter.title}</div>}

					<div className="dh-filters__items">
						{filter.items.map(item => (
							<div
								key={item.id}
								id={item.id}
								className={`dh-filters__item ${filter.isSelected && filter.isSelected(item.id) ? "dh-filters__items--selected" : ""}`}
								onClick={() => {
									if (filter.onClick) {
										filter.onClick(item);
									}
								}}
							>
								<span className="dh-filters__item--text">{item.value}</span>
								{item.badge && <span className="dh-filters__badge">{item.badge}</span>}
							</div>
						))}
					</div>
				</div>
			);
		});
	}
}

export default Filters;
