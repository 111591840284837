import React from "react";
import * as Icon from "react-feather";
import Dropzone from "react-dropzone";

import ToastService from "../../../services/ToastService";

import { MEDIA_EXTENSIONS } from "../../../constants/Messenger";

import "../../../styles/css/components/commons/drag-and-drop-overlay.css";

class DragAndDropOverlay extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isDraggingFile: false,
			isMultiple: false
		};

		this.dropZone = React.createRef();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onClickOpen = () => {
		if (this.dropZone && this.dropZone.current) {
			this.dropZone.current.open();
		}
	};

	onDrop = async files => {
		await this.update({ isDraggingFile: false });

		if (this.props.onFileAdded) {
			this.props.onFileAdded(files);
		}
	};

	onDragEnter = async () => {
		await this.update({ isDraggingFile: true });
	};

	onDragLeave = async () => {
		await this.update({ isDraggingFile: false });
	};

	onDropRejected = async event => {
		let { accept } = this.props;

		if (!accept) {
			ToastService.error("Unexpected file format.");
			return;
		}

		let extensions = {};

		for (const mimeType in accept) {
			let ext = MEDIA_EXTENSIONS.mimeType[mimeType];
			if (ext) {
				extensions[ext] = ext;
			}
		}

		extensions = Object.values(extensions);

		if (extensions.length < 1) {
			ToastService.error("Unexpected file format.");
			return;
		}

		ToastService.error(`Expecting ${extensions} file type${extensions.length > 1 ? "s" : ""}`);
	};

	render = () => {
		let { isDraggingFile } = this.state;
		let { accept, className } = this.props;
		let hasChildren = this.props.children;

		return (
			<Dropzone
				ref={this.dropZone}
				onDrop={this.onDrop}
				onDragEnter={this.onDragEnter}
				onDragLeave={this.onDragLeave}
				onDropRejected={this.onDropRejected}
				noClick={true}
				accept={accept}
			>
				{({ getRootProps, getInputProps, acceptedFiles }) => (
					<div
						{...getRootProps({
							className: `dnd ${className || ""} ${isDraggingFile ? "dnd--is-dragging" : ""}`,
							onDrop: event => event.preventDefault(),
							onClick: event => event.preventDefault()
						})}
					>
						{isDraggingFile && (
							<div className="dnd__overlay">
								<div className="dnd__overlay__text">
									<span className="dnd__overlay__text__icon">
										<Icon.Paperclip size={14} />
									</span>
									Add File Attachment
								</div>
							</div>
						)}
						<input {...getInputProps({ className: "dnd__overlay__file-input" })}></input>
						{hasChildren && this.props.children}
						{!hasChildren && (
							<div className="dnd__drop-area" onClick={this.onClickOpen}>
								{!isDraggingFile && (
									<div>
										Drag & drop a file or <span className="dnd__drop-area__click-here">click here.</span>
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</Dropzone>
		);
	};
}

export default DragAndDropOverlay;
