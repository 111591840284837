import React, { Component } from "react";
import Select from "react-select";

import DHModal from "../../components/common/DHModal";
import DHSelect from "../../components/common/DHSelect";
import Input from "../../components/common/Input";
import Alert from "../../components/common/Alert";

import ToastService from "../../services/ToastService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";
import UserService from "../../services/UserService";

import { CRM_INTEGRATIONS } from "../../constants/CRMIntegration";
import { STATUS } from "../../constants/CommonConstants";

import "../../styles/css/scenes/connections.css";

class ConnectBookerModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			selectedIntegration: null,
			selectableIntegrations: [],
			integration: null,

			tenantId: "",
			personalAccessToken: "",

			lastTestStatus: false,
			showAlertIntegrationNotFound: false,
			showAlertTestSuccess: false,
			showAlertTestFailed: false,
			showAlertConnectionComplete: false,
			showDisconnectModal: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { show } = this.props;

		// If we are not showing the dialog, don't fetch anything
		if (!show) {
			return;
		}

		await this.fetchIntegrations();
		await this.onIntegrationSelected();
	}

	onClickDisconnect = () => {
		this.update({ showDisconnectModal: true });
	};

	disconnectIntegration = async confirm => {
		if (!confirm) {
			await this.update({ showDisconnectModal: false });
			return;
		}

		if (this.props.disconnectIntegration) {
			this.props.disconnectIntegration();
		}

		await this.update({ showDisconnectModal: false });
	};

	fetchIntegrations = async () => {
		let integrations = await CrmIntegrationsService.fetch(UserService.getActiveLocation().id, {
			type: CRM_INTEGRATIONS.type.booker.name,
			status: [STATUS.active]
		});

		if (!integrations) {
			this.update({
				selectableIntegrations: []
			});
			return;
		}
		let selectableIntegrations = integrations.map(i => {
			return { value: i.id, label: i.name ? i.name : CRM_INTEGRATIONS[i.crm_type].displayName };
		});

		this.update({
			selectableIntegrations
		});
	};

	onIntegrationSelected = async integrationOption => {
		let integrationId = null;

		if (integrationOption) {
			integrationId = integrationOption.value;
		}

		if (!integrationId) {
			// Default to the passed in integration id
			integrationId = this.props.integrationId;
		}

		// Set loading to true, and reset dirty bit
		await this.update({
			loading: true,
			integration: null
		});

		try {
			// Fetch the integration object from the backend, we do this fetch to get the integration credentials.
			let integration = await CrmIntegrationsService.fetchRestIntegration(integrationId);

			if (!integration || integration.status === STATUS.deleted) {
				// Show integration not found dialog
				await this.update({
					showAlertIntegrationNotFound: true
				});

				return;
			}

			let tenantId = integration.meta_data.tenantId ? integration.meta_data.tenantId : "";
			let personalAccessToken = integration.meta_data.personalAccessToken ? integration.meta_data.personalAccessToken : "";

			// Save this integration in our state
			await this.update({
				integration,
				tenantId,
				personalAccessToken,
				selectedIntegration: { value: integration.id, label: integration.name ? integration.name : CRM_INTEGRATIONS[integration.crm_type].displayName }
			});
		} catch (error) {
			console.log(error.message);
		}
		await this.update({ loading: false });
	};

	onTenantIdChange = async event => {
		await this.update({ tenantId: event.target.value });
	};

	onPersonalAccessTokenChange = async event => {
		await this.update({ personalAccessToken: event.target.value });
	};

	onTestConnection = async event => {
		let { selectedIntegration } = this.state;

		await this.update({ loading: true });

		let testResponse = await CrmIntegrationsService.testRestIntegration({
			integrationId: selectedIntegration.value,
			tenantId: this.state.tenantId,
			accessToken: this.state.personalAccessToken
		});

		if (testResponse.status) {
			ToastService.info(`Connection Successful!`);
			// If we get here, show the success message
			await this.update({ lastTestStatus: testResponse.status, showAlertConnectionComplete: true });

			return;
		}

		await this.update({ lastTestStatus: testResponse.status, loading: false, showAlertTestFailed: !testResponse.status });
	};

	onConfirmTestSuccess = async event => {
		await this.update({ showAlertTestSuccess: false });
	};

	onConfirmTestFailed = async event => {
		await this.update({ showAlertTestFailed: false });
	};

	onCancel = async event => {
		let { onHide } = this.props;

		await this.update({ loading: false });

		if (onHide) {
			onHide();
		}
	};

	onConfirmConnectionComplete = async event => {
		let { onHide } = this.props;

		// If we get here, we're all done
		await this.update({ loading: false, showAlertConnectionComplete: false });

		if (onHide) {
			onHide();
		}
	};

	render() {
		let { show, integrationId } = this.props;

		let {
			tenantId,
			personalAccessToken,
			showAlertTestSuccess,
			showAlertTestFailed,
			showAlertIntegrationNotFound,
			showAlertConnectionComplete,
			showDisconnectModal,

			selectedIntegration,
			selectableIntegrations
		} = this.state;

		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();
		let showDisconnectButton = integrationId && isSuperOrCs;

		return (
			<>
				<DHModal title="Connect to Booker" show={show} onHide={this.onCancel}>
					<>
						{selectableIntegrations.length > 1 && selectedIntegration && (
							<div className="Connections-modal__select">
								<div>Integration</div>
								<Select
									id={`selectable-integrations`}
									options={selectableIntegrations}
									value={selectedIntegration}
									onChange={this.onIntegrationSelected}
									placeholder={`${CRM_INTEGRATIONS.type.booker.displayName} Integration`}
								/>
							</div>
						)}

						<div className="modal__flex-container">
							<div className="Connections-modal__input">
								<Input label="Tenant ID" name="booker-tenant-id" id="booker-tenant-id" type="text" onChange={this.onTenantIdChange} value={tenantId} required />
							</div>

							<div className="Connections-modal__input">
								<Input
									label="Booker Personal Access Token"
									name="booker-password"
									id="booker-password"
									type="password"
									onChange={this.onPersonalAccessTokenChange}
									value={personalAccessToken}
									required
								/>
							</div>
						</div>

						<div className="modal__actions">
							<button
								id="booker-cancel"
								className={`mb-button mb-button--cancel ${this.state.loading ? "mb-button--disabled" : ""}`}
								disabled={this.state.loading}
								onClick={this.onCancel}
							>
								Cancel
							</button>

							{showDisconnectButton && (
								<button
									id="booker-disconnect"
									className={`mb-button mb-button--cancel ${this.state.loading ? "mb-button--disabled" : ""}`}
									disabled={this.state.loading}
									onClick={this.onClickDisconnect}
								>
									Disconnect
								</button>
							)}

							<button
								id="booker-test-connection"
								className={`mb-button ${this.state.loading ? "mb-button--disabled" : ""}`}
								disabled={this.state.loading}
								onClick={this.onTestConnection}
							>
								Test Connection
							</button>
						</div>
					</>
				</DHModal>

				<Alert type="success" show={showAlertTestSuccess} title="Connection Successful" confirm="OK" onClose={this.onConfirmTestSuccess}>
					The connection was successful! Please choose the location.
				</Alert>
				<Alert type="error" show={showAlertTestFailed} title="Connection Failed" confirm="OK" onClose={this.onConfirmTestFailed}>
					The connection failed. Please verify credentials and try again.
				</Alert>
				<Alert type="error" show={showAlertIntegrationNotFound} title="Not Found" confirm="OK" onClose={this.onConfirmIntegrationNotFound}>
					The Booker integration was not found.
				</Alert>
				<Alert type="success" show={showAlertConnectionComplete} title="Connection Complete" confirm="OK" onClose={this.onConfirmConnectionComplete}>
					The Booker connection is all set up.
				</Alert>
				<Alert type="info" show={showDisconnectModal} title={"Disconnect"} confirm={"Yes"} cancel={"No"} onClose={this.disconnectIntegration}>
					<div>{`Are you sure you would like to disconnect the integration?`}</div>
				</Alert>
			</>
		);
	}
}
export default ConnectBookerModal;
