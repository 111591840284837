import UserService from "./UserService";
import Kichiri from "./KichiriService";

const ReviewService = {
	/**
	 * Fetch review data
	 * @param {Number} locationId
	 * @param {Number} minRating
	 * @param {Number} maxRating
	 * @param {Number} limit
	 * @param {Number} offset
	 * @param {Number} requesterId
	 * @param {Number} reviewSite
	 * @param {Number} range
	 * @param {Number} needsResponse
	 *
	 * @returns {Promise<Array[Object]>}
	 */
	async fetch({ locationId, minRating, maxRating, limit, offset, requesterId, reviewSite, range, needsResponse }) {
		try {
			let { data } = await Kichiri.review.fetch(
				{},
				{
					location: locationId,
					minRating,
					maxRating,
					limit,
					offset,
					requesterId,
					reviewSite,
					range,
					needsResponse: needsResponse ? 1 : 0
				},
				UserService.getAuthToken()
			);

			return data;
		} catch (error) {
			console.log(error);
		}
		return [];
	},

	/**
	 * Fetch review data
	 * @param {Number} locationId
	 * @param {Number} minRating
	 * @param {Number} maxRating
	 * @param {Number} requesterId
	 * @param {Number} reviewSite
	 * @param {Number} range
	 * @param {Number} needsResponse
	 *
	 * @returns {Promise<Array[Object]>}
	 */
	async count({ locationId, minRating, maxRating, requesterId, reviewSite, range, needsResponse }) {
		try {
			let response = await Kichiri.review.count(
				{},
				{
					location: locationId,
					minRating,
					maxRating,
					requesterId,
					reviewSite,
					range,
					needsResponse: needsResponse ? 1 : 0
				},
				UserService.getAuthToken()
			);

			return response.data.total;
		} catch (error) {
			console.log(error);
		}
		return 0;
	},

	/**
	 * Update a review
	 * @param {Integer} reviewId
	 * @param {Boolean} showOnWidgets
	 */
	async updateReview({ reviewId, showOnWidgets }) {
		try {
			let response = await Kichiri.review.update({ reviewId, showOnWidgets }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Generate a ai reply suggestion for a review
	 *
	 * @param {Number} reviewId
	 */
	async generateAiReplySuggestion({ reviewId }) {
		try {
			let locationId = UserService.getActiveLocation().id;
			let response = await Kichiri.review.generateAiReplySuggestion({ reviewId }, { locationId }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get Reviews By Ids
	 * @param {Array} reviewIds
	 * @param {Integer} locationId
	 *
	 * @returns {Array}
	 */
	async fetchReviewsByIds({ reviewIds, locationId }) {
		try {
			const reviews = await Kichiri.review.fetchReviewsByIds({}, { review_ids: reviewIds, location_id: locationId }, UserService.getAuthToken());

			return reviews.data;
		} catch (error) {
			console.log(error);
			return null;
		}
	},

	/**
	 * Get all review sites
	 * @returns {Array}
	 */
	async getAllReviewSites() {
		try {
			const response = await Kichiri.review_sites.getAllReviewSites({}, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get Bulk Invite limit counts
	 * @param {Integer} locationId
	 * @returns {Object}
	 */
	async getBulkInviteCount(locationId) {
		try {
			const response = await Kichiri.review_request.getBulkInviteCount({}, { location: locationId }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Set the request of a review
	 * @param {Integer} reviewId
	 * @param {Integer} requesterId
	 * @returns {Boolean}
	 */
	async setRequester({ reviewId, requesterId }) {
		try {
			await Kichiri.review.setRequester(
				{
					reviewId: reviewId,
					requester: requesterId
				},
				{},
				UserService.getAuthToken()
			);

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Propagate a review from on site to another
	 * @param {Integer} reviewId
	 * @param {String} review
	 * @param {Integer} locationId
	 * @param {Integer} shareToSiteId
	 *
	 * @returns {Boolean}
	 */
	async propagateReview({ reviewId, review, locationId, shareToSiteId }) {
		try {
			await Kichiri.review.propagateReview({ reviewId, review, locationId, shareToSiteId }, {}, UserService.getAuthToken());

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Set a review response
	 * @param {Integer} reviewId
	 * @param {String} repliedBy
	 * @param {String} reply
	 * @param {Integer} locationId
	 *
	 * @returns {Boolean}
	 */
	async setResponse({ reviewId, repliedBy, reply, locationId }) {
		try {
			await Kichiri.review.setResponse({ reviewId, repliedBy, reply, locationId }, {}, UserService.getAuthToken());

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Delete a review response
	 * @param {Integer} reviewId
	 * @param {Integer} locationId
	 *
	 * @returns {Boolean}
	 */
	async deleteResponse({ reviewId, locationId }) {
		try {
			await Kichiri.review.deleteResponse({ reviewId, locationId }, {}, UserService.getAuthToken());

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get the review sites that a location is not using
	 * @param {Integer} locationId
	 *
	 * @returns {Arrray}
	 */
	async getReviewSitesNotInLocation({ locationId }) {
		try {
			let response = await Kichiri.review_sites.getReviewSitesNotInLocation(
				{
					locationId
				},
				{},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update review request notes
	 * @param {Integer} locationId
	 * @param {Array} notes

	 * @returns {Array}
	 */
	async updateReviewRequestNotes({ reviewRequestId, notes }) {
		try {
			await Kichiri.review_request.updateNotes(
				{
					reviewRequestId,
					notes
				},
				{},
				UserService.getAuthToken()
			);

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create a review site
	 * @param {Object} reviewSite
	 *
	 * @returns {Boolean}
	 */
	async createReviewSite(reviewSite) {
		try {
			await Kichiri.review_sites.create(reviewSite, {}, UserService.getAuthToken());

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	}
};

export default ReviewService;
