import React, { Component } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";

import PaymentService from "../../../services/PaymentService";
import UserService from "../../../services/UserService";
import ToastService from "../../../services/ToastService";

import Alert from "../../../components/common/Alert";
import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";

import withLocation from "../../../components/common/WithLocation";

import { INVOICE_STATUS, PAYMENT_STATUS, PAYMENT_TYPES } from "../../../constants/Payments";

import "./invoice-details.css";

class InvoiceDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			invoice: null,
			alertMessage: "",
			showConfirmAlert: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onLocationChanged = async location => {
		await this.update({ loading: true });
		await this.fetchInvoice();
		await this.update({ loading: false });
	};

	async componentDidMount() {
		await this.update({ loading: true });
		await this.fetchInvoice();
		await this.update({ loading: false });
	}

	async fetchInvoice() {
		let invoiceId = this.props.match.params.invoiceId;

		let invoice = await PaymentService.fetchInvoice(invoiceId);

		await this.update({ invoice });
	}

	onRequestPayment = async () => {
		let { invoice } = this.state;
		let { t } = this.props;

		await this.update({
			onConfirm: async confirmed => {
				await this.update({
					showConfirmAlert: false
				});

				if (!confirmed) {
					return;
				}

				let paymentRequest = await PaymentService.createPaymentRequest({ invoiceId: invoice.id, contactId: invoice.contact_id });

				if (!paymentRequest) {
					ToastService.error(t("An error occurred trying to request payment."));
					return;
				}

				await this.fetchInvoice();

				ToastService.info(t("Payment request created successfully."));
			},
			alertMessage: t("Are you sure you want to request a payment for this invoice?"),
			showConfirmAlert: true
		});
	};

	onViewPaymentRequest = async pr => {
		this.props.history.push(`/payments/request/${pr.id}`);
	};

	renderDetails() {
		let { invoice } = this.state;
		let { t } = this.props;

		return (
			<div className="prd-details">
				<div className="prd-details-info">
					<div className="prd-details-title">{t("Details")}</div>
					<div className="prd-details-item">
						<label>{t("Status")}</label>
						<div>{INVOICE_STATUS[invoice.status].display}</div>
					</div>
					<div className="prd-details-item">
						<label>{t("Invoice ID")}</label>
						<div>{invoice.crm_invoice_id || invoice.id}</div>
					</div>
					<div className="prd-details-item">
						<label>{t("Invoice Date")}</label>
						<div>{moment(invoice.invoice_date).format("MMM Do, YYYY")}</div>
					</div>
					<div className="prd-details-item">
						<label>{t("Amount")}</label>
						<div>{PaymentService.formatCharge(invoice.total_amount)}</div>
					</div>
					<div className="prd-details-item">
						<label>{t("Paid Amount")}</label>
						<div>{PaymentService.formatCharge(invoice.customer_paid_amount)}</div>
					</div>
					<div className="prd-details-item">
						<label>{t("Insurance Amount")}</label>
						<div>{PaymentService.formatCharge(invoice.insurance_amount)}</div>
					</div>
					<div className="prd-details-item">
						<label>{t("Name")}</label>
						<div>{invoice.Contact.name}</div>
					</div>
					<div className="prd-details-item">
						<label>{t("Contact")}</label>
						<div>{invoice.Contact.email || invoice.Contact.phone}</div>
					</div>
				</div>
			</div>
		);
	}

	renderHeader() {
		let { t } = this.props;

		return (
			<div className="prd-invoice-items__list__record prd-invoice-items__list__record--header">
				<div className="prd-invoice-items__list__record__name">{t("Name")}</div>
				<div className="prd-invoice-items__list__record__amount">{t("Amount")}</div>
			</div>
		);
	}

	renderRecord(item, index) {
		return (
			<div key={index} className={`prd-invoice-items__list__record ${index % 2 === 0 ? "prd-invoice-items__list__record--odd" : ""}`}>
				<div className="prd-invoice-items__list__record__name">{item.name}</div>
				<div className="prd-invoice-items__list__record__amount">{PaymentService.formatCharge(item.amount)}</div>
			</div>
		);
	}

	renderItems() {
		let { invoice } = this.state;

		let lineItems = PaymentService.generateDisplayLineItems(invoice);

		return (
			<>
				{this.renderHeader()}
				<div className="prd-invoice-items__list">{lineItems.map((item, index) => this.renderRecord(item, index))}</div>
			</>
		);
	}

	renderActions() {
		let { invoice } = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let allowUpdate = user.GroupPermission.update_payments;

		return (
			<div className="prd-actions">
				{(invoice.status === INVOICE_STATUS.not_paid.id || invoice.status === INVOICE_STATUS.partially_paid.id) && allowUpdate && (
					<div className="prd-action-button" onClick={this.onRequestPayment}>
						{t("Request Payment")}
					</div>
				)}
			</div>
		);
	}

	renderInvoiceItems() {
		let { t } = this.props;

		return (
			<div className="prd-invoice-items">
				<div className="prd-invoice-items__title">{t("Invoice Items")}</div>
				{this.renderItems()}
				{this.renderActions()}
			</div>
		);
	}

	renderPaymentRequests() {
		let { invoice } = this.state;
		let { t } = this.props;

		if (invoice.payment_requests.length > 0) {
			return (
				<div className="prd-invoice-payment-requests">
					<div className="prd-invoice-payment-requests__title">{t("Payment Requests and Estimates")}</div>

					<div className="prd-invoice-payment-requests__list">
						{invoice.payment_requests.map((item, index) => this.renderPaymentRequestRecord(item, index))}
					</div>
				</div>
			);
		}

		return null;
	}

	renderPaymentRequestRecord(pr, index) {
		return (
			<div
				key={`invoice-pr-${index}`}
				className={`prd-invoice-payment-requests__list__record ${index % 2 === 0 ? "prd-invoice-payment-requests__list__record--odd" : ""}`}
			>
				<div className="prd-invoice-payment-requests__list__record__name"></div>
				<div className="prd-invoice-payment-requests__list__record__amount">
					<div className="prd-action-button" onClick={() => this.onViewPaymentRequest(pr)}>
						{moment(pr.created_at).format("MMM Do, YYYY") + " - " + PAYMENT_TYPES[pr.type].display + " - " + PAYMENT_STATUS[pr.status].display}
					</div>
				</div>
			</div>
		);
	}

	render() {
		let { invoice, loading, showConfirmAlert, onConfirm, alertMessage } = this.state;
		let { t } = this.props;

		if (loading || !invoice) {
			return null;
		}

		return (
			<Page>
				<Header title={t("Invoice Details")} onBackButtonClick={() => this.props.history.goBack()}></Header>
				<div className="prd-content">
					{this.renderDetails()}
					<div className="prd-column">
						{this.renderInvoiceItems()}
						{this.renderPaymentRequests()}
					</div>
				</div>
				<Alert type="warning" show={showConfirmAlert} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")} onClose={onConfirm}>
					<div>{alertMessage}</div>
				</Alert>
			</Page>
		);
	}
}

export default withLocation(withTranslation(null, { withRef: true })(InvoiceDetails));
