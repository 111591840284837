import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ToastService from "../../services/ToastService";
import ContactService from "../../services/ContactService";
import MessagesService from "../../services/MessagesService";

import Modal from "../../components/common/DHModal";
import TextArea from "../../components/common/TextArea";

import "../../styles/css/scenes/update-notes-modal.css";

class UpdateContactNotesModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			noteText: "",
			loading: false
		};
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	resetComponent = async () => {
		let { contactId, noteId, show } = this.props;

		await this.update({ noteText: "" });

		if (show && contactId && noteId) {
			await this.fetchNote();
		}
	};

	async componentDidUpdate(prevProps) {
		if (this.props.show && prevProps.show !== this.props.show) {
			await this.resetComponent();
		}
	}

	fetchNote = async () => {
		let { contactId, noteId } = this.props;

		await this.update({ loading: true });

		let note = await ContactService.fetchContactNote({ contactId, noteId });

		await this.update({ noteText: MessagesService.formatNote(note.content) });

		await this.update({ loading: false });
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onSubmit = async () => {
		let { contactId, createMode, noteId, t } = this.props;
		let { noteText } = this.state;

		if (createMode) {
			let response = await ContactService.addContactNote({ contactId, note: noteText });
			if (!response) {
				ToastService.info(t("An error occured creating a contact note."));
				return;
			}
		} else {
			let response = await ContactService.updateContactNote({ contactId, noteId, content: noteText });
			if (!response) {
				ToastService.info(t("An error occured updating a contact note."));
				return;
			}
		}

		if (this.props.onSubmit) {
			this.props.onSubmit();
		}
	};

	handleNoteModalInputChange = async event => {
		await this.update({ noteText: event.target.value });
	};

	onHide = () => {
		if (this.props.onHide) {
			this.props.onHide();
		}
	};

	renderTitle = () => {
		const { createMode, t } = this.props;
		return createMode ? t("Create a note") : t("Update a note");
	};

	render() {
		let { show, createMode, t } = this.props;
		let { noteText, loading } = this.state;

		if (loading) {
			return null;
		}

		return (
			<Modal show={show} title={this.renderTitle()} onHide={this.onHide}>
				<div className="modal__flex-container">
					<div className="unm__textarea">
						<TextArea
							id="note-modal-input"
							name="note-modal-input"
							type="text"
							height={130}
							rows={4}
							style={{ resize: "none" }}
							value={noteText}
							showFeedbackFaces={false}
							showFeedbackLength={false}
							showVariables={false}
							blueBorder={true}
							placeholder={t("Type your new note here...")}
							onChange={this.handleNoteModalInputChange}
						/>
					</div>
					<div className="mb-button" onClick={this.onSubmit}>
						{createMode ? t("Create") : t("Update")}
					</div>
				</div>
			</Modal>
		);
	}
}
export default withTranslation(null, { withRef: true })(UpdateContactNotesModal);
