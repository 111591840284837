import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import withLocation from "../../components/common/WithLocation";
import Tabs from "../../components/common/Tabs";
import Tab from "../../components/common/Tab";

import ManageUser from "./ManageUser";
import UserSessions from "./UserSessions";

import { GA_CATEGORIES, GA_ACTIONS } from "../../constants/GAConstants";
import { MANAGE_USER_TABS } from "../../constants/Users";

import GAService from "../../services/GAService";
import UserService from "../../services/UserService";

class ManageUsersOverview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: MANAGE_USER_TABS.user.id
		};
	}

	async componentDidMount() {}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onTabSelect = async tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.manageUser.name,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		await this.update({ selectedTab: tab.id });
	};

	isCreate = () => {
		const userId = this.props.match.params.userId;
		return userId === "create";
	};

	render() {
		let { selectedTab } = this.state;
		let { t } = this.props;
		let isSuperAdminOrCustomerSuccess = UserService.isSuperAdminOrCustomerSuccess();

		return (
			<Page>
				<Header title={t("Users")} backUrl="/users" />
				{isSuperAdminOrCustomerSuccess && !this.isCreate() && (
					<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
						<Tab id={MANAGE_USER_TABS.user.id} value={MANAGE_USER_TABS.user.value} />
						<Tab id={MANAGE_USER_TABS.sessions.id} value={MANAGE_USER_TABS.sessions.value} />
					</Tabs>
				)}
				{selectedTab === MANAGE_USER_TABS.user.id && <ManageUser {...this.props} />}
				{isSuperAdminOrCustomerSuccess && selectedTab === MANAGE_USER_TABS.sessions.id && <UserSessions {...this.props} />}
			</Page>
		);
	}
}

export default withLocation(withTranslation(null, { withRef: true })(ManageUsersOverview));
