import React from "react";
import * as Icon from "react-feather";
import { Redirect, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import FirewallService from "../../../services/FirewallService";
import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";

import Header from "../../../components/common/Header";
import List from "../../../components/common/List";
import Page from "../../../components/common/Page";
import EditRuleModal from "./EditRuleModal";
import Action from "../../../components/common/Action";
import Filters from "../../../components/common/Filters";
import withLocation from "../../../components/common/WithLocation";

import { FIREWALL_COLUMNS, FIREWALL_FILTERS } from "../../../constants/Settings";
import Alert from "../../../components/common/Alert";
import ToastService from "../../../services/ToastService";

class Firewall extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedFilter: FIREWALL_FILTERS.global.id,
			showCreateModal: false,
			showConfirmDeleteModal: false,
			selectedRuleName: "",
			selectedRuleId: null,
			showDeleteAction: false,
			showUpdateAction: false,

			loading: false,
			loadedAll: false,
			sortField: "",
			sortOrder: "",
			limit: 50,

			rules: []
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = () => {
		this.fetchFirewalls();
	};

	resetComponent = async () => {
		let selectedFilter = null;

		if (UserService.get().GroupPermission.view_global_firewall) {
			selectedFilter = FIREWALL_FILTERS.global.id;
		} else if (UserService.get().GroupPermission.view_location_firewall) {
			selectedFilter = FIREWALL_FILTERS.location.id;
		} else if (UserService.get().GroupPermission.view_company_firewall) {
			selectedFilter = FIREWALL_FILTERS.company.id;
		}

		let showUpdateAction = false;
		if (selectedFilter === FIREWALL_FILTERS.location.id && UserService.get().GroupPermission.update_location_firewall) {
			showUpdateAction = true;
		} else if (selectedFilter === FIREWALL_FILTERS.company.id && UserService.get().GroupPermission.update_company_firewall) {
			showUpdateAction = true;
		} else if (selectedFilter === FIREWALL_FILTERS.global.id && UserService.get().GroupPermission.update_global_firewall) {
			showUpdateAction = true;
		}

		let showDeleteAction = false;
		if (selectedFilter === FIREWALL_FILTERS.location.id && UserService.get().GroupPermission.delete_location_firewall) {
			showDeleteAction = true;
		} else if (selectedFilter === FIREWALL_FILTERS.company.id && UserService.get().GroupPermission.delete_company_firewall) {
			showDeleteAction = true;
		} else if (selectedFilter === FIREWALL_FILTERS.global.id && UserService.get().GroupPermission.delete_global_firewall) {
			showDeleteAction = true;
		}

		await this.update({
			selectedFilter: selectedFilter,
			showCreateModal: false,
			showConfirmDeleteModal: false,
			selectedRuleName: "",
			selectedRuleId: null,
			showUpdateAction,
			showDeleteAction,

			loading: false,
			loadedAll: false,
			sortField: "",
			sortOrder: "",
			limit: 50,

			rules: []
		});
		await this.fetchFirewalls();
	};

	fetchFirewalls = async () => {
		let { limit, selectedFilter } = this.state;

		if (!selectedFilter) {
			return;
		}

		let query = {};
		if (selectedFilter === FIREWALL_FILTERS.location.id) {
			query.locationId = UserService.getActiveLocation().id;
		} else if (selectedFilter === FIREWALL_FILTERS.company.id) {
			query.companyId = UserService.getActiveCompany().id;
		} else if (selectedFilter === FIREWALL_FILTERS.global.id) {
			query.includeGlobal = true;
		}

		let rules = await FirewallService.fetch(query);
		await this.update({ rules, loading: false, loadedAll: rules.length < limit });
	};

	onSave = async () => {
		await this.fetchFirewalls();
		await this.update({
			showCreateModal: false,
			selectedRuleId: null
		});
	};

	onShowCreateModal = async id => {
		if (id) {
			await this.update({ selectedRuleId: id, showCreateModal: true });
			return;
		}
		await this.update({ showCreateModal: true, selectedRuleId: null });
	};

	onConfirmDelete = (id, name) => {
		this.update({
			showConfirmDeleteModal: true,
			selectedRuleId: id,
			selectedRuleName: name
		});
	};

	onCloseDeleteModal = async confirm => {
		let { t } = this.props;

		if (confirm) {
			let { selectedRuleId, selectedRuleName } = this.state;
			let success = await FirewallService.update({ firewallId: selectedRuleId, status: "deleted" });

			if (success) {
				ToastService.info(t("Deleted rule"));
			} else {
				ToastService.error(t("Failed to delete rule. Please try again."));
			}

			this.fetchFirewalls();
		}

		await this.update({
			showConfirmDeleteModal: false,
			selectedRuleId: null,
			selectedRuleName: ""
		});
	};

	getHeaders() {
		let headers = {
			items: FIREWALL_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	}

	onFilterSelected = async item => {
		await this.update({ selectedFilter: item.id });
		await this.fetchFirewalls();
	};

	isFilterSelected = item => {
		return item === this.state.selectedFilter;
	};

	getFilters() {
		let filterItems = {};

		if (UserService.get().GroupPermission.view_global_firewall) {
			filterItems.global = FIREWALL_FILTERS.global;
		}
		if (UserService.get().GroupPermission.view_location_firewall) {
			filterItems.location = FIREWALL_FILTERS.location;
		}
		if (UserService.get().GroupPermission.view_company_firewall) {
			filterItems.company = FIREWALL_FILTERS.company;
		}

		filterItems = Object.keys(filterItems)
			.map(item => {
				return { id: item, value: filterItems[item].display, order: filterItems[item].order };
			})
			.sort((a, b) => a.order - b.order);

		let filters = {
			filters: {
				items: filterItems,
				onClick: this.onFilterSelected,
				isSelected: this.isFilterSelected
			}
		};
		return filters;
	}

	renderRecord = recordData => {
		try {
			let { showUpdateAction, showDeleteAction } = this.state;
			let { t } = this.props;

			if (!recordData) {
				return null;
			}
			return [
				recordData.name,
				recordData.description,
				recordData.value,
				recordData.field,
				recordData.match_type,
				<div className="dh-list__actions">
					{showUpdateAction && (
						<Action id={`update-${recordData.id}`} label={t("Update Rule")} icon={Icon.Edit} onClick={() => this.onShowCreateModal(recordData.id)} />
					)}
					{showDeleteAction && (
						<Action
							id={`delete-${recordData.id}`}
							label={t("Delete Rule")}
							icon={Icon.Trash2}
							onClick={() => this.onConfirmDelete(recordData.id, recordData.name)}
						/>
					)}
				</div>
			];
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	render = () => {
		let {
			selectedFilter,
			showCreateModal,
			selectedRuleId,
			rules,
			loading,
			loadedAll,
			sortField,
			sortOrder,
			showConfirmDeleteModal,
			selectedRuleName
		} = this.state;
		let { t } = this.props;

		if (
			!UserService.get().GroupPermission.view_global_firewall &&
			!UserService.get().GroupPermission.view_company_firewall &&
			!UserService.get().GroupPermission.view_location_firewall
		) {
			return <Redirect to="/" />;
		}

		return (
			<Page>
				<Header title={t("Firewall Rules")}>
					<Action id="create" label={t("Create Rule")} icon={Icon.Plus} onClick={() => this.onShowCreateModal()} />
				</Header>

				<Filters filters={this.getFilters()} />

				<List
					items={rules}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={() => {}}
					noDataTitle={t("No firewall rules found...")}
					noDataIcon={<Icon.AlertCircle />}
				/>

				<EditRuleModal
					show={showCreateModal}
					ruleId={selectedRuleId}
					onHide={() => this.update({ showCreateModal: false, selectedRuleId: null })}
					onSave={this.onSave}
					type={selectedFilter}
				/>

				<Alert
					type="warning"
					show={showConfirmDeleteModal}
					title={t("Delete rule")}
					confirm={t("Delete")}
					cancel={t("Cancel")}
					onClose={this.onCloseDeleteModal}
				>
					{t("Are you sure you would like to delete the rule?")}
				</Alert>
			</Page>
		);
	};
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(Firewall)));
