import React from "react";

class Profitwell extends React.Component {
	state = {
		hasInit: false
	};

	componentDidUpdate() {
		const { profitwell } = window;
		const { hasInit } = this.state;

		if (!profitwell || hasInit) {
			return;
		}

		const { email, userId } = this.props;

		if (email) {
			console.log("profitwell: updating user_email");
			profitwell("user_email", email);
			this.setState({
				hasInit: true
			});
		} else if (userId) {
			console.log("profitwell: updating user_id");
			profitwell("user_id", userId);
			this.setState({
				hasInit: true
			});
		}
	}

	componentDidMount() {
		const { doNotLoad, authToken } = this.props;

		if (doNotLoad) {
			return;
		}

		if (!authToken) {
			console.error("ERROR: property authToken is required");
			return;
		}

		const script = document.createElement("script");

		script["id"] = "profitwell-js";
		script["data-pw-auth"] = `${authToken}`;

		document.body.appendChild(script);

		// Beginning of Profitwell code (loads asynchronously)
		/* eslint-disable */
		// (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
		// a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
		// s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
		// })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
		(function(i, s, o, g, r, a, m) {
			i[o] =
				i[o] ||
				function() {
					(i[o].q = i[o].q || []).push(arguments);
				};
			a = s.createElement(g);
			m = s.getElementsByTagName(g)[0];
			a.async = 1;
			a.src = r + "?auth=" + `${authToken}`;
			m.parentNode.insertBefore(a, m);
		})(window, document, "profitwell", "script", "https://public.profitwell.com/js/profitwell.js");
		/* eslint-enable */
	}

	render() {
		return null;
	}
}

export default Profitwell;
