import React, { Component } from "react";
import ReactSwitch from "react-switch";
import * as Icon from "react-feather";
import ReactTable from "react-table";
import matchSorter from "match-sorter";

import PreferenceService from "../../../services/PreferenceService";
import { AnalyticsService } from "../../../services/AnalyticsService";

import Spinners from "../../../components/common/Spinners";
import NoDataComponent from "../../../components/common/NoDataComponent";

import "../../../styles/css/components/commons/react-table.css";

class CSMessageMediums extends Component {
	state = {
		messengerChannelStats: [],
		loading: true,
		filteredMessengerChannelStats: [],
		searchString: "",
		locationDesc: false,
		companyDesc: true,
		smsSentCountDesc: true,
		smsReceivedCountDesc: true,
		fbSentCountDesc: true,
		fbReceivedCountDesc: true,
		showFavoritesOnly: PreferenceService.get("show_favorites") ? true : false
	};
	toggleFavoritesOnlySwitch() {
		PreferenceService.set("show_favorites", !this.state.showFavoritesOnly);
		this.setState({ showFavoritesOnly: !this.state.showFavoritesOnly }, this.fetchMessengerChannelStats);
	}

	async fetchMessengerChannelStats() {
		this.setState({ messengerChannelStats: [], loading: true });

		let data = await AnalyticsService.getMessengerChannelStats({ favoritesOnly: this.state.showFavoritesOnly });

		if (!data) {
			this.setState({ loading: false });
			return;
		}

		this.setState({ messengerChannelStats: data });
	}
	componentDidMount() {
		this.fetchMessengerChannelStats();
	}
	handleGeneralSearchOnChange(event) {
		const searchString = event.target.value;
		const filteredMessengerChannelStats = [{ id: "all", value: searchString }];

		// NOTE: this completely clears any COLUMN filters
		this.setState({ searchString, filteredMessengerChannelStats });
	}
	render() {
		const { messengerChannelStats, loading, filteredMessengerChannelStats, searchString, showFavoritesOnly } = this.state;
		return (
			<div>
				<div className="row wrapper white-bg page-heading">
					<div className="col-sm-6 col-md-8 col-lg-9">
						<h2>
							Message Medium Analytics{" "}
							<span
								id="refresh"
								style={{ marginLeft: "10px", cursor: "pointer", userSelect: "none", transform: "translate(0px, 3px)", position: "absolute" }}
								onClick={() => this.fetchMessengerChannelStats()}
							>
								<Icon.RefreshCcw />
							</span>
							<br />
							<br />
							<input
								style={{ width: "400px" }}
								type="text"
								placeholder="Filter Messenger Channel Analytics..."
								className="form-control filter-textbox"
								value={searchString}
								onChange={e => this.handleGeneralSearchOnChange(e)}
							/>
						</h2>
					</div>
					<div
						className="col-sm-6 col-md-4 col-lg-3"
						style={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							marginTop: "20px",
							paddingRight: "20px"
						}}
					>
						<div>
							<b>All</b>
						</div>
						<div style={{ marginRight: "5px", marginLeft: "5px" }}>
							<ReactSwitch
								id="show_favorites_only"
								onChange={() => this.toggleFavoritesOnlySwitch()}
								checked={showFavoritesOnly}
								uncheckedIcon={false}
								checkedIcon={false}
								onColor="#4A90E2"
								type="text"
							/>
						</div>
						<div>
							<b>Favourites</b>
						</div>
					</div>
				</div>
				<div className="wrapper wrapper-content">
					<div className="ibox">
						<div className="row">
							<div className="col-sm-12">
								<div className="ibox-content">
									<ReactTable
										data={messengerChannelStats}
										filtered={filteredMessengerChannelStats}
										onFilteredChange={filteredMessengerChannelStats => this.setState({ filteredMessengerChannelStats })}
										onSortedChange={sorted => {
											if (sorted.length !== 1) {
												return;
											}
											this.setState({ [sorted[0].id + "Desc"]: sorted[0].desc });
										}}
										defaultSorted={[
											{
												id: "location",
												desc: false
											}
										]}
										columns={[
											{
												Header: () => (
													<div className="DHReactTable__header--container">
														Company {this.state.companyDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
													</div>
												),
												headerClassName: "DHReactTable__row--header",
												accessor: "company_name",
												className: "DHReactTable__cell",
												id: "company",
												Cell: row => <span className="DHReactTable__row">{row.value}</span>
											},
											{
												Header: () => (
													<div className="DHReactTable__header--container">
														Location {this.state.locationDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
													</div>
												),
												headerClassName: "DHReactTable__row--header",
												accessor: "name",
												className: "DHReactTable__cell",
												id: "location",
												Cell: row => <span className="DHReactTable__row">{row.value}</span>
											},
											{
												Header: "SMS",
												headerClassName: "DHReactTable__row--header",
												columns: [
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Sent {this.state.smsSentCountDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "outbound_sms_messages",
														className: "DHReactTable__cell",
														id: "smsSentCount",
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
													},
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Received {this.state.smsReceivedCountDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "inbound_sms_messages",
														className: "DHReactTable__cell",
														id: "smsReceivedCount",
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
													}
												]
											},
											{
												Header: "Email",
												headerClassName: "DHReactTable__row--header",
												columns: [
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Sent {this.state.smsSentCountDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "outbound_email_messages",
														className: "DHReactTable__cell",
														id: "emailSentCount",
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
													},
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Received {this.state.smsReceivedCountDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "inbound_email_messages",
														className: "DHReactTable__cell",
														id: "emailReceivedCount",
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
													}
												]
											},
											{
												Header: "Google",
												headerClassName: "DHReactTable__row--header",
												columns: [
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Sent {this.state.smsSentCountDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "outbound_google_messages",
														className: "DHReactTable__cell",
														id: "googleSentCount",
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
													},
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Received {this.state.smsReceivedCountDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "inbound_google_messages",
														className: "DHReactTable__cell",
														id: "googleReceivedCount",
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
													}
												]
											},
											{
												Header: "Facebook",
												headerClassName: "DHReactTable__row--header",
												columns: [
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Sent {this.state.fbSentCountDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "outbound_facebook_messages",
														className: "DHReactTable__cell",
														id: "fbSentCount",
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
													},
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Received {this.state.fbReceivedCountDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														resizable: false,
														headerClassName: "DHReactTable__row--header",
														accessor: "inbound_facebook_messages",
														className: "DHReactTable__cell",
														id: "fbReceivedCount",
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
													}
												]
											},
											{
												Header: "Instagram",
												headerClassName: "DHReactTable__row--header",
												columns: [
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Sent {this.state.fbSentCountDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "outbound_instagram_messages",
														className: "DHReactTable__cell",
														id: "instagramSentCount",
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
													},
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Received {this.state.fbReceivedCountDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														resizable: false,
														headerClassName: "DHReactTable__row--header",
														accessor: "inbound_instagram_messages",
														className: "DHReactTable__cell",
														id: "instagramReceivedCount",
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
													}
												]
											},
											{
												Header: "Secure",
												headerClassName: "DHReactTable__row--header",
												columns: [
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Sent {this.state.fbSentCountDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "outbound_secure_messages",
														className: "DHReactTable__cell",
														id: "secureSentCount",
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
													},
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Received {this.state.fbReceivedCountDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														resizable: false,
														headerClassName: "DHReactTable__row--header",
														accessor: "inbound_secure_messages",
														className: "DHReactTable__cell",
														id: "secureReceivedCount",
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
													}
												]
											},
											{
												Header: "All",
												id: "all",
												width: 0,
												headerClassName: "hide",
												resizable: false,
												sortable: false,
												Filter: () => {},
												getProps: () => {
													return {
														// style: { padding: "0px"}
													};
												},
												filterMethod: (filter, rows) => {
													// using match-sorter
													// it will take the content entered into the "filter"
													// and search for it in EITHER the firstName or lastName
													const result = matchSorter(rows, filter.value, {
														keys: ["company", "location"],
														threshold: matchSorter.rankings.CONTAINS
													});
													return result;
												},
												filterAll: true
											}
										]}
										LoadingComponent={() => (
											<div className="Common__spinnerdiv--center">
												<Spinners loading={loading} type="tail-fade" size="120px" />
											</div>
										)}
										NoDataComponent={() => <NoDataComponent loading={loading} />}
										showPagination={messengerChannelStats.length > 10}
										PreviousComponent={props => (
											<button {...props} className="btn btn-default btn-outline btn-sm pagination-item">
												<Icon.ChevronLeft style={{ paddingTop: "4px" }} size="20" />
											</button>
										)}
										NextComponent={props => (
											<button {...props} className="btn btn-default btn-outline btn-sm pagination-item">
												<Icon.ChevronRight style={{ paddingTop: "4px" }} size="20" />
											</button>
										)}
										defaultPageSize={10}
										className="-striped -highlight"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CSMessageMediums;
