import React from "react";
import { withRouter } from "react-router-dom";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";

import FeatureSummary from "./FeatureSummary";
import LocationList from "./LocationList";

import GAService from "../../../services/GAService";

import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";
import { FEATURE_MATRIX_TABS } from "../../../constants/FeatureMatrix";

import "../../../styles/css/scenes/feature-matrix.css";

class FeatureMatrix extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: FEATURE_MATRIX_TABS.summary.id
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	onTabSelect = tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.customerSuccess.sections.featureMatrix,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		this.update({ selectedTab: tab.id });
	};

	render = () => {
		let { selectedTab } = this.state;

		return (
			<Page>
				<Header title="Feature Matrix" />
				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					<Tab id={FEATURE_MATRIX_TABS.summary.id} value={FEATURE_MATRIX_TABS.summary.value} />
					<Tab id={FEATURE_MATRIX_TABS.locations.id} value={FEATURE_MATRIX_TABS.locations.value} />
				</Tabs>

				{FEATURE_MATRIX_TABS.summary.id === selectedTab && <FeatureSummary />}
				{FEATURE_MATRIX_TABS.locations.id === selectedTab && <LocationList />}
			</Page>
		);
	};
}

export default withRouter(FeatureMatrix);
