import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";

import UserService from "../../services/UserService";
import UtilityService from "../../services/UtilityService";

import ResetMediumEntry from "./ResetMediumEntry";
import moment from "moment";

import "../../styles/css/scenes/reset-password.css";

class ResetMediumSelect extends Component {
	state = {
		medium: ""
	};
	componentDidMount() {
		document.body.classList.add("gray-bg");
		UtilityService.checkAndSetDarkBackground();
	}

	componentWillUnmount() {
		document.body.classList.remove("gray-bg");
		document.body.classList.remove("Common-bg--dark");
	}

	render() {
		const { medium } = this.state;
		let { t } = this.props;

		if (UserService.get()) {
			this.props.history.push("/");
		}

		if (medium !== "") {
			return <ResetMediumEntry medium={medium} handleResetMedium={() => this.handleResetMedium()} />;
		}

		return (
			<div className="reset-password Common__card animated fadeIn">
				<h2 className="font-bold">{t("Forgot password")}</h2>
				<p>
					{t("How would you like to reset your password?")}
					<br />
					<br /> {t("You will be logged out of all of your devices.")}{" "}
				</p>
				<div className="reset-password__buttons">
					<button className="mb-button mb-button--slim" onClick={() => this.setState({ medium: "phone" })}>
						{t("Phone")}
					</button>
					<button className="mb-button mb-button--slim" onClick={() => this.setState({ medium: "email" })}>
						{t("Email")}
					</button>
				</div>
				<hr />
				<button className="mb-button mb-button--slim mb-button--cancel mb-button--fit" onClick={() => this.props.history.push("/login")}>
					{t("Cancel")}
				</button>

				<div className="reset-password__copyright">
					{t("Copyright")} &copy; {moment().format("YYYY")} DemandHub Inc.
				</div>
			</div>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(ResetMediumSelect));
