export const APP_STOPPAGE_REASONS = {
	maintenance: "maintenance",
	degraded: "degraded",
	webApp: "webApp",
	network: "network"
};

export const BACKEND_STATUSES = {
	ok: "ok",
	maintenance: "maintenance",
	degraded: "degraded"
};
