import React from "react";
import "../../styles/css/components/commons/dh-dropdown-option.css";

const Option = ({ icon, title, action, description }) => {
	let DIcon = icon;

	let dropdownOptionItemClasses = ["dropdown-option__item"];

	if (description) {
		dropdownOptionItemClasses.push(["dropdown-option__item--tall"]);
	}

	return (
		<div className={dropdownOptionItemClasses.join(" ")} onClick={() => action()}>
			<div className="dropdown-option__item__icon">
				<DIcon size={18} />
			</div>
			<div className="dropdown-option__item__text">
				<div className="dropdown-option__item__text__title">{title}</div>
				{description && <div className="dropdown-option__item__text__description">{description}</div>}
			</div>
		</div>
	);
};

export default Option;
