import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Alert from "./Alert";
import LogService from "../../services/logService";
import StatusService from "../../services/StatusService";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			internetOffline: null,
			goTo: this.props.goTo
		};
	}

	async componentDidCatch(error, info) {
		let onlineStatus = await this.checkOnlineStatus();
		if (onlineStatus) {
			this.setState({ hasError: true, internetOffline: false });
		} else {
			this.setState({ hasError: true, internetOffline: true });
		}
		LogService.log(error, info);
	}

	componentReset() {
		this.setState({ hasError: false, internetOffline: false });
	}

	checkOnlineStatus = async () => {
		try {
			// If we are local, don't check anything
			if (process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENV !== "stage") {
				return true;
			}

			return await StatusService.checkOnlineStatus();
		} catch (error) {
			return false;
		}
	};

	render() {
		let { t } = this.props;
		let { hasError, internetOffline } = this.state;

		if (hasError) {
			if (internetOffline)
				return (
					<Alert
						type="error"
						show={true}
						title={t("Network Error")}
						confirm="Okay"
						onClose={() => {
							this.props.history.push(this.state.goTo);
							this.componentReset();
						}}
					>
						<div>{t("Please check your internet connection and try again.")}</div>
					</Alert>
				);
			else {
				return (
					<Alert
						type="error"
						show={true}
						title={t("We're sorry. An error occurred.")}
						confirm="Okay"
						onClose={() => {
							this.props.history.push(this.state.goTo);
							this.componentReset();
						}}
					>
						<div>{t("Something went wrong. Please try again later.")}</div>
					</Alert>
				);
			}
		}
		return this.props.children;
	}
}

export default withRouter(withTranslation(null, { withRef: true })(ErrorBoundary));
