import React from "react";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";

import ReengagementService from "../../services/ReengagementService";
import UserService from "../../services/UserService";
import { AnalyticsService } from "../../services/AnalyticsService";
import SupportChatService from "../../services/SupportChatService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";

import withLocation from "../../components/common/WithLocation";

class ReengagementRevenue extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			reengagementConversions: 0,
			reengagementRevenue: 0,
			hasCrmIntegrations: false
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = () => {
		this.fetchData();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	fetchData = async () => {
		try {
			let locationId = UserService.getActiveLocation().id;
			let result = await AnalyticsService.getTotalConversionsCount({ locationId });
			let crmIntegrations = await CrmIntegrationsService.fetch(locationId, { limit: 1, status: ["active"] });

			// Get average appointment cost stored in the location metadata
			let location = await UserService.getActiveLocation();
			let averageConversionValue = location.average_conversion_value;
			let reengagementConversions = result.reengagementConversions;
			let reengagementRevenue = Math.floor(reengagementConversions * averageConversionValue);

			await this.update({
				reengagementConversions: reengagementConversions,
				reengagementRevenue: reengagementRevenue,
				hasCrmIntegrations: crmIntegrations && crmIntegrations.length > 0 ? true : false
			});
		} catch (error) {
			console.log(error);
		}
	};

	onReengagementsGetStarted = () => {
		SupportChatService.showNewMessage("Hi, I would like to enable Reengagement features. Would you be able to help?");
	};

	renderHeader = () => {
		const { cardName } = this.props;

		return (
			<div className="dashboard__card__header">
				<div className="dashboard__card__header__icon">
					<Icon.Calendar size="42" />
				</div>
				<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">
					{cardName ? cardName : "Reengagement Revenue"} <Icon.Info size={20} data-tip data-for="dashboard-widget-reengagements-revenue-info" />{" "}
				</div>
				<ReactTooltip
					id="dashboard-widget-reengagements-revenue-info"
					className="mb-react-tooltip mb-react-tooltip--medium"
					arrowColor="#333"
					type="info"
					effect="solid"
					place="top"
				>
					Shows the revenue generated through an automated follow-up message to a past customer or client. Requires the Reengagement module to be enabled and a
					CRM Integration to be active.
				</ReactTooltip>
			</div>
		);
	};

	render = () => {
		let { reengagementRevenue, hasCrmIntegrations } = this.state;
		const { isEnabled, isPermissible } = this.props;

		if (isEnabled && !isEnabled()) {
			return (
				<>
					{this.renderHeader()}
					<div className="dashboard__widget__conversions">
						<div className="mb-button" onClick={this.onReengagementsGetStarted}>
							Get Started
						</div>
					</div>
				</>
			);
		}

		let content = "N/A";

		// Only show if there are active crm integrations and there is at least some revenue
		if (hasCrmIntegrations && reengagementRevenue && reengagementRevenue > 0) {
			content = `$ ${reengagementRevenue.toLocaleString()}`;
		}
		if (isPermissible && !isPermissible()) {
			content = "N/A";
		}

		return (
			<>
				{this.renderHeader()}
				<div className="dashboard__card__stat">
					<div className="dashboard__card__stat__count">{content}</div>
				</div>
			</>
		);
	};
}

export default withLocation(ReengagementRevenue);
