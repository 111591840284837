export const CHATBOT_CONFIG_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 1
	},
	description: {
		id: "description",
		value: "Description",
		sortable: true,
		sortField: "description",
		width: 1
	},
	isActive: {
		id: "isActive",
		value: "Is Active",
		sortable: false,
		sortField: "isActive",
		width: 1,
		notClickable: true
	}
};

export const CHATBOT_FAQ_COLUMNS = {
	question: {
		id: "question",
		value: "Question",
		sortable: true,
		sortField: "question",
		width: 1
	},
	answer: {
		id: "answer",
		value: "Answer",
		sortable: true,
		sortField: "answer",
		width: 1
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		width: 1,
		notClickable: true
	}
};
