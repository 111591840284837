import React, { Component } from "react";
import posed, { PoseGroup } from "react-pose";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";

import "./reply-suggestions.css";

export const Content = posed.div({
	enter: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 200
		}
	},
	exit: {
		y: 40,
		x: 0,
		opacity: 0,
		transition: {
			duration: 100
		}
	}
});

class ReplySuggestions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			replySuggestions: this.props.replySuggestions || [],
			messageInput: this.props.messageInput || "",
			showSuggestions: false,
			startIndex: 0,
			endIndex: 2
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	updateReplySuggestions = async newSuggestions => {
		await this.update({
			replySuggestions: newSuggestions || []
		});
		this.refreshComponentVisibility();
	};

	onSuggestionSelected(suggestion) {
		this.update({
			replySuggestions: []
		});
		this.props.onSuggestionSelected(suggestion);
	}

	refreshComponentVisibility = () => {
		let { messageInput, replySuggestions, showSuggestions } = this.state;
		if (messageInput.length === 0 && replySuggestions.length > 0 && !showSuggestions) {
			this.update({
				showSuggestions: true
			});
		} else if ((messageInput.length > 0 || replySuggestions.length === 0) && showSuggestions) {
			this.update({
				showSuggestions: false
			});
		}
	};

	componentDidMount() {
		this.refreshComponentVisibility();
	}

	async componentDidUpdate(prevProps) {
		let { replySuggestions, messageInput } = this.props;
		if (prevProps.contactId !== this.props.contactId) {
			this.updateReplySuggestions(replySuggestions);
		}
		if (prevProps.messageInput !== messageInput) {
			await this.update({
				messageInput: messageInput || ""
			});
			this.refreshComponentVisibility();
		}
	}

	onBack = () => {
		let { startIndex, endIndex } = this.state;

		this.update({
			startIndex: startIndex - 1,
			endIndex: endIndex - 1
		});
	};

	onForward = () => {
		let { startIndex, endIndex } = this.state;

		this.update({
			startIndex: startIndex + 1,
			endIndex: endIndex + 1
		});
	};

	render() {
		let { showSuggestions, replySuggestions, startIndex, endIndex } = this.state;

		return (
			<PoseGroup>
				{showSuggestions && (
					<Content key="container" className="reply-suggestions">
						<div className="reply-suggestions__content">
							{startIndex > 0 && (
								<div className="reply-suggestions__content__action" onClick={this.onBack}>
									<Icon.ArrowLeft size="16" />
								</div>
							)}

							{replySuggestions.slice(startIndex, endIndex + 1).map(suggestion => {
								return (
									<React.Fragment id={suggestion.id}>
										<ReactTooltip id={`rs-${suggestion.id}`} className="mb-react-tooltip" type="info" effect="solid" place="top" arrowColor="#333">
											{suggestion.content}
										</ReactTooltip>
										<div
											data-tip
											data-for={`rs-${suggestion.id}`}
											className="reply-suggestions__content__item"
											onClick={() => this.onSuggestionSelected(suggestion.content)}
										>
											{suggestion.content}
										</div>
									</React.Fragment>
								);
							})}

							{endIndex + 1 < replySuggestions.length && (
								<div className="reply-suggestions__content__action" onClick={this.onForward}>
									<Icon.ArrowRight size="16" />
								</div>
							)}

							<ReactTooltip id={`rs-help`} className="mb-react-tooltip" type="info" effect="solid" place="top" arrowColor="#333">
								These message blurbs are suggested replies to help you quickly answer inbound messages.
							</ReactTooltip>
							<div data-tip data-for={`rs-help`} className="reply-suggestions__content__action">
								<Icon.HelpCircle size="16" />
							</div>

							<ReactTooltip id={`rs-close`} className="mb-react-tooltip" type="info" effect="solid" place="top" arrowColor="#333">
								Close
							</ReactTooltip>
							<div data-tip data-for={`rs-close`} className="reply-suggestions__content__action" onClick={() => this.updateReplySuggestions([])}>
								<Icon.X size="16" />
							</div>
						</div>
					</Content>
				)}
			</PoseGroup>
		);
	}
}

export default ReplySuggestions;
