import React, { Component } from "react";
import * as Icon from "react-feather";
import { PoseGroup } from "react-pose";

import MessagesService from "../../../../services/MessagesService";
import UserService from "../../../../services/UserService";
import UtilityService from "../../../../services/UtilityService";

import { ContextMenu } from "../../../../constants/Messenger";
import ReactTooltip from "react-tooltip";

import "./assigned-user.css";

class AssignedUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contactId: null,
			locationId: null,
			assignedUserId: [],
			users: [],
			showList: false,
			showLabel: false
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async componentDidMount() {
		document.addEventListener("mousedown", this.onMouseDown, false);
		await this.resetComponent();
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onMouseDown, false);
	}

	componentDidUpdate(prevProps) {
		let { contactId, locationId, assignedUserId } = this.props;

		if (prevProps.contactId !== contactId || prevProps.locationId !== locationId || !UtilityService.arraysEqual(prevProps.assignedUserId, assignedUserId)) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { includeAllFilter, contactId, locationId, assignedUserId, showLabel } = this.props;
		let users = await MessagesService.fetchAssignableUsers(locationId);

		users = users.map(user => {
			var fullName = UserService.createFullName({ firstName: user.first_name, lastName: user.last_name });
			return {
				id: user.id,
				name: `${fullName}`,
				email: user.email
			};
		});

		if (includeAllFilter) {
			users.unshift({
				id: null,
				name: "All",
				email: ""
			});
		} else {
			users.unshift({
				id: 0,
				name: "Unassigned",
				email: ""
			});
		}

		await this.update({
			contactId: contactId,
			locationId: locationId,
			assignedUserId: assignedUserId || [],
			users: users,
			showLabel
		});
	}

	onMouseDown = async e => {
		let { showList } = this.state;

		if (this.dropdown && this.dropdown.contains && this.dropdown.contains(e.target)) {
			return;
		}

		if (this.contextContainer && this.contextContainer.contains && this.contextContainer.contains(e.target) && !showList) {
			await this.update({
				showList: true
			});
		} else if (this.dropdown && this.dropdown.contains) {
			await this.update({
				showList: false
			});
		}

		if (this.props.onShowList) {
			this.props.onShowList(this.state.showList);
		}
	};

	getAssignedUser = () => {
		let { users, assignedUserId } = this.state;

		let item = users.find(user => {
			return assignedUserId && assignedUserId.includes(user.id);
		});

		if (!item) {
			item = users[0];
		}

		return item;
	};

	async onSelect(user) {
		let { locationId, contactId } = this.state;

		if (contactId) {
			// XXX - Kinda gunda and highly coupled to backend logic
			let isAssigned = await MessagesService.updateAssignedUserForContact(locationId, contactId, user.id);
			if (isAssigned) {
				await this.update({
					assignedUserId: [user.id]
				});
				await this.updateAssignedUserList(user.id);
			}
		} else {
			await this.updateAssignedUserList(user.id);
		}

		if (this.props.onSelect) {
			let { assignedUserId } = this.state;
			let { multiSelect } = this.props;
			if (multiSelect) {
				this.props.onSelect(assignedUserId);
			} else {
				this.props.onSelect(user);
			}
		}

		await this.update({ showList: false });
	}

	updateAssignedUserList = async userId => {
		let { multiSelect } = this.props;
		let { assignedUserId } = this.state;

		if (!assignedUserId) {
			assignedUserId = [];
		}

		if (!userId) {
			assignedUserId = [];
		} else if (multiSelect) {
			let foundIndex = assignedUserId.indexOf(userId);
			// If it's already selected, remove it
			if (foundIndex >= 0) {
				assignedUserId.splice(foundIndex, 1);
			} else {
				// Otherwise, add this user id to the user filter
				assignedUserId.push(userId);
			}
		} else {
			assignedUserId = [userId];
		}

		await this.update({
			assignedUserId
		});
	};

	renderUsers() {
		let { users, assignedUserId } = this.state;
		return (
			<ContextMenu key="container" ref={ref => (this.dropdown = ref)} className="mb-assigned-user-list">
				{users.map(user => {
					return (
						<div key={user.id} className="mb-assigned-user-item" onClick={() => this.onSelect(user)}>
							{user.name}
							{assignedUserId && assignedUserId.includes(user.id) && (
								<div className="mb-assigned-user-item__selected">
									<Icon.CheckCircle size="16" />
								</div>
							)}
						</div>
					);
				})}
			</ContextMenu>
		);
	}

	render() {
		let { showList, showLabel, assignedUserId } = this.state;
		let assignedUser = this.getAssignedUser();

		if (!assignedUser) {
			return "";
		}

		return (
			<>
				{showLabel && (
					<>
						<div ref={ref => (this.contextContainer = ref)} className="mb-assigned-user">
							<div className="mb-assigned-user-label">Assigned to</div>
							<div className="mb-assigned-user-selector" data-tip data-for="assigned-tooltip">
								<div className="mb-assigned-user-icon">
									<Icon.ChevronDown size="24" />
								</div>
								<div className="mb-assigned-user-name">{assignedUser.name}</div>
							</div>
						</div>
					</>
				)}
				{!showLabel && (
					<div className="fnctst-list-filter-assigned-user-open" ref={ref => (this.contextContainer = ref)} data-tip data-for="assigned-tooltip">
						<Icon.UserCheck size="22" color={assignedUserId && assignedUserId.length > 0 ? "#60A9FF" : "#333"} />
					</div>
				)}
				<PoseGroup>{showList && this.renderUsers()}</PoseGroup>
				<ReactTooltip id="assigned-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="bottom">
					Select a user
				</ReactTooltip>
			</>
		);
	}
}

export default AssignedUser;
