import React from "react";
import * as Icon from "react-feather";
import { Collapse } from "react-bootstrap";

import { AnalyticsService } from "../../../services/AnalyticsService";
import ToastService from "../../../services/ToastService";

import List from "../../../components/common/List";
import Filters from "../../../components/common/Filters";
import SideModal from "../../../components/common/SideModal";
import SearchInput from "../../../components/common/SearchInput";
import Action from "../../../components/common/Action";
import Checkbox from "../../../components/common/Checkbox";

import { SORT_ORDER } from "../../../constants/CommonConstants";
import { FILTER_COLUMN_IS_ENABLED_OPTIONS, LOCATION_LIST_COLUMNS } from "../../../constants/FeatureMatrix";

import "../../../styles/css/scenes/feature-matrix.css";

class LocationList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// The data that we want to show (location data)
			locations: [],
			// Fetching states
			loading: false,
			loadedAll: true,
			// Filtering states
			searchTerm: "",
			sortField: LOCATION_LIST_COLUMNS.company_name.sortField,
			sortOrder: SORT_ORDER.asc,
			selectedFeatureTypeFilter: "",
			limit: 1000,
			pageSize: 100,
			enabledFeaturesFilter: {},
			disabledFeaturesFilter: {},
			// Matrix config data
			featureTypes: null,

			// The columns we are currently showing
			columns: {},

			// Confiure columns side modal states
			showColumnsModal: false,
			columnSectionsExpanded: [],

			// Filter data side modal
			showFilterModal: false,
			filterSectionsExpanded: []
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		let columns = {
			[LOCATION_LIST_COLUMNS.company_name.id]: LOCATION_LIST_COLUMNS.company_name,
			[LOCATION_LIST_COLUMNS.location_name.id]: LOCATION_LIST_COLUMNS.location_name
		};

		let matrixConfig = await AnalyticsService.fetchFeatureMatrixConfig();

		if (!matrixConfig) {
			ToastService.error(`Something went wrong. Please try again.`);
			return;
		}

		let featureTypes = matrixConfig.feature_types;

		featureTypes.messenger.fields.map(field => {
			if (LOCATION_LIST_COLUMNS[field]) {
				columns[field] = LOCATION_LIST_COLUMNS[field];
			} else {
				console.log(`Field ${field} not found in location list messenger columns.`);
			}
		});

		await this.update({
			// The data that we want to show (location data)
			locations: [],
			// Fetching states
			loading: false,
			loadedAll: true,
			// Filtering states
			searchTerm: "",
			sortField: LOCATION_LIST_COLUMNS.company_name.sortField,
			sortOrder: SORT_ORDER.asc,
			selectedFeatureTypeFilter: featureTypes.messenger.id,
			limit: 1000,
			pageSize: 100,
			enabledFeaturesFilter: {},
			disabledFeaturesFilter: {},
			// Matrix config data
			featureTypes: featureTypes,

			// The columns we are currently showing
			columns: columns,

			// Confiure columns side modal states
			showColumnsModal: false,
			columnSectionsExpanded: Object.keys(featureTypes).map(() => false),

			// Filter data side modal
			showFilterModal: false,
			filterSectionsExpanded: Object.keys(featureTypes).map(() => false)
		});

		await this.fetchLocationData();
	};

	fetchLocationData = async () => {
		try {
			let { searchTerm, sortField, sortOrder, enabledFeaturesFilter, disabledFeaturesFilter, limit } = this.state;

			await this.update({ loading: true });

			let data = await AnalyticsService.fetchCSLocationFeatureList({
				searchTerm,
				sortField,
				sortOrder,
				enabledFeaturesFilter: Object.values(enabledFeaturesFilter).map(f => f.sortField),
				disabledFeaturesFilter: Object.values(disabledFeaturesFilter).map(f => f.sortField)
			});

			await this.update({
				locations: data,
				loadedAll: data.length < limit,
				loading: false
			});
		} catch (error) {
			await this.update({ loading: false });
			console.log(error);
			ToastService.error("Unable to fetch data. Please try again.");
		}
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchLocationData();
	};

	/**
	 * Search and Filter related methods
	 */

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		await this.fetchLocationData();
	};

	onFilterSelected = async item => {
		let columns = {
			[LOCATION_LIST_COLUMNS.company_name.id]: LOCATION_LIST_COLUMNS.company_name,
			[LOCATION_LIST_COLUMNS.location_name.id]: LOCATION_LIST_COLUMNS.location_name
		};

		let { featureTypes } = this.state;

		if (featureTypes) {
			featureTypes[item.id].fields.map(field => {
				if (LOCATION_LIST_COLUMNS[field]) {
					columns[field] = LOCATION_LIST_COLUMNS[field];
				} else {
					console.log(`Field ${field} not found in location list columns.`);
				}
			});
		}

		await this.update({
			selectedFeatureTypeFilter: item.id,
			columns
		});
		await this.fetchLocationData();
	};

	isFilterSelected = item => {
		return item === this.state.selectedFeatureTypeFilter;
	};

	getFilters = () => {
		let { featureTypes } = this.state;

		let items = [];
		if (featureTypes) {
			items = Object.keys(featureTypes)
				.map(item => {
					return { id: item, value: featureTypes[item].display, order: featureTypes[item].order };
				})
				.sort((a, b) => a.order - b.order);
		}

		let filters = {
			filters: {
				title: "Show Column Type",
				items: items,
				onClick: this.onFilterSelected,
				isSelected: this.isFilterSelected
			}
		};
		return filters;
	};

	/**
	 * List related methods
	 */

	getHeaders() {
		let { columns } = this.state;
		let items = { ...columns };

		let headers = {
			items: items,
			sortBy: this.sortBy
		};

		return headers;
	}

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ sortField, sortOrder });
		await this.fetchLocationData();
	};

	/**
	 * Side modal related methods
	 */

	onOpenFiltersModal = async () => {
		await this.update({
			showColumnsModal: true
		});
	};

	onConfigColumnChange = async field => {
		let { columns } = this.state;

		// Remove field
		if (columns[field]) {
			delete columns[field];
		} else {
			// Add field to the ones we want to add
			columns[field] = LOCATION_LIST_COLUMNS[field];
		}

		// Ensure the order is the same as LOCATION_LIST_COLUMNS
		// All columns
		let allColumns = Object.values(LOCATION_LIST_COLUMNS);

		// Only include the ones we want to show
		let currentColumns = {};
		for (let i = 0; i < allColumns.length; i++) {
			const allColumn = allColumns[i];
			if (columns[allColumn.id]) {
				currentColumns[allColumn.id] = { ...allColumn };
			}
		}

		await this.update({
			columns: currentColumns
		});
	};

	onFilterColumnChange = async field => {
		let { enabledFeaturesFilter, disabledFeaturesFilter } = this.state;

		if (enabledFeaturesFilter[field] || disabledFeaturesFilter[field]) {
			delete enabledFeaturesFilter[field];
			delete disabledFeaturesFilter[field];
		} else {
			// Add field to the ones we want to add
			enabledFeaturesFilter[field] = LOCATION_LIST_COLUMNS[field];
		}

		await this.update({ enabledFeaturesFilter, disabledFeaturesFilter });
		await this.fetchLocationData();
	};

	onFilterColumnEnabledChange = async field => {
		let { enabledFeaturesFilter, disabledFeaturesFilter } = this.state;

		if (enabledFeaturesFilter[field]) {
			delete enabledFeaturesFilter[field];
			disabledFeaturesFilter[field] = LOCATION_LIST_COLUMNS[field];
		} else if (disabledFeaturesFilter[field]) {
			delete disabledFeaturesFilter[field];
			enabledFeaturesFilter[field] = LOCATION_LIST_COLUMNS[field];
		}

		await this.update({ enabledFeaturesFilter, disabledFeaturesFilter });
		await this.fetchLocationData();
	};

	/**
	 * Rendering methods
	 */

	renderRecord = recordData => {
		let { columns } = this.state;
		try {
			return Object.values(columns).map(column => {
				let value = recordData[column.sortField];

				if (typeof value === "string") {
					return value;
				}

				return value ? <Icon.Check /> : <Icon.X />;
			});
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	renderSearchBar = () => {
		let { locations } = this.state;

		return (
			<div className="fmll__search">
				<div className="fmll__search__section">
					<SearchInput placeholder="Search ..." onChange={this.onSearchChange} />
					{locations && <div className="fmll__search__section__count">Count: {locations.length}</div>}
				</div>

				<div className="fmll__search__actions">
					<Action key="configure-columns" id="configure-columns" label="Select columns to show" icon={Icon.Sliders} onClick={this.onOpenFiltersModal} />
					<Action key="filter-columns" id="filter-columns" label="Filter" icon={Icon.Filter} onClick={() => this.update({ showFilterModal: true })} />
				</div>
			</div>
		);
	};

	renderFilterData = () => {
		let { enabledFeaturesFilter, disabledFeaturesFilter } = this.state;

		let enabledFilters = Object.keys(enabledFeaturesFilter).length > 0;
		let disabledFilters = Object.keys(disabledFeaturesFilter).length > 0;

		return (
			<div className="fmll__filter-data">
				{(enabledFilters || disabledFilters) && <div className="fmll__filter-data__header">Search Filters</div>}
				{enabledFilters && (
					<div className="fmll__filter-data__enabled">
						<div>Enabled:</div>
						{Object.values(enabledFeaturesFilter).map(filter => (
							<span className="badge">{filter.value}</span>
						))}{" "}
					</div>
				)}
				{disabledFilters && (
					<div className="fmll__filter-data__disabled">
						<div>Disabled:</div>
						{Object.values(disabledFeaturesFilter).map(filter => (
							<span className="badge">{filter.value}</span>
						))}
					</div>
				)}
			</div>
		);
	};

	renderConfigureColumnsModal = () => {
		let { showColumnsModal, columns, columnSectionsExpanded, featureTypes } = this.state;

		return (
			<SideModal
				show={showColumnsModal}
				title="Configure Columns"
				titleIcon={Icon.Sliders}
				onHide={() => this.update({ showColumnsModal: false })}
				closeOnClick={true}
			>
				<div className="fmll__sm">
					{featureTypes &&
						Object.values(featureTypes).map((section, index) => (
							// For each section
							<div key={section.id} className="fmll__sm__section">
								{/* Show the section name, and toggle collapsable section */}
								<div
									className="fmll__sm__header"
									onClick={() => {
										columnSectionsExpanded[index] = !columnSectionsExpanded[index];
										this.update({ columnSectionsExpanded });
									}}
								>
									{section.display}
									{columnSectionsExpanded[index] ? <Icon.ChevronDown size={15} /> : <Icon.ChevronUp size={15} />}
								</div>
								{/* All the features of this type */}
								<Collapse in={columnSectionsExpanded[index]}>
									<div className="fmll__sm__columns">
										{section.fields.map(
											field =>
												// For each feature
												LOCATION_LIST_COLUMNS[field] && (
													<div key={field} className="fmll__sm__columns__column">
														<div className="fmll__sm__columns__column__checkbox">
															<Checkbox
																id={`${field}-config-checkbox`}
																name={field}
																checked={columns[field] ? true : false}
																onChange={() => this.onConfigColumnChange(field)}
															/>
														</div>
														<div className="fmll__sm__columns__column__label">{LOCATION_LIST_COLUMNS[field].value}</div>
													</div>
												)
										)}
									</div>
								</Collapse>
							</div>
						))}
				</div>
			</SideModal>
		);
	};

	renderFilterModal = () => {
		let { showFilterModal, filterSectionsExpanded, enabledFeaturesFilter, disabledFeaturesFilter, featureTypes } = this.state;

		return (
			<SideModal show={showFilterModal} title="Filter" titleIcon={Icon.Filter} onHide={() => this.update({ showFilterModal: false })} closeOnClick={true}>
				<div className="fmll__sm">
					{featureTypes &&
						Object.values(featureTypes).map((section, index) => (
							// For each section
							<div key={section.id} className="fmll__sm__section">
								{/* Show the section name, and toggle collapsable section */}
								<div
									className="fmll__sm__header"
									onClick={() => {
										filterSectionsExpanded[index] = !filterSectionsExpanded[index];
										this.update({ filterSectionsExpanded });
									}}
								>
									{section.display}
									{filterSectionsExpanded[index] ? <Icon.ChevronDown size={15} /> : <Icon.ChevronUp size={15} />}
								</div>
								{/* All the features of this type */}
								<Collapse in={filterSectionsExpanded[index]}>
									<div className="fmll__sm__columns">
										{section.fields.map(field => {
											if (field === LOCATION_LIST_COLUMNS.teamchat.id) {
												return null;
											}
											// If this field/feature is being filtered on
											let isFilter = enabledFeaturesFilter[field] || disabledFeaturesFilter[field];
											return (
												// For each feature
												<div key={field} className="fmll__sm__columns__column">
													<div className="fmll__sm__columns__column__checkbox">
														<Checkbox id={`${field}-filter-checkbox`} name={field} checked={isFilter} onChange={() => this.onFilterColumnChange(field)} />
													</div>
													<div className="fmll__sm__columns__column__label">{LOCATION_LIST_COLUMNS[field] ? LOCATION_LIST_COLUMNS[field].value : field}</div>
													{isFilter && (
														<select
															id={`${field}-filter-select`}
															name={field}
															className="fmll__sm__columns__column__select Common__input"
															options={FILTER_COLUMN_IS_ENABLED_OPTIONS}
															value={disabledFeaturesFilter[field] ? FILTER_COLUMN_IS_ENABLED_OPTIONS[1].value : FILTER_COLUMN_IS_ENABLED_OPTIONS[0].value}
															onChange={event => this.onFilterColumnEnabledChange(field, event.target.value)}
														>
															{FILTER_COLUMN_IS_ENABLED_OPTIONS.map(column => (
																<option key={column.value} value={column.value}>
																	{column.label}
																</option>
															))}
														</select>
													)}
												</div>
											);
										})}
									</div>
								</Collapse>
							</div>
						))}
				</div>
			</SideModal>
		);
	};

	render = () => {
		let { locations, loading, loadedAll, sortField, sortOrder } = this.state;

		return (
			<div className="fmll">
				{/* Search bar and action buttons */}
				{this.renderSearchBar()}

				{/* Filter criterea */}
				{this.renderFilterData()}

				{/* Select default column types to show */}
				<Filters filters={this.getFilters()} />
				{/* Render locations and location data */}
				<List
					items={locations}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={"No locations found..."}
				/>

				{/* Configure Columns Side Modal */}
				{this.renderConfigureColumnsModal()}
				{/* Filtering Side Modal */}
				{this.renderFilterModal()}
			</div>
		);
	};
}

export default LocationList;
