import React from "react";
import * as Icon from "react-feather";

import UtilityService from "../../../services/UtilityService";
import Action from "../Action";

class DragAndDropFiles extends React.Component {
	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onRemoveFile = index => {
		let { onRemoveFile } = this.props;

		if (onRemoveFile) {
			onRemoveFile(index);
		}
	};

	render = () => {
		let { files } = this.props;

		if (!files || files.length < 1) {
			return null;
		}

		return (
			<div className="dnd__drop-area__files">
				{files.map((file, index) => {
					let ext = UtilityService.getExtensionFromUrl(file.name);

					return (
						<div className="dnd__drop-area__files__file">
							<div className="dnd__drop-area__files__file__ext">{ext}</div>
							<div className="dnd__drop-area__files__file__name">{file.name}</div>
							<div className="dnd__drop-area__files__file__x" onClick={() => this.onRemoveFile(index)}>
								<Action id={`drop-area-file-${index}`} label="Remove File" icon={Icon.X} onClick={() => this.onRemoveFile(index)} />
							</div>
						</div>
					);
				})}
			</div>
		);
	};
}

export default DragAndDropFiles;
