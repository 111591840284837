export const LANGUAGES_DROPDOWN = [
	{
		value: "en_CA",
		label: "English (Canada)"
	},
	{
		value: "fr_CA",
		label: "French (Canada)"
	},
	{
		value: "en_US",
		label: "English (United States)"
	},
	{
		value: "es_US",
		label: "Spanish (United States)"
	},
	{
		value: "en_GB",
		label: "English (United Kingdom)"
	}
];

export const CODES_TO_LABEL = {
	en_CA: "English (Canada)",
	fr_CA: "French (Canada)",
	en_US: "English (United States)",
	es_US: "Spanish (United States)",
	en_GB: "English (United Kingdom)"
};

export const LANGUAGES = {
	enCA: "en_CA",
	frCA: "fr_CA",
	enUS: "en_US",
	esUS: "es_US",
	enGB: "en_GB"
};

export const MINI_FLAG_URL = "https://flagcdn.com/";

export const BUBBLE_ICONS = {
	phone: "phone",
	text: "text"
};
