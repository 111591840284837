import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

import "./ThreadAction.css";

class ThreadAction extends Component {
	onClick = async () => {
		let { onClickAction } = this.props;

		if (onClickAction) {
			await onClickAction();
		}
	};

	render() {
		let { id, icon, tooltip, show, isActive, tooltipPrefix } = this.props;

		if (!show) {
			return null;
		}

		if (!tooltipPrefix) {
			tooltipPrefix = "Disable";
		}

		if (isActive) {
			tooltip = `${tooltipPrefix} ${tooltip}`;
		}

		return (
			<>
				<div
					data-tip
					data-for={`${id}-tooltip`}
					id={id}
					className={`mb-customer-thread-tour-6 thread-action ${isActive ? "thread-action--active" : ""}`}
					onClick={this.onClick}
				>
					{icon}
				</div>
				<ReactTooltip id={`${id}-tooltip`} type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="top">
					{tooltip}
				</ReactTooltip>
			</>
		);
	}
}

export default ThreadAction;
