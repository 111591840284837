import React from "react";
import { withTranslation } from "react-i18next";

import ContactService from "../../services/ContactService";
import ToastService from "../../services/ToastService";

import Modal from "./DHModal";
import Checkbox from "./Checkbox";

import "../../styles/css/components/commons/download-contact-conversation.css";

class DownloadConversationModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			exporting: false,

			allMediums: true,
			mediums: this.props.mediums ? this.props.mediums : [],
			selectableMediums: ContactService.getDownloadableMediums(),
			allTime: true,
			startDate: "",
			endDate: ""
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = () => {
		this.update({
			loading: false,
			exporting: false,
			allMediums: true,
			mediums: this.props.mediums ? this.props.mediums : [],
			selectableMediums: ContactService.getDownloadableMediums(),
			allTime: true,
			startDate: "",
			endDate: ""
		});
	};

	onHide = () => {
		let { onHide } = this.props;

		this.resetComponent();

		if (onHide) {
			onHide();
		}
	};

	onMediumSelected = medium => {
		let { mediums } = this.state;
		mediums = mediums.slice();

		if (mediums.includes(medium.key)) {
			let index = mediums.indexOf(medium.key);
			if (index >= 0) {
				mediums.splice(index, 1);
			}
		} else {
			mediums.push(medium.key);
		}

		this.update({ mediums });
	};

	handleDateChange = event => {
		let name = event.target.name;
		let value = event.target.value;
		let { startDate, endDate } = this.state;
		if (name === "startDate") {
			startDate = value;
		}

		if (name === "endDate") {
			endDate = value;
		}

		if (name === "startDate" && !endDate) {
			endDate = value;
		}

		if (name === "endDate" && !startDate) {
			startDate = value;
		}

		this.update({ startDate, endDate });
	};

	onDownload = async () => {
		let { contactId, t } = this.props;
		let { allMediums, allTime, mediums, startDate, endDate, exporting } = this.state;

		if (!contactId) {
			return;
		}

		if (exporting) {
			return;
		}

		await this.update({ exporting: true });

		let output = await ContactService.downloadConversation({ contactId, allMediums, allTime, mediums, startDate, endDate });

		await this.update({ exporting: false });

		if (!output) {
			ToastService.error(t("Failed to queue export. Please try again."));
			return;
		}

		ToastService.info(t("Email will be sent to you shortly!"));
		this.onHide();
	};

	render = () => {
		let { show, t } = this.props;
		let { allMediums, allTime, exporting, mediums, selectableMediums, startDate, endDate } = this.state;

		return (
			<Modal show={show} onHide={this.onHide} title={t("Export Conversation")} className="dcc">
				<div className="modal__flex-container">
					<p>{t("Email will be sent with a pdf of the conversation.")}</p>
					<div>{t("Options")}:</div>
					<div className="modal__field__row">
						<div className="modal__field__row__item modal__field__row__item--checkbox">
							<Checkbox id="all-mediums" checked={allMediums} onChange={() => this.update({ allMediums: !allMediums })} />
						</div>
						<div className="modal__field__row__item">{t("All Mediums")}</div>
					</div>
					{!allMediums && (
						<div className="dcc__mediums">
							{selectableMediums.map(medium => (
								<div key={medium.key} className="dcc__mediums__medium">
									<div className="modal__field__row__item modal__field__row__item--checkbox">
										<Checkbox id={medium.key} checked={mediums.includes(medium.key)} onChange={() => this.onMediumSelected(medium)} />
									</div>
									<div className="modal__field__row__item">{medium.value}</div>
								</div>
							))}
						</div>
					)}

					<div className="dcc__all-time modal__field__row">
						<div className="modal__field__row__item modal__field__row__item--checkbox">
							<Checkbox id="all-time" checked={allTime} onChange={() => this.update({ allTime: !allTime })} />
						</div>
						<div className="modal__field__row__item">{t("All Time History")}</div>
					</div>

					{!allTime && (
						<div className="dcc__dates">
							<div className="modal__field">{t("Start Date")}</div>
							<input
								name="startDate"
								id="startDate"
								type="date"
								onChange={e => this.handleDateChange(e)}
								value={startDate}
								max={endDate}
								className="form-control Common__input"
								autoComplete="off"
							/>
							<div className="modal__field">{t("End Date")}</div>
							<input
								name="endDate"
								id="endDate"
								type="date"
								onChange={e => this.handleDateChange(e)}
								value={endDate}
								className="form-control Common__input"
								min={startDate}
								autoComplete="off"
							/>
						</div>
					)}

					<div className="modal__actions">
						<div className={`mb-button ${exporting ? "mb-button--disabled" : ""}`} onClick={this.onDownload}>
							{exporting ? t("Exporting...") : t("Export")}
						</div>
					</div>
				</div>
			</Modal>
		);
	};
}

export default withTranslation(null, { withRef: true })(DownloadConversationModal);
