import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Spinners from "../../components/common/Spinners";
import TotalReviews from "../Dashboard/TotalReviews";
import ReviewGoal from "../Dashboard/ReviewGoal";
import AverageRating from "../Dashboard/AverageRating";
import TotalReplies from "../Dashboard/TotalReplies";
import withLocation from "../../components/common/WithLocation";
import ReviewsBySite from "./Location/ReviewsBySite";
import RatingsBySite from "./Location/RatingsBySite";
import DashboardCard from "../Dashboard/DashboardCard";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import { AnalyticsService } from "../../services/AnalyticsService";
import GmbService from "../../services/GmbService";

import { DASHBOARD_CARDS } from "../../constants/Dashboard";

import "../../styles/css/scenes/analytics.css";
import "../../App.css";

class AnalyticsSummary extends Component {
	state = {
		overallRatingsObject: { reviews: [] },
		loading: true,
		fbActive: false,
		gmbActive: false
	};

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	async resetComponent() {
		try {
			const locationId = UserService.getActiveLocation().id;

			const ratingsAndReviews = await AnalyticsService.getOverallRatingAndReviews({ locationId });
			this.setState({ overallRatingsObject: ratingsAndReviews });

			const gmbAndFbStatus = await GmbService.checkGmbAndFbStatus(locationId);

			this.setState({
				fbActive: gmbAndFbStatus.fb_active,
				gmbActive: gmbAndFbStatus.gmb_active
			});
		} catch (error) {
			console.log(error);
		}
		this.setState({
			loading: false
		});
	}

	onLocationChanged = () => {
		this.resetComponent();
	};

	render() {
		if (this.state.loading) {
			return (
				<div className="Common__spinnerdiv--center">
					<Spinners loading={true} type="tail-fade" size="120px" />
				</div>
			);
		}

		const { overallRatingsObject } = this.state;
		return (
			<div>
				<div className="row wrapper white-bg page-heading">
					<div className="col-sm-6 col-md-8 col-lg-9">
						<h2>
							Analytics<span className="hidden-xxs"> - {UserService.getActiveLocation() ? UserService.getActiveLocation().name : ""}</span>
						</h2>
					</div>
				</div>
				{!overallRatingsObject ? (
					<div className="wrapper wrapper-content border-bottom">
						<div className="ibox">
							<div className="row border-bottom">
								<div className="col-sm-12">
									<div className="ibox-content">
										<h2>
											Sorry, no was data found{" "}
											<span role="img" aria-label="sad-face">
												😞
											</span>
										</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div>
						<br />
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-12">
								<DashboardCard className={DASHBOARD_CARDS.averageRating.className} height={DASHBOARD_CARDS.averageRating.h}>
									<AverageRating isEnabled={() => true} isPermissible={() => UserService.get().GroupPermission.view_reviews} />
								</DashboardCard>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12">
								<DashboardCard className={DASHBOARD_CARDS.reviewGoalThisMonth.className} height={DASHBOARD_CARDS.reviewGoalThisMonth.h}>
									<ReviewGoal isEnabled={() => true} isPermissible={() => UserService.get().GroupPermission.view_reviews} />
								</DashboardCard>
							</div>
						</div>
						<br></br>
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-12">
								<DashboardCard className={DASHBOARD_CARDS.totalReviews.className} height={DASHBOARD_CARDS.totalReviews.h}>
									<TotalReviews isEnabled={() => true} isPermissible={() => UserService.get().GroupPermission.view_reviews} />
								</DashboardCard>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12">
								<DashboardCard className={DASHBOARD_CARDS.totalReplies.className} height={DASHBOARD_CARDS.totalReplies.h}>
									<TotalReplies isEnabled={() => true} isPermissible={() => UserService.get().GroupPermission.view_reviews} />
								</DashboardCard>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 col-sm-12 Analytics__ibox-content">
								<DashboardCard className={DASHBOARD_CARDS.reviewsBySite.className} height={DASHBOARD_CARDS.reviewsBySite.h}>
									<ReviewsBySite isEnabled={() => true} isPermissible={() => UserService.get().GroupPermission.view_reviews} />
								</DashboardCard>
							</div>
							<div className="col-md-6 col-sm-12 Analytics__ibox-content AnalyticsSummary__ibox-content--margin">
								<DashboardCard className={DASHBOARD_CARDS.ratingsBySite.className} height={DASHBOARD_CARDS.ratingsBySite.h}>
									<RatingsBySite isEnabled={() => true} isPermissible={() => UserService.get().GroupPermission.view_reviews} />
								</DashboardCard>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(withLocation(AnalyticsSummary));
