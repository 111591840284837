import React from "react";

import "../../../styles/css/scenes/feature-summary.css";
import { AnalyticsService } from "../../../services/AnalyticsService";
import LocationService from "../../../services/LocationService";
import { LOCATION_LIST_COLUMNS } from "../../../constants/FeatureMatrix";

class FeatureSummary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeLocations: 0,
			featureCounts: null,
			featureTypes: null,
			locationFeaturesConfig: {}
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.fetchConfig();
		let matrixConfig = await AnalyticsService.fetchFeatureMatrixConfig();

		await this.update({
			activeLocations: 0,
			featureCounts: null,
			featureTypes: matrixConfig.feature_types || null
		});

		await this.fetchFeatureSummary();
	};

	fetchFeatureSummary = async () => {
		try {
			let data = await AnalyticsService.fetchCSFeatureSummary();
			this.update({
				featureCounts: data.featureCounts,
				activeLocations: data.activeLocations
			});
		} catch (error) {
			console.log(error);
		}
	};

	fetchConfig = async () => {
		let config = await LocationService.getLocationFeaturesConfig();
		if (!config) {
			return;
		}

		let configFeatures = config.features;

		// Add specific thing for teamchat since it is not a location feature but is company wide
		configFeatures[LOCATION_LIST_COLUMNS.teamchat.id] = { id: LOCATION_LIST_COLUMNS.teamchat.id, name: "Messenger - Teamchat" };

		this.update({
			locationFeaturesConfig: configFeatures
		});
	};

	render = () => {
		let { activeLocations, featureCounts, featureTypes, locationFeaturesConfig } = this.state;

		return (
			<div className="fms">
				{featureTypes &&
					featureCounts &&
					Object.values(featureTypes).map((section, index) => (
						<div key={section.display}>
							<div className="fms__section-header">{section.display}</div>
							<div className="fms__cards">
								{section.fields.map(field => {
									if (typeof featureCounts[field] === "undefined") {
										console.log(`Field '${field}' not found`);
										return null;
									}

									return (
										<div key={field} className="Common__card fms__cards__card">
											<div className="fms__cards__card__number">{featureCounts[field]}</div>
											<div className="fms__cards__card__feature">{locationFeaturesConfig[field] ? locationFeaturesConfig[field].name : field}</div>
											<div className="fms__cards__card__percentage dh-tip" tip={`Total ${activeLocations} active locations`}>
												{((featureCounts[field] / activeLocations) * 100).toFixed(1)}% of locations
											</div>
										</div>
									);
								})}
							</div>
						</div>
					))}
			</div>
		);
	};
}

export default FeatureSummary;
