import i18n from "../i18n/config";
import moment from "moment";
import UserService from "./UserService";
import { CODES_TO_LABEL } from "../constants/LanguageConstants";

const TranslationService = {
	/**
	 * Changes the language of the app
	 *
	 * @param {String} language ISO 3166-1 alpha-2 (en_US, en_CA, etc)
	 * @return {Boolean}
	 */
	setLanguage: function(language) {
		try {
			let currentlySetLanguage = this.getCurrentlySetLanguage();

			// Check if the requested language is already set
			if (language === currentlySetLanguage) {
				return true;
			}

			// Extract the language code for moment.js
			const languageCode = language.split("_")[0]; // ex: en, es

			// Use getAvailableLanguages to check if the language is available
			const availableLanguages = this.getAvailableLanguages();

			if (!availableLanguages.includes(language)) {
				console.log(`TranslationService: Unable to find language ${language} in config.`);
				return false;
			}

			// Change the i18n language
			i18n.changeLanguage(language);

			// Change the moment langauge using the 2 letter code
			moment.locale(languageCode);

			console.log(`TranslationService: Language changed from ${currentlySetLanguage} to ${language}.`);

			return true;
		} catch (error) {
			console.error(error);
			return false;
		}
	},

	/**
	 * Get a list of all avaliable languages in i18n
	 * See i18n config's resources
	 * @returns {Array} ex: ["en_US", "en_CA"]
	 */
	getAvailableLanguages: function() {
		try {
			// Return the keys of the resources object
			return Object.keys(i18n.options.resources);
		} catch (error) {
			console.error(error);
		}
		return [];
	},

	/**
	 * Returns the currently set langauge
	 *
	 * See i18n config's resources
	 * @returns {String} "en_US"
	 */
	getCurrentlySetLanguage: function() {
		try {
			return i18n.language;
		} catch (error) {
			console.error(error);
		}
	},

	/**
	 * Gets the language on the active location
	 *
	 * @returns {string}
	 */
	getLocationLanguage: function() {
		try {
			let location = UserService.getActiveLocation();
			return location.language;
		} catch (error) {
			console.error(error);
		}
	},

	/**
	 * Gets all the supported languages on the location but falls back to
	 * the active locations langugage in case
	 *
	 * @returns {Array}
	 */
	getSupportedLanguagesOptions: function() {
		let locationLanguage = this.getLocationLanguage();

		try {
			let location = UserService.getActiveLocation();
			let supportedLanguages = JSON.parse(location.supported_languages);
			let supportedLanguageOptions = [];

			for (let i = 0; i < supportedLanguages.length; i++) {
				let currentLanguage = supportedLanguages[i];
				supportedLanguageOptions.push({ value: currentLanguage, label: CODES_TO_LABEL[currentLanguage] });
			}

			return supportedLanguageOptions;
		} catch (error) {
			console.error(error);
		}

		// Fall back to just our location language
		return [{ value: locationLanguage, label: CODES_TO_LABEL[locationLanguage] }];
	}
};

export default TranslationService;
