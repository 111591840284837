import React, { Component } from "react";
import * as Icon from "react-feather";
import moment from "moment";
import { withTranslation } from "react-i18next";

import List from "../../components/common/List";

import UserService from "../../services/UserService";
import UtilityService from "../../services/UtilityService";

import { SORT_ORDER } from "../../constants/CommonConstants";
import { SESSIONS_COLUMNS } from "../../constants/Users";

class UserSessions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			loadedAll: true,
			data: [],
			sortField: SESSIONS_COLUMNS.updated_at.id,
			sortOrder: SORT_ORDER.desc,
			limitDefault: 25,
			limit: 25,
			pageSize: 50
		};
	}

	componentDidMount = async () => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.update({ loading: true });
		await this.fetchData();
		await this.update({ loading: false });
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	fetchData = async () => {
		let userId = this.props.match.params.userId;
		let { limit, sortField, sortOrder } = this.state;

		await this.update({ loading: true });

		let data = await UserService.fetchSessions({
			userId,
			sortField,
			sortOrder,
			limit
		});

		await this.update({
			data,
			loading: false,
			loadedAll: data && data.length < limit
		});
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchData();
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ loading: true, sortField, sortOrder });
		await this.fetchData();
		await this.update({ loading: false });
	};

	getHeaders = () => {
		let headers = {
			items: SESSIONS_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	};

	renderRecord = recordData => {
		let updatedAt = recordData.updated_at ? moment(recordData.updated_at).format("MMMM Do YYYY, h:mm A") : "";
		let createdAt = recordData.created_at ? moment(recordData.created_at).format("MMMM Do YYYY, h:mm A") : "";

		let timeElapsed = "";
		if (recordData.updated_at && recordData.created_at) {
			timeElapsed = UtilityService.getFormattedTimeElapsed(recordData.created_at, recordData.updated_at);
		}

		return [recordData.origin, recordData.usage_count, timeElapsed, updatedAt, createdAt];
	};

	render() {
		let { data, loading, loadedAll, sortField, sortOrder } = this.state;
		let { t } = this.props;

		return (
			<List
				items={data}
				loading={loading}
				loadedAll={loadedAll}
				sortField={sortField}
				sortOrder={sortOrder}
				headers={this.getHeaders()}
				renderRecord={this.renderRecord}
				onRecordClicked={() => {}}
				onLoadMore={this.onLoadMore}
				noDataTitle={t("Sessions not found...")}
				noDataIcon={<Icon.AlertCircle />}
			/>
		);
	}
}

export default withTranslation(null, { withRef: true })(UserSessions);
