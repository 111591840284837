import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { AnalyticsService } from "../../services/AnalyticsService";

import Modal from "../../components/common/DHModal";

class LaggingLocationDetailsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			locationId: this.props.locationId,
			laggingLocation: null,
			reviewsReceivedLastDays: 7,
			invitesLastDays: 7,
			avgInvitesPerDay: 7,
			avgInvitesPerWeek: 60,
			avgMessagesPerDay: 7,
			selectedReviewsReceivedLastDays: 7,
			selectedInvitesLastDays: 7,
			selectedAvgInvitesPerDay: 7,
			selectedAvgInvitesPerWeek: 60,
			selectedAvgMessagesPerDay: 7,
			errorLoading: false
		};
	}

	componentWillUpdate = nextProps => {
		if (JSON.stringify(this.props.locationId) !== JSON.stringify(nextProps.locationId)) {
			this.setState({ locationId: nextProps.locationId }, () => {
				this.fetchDetails();
			});
		}
	};

	fetchDetails = async () => {
		try {
			let data = {
				locationId: this.state.locationId,
				lastReviewdInXDays: this.state.selectedReviewsReceivedLastDays,
				invitesSentInXDays: this.state.selectedInvitesLastDays,
				avgInvitesPerDayOverXDays: this.state.selectedAvgInvitesPerDay,
				avgInvitesPerWeekOverXDays: this.state.selectedAvgInvitesPerWeek,
				avgMessagesPerDayOverXDays: this.state.selectedAvgMessagesPerDay
			};

			let laggingLocation = await AnalyticsService.fetchCSLaggingLocationDetails(data);

			this.setState({ laggingLocation });
		} catch (error) {
			this.setState({ errorLoading: true });
			console.log(error);
		}
	};

	onHide = () => {
		if (this.props.close) {
			this.props.close();
		}

		this.setState({ errorLoading: false });
	};

	render() {
		let { showModal, locationName, t } = this.props;
		let { laggingLocation, errorLoading } = this.state;

		return (
			<Modal title={<span key={locationName}>{locationName}</span>} show={showModal} onHide={this.onHide}>
				{laggingLocation ? (
					<div>
						<table className="dh-table table table-striped table-hover">
							<thead>
								<tr>
									<th>{t("Reviews Received")}</th>
									<th>{t("Invites")}</th>
									<th>{t("Invites/Day (Avg)")}</th>
									<th>{t("Invites/Week (Avg)")}</th>
									<th>{t("Messages/Day (Avg)")}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<select
											className="form-control"
											onChange={event => {
												let value = event.target.value;
												if (!(value === "last_month" || value === "all_time")) {
													value = parseInt(value, 10);
												}
												this.setState(
													{
														selectedReviewsReceivedLastDays: value
													},
													() => {
														this.fetchDetails();
													}
												);
											}}
											style={{ width: "100%" }}
										>
											<option value="7">{t("Last 7 days")}</option>
											<option value="14">{t("Last 14 days")}</option>
											<option value="21">{t("Last 21 days")}</option>
											<option value="last_month">{t("Last Month")}</option>
											<option value="all_time">{t("All Time")}</option>
										</select>
									</td>
									<td>
										<select
											className="form-control"
											onChange={event => {
												let value = event.target.value;
												if (!(value === "last_month" || value === "all_time")) {
													value = parseInt(value, 10);
												}
												this.setState({ selectedInvitesLastDays: value }, () => {
													this.fetchDetails();
												});
											}}
											style={{ width: "100%" }}
										>
											<option value="7">{t("Last 7 days")}</option>
											<option value="14">{t("Last 14 days")}</option>
											<option value="21">{t("Last 21 days")}</option>
											<option value="last_month">{t("Last Month")}</option>
											<option value="all_time">{t("All Time")}</option>
										</select>
									</td>
									<td>
										<select
											className="form-control"
											onChange={event => {
												this.setState({ selectedAvgInvitesPerDay: parseInt(event.target.value, 10) }, () => {
													this.fetchDetails();
												});
											}}
											style={{ width: "100%" }}
										>
											<option value="7">{t("Last 7 days")}</option>
											<option value="14">{t("Last 14 days")}</option>
											<option value="21">{t("Last 21 days")}</option>
											<option value="60">{t("Last 60 days")}</option>
										</select>
									</td>
									<td>
										<select
											className="form-control"
											onChange={event => {
												this.setState({ selectedAvgInvitesPerWeek: parseInt(event.target.value, 10) }, () => {
													this.fetchDetails();
												});
											}}
											style={{ width: "100%" }}
										>
											<option value="60">{t("Last 60 days")}</option>
										</select>
									</td>
									<td>
										<select
											className="form-control"
											onChange={event => {
												this.setState({ selectedAvgMessagesPerDay: parseInt(event.target.value, 10) }, () => {
													this.fetchDetails();
												});
											}}
											style={{ width: "100%" }}
										>
											<option value="7">{t("Last 7 days")}</option>
											<option value="14">{t("Last 14 days")}</option>
											<option value="21">{t("Last 21 days")}</option>
											<option value="60">{t("Last 60 days")}</option>
										</select>
									</td>
								</tr>
								<tr>
									<td>{laggingLocation.reviews_received}</td>
									<td>{laggingLocation.review_request_count}</td>
									<td>{laggingLocation.avg_review_request_per_day}</td>
									<td>{laggingLocation.avg_review_request_per_week}</td>
									<td>{laggingLocation.avg_messages_count_per_day}</td>
								</tr>
							</tbody>
						</table>
					</div>
				) : errorLoading ? (
					<div className="text-center">{t("There was an error loading the details for this location. Please try again.")}</div>
				) : (
					<div className="text-center">{t("Loading...")}</div>
				)}
			</Modal>
		);
	}
}

export default withTranslation(null, { withRef: true })(LaggingLocationDetailsModal);
