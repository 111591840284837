import React, { Component } from "react";
import { CSVLink } from "react-csv";
import * as Icon from "react-feather";
import { Tooltip, OverlayTrigger, Table } from "react-bootstrap";

import Checkbox from "../../components/common/Checkbox";

import CSVParsingService from "../../services/CSVParsingService";

import { SAMPLE_CSV_DATA, BULK_TYPES } from "../../constants/BulkActionsConstants";
import DragAndDropOverlay from "../../components/common/DragAndDropOverlay/DragAndDropOverlay";
import DragAndDropFiles from "../../components/common/DragAndDropOverlay/DragAndDropFiles";

class BulkUploadCsv extends Component {
	constructor(props) {
		super(props);

		this.state = {
			bulkType: this.props.bulkType ? this.props.bulkType : BULK_TYPES.reviewRequest.id,
			header: this.props.header ? this.props.header : "Upload a list of individuals or businesses that you would like to contact.",
			loading: true,
			show: typeof this.props.show !== "undefined" ? this.props.show : true,
			defaultReviewTemplate: 0,
			csvFile: this.props.csvFile,
			isValidCSVfile: false,
			invalidCsvFileMessage: "",
			csvData: this.props.csvData,
			csvString: "",
			csvHasHeader: typeof this.props.csvHasHeader !== "undefined" ? this.props.csvHasHeader : false,
			originalCsvData: [],
			previewLines: this.props.previewLines || 10,
			bulkLimit: -1,
			showLimitMessage: this.props.showLimitMessage || false,
			limitMessage: this.props.limitMessage || "",
			showColumnMismatchWarning: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		this.handleOnCSVUpdate(this.props.csvFile);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.show !== this.props.show) {
			this.setState({ show: this.props.show });
		}
		if (prevProps.csvData !== this.props.csvData) {
			this.setState({ csvData: this.props.csvData });
		}
		if (prevProps.csvFile !== this.props.csvFile) {
			this.setState({ csvFile: this.props.csvFile, isValidCSVfile: true });
		}
	}

	onHasHeaderChange(value) {
		this.setState({ csvHasHeader: value });
	}

	handleOnCSVUpdate = async fileItems => {
		// no file uploaded
		if (fileItems.length <= 0) {
			this.setState({
				csvFile: [],
				isValidCSVfile: false,
				invalidCsvFileMessage: "",
				csvHasHeader: false,
				csvData: []
			});
			return;
		}
		// empty file or no file object found
		if (!fileItems[0] || (fileItems[0].file && fileItems[0].file.size === 0)) {
			this.setState({
				csvFile: [],
				csvData: [],
				isValidCSVfile: false,
				invalidCsvFileMessage: "Empty file uploaded."
			});
			return;
		}

		this.resetAllStates();

		await this.update({
			csvFile: fileItems.map(fileItem => fileItem),
			/**
			 * The following check is done for windows platform as csv created from
			 * linux/unix or notepad or VS code has empty file type value on windows.
			 * Disabling file type check until further notice
			 */
			// isValidCSVfile: CSV_FILE_TYPES.includes(fileItems[0].file.type) || (fileItems[0].file.type === "" && fileItems[0].file.name.endsWith(".csv")),
			// showInvalidFileErrorMsg: !CSV_FILE_TYPES.includes(fileItems[0].file.type) && !(fileItems[0].file.type === "" && fileItems[0].file.name.endsWith(".csv"))
			isValidCSVfile: true
		});
		if (this.state.isValidCSVfile) {
			var fr = new FileReader();
			var csvinput = this.state.csvFile[0];
			if (csvinput) {
				fr.readAsText(csvinput);
				fr.onload = () => {
					this.parseCsvPreview(fr.result);
				};
			}
		}
	};

	parseCsvPreview = async csvString => {
		try {
			let { csvHasHeader } = this.state;
			let parseParams = {
				noheader: !csvHasHeader,
				output: "csv"
			};
			let csvData = await CSVParsingService.parseCSVString(parseParams, csvString);

			if (!csvData || csvData.length < 1) {
				this.setState({
					isValidCSVfile: false,
					invalidCsvFileMessage: "No rows found in csv."
				});
				return;
			}

			this.setState({
				csvData: csvData,
				csvString: csvString,
				originalCsvData: csvData
			});

			let showColumnMismatchWarning = false;

			for (let i = 0; i < csvData.length; i++) {
				const row = csvData[i];
				if (i === csvData.length - 1) {
					continue;
				}

				const nextRow = csvData[i + 1];
				if (row.length !== nextRow.length) {
					showColumnMismatchWarning = true;
					break;
				}
			}

			this.setState({
				showColumnMismatchWarning
			});

			return { csvData: csvData, csvString: csvString, csvHasHeader: csvHasHeader, showColumnMismatchWarning };
		} catch (error) {
			console.log(error.stack);
		}
	};

	async resetAllStates() {
		await this.update({
			isValidCSVfile: false,
			invalidCsvFileMessage: "",
			originalCsvData: []
		});
	}

	render() {
		const { header, showLimitMessage, csvData, isValidCSVfile, invalidCsvFileMessage, showColumnMismatchWarning } = this.state;
		let { limitMessage } = this.props;

		const tooltip = (
			<Tooltip id="bulkUploading-header-info" title="CSV Header Infographic" className="invites-bulkUploading-header-info">
				<img className="invites-bulkUploading-header-infographic" src="/img/icons/png/headerinfo.png" alt="Not a header row example" />
				<img className="invites-bulkUploading-header-infographic" src="/img/icons/png/headerinfo2.png" alt="Header row example" />
			</Tooltip>
		);
		return (
			<div>
				<div className="text-center">
					<p>
						<strong>{header}</strong>
					</p>
					<p>
						Limit of 1 CSV file per upload. <br /> Up to 2,000 contacts can be uploaded at a time.
					</p>
					{showLimitMessage && <p>{limitMessage ? limitMessage : ""}</p>}
				</div>
				{showColumnMismatchWarning && (
					<div className="text-center bulk-actions__csv-upload__warning">
						Warning: Not all rows have the same amount of columns. There could be some issue with the csv.
					</div>
				)}
				<div>
					<CSVLink data={SAMPLE_CSV_DATA} filename={"sample.csv"} target="_self">
						<div>
							Sample CSV <Icon.Download size="15" />
						</div>
					</CSVLink>
				</div>
				<DragAndDropOverlay onFileAdded={this.handleOnCSVUpdate} accept={{ "application/csv": [], "text/csv": [], "text/plain": [] }}>
					{this.state.csvFile && this.state.csvFile.length > 0 && (
						<DragAndDropFiles files={this.state.csvFile} onRemoveFile={() => this.handleOnCSVUpdate([])} />
					)}
				</DragAndDropOverlay>
				{!isValidCSVfile && invalidCsvFileMessage && invalidCsvFileMessage.length > 0 && <div className="text-danger">{invalidCsvFileMessage}</div>}
				{isValidCSVfile && (
					<div>
						<div className="invites-bulkUploading-preview" ref={el => (this.uploadPreview = el)}>
							<div>
								<div>The first 2 rows from your CSV File:</div>
								<br />
								<Table className="invites-bulkUploading-preview-rows">
									<tbody>
										{csvData.map((row, i) =>
											i < 2 ? (
												<tr key={i}>
													<td key={i}>{i + 1}</td>
													{row.map((item, index) => {
														return <td key={i + index}>{item}</td>;
													})}
												</tr>
											) : null
										)}
									</tbody>
								</Table>
								<br />
							</div>
						</div>
						<div className="invites-bulkUploading-header" ref={el => (this.csvHeaderSelector = el)}>
							<Checkbox
								id="header-row-checkbox"
								name="csvHasHeader"
								checked={this.state.csvHasHeader}
								display="inline-block"
								onChange={() => this.onHasHeaderChange(!this.state.csvHasHeader)}
							/>
							<span name="csvHasHeader" onClick={() => this.onHasHeaderChange(!this.state.csvHasHeader)} className="invites-bulkUploading-header__text">
								Is row 1 a header row?
							</span>
						</div>
						<div>
							<OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={tooltip}>
								<span className="text-success invites-bulkUploading-header-help-text">What is a header row?</span>
							</OverlayTrigger>
						</div>
					</div>
				)}
				<div className="invites-bulkUploading-buttons">
					<div
						className="mb-button mb-button--fit invites--cancelUpload-button"
						onClick={() => {
							if (this.props.onCancel) {
								this.props.onCancel();
							}
						}}
						style={{ width: "auto" }}
					>
						Cancel
					</div>
					<div
						className={`mb-button mb-button--fit ${!isValidCSVfile ? "mb-button--disabled" : ""} invites--confirmUpload-button`}
						onClick={async () => {
							if (!isValidCSVfile) {
								return;
							}
							let { csvData: newCsvData, csvHasHeader, csvString } = await this.parseCsvPreview(this.state.csvString); //change the parsed preview, in case has header was selected

							if (this.props.updateCsvData) {
								this.props.updateCsvData(newCsvData, csvHasHeader, csvString);
							}
							if (this.props.setCsvFile) {
								this.props.setCsvFile(this.state.csvFile);
							}
							if (this.props.next) {
								this.props.next();
							}
						}}
						style={{ width: "auto" }}
					>
						Next
					</div>
				</div>
			</div>
		);
	}
}

export default BulkUploadCsv;
