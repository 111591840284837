import UserService from "./UserService";
import Kichiri from "./KichiriService";

import { SHORT_LINK_TYPES } from "../constants/ShortLinksConstants";

const ShortLinkService = {
	/**
	 * Fetch short links for a location
	 *
	 * @param {Integer} locationId
	 * @param {String} type
	 * @param {Boolean} includeExpired
	 * @param {String} searchTerm
	 * @param {String} sortField
	 * @param {String} sortOrder
	 * @param {Integer} limit
	 *
	 * @returns {Promise/Array} List of Short Links
	 */
	async fetch({ locationId, type, includeExpired, searchTerm, sortField, sortOrder, limit }) {
		try {
			const response = await Kichiri.short_links.fetch(
				{},
				{
					locationId,
					type,
					includeExpired,
					searchTerm,
					sortField,
					sortOrder,
					limit
				},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return [];
	},

	/**
	 * Fetch a short link
	 *
	 * @param {Integer} id
	 * @param {Integer} locationId
	 *
	 * @returns {Promise/Object} Short Link
	 */
	async fetchShortLink({ id, locationId }) {
		try {
			const response = await Kichiri.short_links.fetchShortLink({ shortLinkId: id }, { locationId }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return [];
	},

	/**
	 * Create a short link
	 *
	 * @param {Integer} locationId
	 * @param {String} longUrl
	 * @param {String} name
	 * @param {String} expireAt
	 * @param {String} password
	 * @returns {Promise/Object} Short Link
	 */
	async create({ locationId, longUrl, name, expireAt, password }) {
		try {
			const response = await Kichiri.short_links.createShortLink(
				{
					locationId,
					longUrl,
					type: SHORT_LINK_TYPES.custom,
					name,
					expireAt,
					password
				},
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a short link
	 *
	 * @param {Integer} id
	 * @param {Integer} locationId
	 * @param {String} longUrl
	 * @param {String} name
	 * @param {String} expireAt
	 * @param {String} password
	 * @param {String} status
	 *
	 * @returns {Promise/Object} Short Link
	 */
	async update({ id, locationId, longUrl, name, expireAt, password, status }) {
		try {
			const response = await Kichiri.short_links.updateShortLink(
				{
					id,
					locationId,
					longUrl,
					name,
					expireAt,
					password,
					status
				},
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	}
};

export default ShortLinkService;
