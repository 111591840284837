import React, { Component } from "react";
import LightboxService from "../../services/LightboxService";
import DHLightbox from "./DHLightbox";

import "react-image-lightbox/style.css";

class LightboxContainer extends Component {
	constructor(props) {
		super(props);

		this.lightboxComponent = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		LightboxService.storeReference(this.lightboxComponent);
	}

	render() {
		return <DHLightbox ref={ref => (this.lightboxComponent = ref)} />;
	}
}

export default LightboxContainer;
