import React, { Component } from "react";
import * as Icon from "react-feather";
import { withRouter } from "react-router-dom";
import moment from "moment";
import ContentLoader from "react-content-loader";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import TagService from "../../services/TagService";
import ToastService from "../../services/ToastService";
import GAService from "../../services/GAService";

import List from "../../components/common/List";
import Action from "../../components/common/Action";
import SearchInput from "../../components/common/SearchInput";
import Alert from "../../components/common/Alert";
import withLocation from "../../components/common/WithLocation";

import UpdateTagsModal from "./UpdateTagsModal";

import { SORT_ORDER } from "../../constants/CommonConstants";
import { TAG_COLUMNS } from "../../constants/Tags";

class Tags extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tags: [],
			loading: true,
			selectedTag: null,
			createMode: false,
			searchTerm: "",
			showUpdateModal: false,
			successSubmit: false,
			successText: "",
			errorSubmit: false,
			errorText: "",
			limit: 25,
			pageSize: 50,
			sortField: TAG_COLUMNS.name.id,
			sortOrder: SORT_ORDER.asc,
			deleteConfirmModal: false,
			deleteConfirmModalLoading: false,
			warningData: {}
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.updateTags();
	}

	onLocationChanged = () => {
		this.updateTags();
	};

	updateTags = async () => {
		let { searchTerm, sortField, sortOrder, limit } = this.state;
		let { t } = this.props;

		let locationId = UserService.getActiveLocation().id;
		let tags = await TagService.getTags(locationId, { searchTerm, sortField, sortOrder, limit });

		if (!tags) {
			ToastService.info(t("An error occurred trying to get tags."));
			return;
		}

		this.setState({
			tags: tags ? tags : [],
			loadedAll: tags.length < limit
		});
	};

	onUpdateModalHide = () => {
		this.setState({ showUpdateModal: false });
	};

	handleGeneralSearchOnChange = async value => {
		const searchTerm = value;
		await this.update({ searchTerm });
		await this.updateTags();
	};

	onUpdateModalSubmit = async (createMode, id, name) => {
		let { t } = this.props;

		let updateObj = {};

		this.onUpdateModalHide();

		if (createMode) {
			let locationId = UserService.getActiveLocation().id;
			let response = await TagService.createTag(locationId, name);
			if (!response) {
				updateObj.errorSubmit = true;
				updateObj.errorText = t(`Error trying to create tag`);
				this.setState(updateObj);
				return;
			}
			updateObj.successSubmit = true;
			updateObj.successText = t(`Successfully created tag`);
			this.setState(updateObj);
			this.updateTags();
			return;
		}

		let response = await TagService.updateTag(id, name);
		if (!response) {
			updateObj.errorSubmit = true;
			updateObj.errorText = t(`Error trying to update tag`);
			this.setState(updateObj);
			return;
		}
		updateObj.successSubmit = true;
		updateObj.successText = t(`Successfully updated tag`);

		this.updateTags();
		this.setState(updateObj);
	};

	onConfirmDelete = async recordData => {
		await this.update({ deleteConfirmModal: true, selectedTag: recordData, deleteConfirmModalLoading: true });
		let data = await TagService.getTagUsageData(recordData.id);
		await this.update({ deleteConfirmModalLoading: false, warningData: data });
	};

	onCloseConfirmDelete = confirmed => {
		if (confirmed) {
			this.onUpdateModalDelete();
		}
		this.update({ deleteConfirmModal: false });
	};

	onUpdateModalDelete = async () => {
		let { selectedTag } = this.state;
		let { t } = this.props;

		let response = await TagService.deleteTag(selectedTag.id);
		if (!response) {
			this.setState({
				errorSubmit: true,
				errorText: t(`Error trying to delete tag`),
				showUpdateModal: false,
				selectedTag: null
			});
			return;
		}
		this.setState({
			successSubmit: true,
			successText: t(`Successfully deleted tag`),
			showUpdateModal: false,
			selectedTag: null
		});
		this.updateTags();
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.updateTags();
	};

	openTagModal = async () => {
		await this.update({ createMode: true, selectedTag: undefined });
		await this.update({ showUpdateModal: true });
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ loading: true, sortField, sortOrder });
		await this.updateTags();
		await this.update({ loading: false });
	};

	getHeaders = () => {
		let columns = TAG_COLUMNS;

		let headers = {
			items: columns,
			sortBy: this.sortBy
		};

		return headers;
	};

	renderRecord = recordData => {
		try {
			let { t } = this.props;

			let user = UserService.get();

			return [
				recordData.name,
				recordData.created_at ? moment(recordData.created_at).format("MMM Do YYYY, h:mm a") : "",
				recordData.updated_at ? moment(recordData.updated_at).format("MMM Do YYYY, h:mm a") : "",
				<div className="dh-list__actions">
					{user.GroupPermission.update_tags && (
						<Action
							id={`edit-tag-${recordData.id}`}
							label={t("Edit")}
							icon={Icon.Edit}
							onClick={() => {
								this.setState({ selectedTag: recordData }, () => this.setState({ showUpdateModal: true, createMode: false }));
							}}
						/>
					)}
					{user.GroupPermission.delete_tags && (
						<Action id={`delete-tag-${recordData.id}`} label={t("Delete")} icon={Icon.Trash2} onClick={() => this.onConfirmDelete(recordData)} />
					)}
				</div>
			];
		} catch (error) {
			console.log(error.message);
		}
		return null;
	};

	render() {
		let {
			tags,
			loading,
			showUpdateModal,
			createMode,
			selectedTag,
			successSubmit,
			errorSubmit,
			successText,
			errorText,
			loadedAll,
			sortField,
			sortOrder,
			deleteConfirmModal,
			deleteConfirmModalLoading,
			warningData
		} = this.state;
		let { t } = this.props;

		let user = UserService.get();
		let createTagsPermission = user.GroupPermission.create_tags;

		return (
			<React.Fragment>
				<div className="contacts-search">
					<SearchInput placeholder={t("Search ...")} onChange={this.handleGeneralSearchOnChange} />
					{createTagsPermission && (
						<div className="contacts-search__actions">
							<Action id="create-tag" label={t("Create")} icon={Icon.Plus} onClick={() => this.openTagModal()} />
						</div>
					)}
				</div>
				<List
					items={tags}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("Looks like there are no tags yet...")}
					noDataIcon={<Icon.AlertCircle />}
				/>
				<Alert type="warning" show={deleteConfirmModal} title={t("Please confirm")} confirm={t("Yes")} cancel={t("No")} onClose={this.onCloseConfirmDelete}>
					<div>
						{deleteConfirmModalLoading && (
							<ContentLoader height={160} width={500}>
								<rect x="0" y="0" rx="5" ry="5" width="100%" height="70" />
								<rect x="0" y="90" rx="5" ry="5" width="100%" height="70" />
							</ContentLoader>
						)}
						{!deleteConfirmModalLoading && (
							<>
								<div>{t("Are you sure you would like to delete this tag?")}</div>
								<br />
								{warningData && warningData.contacts ? (
									<div>
										{warningData.contacts > 1
											? t("This tag is assigned to {{number}} contacts.", { number: warningData.contacts })
											: t("This tag is assigned to a contact.")}
									</div>
								) : null}
							</>
						)}
					</div>
				</Alert>
				<UpdateTagsModal
					data={selectedTag}
					show={showUpdateModal}
					onHide={() => this.onUpdateModalHide()}
					createMode={createMode}
					onSubmit={(createMode, id, name) => this.onUpdateModalSubmit(createMode, id, name)}
				/>
				<Alert
					type="error"
					show={errorSubmit}
					title={t("Error")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ errorSubmit: false, errorText: "" });
					}}
				>
					<div>{errorText}</div>
				</Alert>
				<Alert
					type="success"
					show={successSubmit}
					title={t("Success")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ successSubmit: false, successText: "" });
					}}
				>
					<div>{successText}</div>
				</Alert>
			</React.Fragment>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(Tags)));
