export const GA_CATEGORIES = {
	/* Top Level Components */
	mainNavigation: {
		name: "Main Navigation",
		sections: {}
	},
	topBar: {
		name: "Top Bar",
		sections: {
			sendReviewButton: "Top Bar Send Review Button",
			locationSelector: "Location Selector",
			userProfileDropdown: "User Profile Dropdown"
		}
	},

	/* Reusable Modals */
	editContactModal: {
		name: "Edit Contact Modal",
		sections: {
			basics: "Edit Contact Modal - Basics Section",
			advanced: "Edit Contact Modal - Advanced Section",
			notes: "Edit Contact Modal - Notes Section",
			action: "Edit Contact Modal - Action Section",
			receive: "Edit Contact Modal - Receive Section"
		}
	},

	/* Pages & Page Specific Components */
	dashboard: {
		name: "Dashboard Page",
		sections: {}
	},
	nps: {
		name: "NPS Page",
		sections: {}
	},
	invites: {
		name: "Invites Page",
		sections: {}
	},
	manageUser: {
		name: "Manage User Page",
		sections: {}
	},
	usersOverview: {
		name: "User Overview",
		sections: {}
	},
	messenger: {
		name: "Messenger Page",
		sections: {
			navigation: {
				name: "Messenger Page - Navigation",
				sections: {
					editInbox: "Messenger Page - Navigation - Edit Inbox"
				}
			},
			list: "Messenger Page - List",
			thread: "Messenger Page - Thread"
		}
	},
	contacts: {
		name: "Contacts Page",
		sections: {}
	},
	scheduledMessages: {
		name: "Scheduled Messages Page",
		sections: {}
	},
	settings: {
		name: "Settings Page",
		sections: {
			reviews: "Settings Page - Reviews",
			numbers: "Settings Page - Numbers",
			hours: "Settings Page - Hours",
			chatbots: "Settings Page - Chatbots",
			widgetConfig: "Settings Page - Widget Config",
			workflows: "Settings Page - Workflows",
			shortLinks: "Settings Page - Short Links",
			a2p: "Settings Page - A2p",
			connections: "Settings Page - Connections"
		}
	},
	analytics: {
		name: "Analytics Page",
		sections: {
			contactAnalytics: "Analytics Page - Contact Analytics"
		}
	},
	customerSuccess: {
		name: "Customer Success Page",
		sections: {
			featureMatrix: "Customer Success Page - Feature Matrix"
		}
	},
	maintenancePage: {
		name: "Maintenance Page"
	}
};

export const GA_ACTIONS = {
	generic: {
		buttonClick: "Button Click",
		linkClick: "Link Click",
		outBoundlinkClick: "Outbound Link Click",
		navSelect: "Navigation Select",
		tabSelect: "Tab Select",
		dropDownOpen: "Dropdown Open",
		dropDownSelect: "Dropdown Select",
		intercomOpen: "Open Intercom"
	},
	invites: {
		sendReviewInvite: "Send Review Invite",
		sendNpsInvite: "Send NPS Invite"
	},
	messenger: {
		navigation: {
			customer: {
				selectInbox: "Selected Inbox",

				openedCreateInboxModal: "Opened Create Inbox Modal",
				createdCreateInboxModal: "Created Inbox via Create Inbox Modal",

				openedEditInboxModal: "Opened Edit Inbox Modal",
				savedEditInboxModal: "Saved Edit Inbox Modal",

				selectedAllFilter: "Selected Filter - All",
				selectedUnassignedFilter: "Selected Filter - Unassigned",
				selectedMyMessagesFilter: "Selected Filter - My Messages"
			},
			teamchat: {
				selectChannel: "Teamchat - Selected channel",
				openNewChannelModal: "Teamchat - Opened New Channel Modal",
				createNewChannelModal: "Teamchat - Created New Channel",

				openSearch: "Teamchat - Opened Channel Search",

				selectDM: "Teamchat - Selected DM",
				openNewDMModal: "Teamchat - Opened New DM Modal",
				createNewDMModal: "Teamchat - Created New DM",
				hideDM: "Teamchat - Hide a DM"
			}
		},
		thread: {
			customer: {
				sentMessage: "Sent Customer Message",
				sentAndCloseMessage: "Sent & Closed Customer Message",
				sendLaterMessage: "Sent Later Customer Message",
				sendLaterAndCloseMessage: "Sent Later And Closed Customer Message",

				topControls: {
					openRenameContactTool: "Opened Rename Contact Tool",
					savedRenameContactTool: "Saved using Rename Contact Tool",

					openAssignedUserList: "Opened Assigned User List",
					selectedAssignedUserList: "Selected User Using Assigned User List",

					closedConversationControl: "Closed Conversation Using Control",
					openConversationControl: "Opened Conversation Using Control"
				},

				topMenu: {
					openedTopMenu: "Opened Top Menu",

					createdNote: "Created A Note",
					movedContactToInbox: "Moved Contact To Inbox",
					sendContactToCRM: "Sent Contact To CRM"
				}
			},
			teamchat: {
				sentMessage: "Sent Teamchat Message",

				openEditChannelModal: "Teamchat - Opened Edit Channel Modal",
				savedEditChannelModal: "Teamchat - Saved Edit Channel Modal",

				openEditDMModal: "Teamchat - Opened Edit DM Modal",
				savedEditDMModal: "Teamchat - Saved Edit DM Modal"
			}
		},
		list: {
			clickContact: "Clicked Into A Contact",

			openedCreateNewContactModal: "Opened Create New Contact Modal",
			createdCreateNewContactModal: "Created New Contact via Modal",

			openedSearchContact: "Opened Search Contact",

			openedFilter: "Opened Filter",
			selectedFilter: "Selected Filter",

			openedUserFilter: "Opened User Filter",
			selectedUserFilter: "Selected User Filter",

			switchedToOpenFilter: "Switched To Open Filter",
			switchedToCloseFilter: "Switched To Close Filter",

			clickedLoadMoreContacts: "Clicked Load More Contacts"
		}
	},

	/* Modals */
	editContactModal: {
		openedEditContactModal: "Edit Contact Modal - Opened",
		savedEditContactModal: "Edit Contact Modal - Saved",

		//
		// Basics Tab
		//

		changedName: "Edit Contact Modal - Changed Name",
		changedPhone: "Edit Contact Modal - Changed Phone",
		changedEmail: "Edit Contact Modal - Changed Email",
		changedDOB: "Edit Contact Modal - Changed DOB",
		changedLanguage: "Edit Contact Modal - Changed Language",

		//
		// Advanced Tab
		//

		changedStatus: "Edit Contact Modal - Changed Status",
		changedAssignedUser: "Edit Contact Modal - Changed Assigned User",
		changedAssignedInbox: "Edit Contact Modal - Changed Assigned Inbox",
		changedTags: "Edit Contact Modal - Changed Assigned Tags",
		changedGroups: "Edit Contact Modal - Changed Assigned Groups",
		changedPreferedMedium: "Edit Contact Modal - Changed Prefered Medium",
		changedCustomField: "Edit Contact Modal - Changed Custom Field",

		createdTag: "Edit Contact Modal - Created Tag",
		createdGroup: "Edit Contact Modal - Created Group",

		//
		// Notes Tab
		//

		createdNote: "Edit Contact Modal - Created Note",
		editedNote: "Edit Contact Modal - Edited Note",
		deletedNote: "Edit Contact Modal - Deleted Note",

		//
		// Action Tab
		//

		actionGoToMessenger: "Edit Contact Modal - Go To Messenger",
		actionSentToCRM: "Edit Contact Modal - Sent to CRM",
		actionDeletedContact: "Edit Contact Modal - Deleted Contact",
		actionBlockedContact: "Edit Contact Modal - Blocked Contact",
		actionMergedContact: "Edit Contact Modal - Merged Contact",

		//
		// Receive Tab
		//

		turnedOnTransactionalSms: "Edit Contact Modal - Turned on Receive Transactional SMS",
		turnedOffTransactionalSms: "Edit Contact Modal - Turned off Receive Transactional SMS",

		turnedOnTransactionalEmail: "Edit Contact Modal - Turned on Receive Transactional Email",
		turnedOffTransactionalEmail: "Edit Contact Modal - Turned off Receive Transactional Email",

		turnedOnMarketingSms: "Edit Contact Modal - Turned on Receive Marketing SMS",
		turnedOffMarketingSms: "Edit Contact Modal - Turned off Receive Marketing SMS",

		turnedOnMarketingEmail: "Edit Contact Modal - Turned on Receive Marketing Email",
		turnedOffMarketingEmail: "Edit Contact Modal - Turned off Receive Marketing Email",

		turnedOnFeedbackSms: "Edit Contact Modal - Turned on Receive Feedback SMS",
		turnedOffFeedbackSms: "Edit Contact Modal - Turned off Receive Feedback SMS",

		turnedOnFeedbackEmail: "Edit Contact Modal - Turned on Receive Feedback Email",
		turnedOffFeedbackEmail: "Edit Contact Modal - Turned off Receive Feedback Email"
	}
};
