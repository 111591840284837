import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import ReactSwitch from "react-switch";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import * as Icon from "react-feather";
import AsyncSelect from "react-select/lib/Async";
import debounce from "debounce-promise";

import UserService from "../../services/UserService";
import NumberService from "../../services/NumberService";
import LocationService from "../../services/LocationService";
import UtilityService from "../../services/UtilityService";
import NotificationService from "../../services/NotificationService";
import GAService from "../../services/GAService";
import ToastService from "../../services/ToastService";
import CompanyService from "../../services/CompanyService";

import LanguageDropdown from "../../components/common/LanguageDropdown";
import AddressAutocomplete from "../../components/common/AddressAutocomplete";
import CountryDropdown from "../../components/common/CountryDropdown";
import TimezoneDropdown from "../../components/common/TimezoneDropdown";
import ManageNumbers from "../../components/common/ManageNumbers";
import Spinners from "../../components/common/Spinners";
import NumbersAutocomplete from "../../components/common/NumbersAutocomplete";
import TimeDropdown from "../../components/common/TimeDropdown";
import Alert from "../../components/common/Alert";
import Action from "../../components/common/Action";
import Connections from "./Connections";
import Page from "../../components/common/Page";

import { STATUS } from "../../constants/CommonConstants";
import GROUP_PERMISSIONS from "../../constants/GroupPermissions";
import { CREATE_LOCATION_STEPS } from "../../constants/CreateLocation";
import { CODES_TO_LABEL, LANGUAGES, LANGUAGES_DROPDOWN } from "../../constants/LanguageConstants";

import "../../styles/css/scenes/locations.css";

class CreateLocation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			locationId: this.props.match.params.locationId,
			company: { value: UserService.getActiveCompany().id, label: UserService.getActiveCompany().name },
			selectableCompanies: [],
			location: null,
			progressPrecentage: 0,
			step: 1,
			lastStep: CREATE_LOCATION_STEPS.complete.step,
			headingText: "",
			subHeadingText: "",
			headingIcon: "fa fa-magic",
			name: "",
			handle: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			country: "",
			countryObject: null,
			latitude: 0,
			longitude: 0,
			timezone: "",
			timezoneObject: {},
			vertical: "",
			assignableVerticals: [],
			subVertical: null,
			assignableSubVerticals: [],
			language: LANGUAGES.enCA,
			supported_languages: [{ label: CODES_TO_LABEL[LANGUAGES.enCA], value: LANGUAGES.enCA }],
			verticalsConfig: {},
			smsServiceProvider: "",
			smsServiceProviderOptions: [],
			smsServiceCountry: "",
			autoRespondReviews: true,
			replyInHours: 72,
			website: "",
			phone: "",
			defaultAreaCode: "",
			email: "",
			monthlyInviteGoal: 75,
			monthlyReviewGoal: 25,
			messageRandomSeconds: 300,
			messageMinSeconds: 300,
			messageStartTime: "09:00",
			messageEndTime: "20:00",
			smsLogoUrl: "",
			emailLogoUrl: "",
			googlePlaceCid: "",
			googlePlaceId: "",
			googlePlaceUrl: "",
			facebookPlaceId: "",
			facebookMarkIncomingRead: false,
			businessNumber: "",
			partialPaymentThreshold: 0,
			minimumPaymentAmount: 0,
			stripeAccountId: "",
			isKeywordsEnabled: false,
			keywordsTemplateLimit: 3,
			keywordsWordsPerTemplate: 1,
			maxDailySmsScheduledMessageLimit: 50,
			maxDailyMmsScheduledMessageLimit: 25,
			maxMonthlySmsScheduledMessageLimit: 500,
			maxMonthlyMmsScheduledMessageLimit: 125,
			maxWeeklyRequest: 150,
			maxDailyReengagements: 30,
			averageConversionValue: 100,
			deactivateOn: null,

			saveSuccess: false,
			saveError: false,
			autoCompleteAddress: "",
			autoCompleteAddressObject: { label: "", value: "" },
			autoCompleteNumbersObject: { label: "", value: "" },
			completeAddress: "",
			populateError: false,
			loading: true,
			loadingFailed: false,
			showPseudoLocationWarning: false,
			pseudoLocation: false,
			isTrial: false,
			numbers: [],
			errorText: "Sorry, an error was encountered while saving this location. Please try again.",
			nextDisabled: true,
			toOrderNumber: ""
		};

		this.handleEdited = false;

		this.manageNumbersComponent = React.createRef();

		this.onDebouncedSearchCompanies = debounce(this.fetchCompanies.bind(this), 1000);
	}

	componentDidMount = async () => {
		GAService.GAPageView({ page: this.props.location.pathname });

		let step = this.state.step;
		await this.fetchVerticals();
		await this.fetchSMSProviders();

		if (this.isCreate()) {
			await this.fetchCompanies();
			this.setState({ loading: false });
		} else {
			await this.fetchLocation();
			step++;
		}
		this.setState(
			{
				step: step,
				progressPrecentage: (step / this.state.lastStep) * 100,
				loading: false
			},
			() => {
				this.setText();
				this.setState({
					nextDisabled: this.handleCheckNextBtnDisabled()
				});
			}
		);
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	setText() {
		let headingText = "";
		let subHeadingText = "";
		let headingIcon = "";
		for (const key in CREATE_LOCATION_STEPS) {
			if (!CREATE_LOCATION_STEPS.hasOwnProperty(key)) {
				continue;
			}
			const element = CREATE_LOCATION_STEPS[key];
			if (element.step === this.state.step) {
				headingText = element.headingText;
				subHeadingText = element.subHeadingText;
				headingIcon = element.headingIcon;
			}
		}

		this.setState({
			headingText: headingText,
			subHeadingText: subHeadingText,
			headingIcon: headingIcon
		});
	}

	genericEventHandler = event => {
		let { name, value } = event.target;

		let update = {
			[name]: value
		};

		if (name === "name" && !this.handleEdited) {
			let newHandle = LocationService.generateHandle(value);
			update.handle = newHandle;
		}

		if (name === "handle") {
			this.handleEdited = true;
		}

		this.setState(update, () => {
			this.setState({ nextDisabled: this.handleCheckNextBtnDisabled() });
		});
	};

	handleRandomSecondChange = event => {
		// don't allow more than 180 minutes of random seconds
		let newRandomSeconds = Math.min(event.target.value, 10800);
		this.setState({ messageRandomSeconds: newRandomSeconds });
		this.setState({ changesMade: true });
	};

	handleCountryChange = newValue => {
		const country = newValue ? newValue.label : "";
		this.setState({ countryObject: newValue, country }, () => {
			this.setState({ nextDisabled: this.handleCheckNextBtnDisabled() });
		});
	};

	handleAutocompleteAddressOnChange(newValue) {
		const autoCompleteAddress = newValue ? newValue.label.split(", ")[0] : "";
		this.setState({ autoCompleteAddressObject: newValue, autoCompleteAddress }, () => {
			this.setState({ nextDisabled: this.handleCheckNextBtnDisabled() });
		});
	}
	handleAutocompleteNumbersOnChange(newValue) {
		const toOrderNumber = newValue ? newValue.label.split(", ")[0] : "";
		this.setState({ autoCompleteNumbersObject: newValue, toOrderNumber }, () => {
			this.setState({ nextDisabled: this.handleCheckNextBtnDisabled() });
		});
	}

	handleTimezoneChange = selectedTimezone => {
		const timezone = selectedTimezone ? selectedTimezone.id : "";
		this.setState({ timezoneObject: selectedTimezone, timezone }, () => {
			this.setState({ nextDisabled: this.handleCheckNextBtnDisabled() });
		});
	};

	handleMessageStartTimeChange = newValue => {
		let messageStartTimeString = newValue.target.value.split(":");
		let messageStartTime = moment()
			.hours(messageStartTimeString[0])
			.minutes(messageStartTimeString[1]);

		let messageEndTimeString = this.state.messageEndTime.split(":");
		let messageEndTime = moment()
			.hours(messageEndTimeString[0])
			.minutes(messageEndTimeString[1]);

		if (messageStartTime.isAfter(messageEndTime, "minute")) {
			newValue = this.state.messageEndTime;
		} else {
			newValue = newValue.target.value;
		}

		this.setState({ messageStartTime: newValue });
	};

	handleMessageEndTimeChange = newValue => {
		let messageStartTimeString = this.state.messageStartTime.split(":");
		let messageStartTime = moment()
			.hours(messageStartTimeString[0])
			.minutes(messageStartTimeString[1]);

		let messageEndTimeString = newValue.target.value.split(":");
		let messageEndTime = moment()
			.hours(messageEndTimeString[0])
			.minutes(messageEndTimeString[1]);

		if (messageStartTime.isAfter(messageEndTime, "minute")) {
			newValue = this.state.messageStartTime;
		} else {
			newValue = newValue.target.value;
		}

		this.setState({ messageEndTime: newValue });
	};

	numbersOnChange = async numbers => {
		await this.update({ numbers: numbers });
		let isDisabled = this.handleCheckNextBtnDisabled();
		await this.update({ nextDisabled: isDisabled });
	};

	handlOrderOnClick = async e => {
		e.preventDefault();

		let response = await NumberService.orderNumber(this.state.toOrderNumber, this.state.smsServiceProvider.value);

		if (response === null) {
			this.setState({ populateError: true });
		}

		this.handleNextStepOnClick();
	};

	handleOnPopulateClick = async e => {
		try {
			e.preventDefault();

			const { autoCompleteAddressObject, autoCompleteAddress } = this.state;
			if (autoCompleteAddressObject.value.length === 0) {
				return;
			}

			let data = await LocationService.getPlacesDetails(autoCompleteAddressObject.value);
			const { administrative_area_level_1, cid, country, locality, phone, postal_code, street_address, website, subpremise, complete_address } = data;
			let handle = LocationService.generateHandle(autoCompleteAddress);

			let updateObj = {
				address2: subpremise,
				address1: street_address,
				city: locality,
				state: administrative_area_level_1,
				country,
				countryObject: { label: country, value: country },
				latitude: data.latitude,
				longitude: data.longitude,
				zip: postal_code,
				website,
				phone,
				googlePlaceCid: cid,
				googlePlaceId: autoCompleteAddressObject.value,
				name: autoCompleteAddress,
				completeAddress: complete_address,
				handle
			};

			if (data.timezoneId) {
				updateObj.timezone = data.timezoneId;
				updateObj.timezoneObject = data.timezoneDetails;
			}

			await this.update(updateObj);

			this.handleNextStepOnClick();
			this.setState({ googlePlaceUrl: this.generatePlacesUrl() });
		} catch (error) {
			console.error(error);
			this.setState({ populateError: true });
		}
	};

	generatePlacesUrl = () => {
		const { completeAddress, autoCompleteAddress } = this.state;

		// Use the CID to generate the URL to see reviews
		let hexCid = NumberService.largeDecimalTohex(this.state.googlePlaceCid);

		let googlePlaceUrl = `https://www.google.com/search?q=${autoCompleteAddress.split(" ").join("+")},${completeAddress.split(" ").join("+")}&ludocid=${
			this.state.googlePlaceCid
		}#lrd=0x0:0x${hexCid},1`;

		return googlePlaceUrl;
	};

	showErrorModal = (message = "Sorry, an error was encountered while saving this location. Please try again.") => {
		this.setState({
			errorText: message,
			saveError: true
		});
	};

	handleNextStepOnClick = async () => {
		let step = this.state.step;
		// if the setup is not done
		if (step < this.state.lastStep) {
			step++;

			//skip connections step when creating a location
			if (step === CREATE_LOCATION_STEPS.connections.step && this.isCreate()) {
				step++;
			}

			if (this.state.pseudoLocation && this.state.step >= CREATE_LOCATION_STEPS.summary.step) {
				await this.update({
					step: CREATE_LOCATION_STEPS.complete.step,
					progressPrecentage: (CREATE_LOCATION_STEPS.complete.step / (this.state.lastStep - 1)) * 100
				});
				this.setText();
				return;
			} else if (this.state.pseudoLocation && this.state.step >= CREATE_LOCATION_STEPS.integrations.step) {
				await this.update({
					step: CREATE_LOCATION_STEPS.summary.step,
					progressPrecentage: (CREATE_LOCATION_STEPS.summary.step / (this.state.lastStep - 1)) * 100
				});
				this.setText();
				return;
			} else if (this.state.pseudoLocation && this.state.step >= CREATE_LOCATION_STEPS.contactInfo.step) {
				await this.update({
					step: CREATE_LOCATION_STEPS.integrations.step,
					progressPrecentage: (CREATE_LOCATION_STEPS.complete.step / (this.state.lastStep - 1)) * 100
				});
				this.setText();
				this.update({ nextDisabled: this.handleCheckNextBtnDisabled() });
				return;
			} else if (this.state.pseudoLocation && this.state.step >= CREATE_LOCATION_STEPS.locationInfo.step) {
				await this.update({
					step: CREATE_LOCATION_STEPS.contactInfo.step,
					progressPrecentage: (CREATE_LOCATION_STEPS.contactInfo.step / (this.state.lastStep - 1)) * 100
				});
				this.setText();
				return;
			}

			await this.update({
				step: step,
				progressPrecentage: (step / (this.state.lastStep - 1)) * 100
			});

			this.setText();
			this.update({ nextDisabled: this.handleCheckNextBtnDisabled() });
		}
	};

	handleBackStepOnClick = async () => {
		let step = this.state.step;
		if (step > 1) {
			if (this.state.pseudoLocation && this.state.step === CREATE_LOCATION_STEPS.summary.step) {
				await this.update({
					step: CREATE_LOCATION_STEPS.integrations.step,
					progressPrecentage: (CREATE_LOCATION_STEPS.integrations.step / (this.state.lastStep - 1)) * 100
				});
				this.setText();
				this.setState({ nextDisabled: this.handleCheckNextBtnDisabled() });
				return;
			} else if (this.isCreate() && this.state.step === CREATE_LOCATION_STEPS.summary.step) {
				await this.update({
					step: CREATE_LOCATION_STEPS.integrations.step,
					progressPrecentage: (CREATE_LOCATION_STEPS.integrations.step / (this.state.lastStep - 1)) * 100
				});
				this.setText();
				this.setState({ nextDisabled: this.handleCheckNextBtnDisabled() });
				return;
			} else if (this.state.pseudoLocation && this.state.step >= CREATE_LOCATION_STEPS.integrations.step) {
				await this.update({
					step: CREATE_LOCATION_STEPS.contactInfo.step,
					progressPrecentage: (CREATE_LOCATION_STEPS.contactInfo.step / (this.state.lastStep - 1)) * 100
				});
				this.setText();
				this.setState({ nextDisabled: this.handleCheckNextBtnDisabled() });
				return;
			} else if (this.state.pseudoLocation && this.state.step >= CREATE_LOCATION_STEPS.contactInfo.step) {
				await this.update({
					step: CREATE_LOCATION_STEPS.locationInfo.step,
					progressPrecentage: (CREATE_LOCATION_STEPS.locationInfo.step / (this.state.lastStep - 1)) * 100
				});
				this.setText();
				this.setState({ nextDisabled: this.handleCheckNextBtnDisabled() });
				return;
			}

			step--;

			await this.update({
				step: step,
				progressPrecentage: (step / (this.state.lastStep - 1)) * 100
			});

			this.setText();
			this.setState({ nextDisabled: this.handleCheckNextBtnDisabled() });
		}
	};

	handleCheckNextBtnDisabled = () => {
		let isDisabled = true;

		// validation for filling out location information
		if (this.state.step === CREATE_LOCATION_STEPS.locationInfo.step) {
			if (this.state.name === "") {
				return isDisabled;
			}
			if (this.state.address1 === "") {
				return isDisabled;
			}
			if (this.state.city === "") {
				return isDisabled;
			}
			if (this.state.state === "") {
				return isDisabled;
			}
			if (this.state.countryObject === null) {
				return isDisabled;
			}
			if (this.state.zip === "") {
				return isDisabled;
			}
			if (
				!this.state.pseudoLocation &&
				this.state.timezoneObject &&
				!this.state.timezoneObject.hasOwnProperty("id") &&
				!this.state.timezoneObject.hasOwnProperty("value")
			) {
				return isDisabled;
			}

			if (this.state.vertical === "") {
				return isDisabled;
			}

			// validation for filling out the contact information
		} else if (this.state.step === CREATE_LOCATION_STEPS.contactInfo.step) {
			if (!this.state.pseudoLocation && this.state.phone === "") {
				return isDisabled;
			}
		} else if (this.state.step === CREATE_LOCATION_STEPS.selectSMSService.step) {
			if (!this.state.smsServiceProvider && this.state.smsServiceProvider === "") {
				return isDisabled;
			}
			if (!this.state.smsServiceCountry && this.state.smsServiceCountry === "") {
				return isDisabled;
			}

			// validaiton for filling out the sms numbers
		} else if (this.state.step === CREATE_LOCATION_STEPS.smsNumbers.step) {
			// double check if the numbers have been set properly
			let numbers = this.state.numbers;

			// eslint-disable-next-line
			let safeCount = numbers.filter(number => number.safe == true).length;
			if (safeCount !== 1) {
				return isDisabled;
			}
			// TODO: we need to start using the same validation as the platform
			// This validation we use here is a little finicky
			// for (let i = 0; i < numbers.length; i++) {
			// 	if (!UtilityService.isMobilePhoneValid(numbers[i].number)) {
			// 		return isDisabled;
			// 	}
			// }
			if (this.state.numbers === 0) {
				return isDisabled;
			}
			if (this.state.messageRandomSeconds === "") {
				return isDisabled;
			}
			if (this.state.messageMinSeconds === "") {
				return isDisabled;
			}
			if (this.state.messageStartTime === "") {
				return isDisabled;
			}
			if (this.state.messageEndTime === "") {
				return isDisabled;
			}
			// validation for the goals
		} else if (this.state.step === CREATE_LOCATION_STEPS.goals.step) {
			// check that the values are numbers
			if (this.state.monthlyInviteGoal === "") {
				return isDisabled;
			}
			if (this.state.monthlyReviewGoal === "") {
				return isDisabled;
			}

			// validation for the integrations (Google and Facebook)
		} else if (this.state.step === CREATE_LOCATION_STEPS.integrations.step) {
			// check that the values are numbers
			if (this.state.googlePlaceCid === "") {
				return isDisabled;
			}
			if (this.state.googlePlaceId === "") {
				return isDisabled;
			}
			if (this.state.googlePlaceUrl === "") {
				return isDisabled;
			}
		}
		isDisabled = false;
		return isDisabled;
	};

	isCreate = () => {
		const { locationId } = this.state;
		return locationId === "create";
	};

	fetchLocation = async () => {
		if (this.isCreate()) {
			return;
		}

		try {
			const { locationId } = this.state;
			let user = UserService.get();

			let location = await LocationService.fetchLocation(locationId);

			if (!location) {
				throw new Error("No data found.");
			}

			if (!location.LocationFeature) {
				ToastService.error("Location is missing location features. Go to Manage -> Settings -> Features.");
				throw new Error("No location feature found for this location.");
			}

			let numbers = location.numbers;

			numbers.forEach(number => {
				number.notes = number.notes !== null ? JSON.parse(number.notes) : [];
				number.notes = number.notes.sort((n1, n2) => n1.created_at - n2.created_at);
			});

			let metaData = LocationService.getAndParseMetaData(location);
			let keywordsTemplateLimit = 3;
			let keywordsWordsPerTemplate = 1;
			if (metaData && metaData.keywords) {
				keywordsTemplateLimit = metaData.keywords.templateLimit ? metaData.keywords.templateLimit : keywordsTemplateLimit;
				keywordsWordsPerTemplate = metaData.keywords.wordsPerTemplateLimit ? metaData.keywords.wordsPerTemplateLimit : keywordsWordsPerTemplate;
			}

			let isKeywordsEnabled =
				location.LocationFeature.workflows &&
				location.LocationFeature.workflow_keywords &&
				(user.GroupPermission.type === GROUP_PERMISSIONS.super_administrator.type || user.GroupPermission.type === GROUP_PERMISSIONS.customer_success.type);

			let supportedLanguagesArray = JSON.parse(location.supported_languages);

			let supportedLanguages = supportedLanguagesArray.map(lang => {
				return {
					label: CODES_TO_LABEL[lang],
					value: lang
				};
			});

			let state = {
				location: location,
				name: location.name,
				handle: location.handle,
				address1: location.address_1,
				address2: location.address_2,
				city: location.city,
				state: location.state_prov,
				zip: location.zip_postal,
				country: location.country,
				countryObject: { value: location.country, label: location.country },
				latitude: location.latitude,
				longitude: location.longitude,
				timezone: location.Timezone.id,
				timezoneObject: location.Timezone,
				vertical: { value: location.vertical, label: location.vertical },
				subVertical: { value: location.sub_vertical, label: location.sub_vertical },
				language: location.language,
				supported_languages: supportedLanguages,
				website: location.website,
				phone: location.phone,
				defaultAreaCode: location.default_area_code,
				email: location.email,
				monthlyInviteGoal: location.monthly_invite_goal,
				monthlyReviewGoal: location.monthly_review_goal,
				autoRespondReviews: location.LocationFeature.reviews_auto_response,
				replyInHours: location.reply_in_hours,
				smsLogoUrl: metaData && metaData.sms_logo_url ? metaData.sms_logo_url : "",
				emailLogoUrl: metaData && metaData.email_logo_url ? metaData.email_logo_url : "",
				googlePlaceCid: metaData && metaData.google_place_cid ? metaData.google_place_cid : "",
				googlePlaceUrl: metaData && metaData.google_place_url ? metaData.google_place_url : "",
				facebookPlaceId: metaData && metaData.facebook_place_id ? metaData.facebook_place_id : "",
				googlePlaceId: location.google_place_id,
				facebookMarkIncomingRead: location.facebook_mark_incoming_read,
				businessNumber: location.business_number,
				partialPaymentThreshold: location.partial_payment_threshold,
				minimumPaymentAmount: location.minimum_payment_amount,
				stripeAccountId: location.stripe_account_id,
				isKeywordsEnabled,
				keywordsTemplateLimit,
				keywordsWordsPerTemplate,
				maxDailySmsScheduledMessageLimit: location.max_daily_sms_scheduled_message_limit,
				maxDailyMmsScheduledMessageLimit: location.max_daily_mms_scheduled_message_limit,
				maxMonthlySmsScheduledMessageLimit: location.max_monthly_sms_scheduled_message_limit,
				maxMonthlyMmsScheduledMessageLimit: location.max_monthly_mms_scheduled_message_limit,
				maxWeeklyRequest: location.max_weekly_request,
				maxDailyReengagements: location.max_daily_reengagements,
				averageConversionValue: location.average_conversion_value,
				deactivateOn: location.deactivate_on,
				numbers: numbers,
				messageRandomSeconds: location.message_random_seconds,
				messageMinSeconds: location.message_min_seconds,
				messageStartTime: location.message_start_time,
				messageEndTime: location.message_end_time,
				action: "Manage",
				loading: false,
				pseudoLocation: location.is_pseudo,
				isTrial: location.is_trial
			};

			await this.setStateLocation(state);
			await this.setAssignableSubVerticals();
		} catch (error) {
			this.setState({ action: "Manage", loadingFailed: true, loading: false });
			console.log(error);
		}
	};

	fetchCompanies = async searchTerm => {
		let companies = await CompanyService.fetchCompanies({ limit: 1000, searchTerm, status: [STATUS.active] });

		if (!companies) {
			this.update({ selectableCompanies: [] });
			return;
		}

		companies = companies.map(c => {
			return { value: c.id, label: c.name };
		});

		this.update({
			selectableCompanies: companies
		});

		return companies;
	};

	fetchVerticals = async () => {
		let verticals = await LocationService.getVerticals();

		let assignableVerticals = Object.values(verticals)
			.sort()
			.map(vertical => {
				return { value: vertical.id, label: vertical.id };
			});
		await this.update({ assignableVerticals, verticalsConfig: verticals });
	};

	setAssignableSubVerticals = async () => {
		let { vertical, verticalsConfig } = this.state;
		let assignableSubVerticals = [];

		if (verticalsConfig && vertical && verticalsConfig[vertical.value] && verticalsConfig[vertical.value].subVertical) {
			assignableSubVerticals = Object.values(verticalsConfig[vertical.value].subVertical).map(subVertical => {
				return { value: subVertical, label: subVertical };
			});
		}

		await this.update({ assignableSubVerticals });
	};

	onVerticalChange = async vertical => {
		await this.update({ vertical });
		await this.update({ nextDisabled: this.handleCheckNextBtnDisabled() });
		await this.setAssignableSubVerticals();
	};

	fetchSMSProviders = async () => {
		let providers = await LocationService.getSMSProviders();

		let smsServiceProviderOptions = Object.values(providers)
			.sort()
			.map(provider => {
				return { value: provider.id, label: UtilityService.toTitleCase(provider.id) };
			});

		await this.update({ smsServiceProviderOptions });
	};

	setStateLocation(state) {
		return new Promise(resolve => {
			this.setState(state, resolve);
		});
	}

	showAreaCodeMismatchWarning = () => {
		let { phone, defaultAreaCode, numbers } = this.state;

		return LocationService.isAreaCodeMismatching({ phone, defaultAreaCode, numbers });
	};

	handleSaveLocation = async event => {
		try {
			const { locationId, company } = this.state;
			event.preventDefault();
			event.stopPropagation();

			if (!this.state.pseudoLocation) {
				// double check if the numbers have been set properly
				let numbers = this.state.numbers;

				// eslint-disable-next-line
				let safeCount = numbers.filter(number => number.safe == true).length;
				if (safeCount !== 1) {
					this.showErrorModal(`At least one number must be a primary number`);
					this.setState({ loading: false });
					return;
				}
				// for (let i = 0; i < numbers.length; i++) {
				// 	if (!UtilityService.isMobilePhoneValid(numbers[i].number)) {
				// 		this.showErrorModal(`Sorry, the phone number '${numbers[i].number}' is invalid`);
				// 		this.setState({ loading: false });
				// 		return;
				// 	}
				// }
			}

			if (!this.state.vertical || !this.state.vertical.value) {
				this.showErrorModal(`A vertical must be selected`);
				return;
			}

			let assignPrimaryNumberToContacts = false;
			if (this.manageNumbersComponent && this.manageNumbersComponent.current) {
				assignPrimaryNumberToContacts = this.manageNumbersComponent.assignPrimaryNumberToContacts();
			}

			let supportedLanguages = [LANGUAGES.enCA];
			if (this.state.supported_languages && this.state.supported_languages.length > 0) {
				supportedLanguages = this.state.supported_languages.map(l => l.value);
			}

			// Set up the location data
			const locationData = {
				company: company.value,
				name: this.state.name,
				handle: this.state.handle,
				address_1: this.state.address1,
				address_2: this.state.address2,
				city: this.state.city,
				state_prov: this.state.state,
				zip_postal: this.state.zip,
				country: this.state.country,
				latitude: this.state.latitude,
				longitude: this.state.longitude,
				timezone: parseInt(this.state.timezone, 10),
				vertical: this.state.vertical ? this.state.vertical.value : "Other",
				sub_vertical: this.state.subVertical ? this.state.subVertical.value : null,
				language: this.state.language ? this.state.language : LANGUAGES.enCA,
				supported_languages: supportedLanguages,
				website: this.state.website,
				phone: this.state.phone,
				default_area_code: this.state.defaultAreaCode,
				email: this.state.email,
				monthly_invite_goal: this.state.monthlyInviteGoal,
				monthly_review_goal: this.state.monthlyReviewGoal,
				reply_in_hours: this.state.replyInHours,
				sms_logo_url: this.state.smsLogoUrl,
				email_logo_url: this.state.emailLogoUrl,
				google_place_cid: this.state.googlePlaceCid,
				google_place_id: this.state.googlePlaceId,
				google_place_url: this.state.googlePlaceUrl,
				facebook_place_id: this.state.facebookPlaceId,
				facebook_mark_incoming_read: this.state.facebookMarkIncomingRead,
				is_pseudo: this.state.pseudoLocation,
				is_trial: this.state.isTrial,
				business_number: this.state.businessNumber,
				partial_payment_threshold: this.state.partialPaymentThreshold,
				minimum_payment_amount: this.state.minimumPaymentAmount,
				stripe_account_id: this.state.stripeAccountId,
				keywords_template_limit: this.state.keywordsTemplateLimit,
				keywords_words_per_template: this.state.keywordsWordsPerTemplate,
				max_daily_sms_scheduled_message_limit: this.state.maxDailySmsScheduledMessageLimit,
				max_daily_mms_scheduled_message_limit: this.state.maxDailyMmsScheduledMessageLimit,
				max_monthly_sms_scheduled_message_limit: this.state.maxMonthlySmsScheduledMessageLimit,
				max_monthly_mms_scheduled_message_limit: this.state.maxMonthlyMmsScheduledMessageLimit,
				max_weekly_request: this.state.maxWeeklyRequest,
				max_daily_reengagements: this.state.maxDailyReengagements,
				average_conversion_value: this.state.averageConversionValue,
				deactivate_on: this.state.deactivateOn,
				numbers: this.state.numbers,
				message_random_seconds: this.state.messageRandomSeconds,
				message_min_seconds: this.state.messageMinSeconds,
				message_start_time: this.state.messageStartTime,
				message_end_time: this.state.messageEndTime,
				assign_primary_number_to_contacts: assignPrimaryNumberToContacts || false
			};

			this.setState({ loading: true });
			if (this.isCreate()) {
				// Make an API call to create a new location
				let response = await LocationService.createLocation(locationData);

				if (!response) {
					this.setState({ saveSuccess: false, loading: false });
					this.showErrorModal(`Sorry, an error was encountered while saving this location. Please try again.`);
					return;
				}
				ToastService.info(`${this.state.name} created for ${company.label}.`);
				NotificationService.publish("locationCreated");
			} else {
				locationData.locationId = locationId;

				// Make an API call to update a location
				let response = await LocationService.updateLocation(locationData);

				if (!response) {
					this.setState({ saveSuccess: false, loading: false });
					this.showErrorModal(`Sorry, an error was encountered while saving this location. Please try again.`);
					return;
				}

				ToastService.info(`${this.state.name} updated!`);
				NotificationService.publish("locationUpdated", locationId);
			}
			this.handleNextStepOnClick();
			this.setState({ saveSuccess: true, loading: false });
		} catch (error) {
			console.error(error);
			this.showErrorModal();
			this.setState({ loading: false }, () => {
				this.setText();
			});
		}
	};

	render() {
		const { pseudoLocation, isTrial } = this.state;

		let user = UserService.get();

		if (this.isCreate() && !user.GroupPermission.create_locations) {
			return <Redirect to="/dashboard" />;
		}

		if (this.state.loading) {
			return (
				<div className="Common__spinnerdiv--center">
					<Spinners loading={true} type="tail-fade" size="120px" />
				</div>
			);
		}

		return (
			<Page>
				<Alert
					type="error"
					show={this.state.saveError}
					title="Saving Error"
					confirm="OK"
					onClose={() => {
						this.setState({ saveError: false });
					}}
				>
					<div>{this.state.errorText}</div>
				</Alert>
				<Alert
					type="warning"
					show={this.state.showPseudoLocationWarning}
					title="Are you sure?"
					confirm="Yes"
					cancel="No"
					onClose={confirmed => {
						if (confirmed) {
							this.setState({ pseudoLocation: !this.state.pseudoLocation }, () => {
								this.setState({ nextDisabled: this.handleCheckNextBtnDisabled() });
							});
						}
						this.setState({
							showPseudoLocationWarning: false
						});
					}}
				>
					<div>Are you sure you want to change the status of the location? This can have undesirable side effects.</div>
				</Alert>
				<div className="locations-page">
					{!this.state.loading ? (
						<div>
							<div className="row">
								<div className="col-lg-12">
									<div className="text-center">
										<span style={{ fontSize: "28px" }}>{this.state.headingText}</span>
										<h4>{this.state.subHeadingText}</h4>
									</div>
								</div>
							</div>
							{this.state.step !== CREATE_LOCATION_STEPS.complete.step && (
								<div>
									<div className="col-lg-6 col-lg-offset-3">
										<div className="progress progress-large" style={{ borderRadius: "50px" }}>
											<div
												style={{ width: `${this.state.progressPrecentage}%`, borderRadius: "50px" }}
												className={"progress-bar progress-bar-success progress-bar-striped active"}
											/>
										</div>
									</div>
								</div>
							)}
							<div className="col-lg-12">
								<hr className="" style={{ marginTop: "0", marginBottom: "25px" }} />
							</div>
						</div>
					) : (
						""
					)}
					<div className="row">
						<div className="col-md-12">
							<div className="form-horizontal">
								{this.state.step === CREATE_LOCATION_STEPS.gmbApi.step ? (
									<div>
										<div className="form-group">
											<div className="row">
												<div className="col-sm-12 text-center">
													<label className="control-label" style={{ marginBottom: "10px" }}>
														Enter Business Name
													</label>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8 col-sm-offset-2 col-xs-10 col-xs-offset-1 text-center">
													<AddressAutocomplete
														id="address_auto_complete"
														value={this.state.autoCompleteAddressObject}
														onChange={newValue => this.handleAutocompleteAddressOnChange(newValue)}
													/>
												</div>
											</div>
										</div>
										<div className="form-group">
											<div className="hr-line-dashed" />
											<div className="col-xs-12 text-center">
												<Link to="/locations" className="btn btn-warning">
													Cancel
												</Link>
												&nbsp;
												<button id="skip" className="btn btn-info" onClick={this.handleNextStepOnClick}>
													Skip
												</button>
												&nbsp;
												<button id="populate_button" className="btn btn-primary btn-md inline" onClick={e => this.handleOnPopulateClick(e)}>
													Populate
												</button>
											</div>
										</div>
									</div>
								) : this.state.step === CREATE_LOCATION_STEPS.locationInfo.step ? (
									<div>
										{!this.isCreate() &&
											(user.GroupPermission.type === GROUP_PERMISSIONS.super_administrator.type ||
												user.GroupPermission.type === GROUP_PERMISSIONS.customer_success.type) && (
												<div className="form-group">
													<label className="col-sm-3 control-label">
														Location Id <span className="text-danger">*</span>
													</label>
													<div className="col-sm-9">
														<input name="locationId" id="locationId" type="text" disabled={true} value={this.state.locationId} className="Common__input" />
													</div>
												</div>
											)}
										{this.isCreate() && (
											<div className="form-group">
												<label className="col-sm-3 control-label">
													Company<span className="text-danger">*</span>
												</label>
												<div className="col-sm-9">
													<AsyncSelect
														id="company-selector"
														isDisabled={false}
														isSearchable={true}
														isClearable={false}
														placeholder="Select a company"
														defaultOptions={this.state.selectableCompanies}
														loadOptions={this.onDebouncedSearchCompanies}
														value={this.state.company ? this.state.company : null}
														onChange={value => this.update({ company: value })}
													/>
												</div>
											</div>
										)}
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Location Name <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<input
													name="name"
													id="name"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.name}
													className="Common__input"
													required
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Location Handle <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<input
													name="handle"
													id="handle"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.handle}
													className="Common__input"
													required
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Is On Trial</label>
											<div className="col-sm-9">
												<ReactSwitch
													id="is_trial"
													type="text"
													onChange={() => this.setState({ isTrial: !this.state.isTrial })}
													checked={isTrial}
													onColor="#4A90E2"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Pseudo Location</label>
											<div className="col-sm-9">
												<ReactSwitch
													id="is_pseudo_location"
													type="text"
													onChange={() => this.setState({ showPseudoLocationWarning: true })}
													checked={pseudoLocation}
													onColor="#4A90E2"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Address 1 <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<input
													name="address1"
													id="address_1"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.address1}
													className="Common__input"
													required
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Address 2</label>
											<div className="col-sm-9">
												<input
													name="address2"
													id="address_2"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.address2}
													className="Common__input"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												City <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<input
													name="city"
													id="city"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.city}
													className="Common__input"
													required
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												State/Province <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<input
													name="state"
													id="state"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.state}
													className="Common__input"
													required
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Country <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<CountryDropdown name="country" id="country" onChange={this.handleCountryChange} value={this.state.countryObject} required />
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												ZIP/Postal <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<input name="zip" id="zip" type="text" onChange={this.genericEventHandler} value={this.state.zip} className="Common__input" required />
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Latitude <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<input
													name="latitude"
													id="latitude"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.latitude}
													className="Common__input"
													required
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Longitude <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<input
													name="longitude"
													id="longitude"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.longitude}
													className="Common__input"
													required
												/>
											</div>
										</div>
										{!pseudoLocation && (
											<div className="form-group">
												<label className="col-sm-3 control-label">
													Time Zone <span className="text-danger">*</span>
												</label>
												<div className="col-sm-9">
													<TimezoneDropdown
														id="timezone"
														value={{
															label: this.state.timezoneObject ? this.state.timezoneObject.text || this.state.timezoneObject.label : "",
															value: this.state.timezoneObject ? this.state.timezoneObject.value : "",
															id: this.state.timezoneObject ? this.state.timezoneObject.id : ""
														}}
														onChange={this.handleTimezoneChange}
														required
													/>
												</div>
											</div>
										)}
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Vertical<span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<Select
													id="verticals"
													options={this.state.assignableVerticals}
													onChange={this.onVerticalChange}
													value={this.state.vertical}
													isSearchable={true}
													isClearable={true}
													placeholder="Select a vertical"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Sub Vertical</label>
											<div className="col-sm-9">
												<Select
													id="sub-verticals"
													options={this.state.assignableSubVerticals}
													onChange={async subVertical => {
														await this.update({ subVertical });
														await this.update({ nextDisabled: this.handleCheckNextBtnDisabled() });
													}}
													value={this.state.subVertical}
													isSearchable={true}
													isClearable={true}
													placeholder="Select a sub vertical"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Language</label>
											<div className="col-sm-9">
												<LanguageDropdown
													name="language"
													id="language"
													onChange={newValue => this.setState({ language: newValue.value })}
													value={{ label: CODES_TO_LABEL[this.state.language], value: this.state.language }}
													required
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-2 control-label">Supported Languages</label>
											<div className="col-sm-10">
												<Select
													id="supported_languages"
													name="supported_languages"
													options={LANGUAGES_DROPDOWN}
													value={this.state.supported_languages}
													isMulti
													placeholder="Select a language ..."
													onChange={newList => {
														this.setState({ supported_languages: newList, changesMade: true });
													}}
												/>
											</div>
										</div>
										<div className="hr-line-dashed" />
										<div className="form-group">
											<div className="col-xs-12 text-center">
												<button id="back-btn" className="btn btn-warning" onClick={this.handleBackStepOnClick}>
													Back
												</button>
												&nbsp;
												<button id="next-btn" className="btn btn-primary" disabled={this.state.nextDisabled} onClick={this.handleNextStepOnClick}>
													Next
												</button>
											</div>
										</div>
									</div>
								) : this.state.step === CREATE_LOCATION_STEPS.contactInfo.step ? (
									<div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Website</label>
											<div className="col-sm-9">
												<input
													name="website"
													id="website"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.website}
													className="Common__input"
												/>
											</div>
										</div>
										{!pseudoLocation && (
											<div className="form-group">
												<label className="col-sm-3 control-label">
													Main Phone <span className="text-danger">*</span>
												</label>
												<div className="col-sm-9">
													<input
														name="phone"
														id="phone"
														type="tel"
														onChange={this.genericEventHandler}
														value={this.state.phone}
														className="Common__input"
														required
													/>
												</div>
											</div>
										)}
										{!pseudoLocation && (
											<div className="form-group">
												<label className="col-sm-3 control-label">Default Phone Number Area Code</label>
												<div className="col-sm-9">
													<input
														name="defaultAreaCode"
														id="default_area_code"
														type="tel"
														onChange={this.genericEventHandler}
														value={this.state.defaultAreaCode}
														className="Common__input"
													/>
												</div>
											</div>
										)}
										<div className="form-group">
											<label className="col-sm-3 control-label">Contact Email</label>
											<div className="col-sm-9">
												<input name="email" id="email" type="text" onChange={this.genericEventHandler} value={this.state.email} className="Common__input" />
											</div>
										</div>
										<div className="hr-line-dashed" />
										<div className="form-group">
											<div className="col-xs-12 text-center">
												<button id="back-btn" className="btn btn-warning" onClick={this.handleBackStepOnClick}>
													Back
												</button>
												&nbsp;
												<button id="next-btn" className="btn btn-primary" disabled={this.state.nextDisabled} onClick={this.handleNextStepOnClick}>
													Next
												</button>
											</div>
										</div>
									</div>
								) : this.state.step === CREATE_LOCATION_STEPS.smsNumbers.step ? (
									<div className="col-xs-8 col-xs-offset-2">
										<ManageNumbers
											ref={ref => (this.manageNumbersComponent = ref)}
											numbers={this.state.numbers}
											companyId={this.state.company.value}
											locationId={this.state.locationId}
											onChange={numbers => this.numbersOnChange(numbers)}
										/>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												<span data-tip data-for="message-random-seconds-tooltip">
													Message Random Seconds <i className="fa fa-info-circle" /> <span className="text-danger">*</span>
												</span>
												<ReactTooltip
													id="message-random-seconds-tooltip"
													className="mb-react-tooltip text-left"
													arrowColor="#333"
													type="info"
													effect="solid"
													place="top"
												>
													The number of seconds over which to <br /> randomize an automated review invite. By <br /> default it is set to 300 seconds.
												</ReactTooltip>
											</label>
											<div className="col-sm-9">
												<input
													name="messageRandomSeconds"
													id="messageRandomSeconds"
													type="text"
													onChange={this.handleRandomSecondChange}
													value={this.state.messageRandomSeconds}
													className="Common__input"
													required
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												<span data-tip data-for="message-min-seconds-tooltip">
													Message Min Seconds <i className="fa fa-info-circle" /> <span className="text-danger">*</span>
												</span>
												<ReactTooltip
													id="message-min-seconds-tooltip"
													className="mb-react-tooltip text-left"
													arrowColor="#333"
													type="info"
													effect="solid"
													place="top"
												>
													The minimum number of seconds to wait
													<br /> before sending an automated review invite. By
													<br /> default it is set to 300 seconds.
												</ReactTooltip>
											</label>
											<div className="col-sm-9">
												<input
													name="messageMinSeconds"
													id="messageMinSeconds"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.messageMinSeconds}
													className="Common__input"
													required
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												<span data-tip data-for="message-start-time-tooltip">
													Message Start Time <i className="fa fa-info-circle" /> <span className="text-danger">*</span>
												</span>
												<ReactTooltip
													id="message-start-time-tooltip"
													className="mb-react-tooltip text-left"
													arrowColor="#333"
													type="info"
													effect="solid"
													place="top"
												>
													The start time (HH:MM) of the range in which <br /> automated review invites are sent. By <br /> default it is set to 09:00.
												</ReactTooltip>
											</label>
											<div className="col-sm-9">
												<TimeDropdown
													id={"messageStartTime"}
													name={"messageStartTime"}
													checked={false}
													isDisabled={false}
													handleChange={newValue => this.handleMessageStartTimeChange(newValue)}
													value={this.state.messageStartTime}
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												<span data-tip data-for="message-end-time-tooltip">
													Message End Time <i className="fa fa-info-circle" /> <span className="text-danger">*</span>
												</span>
												<ReactTooltip
													id="message-end-time-tooltip"
													className="mb-react-tooltip text-left"
													arrowColor="#333"
													type="info"
													effect="solid"
													place="top"
												>
													The end time (HH:MM) of the range in which <br /> automated review invites are sent. By default
													<br /> it is set to 20:00.
												</ReactTooltip>
											</label>
											<div className="col-sm-9">
												<TimeDropdown
													id={"messageEndTime"}
													name={"messageEndTime"}
													checked={false}
													isDisabled={false}
													handleChange={newValue => this.handleMessageEndTimeChange(newValue)}
													value={this.state.messageEndTime}
												/>
											</div>
										</div>
										<div className="hr-line-dashed" />
										<div className="form-group">
											<div className="col-xs-12 text-center">
												<button id="back-btn" className="btn btn-warning" onClick={this.handleBackStepOnClick}>
													Back
												</button>
												&nbsp;
												<button id="next-btn" className="btn btn-primary" disabled={this.state.nextDisabled} onClick={this.handleNextStepOnClick}>
													Next
												</button>
											</div>
										</div>
									</div>
								) : this.state.step === CREATE_LOCATION_STEPS.goals.step ? (
									<div className="">
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Monthly Invite Goal <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<input
													name="monthlyInviteGoal"
													id="monthlyInviteGoal"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.monthlyInviteGoal}
													className="Common__input"
													required
												/>
											</div>
											{/* <div className="col-sm-1"> &nbsp; </div> */}
										</div>
										<br />
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Monthly Review Goal <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<input
													name="monthlyReviewGoal"
													id="monthlyReviewGoal"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.monthlyReviewGoal}
													className="Common__input"
													required
												/>
											</div>
										</div>
										<div className="hr-line-dashed" />
										<div className="form-group">
											<div className="col-xs-12 text-center">
												<button id="back-btn" className="btn btn-warning" onClick={this.handleBackStepOnClick}>
													Back
												</button>
												&nbsp;
												<button id="next-btn" className="btn btn-primary" disabled={this.state.nextDisabled} onClick={this.handleNextStepOnClick}>
													Next
												</button>
											</div>
										</div>
									</div>
								) : this.state.step === CREATE_LOCATION_STEPS.settings.step ? (
									<div>
										{this.state.autoRespondReviews && (
											<div className="form-group">
												<label className="col-sm-3 control-label">
													<span>Auto Reply In Hours</span>
												</label>
												<div className="col-sm-3">
													<input
														name="replyInHours"
														id="reply_in_hours"
														type="text"
														onChange={this.genericEventHandler}
														value={this.state.replyInHours}
														className="Common__input"
														style={{ width: "150px" }}
														required
													/>
												</div>
											</div>
										)}
										<div className="form-group">
											<label className="col-sm-3 control-label">Mark Incoming Facebook Messages As Read</label>
											<div className="col-sm-9">
												<ReactSwitch
													id="facebook_mark_incoming_read"
													type="text"
													onChange={() => this.setState({ facebookMarkIncomingRead: !this.state.facebookMarkIncomingRead })}
													checked={this.state.facebookMarkIncomingRead}
													uncheckedIcon={false}
													checkedIcon={false}
													onColor="#4A90E2"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Business Number ID</label>
											<div className="col-sm-9">
												<input
													name="businessNumber"
													id="business_number"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.businessNumber}
													className="Common__input"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Partial Payment Threshold</label>
											<div className="col-sm-9">
												<input
													name="partialPaymentThreshold"
													id="partial_payment_threshold"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.partialPaymentThreshold}
													className="Common__input"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Minimum Payment Amount</label>
											<div className="col-sm-9">
												<input
													name="minimumPaymentAmount"
													id="minimum_payment_amount"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.minimumPaymentAmount}
													className="Common__input"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Stripe Account ID</label>
											<div className="col-sm-9">
												<input
													name="stripeAccountId"
													id="stripe_account_id"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.stripeAccountId}
													className="Common__input"
												/>
											</div>
										</div>
										{this.state.isKeywordsEnabled && (
											<>
												<div className="form-group">
													<label className="col-sm-3 control-label">Keywords - Template Limit</label>
													<div className="col-sm-9">
														<input
															name="keywordsTemplateLimit"
															id="keywords_template_limit"
															type="text"
															onChange={this.genericEventHandler}
															value={this.state.keywordsTemplateLimit}
															className="Common__input"
														/>
													</div>
												</div>
												<div className="form-group">
													<label className="col-sm-3 control-label">Keywords - Words Per Template</label>
													<div className="col-sm-9">
														<input
															name="keywordsWordsPerTemplate"
															id="keywords_words_per_template"
															type="text"
															onChange={this.genericEventHandler}
															value={this.state.keywordsWordsPerTemplate}
															className="Common__input"
														/>
													</div>
												</div>
											</>
										)}
										<div className="form-group">
											<label className="col-sm-3 control-label">Max Daily SMS Scheduled Message Limit</label>
											<div className="col-sm-9">
												<input
													name="maxDailySmsScheduledMessageLimit"
													id="max_daily_sms_scheduled_message_limit"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.maxDailySmsScheduledMessageLimit}
													className="Common__input"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Max Daily MMS Scheduled Message Limit</label>
											<div className="col-sm-9">
												<input
													name="maxDailyMmsScheduledMessageLimit"
													id="max_daily_mms_scheduled_message_limit"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.maxDailyMmsScheduledMessageLimit}
													className="Common__input"
												/>
											</div>
										</div>

										<div className="form-group">
											<label className="col-sm-3 control-label">Max Monthly SMS Scheduled Message Limit</label>
											<div className="col-sm-9">
												<input
													name="maxMonthlySmsScheduledMessageLimit"
													id="max_monthly_sms_scheduled_message_limit"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.maxMonthlySmsScheduledMessageLimit}
													className="Common__input"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Max Monthly MMS Scheduled Message Limit</label>
											<div className="col-sm-9">
												<input
													name="maxMonthlyMmsScheduledMessageLimit"
													id="max_monthly_mms_scheduled_message_limit"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.maxMonthlyMmsScheduledMessageLimit}
													className="Common__input"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Max Weekly Review Request Limit</label>
											<div className="col-sm-9">
												<input
													name="maxWeeklyRequest"
													id="max_weekly_request"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.maxWeeklyRequest}
													className="Common__input"
												/>
											</div>
										</div>

										<div className="form-group">
											<label className="col-sm-3 control-label">Max Daily Reengagements</label>
											<div className="col-sm-9">
												<input
													name="maxDailyReengagements"
													id="max_daily_reengagements"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.maxDailyReengagements}
													className="Common__input"
												/>
											</div>
										</div>

										<div className="form-group">
											<label className="col-sm-3 control-label">Average Conversion Value</label>
											<div className="col-sm-9">
												<input
													name="averageConversionValue"
													id="average_conversion_value"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.averageConversionValue}
													className="Common__input"
												/>
											</div>
										</div>

										<div className="form-group">
											<label className="col-sm-3 control-label">Deactivate Date</label>
											<div className="col-sm-9">
												<div className="locations__deactivate-on">
													<div className="InputFromTo InputDate">
														<DayPickerInput
															placeholder="Deactivation Date"
															format="LL"
															onDayChange={deactivateOn => this.setState({ deactivateOn })}
															component={props => <input {...props} value={this.state.deactivateOn ? moment(this.state.deactivateOn).format("LL") : ""} />}
														/>
													</div>
													<Action
														key="clear-deactivate-on-date"
														id="clear-deactivate-on-date"
														className="locations__deactivate-on__clear-button"
														icon={Icon.X}
														label="Remove Deactivation Date"
														onClick={() => this.setState({ deactivateOn: null })}
														transparent={true}
													/>
												</div>
											</div>
										</div>

										<div className="hr-line-dashed" />
										<div className="form-group">
											<div className="col-xs-12 text-center">
												<button id="back-btn" className="btn btn-warning" onClick={this.handleBackStepOnClick}>
													Back
												</button>
												&nbsp;
												<button id="next-btn" className="btn btn-primary" disabled={this.state.nextDisabled} onClick={this.handleNextStepOnClick}>
													Next
												</button>
											</div>
										</div>
									</div>
								) : this.state.step === CREATE_LOCATION_STEPS.logos.step ? (
									<div>
										<div className="form-group">
											<label className="col-sm-3 control-label">SMS Logo Url</label>
											<div className="col-sm-9">
												<textarea
													name="smsLogoUrl"
													id="smsLogoUrl"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.smsLogoUrl}
													className="Common__input"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Email Logo Url</label>
											<div className="col-sm-9">
												<textarea
													name="emailLogoUrl"
													id="emailLogoUrl"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.emailLogoUrl}
													className="Common__input"
												/>
											</div>
										</div>
										<div className="hr-line-dashed" />
										<div className="form-group">
											<div className="col-xs-12 text-center">
												<button id="back-btn" className="btn btn-warning" onClick={this.handleBackStepOnClick}>
													Back
												</button>
												&nbsp;
												<button id="next-btn" className="btn btn-primary" disabled={this.state.nextDisabled} onClick={this.handleNextStepOnClick}>
													Next
												</button>
											</div>
										</div>
									</div>
								) : this.state.step === CREATE_LOCATION_STEPS.selectSMSService.step ? (
									<div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Sms Service: <span className="text-danger"> *</span>
											</label>
											<div className="col-sm-9">
												<Select
													id="smsProvider"
													options={this.state.smsServiceProviderOptions}
													onChange={async smsServiceProvider => {
														await this.update({ smsServiceProvider });
														await this.update({ nextDisabled: this.handleCheckNextBtnDisabled() });
													}}
													value={this.state.smsServiceProvider}
													isSearchable={true}
													placeholder="Select a sms service provider"
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Country: <span className="text-danger"> *</span>
											</label>
											<div className="col-sm-9">
												<Select
													id="smsServiceCountry"
													options={[
														{ label: "Canada", value: "CA" },
														{ label: "USA", value: "US" }
													]}
													onChange={async smsServiceCountry => {
														await this.update({ smsServiceCountry });
														await this.update({ nextDisabled: this.handleCheckNextBtnDisabled() });
													}}
													value={this.state.smsServiceCountry}
													isSearchable={true}
													placeholder="Select a country to pull numbers from"
												/>
											</div>
										</div>
										<div className="hr-line-dashed" />
										<div className="form-group">
											<div className="col-xs-12 text-center">
												<button id="back-btn" className="btn btn-warning" onClick={this.handleBackStepOnClick}>
													Back
												</button>
												&nbsp;
												<button id="next-btn" className="btn btn-primary" disabled={this.state.nextDisabled} onClick={this.handleNextStepOnClick}>
													Next
												</button>
											</div>
										</div>
									</div>
								) : this.state.step === CREATE_LOCATION_STEPS.integrations.step ? (
									<div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Google Place CID <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<input
													name="googlePlaceCid"
													id="googlePlaceCid"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.googlePlaceCid}
													className="Common__input"
													required
												/>
												<span id="helpBlock" className="help-block">
													Visit the&nbsp;
													<a href="https://supple.com.au/tools/google-review-link-generator/" target="_blank" rel="noopener noreferrer">
														Supple Review Link Generator
													</a>
													&nbsp;and extract the "ludocid"
												</span>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Google Place ID <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<input
													name="googlePlaceId"
													id="googlePlaceId"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.googlePlaceId}
													className="Common__input"
													required
												/>
												<span id="helpBlock" className="help-block">
													Visit the&nbsp;
													<a href="https://developers.google.com/places/place-id" target="_blank" rel="noopener noreferrer">
														Google Place ID Finder
													</a>
												</span>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">
												Google Place URL <span className="text-danger">*</span>
											</label>
											<div className="col-sm-9">
												<input
													name="googlePlaceUrl"
													id="googlePlaceUrl"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.googlePlaceUrl}
													className="Common__input"
													required
												/>
												<span id="helpBlock" className="help-block">
													Visit the&nbsp;
													<a href="https://supple.com.au/tools/google-review-link-generator/" target="_blank" rel="noopener noreferrer">
														Supple Review Link Generator
													</a>
													&nbsp;and get a link to existing reviews
												</span>
											</div>
										</div>
										<div className="form-group">
											<label className="col-sm-3 control-label">Facebook Place ID</label>
											<div className="col-sm-9">
												<input
													name="facebookPlaceId"
													id="facebookPlaceId"
													type="text"
													onChange={this.genericEventHandler}
													value={this.state.facebookPlaceId}
													className="Common__input"
												/>
											</div>
										</div>
										<div className="hr-line-dashed" />
										<div className="form-group">
											<div className="col-xs-12 text-center">
												<button id="back-btn" className="btn btn-warning" onClick={this.handleBackStepOnClick}>
													Back
												</button>
												&nbsp;
												<button id="next-btn" className="btn btn-primary" disabled={this.state.nextDisabled} onClick={this.handleNextStepOnClick}>
													{this.state.pseudoLocation || this.isCreate() ? "Summary" : "Next"}
												</button>
											</div>
										</div>
									</div>
								) : this.state.step === CREATE_LOCATION_STEPS.connections.step && !this.isCreate() ? (
									<div>
										<Connections locationId={this.state.locationId} />
										<div className="hr-line-dashed" />
										<div className="form-group">
											<div className="col-xs-12 text-center">
												<button id="back-btn" className="btn btn-warning" onClick={this.handleBackStepOnClick}>
													Back
												</button>
												&nbsp;
												<button id="next-btn" className="btn btn-primary" disabled={this.state.nextDisabled} onClick={this.handleNextStepOnClick}>
													Summary
												</button>
											</div>
										</div>
									</div>
								) : this.state.step === CREATE_LOCATION_STEPS.summary.step ? (
									<div>
										{this.showAreaCodeMismatchWarning() && (
											<div className="locations__warning">
												Warning: The area code of the new text number does not match the area code of the location's main voice number or the default area code.
											</div>
										)}
										<table id="summary-table" className="table">
											<tbody>
												{this.isCreate() && (
													<tr>
														<td>Company</td>
														<td>{this.state.company.label}</td>
													</tr>
												)}
												<tr>
													<td>Location Name</td>
													<td>{this.state.name}</td>
												</tr>
												<tr>
													<td>Handle</td>
													<td>{this.state.handle}</td>
												</tr>
												{this.state.isTrial ? (
													<tr>
														<td>Is On Trial</td>
														<td>{this.state.isTrial ? <i className="fa fa-check fa-2x" style={{ marginTop: "-5px" }} /> : <span>&nbsp;</span>}</td>
													</tr>
												) : null}
												{this.state.pseudoLocation ? (
													<tr>
														<td>Pseudo Location</td>
														<td>{this.state.pseudoLocation ? <i className="fa fa-check fa-2x" style={{ marginTop: "-5px" }} /> : <span>&nbsp;</span>}</td>
													</tr>
												) : null}
												<tr>
													<td>Address 1</td>
													<td>{this.state.address1}</td>
												</tr>
												{this.state.address2 ? (
													<tr>
														<td>Address 2</td>
														<td>{this.state.address2}</td>
													</tr>
												) : null}
												<tr>
													<td>City</td>
													<td>{this.state.city}</td>
												</tr>
												<tr>
													<td>State/Province</td>
													<td>{this.state.state}</td>
												</tr>
												<tr>
													<td>Country</td>
													<td>{this.state.countryObject.label}</td>
												</tr>
												<tr>
													<td>ZIP/Postal</td>
													<td>{this.state.zip}</td>
												</tr>
												<tr>
													<td>Latitude</td>
													<td>{this.state.latitude}</td>
												</tr>
												<tr>
													<td>Longitude</td>
													<td>{this.state.longitude}</td>
												</tr>
												{!this.state.pseudoLocation && this.state.timezoneObject.label ? (
													<tr>
														<td>Time Zone</td>
														<td>{this.state.timezoneObject.label}</td>
													</tr>
												) : null}
												{this.state.vertical ? (
													<tr>
														<td>Vertical</td>
														<td>{this.state.vertical.value}</td>
													</tr>
												) : null}
												{this.state.subVertical ? (
													<tr>
														<td>Sub Vertical</td>
														<td>{this.state.subVertical.label}</td>
													</tr>
												) : null}
												{this.state.language ? (
													<tr>
														<td>Language</td>
														<td>{CODES_TO_LABEL[this.state.language]}</td>
													</tr>
												) : null}
												{this.state.supported_languages ? (
													<tr>
														<td>Supported Languages</td>
														<td>{this.state.supported_languages.map(lang => lang.label).join(", ")}</td>
													</tr>
												) : null}
												{this.state.website ? (
													<tr>
														<td>Website</td>
														<td>{this.state.website}</td>
													</tr>
												) : null}
												{!pseudoLocation ? (
													<tr>
														<td>Main Phone</td>
														<td>{this.state.phone}</td>
													</tr>
												) : null}
												{!pseudoLocation ? (
													<tr>
														<td>Default Phone Number Area Code</td>
														<td>{this.state.defaultAreaCode}</td>
													</tr>
												) : null}
												{this.state.email ? (
													<tr>
														<td>Contact Email</td>
														<td>{this.state.email}</td>
													</tr>
												) : null}
												{this.state.smsServiceProvider ? (
													<tr>
														<td>SMS Service Provider</td>
														<td>{this.state.smsServiceProvider.value}</td>
													</tr>
												) : null}
												{!pseudoLocation ? (
													<tr>
														<td>SMS Numbers</td>
														<td>
															{this.state.numbers &&
																this.state.numbers.map(number => (
																	<div key={number.number} style={{ marginTop: "5px" }}>
																		<span>
																			{number.number} ({number.api})
																		</span>
																		{number.safe ? <span className="label label-primary locations__sms-numbers-label">Primary</span> : ""}
																		{number.burned ? <span className="label label-danger locations__sms-numbers-label">Burned</span> : ""} <br />
																	</div>
																))}
														</td>
													</tr>
												) : null}
												{!pseudoLocation ? (
													<tr>
														<td>Message Random Seconds</td>
														<td>{this.state.messageRandomSeconds}</td>
													</tr>
												) : null}
												{!pseudoLocation ? (
													<tr>
														<td>Message Min Seconds</td>
														<td>{this.state.messageMinSeconds}</td>
													</tr>
												) : null}
												{!pseudoLocation ? (
													<tr>
														<td>Message Start Seconds</td>
														<td>{this.state.messageStartTime ? this.state.messageStartTime : ""}</td>
													</tr>
												) : null}
												{!pseudoLocation ? (
													<tr>
														<td>Message End Seconds</td>
														<td>{this.state.messageEndTime ? this.state.messageEndTime : ""}</td>
													</tr>
												) : null}
												{!pseudoLocation ? (
													<tr>
														<td>Monthly Invite Goal</td>
														<td>{this.state.monthlyInviteGoal}</td>
													</tr>
												) : null}
												{!pseudoLocation ? (
													<tr>
														<td>Monthly Review Goal</td>
														<td>{this.state.monthlyReviewGoal}</td>
													</tr>
												) : null}
												{/* CONFIGURE SETTINGS */}
												{!pseudoLocation && this.state.autoRespondReviews && this.state.replyInHours ? (
													<tr>
														<td>Auto Reply In</td>
														<td>{this.state.replyInHours} hours</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.facebookMarkIncomingRead ? (
													<tr>
														<td>Mark Incoming Facebook Messages As Read</td>
														<td>
															<i className="fa fa-check fa-2x" style={{ marginTop: "-5px" }} />
														</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.businessNumber ? (
													<tr>
														<td>Business Number ID</td>
														<td>{this.state.businessNumber}</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.partialPaymentThreshold ? (
													<tr>
														<td>Partial Payment Threshold</td>
														<td>{this.state.partialPaymentThreshold}</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.minimumPaymentAmount ? (
													<tr>
														<td>Minimum Payment Amount</td>
														<td>{this.state.minimumPaymentAmount}</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.stripeAccountId ? (
													<tr>
														<td>Stripe Account ID</td>
														<td>{this.state.stripeAccountId}</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.isKeywordsEnabled && this.state.keywordsTemplateLimit ? (
													<tr>
														<td>Keywords - Template Limit</td>
														<td>{this.state.keywordsTemplateLimit}</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.isKeywordsEnabled && this.state.keywordsWordsPerTemplate ? (
													<tr>
														<td>Keywords - Words Per Template</td>
														<td>{this.state.keywordsWordsPerTemplate}</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.maxDailySmsScheduledMessageLimit ? (
													<tr>
														<td>Max Daily SMS Scheduled Message Limit</td>
														<td>{this.state.maxDailySmsScheduledMessageLimit}</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.maxDailyMmsScheduledMessageLimit ? (
													<tr>
														<td>Max Daily MMS Scheduled Message Limit</td>
														<td>{this.state.maxDailyMmsScheduledMessageLimit}</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.maxMonthlySmsScheduledMessageLimit ? (
													<tr>
														<td>Max Monthly SMS Scheduled Message Limit</td>
														<td>{this.state.maxMonthlySmsScheduledMessageLimit}</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.maxMonthlyMmsScheduledMessageLimit ? (
													<tr>
														<td>Max Monthly MMS Scheduled Message Limit</td>
														<td>{this.state.maxMonthlyMmsScheduledMessageLimit}</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.maxWeeklyRequest ? (
													<tr>
														<td>Max Weekly Review Request Limit</td>
														<td>{this.state.maxWeeklyRequest}</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.maxDailyReengagements ? (
													<tr>
														<td>Max Weekly Review Request Limit</td>
														<td>{this.state.maxDailyReengagements}</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.averageConversionValue ? (
													<tr>
														<td>Average Conversion Value</td>
														<td>{this.state.averageConversionValue}</td>
													</tr>
												) : null}
												{!pseudoLocation ? (
													<tr>
														<td>Deactivation Date</td>
														<td>{this.state.deactivateOn ? moment(this.state.deactivateOn).format("LL") : "None"}</td>
													</tr>
												) : null}
												{/* CONFIGURE SETTINGS END */}
												{!pseudoLocation && this.state.smsLogoUrl ? (
													<tr>
														<td>SMS Logo Url</td>
														<td>{this.state.smsLogoUrl}</td>
													</tr>
												) : null}
												{!pseudoLocation && this.state.emailLogoUrl ? (
													<tr>
														<td>Email Logo Url</td>
														<td>{this.state.emailLogoUrl}</td>
													</tr>
												) : null}
												<tr>
													<td>Google Place CID</td>
													<td>{this.state.googlePlaceCid}</td>
												</tr>
												<tr>
													<td>Google Place ID</td>
													<td>{this.state.googlePlaceId}</td>
												</tr>
												<tr>
													<td>Google Place URL</td>
													<td>{this.state.googlePlaceUrl}</td>
												</tr>
												{this.state.facebookPlaceId ? (
													<tr>
														<td>Facebook Place ID</td>
														<td>{this.state.facebookPlaceId}</td>
													</tr>
												) : null}
											</tbody>
										</table>
										<div className="hr-line-dashed" />
										<div className="form-group">
											<div className="col-xs-12 text-center">
												<button id="back-btn" className="btn btn-warning" onClick={this.handleBackStepOnClick}>
													Back
												</button>
												&nbsp;
												<button id="next-btn" className="btn btn-primary" disabled={this.state.nextDisabled} onClick={this.handleSaveLocation}>
													{this.isCreate() ? <span>Create</span> : <span>Save</span>}
												</button>
											</div>
										</div>
									</div>
								) : this.state.step === CREATE_LOCATION_STEPS.complete.step ? (
									<div className="text-center">
										{this.state.loading ? (
											<div className="Common__spinnerdiv--center">
												<Spinners loading={true} type="tail-fade" size="120px" />
											</div>
										) : (
											<div className="form-group">
												<div className="col-xs-12 text-center">
													<Link to="/locations" className="btn btn-primary">
														View Locations
													</Link>
												</div>
											</div>
										)}
									</div>
								) : (
									""
								)}
								{this.state.step === CREATE_LOCATION_STEPS.createSMSNumbers.step && (
									<div>
										<div className="form-group">
											<div className="row">
												<div className="col-sm-12 text-center">
													<label className="control-label" style={{ marginBottom: "10px" }}>
														Enter 3 digit area code and select a number from the dropdown
													</label>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8 col-sm-offset-2 col-xs-10 col-xs-offset-1">
													<NumbersAutocomplete
														id="numbers_auto_complete"
														value={this.state.autoCompleteNumbersObject}
														onChange={newValue => this.handleAutocompleteNumbersOnChange(newValue)}
														provider={this.state.smsServiceProvider.value}
														countryCode={this.state.smsServiceCountry.value}
													/>
												</div>
											</div>
										</div>
										<div className="form-group">
											<div className="hr-line-dashed" />
											<div className="col-xs-12 text-center">
												<button id="back-btn" className="btn btn-warning" onClick={this.handleBackStepOnClick}>
													Back
												</button>
												&nbsp;
												<button id="skip" className="btn btn-info" onClick={this.handleNextStepOnClick}>
													Skip
												</button>
												&nbsp;
												<button id="order_button" className="btn btn-primary btn-md inline" onClick={e => this.handlOrderOnClick(e)}>
													Order
												</button>
											</div>
										</div>
									</div>
								)}
								{/* MASTER NAVIGATING CONTROLS */}
								{/* <div className="hr-line-dashed" />
									<div className="form-group">
										<div className="col-sm-4 col-sm-offset-2">
											<button id="save" className="btn btn-primary" onClick={this.handleNextStepOnClick}>
												Next
											</button>
											&nbsp;
											<button className="btn btn-warning" onClick={this.handleBackStepOnClick}>
												Back
											</button>
										</div>
									</div> */}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12" />
					</div>
				</div>
			</Page>
		);
	}
}

export default CreateLocation;
