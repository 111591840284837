import React, { Component } from "react";
import * as Icon from "react-feather";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import queryString from "query-string";

import withLocation from "../../../components/common/WithLocation";

import List from "../../../components/common/List";
import Action from "../../../components/common/Action";
import Alert from "../../../components/common/Alert";

// Using NewFilters, that way we don't have to pass in JSON objects that ultimately define layout
import Filters from "../../../components/common/NewFilters";
import Filter from "../../../components/common/Filter";
import SearchInput from "../../../components/common/SearchInput";
import DHDropdown from "../../../components/common/DHDropdown";
import DHDropdownOption from "../../../components/common/DHDropdownOption";
import NewConversationModal from "../../MessengerBeta/List/NewConversationModal/NewConversationModal";
import Invoice from "../../MessengerBeta/Thread/Invoice/Invoice";

import UserService from "../../../services/UserService";
import PaymentService from "../../../services/PaymentService";
import UtilityService from "../../../services/UtilityService";
import ContactService from "../../../services/ContactService";

import { INVOICE_STATUS, SORT_FIELD, SORT_ORDER, INVOICE_COLUMNS } from "../../../constants/Payments";

import "./invoice-list.css";

class InvoiceList extends Component {
	constructor(props) {
		super(props);

		let { statusSelected, searchTerm, sortField, sortOrder } = queryString.parse(this.props.location.search);

		this.state = {
			loading: true,
			invoices: [],
			statusSelected: statusSelected ? statusSelected : Object.keys(INVOICE_STATUS)[0],
			searchTerm: searchTerm ? searchTerm : "",
			sortField: sortField ? sortField : SORT_FIELD.invoiceDate,
			sortOrder: sortOrder ? sortOrder : SORT_ORDER.desc,
			limit: 50,
			pageSize: 50,
			loadedAll: true,
			error: false,
			errorDescription: "",

			showCreateDropdown: false,
			showNewConversationModal: false,
			showInvoiceModal: false,

			selectedContactId: null,
			selectedContactName: ""
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	resetComponent = async () => {
		await this.update({ loading: true });
		await this.fetchInvoices();
		await this.update({ loading: false });
	};

	fetchInvoices = async () => {
		let { statusSelected, searchTerm, sortField, sortOrder, limit } = this.state;

		let location = UserService.getActiveLocation();
		let invoices = await PaymentService.fetchInvoices({
			locationId: location.id,
			status: statusSelected,
			searchTerm,
			sortField,
			sortOrder,
			limit
		});

		this.update({
			invoices,
			loadedAll: invoices.length < limit
		});
	};

	onStatusSelect = async item => {
		await this.update({ loading: true, statusSelected: item.id });
		this.setUrlParams();
		await this.fetchInvoices();
		await this.update({ loading: false });
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		this.setUrlParams();
		await this.fetchInvoices();
	};

	setUrlParams() {
		if (this.props.history) {
			let { statusSelected, searchTerm, sortField, sortOrder } = this.state;

			let params = {};

			if (statusSelected) {
				params.statusSelected = statusSelected;
			}

			if (searchTerm) {
				params.searchTerm = searchTerm;
			}

			if (sortField) {
				params.sortField = sortField;
			}

			if (sortOrder) {
				params.sortOrder = sortOrder;
			}

			params = new URLSearchParams(params);
			this.props.history.replace({
				pathname: this.props.location.pathname,
				search: params.toString()
			});
		}
	}

	onInvoiceClicked = invoice => {
		this.props.history.push(`/payments/invoice/${invoice.id}`);
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ loading: true, sortField, sortOrder });
		this.setUrlParams();
		await this.fetchInvoices();
		await this.update({ loading: false });
	};

	onLocationChanged = async location => {
		await this.resetComponent();
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.fetchInvoices();
	};

	onNewConversationModalClose = () => {
		this.update({
			showNewConversationModal: false
		});
	};

	onContactSelected = async (contact, createMode) => {
		let { currentType } = this.state;

		// Fetch the contact with more details (has the payment method on it)
		let contactDetails = await ContactService.getContact(contact.contact_id);

		await this.update({
			selectedContactName: contactDetails.name,
			selectedContactId: contactDetails.id
		});

		// If there is no payment method on file, open the Invoice modal
		await this.update({
			showNewConversationModal: false,
			showInvoiceModal: true
		});
	};

	onNewInvoiceClicked = async type => {
		await this.update({
			currentType: type,
			showNewConversationModal: true,
			showCreateDropdown: false
		});
	};

	onInvoiceModalClosed = async () => {
		await this.update({
			showInvoiceModal: false,
			loading: true
		});

		await this.fetchInvoices();
		await this.update({ loading: false });
	};

	renderActionDropdown() {
		let { t } = this.props;
		let { showCreateDropdown } = this.state;

		const user = UserService.get();

		let isCreatePaymentAllowed = user.GroupPermission.create_payments;

		if (!isCreatePaymentAllowed) {
			return null;
		}

		return (
			<DHDropdown
				offset={{
					top: 30
				}}
				show={showCreateDropdown}
				onChange={({ show }) => {
					this.update({ showCreateDropdown: show });
				}}
				trigger={
					<div className="mb-button invoice-list__search__actions__dropdown-action">
						<div className="invoice-list__search__actions__dropdown-action__text">{t("Create")}</div>
						{showCreateDropdown && <Icon.ChevronUp size="16" />}
						{!showCreateDropdown && <Icon.ChevronDown size="16" />}
					</div>
				}
				options={
					<>
						<DHDropdownOption icon={Icon.DollarSign} title={t("Create an Invoice")} action={() => this.onNewInvoiceClicked()} />
					</>
				}
			/>
		);
	}

	renderSearch() {
		let { t } = this.props;

		return (
			<div className="invoice-list__search">
				<SearchInput initValue={this.state.searchTerm} placeholder={t("Search ...")} onChange={this.onSearchChange} leading={false} />
				<div className="invoice-list__search__actions">{this.renderActionDropdown()}</div>
			</div>
		);
	}

	renderFilters() {
		let { statusSelected } = this.state;

		// Convert the Invoice STATUS keys into a list for easier renderings
		let statuses = Object.keys(INVOICE_STATUS).map(item => {
			return { id: item, value: INVOICE_STATUS[item].display };
		});

		return (
			<Filters onSelect={this.onStatusSelect} selected={statusSelected}>
				{statuses.map(item => (
					<Filter key={item.id} id={item.id}>
						{item.value}
					</Filter>
				))}
			</Filters>
		);
	}

	renderRecord = inv => {
		let { localTimeZoneString } = UtilityService.getTimeZoneHelpers();
		let { t } = this.props;

		return [
			inv.Contact.name + " • " + inv.Contact.phone,
			inv.crm_invoice_id || inv.id,
			INVOICE_STATUS[inv.status].display,
			PaymentService.formatCharge(inv.total_amount),
			PaymentService.formatCharge(inv.customer_paid_amount),
			PaymentService.formatCharge(inv.insurance_amount),
			<span className="dh-tip" tip={localTimeZoneString}>
				{moment(inv.invoice_date).format("ddd, MMM Do YYYY")}
			</span>,
			<Action transparent id={`view-scheduled-message-${inv.id}`} label={t("View")} icon={Icon.Info} onClick={() => this.onInvoiceClicked(inv)} />
		];
	};

	render() {
		let { invoices, loading, loadedAll, sortOrder, sortField, showNewConversationModal, showInvoiceModal, selectedContactId, selectedContactName } = this.state;
		let { t } = this.props;

		return (
			<>
				{this.renderSearch()}
				{this.renderFilters()}
				<List
					items={invoices}
					loading={loading}
					loadedAll={loadedAll}
					headers={{
						items: INVOICE_COLUMNS,
						sortBy: this.sortBy
					}}
					renderRecord={this.renderRecord}
					onLoadMore={this.onLoadMore}
					sortOrder={sortOrder}
					sortField={sortField}
					noDataTitle={t("You don't have any invoices! Create a new invoice in DemandHub!")}
					noDataIcon={<img alt="Invoices" className="scheduled-messages__no-data-img" src="https://cdn.demandhub.co/web-app/assets/payment_requests.svg" />}
				/>

				<NewConversationModal
					show={showNewConversationModal}
					title={t("Select a contact ...")}
					actionText={t("Next")}
					showPaymentMethod={false}
					onCreate={this.onContactSelected}
					onClose={this.onNewConversationModalClose}
				/>
				<Invoice show={showInvoiceModal} onClose={this.onInvoiceModalClosed} contactId={selectedContactId} />
			</>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(InvoiceList)));
