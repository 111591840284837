import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import Alert from "../../components/common/Alert";

class LoggedOutModal extends Component {
	state = {
		showAlert: true,
		surelyLoggedOut: false,
		redirectToPath: false
	};

	async verifyLogin() {
		try {
			await UserService.checkAuthToken();
			this.setState({ showAlert: false, redirectToPath: true });
		} catch (error) {
			console.log(error);
			if (typeof error.response !== "undefined") {
				if (error.response.status === 401) {
					this.setState({ surelyLoggedOut: true });
					UserService.clear();
				}
			} else {
				this.setState({ showAlert: false, redirectToPath: true });
			}
		}
	}
	render() {
		let { t } = this.props;

		if (this.state.surelyLoggedOut) {
			return (
				<Redirect
					to={{
						pathname: "/login",
						state: this.props.location ? this.props.location.pathname : "/"
					}}
				/>
			);
		}
		if (this.state.redirectToPath) {
			return (
				<Redirect
					to={{
						pathname: this.props.location ? this.props.location.pathname : "/"
					}}
				/>
			);
		}
		return (
			<Alert type="error" show={this.state.showAlert} title={t("Session Expired")} confirm={t("OK")} onClose={() => this.verifyLogin()}>
				<div>{t("Please click Okay to go to the login page.")}</div>
			</Alert>
		);
	}
}

export default withTranslation(null, { withRef: true })(LoggedOutModal);
