import React from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Spinners from "../../../components/common/Spinners";
import Action from "../../../components/common/Action";
import withLocation from "../../../components/common/WithLocation";
import Alert from "../../../components/common/Alert";
import AgentForm from "./AgentForm";
import VerifyModal from "./VerifyModal";

import UserService from "../../../services/UserService";
import GbmService from "../../../services/GbmService";
import ToastService from "../../../services/ToastService";
import LocationService from "../../../services/LocationService";
import SupportChatService from "../../../services/SupportChatService";
import GAService from "../../../services/GAService";

import { GBM } from "../../../constants/GBMConstants";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";

import "../../../styles/css/scenes/widget-config.css";
import "../../../styles/css/scenes/google-business-messaging.css";

class GBMConnection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			loadingText: "Fetching data...",
			fetchError: true,

			messengerIntegration: null,
			hasGbmAgent: false,
			isVerified: false,
			agentVerificationState: null,
			isLocationVerified: false,
			locationVerificationState: null,
			isAgentLaunched: false,
			agentLaunchState: null,
			isLocationLaunched: false,
			locationLaunchState: null,
			hasGbmAgentLocation: false,
			manageAgent: false,
			testUrls: [],
			showSyncAgentModal: false,

			showConfirmVerifyAlert: false,
			showVerifyModal: false
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({ loading: true, loadingText: "Fetching data...", fetchError: true });

		let location = await LocationService.fetchLocation(UserService.getActiveLocation().id);

		if (!location) {
			ToastService.error("Error fetching data. Please try again");
			await this.update({ loading: false, loadingText: "Unable to fetch data.", fetchError: true });
			return;
		}

		if (!location.google_place_id || !location.google_place_id.trim()) {
			ToastService.error("Google place id is required for this location.");
			await this.update({ loading: false, loadingText: "Google place id needed on the location.", fetchError: true });
			return;
		}

		let messengerIntegration = await this.fetchGbmMessengerIntegration();

		if (messengerIntegration !== null && !messengerIntegration) {
			ToastService.info("Unable to fetch data");
			// Do somesthing with states here;
			await this.update({ loading: false, loadingText: "Failed to fetch data.", fetchError: true });
			return;
		}

		this.update({
			messengerIntegration: messengerIntegration,
			hasGbmAgent: GbmService.hasGbmAgent(messengerIntegration),
			isVerified: GbmService.isVerified(messengerIntegration),
			isAgentVerified: GbmService.isAgentVerified(messengerIntegration),
			agentVerificationState: GbmService.agentVerificationState(messengerIntegration),
			isLocationVerified: GbmService.isLocationVerified(messengerIntegration),
			locationVerificationState: GbmService.locationVerificationState(messengerIntegration),
			isAgentLaunched: GbmService.isAgentLaunched(messengerIntegration),
			agentLaunchState: GbmService.agentLaunchState(messengerIntegration),
			isLocationLaunched: GbmService.isLocationLaunched(messengerIntegration),
			locationLaunchState: GbmService.locationLaunchState(messengerIntegration),
			hasGbmAgentLocation: GbmService.hasGbmAgentLocation(messengerIntegration),
			manageAgent: messengerIntegration && !GbmService.hasGbmAgent(messengerIntegration) ? true : false,
			testUrls: GbmService.getTestUrls(messengerIntegration),
			fetchError: false,
			loading: false,
			loadingText: ""
		});
	};

	onLocationChanged = location => {
		this.resetComponent();
	};

	onCreateIntegration = async () => {
		await this.update({ loading: true, loadingText: "DemandHub is setting up..." });

		// Create Messenger Integration
		let messengerIntegration = await GbmService.createGbmIntegration({ locationId: UserService.getActiveLocation().id });

		if (!messengerIntegration) {
			ToastService.error("Failed to create integration. Please try again.");
			await this.update({ loading: false, loadingText: "" });

			return;
		}

		await this.resetComponent();
	};

	fetchGbmMessengerIntegration = async () => {
		let integration = await GbmService.fetchGbmIntegration({ locationId: UserService.getActiveLocation().id });
		return integration;
	};

	onCloseSyncAgentModal = async confirm => {
		if (!confirm) {
			await this.update({ showSyncAgentModal: false });
			return;
		}

		ToastService.info("Synchronizing agent with GBM.");
		let response = await GbmService.synchronizeAgent({ locationId: UserService.getActiveLocation().id });
		if (!response) {
			ToastService.error("Failed to synchronize agent. Please try again.");
			this.update({ showSyncAgentModal: false });
			return;
		}

		if (response.errorMessages) {
			ToastService.error(`${response.errorMessages}`);
			this.update({ showSyncAgentModal: false });
			return;
		}

		ToastService.info("Agent Synced!");
		await this.update({ showSyncAgentModal: false });
		this.resetComponent();
	};

	onCloseConfirmVerifyAlert = confirmed => {
		this.update({ showConfirmVerifyAlert: false });

		if (confirmed) {
			this.onShowVerifyModal(null, true);
		}
	};

	onCheckStatus = async () => {
		let result = await GbmService.gbmCheckStatus({ locationId: UserService.getActiveLocation().id });
		if (!result) {
			ToastService.error("There was an error checking status. Please try again.");
			return;
		}

		this.resetComponent();
	};

	onAddLocation = async () => {
		ToastService.info("Connecting GMB Location to Agent...");

		let response = await GbmService.addLocation({ locationId: UserService.getActiveLocation().id });
		if (!response) {
			ToastService.error("Error occurred adding your GMB location to the agent. Please try again.");
			return;
		}

		if (response.errorMessages) {
			ToastService.error(`${response.errorMessages}`);
			return;
		}

		ToastService.info("GMB Location connected successfully.");
		this.resetComponent();
	};

	onAgentCreated = async () => {
		await this.resetComponent();
	};

	onEditAgent = () => {
		this.update({ manageAgent: true });
	};

	onSyncAgent = () => {
		this.update({ showSyncAgentModal: true });
	};

	onGbmSupport = () => {
		window.open("https://developers.google.com/business-communications/business-messages/support/contact", "_blank");
	};

	onGbc = () => {
		window.open("https://business-communications.cloud.google.com/console/partner-console", "_blank");
	};

	onCloseAgentForm = () => {
		this.update({ manageAgent: !this.state.manageAgent });
	};

	onShowVerifyModal = (event, confirmed = false) => {
		let { messengerIntegration, agentVerificationState } = this.state;
		let isAgentVerified = GbmService.isAgentVerified(messengerIntegration);

		if (!confirmed && !isAgentVerified && !agentVerificationState) {
			this.update({ showConfirmVerifyAlert: true });
			return;
		}

		this.update({ showVerifyModal: true });
	};

	onHideVerifyModal = refresh => {
		this.update({ showVerifyModal: false });
		if (refresh) {
			this.resetComponent();
		}
	};

	onLaunchAgent = async () => {
		let response = await GbmService.launchAgent({ locationId: UserService.getActiveLocation().id });

		if (!response) {
			ToastService.error("Error trying to launch agent. Please try again.");
			return;
		}

		ToastService.info("Requested to launch agent.");
		this.resetComponent();
	};

	onLaunchLocation = async () => {
		let response = await GbmService.launchLocation({ locationId: UserService.getActiveLocation().id });

		if (!response) {
			ToastService.error("Error trying to launch location. Please try again.");
			return;
		}

		ToastService.info("Requested to launch location.");
		this.resetComponent();
	};

	openIntercom = () => {
		SupportChatService.showNewMessage("Hi, I would like to set up my GBM Agent. Would you be able to help?");

		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.name,
			action: GA_ACTIONS.generic.intercomOpen,
			label: GA_ACTIONS.generic.intercomOpen
		});
	};

	renderCreateIntegration = () => {
		let { manageAgent } = this.state;
		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();
		return (
			<div className="gbm__info__item gbm__info__item--start">
				<img className="gbm__info__item--start__img" src={"https://cdn.demandhub.co/web-app/google-business-messaging/GBM-Get-Started.svg"} />
				{manageAgent ? (
					<div>Agent is being set up....</div>
				) : (
					<div>No GBM Agent integration found. Would you like to set up your Google Business Messages agent?</div>
				)}
				<br />
				{!isSuperOrCs && (
					<div className="mb-button mb-button--fit mb-button--center" onClick={this.openIntercom}>
						Chat with Support
					</div>
				)}
				{isSuperOrCs && (
					<div className="mb-button mb-button--fit mb-button--center" onClick={this.onCreateIntegration}>
						Start
					</div>
				)}
			</div>
		);
	};

	renderTestUrls = () => {
		let { testUrls } = this.state;

		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();

		if (!isSuperOrCs) {
			return null;
		}

		if (!testUrls || testUrls.length < 1) {
			return null;
		}

		return (
			<div className="gbm__info__test-urls">
				<div className="gbm__info__test-urls__header">Agent Test Urls (On Mobile)</div>
				<div className="gbm__info__test-urls__links">
					{testUrls.map((item, index) => (
						<a key={index} className="gbm__info__test-urls__links__link mb-button" target="_blank" href={item.url} rel="noopener noreferrer">
							{GBM.testUrlSurface[item.surface] ? GBM.testUrlSurface[item.surface] : item.surface}
						</a>
					))}
				</div>
			</div>
		);
	};

	renderBody = () => {
		let {
			messengerIntegration,
			fetchError,
			manageAgent,
			hasGbmAgent,
			hasGbmAgentLocation,
			isVerified,
			isAgentVerified,
			agentVerificationState,
			agentLaunchState,
			isLocationVerified,
			locationVerificationState,
			locationLaunchState,
			isAgentLaunched,
			isLocationLaunched,
			showVerifyModal
		} = this.state;

		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();

		if (fetchError) {
			return (
				<div className="gbm__info">
					<div className="gbm__info__item">
						<div className="text-center">An error occurred. Please try again.</div>
					</div>
				</div>
			);
		}

		if (manageAgent && isSuperOrCs) {
			return <AgentForm onSubmit={this.onAgentCreated} onClose={this.onCloseAgentForm} />;
		}

		if (!hasGbmAgent || manageAgent) {
			return <div className="gbm__info">{this.renderCreateIntegration()}</div>;
		}

		const agentVerState = agentVerificationState;
		const locVerState = locationVerificationState;
		const locLaunchState = locationLaunchState;

		return (
			<div className="gbm__info">
				<div className="gbm__info__actions">
					{isSuperOrCs && (
						<Action id="gbc-console" name="gbc-console" icon={Icon.Cloud} label="Google Business Communications Partner Console" onClick={this.onGbc} />
					)}
					{isSuperOrCs && <Action id="google-support" name="google-support" icon={Icon.HelpCircle} label="GBM Support" onClick={this.onGbmSupport} />}
					{isSuperOrCs && <Action id="sync-agent" name="sync-agent" icon={Icon.DownloadCloud} label="Sync Agent With GBM" onClick={this.onSyncAgent} />}
					{isSuperOrCs && (
						<Action id="check-status" name="check-status" icon={Icon.RefreshCw} label="Check Agent and Location Statuses" onClick={this.onCheckStatus} />
					)}

					{isSuperOrCs && <Action id="edit-agent" name="edit-agent" icon={Icon.Edit2} label="Edit" onClick={this.onEditAgent} />}
				</div>
				<div className="gbm__info__item">
					{messengerIntegration.properties.agentLogoUrl && (
						<div className="gbm__info__item__logo">
							<img className="gbm__info__item__logo__img" src={messengerIntegration.properties.agentLogoUrl} />
						</div>
					)}
					<div className="text-center">
						Agent {messengerIntegration.properties.agent.displayName ? ` - ${messengerIntegration.properties.agent.displayName}` : ""}
					</div>
				</div>
				<div className="gbm__info__item gbm__info__item--verify">
					<div className="gbm__info__item__text">
						Location Connected
						<div className="gbm__info__item__text__icon">
							{hasGbmAgentLocation ? <Icon.CheckCircle size={16} color={"#18a689"} /> : <Icon.XCircle size={16} color="#ed5565" />}
						</div>
					</div>
					{!hasGbmAgentLocation && isSuperOrCs && (
						<div className="mb-button mb-button--fit" onClick={this.onAddLocation}>
							Add GMB Location
						</div>
					)}
				</div>

				{hasGbmAgentLocation && (
					<div className="gbm__info__item gbm__info__item--verify">
						<div className="gbm__info__item__text">
							Agent Verified
							<div className="gbm__info__item__text__icon">
								{isAgentVerified ? <Icon.CheckCircle size={16} color={"#18a689"} /> : <Icon.XCircle size={16} color="#ed5565" />}
							</div>
						</div>
						{!isAgentVerified &&
							agentVerState &&
							GBM.verificationState[agentVerState] &&
							agentVerState !== GBM.verificationState.VERIFICATION_STATE_UNVERIFIED.id && (
								<div className="gbm__info__item__text">
									({GBM.verificationState[agentVerState].description})
									{agentVerState !== GBM.verificationState.VERIFICATION_STATE_PENDING.id && " Contact DH Support For Assistance."}
								</div>
							)}
						{!isAgentVerified && isSuperOrCs && (!agentVerState || agentVerState === GBM.verificationState.VERIFICATION_STATE_UNVERIFIED.id) && (
							<div className="mb-button mb-button--fit" onClick={this.onShowVerifyModal}>
								Verify
							</div>
						)}
					</div>
				)}

				{hasGbmAgentLocation && (
					<div className="gbm__info__item gbm__info__item--verify">
						<div className="gbm__info__item__text">
							Location Verified
							<div className="gbm__info__item__text__icon">
								{isLocationVerified ? <Icon.CheckCircle size={16} color={"#18a689"} /> : <Icon.XCircle size={16} color="#ed5565" />}
							</div>
							{!isLocationVerified && locVerState && GBM.verificationState[locVerState] && (
								<>
									({GBM.verificationState[locVerState].description})
									{locVerState !== GBM.verificationState.VERIFICATION_STATE_PENDING.id && "Contact DH Support For Assistance."}
								</>
							)}
						</div>

						{isAgentVerified && !isLocationVerified && isSuperOrCs && (
							<div className="mb-button mb-button--fit" onClick={this.onShowVerifyModal}>
								Verify
							</div>
						)}
					</div>
				)}

				{hasGbmAgentLocation && isVerified && (
					<div className="gbm__info__item gbm__info__item--launch">
						<div className="gbm__info__item__text">
							Agent Launched
							<div className="gbm__info__item__text__icon">
								{isAgentLaunched ? <Icon.CheckCircle size={16} color={"#18a689"} /> : <Icon.XCircle size={16} color="#ed5565" />}
							</div>
							{!isAgentLaunched && agentLaunchState && GBM.launchState[agentLaunchState] && (
								<>
									({GBM.launchState[agentLaunchState].description}.)
									{agentLaunchState !== GBM.launchState.LAUNCH_STATE_PENDING.id && " Contact DH Support For Assistance."}
								</>
							)}
						</div>
						{!isAgentLaunched && isSuperOrCs && (
							<div className="mb-button mb-button--fit" onClick={this.onLaunchAgent}>
								Launch
							</div>
						)}
					</div>
				)}

				{hasGbmAgentLocation && isVerified && isAgentLaunched && (
					<div className="gbm__info__item gbm__info__item--launch">
						<div className="gbm__info__item__text">
							Location Launched
							<div className="gbm__info__item__text__icon">
								{isLocationLaunched ? <Icon.CheckCircle size={16} color={"#18a689"} /> : <Icon.XCircle size={16} color="#ed5565" />}
							</div>
							{!isLocationLaunched && locLaunchState && GBM.launchState[locLaunchState] && (
								<>
									({GBM.launchState[locLaunchState].description}.)
									{locLaunchState !== GBM.launchState.LAUNCH_STATE_PENDING.id && " Contact DH Support For Assistance."}
								</>
							)}
						</div>
						{!isLocationLaunched && isSuperOrCs && (
							<div className="mb-button mb-button--fit" onClick={this.onLaunchLocation}>
								Launch
							</div>
						)}
					</div>
				)}

				{this.renderTestUrls()}

				<VerifyModal show={showVerifyModal} integration={messengerIntegration} onHide={this.onHideVerifyModal} />
			</div>
		);
	};

	render = () => {
		let { loading, loadingText, showSyncAgentModal, showConfirmVerifyAlert } = this.state;
		return (
			<Page>
				<Header title="Google Business Messaging" />
				<div className="wc gbm">
					{loading && (
						<div className="gbm__loading">
							<div className="text-center">{loadingText}</div>
							<div className="Common__spinnerdiv--center">
								<Spinners loading={true} type="circle" size="5px" />
							</div>
						</div>
					)}
					{!loading && this.renderBody()}
				</div>
				<Alert type="info" show={showSyncAgentModal} title="Are you sure?" confirm="Yes" cancel="No" onClose={this.onCloseSyncAgentModal}>
					<div>Are you sure you would like to sync this agent with GBM?</div>
				</Alert>
				<Alert type="info" show={showConfirmVerifyAlert} title="Are you sure?" confirm="Yes" cancel="No" onClose={this.onCloseConfirmVerifyAlert}>
					<div>
						Are you sure you would like to verify this agent? Please confirm that the information is correct. Once an agent is verified only the conversation
						settings and the phone number will be modifiable.
					</div>
				</Alert>
			</Page>
		);
	};
}

export default withRouter(withLocation(GBMConnection));
