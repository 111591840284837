import { DAY_SORTER, GRADE_SORTER } from "../constants/CommonConstants";

/*
    Add a function to below object to export it to other components
*/
const JSONService = {
	sortByField,
	sortByDay,
	sortByGrade
};

/**
 * This function sorts array of JSON Objects based on the field in ascending or descending
 * order and returns a new object.
 * @param {Array} data - Array of json objects
 * @param {String} field - Name of field from JSON obect on which sorting needs to be done
 * @param {String} order - Order of sort("asc" or "desc")
 */
function sortByField(data, field, order) {
	const sortedData = JSON.parse(JSON.stringify(data));
	if (order === "asc") {
		sortedData.sort((a, b) => {
			if (a[field] < b[field]) return -1;
			if (a[field] > b[field]) return 1;
			return 0;
		});
	} else if (order === "desc") {
		sortedData.sort((a, b) => {
			if (a[field] < b[field]) return 1;
			if (a[field] > b[field]) return -1;
			return 0;
		});
	} else {
		console.warn("Invalid sort order. Returning original data");
	}
	return sortedData;
}

/**
 * Sort by day of the week
 * @param {jsonArray} data
 * @param {string} field
 * @param {string} order "ASC" || DESC
 */
function sortByDay(data, field, order = "ASC") {
	const sortedData = JSON.parse(JSON.stringify(data));

	sortedData.sort((a, b) => {
		const day1 = a[field].toLowerCase();
		const day2 = b[field].toLowerCase();
		if (order === "ASC") {
			return DAY_SORTER[day1] > DAY_SORTER[day2];
		}
		return DAY_SORTER[day1] < DAY_SORTER[day2];
	});
	return sortedData;
}

/**
 * Sort data by grades
 * @param {jsonArray} data
 * @param {String} field
 * @param {String} order ASC|DESC
 */

function sortByGrade(data, field, order = "ASC") {
	const sortedData = JSON.parse(JSON.stringify(data));
	sortedData.sort((a, b) => {
		const grade1 = a[field].toLowerCase();
		const grade2 = b[field].toLowerCase();
		if (order === "ASC") {
			return GRADE_SORTER[grade1] > GRADE_SORTER[grade2];
		}
		return GRADE_SORTER[grade1] < GRADE_SORTER[grade2];
	});
	return sortedData;
}

export default JSONService;
