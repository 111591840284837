import React, { Component } from "react";
import * as Icon from "react-feather";
import { toast } from "react-toastify";
import Tour from "reactour";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import posed, { PoseGroup } from "react-pose";
import config from "../../../config/app/web-app.config";

import { STATE, MODE, EVENT_TYPES, CONVERSATION, KEYS, TOURS, MEDIA_TYPES, MEDIUM, TOUR_THRESHOLDS, CHANNEL_TYPE } from "../../../constants/Messenger";
import MESSAGING from "../../../constants/Messaging";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";
import { PAYMENT_TYPES } from "../../../constants/Payments";
import { ATTACHMENT_LOADING, ATTACHMENT_MEDIA } from "../../../constants/Attachments";

import GAService from "../../../services/GAService";
import UserService from "../../../services/UserService";

import MessagesService from "../../../services/MessagesService";
import RealtimeService from "../../../services/WebsocketsConnection";
import NotificationService from "../../../services/NotificationService";
import ToastService from "../../../services/ToastService";
import UtilityService from "../../../services/UtilityService";
import TemplateService from "../../../services/TemplateService";
import ChatbotService from "../../../services/ChatbotService";
import ContactService from "../../../services/ContactService";
import PaymentService from "../../../services/PaymentService";
import LocationService from "../../../services/LocationService";
import SupportChatService from "../../../services/SupportChatService";
import TeamChatService from "../../../services/TeamChatService";

import MessageList from "./MessageList/MessageList";
import TeamChatMessageList from "./TeamChatMessageList/TeamChatMessageList";
import TeamChatMessageResultList from "./TeamChatMessageList/TeamChatMessageResultList";
import TeamChatScheduledMessagesList from "./TeamChatMessageList/TeamChatScheduledMessagesList";
import TemplateSelector from "./Templates/TemplateSelector";
import Attachments from "./Attachments/Attachments";
import Emojis from "./Emojis/Emojis";
import Gifs from "./Gifs/Gifs";
import ReplyMessage from "./ReplyMessage/ReplyMessage";
import Chatbots from "./Chatbots/Chatbots";
import AssignedUser from "./AssignedUser/AssignedUser";
import EditContactName from "./EditContactName/EditContactName";
import EditTeamChat from "./EditTeamChat/EditTeamChat";
import EditContactModal from "../../../components/common/EditContactModal";
import Mentions from "./Mentions/Mentions";
import PaymentRequest from "./PaymentRequest/PaymentRequest";
import SendButton from "./SendButton/SendButton";
import ReplySuggestions from "./ReplySuggestions/ReplySuggestions";
import CustomerMoreOptions from "./CustomerMoreOptions/CustomerMoreOptions";

import Alert from "../../../components/common/Alert";
import SearchInput from "../../../components/common/SearchInput";
import SendBookingRequestLinkModal from "../../../components/common/SendBookingRequestLinkModal";
import DHDropdown from "../../../components/common/DHDropdown";
import DHDropdownOption from "../../../components/common/DHDropdownOption";
import ThreadAction from "./Action/ThreadAction";
import TeamChatSeeDisplay from "./TeamChatMessageList/TeamChatSeeDisplay";
import TeamChatTaskList from "./TeamChatMessageList/TeamChatTaskList";
import UpdateTasksModal from "../../Tasks/UpdateTasksModal";
import SendShortLinksModal from "./ShortLinks/SendShortLinksModal";
import SendNpsRequest from "../../NetPromoterScore/SendNpsRequest";
import DragAndDropOverlay from "../../../components/common/DragAndDropOverlay/DragAndDropOverlay";

import "./thread.css";
import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker.css";

let stepsForMessengerClosingConversationsTour = [
	{
		selector: ".mb-list-tour",
		content: <div className="mb-tour-step">When the open list starts to get a little long, you can begin to close conversations ...</div>
	},
	{
		selector: ".mb-list-header-container-tour",
		content: <div className="mb-tour-step">Here you can switch to the closed filter to see your closed conversations ...</div>
	},
	{
		selector: ".mb-thread-header-close-tour",
		content: <div className="mb-tour-step">You can click here to close a conversation ...</div>
	},
	{
		selector: "",
		content: (
			<div className="mb-tour-step">
				If the contact sends a followup message, the closed conversation will automatically be opened and moved to your open filter!
			</div>
		),
		stepInteraction: false
	}
];

const customerSteps = [
	{
		selector: ".mb-customer-thread-tour-1",
		content: <div className="mb-tour-step">This is a customer conversation.</div>
	},
	{
		selector: ".mb-customer-thread-tour-2",
		content: <div className="mb-tour-step">You can view the customer name here, and you can edit by simply clicking it.</div>
	},
	{
		selector: ".mb-customer-thread-tour-3",
		content: <div className="mb-tour-step">Here you can see who is assigned to this customer conversation.</div>
	},
	{
		selector: ".mb-customer-thread-tour-4",
		content: <div className="mb-tour-step">You can Open or Close this conversation.</div>
	},
	{
		selector: ".mb-customer-thread-tour-5",
		content: <div className="mb-tour-step">You can compose your message ...</div>
	},
	{
		selector: ".mb-customer-thread-tour-6",
		content: <div className="mb-tour-step">... or use one of your existing templates ...</div>
	},
	{
		selector: ".mb-customer-thread-tour-7",
		content: <div className="mb-tour-step">You can now send images from the web!</div>
	},
	{
		selector: ".mb-customer-thread-tour-8",
		content: <div className="mb-tour-step">And you have a more comprehensive Emoji selection!</div>
	}
];

const teamchatSteps = [
	{
		selector: ".mb-teamchat-thread-tour-1",
		content: <div className="mb-tour-step">This is a TeamChat.</div>
	},
	{
		selector: ".mb-teamchat-thread-tour-2",
		content: <div className="mb-tour-step">Here you can see the TeamChat name and the members that are in it.</div>
	},
	{
		selector: ".mb-teamchat-thread-tour-3",
		content: <div className="mb-tour-step">Group details and notification preferences can be accessed here.</div>
	},
	{
		selector: "",
		content: <div className="mb-tour-step">Mention colleagues in your chat using the @ symbol!</div>
	},
	{
		selector: "",
		content: <div className="mb-tour-step">You are ready to start using TeamChat!</div>
	}
];

export const IsTyping = posed.div({
	enter: {
		y: 0,
		x: 0,
		opacity: 1,
		transition: {
			duration: 300
		}
	},
	exit: {
		y: 0,
		x: 0,
		opacity: 0,
		transition: {
			duration: 300
		}
	}
});

const MIN_COMPOSE_HEIGHT = 98;
const MIN_COMPOSE_CONTAINER_HEIGHT = 190;
const COMPOSE_LINE_HEIGHT = 24;
const MIN_COMPOSE_LINES = 3;
const MAX_COMPOSE_LINES = 6;

const LOAD_MORE_HEIGHT = 45;
const SHOW_NEWEST_BUTTON_THRESHOLD = 0.75;

class Thread extends Component {
	constructor(props) {
		super(props);

		let { locationId, convo } = this.props;

		this.state = {
			loading: false,

			mode: null,

			locationId: locationId,
			convo: convo,

			messageInput: "",
			sendButtonDisabled: true,

			messageSubject: "",

			showTemplates: false,
			selectedTemplateId: null,
			templateSelected: false,
			mediaFromSelectedTemplate: false, // keep track of media that came from a template

			chatbotsEnabled: false,
			showChatbots: false,
			selectedChatbotId: null,
			chatbotSelected: false,
			showChatbotKillAlert: false,
			chatbotKillAlertText: "Click 'Yes' to take over the conversation from the chatbot or 'No' to go back",
			showStartVideoChatAlert: false,

			showStartSecureChatAlert: false,
			showEndSecureChatAlert: false,
			secureChatPublicId: null,

			isChatbotActive: false,

			showEmojis: false,
			showGifs: false,
			showGifsOverlay: false,
			showEditTeamChat: false,

			replyMessageId: null,

			showTeamChatMessageSearch: false,
			teamChatSearchTerm: "",
			teamchatQueuedMessageCount: 0,
			showTeamChatTaskMessages: false,

			typers: [],
			typingContent: "",
			cachedTypingContent: "",

			eventType: EVENT_TYPES.message,
			needsTour: false,

			showPaymentRequest: false,
			showPaymentPackageModal: false,
			paymentPackageText: "",

			showContactDetails: false,

			sending: false,

			minSendAfter: UtilityService.getNext15Minutes(),
			sendAfter: UtilityService.getNext15Minutes(),

			attachmentMediaIds: [],
			currentGifAttachment: null,

			locationSmsNumber: "",
			fbEnabled: true,

			referenceMessageId: null,
			highlightReferenceId: false,

			showCustomerMenu: false,

			userSignatureEnabled: false,
			showSignature: false,
			userSignature: "",
			userClickToSendEnabled: false,
			signatureProblem: false,

			composeTextHeight: MIN_COMPOSE_HEIGHT,
			composeContainerHeight: MIN_COMPOSE_CONTAINER_HEIGHT,

			dob: "0000-00-00",

			showSendBookingRequestLinkModal: false,
			showSendShortLinkModal: false,
			showSendNpsRequestModal: false,
			showMoreComposeActions: false,

			needsTourForClosingConvos: false,

			conversationTasks: [],
			showModalTasks: false,
			taskCreationMessageId: null
		};

		this.attachmentComponent = null;
		this.gifsComponent = null;
		this.replyMessageComponent = null;
		this.messageListComponent = null;
		this.typingTask = {};
		this.threadFooter = null;
		this.messageInput = React.createRef();
		this.mentionsComponent = null;
		this.templates = React.createRef();
		this.customerMoreOptions = React.createRef();
		this.teamchatSearchInput = React.createRef();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		// If we have changed the members associated with the channel, refetch the members
		// So that the header member list is correct
		NotificationService.subscribeOnce("teamChatUpdate", "membersChanged", conversationId => {
			this.resetComponent();
		});

		NotificationService.subscribeOnce("threadUpdate", "thread__component", ({ fbEnabled }) => {
			this.update({
				fbEnabled
			});
		});

		NotificationService.subscribeOnce("contactUpdated", "thread_componentDidMount", contact => {
			this.resetComponent();

			if (this.customerMoreOptions) {
				let { convo } = this.props;
				this.customerMoreOptions.resetCustomFields(convo.contact_id);
			}
		});

		let user = UserService.get();
		NotificationService.subscribeOnce("newInternalMessage", "thread_newInternalMessage_componentDidMount", async message => {
			let { convo } = this.props;

			if (message.conversation_id && message.sender_user_id === user.id && message.is_scheduled_message) {
				this.fetchTeamchatQueuedMessageCount();
			}

			// If the message is in this conversation and it impacts the teamchat members
			if (
				convo &&
				message.conversation_id &&
				message.conversation_id === convo.conversationId &&
				this.mentionsComponent &&
				(message.event_type === EVENT_TYPES.teamchatAddedMember ||
					message.event_type === EVENT_TYPES.teamchatRemovedMember ||
					message.event_type === EVENT_TYPES.teamchatMemberLeft)
			) {
				// Refresh the mentions component
				this.mentionsComponent.fetchData();
			}
		});

		// Fired when the customer conversation open count has changed
		NotificationService.subscribeOnce("contactCountUpdate", "thread_componentDidMount", contactCount => {
			let user = UserService.get();

			// Show react tour if we have 7 conversations open
			if (contactCount.all >= TOUR_THRESHOLDS.messengerCloseConvos && !UserService.hasToured(TOURS.messengerCloseConvos, user.id)) {
				this.update({
					needsTourForClosingConvos: true
				});
			}
		});

		this.resetComponent();
	}

	componentWillUnmount() {
		if (this.messageInput && this.messageInput.current) {
			this.messageInput.removeEventListener("paste", this.onFilePaste);
		}
	}

	componentDidUpdate(prevProps) {
		let { locationId, convo, mode } = this.props;
		let { messageInput, showEmojis, showGifs, showGifsOverlay } = this.state;

		if (prevProps.convo && !convo) {
			this.update({
				convo
			});
			return;
		}

		// Customer Messaging
		if (!prevProps.convo || prevProps.convo.contact_id !== convo.contact_id || prevProps.convo.status !== convo.status || prevProps.locationId !== locationId) {
			if (prevProps.convo) {
				MessagesService.setMessageDraft(prevProps.convo.contact_id, messageInput);
			}
			this.resetComponent();
			return;
		}

		// Team Messaging
		if (!prevProps.convo || prevProps.convo.senderId !== convo.senderId || prevProps.convo.conversationId !== convo.conversationId || prevProps.mode !== mode) {
			if (prevProps.convo) {
				MessagesService.setMessageDraft("teamchat_" + prevProps.convo.conversationId, messageInput);
			}
			this.resetComponent();

			// If we are switching team chats, close the attachment preview
			if (this.attachmentComponent) {
				this.attachmentComponent.hide();
			}

			// If we are switching team chats, close the emoji selector
			if (showEmojis) {
				this.update({
					showEmojis: false
				});
			}

			// If we are switching team chats, close the gifs selector
			if (showGifs) {
				this.update({
					showGifs: false
				});
			}

			// If we are switching team chats, close the gifs preview
			if (showGifsOverlay) {
				this.update({
					showGifsOverlay: false
				});
			}
		}
	}

	resetComponent = async () => {
		let { locationId, convo, mode } = this.props;
		let user = UserService.get();

		if (!convo) {
			return;
		}

		let messageInputDraft = "";
		let members = [];
		if (mode === MODE.team) {
			members = await MessagesService.fetchMembers(convo.conversationId);
			messageInputDraft = MessagesService.getMessageDraft("teamchat_" + convo.conversationId);

			this.fetchTeamchatQueuedMessageCount();
		} else {
			messageInputDraft = MessagesService.getMessageDraft(convo.contact_id);
		}

		let tour = mode === MODE.team ? TOURS.teamchatThread : TOURS.customerThread;

		let contact = null;
		let secureChatPublicId = null;
		let dob = "0000-00-00";
		if (mode === MODE.customer) {
			contact = await ContactService.getContact(convo.contact_id);

			if (contact) {
				if (contact.SecureChat && contact.SecureChat.status === "active") {
					secureChatPublicId = contact.SecureChat.public_id;
				}

				convo.receive_transactional_sms = contact.receive_transactional_sms;
				convo.receive_transactional_emails = contact.receive_transactional_emails;
				convo.receive_feedback_sms = contact.receive_feedback_sms;
				convo.receive_feedback_emails = contact.receive_feedback_emails;

				dob = contact.dob;
			}
		}

		let number = await MessagesService.getSafeNumber(locationId);

		// Check if we should show the signature. By default if it's enabled we should show it.
		let showSignature = user.enable_messenger_signature;

		// Check if we prefer to have our signature enabled/disabled for this specific contact
		let cachedContactSignaturePreference = MessagesService.getSignaturePreference(convo.contact_id);
		if (cachedContactSignaturePreference !== null) {
			showSignature = cachedContactSignaturePreference;
		}

		// Don't allow message signatures in teamchat
		if (mode === MODE.team) {
			showSignature = false;
		}

		await this.update({
			loading: false,
			locationId: locationId,
			convo: convo,
			messageInput: "",
			messageSubject: "",
			mode: mode,
			isChatbotActive: false,
			messageInput: messageInputDraft,
			sendButtonDisabled: messageInputDraft.length > 0 ? false : true,
			members: members,
			showSendCRMConfirmation: false,
			needsTour: !UserService.hasToured(tour, user.id),
			locationSmsNumber: number,
			referenceMessageId: null,
			highlightReferenceId: false,
			teamChatSearchTerm: "",
			showTeamChatMessageSearch: false,
			showTeamChatTaskMessages: false,
			fbEnabled: true,
			userSignatureEnabled: user.enable_messenger_signature,
			userSignature: user.messenger_signature || "",
			userHtmlSignature: user.messenger_html_signature || "",
			userClickToSendEnabled: user.messenger_click_to_send || false,
			showSignature: showSignature,
			signatureProblem: false,
			replyMessageId: null,
			composeTextHeight: MIN_COMPOSE_HEIGHT,
			composeContainerHeight: MIN_COMPOSE_CONTAINER_HEIGHT,
			secureChatPublicId,
			showPaymentRequest: false,
			showPaymentPackageModal: false,
			paymentPackageText: "",
			dob
		});

		// Close the search input
		if (this.teamchatSearchInput && this.teamchatSearchInput.onClose) {
			this.teamchatSearchInput.onClose();
		}

		await this.generateSignaturePreview();

		if (mode === MODE.customer) {
			this.updateChatbotStatus("", contact.chatbot_id);
			this.updateChatbotPermissions();
			await TemplateService.getMessageVariables();
		}

		if (this.messageInput) {
			try {
				this.messageInput.addEventListener("paste", this.onFilePaste, false);

				this.messageInput.focus();
			} catch (error) {
				console.log(error);
			}
		}

		await this.fetchTasks();
	};

	sendMessage = async (sendAndClose = false, sendAfter, overrideText = null, isScheduledMessage) => {
		let {
			locationId,
			convo,
			messageInput,
			messageSubject,
			mode,
			selectedTemplateId,
			templateSelected,
			eventType,
			sending,
			attachmentMediaIds,
			currentGifAttachment,
			mediaFromSelectedTemplate,
			showEmojis,
			showGifs,
			showGifsOverlay,
			showSignature,
			replyMessageId
		} = this.state;

		try {
			if ((!this.isMessageInputValid() && !overrideText && (!attachmentMediaIds || attachmentMediaIds.length === 0) && !currentGifAttachment) || sending) {
				return;
			}

			let scheduleMessageToastId = null;
			if (isScheduledMessage) {
				scheduleMessageToastId = ToastService.info("Scheduling message ...");
			}

			await this.update({
				sending: true
			});

			if (overrideText && overrideText.length > 0) {
				messageInput = overrideText;
			}

			let mediaList = null;

			let companyId = UserService.getActiveCompany().id;

			// XXX If the user is a super user visiting another company, the location id should be
			// be demandhub; the user's location, not the one they are visiting visiting one
			if (UserService.isSuperOrCsVisitingAnotherCompany() && mode === MODE.team) {
				locationId = config.DEMANDHUB.LOCATION.ID;
				companyId = config.DEMANDHUB.COMPANY.ID;
			}

			// If there are attachments
			if (attachmentMediaIds && attachmentMediaIds.length > 0) {
				mediaList = await MessagesService.uploadLocalMedia({
					mediaIds: attachmentMediaIds,
					mode,
					medium: convo.medium,
					optimize: !mediaFromSelectedTemplate,
					companyId,
					locationId
				});

				if (!mediaList) {
					ToastService.error(`Error uploading attachment${attachmentMediaIds.length > 1 ? "s" : ""}. Please try again.`);
					throw new Error(`Error uploading attachment${attachmentMediaIds.length > 1 ? "s" : ""}.`);
				}

				MessagesService.clearLocalMedia();
			} else if (currentGifAttachment) {
				try {
					// If we have selected a gif from the gif selector
					let media = await MessagesService.uploadMedia({ file: currentGifAttachment, mode, medium: convo.medium, companyId, locationId });
					mediaList = [media];
				} catch (error) {
					ToastService.error("Error uploading gif. Please try again.");
					throw error;
				}
			}

			let mediaIds = [];
			// Check if we received media back from platform
			if (mediaList && mediaList.length > 0) {
				for (const media of mediaList) {
					if (mode === MODE.customer && (media.type === MEDIA_TYPES.video || media.type === MEDIA_TYPES.file || media.type === MEDIA_TYPES.audio)) {
						messageInput += messageInput.length > 0 ? ` ${media.short_url}` : media.short_url;
					}
				}

				mediaIds = mediaList.map(m => m.id);
			}

			let message = null;

			if (mode === MODE.customer) {
				message = await MessagesService.sendMessage({
					locationId,
					contactId: convo.contact_id,
					content: messageInput,
					subject: messageSubject,
					mediaIds,
					medium: convo.medium,
					selectedTemplateId,
					templateSelected,
					eventType,
					sendAfter,
					showSignature
				});

				if (message && isScheduledMessage) {
					ToastService.dismiss(scheduleMessageToastId);
					ToastService.info(`Scheduled send for \n ${moment(sendAfter).format("ddd, MMM Do YYYY @ h:mm a")}`);
				}
			} else if (mode === MODE.team) {
				// Check if we are replying to a message
				let reply = null;
				if (replyMessageId) {
					// Fetch the message we are replying to
					let messageReplyingTo = await MessagesService.getMessage(replyMessageId);

					if (!messageReplyingTo) {
						ToastService.error("The message you are replying to no longer exists.");
						return;
					}

					// Create reply object
					reply = {
						id: messageReplyingTo.id,
						name: messageReplyingTo.sender_user_name,
						content: messageReplyingTo.content
					};

					// Append the image to this reply if the original message has one
					if (messageReplyingTo.media && messageReplyingTo.media.length > 0) {
						// For now, the reply media id will only be the first media object
						reply.mediaId = messageReplyingTo.media[0].id;
					}

					// Append the image to this reply if the original message has one
					// XXX TODO: DH-2331  Remove this after we've removed media urls
					if (messageReplyingTo.media && messageReplyingTo.media.length > 0) {
						reply.mediaUrl = messageReplyingTo.media[0].download_url;
					}
				}

				// If the teamchat message is a scheduled message. An entry gets created in the teamchat queue, a message is not yet created technically
				if (isScheduledMessage) {
					message = null;

					let queuedTeamchatMessage = await TeamChatService.createScheduledMessage({
						companyId: UserService.getActiveCompany().id,
						locationId: UserService.getActiveLocation().id,
						conversationId: convo.conversationId,
						content: messageInput,
						mediaIds: mediaIds,
						sendAfter: sendAfter
					});

					if (!queuedTeamchatMessage) {
						ToastService.error("Failed to schedule message. Please try again.");
						this.update({
							sendButtonDisabled: false,
							sending: false
						});
						return;
					}

					this.clearMessageInput();
					this.fetchTeamchatQueuedMessageCount();

					await this.update({
						sendButtonDisabled: true,
						sending: false
					});

					ToastService.dismiss(scheduleMessageToastId);
					ToastService.info(`Scheduled send for \n ${moment(sendAfter).format("ddd, MMM Do YYYY @ h:mm a")}`);

					this.closePopups();
					return;
				} else {
					message = await MessagesService.sendInternalMessage({
						locationId,
						conversationId: convo.conversationId,
						content: messageInput,
						mediaIds: mediaIds,
						reply
					});
				}
			}

			// Show the toast if the message is not defined and it is a customer chat or a non scheduled message teamchat
			if (!message && (mode === MODE.customer || (mode === MODE.team && !isScheduledMessage))) {
				ToastService.error("An error occurred trying to send your message. Please try again.");
			}

			if (!message) {
				throw new Error("Error creating message.");
			}

			this.onTeamChatMessageSearchInputChange("");

			if (this.messageListComponent) {
				await this.messageListComponent.onNewMessage(message, true, false);
			}

			this.clearMessageInput();

			this.props.onSend(message, 0);

			this.update({
				sendButtonDisabled: true
			});

			if (sendAndClose) {
				this.onStatusChangeClicked();
			}

			/**
			 * Reset states and close components if needed
			 */

			await this.update({
				messageSubject: "",
				composeTextHeight: MIN_COMPOSE_HEIGHT,
				composeContainerHeight: MIN_COMPOSE_CONTAINER_HEIGHT,
				sending: false,
				currentGifAttachment: null
			});

			// Clear the gifs component of it's current file
			if (this.gifsComponent) {
				this.gifsComponent.removeGifUpload();
			}

			// Clear the reply message of any data
			await this.update({
				replyMessageId: null
			});

			this.closePopups();
		} catch (error) {
			console.log(error);
			this.update({
				sending: false,
				sendButtonDisabled: messageInput.length === 0 && attachmentMediaIds.length === 0 && !currentGifAttachment
			});
		}
	};

	closePopups = () => {
		let { showEmojis, showGifs, showGifsOverlay } = this.state;

		// Close any attachments open
		if (this.attachmentComponent) {
			this.attachmentComponent.hide();
		}

		// Close an emoji selector if it's open
		if (showEmojis) {
			this.update({
				showEmojis: false
			});
		}

		// Close an gifs selector if it's open
		if (showGifs) {
			this.update({
				showGifs: false
			});
		}

		// Close an gifs preview if it's open
		if (showGifsOverlay) {
			this.update({
				showGifsOverlay: false
			});
		}
	};

	onInternalMessageUserRemoved = async () => {
		// Reset the component so everyone has an updated list of members and a fresh thread
		this.resetComponent();
	};

	fetchTeamchatQueuedMessageCount = async () => {
		let { convo, mode } = this.props;

		if (mode !== MODE.team || !convo || !convo.conversationId) {
			await this.update({ teamchatQueuedMessageCount: 0 });
			return;
		}

		let teamchatQueuedMessageCount = await TeamChatService.countScheduledMessages(convo.conversationId);
		await this.update({ teamchatQueuedMessageCount });
	};

	/**
	 * Updates reply suggestion box to include latest reply suggestions
	 */
	updateReplySuggestions = newReplySuggestions => {
		// New suggestions to show
		this.replySuggestions.updateReplySuggestions(newReplySuggestions);
	};

	isMentionsComponentOpen = () => {
		return this.mentionsComponent && this.mentionsComponent.isOpen();
	};

	onEnter = e => {
		let { userClickToSendEnabled } = this.state;
		if (!userClickToSendEnabled && e.keyCode === KEYS.enter && e.shiftKey === false && !this.isMentionsComponentOpen()) {
			e.preventDefault();
			this.sendMessage();
		}

		if (this.isMentionsComponentOpen() && (e.keyCode === KEYS.up || e.keyCode === KEYS.down || e.keyCode === KEYS.enter || e.keyCode === KEYS.tab)) {
			e.preventDefault();
			this.mentionsComponent.triggerKeyboardEvent(e.keyCode);
		}
	};

	onMessengerCloseConvosTourClosed = () => {
		let { userId } = this.state;

		UserService.completeTour(TOURS.messengerCloseConvos, userId);

		this.update({
			needsTourForClosingConvos: false
		});
	};

	clearMessageInput = async () => {
		await this.update({
			messageInput: ""
		});
	};

	isMessageInputValid = () => {
		let { messageInput } = this.state;
		return messageInput.replace(/\s/g, "").length > 0;
	};

	onMessageInput = async e => {
		this.processTypingEvents();

		let messageInput = e.target.value;

		// Calculate the number of lines based on the scrollHeight and line height
		let lines = Math.floor(this.messageInput.scrollHeight / COMPOSE_LINE_HEIGHT);

		// If the current lines are less than then the minimum number of lines allowed for the text area anchor it to the minimum
		if (lines <= MIN_COMPOSE_LINES) {
			lines = MIN_COMPOSE_LINES;
		}
		// If the current lines are more than the max number of lines, anchor the lines to the max
		else if (lines > MAX_COMPOSE_LINES) {
			lines = MAX_COMPOSE_LINES;
		}

		// Compute the textarea wrapper (mb-thread-compose__input) height by multiplying the number of lines (+1 for internal padding and border width) by the COMPOSE_LINE_HEIGHT (24px), also add an additional 2 px to compensate for the border width
		let textAreaHeight = (lines + 1) * COMPOSE_LINE_HEIGHT + 2;

		// Compute the overall compose area (mb-thread-compose) height and dynamically change it as the textarea wrapper grows
		let containerHeight = MIN_COMPOSE_CONTAINER_HEIGHT + textAreaHeight - MIN_COMPOSE_HEIGHT;

		// XXX - Dirty trick for now, will address in DH-2553.1
		if (messageInput.length === 0) {
			textAreaHeight = MIN_COMPOSE_HEIGHT;
			containerHeight = MIN_COMPOSE_CONTAINER_HEIGHT;
		}

		// We should update the message input textarea as soon as possible to prevent UI syncing issues
		await this.update({
			messageInput: messageInput,
			selectedTemplateId: null,
			templateSelected: false,
			sendButtonDisabled: true,
			eventType: EVENT_TYPES.message,
			composeTextHeight: textAreaHeight,
			composeContainerHeight: containerHeight
		});

		// We look to see if anything that was typed can be replaced
		if (this.state.mode === MODE.customer) {
			let messageInputReplacement = await this.replaceVariables(messageInput);

			await this.update({ messageInput: messageInputReplacement });
		}

		if (this.isMessageInputValid()) {
			await this.update({
				sendButtonDisabled: false
			});
		}
	};

	async replaceVariables(text) {
		let { convo, locationSmsNumber } = this.state;
		return await TemplateService.getMessage(text, convo.name, null, locationSmsNumber);
	}

	async onNewMessage(message) {
		this.updateChatbotStatus(message);
		let { convo, mode } = this.state;

		// If we are in customer chat mode
		if (mode === MODE.customer && message.Contact) {
			// Get contact id of the new message
			let newMessageContactId = message.contact_id;

			// Extract the assigned user and contact id of the conversation we currently have open
			let conversationAssignedUser = convo.assigned_user_id;
			let conversationContactId = convo.contact_id;

			// If the current conversation we have in view has a new message comming in
			if (conversationContactId === newMessageContactId) {
				// Get the assigned user and the receive transactional flags
				let newMessageAssignedUser = message.Contact.assigned_user_id;
				let newMessageReceiveTransactionalSms = message.Contact.receive_transactional_sms;
				let newMessageReceiveTransactionalEmails = message.Contact.receive_transactional_emails;
				let newMessageReceiveFeedbackSms = message.Contact.receive_feedback_sms;
				let newMessageReceiveFeedbackEmails = message.Contact.receive_feedback_emails;

				let convoUpdated = false;

				// if there are any changes with the assigned user
				if (conversationAssignedUser !== newMessageAssignedUser) {
					// Update the assigned user of our current conversation
					convo.assigned_user_id = newMessageAssignedUser;
					convoUpdated = true;
				}

				// if there are any changes with the receive transactional sms flag
				if (convo.receive_transactional_sms !== newMessageReceiveTransactionalSms) {
					convo.receive_transactional_sms = newMessageReceiveTransactionalSms;
					convoUpdated = true;
				}

				// if there are any changes with the receive transactional email flag
				if (convo.receive_transactional_emails !== newMessageReceiveTransactionalEmails) {
					convo.receive_transactional_emails = newMessageReceiveTransactionalEmails;
					convoUpdated = true;
				}

				// if there are any changes with the receive transactional email flag
				if (convo.receive_feedback_sms !== newMessageReceiveFeedbackSms) {
					convo.receive_feedback_sms = newMessageReceiveFeedbackSms;
					convoUpdated = true;
				}

				// if there are any changes with the receive transactional email flag
				if (convo.receive_feedback_emails !== newMessageReceiveFeedbackEmails) {
					convo.receive_feedback_emails = newMessageReceiveFeedbackEmails;
					convoUpdated = true;
				}

				// if there were any changes to the current open conversation
				if (convoUpdated) {
					this.update({
						convo
					});
				}

				await this.updateMedium(message);
			}
		}

		if (this.messageListComponent) {
			this.messageListComponent.onNewMessage(message);
		}
	}

	handleStatusChange() {
		let { convo } = this.state;
		let newStatus = convo.status === STATE.open ? STATE.closed : STATE.open;

		if (this.props.onStatusChange) {
			let toastMessage = newStatus === STATE.open ? "Conversation opened." : "Conversation closed.";

			toast.info(toastMessage, {
				position: "bottom-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				className: "mb-toast"
			});

			this.props.onStatusChange(convo.contact_id, newStatus);
		}
	}

	onStatusChangeClicked = async () => {
		let { locationId, convo } = this.state;
		let newStatus = convo.status === STATE.open ? STATE.closed : STATE.open;
		let statusChanged = await MessagesService.updateConversationStatus(locationId, convo.contact_id, newStatus);

		if (!statusChanged) {
			return;
		}

		this.handleStatusChange();

		if (newStatus === STATE.closed) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.thread,
				action: GA_ACTIONS.messenger.thread.customer.topControls.closedConversationControl,
				label: GA_ACTIONS.messenger.thread.customer.topControls.closedConversationControl
			});
		} else {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.thread,
				action: GA_ACTIONS.messenger.thread.customer.topControls.openConversationControl,
				label: GA_ACTIONS.messenger.thread.customer.topControls.openConversationControl
			});
		}
	};

	onChatbotToggle = () => {
		let { isChatbotActive } = this.state;

		if (isChatbotActive) {
			this.update({
				showChatbotKillAlert: true
			});
		} else {
			this.update({
				showChatbots: true,
				showMoreComposeActions: false
			});
		}
	};

	onChatbotsClosed = () => {
		this.update({
			showChatbots: false
		});
	};

	onShowEmojis = () => {
		this.update({
			showEmojis: true
		});
	};

	onShowGifs = () => {
		this.update({
			showGifs: true,
			showMoreComposeActions: false
		});
	};

	onEmojiClose = () => {
		this.update({ showEmojis: false });
	};

	onEmojiSelected = emoji => {
		let { messageInput, messageInputSelection } = this.state;

		let start = messageInput.substring(0, messageInputSelection);
		let end = messageInput.substring(messageInputSelection, messageInput.length);

		if (emoji) {
			this.update({
				messageInput: `${start}${emoji} ${end}`,
				sendButtonDisabled: false
			});
		}

		this.update({
			showEmojis: false
		});

		if (this.messageInput) {
			this.messageInput.focus();
		}
	};

	onSignatureToggle = event => {
		let { showSignature, convo } = this.state;

		// Toggle the value
		showSignature = !showSignature;

		// Update state
		this.update({
			showSignature: showSignature,
			showMoreComposeActions: false
		});

		// Save preference for this contact in local storage
		MessagesService.setSignaturePreference(convo.contact_id, showSignature);
	};

	onGifChanged = file => {
		let { messageInput, attachmentMediaIds } = this.state;
		if (!file) {
			this.update({
				currentGifAttachment: null,
				showGifs: false,
				showGifsOverlay: false,
				sendButtonDisabled: messageInput.length === 0 && attachmentMediaIds.length === 0
			});
		} else {
			this.update({
				currentGifAttachment: file,
				showGifs: false,
				showGifsOverlay: true,
				sendButtonDisabled: false
			});
			if (this.messageInput) {
				this.messageInput.focus();
			}
		}
	};

	onReplyMessageCancel = () => {
		this.update({
			replyMessageId: null
		});
	};

	onReviewInviteClick = () => {
		let { convo } = this.state;

		this.update({
			showMoreComposeActions: false
		});

		// Open and fill the review invite modal
		NotificationService.publish("openReviewInvite", convo.contact_id);
	};

	onSendShortLinkClick = () => {
		this.update({ showSendShortLinkModal: true, showMoreComposeActions: false });
	};

	onShowTemplates = async () => {
		this.update({
			showTemplates: true
		});
	};

	onTemplateSelected = async template => {
		let messageInput = template.msg_text;
		let subject = template.msg_subject;

		if (template.Media && template.Media.length > 0) {
			// For now, we limit templates to only have one media
			let media = template.Media[0];

			if (this.attachmentComponent && this.attachmentComponent.showAttachment) {
				let downloadUrl = UtilityService.appendQueryAuthToken(media.download_url);
				let response = await fetch(downloadUrl, {
					method: "GET"
				});

				let blob = await response.blob();
				blob.name = `${media.file_name}.${media.extension}`;

				let newFile = new File([blob], media.file_name);
				let files = [newFile];
				this.attachmentComponent.showAttachment(files, true);

				await this.update({
					mediaFromSelectedTemplate: true
				});
			}
		}

		await this.update({
			selectedTemplateId: template.id,
			templateSelected: true,
			showTemplates: false,
			messageInput: messageInput,
			messageSubject: subject,
			sendButtonDisabled: false
		});
		if (this.messageInput) {
			this.messageInput.focus();
		}
	};

	hideCustomerMenu = () => {
		this.update({
			showCustomerMenu: false
		});
	};

	showCustomerMenu = () => {
		this.update({
			showCustomerMenu: true
		});
	};

	onSuggestionSelected = async suggestion => {
		await this.update({
			messageInput: suggestion,
			sendButtonDisabled: false
		});
		this.messageInput.focus();
	};

	onSeeScheduledMessages = close => {
		if (close) {
			// Hide this message from showing
			this.update({ teamchatQueuedMessageCount: 0 });
			return;
		}

		this.update({
			showTeamChatMessageSearch: false
		});

		let { convo } = this.state;
		if (this.props.onSeeScheduledMessages) {
			this.props.onSeeScheduledMessages(convo);
		}
	};

	killChatbotConvo = async () => {
		try {
			let contact_id = this.state.convo.contact_id;
			await ChatbotService.killConversation(contact_id);
		} catch (err) {
			console.error(err);
		}
	};

	updateChatbotPermissions = async () => {
		try {
			let isChatbotAllowed = await ChatbotService.isUserAllowed();
			await this.update({
				chatbotsEnabled: isChatbotAllowed
			});
		} catch (err) {
			console.error(err);
		}
	};

	updateChatbotStatus = async (message, initialChatbotId = null) => {
		try {
			// Fetch initial Chatbot state for conversation
			if (initialChatbotId) {
				await this.update({
					isChatbotActive: true
				});
			} else {
				// Update chatbot status if Chatbot change event is received
				if (
					message.event_type === EVENT_TYPES.chatbotActivated ||
					message.event_type === EVENT_TYPES.chatbotDeactivated ||
					message.event_type === EVENT_TYPES.chatbotForceQuit
				) {
					let isChatbotActive = message.Contact.chatbot_id ? true : false;
					await this.update({
						isChatbotActive: isChatbotActive
					});
				}
			}
		} catch (err) {
			console.error(err);
		}
	};

	onCreateTaskMessageClick = messageId => {
		this.update({
			showModalTasks: true,
			taskCreationMessageId: messageId
		});
	};

	onTaskSubmit = async () => {
		await this.update({ showModalTasks: false, taskCreationMessageId: null });

		ToastService.info("A task has been created.");

		await this.fetchTasks();
	};

	onHideTaskModal = async () => {
		await this.update({ showModalTasks: false, taskCreationMessageId: null });
	};

	updateMedium = async message => {
		let { convo } = this.state;

		try {
			if (message.event_type === EVENT_TYPES.secureChatStarted) {
				convo.medium = MEDIUM.secure.key;

				await this.update({
					medium: MEDIUM.secure.key,
					convo
				});
			}

			if (message.event_type === EVENT_TYPES.secureChatEnded) {
				let contact = await ContactService.getContact(convo.contact_id);

				if (!contact.SecureChat) {
					return;
				}

				convo.medium = contact.preferred_medium;

				await this.update({
					medium: contact.preferred_medium,
					convo
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	onTemplatesClosed = async () => {
		this.update({
			showTemplates: false
		});
	};

	onShowAttachments = async () => {
		this.attachmentComponent.show();
	};

	onShowEditTeamChat = async () => {
		let { convo } = this.state;

		this.update({
			showEditTeamChat: true
		});

		if (convo.type === CONVERSATION.channel) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.thread,
				action: GA_ACTIONS.messenger.thread.teamchat.openEditChannelModal,
				label: GA_ACTIONS.messenger.thread.teamchat.openEditChannelModal
			});
		} else if (convo.type === CONVERSATION.dm) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.thread,
				action: GA_ACTIONS.messenger.thread.teamchat.openEditDMModal,
				label: GA_ACTIONS.messenger.thread.teamchat.openEditDMModal
			});
		}
	};

	onTeamChatMessageSearchInputChange = async searchTerm => {
		let { referenceMessageId } = this.state;

		await this.update({
			teamChatSearchTerm: searchTerm,
			referenceMessageId: searchTerm.length === 0 ? null : referenceMessageId,
			showTeamChatMessageSearch: searchTerm.length > 0
		});
	};

	onTeamChatMessageResultSelected = async messageId => {
		await this.update({
			teamChatSearchTerm: "",
			referenceMessageId: messageId,
			highlightReferenceId: true,
			showTeamChatMessageSearch: false
		});
	};

	onTeamChatReplyClicked = async messageId => {
		let { referenceMessageId } = this.state;

		await this.update({
			teamChatSearchTerm: "",
			referenceMessageId: messageId,
			highlightReferenceId: true,
			showTeamChatMessageSearch: false
		});

		// Need to do this because if click twice, messageId is the same so no render is called
		if (referenceMessageId === messageId && this.messageListComponent) {
			this.messageListComponent.resetComponent();
		}
	};

	onCloseTeamChatTaskList = async () => {
		await this.update({ showTeamChatTaskMessages: false });

		await this.fetchTasks();
	};

	onReferenceMessageClickedTaskList = async messageId => {
		let { referenceMessageId } = this.state;

		await this.update({ showTeamChatTaskMessages: false });

		await this.update({
			teamChatSearchTerm: "",
			referenceMessageId: messageId,
			highlightReferenceId: true
		});

		// Need to do this because if click twice, messageId is the same so no render is called
		if (referenceMessageId === messageId && this.messageListComponent) {
			this.messageListComponent.resetComponent();
		}
	};

	onShowPaymentRequest = async () => {
		let location = UserService.getActiveLocation();

		let paymentsEnabled = PaymentService.isPaymentsEnabled();
		let paymentsConnected = location.stripe_account_id && location.stripe_account_id.length > 0;

		if (!paymentsEnabled) {
			await this.update({
				showPaymentPackageModal: true,
				paymentPackageText: "Upgrade to the Payments Package to enable sending and receiving payments, and more!"
			});
			return;
		}

		if (!paymentsConnected) {
			await this.update({
				showPaymentPackageModal: true,
				paymentPackageText: "Payments isn't currently setup. Chat with us to get started!"
			});
			return;
		}

		await this.update({
			showPaymentPackageModal: false,
			showPaymentRequest: true,
			showMoreComposeActions: false
		});
	};

	onPaymentRequestClosed = async () => {
		this.update({
			showPaymentRequest: false
		});
	};

	onVideoChatClicked = async () => {
		this.update({
			showStartVideoChatAlert: true,
			showMoreComposeActions: false
		});
	};

	onConfirmStartVideoChat = async confirm => {
		this.update({ showStartVideoChatAlert: false });
		if (!confirm) {
			return;
		}
		let { convo } = this.state;
		MessagesService.createVideoChat(convo.contact_id);
	};

	onSecureChatToggle = async () => {
		let { convo } = this.state;
		let { medium } = convo;

		if (medium === MEDIUM.sms.key) {
			await this.update({
				showStartSecureChatAlert: true,
				showMoreComposeActions: false
			});
		} else if (medium === MEDIUM.secure.key) {
			await this.update({
				showEndSecureChatAlert: true
			});
		}
	};

	onConfirmStartSecureChat = async confirm => {
		let { convo } = this.state;

		await this.update({ showStartSecureChatAlert: false });

		if (!confirm) {
			return;
		}

		let secureChat = await MessagesService.createSecureChat({ contactId: convo.contact_id });

		await this.update({
			secureChatPublicId: secureChat.public_id
		});
	};

	onConfirmEndSecureChat = async confirm => {
		let { secureChatPublicId } = this.state;

		await this.update({ showEndSecureChatAlert: false });

		if (!confirm) {
			return;
		}

		MessagesService.endSecureChat({ secureChatPublicId });
	};

	onEditTeamChatClose = async teamChat => {
		let { convo } = this.state;

		if (teamChat) {
			NotificationService.publish("internalConversationUpdate", teamChat);
			convo.name = teamChat.name;
		}

		this.update({ showEditTeamChat: false });

		this.update({
			showEditTeamChat: false,
			convo
		});

		if (convo.type === CONVERSATION.channel) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.thread,
				action: GA_ACTIONS.messenger.thread.teamchat.savedEditChannelModal,
				label: GA_ACTIONS.messenger.thread.teamchat.savedEditChannelModal
			});
		} else if (convo.type === CONVERSATION.dm) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.thread,
				action: GA_ACTIONS.messenger.thread.teamchat.savedEditDMModal,
				label: GA_ACTIONS.messenger.thread.teamchat.savedEditDMModal
			});
		}

		this.resetComponent();
		this.messageListComponent.resetComponent();
	};

	onShowContactDetails = async () => {
		// Show the modal and hide the customer menu
		this.update({
			showCustomerMenu: false,
			showContactDetails: true
		});

		GAService.GAEvent({
			category: GA_CATEGORIES.editContactModal.name,
			action: GA_ACTIONS.editContactModal.openedEditContactModal,
			label: GA_ACTIONS.editContactModal.openedEditContactModal
		});
	};

	onContactDetailsClosed = async contact => {
		let { convo } = this.state;

		await this.update({
			showContactDetails: false
		});

		// If we don't have a contact, then nothing changed
		// And if the convo no longer exist (for example on a contact delete) then no work needed to be done
		if (!contact || !convo) {
			return;
		}

		this.onNameChanged(contact.name);

		// If contact status changed
		if (contact.status !== convo.status) {
			this.handleStatusChange();
		}

		// Yucky but necessary to block the thread-compose ui
		convo.receive_transactional_sms = contact.receive_transactional_sms;
		convo.receive_transactional_emails = contact.receive_transactional_emails;
		convo.receive_feedback_sms = contact.receive_feedback_sms;
		convo.receive_feedback_emails = contact.receive_feedback_emails;
		convo.status = contact.status;

		// If deleting the contact
		if (this.props.onStatusChange && convo.status === STATE.deleted) {
			this.props.onStatusChange(convo.contact_id, contact.status);
		}

		await this.update({
			convo
		});
	};

	onContactDetailsSaved = async contact => {
		// Close the contact details modal
		this.onContactDetailsClosed(contact);

		// Refresh the message templates
		if (this.templates && this.templates.onRefreshMessageTemplate) {
			this.templates.onRefreshMessageTemplate();
		}
	};

	onAttachmentChanged = async mediaIds => {
		let { messageInput, currentGifAttachment } = this.state;
		await this.update({
			attachmentMediaIds: mediaIds,
			sendButtonDisabled: messageInput.length === 0 && mediaIds.length === 0 && !currentGifAttachment,
			mediaFromSelectedTemplate: mediaIds.length !== 0
		});

		if (this.messageInput) {
			this.messageInput.focus();
		}
	};

	onNameChanged = async name => {
		let { convo } = this.state;

		convo.name = name;

		await this.update({
			convo
		});

		NotificationService.publish("contactUpdated", convo);

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.thread,
			action: GA_ACTIONS.messenger.thread.customer.topControls.savedRenameContactTool,
			label: GA_ACTIONS.messenger.thread.customer.topControls.savedRenameContactTool
		});
	};

	processTypingEvents = () => {
		let user = UserService.get();
		let { convo, locationId } = this.state;

		let fullName = UserService.getCurrentUserFullName();

		RealtimeService.sendTypingEvent({
			userId: user.id,
			contactId: parseInt(convo.contact_id),
			conversationId: parseInt(convo.conversationId),
			locationId: locationId,
			userName: fullName,
			event: MESSAGING.typingEvents.started
		});
	};

	onTypingEvent = typingEvent => {
		let user = UserService.get();
		let { convo, mode } = this.state;

		if (!convo) {
			return;
		}

		let { contactId, conversationId, userId, event } = typingEvent;

		if (user.id === userId) {
			return;
		}

		if (mode === MODE.team && convo.conversationId !== conversationId) {
			return;
		}

		if (mode === MODE.customer && convo.contact_id !== contactId) {
			return;
		}

		if (event === MESSAGING.typingEvents.started) {
			this.typingStartedEvent(typingEvent);
		}

		if (event === MESSAGING.typingEvents.stopped) {
			this.typingStoppedEvent(typingEvent);
		}
	};

	typingStartedEvent = async typingEvent => {
		let { convo, typers } = this.state;

		let { contactId, userId, userName } = typingEvent;

		let typingTaskKey = null;

		// If this is an event from a user. Note that this event also contains a contactId if it is a customer convo
		if (userId) {
			typingTaskKey = `userId-${userId}`;

			// If the user is not in our list of typers
			let found = typers.some(typer => {
				return typer.userId === userId;
			});

			// If the user is not in our list of typers
			if (!found) {
				typers.push({ userName, userId });
			}
		} else if (contactId) {
			// If this is an event from a contact

			typingTaskKey = `contactId-${userId}`;

			// See if the contact is found in the list of typers
			let found = typers.some(typer => {
				return typer.contactId === contactId;
			});

			// If the contact is typing, and wasn't already in the typers list
			if (!found) {
				typers.push({ contactName: convo.name, contactId: contactId });
			}
		}

		if (!typingTaskKey) {
			return;
		}

		await this.updateTypers(typers);

		this.removeTypingTask({ key: typingTaskKey });

		// Add a new typing timeout for this user/contact
		this.addTypingTimeout({ key: typingTaskKey, typingEvent });
	};

	typingStoppedEvent = async typingEvent => {
		let { contactId, userId } = typingEvent;

		let typingTaskKey = null;

		// If this is an event from a user. Note that this event also contains a contactId if it is a customer convo
		if (userId) {
			typingTaskKey = `userId-${userId}`;
		} else if (contactId) {
			typingTaskKey = `contactId-${contactId}`;
		}

		if (!typingTaskKey) {
			return;
		}

		await this.removeTyper({ userId, contactId });
		this.removeTypingTask({ key: typingTaskKey });
	};

	removeTyper = async ({ userId, contactId }) => {
		let { typers } = this.state;

		for (let i = 0; i < typers.length; i++) {
			if (userId && typers[i].userId && typers[i].userId === userId) {
				// Remove this user from the typers list
				typers.splice(i, 1);
				break;
			} else if (contactId && typers[i].contactId && typers[i].contactId === contactId) {
				// Remove this contact from the typers list
				typers.splice(i, 1);
				break;
			}
		}

		await this.updateTypers(typers);
	};

	removeTypingTask = ({ key }) => {
		// If this user/contact typing timeout is already there, clear the current one so that we can add a new typing timeout
		if (this.typingTask[key]) {
			clearTimeout(this.typingTask[key]);
			delete this.typingTask[key];
		}
	};

	addTypingTimeout = ({ key, typingEvent }) => {
		let { mode } = this.state;
		let { contactId, userId } = typingEvent;

		let milliseconds = 1000; // 1 second

		// If it's customer mode and the contact is typing
		if (mode === MODE.customer && !userId && contactId) {
			milliseconds = 10000; // 10 seconds
		} else if (mode === MODE.customer) {
			milliseconds = 5000;
		}

		// Create a set timeout for this user, after x time remove them from the typers list
		this.typingTask[key] = setTimeout(async () => {
			await this.removeTyper({ userId, contactId });
		}, milliseconds);
	};

	updateTypers = async typers => {
		await this.update({
			typers: typers
		});

		if (typers.length > 0) {
			let content = "Multiple people are typing ";

			if (typers.length === 1) {
				let name = typers[0].userName || typers[0].contactName || "Contact";
				content = `${name} is typing `;
			}

			this.update({
				typingContent: content,
				cachedTypingContent: content
			});
		} else {
			this.update({
				typingContent: ""
			});
		}
	};

	onMessageInputSelect = event => {
		this.update({
			messageInputSelection: event.target.selectionStart
		});
	};

	onMentionSelected = newString => {
		this.update({
			messageInput: newString
		});

		this.messageInput.focus();
	};

	onTourClosed = () => {
		let user = UserService.get();
		let { mode } = this.state;

		let tour = mode === MODE.customer ? TOURS.customerThread : TOURS.teamchatThread;

		UserService.completeTour(tour, user.id);

		this.update({
			needsTour: false
		});
	};

	onCloseShortLinkModal = () => {
		this.update({ showSendShortLinkModal: false });
	};

	onShortLinkSelected = async longUrl => {
		let { messageInput } = this.state;

		if (messageInput && messageInput.length > 0) {
			messageInput += ` `;
		}
		messageInput += `${longUrl}`;

		await this.onMessageInput({ target: { value: messageInput } });

		if (this.messageInput) {
			this.messageInput.focus();
		}

		this.onCloseShortLinkModal();
	};

	onSendAfterDateSelected = async ({ sendAfter: date, close, scheduledMessage = false }) => {
		let { mode } = this.state;

		await this.sendMessage(close, date, null, scheduledMessage);

		// For the free creams and lotions
		await UtilityService.timeout(5000);

		if (mode === MODE.team) {
			await this.fetchTeamchatQueuedMessageCount();
			NotificationService.publish("teamChatScheduledMessageChange");
		} else if (mode === MODE.customer) {
			NotificationService.publish("customerScheduledMessageChange");
		}
	};

	onReplyToMessageClicked = async messageId => {
		await this.update({
			replyMessageId: messageId
		});

		// Focus the input
		if (this.messageInput) {
			this.messageInput.focus();
		}
	};

	onFileAdded = async files => {
		try {
			if (files) {
				this.attachmentComponent.showAttachment(files);
			}
		} catch (error) {
			console.error(`Thread.js - File Drag/Drop Error - ${error}`);
		}
	};

	onFilePaste = async event => {
		try {
			let files = [];

			for (const key in event.clipboardData.items) {
				try {
					let file = event.clipboardData.items[key] && event.clipboardData.items[key].getAsFile ? event.clipboardData.items[key].getAsFile() : null;
					if (file) {
						files.push(file);
					}
				} catch (error) {
					console.error(`Thread.js - Clipboard Error - ${error}`);
				}
			}
			if (files && files.length > 0) {
				this.attachmentComponent.showAttachment(files);
				event.preventDefault();
				event.stopPropagation();
			}
		} catch (error) {
			console.error(`Thread.js - File Copy/Paste Error - ${error}`);
		}
	};

	onAssignedUserSelect = user => {
		let { convo } = this.state;

		convo.assigned_user = user.name;
		convo.assigned_user_id = user.assigned_user_id = user.id;

		NotificationService.publish("contactUpdated", convo);

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.thread,
			action: GA_ACTIONS.messenger.thread.customer.topControls.selectedAssignedUserList,
			label: GA_ACTIONS.messenger.thread.customer.topControls.selectedAssignedUserList
		});
	};

	onAssignedUserListShow = show => {
		if (show) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.thread,
				action: GA_ACTIONS.messenger.thread.customer.topControls.openAssignedUserList,
				label: GA_ACTIONS.messenger.thread.customer.topControls.openAssignedUserList
			});
		}
	};

	isDisabled = () => {
		let { isChatbotActive } = this.state;
		return isChatbotActive;
	};

	isComposeEnabled = () => {
		let user = UserService.get();
		let { mode, convo, fbEnabled, members, showTeamChatTaskMessages, showTeamChatMessageSearch } = this.state;

		// If the user is no longer a part of the team chat and if the user is able to send messages
		if (mode === MODE.team) {
			// If the user is searching something
			if (showTeamChatMessageSearch) {
				return false;
			}

			// Declare a variable to hold whether the compose should be enabled or not
			let isComposeEnabled = false;

			// Try to find ourselves in the members list
			for (let i = 0; i < members.length; i++) {
				let currentMemberId = members[i].id;
				if (currentMemberId === user.id) {
					// If it a broadcast channel, only admins are able to send messages
					if (convo && convo.channelType === CHANNEL_TYPE.broadcast.id) {
						// Only enabled for users that are admins
						if (members[i].is_admin) {
							isComposeEnabled = true;
						}
						break;
					}

					isComposeEnabled = true;
					break;
				}
			}

			// If we're no longer a part of the team chat
			if (!isComposeEnabled) {
				return false;
			}
		}

		let isReceiveTransactional = true;

		if (convo.medium === MEDIUM.email.key) {
			isReceiveTransactional = convo.receive_transactional_emails;
		} else if (convo.medium === MEDIUM.sms.key) {
			isReceiveTransactional = convo.receive_transactional_sms;
		}

		return (
			user.GroupPermission.create_messages &&
			((isReceiveTransactional && mode === MODE.customer) || mode === MODE.team) &&
			fbEnabled &&
			!showTeamChatTaskMessages
		);
	};

	placeholderMessage = () => {
		let { isChatbotActive } = this.state;

		if (isChatbotActive) {
			return "A chatbot is currently active ...";
		}

		return "Type a message ...";
	};

	setTextAreaRef = ref => {
		this.messageInput = ref;
	};

	onPaymentPackageAlertClose = async confirm => {
		if (confirm) {
			SupportChatService.showNewMessage();
		}

		await this.update({
			showPaymentPackageModal: false
		});
	};

	fetchTasks = async () => {
		let { convo, locationId } = this.state;

		if (convo && convo.conversationId) {
			let conversationTasks = await LocationService.fetchTeamChatTasks({ locationId, conversationId: convo.conversationId, ignoreDone: true });

			await this.update({ conversationTasks });
		}
	};

	renderWelcomeMessage() {
		let user = UserService.get();
		return (
			<div className="mb-thread-welcome">
				<img className="mb-thread-welcome-img" src="https://cdn.demandhub.co/web-app/assets/messenger-welcome.svg" alt="Welcome" />
				<div className="mb-thread-welcome-title">Welcome to DemandHub Messenger!</div>
				<div className="mb-thread-welcome-subtitle">
					Select a chat in the left pane or click &nbsp;
					{user.GroupPermission.view_customer_messages ? <Icon.Edit3 size="16" /> : <Icon.PlusCircle size="16" />}
					&nbsp; to start a new conversation!
				</div>
			</div>
		);
	}

	renderPermissionDenied() {
		return (
			<div className="mb-thread-permission-denied">
				<div className="mb-thread-permission-denied-img">
					<Icon.AlertCircle />
				</div>
				<div className="mb-thread-permission-denied-title">Permission denied</div>
				<div className="mb-thread-permission-denied-subtitle">
					Sorry, you don't have the permissions to access all messages. You can only see messages assigned to you.{" "}
				</div>
			</div>
		);
	}

	renderMembers() {
		let { members } = this.state;

		if (!members) {
			return null;
		}

		// Find duplicate names
		let firstNames = members.map(item => item.first_name.toLowerCase());
		let duplicateIndexes = {}; // Keep track of which first name have duplicates

		for (let i = 0; i < firstNames.length; i++) {
			const firstName = firstNames[i];

			// If its the last element, there nothing to check anymore
			if (i === firstNames.length - 1) {
				continue;
			}

			// Check if there is another name that is the same
			let duplicateNameIndex = firstNames.indexOf(firstName.toLowerCase(), i + 1);

			// If there is a duplicate
			if (duplicateNameIndex >= 0) {
				// Add this index, and the index of the duplicate to the index key
				duplicateIndexes[i] = true;
				duplicateIndexes[duplicateNameIndex] = true;
			}
		}

		return members.map((member, index) => {
			let name = member.first_name;
			// If this name is a duplicate, show the full name
			if (duplicateIndexes[index]) {
				name = UserService.createFullName({ firstName: member.first_name, lastName: member.last_name });
			}

			// If it's not the last member add a comma
			if (index !== members.length - 1) {
				name += ", ";
			}

			return name;
		});
	}

	viewTeamChatMessageList = () => {
		let { mode, showTeamChatMessageSearch, showTeamChatTaskMessages } = this.state;

		if (mode === MODE.team && !showTeamChatMessageSearch && !showTeamChatTaskMessages) {
			return true;
		}

		return false;
	};

	onScroll = e => {
		if (this.viewTeamChatMessageList()) {
			let scrollHeight = e.target.scrollHeight;
			let scrollTop = e.target.scrollTop;
			let clientHeight = e.target.clientHeight;

			let bottom = scrollHeight - scrollTop - LOAD_MORE_HEIGHT <= clientHeight;
			let top = scrollTop < LOAD_MORE_HEIGHT;

			this.messageListComponent.scrolledToBottom(bottom);

			let showSeeNewestMessagesButton = false;

			if (scrollHeight && scrollHeight > 0) {
				// If we've scrolled passed the 25% of the message thread
				showSeeNewestMessagesButton = scrollTop / scrollHeight < SHOW_NEWEST_BUTTON_THRESHOLD;
			}

			this.messageListComponent.showNewestButton(showSeeNewestMessagesButton);

			if (this.messageListComponent && (top || bottom)) {
				// Load more messages
				// The height before we add more messages
				let height = this.messageListComponent.getScrollHeight();
				this.messageListComponent.loadMoreDebounced(top, height);
			}
		}
	};

	setScrollTop = height => {
		if (this.threadList) {
			setTimeout(() => {
				let scrollTop = this.threadList.scrollHeight - height + 45;
				// Just in case
				if (scrollTop < 45) {
					scrollTop = 50;
				}
				this.threadList.scrollTop = scrollTop;
			}, 0);
		}
	};

	renderDisabledMessage() {
		let {
			convo: { receive_transactional_sms, medium, receive_transactional_emails, channelType },
			mode,
			members,
			fbEnabled
		} = this.state;
		let user = UserService.get();

		if (mode === MODE.team && channelType === CHANNEL_TYPE.broadcast.id) {
			// Declare a variable to hold whether the user is a part of the team chat
			let isUserAdmin = false;

			// Try to find ourselves in the members list
			for (let i = 0; i < members.length; i++) {
				let currentMemberId = members[i].id;
				if (currentMemberId === user.id && members[i].is_admin) {
					isUserAdmin = true;
					break;
				}
			}

			// If we're no longer a part of the team chat
			if (!isUserAdmin) {
				return <div className="mb-thread-compose__disabled">This is a broadcast channel. Only Admins are able to send messages.</div>;
			}
		}

		if (medium === MEDIUM.sms.key && !receive_transactional_sms && mode === MODE.customer) {
			return <div className="mb-thread-compose__disabled">This customer has opted out from receiving incoming messages from this line.</div>;
		}

		if (medium === MEDIUM.email.key && !receive_transactional_emails && mode === MODE.customer) {
			return <div className="mb-thread-compose__disabled">This customer has opted out from receiving incoming messages from your business.</div>;
		}

		if (!fbEnabled) {
			return (
				<div className="mb-thread-compose__disabled">
					<div>
						Facebook doesn't allow responses after 7 days. For more information, see their
						<a href="https://developers.facebook.com/docs/messenger-platform/policy/policy-overview/#standard_messaging"> messaging guidelines</a>
					</div>
				</div>
			);
		}
		return null;
	}

	generateSignaturePreview = async () => {
		let { convo, userSignature, userHtmlSignature } = this.state;
		let { medium } = convo;

		let signaturePreview = "";

		if (medium === MEDIUM.email.key) {
			if (!userSignature && !userHtmlSignature) {
				signaturePreview = "No Signature";
				await this.update({ signatureProblem: true });
			} else if (userHtmlSignature) {
				signaturePreview = "Your HTML signature will be appended to the end of the email.";
			}
			// Fall back to plain text signature
			else {
				signaturePreview = userSignature;
			}
		} else {
			if (userSignature) {
				signaturePreview = userSignature;
			} else {
				signaturePreview = "No Signature";
				await this.update({ signatureProblem: true });
			}
		}

		await this.update({ signaturePreview });
	};

	render() {
		const user = UserService.get();
		let location = UserService.getActiveLocation();

		let { hide } = this.props;

		if (hide) {
			return null;
		}

		if (!this.state.convo || this.state.convo.status === STATE.deleted) {
			return this.renderWelcomeMessage();
		}

		// If the user does not have permissions to see messages assigned to others
		if (
			!user.GroupPermission.view_messages_assigned_to_others &&
			this.state.convo.assigned_user_id !== user.id &&
			this.state.convo.assigned_user_id > 0 &&
			this.state.mode === MODE.customer
		) {
			return this.renderPermissionDenied();
		}

		// If the user does not have permissions to see messages that are unassigned
		if (!user.GroupPermission.view_unassigned_messages && this.state.convo.assigned_user_id === 0 && this.state.mode === MODE.customer) {
			return this.renderPermissionDenied();
		}

		// If the user does not have permissions to see their messages
		if (!user.GroupPermission.view_customer_messages && this.state.convo.assigned_user_id === user.id && this.state.mode === MODE.customer) {
			return this.renderPermissionDenied();
		}

		// Payments related information
		let paymentsEnabled = PaymentService.isPaymentsEnabled();
		let paymentsConnected = location.stripe_account_id && location.stripe_account_id.length > 0;
		let paymentsAllowed = user.GroupPermission.create_payments;

		let hidePayment = paymentsEnabled && paymentsConnected && !paymentsAllowed;

		let {
			convo,
			isChatbotActive,
			locationId,
			showTemplates,
			sendButtonDisabled,
			showEmojis,
			showGifs,
			showGifsOverlay,
			typingContent,
			cachedTypingContent,
			mode,
			showEditTeamChat,
			messageInputSelection,
			messageInput,
			needsTour,
			showPaymentRequest,
			sending,
			showContactDetails,
			chatbotsEnabled,
			showChatbots,
			showChatbotKillAlert,
			showStartVideoChatAlert,
			showStartSecureChatAlert,
			showEndSecureChatAlert,
			chatbotKillAlertText,
			teamChatSearchTerm,
			referenceMessageId,
			highlightReferenceId,
			showTeamChatMessageSearch,
			attachmentMediaIds,
			showTeamChatTaskMessages,
			teamchatQueuedMessageCount,
			showCustomerMenu,
			showSignature,
			userSignatureEnabled,
			replyMessageId,
			composeTextHeight,
			composeContainerHeight,
			showPaymentPackageModal,
			paymentPackageText,
			signaturePreview,
			dob,

			showSendBookingRequestLinkModal,
			showSendShortLinkModal,
			showSendNpsRequestModal,
			showMoreComposeActions,

			needsTourForClosingConvos,
			conversationTasks,
			showModalTasks,
			taskCreationMessageId
		} = this.state;
		let {
			name,
			phone,
			email,
			medium,
			contact_id,
			status,
			senderId,
			conversationId,
			assigned_user_id,
			receive_transactional_sms,
			receive_transactional_emails,
			receive_feedback_sms,
			receive_feedback_emails
		} = convo;

		// Enable the send review invite feature if there is a phone or email present
		let contactHasPhoneOrEmail = convo.phone || convo.email;
		let isSmsDisabled = medium === MEDIUM.sms.key && !receive_transactional_sms && mode === MODE.customer;
		let isEmailDisabled = medium === MEDIUM.email.key && !receive_transactional_emails && mode === MODE.customer;
		let isFeedbackEnabled = medium === MEDIUM.sms.key && receive_feedback_sms && mode === MODE.customer;

		if (medium === MEDIUM.email.key && mode === MODE.customer) {
			isFeedbackEnabled = receive_feedback_emails;
		}

		let isBirthday = UtilityService.isBirthday(dob);

		let useLowResolutionGifs = mode === MODE.customer && medium === MEDIUM.sms.key;

		let isInExternalDisplayMode = showTeamChatTaskMessages;

		return (
			<div className="mb-thread mb-customer-thread-tour-1 mb-teamchat-thread-tour-1">
				<div className="mb-thread-header">
					<div className="mb-thread-header-name mb-customer-thread-tour-2 mb-teamchat-thread-tour-2">
						{mode === MODE.team && (
							<div>
								<div className="mb-thread-header-name-title">{name}</div>
								{convo.type === CONVERSATION.channel && <div className="mb-thread-header-name-members">{this.renderMembers()}</div>}
							</div>
						)}
						{mode === MODE.customer && <EditContactName locationId={locationId} contactId={contact_id} name={name} onNameChanged={this.onNameChanged} />}
						{isBirthday && <span className="mb-thread-header-birthday">{"🎂"}</span>}
					</div>
					{mode === MODE.customer && (
						<div className={`mb-thread-header-medium mb-thread-header-medium--${medium}`}>{MessagesService.getMediumData(medium, phone, email)}</div>
					)}
					{mode === MODE.customer && user.GroupPermission.view_customer_messages && user.GroupPermission.assign_customer_messages && (
						<div className="mb-thread-header-action--auto mb-customer-thread-tour-3">
							<AssignedUser
								locationId={locationId}
								contactId={contact_id}
								assignedUserId={assigned_user_id ? [assigned_user_id] : []}
								onSelect={this.onAssignedUserSelect}
								onShowList={this.onAssignedUserListShow}
								showLabel={true}
							/>
						</div>
					)}
					{mode === MODE.customer && user.GroupPermission.update_messages && (
						<>
							<div
								id="mb-thread-header--status-action"
								data-tip
								data-for="status-tooltip"
								className={`mb-thread-header-action mb-customer-thread-tour-4 mb-thread-header-close-tour  ${
									status === STATE.open ? "fnctst-close-button" : "fnctst-open-button"
								}`}
								onClick={this.onStatusChangeClicked}
							>
								{status === STATE.open ? <Icon.Check size="22" /> : <Icon.Inbox size="22" />}
							</div>
							<ReactTooltip id="status-tooltip" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
								{status === STATE.open ? "Close" : "Open"}
							</ReactTooltip>
						</>
					)}
					{mode === MODE.customer && (
						<CustomerMoreOptions
							ref={ref => (this.customerMoreOptions = ref)}
							showCustomerMenu={showCustomerMenu}
							contactId={convo.contact_id}
							onShowContactDetails={this.onShowContactDetails}
							onShow={this.showCustomerMenu}
							onHide={this.hideCustomerMenu}
						/>
					)}
					{mode === MODE.team && (
						<>
							<SearchInput ref={ref => (this.teamchatSearchInput = ref)} placeholder="Search messages ..." onChange={this.onTeamChatMessageSearchInputChange} />
							<div
								data-tip
								data-for="more-tooltip"
								id="mb-teamchat-header-action-more"
								className={`mb-teamchat-thread-tour-3 mb-thread-header-action ${
									mode === MODE.team ? "mb-thread-header-action--auto mb-thread-header-action--team-chat-details" : ""
								}`}
								onClick={this.onShowEditTeamChat}
							>
								<Icon.MoreVertical size="22" />
								<ReactTooltip id="more-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="bottom">
									Details
								</ReactTooltip>
							</div>
						</>
					)}
				</div>

				<div className="mb-thread-list" ref={ref => (this.threadList = ref)} onScroll={this.onScroll}>
					{this.viewTeamChatMessageList() && (
						<TeamChatMessageList
							ref={ref => (this.messageListComponent = ref)}
							senderId={senderId}
							conversationId={conversationId}
							onReplyToMessageClicked={this.onReplyToMessageClicked}
							onReplyClicked={this.onTeamChatReplyClicked}
							searchTerm={teamChatSearchTerm}
							referenceMessageId={referenceMessageId}
							highlightReference={highlightReferenceId}
							onCreateTaskMessageClick={this.onCreateTaskMessageClick}
							setScrollTop={this.setScrollTop}
						/>
					)}
					{mode === MODE.team && showTeamChatMessageSearch && !isInExternalDisplayMode && (
						<TeamChatMessageResultList
							searchTerm={teamChatSearchTerm}
							conversationId={conversationId}
							onSelect={message => this.onTeamChatMessageResultSelected(message.id)}
						/>
					)}
					{mode === MODE.team && showTeamChatTaskMessages && (
						<TeamChatTaskList
							onClose={this.onCloseTeamChatTaskList}
							conversationId={conversationId}
							onReferencedMessageClick={this.onReferenceMessageClickedTaskList}
						/>
					)}
					{mode === MODE.customer && (
						<MessageList
							ref={ref => (this.messageListComponent = ref)}
							locationId={locationId}
							contactId={contact_id}
							senderId={senderId}
							updateReplySuggestions={this.updateReplySuggestions}
							disabled={isChatbotActive || (isSmsDisabled && isEmailDisabled)}
						/>
					)}
					{mode === MODE.customer && (
						<ReplySuggestions
							ref={ref => {
								this.replySuggestions = ref;
							}}
							messageInput={messageInput}
							contactId={contact_id}
							onSuggestionSelected={this.onSuggestionSelected}
						/>
					)}
					{mode === MODE.team && (
						<div className="mb-thread-views-container" style={{ marginBottom: composeContainerHeight }}>
							<div className="mb-thread-views-items">
								{!isInExternalDisplayMode && (
									<TeamChatSeeDisplay
										show={teamchatQueuedMessageCount > 0 ? true : false}
										textLabel={
											teamchatQueuedMessageCount === 1
												? `There is ${teamchatQueuedMessageCount} scheduled message.`
												: `There are ${teamchatQueuedMessageCount} scheduled messages.`
										}
										linkLabel="See all scheduled messages."
										onClick={(event, close) => this.onSeeScheduledMessages(close)}
									/>
								)}
								{!isInExternalDisplayMode && (
									<TeamChatSeeDisplay
										show={conversationTasks.length > 0 ? true : false}
										textLabel={conversationTasks.length === 1 ? `There is ${conversationTasks.length} task.` : `There are ${conversationTasks.length} tasks.`}
										linkLabel="See all tasks."
										onClick={(event, close) => {
											if (close) {
												// Hide this message from showing
												this.update({ conversationTasks: [] });
												return;
											}
											this.update({
												showTeamChatMessageSearch: false,
												showTeamChatTaskMessages: true
											});
										}}
										color="#fffbe7"
									/>
								)}
							</div>
						</div>
					)}
					<PoseGroup>
						{typingContent && (
							<IsTyping key="is-typing-box" className="mb-thread-list-footer">
								{cachedTypingContent}
								<span className="ellipsis-anim">
									<span>.</span>
									<span>.</span>
									<span>.</span>
								</span>
							</IsTyping>
						)}
					</PoseGroup>
				</div>
				{this.renderDisabledMessage()}
				{this.isComposeEnabled() && (
					<>
						<div className="mb-thread-compose" style={{ height: composeContainerHeight }}>
							<DragAndDropOverlay onFileAdded={this.onFileAdded} className="mb-thread-compose--attachmentOverlay">
								<div
									style={{ height: composeTextHeight }}
									className={`mb-thread-compose__input ${this.isDisabled() ? "mb-thread-compose__input--disabled" : ""}`}
								>
									<textarea
										id="mb-thread-compose-text"
										onSelect={this.onMessageInputSelect}
										ref={ref => (this.messageInput = ref)}
										className="mb-thread-compose__input__textarea mb-customer-thread-tour-5"
										disabled={this.isDisabled()}
										autoComplete="off"
										value={this.state.messageInput}
										onChange={this.onMessageInput}
										placeholder={this.placeholderMessage()}
										onKeyDown={this.onEnter}
									/>
									{showSignature && <div className="mb-thread-compose__input__signature">{signaturePreview}</div>}
								</div>
							</DragAndDropOverlay>

							<div className="mb-thread-compose-actions">
								<ThreadAction
									id={"mb-template-selector"}
									onClickAction={this.onShowTemplates}
									icon={<Icon.Layers size="22" />}
									tooltip={"Templates"}
									show={mode === MODE.customer}
								/>
								<ThreadAction
									id={"mb-attachments"}
									onClickAction={this.onShowAttachments}
									icon={<Icon.Paperclip size="22" />}
									tooltip={"Attachments"}
									show={true}
								/>
								<ThreadAction id={"mb-emoji-tooltip"} onClickAction={this.onShowEmojis} icon={<Icon.Smile size="22" />} tooltip={"Emojis"} show={true} />
								<ThreadAction
									id={"mb-gifs-tooltip"}
									onClickAction={this.onShowGifs}
									icon={<Icon.Youtube size="22" />}
									tooltip={"GIFs"}
									show={mode === MODE.team}
								/>

								{mode === MODE.customer && (
									<>
										<ReactTooltip id="more-compose-actions" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="top">
											More Actions
										</ReactTooltip>
										<DHDropdown
											offset={{
												transform: `translateX(50px) !important`,
												bottom: 70
											}}
											show={showMoreComposeActions}
											onChange={({ show }) => {
												this.update({ showMoreComposeActions: show });
											}}
											trigger={
												<div
													data-tip
													id="mb-more-compose-actions"
													data-for="more-compose-actions"
													className={`mb-thread-compose-action`}
													onClick={() => this.update({ showMoreComposeActions: true })}
												>
													<Icon.Plus size="22" />
												</div>
											}
											options={
												<>
													{convo.medium !== MEDIUM.google.key && (
														<DHDropdownOption icon={Icon.Youtube} title="GIFs" action={this.onShowGifs} description="Send an animated GIF." />
													)}
													{userSignatureEnabled && (
														<DHDropdownOption
															icon={Icon.Edit3}
															title={showSignature ? "Disable Signature" : "Enable Signature"}
															action={this.onSignatureToggle}
															description="Add a signature to all of your outgoing messages"
														/>
													)}
													{!hidePayment && (
														<DHDropdownOption
															icon={Icon.DollarSign}
															title="Payment Request"
															action={this.onShowPaymentRequest}
															description="Request a payment from this contact"
														/>
													)}
													{MessagesService.isVideoChatEnabled() && (
														<DHDropdownOption
															icon={Icon.Video}
															title="Video Chat"
															action={this.onVideoChatClicked}
															description="Start a video chat with this contact"
														/>
													)}
													{MessagesService.isSecureChatEnabled() && medium === MEDIUM.sms.key && (
														<DHDropdownOption
															icon={Icon.Lock}
															title="Secure Chat"
															action={this.onSecureChatToggle}
															description="Start a secure chat for a more privacy focused experience"
														/>
													)}
													{MessagesService.isSecureChatEnabled() && medium === MEDIUM.secure.key && (
														<DHDropdownOption
															icon={Icon.Unlock}
															title="End Secure Chat"
															action={this.onSecureChatToggle}
															description="End the current secure chat session with this contact"
														/>
													)}
													{chatbotsEnabled && convo.medium !== MEDIUM.email.key && (
														<DHDropdownOption
															icon={Icon.Cpu}
															title="Chatbots"
															action={this.onChatbotToggle}
															description="Initiate a chat bot powered conversation with this contact"
														/>
													)}
													{LocationService.isCreateReviewsInvitesEnabled() && isFeedbackEnabled && contactHasPhoneOrEmail && (
														<DHDropdownOption
															icon={Icon.Star}
															title="Review Request"
															action={this.onReviewInviteClick}
															description="Invite this contact to leave a review for your business"
														/>
													)}
													{LocationService.isBookingsPermissible() && (
														<DHDropdownOption
															icon={Icon.Book}
															title="Booking Request"
															action={() => this.update({ showSendBookingRequestLinkModal: true, showMoreComposeActions: false })}
															description="Send a booking request link to this contact"
														/>
													)}
													{LocationService.isNpsPermissible() && isFeedbackEnabled && (
														<DHDropdownOption
															icon={Icon.Activity}
															title="Net Promoter Score"
															action={() => this.update({ showSendNpsRequestModal: true, showMoreComposeActions: false })}
															description="Send an NPS request to this contact"
														/>
													)}
													<DHDropdownOption icon={Icon.Link} title="Short Links" action={this.onSendShortLinkClick} description="Send a short link" />
												</>
											}
										/>

										<div className="mb-thread-compose-active-actions">
											<ThreadAction
												id={"mb-signature-tooltip"}
												onClickAction={this.onSignatureToggle}
												icon={<Icon.Edit3 size="22" />}
												tooltip={"Signature"}
												show={userSignatureEnabled && showSignature}
												isActive={showSignature}
											/>
											<ThreadAction
												id={"chatbot-tooltip"}
												onClickAction={this.onChatbotToggle}
												icon={<Icon.Cpu size="22" />}
												tooltip={"Chatbot"}
												show={isChatbotActive}
												isActive={isChatbotActive}
											/>
											<ThreadAction
												id={"mb-secure-chat-tooltip"}
												onClickAction={this.onSecureChatToggle}
												icon={<Icon.Lock size="22" />}
												tooltipPrefix="End"
												tooltip={"Secure Chat"}
												show={MessagesService.isSecureChatEnabled() && medium === MEDIUM.secure.key}
												isActive={medium === MEDIUM.secure.key}
											/>
										</div>
									</>
								)}

								<SendButton
									message={messageInput}
									mediaIds={attachmentMediaIds}
									mode={mode}
									disabled={sendButtonDisabled}
									sending={sending}
									conversationStatus={status}
									onSend={() => this.sendMessage(false)}
									onSendAndClose={() => this.sendMessage(true)}
									onSendLater={this.onSendAfterDateSelected}
								/>
							</div>
						</div>

						{mode === MODE.customer && (
							<TemplateSelector
								ref={ref => (this.templates = ref)}
								locationId={locationId}
								contactId={convo && convo.contact_id ? convo.contact_id : null}
								show={showTemplates}
								onSelect={this.onTemplateSelected}
								onClose={this.onTemplatesClosed}
								templateType={"general"}
								showEditAction={true}
								showRecentlyUsed={true}
								showFavourites={true}
							/>
						)}
						<Attachments
							ref={ref => (this.attachmentComponent = ref)}
							onChange={this.onAttachmentChanged}
							disabled={sending}
							sizeLimit={medium && medium === MEDIUM.google.key ? ATTACHMENT_MEDIA.gbmSizeLimit : ATTACHMENT_MEDIA.sizeLimit}
							inputComposeHeight={composeTextHeight}
						/>
						<Emojis show={showEmojis} onSelect={this.onEmojiSelected} onClose={this.onEmojiClose} />
						<Gifs
							ref={ref => (this.gifsComponent = ref)}
							show={showGifs}
							showOverlay={showGifsOverlay}
							onChange={this.onGifChanged}
							lowResolution={useLowResolutionGifs}
						/>
						<ReplyMessage replyMessageId={replyMessageId} onReplyMessageCancel={this.onReplyMessageCancel} inputComposeHeight={composeTextHeight} />
						{mode === MODE.customer && <Chatbots locationId={locationId} convo={convo} show={showChatbots} onClose={this.onChatbotsClosed} />}
						{mode === MODE.customer && PaymentService.isPaymentsEnabled() && (
							<PaymentRequest type={PAYMENT_TYPES.general.id} show={showPaymentRequest} onClose={this.onPaymentRequestClosed} contactId={contact_id} />
						)}
					</>
				)}
				{mode === MODE.customer && (
					<EditContactModal
						show={showContactDetails}
						contactId={contact_id ? contact_id : null}
						onClose={this.onContactDetailsClosed}
						onSave={this.onContactDetailsSaved}
					/>
				)}

				{mode === MODE.team && (
					<Mentions
						ref={ref => (this.mentionsComponent = ref)}
						selection={messageInputSelection}
						text={messageInput}
						conversationId={conversationId}
						onMentionSelected={this.onMentionSelected}
					/>
				)}
				{mode === MODE.team && <EditTeamChat show={showEditTeamChat} onClose={this.onEditTeamChatClose} conversationId={conversationId} />}
				{mode === MODE.team && (
					<Tour
						steps={teamchatSteps}
						isOpen={needsTour}
						onRequestClose={this.onTourClosed}
						disableDotsNavigation={true}
						disableInteraction={true}
						lastStepNextButton={<div>OK!</div>}
						closeWithMask={false}
						rounded={10}
					/>
				)}
				{mode === MODE.customer && (
					<>
						<Tour
							steps={customerSteps}
							isOpen={needsTour}
							onRequestClose={this.onTourClosed}
							disableDotsNavigation={true}
							disableInteraction={true}
							lastStepNextButton={<div>OK!</div>}
							closeWithMask={false}
							rounded={10}
						/>

						<Tour
							id="react-tour-messenger-close-conversation"
							steps={stepsForMessengerClosingConversationsTour}
							isOpen={false}
							onRequestClose={this.onMessengerCloseConvosTourClosed}
							disableDotsNavigation={true}
							disableInteraction={true}
							rounded={10}
							lastStepNextButton={<div>OK!</div>}
							closeWithMask={false}
						/>
					</>
				)}

				{mode === MODE.customer && LocationService.isBookingsPermissible && (
					<SendBookingRequestLinkModal
						show={showSendBookingRequestLinkModal}
						onHide={() => this.update({ showSendBookingRequestLinkModal: false })}
						title={"Send Booking Request"}
						convo={convo}
					/>
				)}
				{mode === MODE.customer && LocationService.isNpsPermissible() && (
					<SendNpsRequest
						show={showSendNpsRequestModal}
						onHide={() => this.update({ showSendNpsRequestModal: false })}
						phoneOrEmail={medium === MEDIUM.email.key ? email : phone}
						name={name}
					/>
				)}
				{taskCreationMessageId && (
					<UpdateTasksModal
						show={showModalTasks}
						createMode={true}
						onSubmit={this.onTaskSubmit}
						onHide={this.onHideTaskModal}
						messageId={taskCreationMessageId}
					/>
				)}

				{mode === MODE.customer && (
					<SendShortLinksModal
						show={showSendShortLinkModal}
						onClose={this.onCloseShortLinkModal}
						onSelect={this.onShortLinkSelected}
						onShortLinkUpdated={this.onShortLinkUpdated}
					/>
				)}

				<Alert
					type="warning"
					show={showChatbotKillAlert}
					title="Are you sure?"
					confirm="Yes"
					cancel="No"
					onClose={async confirmed => {
						await this.update({ showChatbotKillAlert: false });
						if (confirmed) {
							this.killChatbotConvo();
						}
					}}
				>
					<div>{chatbotKillAlertText}</div>
				</Alert>

				<Alert type="warning" show={showStartVideoChatAlert} title="Start a live video chat?" confirm="Yes" cancel="No" onClose={this.onConfirmStartVideoChat}>
					<div>Would you like to start a live video chat with this contact?</div>
					<div>A video chat link will be sent.</div>
				</Alert>

				<Alert type="warning" show={showStartSecureChatAlert} title="Start a secure chat?" confirm="Yes" cancel="No" onClose={this.onConfirmStartSecureChat}>
					<div>Would you like to start a secure chat with this contact?</div>
					<div>A secure chat link will be sent.</div>
				</Alert>

				<Alert type="warning" show={showEndSecureChatAlert} title="End secure chat?" confirm="Yes" cancel="No" onClose={this.onConfirmEndSecureChat}>
					<div>Would you like to end the secure chat?</div>
				</Alert>

				<Alert type="info" show={showPaymentPackageModal} title="Payments Package" confirm="Chat with us" onClose={this.onPaymentPackageAlertClose}>
					{paymentPackageText}
				</Alert>
			</div>
		);
	}
}

export default Thread;
