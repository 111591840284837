import React, { Component } from "react";
import UserService from "../../services/UserService";
import PollingService from "../../services/PollingService";
import LoggedOutModal from "./LoggedOutModal";

class CheckLoginInterval extends Component {
	state = {
		isLoggedIn: true
	};
	async checkAuthApiCall() {
		try {
			await UserService.checkAuthToken();
		} catch (errorResponse) {
			if (typeof errorResponse.response !== "undefined") {
				if (errorResponse.response.status === 401) {
					console.log(errorResponse.response.data.error);
					this.setState({ isLoggedIn: false });
				}
			}
		}
	}
	componentDidMount() {
		PollingService.addListener("checkAuth", () => this.checkAuthApiCall());
	}

	render() {
		if (!this.state.isLoggedIn) {
			return (
				<LoggedOutModal
					location={{
						pathname: this.props.location ? this.props.location.pathname : "/"
					}}
				/>
			);
		}
		return null;
	}
}

export default CheckLoginInterval;
