import React, { Component } from "react";
import * as Icon from "react-feather";
import moment from "moment";
import UserService from "../../services/UserService";
import AppointmentsService from "../../services/AppointmentsService";
import { withTranslation } from "react-i18next";

import GROUP_PERMISSIONS from "../../constants/GroupPermissions";

import "../../styles/css/components/commons/dh-details.css";

class Appointment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			appointment: null
		};
	}

	async componentDidMount() {
		await this.update({ loading: true });
		await this.fetchAppointment();
		await this.update({ loading: false });
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async fetchAppointment() {
		let appointmentId = this.props.appointmentId;
		// The call to fetch appointments
		let appointment = await AppointmentsService.getAppointment(appointmentId);
		await this.update({
			appointment
		});
	}

	render() {
		const { loading, appointment } = this.state;
		let { t } = this.props;

		const user = UserService.get();
		let isAdmin = user.GroupPermission.type === GROUP_PERMISSIONS.super_administrator.type;
		return (
			<div className="dh-details">
				<div className="dh-details-info">
					<div className="dh-details-title">{t("Details")}</div>
					{loading && (
						<div className="dh-details-item">
							<label>{t("loading ...")}</label>
						</div>
					)}
					{!loading && (
						<>
							{isAdmin && (
								<>
									<div className="dh-details-item">
										<label>{t("Location Id")}</label>
										<div>{appointment.location_id}</div>
									</div>
									<div className="dh-details-item">
										<label>{t("Crm Version")}</label>
										<div>{appointment.crm_version}</div>
									</div>
									<div className="dh-details-item">
										<label>{t("Crm Appointment Id")}</label>
										<div>{appointment.crm_appointment_id}</div>
									</div>
									<div className="dh-details-item">
										<label>{t("Crm Contact Id")}</label>
										<div>{appointment.crm_contact_id}</div>
									</div>
									<div className="dh-details-item">
										<label>{t("Crm Family Head Id")}</label>
										<div>{appointment.crm_family_head_id}</div>
									</div>
								</>
							)}
							<div className="dh-details-item">
								<label>{t("First Name")}</label>
								<div>{appointment.first_name}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Last Name")}</label>
								<div>{appointment.last_name}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Mobile Phone")}</label>
								<div>{appointment.mobile_phone}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Home Phone")}</label>
								<div>{appointment.home_phone}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Work Phone")}</label>
								<div>{appointment.work_phone}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Email")}</label>
								<div>{appointment.email}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Confirmed")}</label>
								<div>{appointment.confirmed ? <Icon.Check /> : <Icon.X />}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Status")}</label>
								<div>{appointment.state}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Type")}</label>
								<div>{appointment.type}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Provider Name")}</label>
								<div>{appointment.provider_name}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Booking At")}</label>
								<div>{appointment.booking_at}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Booking End")}</label>
								<div>{appointment.booking_end}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Contact Type")}</label>
								<div>{appointment.contact_type}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Address 1")}</label>
								<div>{appointment.address_1}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Address 2")}</label>
								<div>{appointment.address_2}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("City")}</label>
								<div>{appointment.city}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("State/Province")}</label>
								<div>{appointment.state_prov}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Zip Postal Code")}</label>
								<div>{appointment.zip_postal}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Country")}</label>
								<div>{appointment.country}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Birthday")}</label>
								<div>{appointment.dob}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Gender")}</label>
								<div>{appointment.gender}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Language")}</label>
								<div>{appointment.language}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Created At")}</label>
								<div>{moment(appointment.created_at).format("ddd, MMM Do YYYY, h:mm a")}</div>
							</div>
							<div className="dh-details-item">
								<label>{t("Update At")}</label>
								<div>{moment(appointment.updated_at).format("ddd, MMM Do YYYY, h:mm a")}</div>
							</div>
						</>
					)}
				</div>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(Appointment);
