import _ from "lodash";
import moment from "moment-timezone";
import config from "../config/app/web-app.config";

import UserService from "./UserService";
import Kichiri from "./KichiriService";
import TeamChatService from "./TeamChatService";
import CrmIntegrationsService from "./CrmIntegrationsService";
import UtilityService from "./UtilityService";

import GROUP_PERMISSIONS from "../constants/GroupPermissions";
import { LOCATION_FEATURES } from "../constants/LocationConstants";
import { SCHEDULE } from "../constants/LocationConstants";

const LocationService = {
	/**
	 * Fetch a location
	 *
	 * @param {Number} locationId
	 *
	 * @return {Promise}
	 */
	async fetchLocation(locationId) {
		let authToken = UserService.getAuthToken();
		if (!authToken) {
			console.log("LocationService: No valid auth token.");
			return;
		}
		if (!locationId) {
			locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
		}

		try {
			let response = await Kichiri.location.getLocation(
				{
					locationId: locationId
				},
				null,
				authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},
	/**
	 * Generate location options
	 * @return {Array}
	 */
	generateLocationOptions() {
		const options = [];
		const user = UserService.get();

		if (user.GroupPermission.update_locations) {
			options.push(
				{
					label: "Admin",
					value: "admin"
				},
				{
					label: "Update",
					value: "update"
				},
				{
					label: "Update Wizard",
					value: "updateWizard"
				},
				{
					label: "Business Hours",
					value: "businessHours"
				},
				{
					label: "Configure Widget",
					value: "configureWidget"
				}
			);
		}
		if (user.GroupPermission.delete_locations) {
			options.push({
				label: "Delete",
				value: "delete"
			});
		}
		if (user.GroupPermission.view_review_sites) {
			options.push({
				label: "Review Sites",
				value: "reviewSites"
			});
		}
		if (user.GroupPermission.view_templates) {
			options.push({
				label: "Templates",
				value: "templates"
			});
		}
		if (user.GroupPermission.connect_locations) {
			options.push({
				label: "Configure Connections",
				value: "configureConnections"
			});
		}
		if (user.GroupPermission.view_crm_integrations) {
			options.push({
				label: "CRM Integrations",
				value: "crmIntegrations"
			});
		}
		if (user.GroupPermission.update_chatbots) {
			options.push({
				label: "Chatbot Configuration",
				value: "chatbotConfiguration"
			});
		}
		if (user.GroupPermission.update_chatbot_faqs) {
			options.push({
				label: "Chatbot FAQs",
				value: "chatbotFaqs"
			});
		}
		if (user.GroupPermission.type === GROUP_PERMISSIONS.super_administrator.type || user.GroupPermission.type === GROUP_PERMISSIONS.customer_success.type) {
			options.push({
				label: "Location Features",
				value: "locationFeature"
			});
		}
		return options;
	},

	/**
	 * Fetch timezones
	 * @param {Integer} timezoneId
	 * @returns {Array}
	 */
	async fetchTimeZones({ timezoneId }) {
		try {
			let response = await Kichiri.timezones.fetch({}, { timezoneId }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch the timezone of a location
	 * @return {String}
	 */
	async fetchTimeZone() {
		try {
			let location = UserService.getActiveLocation();

			let data = await this.fetchTimeZones({ timezoneId: location.timezone });

			let timezone = data.filter(t => t.id === location.timezone);
			let timezoneString = JSON.parse(timezone[0].utc)[0];
			return timezoneString;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetch the invite queue deadline details
	 */
	async fetchInviteQueueDeadline() {
		try {
			let location = UserService.getActiveLocation();
			if (!location.LocationFeature.reviews_invites_queue) {
				return null;
			}

			const authToken = UserService.getAuthToken();
			let inviteQueueDetails = await Kichiri.location.getInviteQueueDetails({ locationId: location.id }, {}, authToken);
			inviteQueueDetails = inviteQueueDetails.data;

			let data = await this.fetchTimeZones({ timezoneId: location.timezone });
			let timezone = data.filter(t => t.id === location.timezone);
			let timezoneString = JSON.parse(timezone[0].utc)[0];
			inviteQueueDetails.deadlineDateTime = moment(inviteQueueDetails.deadlineDateTime).tz(timezoneString);
			return inviteQueueDetails;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetch location features
	 *
	 * @param {Number} locationId
	 * @return {Promise}
	 */
	async getLocationFeatures(locationId = null) {
		if (!locationId) {
			locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
		}
		try {
			let response = await Kichiri.location.getLocationFeatures(
				{
					locationId: locationId
				},
				null,
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Location features config
	 * @returns {Object}
	 */
	async getLocationFeaturesConfig() {
		try {
			let response = await Kichiri.location.getLocationFeaturesConfig({}, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a location features for a location. Create a feature list if it doesn't exist
	 * @param {Number} locationId
	 * @param {Object} updateObject The flags that will be modified
	 */
	async updateLocationFeature(locationId, updateObject) {
		try {
			let authToken = UserService.getAuthToken();
			if (!locationId) {
				locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
			}

			let response = await Kichiri.location.updateLocationFeatures(
				{
					locationId: locationId,
					locationFeatures: updateObject
				},
				null,
				authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create a google integration with a location
	 * @param {Object} gmbData
	 * @param {Number} locationId
	 */
	async connectGMB(gmbData, locationId) {
		try {
			const authToken = UserService.getAuthToken();
			if (!locationId) {
				locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
			}
			const response = await Kichiri.location.connectGMB({ locationId, gmbData }, {}, authToken);
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get all of the gmb locations from an integration
	 * @param {Number} integrationId
	 * @param {Number} locationId
	 */
	async getGMBLocations(integrationId, locationId) {
		try {
			const authToken = UserService.getAuthToken();
			if (!locationId) {
				locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
			}
			const response = await Kichiri.location.getGMBLocations({ locationId }, { integrationId }, authToken);
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Add ourselves as a manager for a gmb location
	 * @param {Object} gmbLocation A GMB location
	 * @param {Number} locationId
	 */
	async addDHToGMBLocation(gmbLocation, locationId) {
		try {
			const authToken = UserService.getAuthToken();
			if (!locationId) {
				locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
			}
			const response = await Kichiri.location.addDHToGMBLocation({ locationId, gmbLocation: gmbLocation }, {}, authToken);
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Check if specific integrations are active for a location (eg gmb,facebook)
	 * @param {Number} locationId
	 */
	async hasIntegrations(locationId) {
		try {
			let authToken = UserService.getAuthToken();
			if (!locationId) {
				locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
			}

			const response = await Kichiri.location.hasIntegrations({ locationId: locationId }, {}, authToken);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get the requester list for a location
	 * @param {Number} locationId
	 */
	async requesterList(locationId) {
		try {
			let authToken = UserService.getAuthToken();
			if (!locationId) {
				locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
			}

			let response = await Kichiri.location.requesterList(
				{
					locationId: locationId
				},
				{},
				authToken
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Indicates if dashboard is enabled for the current location
	 */
	isDashboardEnabled() {
		try {
			return this.isLocationFeaturePermissible("dashboard");
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Indicates if leaderboard is enabled for the current location
	 */
	isLeaderboardEnabled() {
		try {
			const user = UserService.get();

			let { view_reports } = user.GroupPermission;

			return view_reports && this.isLocationFeaturePermissible("leaderboard");
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Indicates if review invites are enabled at a location level
	 * Note: To return true, we must also have reviews enabled at a location level.
	 */
	isReviewInvitesEnabled() {
		try {
			let location = UserService.getActiveLocation();

			// Check both the reviews location feature, and the review invites location feature
			return this.isReviewsEnabled() && location.LocationFeature.reviews_invites;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Indicates if reviews are enabled for the current location and user
	 */
	isViewReviewInvitesEnabled() {
		try {
			const user = UserService.get();

			let { view_invites } = user.GroupPermission;

			return this.isReviewInvitesEnabled() && view_invites;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Indicates if reviews is enabled and the user can send reviews
	 */
	isCreateReviewsInvitesEnabled() {
		try {
			const user = UserService.get();
			let { create_invites } = user.GroupPermission;

			return this.isReviewInvitesEnabled() && create_invites;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Indicates if reviews is enabled for the current location
	 */
	isReviewsEnabled() {
		try {
			const user = UserService.get();
			let { view_reviews } = user.GroupPermission;

			return view_reviews && this.isLocationFeaturePermissible("reviews");
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Indicates if location tasks is enabled for the current location
	 * And the user has the permission
	 */
	isLocationTasksPermissible() {
		try {
			const user = UserService.get();
			let { view_tasks } = user.GroupPermission;

			return view_tasks && this.isLocationFeaturePermissible("tasks");
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Check if the location feature is permitted
	 * @param {String} feature
	 * @returns {Boolean}
	 */
	isLocationFeaturePermissible(feature) {
		return UserService.getActiveLocation().LocationFeature && UserService.getActiveLocation().LocationFeature[feature];
	},

	/**
	 * Check the booking feature is enabled for a location
	 * @returns {Boolean}
	 */
	isBookingsPermissible() {
		return this.isLocationFeaturePermissible("bookings");
	},

	/**
	 * Check if messenger is enabled for a user
	 * @returns {Boolean}
	 */
	isMessengerPermissible() {
		const user = UserService.get();
		let location = UserService.getActiveLocation();

		let overallChat = location.LocationFeature && location.LocationFeature.messenger;
		let teamChat = TeamChatService.isEnabled() && user.GroupPermission.view_teamchat_messages;
		let customerChat = user.GroupPermission.view_customer_messages;

		let result = overallChat && (customerChat || teamChat);

		return result;
	},

	/**
	 * Check if NPS is enabled for a user
	 * @returns {Boolean}
	 */
	isNpsPermissible() {
		try {
			const user = UserService.get();
			return this.isLocationFeaturePermissible(LOCATION_FEATURES.nps.id) && user.GroupPermission.view_nps;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Check if Scheduled NPS is enabled for a user
	 * @returns {Boolean}
	 */
	isScheduledNpsPermissible() {
		try {
			const user = UserService.get();
			return this.isScheduledMessagesEnabled() && this.isNpsPermissible() && this.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_scheduled_nps.id);
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Check if the review invite queue is enabled
	 * @returns {Boolean}
	 */
	isInviteQueuePermissible() {
		try {
			let location = UserService.getActiveLocation();

			return location.LocationFeature.reviews_invites_queue;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Check if the reviews widget is enabled
	 * @returns {Boolean}
	 */
	isReviewsWidgetPermissible() {
		try {
			let location = UserService.getActiveLocation();
			return location.LocationFeature.reviews_widget;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Check if the embedded reviews widget is enabled
	 * @returns {Boolean}
	 */
	isEmbeddedReviewsWidgetPermissible() {
		try {
			let location = UserService.getActiveLocation();
			return location.LocationFeature.reviews_embedded_reviews_widget;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Check if the review invite queue is enabled
	 * @returns {Boolean}
	 */
	isWebchatPermissible() {
		try {
			let location = UserService.getActiveLocation();
			return location.LocationFeature.webchat;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Indicates whether or not Scheduled Messages is enabled for this account and user
	 *
	 * @returns {Boolean}
	 */
	isScheduledMessagesEnabled() {
		try {
			let user = UserService.get();
			return this.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_scheduled.id) && user.GroupPermission.view_scheduled_messages;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Indicates whether or not Scheduled Messages is enabled for this account and user
	 *
	 * @returns {Boolean}
	 */
	isScheduledMessagesMMSEnabled() {
		try {
			return (
				this.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_scheduled.id) &&
				this.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_scheduled_mms.id)
			);
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Indicates whether or not Scheduled Messages is enabled for this account and user
	 *
	 * @returns {Boolean}
	 */
	isScheduledGeneralEnabled() {
		try {
			let user = UserService.get();
			return this.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_scheduled_general.id) && user.GroupPermission.view_scheduled_messages;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Indicates whether or not Scheduled Messages is enabled for this account and user
	 *
	 * @returns {Boolean}
	 */
	isScheduledNpsEnabled() {
		try {
			let user = UserService.get();
			return this.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_scheduled_nps.id) && user.GroupPermission.view_nps;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Indicates whether or not Scheduled Messages is enabled for this account and user
	 *
	 * @returns {Boolean}
	 */
	isScheduledReviewInvitesEnabled() {
		try {
			let user = UserService.get();
			return (
				this.isScheduledMessagesEnabled() &&
				this.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_scheduled_review_invites.id) &&
				user.GroupPermission.view_invites
			);
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Indicates whether or not Reengagements Scheduled Messages is enabled for this account and user
	 *
	 * @returns {Boolean}
	 */
	isSMReengagementsEnabled() {
		try {
			let user = UserService.get();
			return this.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_scheduled_reengagement.id) && user.GroupPermission.view_scheduled_messages;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Check if Micro Sites is enabled for this location
	 * @returns {Boolean}
	 */
	isMicroSitePermissible() {
		try {
			return this.isLocationFeaturePermissible(LOCATION_FEATURES.micro_site.id);
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Generate a handle friendly string for a location
	 * @param {String} potentialHandle
	 * @return {String}
	 */
	generateHandle(potentialHandle) {
		potentialHandle = potentialHandle.trim(); // remove leading and trailing spaces
		potentialHandle = potentialHandle.replace(/\n|\r/g, ""); // remove new lines
		potentialHandle = potentialHandle.replace(/\t/g, ""); // remove tabs
		potentialHandle = potentialHandle.replace(/\s/g, "-"); // replaces remaining spaces with dashes
		potentialHandle = _.deburr(potentialHandle); // change foreign language chars to english
		potentialHandle = potentialHandle.replace(/[^a-zA-Z0-9-]/g, ""); // remove every non alpha numeric char, keep dashes
		potentialHandle = potentialHandle.replace(/-{2,}/g, "-"); // turn double dashes -- into single -
		potentialHandle = potentialHandle.replace(/^-+|-+$/g, ""); // trim dashes on left and right
		potentialHandle = potentialHandle.toLowerCase(); // turn all letters lowercase
		return potentialHandle;
	},

	/**
	 * Given a location object, we get and parse the JSON data in the meta_data field
	 * @param {Object} location
	 */
	getAndParseMetaData(location) {
		try {
			if (location && location.meta_data) {
				let metaData = JSON.parse(location.meta_data);
				return metaData;
			}
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get the full location address given a location
	 * @param {Object} location
	 */
	getFullAddress(location) {
		try {
			if (!location) {
				location = UserService.getActiveLocation();
			}
			let fullAddress = `${location.address_1} ${location.address_2}, ${location.city}, ${location.state_prov}, ${location.country} ${location.zip_postal}`;
			return fullAddress;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update location data
	 * @param {Object} data params
	 */
	async update(data) {
		try {
			await Kichiri.location.update(data, {}, UserService.getAuthToken());
			return true;
		} catch (error) {
			console.log(error);
		}
		return false;
	},

	/**
	 * Get schedules that belong to a location
	 * @param {Number} locationId
	 * @param {Array[String]} types
	 * @param {Array[String]} daysOfWeek
	 * @returns A list of schedules
	 */
	async getSchedulesForLocation(locationId, type = SCHEDULE.types.regularBusinessHours, daysOfWeek) {
		try {
			let response = await Kichiri.location.getSchedulesForLocation({ locationId }, { type, daysOfWeek }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Updates a schedule.
	 * Note: We update an entire schedule at once, not a single day.
	 * @param {Number} locationId
	 * @param {String} type
	 * @param {Array} schedule
	 * @returns {Object}
	 */
	async updateSchedule({ locationId, type, schedule }) {
		try {
			let response = await Kichiri.location.updateSchedules({ locationId, type, schedule }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get leaderboard Data
	 * @param {Number} locationId
	 * @param {String} start
	 * @param {String} end
	 * @param {String} searchTerm
	 * @param {String} sortField
	 * @param {String} sortOrder
	 * @param {Boolean} resetCache
	 * @param {Number} limit
	 */
	async getLeaderboardData({ locationId, start, end, searchTerm, sortField, sortOrder, limit, resetCache }) {
		try {
			const result = await Kichiri.location.leaderboard(
				{
					locationId: locationId
				},
				{
					start: start,
					end: end,
					searchTerm,
					sortField,
					sortOrder,
					limit,
					resetCache
				},
				UserService.getAuthToken()
			);

			return result.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Indicates if Custom Fields is enabled for the current location and user
	 */
	isCustomFieldsEnabled() {
		try {
			const user = UserService.get();

			let { view_custom_fields } = user.GroupPermission;

			return view_custom_fields && this.isLocationFeaturePermissible("custom_fields");
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Fetch custom fields for a location
	 * @param {Object} params
	 * @returns
	 */
	async getCustomFields(params) {
		try {
			const locationId = UserService.getActiveLocation().id;
			let query = {
				locationId: locationId,
				searchTerm: "",
				sortField: null,
				sortOrder: null,
				limit: 50
			};
			query = Object.assign(query, params);
			let response = await Kichiri.location.getCustomFields({ locationId }, query, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create a custom field for a location
	 * @param {Number} locationId
	 * @param {String} name
	 * @param {String} field
	 * @param {String} description
	 * @param {String} type
	 * @param {Boolean} favorite
	 * @returns Custom Field
	 */
	async createCustomField({ locationId, name, field, description, type, favorite }) {
		try {
			let response = await Kichiri.location.createCustomField({ locationId, name, field, description, type, favorite }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create a custom field for a location
	 * @param {Number} locationId
	 * @param {Number} id
	 * @param {String} name
	 * @param {String} description
	 * @param {String} type
	 * @param {Boolean} favorite
	 * @param {String} status
	 * @returns Custom Field
	 */
	async updateCustomField({ locationId, id, name, field, description, type, status, favorite }) {
		try {
			let response = await Kichiri.location.updateCustomField(
				{ locationId, id, name, field, description, type, status, favorite },
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * See if a custom field already exists for a location
	 * @param {Number} locationId
	 * @param {String} name
	 * @returns {Boolean}
	 */
	async customFieldExists({ locationId, field }) {
		try {
			let customField = await this.getCustomField({ locationId, field });
			return customField ? true : false;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch a custom field for a location
	 * @param {Number} locationId
	 * @param {String} field
	 * @returns {Boolean}
	 */
	async getCustomField({ locationId, field }) {
		try {
			let response = await Kichiri.location.getCustomField({ locationId, fieldName: field }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * See if a review request has been send to a phone or email
	 * @param {Number} locationId
	 * @param {String} phoneOrEmail
	 * @returns {ReviewRequest} the last_sent field will give us a boolean to know if it has been sent before or not
	 */
	async lastSentReviewRequest(locationId, phoneOrEmail) {
		try {
			const isEmailOrPhoneParam = phoneOrEmail.indexOf("@") >= 0 ? "email" : "phone";

			let response = await Kichiri.review_request.lastSent(
				{},
				{
					location: locationId,
					[isEmailOrPhoneParam]: phoneOrEmail
				},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get all assignable verticals
	 * @returns List of verticals
	 */
	async getVerticals() {
		try {
			let response = await Kichiri.location.getVerticals({}, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return {};
	},

	/**
	 * Get all sms providers
	 * @returns List of sms providers
	 */
	async getSMSProviders() {
		try {
			let response = await Kichiri.admin.providers({ medium: "sms" }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return {};
	},

	/**
	 * Create a location
	 * @param {Object} locationData Location fields
	 * @returns
	 */
	async createLocation(locationData) {
		try {
			let response = await Kichiri.location.create(locationData, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a location
	 * @param {Object} locationData Location fields, locationId required
	 * @returns
	 */
	async updateLocation(locationData) {
		try {
			let response = await Kichiri.location.update(locationData, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Delete a location
	 * @returns {Boolean}
	 */
	async deleteLocation(locationId) {
		try {
			await Kichiri.location.deleteLocation({ locationId }, {}, UserService.getAuthToken());

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create a review request
	 * @param {String} name
	 * @param {Number} location
	 * @param {String} phone
	 * @param {String} email
	 * @param {Number} template
	 *
	 * @returns
	 */
	async createReviewRequest({ name, location, phone, email, template }) {
		try {
			const data = { name: name, location: location, phone, email, template: template };
			let response = await Kichiri.review_request.create(data, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
			if (error.response && error.response.data.error) {
				return error.response.data;
			}
		}
		return null;
	},

	/**
	 * Send review requests based on the last 30 days of synced appointment data
	 * @param {Array} locationIds
	 */
	async batchSendReviewRequests(locationIds) {
		try {
			let response = await Kichiri.review_request.batchSend({ locationIds }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get Google Place details
	 * @param {String} placeId
	 * @returns
	 */
	async getPlacesDetails(placeId) {
		try {
			let response = await Kichiri.recommendations.getPlacesDetails({ placeId: placeId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Indicates if Auto Review Requests is enabled for the current location
	 */
	isAutoReviewRequestsEnabled() {
		try {
			return this.isLocationFeaturePermissible("reviews") && this.isLocationFeaturePermissible("reviews_auto_requests");
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Verifies an a2p submission, and if it passes, submits a mock submission to twilio.
	 * If the mock submission passes, the backend will automatically make a real submission.
	 * @param {Number} locationId
	 * @param {String} provider like twilio or bandwidth
	 * @param {Object} submissionData
	 * @return {Promise}
	 */
	async submitA2P({ locationId, mock = true, provider, submissionData }) {
		try {
			const response = await Kichiri.location.submitA2P({ mock, provider, submissionData, locationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			return error;
		}
	},

	/**
	 * Get the a2p meta data for a specific provider, for this location
	 * @param {Number} locationId
	 * @param {String} provider like twilio or bandwidth
	 * @return {Object}
	 */
	async getA2PMetaData({ locationId, provider }) {
		try {
			const response = await Kichiri.location.getA2PMetaData({ locationId }, { provider }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Restart and the state of a2p and remove existing campaigns
	 * @param {Number} locationId
	 * @param {String} provider like twilio or bandwidth
	 * @return {Object}
	 */
	async resetA2PSubmission({ locationId, provider }) {
		try {
			const response = await Kichiri.location.resetA2p({ locationId }, { provider }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Returns a list of Google Place API Recommendations based on the search term provided
	 *
	 * @param {String} searchTerm
	 * @returns
	 */
	async fetchGooglePlaceRecommendations(searchTerm) {
		try {
			let authToken = UserService.getAuthToken();

			let { data } = await Kichiri.recommendations.getPlacesSuggestions({}, { input: searchTerm }, authToken);

			return data.predictions;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Fetches all tasks for a location
	 *
	 * @param {Number} locationId
	 * @returns {Array}
	 */
	async fetchTasks({ locationId }) {
		try {
			if (UserService.isSuperOrCsVisitingAnotherCompany()) {
				locationId = config.DEMANDHUB.LOCATION.ID;
			}

			let authToken = UserService.getAuthToken();

			let response = await Kichiri.tasks.fetchTasks({}, { locationId }, authToken);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetches a task for a location
	 *
	 * @param {Number} taskId
	 * @returns {Object}
	 */
	async fetchTask({ taskId }) {
		try {
			let authToken = UserService.getAuthToken();

			let response = await Kichiri.tasks.fetchTask({ taskId }, {}, authToken);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetch teamchat tasks
	 *
	 * @param {Number} locationId
	 * @param {Number} conversationId
	 * @param {Boolean} ignoreDone
	 * @returns {Array}
	 */
	async fetchTeamChatTasks({ locationId, conversationId, ignoreDone }) {
		try {
			if (locationId && UserService.isSuperOrCsVisitingAnotherCompany()) {
				locationId = config.DEMANDHUB.LOCATION.ID;
			}

			let authToken = UserService.getAuthToken();

			let response = await Kichiri.tasks.fetchTasks({}, { locationId, conversationId, ignoreDone }, authToken);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Updates a location note for a location
	 *
	 * @param {Number} taskId
	 * @param {String} title
	 * @param {String} content
	 * @param {String} color
	 * @param {String} status
	 * @param {Boolean} pinned
	 * @param {Boolean} showEveryone
	 * @returns {Boolean}
	 */
	async updateTask({ taskId, title, content, color, status, pinned, showEveryone }) {
		try {
			let authToken = UserService.getAuthToken();

			let response = await Kichiri.tasks.updateTask({ taskId, title, content, color, status, pinned, showEveryone }, {}, authToken);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Clones a task for a location
	 *
	 * @param {Number} taskId
	 * @returns {Object}
	 */
	async duplicateTask({ taskId }) {
		try {
			let authToken = UserService.getAuthToken();

			let response = await Kichiri.tasks.duplicateTask({ taskId }, {}, authToken);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Create a task for a location
	 *
	 * @param {Number} locationId
	 * @param {String} title
	 * @param {String} content
	 * @param {Number} messageId
	 * @returns {Object}
	 */
	async createTask({ locationId, title, content, messageId }) {
		try {
			if (UserService.isSuperOrCsVisitingAnotherCompany()) {
				locationId = config.DEMANDHUB.LOCATION.ID;
			}

			let authToken = UserService.getAuthToken();
			let response = await Kichiri.tasks.createTask({ locationId, title, content, messageId }, {}, authToken);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Deletes a task for a location
	 *
	 * @param {Number} taskId
	 * @returns {Boolean}
	 */
	async deleteTask({ taskId }) {
		try {
			let authToken = UserService.getAuthToken();

			let response = await Kichiri.tasks.deleteTask({ taskId }, {}, authToken);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetch all locations
	 * @param {Array/Number} companyIds
	 * @param {String} searchTerm
	 * @param {Array/String} status
	 * @param {Array/String} vertical
	 * @param {Array/String} locationFeatures
	 * @param {Number} limit
	 *
	 * @returns {Array} Locations
	 */
	async fetchAllLocations({ companyIds, searchTerm, status, vertical, locationFeatures, limit }) {
		try {
			let data = {};

			if (companyIds) {
				data.companyIds = companyIds;
			}
			if (searchTerm) {
				data.searchTerm = searchTerm;
			}
			if (status) {
				data.status = status;
			}
			if (vertical) {
				data.vertical = vertical;
			}
			if (locationFeatures) {
				data.locationFeatures = locationFeatures;
			}
			if (limit) {
				data.limit = limit;
			}

			let response = await Kichiri.admin.locations({}, data, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch reviews goal data
	 * @param {Number} locationId
	 * @returns {Object}
	 */
	async fetchReviewGoals(locationId) {
		try {
			let response = await Kichiri.location.fetchReviewGoal(
				{
					locationId: locationId
				},
				null,
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch invite goal data
	 * @param {Number} locationId
	 * @returns {Object}
	 */
	async fetchInviteGoals(locationId) {
		try {
			let response = await Kichiri.location.fetchInviteGoal(
				{
					locationId: locationId
				},
				null,
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch monthly review count
	 * @param {Number} locationId
	 * @returns {Number}
	 */
	async fetchMonthlyReviewCount(locationId) {
		try {
			let response = await Kichiri.location.monthlyReviewCount(
				{
					locationId: locationId
				},
				null,
				UserService.getAuthToken()
			);

			return response.data.count;
		} catch (error) {
			console.log(error);
		}

		return 0;
	},

	/**
	 * Fetch monthly replies data
	 * @param {Number} locationId
	 * @returns {Object}
	 */
	async fetchMonthlyRepliesCount(locationId) {
		try {
			let response = await Kichiri.location.monthlyReviewRepliesCount(
				{
					locationId: locationId
				},
				null,
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch monthly replies data
	 * @param {Number} locationId
	 * @returns {Number}
	 */
	async fetchClickRate({ locationId }) {
		try {
			let response = await Kichiri.location.clickRate(
				{
					locationId: locationId
				},
				{},
				UserService.getAuthToken()
			);
			let clickRate = response.data.click_rate;
			return parseInt(clickRate * 100, 10);
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch facebook pages
	 * @param {Number} locationId
	 * @param {String} facebookAccessToken
	 * @returns {Object}
	 */
	async fetchFacebookPages({ locationId, facebookAccessToken }) {
		try {
			let response = await Kichiri.location.fetchPages(
				{
					facebookAccessToken: facebookAccessToken,
					locationId: locationId
				},
				{},
				UserService.getAuthToken()
			);

			response.data.status = response.status;

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Subscribe to a facebook page
	 * @param {String} userAccessToken
	 * @param {String} pageAccessToken
	 * @param {Number} pageId
	 * @param {Number} locationId
	 * @param {Object} properties
	 *
	 * @returns {Object}
	 */
	async subscribeToFacebookPage({ userAccessToken, pageAccessToken, pageId, locationId, properties }) {
		try {
			let response = await Kichiri.location.subscribeToPage(
				{
					userAccessToken,
					pageAccessToken,
					pageId,
					locationId,
					properties
				},
				{},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Unsubscribe from a facebook page
	 * @param {String} userAccessToken
	 * @param {String} pageAccessToken
	 * @param {Number} pageId
	 * @param {Number} locationId
	 *
	 * @returns {Object}
	 */
	async unsubscribeFromFacebookPage({ userAccessToken, pageAccessToken, pageId, locationId }) {
		try {
			let response = await Kichiri.location.unsubscribeFromPage(
				{
					userAccessToken,
					pageAccessToken,
					pageId,
					locationId
				},
				{},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Update a Messenger Integration
	 * @param {Number} id
	 * @param {Number} properties
	 * @param {String} status
	 *
	 * @returns {Object}
	 */
	async updateMessengerIntegration({ id, properties, status }) {
		try {
			let response = await Kichiri.location.updateMessengerIntegration(
				{
					id,
					properties,
					status
				},
				{},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Check if Voice is enabled
	 * @returns {Boolean}
	 */
	isVoiceEnabled() {
		try {
			const user = UserService.get();
			return this.isLocationFeaturePermissible(LOCATION_FEATURES.messenger.id) && this.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_voice.id);
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Check if Voicemail is enabled for a location
	 * @returns {Boolean}
	 */
	isVoicemailEnabled() {
		try {
			return this.isVoiceEnabled() && this.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_voice_voicemail.id);
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Check if Voicemail Transcription is enabled for a location
	 * @returns {Boolean}
	 */
	isVoicemailTranscriptionEnabled() {
		try {
			return this.isVoicemailEnabled() && this.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_voice_voicemail_transcription.id);
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Check if a user is allowed to access Voicemail data
	 * @returns {Boolean}
	 */
	isVoicemailPermissible() {
		try {
			const user = UserService.get();
			let { view_voicemail } = user.GroupPermission;

			return view_voicemail && this.isVoicemailEnabled();
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Whether to show the account setup or not
	 * @returns {Boolean}
	 */
	async showAccountSetup() {
		try {
			let crmStatus = await CrmIntegrationsService.crmIntegrationsSetupStatus();
			let isCrmIntegrationExpected = crmStatus.integrationsExpected;
			let allCrmsActive = crmStatus.integrationsActive;

			const locationId = UserService.getActiveLocation().id;

			const messengerIntegrations = await this.hasIntegrations(locationId);

			let data = await this.isWidgetCodeInstalled({ locationId });

			// Gmb states
			let isGmbConnected = messengerIntegrations.gmb;

			// Widget states
			let isWidgetCodeExpected = data.isWidgetCodeExpected;
			let isWidgetCodeInstalled = data.isWidgetCodeInstalled;

			// Flags for Gmb Connection
			let isReviewsEnabled = this.isReviewsEnabled();

			if (!isReviewsEnabled && !isCrmIntegrationExpected && !isWidgetCodeExpected) {
				// If nothing is expected to be enabled
				return false;
			}

			if (isGmbConnected && allCrmsActive && isWidgetCodeInstalled) {
				// If everything is enabled correctly
				// The account is set up
				return false;
			}

			return true;
		} catch (error) {
			console.log(error);
		}
		return false;
	},

	/**
	 * Check whether the widgets are called and return related flags as well
	 * @returns {Object}
	 */
	async isWidgetCodeInstalled() {
		// Flags for widget code
		let isWebchatPermissible = this.isWebchatPermissible();
		let isReviewsWidgetPermissible = this.isReviewsWidgetPermissible();
		let isBookingsPermissible = this.isBookingsPermissible();

		let isWidgetCodeExpected = isWebchatPermissible || isReviewsWidgetPermissible || isBookingsPermissible;
		let isWidgetCodeInstalled = true;
		let isNucleusInstalled = false;
		let isReviewsWidgetInstalled = false;
		let isWebchatInstalled = false;

		try {
			const locationId = UserService.getActiveLocation().id;
			let data = await this.getLocationWidgetsInstalled({ locationId });

			if (data) {
				isNucleusInstalled = data.isNucleusInstalled;
				isWebchatInstalled = data.isWebchatInstalled;
				isReviewsWidgetInstalled = data.isReviewsWidgetInstalled;

				if (isWebchatPermissible) {
					isWidgetCodeInstalled = isWidgetCodeInstalled && isWebchatInstalled;
				}
				if (isReviewsWidgetPermissible) {
					isWidgetCodeInstalled = isWidgetCodeInstalled && isReviewsWidgetInstalled;
				}
				if (isBookingsPermissible) {
					isWidgetCodeInstalled = isWidgetCodeInstalled && isNucleusInstalled;
				}
			}
		} catch (error) {
			console.log(error);
		}

		return {
			isWebchatPermissible,
			isReviewsWidgetPermissible,
			isBookingsPermissible,

			isWidgetCodeExpected,
			isWidgetCodeInstalled,
			isNucleusInstalled,
			isWebchatInstalled,
			isReviewsWidgetInstalled
		};
	},

	/**
	 * Get location widget code installed data
	 * @param {Number} locationId
	 */
	async getLocationWidgetsInstalled({ locationId }) {
		try {
			const response = await Kichiri.location.getLocationWidgetsInstalled({ locationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Checks for mismatches between the default area code, the main phone area code, and the area codes of other numbers associated with the location.
	 * @param {String} phone The phone number we are checking against
	 * @param {String} defaultAreaCode The locations default area code if it is set
	 * @param {Array} numbers An array of the locations phone numbers
	 * @returns {Boolean}
	 */
	isAreaCodeMismatching({ phone, defaultAreaCode, numbers }) {
		// If phone number is not provided, return false
		if (!phone) {
			return false;
		}

		// Deformat the phone number to extract the main phone area code
		let deformattedNumber = UtilityService.deFormatPhoneNumber(phone);
		let mainPhoneAreaCode = deformattedNumber.substring(0, 3);

		// Check if phone and numbers are available and if there are any numbers
		if (phone && numbers && numbers.length > 0) {
			// Loop through each number associated with the location

			for (let i = 0; i < numbers.length; i++) {
				const number = numbers[i];
				let phoneNumber = UtilityService.deFormatPhoneNumber(number.number);
				let numberAreaCode = phoneNumber.substring(0, 3);

				// Check for mismatch between default area code and number's area code
				if (defaultAreaCode && defaultAreaCode !== numberAreaCode) {
					return true;
				}

				// Check for mismatch between main phone area code and number's area code
				if (mainPhoneAreaCode !== numberAreaCode) {
					return true;
				}
			}
		}

		return false;
	}
};

export default LocationService;
