import Kichiri from "./KichiriService";
import UserService from "./UserService";

export default {
	/**
	 * Fetch
	 * @param {Object} params Query params
	 * @returns {Promise} A list of api keys
	 */
	async fetch(locationId, params) {
		let query = {
			searchTerm: "",
			sortField: "",
			sortOrder: "",
			limit: 30,
			offset: 0
		};

		query = Object.assign(query, params);

		try {
			const response = await Kichiri.api_keys.fetch({ locationId: locationId }, query, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Create an api key
	 * @param {Integer} locationId
	 * @param {String} name
	 * @param {String} username
	 * @param {String} password
	 * @param {String} knownHosts
	 *
	 * @returns {Boolean}
	 */
	async create({ locationId, name, username, password, knownHosts }) {
		try {
			await Kichiri.api_keys.create({ locationId, name, username, password, known_hosts: knownHosts }, {}, UserService.getAuthToken());

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update an api key
	 * @param {Integer} locationId
	 * @param {String} name
	 * @param {String} username
	 * @param {String} password
	 * @param {String} token
	 * @param {String} status
	 * @param {String} knownHosts
	 *
	 * @returns {Boolean}
	 */
	async update({ apiKeyId, name, username, password, token, status, knownHosts }) {
		try {
			await Kichiri.api_keys.update({ apiKeyId, name: name || "", username, password, token, status, known_hosts: knownHosts }, {}, UserService.getAuthToken());

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get all api key names
	 * @returns {Array}
	 */
	async getAllKeyNames() {
		try {
			const response = await Kichiri.api_keys.getAllKeyNames({}, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	}
};
