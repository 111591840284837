import React from "react";
import ReactLinkify from "react-linkify";
import UtilityService from "../../services/UtilityService";

const Linkify = ({ children, classNames = [], charLimit }) => {
	classNames.push("mb-message-content-link");

	return (
		<ReactLinkify
			componentDecorator={(href, text, key) => (
				<a target="_blank" rel="noopener noreferrer" href={href} key={key} className={classNames.join(" ")}>
					{charLimit && text.length > charLimit ? UtilityService.condenseURL({ url: text }) : text}
				</a>
			)}
		>
			{children}
		</ReactLinkify>
	);
};

export default Linkify;
