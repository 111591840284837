import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import config from "../../config/app/web-app.config";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";
import SupportChatService from "../../services/SupportChatService";
import ToastService from "../../services/ToastService";

import ConnectionsCoreServices from "./ConnectionsCoreServices";
import ConnectionsCard from "./ConnectionsCard";
import CRMUpdateModal from "../CRMIntegrations/CRMUpdateModal";
import withLocation from "../../components/common/WithLocation";
import ConnectOdOfficeProModal from "./ConnectOdOfficeProModal";

import { CRM_INTEGRATIONS, CRM_LOGO_URL, CRM_COMPONENT_MAP } from "../../constants/CRMIntegration";
import { STATUS } from "../../constants/CommonConstants";

import "../../styles/css/scenes/connections.css";

class ConnectionsOverview extends Component {
	constructor(props) {
		super(props);

		this.state = {
			appList: {},
			restIntegrationTypes: [],

			// Crm Modal
			modalCrmIntegrationId: null,
			showCrmIntegrationModal: false,

			// Restful API Modal
			modalAppId: null, // ex: janeapp, mindbody, etc
			restfulApiIntegrationId: null,
			showRestfulConnectModal: false
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = async location => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.update({
			appList: {},
			restIntegrationTypes: [],

			// Crm Modal
			modalCrmIntegrationId: null,
			showCrmIntegrationModal: false,

			// Restful API Modal
			modalAppId: null,
			restfulApiIntegrationId: null,
			showRestfulConnectModal: false
		});

		await this.fetchApps();
		await this.fetchRestIntegrationTypes();
	};

	fetchApps = async () => {
		let appList = await CrmIntegrationsService.fetchApps();
		await this.update({ appList });
	};

	fetchRestIntegrationTypes = async () => {
		let location = UserService.getActiveLocation().id;

		let integrationArray = [];

		let integrations = await CrmIntegrationsService.fetch(
			location,
			{
				status: [STATUS.active]
			},
			true
		);

		if (integrations && integrations.length !== 0) {
			integrationArray = integrations;
		}

		await this.update({ connectedApps: integrationArray });
	};

	onSupportOpen = chatText => {
		SupportChatService.showNewMessage(chatText);
	};

	onShowCrmIntegrationModal = crmIntegrationId => {
		this.update({ modalCrmIntegrationId: crmIntegrationId, showCrmIntegrationModal: true });
	};

	onHideCrmIntegrationModal = async () => {
		await this.update({ modalCrmIntegrationId: null, showCrmIntegrationModal: false });

		await this.resetComponent();
	};

	onShowRestfulApiIntegrationModal = (crmIntegrationId, modalAppId) => {
		this.update({
			restfulApiIntegrationId: crmIntegrationId,
			modalAppId: modalAppId,
			showRestfulConnectModal: true
		});
	};

	onHideRestfulApiIntegrationModal = async () => {
		await this.update({
			restfulApiIntegrationId: null,
			modalAppId: null,
			showRestfulConnectModal: false
		});

		await this.resetComponent();
	};

	onUpdateModalSubmit = async result => {
		if (!result) {
			ToastService.error("Failed to update CRM Integration");
		}

		ToastService.info("CRM Integration updated successfully");

		await this.resetComponent();
	};

	renderRestfulModal() {
		let { modalAppId, restfulApiIntegrationId, showRestfulConnectModal } = this.state;

		if (!modalAppId || !restfulApiIntegrationId || !showRestfulConnectModal) {
			return null;
		}

		const CRMModalComponent = CRM_COMPONENT_MAP[modalAppId];

		if (!CRMModalComponent) {
			return null;
		}

		return (
			<CRMModalComponent
				show={showRestfulConnectModal}
				onHide={this.onHideRestfulApiIntegrationModal}
				integrationId={restfulApiIntegrationId}
				disconnectIntegration={() => {
					this.disconnectIntegration(restfulApiIntegrationId);
				}}
			/>
		);
	}

	disconnectIntegration = async crmIntegrationId => {
		let integration = null;

		// Find the integration
		for (let i = 0; i < this.state.connectedApps.length; i++) {
			if (this.state.connectedApps[i].id === crmIntegrationId) {
				integration = this.state.connectedApps[i];
				break;
			}
		}

		if (!integration) {
			ToastService.error(`Failed to disconnect the integration. Please try again.`);
			this.update({ showDisconnectModal: false });
			return;
		}

		let success = await CrmIntegrationsService.update({
			id: crmIntegrationId,
			crmType: integration.crm_type,
			crmVersion: integration.crm_version,
			integrationType: integration.integration_type,
			direction: integration.direction,
			metadata: JSON.stringify(integration.meta_data),
			status: STATUS.deleted
		});

		if (!success) {
			ToastService.info(`Failed to disconnect the integration. Please try again.`);
			this.update({ showDisconnectModal: false });
			return;
		}

		ToastService.info(`Disconnected the integration.`);

		await this.resetComponent();
	};

	getRestfulApiIntegrationStatus = integration => {
		let type = integration.crm_type;

		if (type === CRM_INTEGRATIONS.type.janeapp.name) {
			if (integration.meta_data && integration.meta_data.cookies) {
				return integration.meta_data.cookies.some(cookie => cookie.includes("jane_device"));
			} else {
				return false;
			}
		} else if (type === CRM_INTEGRATIONS.type.noterro.name) {
			if (integration.meta_data && integration.meta_data.cookies) {
				return integration.meta_data.cookies.some(cookie => cookie.includes("redis_cookie_"));
			} else {
				return false;
			}
		}

		return integration.meta_data && integration.meta_data.lastSessionCheckStatus ? integration.meta_data.lastSessionCheckStatus === true : false;
	};

	renderConnectedApps = () => {
		let { connectedApps, appList } = this.state;

		if (!connectedApps || connectedApps.length === 0 || !appList || Object.keys(appList).length === 0) {
			return;
		}

		let user = UserService.get();
		let canConnectLocations = user.GroupPermission.connect_locations;

		return (
			<div className="connections__section">
				<div className="connections__section-title">CONNECTED APPS</div>
				<div className="connections__section-list">
					{connectedApps.map(app => {
						// Get the app data from the config
						let appConfig = appList[app.crm_type];

						let isRestfulIntegration = !appConfig.requiresConnectionAssistance;

						let buttonAction = null;

						if (canConnectLocations) {
							// If it's a restful api integration, we need to handle it differently
							if (isRestfulIntegration) {
								buttonAction = () => {
									this.onShowRestfulApiIntegrationModal(app.id, appConfig.id);
								};
							} else {
								buttonAction = () => {
									this.onShowCrmIntegrationModal(app.id);
								};
							}
						}

						// Check if we can pull the status from the metadata
						let status = isRestfulIntegration ? this.getRestfulApiIntegrationStatus(app) : null;

						// Decide on action text
						let actionText = "View Details";

						if (isRestfulIntegration && !status) {
							actionText = "Reconnect";
						}

						return (
							<ConnectionsCard
								key={app.id}
								crmIntegrationId={app.id}
								crmIntegrationData={app}
								title={`${app.name} (${appConfig.name})`}
								description={appConfig.description}
								logoUrl={`${config.CDN_URL}${CRM_LOGO_URL}${appConfig.logo}`}
								actionText={actionText}
								status={status}
								onClick={buttonAction}
							/>
						);
					})}
				</div>
			</div>
		);
	};

	render() {
		let { modalCrmIntegrationId, showCrmIntegrationModal } = this.state;

		let locationId = UserService.getActiveLocation().id;

		return (
			<div className="connections">
				<div className="connections__inner">
					{this.renderConnectedApps()}
					<ConnectionsCoreServices />
				</div>
				<CRMUpdateModal
					id={modalCrmIntegrationId}
					locationId={locationId}
					show={showCrmIntegrationModal}
					onHide={this.onHideCrmIntegrationModal}
					createMode={false}
					onSubmit={this.onUpdateModalSubmit}
				/>
				{this.renderRestfulModal()}
			</div>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(ConnectionsOverview)));
