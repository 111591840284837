import React from "react";
import Times from "../../constants/Times.json";
import FifteenMinuteTimes from "../../constants/FifteenMinuteTimes.json";
import Select from "../../components/common/Select";

import "../../styles/css/components/commons/time-dropdown.css";

const timeIntervals = {
	fifteenMinutes: 15,
	thirtyMinutes: 30
};

const ThirtyMinuteTimes = Times;

const TimeDropdown = ({ id, handleChange, value, isDisabled = false, allow24thHour = false, timeInterval = timeIntervals.thirtyMinutes }) => {
	// Time options to select from, default is thirty minute intervals
	let timeOptions = timeInterval === timeIntervals.thirtyMinutes ? [...ThirtyMinuteTimes] : [...FifteenMinuteTimes];

	// If we want 24 hours
	if (allow24thHour) {
		timeOptions = timeInterval === timeIntervals.thirtyMinutes ? [...ThirtyMinuteTimes] : [...FifteenMinuteTimes];
		// DH tootiness
		timeOptions.push({ value: "24:00", label: "24:00" });
	}

	return (
		<div className="TimeDropdown--container">
			<Select
				id={id}
				disabled={isDisabled}
				value={value}
				onChange={(newValue, actionMeta) => handleChange(newValue, actionMeta)}
				options={timeOptions}
				placeholder="HH:MM"
				clearable={false}
				isSearchable={false}
			/>
		</div>
	);
};

export default TimeDropdown;
