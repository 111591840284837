import React from "react";
import * as Icon from "react-feather";
import c3 from "c3";

import PaymentService from "../../services/PaymentService";
import { AnalyticsService } from "../../services/AnalyticsService";
import UserService from "../../services/UserService";
import SupportChatService from "../../services/SupportChatService";

import withLocation from "../../components/common/WithLocation";

class PaymentsCount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			paymentRequests: 0,
			paymentEstimates: 0,
			paymentsReceived: 0
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	resetComponent = async () => {
		await this.fetchData();
		this.refreshChart();
	};

	onLocationChanged = () => {
		this.resetComponent();
	};

	fetchData = async () => {
		let data = await AnalyticsService.getTotalPaymentsCount({ locationId: UserService.getActiveLocation().id });

		if (!data) {
			return;
		}

		await this.update({ paymentRequests: data.paymentRequests, paymentEstimates: data.paymentEstimates, paymentsReceived: data.paymentsReceived });
	};

	refreshChart = () => {
		let { paymentRequests, paymentEstimates, paymentsReceived } = this.state;

		let isPaymentsEnabled = PaymentService.isPaymentsEnabled();

		if (!isPaymentsEnabled) {
			return;
		}

		c3.generate({
			bindto: "#dashboard__total--payments",
			data: {
				columns: [
					[`Payment Requests`, paymentRequests],
					[`Payments Received`, paymentEstimates],
					[`Estimates Sent`, paymentsReceived]
				],
				type: "bar",
				colors: {
					"Payment Requests": "#6761DB",
					"Payments Received": "#6198DB",
					"Estimates Sent": "#61D5DB"
				}
			},
			bar: {
				width: {
					ration: 0.5
				},
				title: "Payments"
			},
			axis: {
				rotated: true
			},
			size: {
				height: 90
			}
		});
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onGetStarted = () => {
		SupportChatService.showNewMessage("Hi, I would like to enable Payments. Would you be able to help?");
	};

	renderHeader = () => {
		const { cardName } = this.props;

		return (
			<div className="dashboard__card__header">
				<div className="dashboard__card__header__icon">
					<Icon.DollarSign size="42" />
				</div>
				<div className="dashboard__card__header__name dashboard__card__header__name--margin-left">{cardName ? cardName : "Payments"}</div>
			</div>
		);
	};

	render = () => {
		let { paymentRequests, paymentEstimates, paymentsReceived } = this.state;
		const { isEnabled, isPermissible } = this.props;

		if (isEnabled && !isEnabled()) {
			return (
				<div className="dashboard__card--payments">
					{this.renderHeader()}
					<div className="dashboard__widget__payments dashboard__widget__payments--get-started">
						<div className="dashboard__widget__payments__text">
							Send payment requests, receive credit card information, and send estimates via text message.
						</div>
						<div className="mb-button" onClick={this.onGetStarted}>
							Get Started
						</div>
					</div>
				</div>
			);
		}

		if (isPermissible && !isPermissible()) {
			return (
				<div className="dashboard__card--payments">
					{this.renderHeader()}
					<div className="dashboard__widget__payments dashboard__widget__payments--get-started">
						<div className="dashboard__widget__payments__text">Payment data restricted.</div>
					</div>
				</div>
			);
		}

		if (paymentRequests === 0 && paymentEstimates === 0 && paymentsReceived === 0) {
			return (
				<div className="dashboard__card--payments">
					{this.renderHeader()}
					<div className="dashboard__widget__payments dashboard__widget__payments--get-started">
						<div className="dashboard__widget__payments__text">Not applicable. No payment requests sent.</div>
					</div>
				</div>
			);
		}

		return (
			<>
				{this.renderHeader()}
				<div className="dashboard__widget__payments">
					<div id="dashboard__total--payments" />
				</div>
			</>
		);
	};
}

export default withLocation(PaymentsCount);
