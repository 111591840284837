import React, { Component } from "react";
import DOMPurify from "dompurify";
import ReactHtmlParser from "react-html-parser";
import Iframe from "react-frame-component";
import { withTranslation } from "react-i18next";

import Input from "../../components/common/Input";

import "../../styles/css/components/commons/signature.css";

const HTML_IFRAME_STYLES = {
	backgroundColor: "#ffffff",
	border: "1px solid #e5e6e7",
	borderRadius: "5px",
	width: "100%",
	minWidth: "100%",
	height: "160px",
	background: "white"
};

class Signature extends Component {
	constructor(props) {
		super(props);

		this.state = {
			plainTextSignature: "",
			htmlSignature: "",
			showHTMLSignaturePreview: false
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async componentDidMount() {
		if (this.props.plainTextSignature) {
			await this.update({ plainTextSignature: this.props.plainTextSignature });
		}

		if (this.props.htmlSignature) {
			await this.update({ htmlSignature: this.props.htmlSignature, showHTMLSignaturePreview: true });
		}
	}

	async componentDidUpdate(prevProps) {
		const { plainTextSignature, htmlSignature } = prevProps;

		if (this.props.plainTextSignature !== plainTextSignature) {
			await this.update({ plainTextSignature: this.props.plainTextSignature });
		}

		if (this.props.htmlSignature !== htmlSignature) {
			await this.update({ htmlSignature: this.props.htmlSignature });
		}
	}

	onPlainTextSignatureChange = async event => {
		let newValue = event.target.value;

		// Save to state right away instead of getting from prop
		// because typing events can be too fast to keep up
		await this.update({ plainTextSignature: newValue });

		if (this.props.onPlainTextSignatureChange) {
			await this.props.onPlainTextSignatureChange(newValue);
		}
	};

	onHtmlSignatureChange = async event => {
		let newValue = event.target.value;

		// Save to state right away instead of getting from prop
		// because typing events can be too fast to keep up
		await this.update({ htmlSignature: newValue });

		if (this.props.onHtmlSignatureChange) {
			await this.props.onHtmlSignatureChange(newValue);
		}
	};

	handleHtmlSignaturePreviewToggle = async () => {
		let { showHTMLSignaturePreview } = this.state;
		await this.update({ showHTMLSignaturePreview: !showHTMLSignaturePreview });
	};

	renderHtmlSignature = () => {
		let { htmlSignature } = this.state;
		let { t } = this.props;

		let cleanHTML = DOMPurify.sanitize(htmlSignature);

		return (
			<>
				<div className="input__description">{t("A HTML signature can be appended at the end of an Email.")}</div>
				<div className="signature__html-signature__preview">
					<Iframe style={HTML_IFRAME_STYLES}>{ReactHtmlParser(cleanHTML)}</Iframe>
				</div>
			</>
		);
	};

	render() {
		let { plainTextSignature, htmlSignature, showHTMLSignaturePreview } = this.state;
		let { t } = this.props;

		return (
			<>
				<Input
					id="messengerSignature"
					label=""
					description={t("A signature will be appended to the end of messages.")}
					name="messengerSignature"
					type="text"
					value={plainTextSignature}
					placeholder={t(`eg. - John Smith`)}
					onChange={this.onPlainTextSignatureChange}
				/>

				{!showHTMLSignaturePreview && (
					<Input
						id="messengerHtmlSignature"
						label=""
						description={t("A HTML signature can be appended at the end of an Email.")}
						name="messengerHtmlSignature"
						type="textarea"
						value={htmlSignature}
						onChange={this.onHtmlSignatureChange}
						placeholder={t(`eg. <span>John Smith</span>`)}
					/>
				)}
				{showHTMLSignaturePreview && this.renderHtmlSignature()}

				<div
					className="signature__html-signature__toggle_preview"
					onClick={() => {
						this.handleHtmlSignaturePreviewToggle();
					}}
				>
					{showHTMLSignaturePreview ? t("Edit HTML Signature") : t("Preview HTML Signature")}
				</div>
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(Signature);
