/**
 * Specific constants for Bulk Review Invite sending
 */

export const BULK_TYPES = {
	reviewRequest: {
		id: "reviewRequest",
		name: "review_request",
		friendlyName: "review invites"
	},
	sendMessage: {
		id: "sendMessage",
		name: "send_message",
		friendlyName: "messages"
	},
	contactUpload: {
		id: "contactUpload",
		name: "contact_upload",
		friendlyName: "contact upload"
	}
};

export const BULK_SCHEDULE_TYPES = {
	sendImmediately: {
		id: "sendImmediately"
	},
	sendAfter: {
		id: "sendAfter"
	},
	options: [
		{
			label: "Send Now",
			value: "sendImmediately"
		},
		{
			label: "Send later",
			value: "sendAfter"
		}
	]
};

export const COLUMN_HEADING_TEXT = {
	name: "Name",
	phone: "Cell Phone",
	email: "Email"
};

export const COLUMN_HEADING_OPTION = {
	name: { label: "Name", value: "name" },
	firstName: { label: "First Name", value: "dh_first_name" },
	lastName: { label: "Last Name", value: "dh_last_name" },
	phone: { label: "Cell Phone", value: "phone" },
	email: { label: "Email", value: "email" },
	homePhone: { label: "Home Phone", value: "home_phone" },
	workPhone: { label: "Work Phone", value: "work_phone" },
	address1: { label: "Address 1", value: "address_1" },
	address2: { label: "Address 2", value: "address_2" },
	city: { label: "City", value: "city" },
	stateProv: { label: "State or Province", value: "state_prov" },
	zipPostal: { label: "Zip or Postal Code", value: "zip_postal" },
	country: { label: "Country", value: "country" },
	notes: { label: "Notes", value: "notes" }
};

export const COLUMN_HEADING_OPTION_MAP = {
	name: "name",
	dh_first_name: "firstName",
	dh_last_name: "lastName",
	phone: "phone",
	email: "email"
};

/**
 * Supported CSV mime types
 */
export const CSV_FILE_TYPES = [
	"text/comma-separated-values",
	"text/comma-separated-values",
	"text/csv",
	"application/csv",
	"application/vnd.ms-excel",
	"application/vnd.msexcel"
];
/**
 * Data for sample CSV file to be downloaded
 */
export const SAMPLE_CSV_DATA = [
	["Name", "Phone", "Email"],
	["John Doe", 6476713142, "jdoe@example.com"],
	["Bob Johnson", 6476713142, "bjohnson@example.com"]
];
/**
 * Threshold for invalid emails
 */
export const INVALID_EMAIL_THRESHOLD = 0.4;
