import React from "react";
import Select from "react-select";
import ReactSwitch from "react-switch";
import { withTranslation } from "react-i18next";

import ReengagementService from "../../../services/ReengagementService";
import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import TemplateService from "../../../services/TemplateService";

import Modal from "../../../components/common/DHModal";
import Input from "../../../components/common/Input";

import { TEMPLATE_TYPE } from "../../../constants/TemplateConstants";
import { STATUS, STATUS_LABEL } from "../../../constants/CommonConstants";

const REENGAGEMENT_DEFAULTS = {
	appointmentLookbackDays: 180,
	lastAppointmentDays: 30,
	lastMessageDays: 45,
	maxAnnualReengagements: 3,
	matchUpcomingAppointments: false,
	delayHours: 24,
	types: "",
	typesExactMatch: true,
	assignedReps: "",
	assignedRepsExactMatch: true,
	apptStates: ""
};

const INPUT_LIMITS = {
	nameMax: 255,
	appointmentLookbackDaysMin: 30,
	lastAppointmentDaysMin: 7,
	lastMessageDaysMin: 3,
	maxAnnualReengagementsMin: 1,
	delayHoursMin: 0
};

class ReengagementManageModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			edited: false,

			name: "",
			status: { value: STATUS.active, label: STATUS_LABEL[STATUS.active] },
			statusOptions: [
				{ value: STATUS.active, label: STATUS_LABEL[STATUS.active] },
				{ value: STATUS.inactive, label: STATUS_LABEL[STATUS.inactive] }
			],
			template: null,
			templateOptions: [],
			appointmentLookbackDays: REENGAGEMENT_DEFAULTS.appointmentLookbackDays,
			lastAppointmentDays: REENGAGEMENT_DEFAULTS.lastAppointmentDays,
			lastMessageDays: REENGAGEMENT_DEFAULTS.lastMessageDays,
			maxAnnualReengagements: REENGAGEMENT_DEFAULTS.maxAnnualReengagements,
			matchUpcomingAppointments: REENGAGEMENT_DEFAULTS.matchUpcomingAppointments,
			delayHours: REENGAGEMENT_DEFAULTS.delayHours,
			types: REENGAGEMENT_DEFAULTS.types,
			typesExactMatch: REENGAGEMENT_DEFAULTS.typesExactMatch,
			assignedReps: REENGAGEMENT_DEFAULTS.assignedReps,
			assignedRepsExactMatch: REENGAGEMENT_DEFAULTS.assignedRepsExactMatch,
			apptStates: REENGAGEMENT_DEFAULTS.apptStates
		};
	}

	componentDidUpdate = prevProps => {
		let { show } = this.props;

		if (prevProps.show !== show && show) {
			this.resetComponent();
		}
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onHide = (resetComponent = false) => {
		if (this.props.onHide) {
			this.props.onHide(resetComponent);
		}
	};

	resetComponent = async () => {
		let { t } = this.props;

		let templateOptions = await TemplateService.getTemplatesForType([TEMPLATE_TYPE.reengagementRequest]);

		templateOptions = templateOptions.map(t => {
			return { value: t.id, label: t.name };
		});

		await this.update({
			name: "",
			status: { value: STATUS.active, label: STATUS_LABEL[STATUS.active] },
			template: null,
			templateOptions,
			appointmentLookbackDays: REENGAGEMENT_DEFAULTS.appointmentLookbackDays,
			lastAppointmentDays: REENGAGEMENT_DEFAULTS.lastAppointmentDays,
			lastMessageDays: REENGAGEMENT_DEFAULTS.lastMessageDays,
			maxAnnualReengagements: REENGAGEMENT_DEFAULTS.maxAnnualReengagements,
			matchUpcomingAppointments: REENGAGEMENT_DEFAULTS.matchUpcomingAppointments,
			delayHours: REENGAGEMENT_DEFAULTS.delayHours,
			types: REENGAGEMENT_DEFAULTS.types,
			typesExactMatch: REENGAGEMENT_DEFAULTS.typesExactMatch,
			assignedReps: REENGAGEMENT_DEFAULTS.assignedReps,
			assignedRepsExactMatch: REENGAGEMENT_DEFAULTS.assignedRepsExactMatch,
			apptStates: REENGAGEMENT_DEFAULTS.apptStates
		});

		if (!this.props.id) {
			return;
		}

		let reengagement = await ReengagementService.fetchReengagement({ locationId: UserService.getActiveLocation().id, reengagementId: this.props.id });

		if (!reengagement) {
			ToastService.error(t("Error fetching the reengagement. Please try again."));
			return;
		}

		let status = reengagement.status;
		let template = templateOptions.find(t => t.value === reengagement.template_id);

		if (!template) {
			template = null;
		}

		this.update({
			name: reengagement.name,
			status: { value: status, label: STATUS_LABEL[status] },
			template: template,
			appointmentLookbackDays: reengagement.appointment_look_back_days,
			lastAppointmentDays: reengagement.contact_last_appointment_days,
			lastMessageDays: reengagement.last_message_days,
			maxAnnualReengagements: reengagement.max_annual_reengagements,
			matchUpcomingAppointments: reengagement.match_upcoming_appointments,
			delayHours: reengagement.delay_hours,
			types: reengagement.meta_data.types ? reengagement.meta_data.types.join(",") : [],
			typesExactMatch: typeof reengagement.meta_data.typesExactMatch !== "undefined" ? reengagement.meta_data.typesExactMatch : true,
			assignedReps: reengagement.meta_data.assignedReps ? reengagement.meta_data.assignedReps.join(",") : [],
			assignedRepsExactMatch: typeof reengagement.meta_data.assignedRepsExactMatch !== "undefined" ? reengagement.meta_data.assignedRepsExactMatch : true,
			apptStates: reengagement.meta_data.apptStates ? reengagement.meta_data.apptStates.join(",") : []
		});
	};

	onSave = async () => {
		let { t } = this.props;

		if (this.invalid()) {
			return;
		}

		let {
			name,
			status,
			template,
			appointmentLookbackDays,
			lastAppointmentDays,
			lastMessageDays,
			maxAnnualReengagements,
			matchUpcomingAppointments,
			delayHours,
			types,
			typesExactMatch,
			assignedReps,
			assignedRepsExactMatch,
			apptStates
		} = this.state;

		if (!template) {
			ToastService.info(t("Please select a template."));
			return;
		}

		// If we are updating
		if (this.props.id) {
			let response = await ReengagementService.update({
				locationId: UserService.getActiveLocation().id,
				reengagementId: this.props.id,
				templateId: template.value,
				name,
				appointmentLookbackDays,
				lastAppointmentDays,
				lastMessageDays,
				maxAnnualReengagements,
				matchUpcomingAppointments,
				delayHours,
				types: types && types.length > 0 ? types : [],
				typesExactMatch,
				assignedReps: assignedReps && assignedReps.length > 0 ? assignedReps : [],
				assignedRepsExactMatch,
				apptStates: apptStates && apptStates.length > 0 ? apptStates : [],
				status: status.value
			});

			if (!response) {
				ToastService.error(t("Error occurred trying to create Reengagement. Please try again."));
				return;
			}

			ToastService.info(t("Reengagement updated!"));
			this.onHide(true);
			return;
		}

		// If we are creating
		let response = await ReengagementService.create({
			locationId: UserService.getActiveLocation().id,
			name,
			templateId: template.value,
			appointmentLookbackDays,
			lastAppointmentDays,
			lastMessageDays,
			maxAnnualReengagements,
			matchUpcomingAppointments,
			delayHours,
			types: types && types.length > 0 ? types : [],
			typesExactMatch,
			assignedReps: assignedReps && assignedReps.length > 0 ? assignedReps : [],
			assignedRepsExactMatch,
			apptStates: apptStates && apptStates.length > 0 ? apptStates : [],
			status: status.value
		});

		if (!response) {
			ToastService.error(t("Error occurred trying to create Reengagement. Please try again."));
			return;
		}

		ToastService.info(t("Reengagement created!"));
		this.onHide(true);
	};

	handleGenericEventHandler = (event, name) => {
		if (!name) {
			name = event.target.name;
		}
		let value = event.target ? event.target.value : event;
		this.update({ [name]: value, edited: true });
	};

	onSelectChange = option => {
		this.update({ template: option });
	};

	onStatusChange = option => {
		this.update({ status: option });
	};

	onChange = (field, value) => {
		this.update({
			[field]: value
		});
	};

	Switch = ({ field, checked, onChange }) => {
		return (
			<ReactSwitch
				id={field}
				height={22}
				width={38}
				checked={checked}
				uncheckedIcon={false}
				checkedIcon={false}
				onColor="#60A9FF"
				offColor="#c5c5c5"
				onChange={newValue => {
					onChange(field, newValue);
				}}
				disabled={false}
			/>
		);
	};

	isInputValid = (value, min) => {
		return value && parseInt(value) < min;
	};

	invalid = () => {
		const { name, appointmentLookbackDays, lastAppointmentDays, lastMessageDays, maxAnnualReengagements, delayHours } = this.state;

		if (!name || name.length > INPUT_LIMITS.nameMax) {
			return true;
		}

		if (!appointmentLookbackDays || parseInt(appointmentLookbackDays) < INPUT_LIMITS.appointmentLookbackDaysMin) {
			return true;
		}

		if (!lastAppointmentDays || parseInt(lastAppointmentDays) < INPUT_LIMITS.lastAppointmentDaysMin) {
			return true;
		}

		if (!lastMessageDays || parseInt(lastMessageDays) < INPUT_LIMITS.lastMessageDaysMin) {
			return true;
		}

		if (!maxAnnualReengagements || parseInt(maxAnnualReengagements) < INPUT_LIMITS.maxAnnualReengagementsMin) {
			return true;
		}

		if (!delayHours || parseInt(delayHours) < INPUT_LIMITS.delayHoursMin) {
			return true;
		}

		if (parseInt(appointmentLookbackDays) < parseInt(lastAppointmentDays)) {
			return true;
		}

		return false;
	};

	render = () => {
		const { show, id, t } = this.props;
		const {
			name,
			status,
			statusOptions,
			template,
			templateOptions,
			appointmentLookbackDays,
			lastAppointmentDays,
			lastMessageDays,
			maxAnnualReengagements,
			matchUpcomingAppointments,
			delayHours,
			types,
			typesExactMatch,
			assignedReps,
			assignedRepsExactMatch,
			apptStates
		} = this.state;

		return (
			<Modal show={show} onHide={this.onHide} title={id ? t("Update Setting") : t("Create Setting")}>
				<div style={{ width: "100%", maxWidth: "500px" }}>
					<Input
						id="name"
						name="name"
						label={t("Name")}
						type="text"
						maxLength={INPUT_LIMITS.nameMax}
						onChange={e => this.handleGenericEventHandler(e)}
						value={name}
						invalid={name && name.length > INPUT_LIMITS.nameMax}
					/>

					<div>{t("Template")}</div>
					<Select id={`reengagement-select`} options={templateOptions} value={template} placeholder={t("Select Template")} onChange={this.onSelectChange} />
					<br></br>

					<Input
						id="appointmentLookbackDays"
						name="appointmentLookbackDays"
						label={t("Appointment Look Back Days")}
						description={t(
							`The amount of days to look back at all existing appointments for a location. Minimum of ${INPUT_LIMITS.appointmentLookbackDaysMin}.`
						)}
						type="number"
						onChange={e => this.handleGenericEventHandler(e)}
						value={appointmentLookbackDays}
						invalid={this.isInputValid(appointmentLookbackDays, INPUT_LIMITS.appointmentLookbackDaysMin)}
					/>
					<Input
						id="lastAppointmentDays"
						name="lastAppointmentDays"
						label={t("Last Appointment Days")}
						description={t(
							`The amount of days to look back to see if the most recent appointment booking data is more than X days ago. Minimum of ${INPUT_LIMITS.lastAppointmentDaysMin}. Must be less than 'Appointment Look Back Days'.`
						)}
						type="number"
						onChange={e => this.handleGenericEventHandler(e)}
						value={lastAppointmentDays}
						invalid={this.isInputValid(lastAppointmentDays, INPUT_LIMITS.lastAppointmentDaysMin)}
					/>
					<Input
						id="lastMessageDays"
						name="lastMessageDays"
						label={t("Last Message Days")}
						description={t(
							`The amount of days to look back to for scheduled messages reengagement messages and regular messages. Minimum of ${INPUT_LIMITS.lastMessageDaysMin}.`
						)}
						type="number"
						onChange={e => this.handleGenericEventHandler(e)}
						value={lastMessageDays}
						invalid={this.isInputValid(lastMessageDays, INPUT_LIMITS.lastMessageDaysMin)}
					/>

					<Input
						id="maxAnnualReengagements"
						name="maxAnnualReengagements"
						label={t("Max Annual Reengagements")}
						description={t(`The maximum amount of reengagements that can be sent to a contact. Minimum of ${INPUT_LIMITS.maxAnnualReengagementsMin}.`)}
						type="number"
						onChange={e => this.handleGenericEventHandler(e)}
						value={maxAnnualReengagements}
						invalid={this.isInputValid(maxAnnualReengagements, INPUT_LIMITS.maxAnnualReengagementsMin)}
					/>
					<Input
						id="delayHours"
						name="delayHours"
						label={t("Delay Hours")}
						description={t(`The amount of hours to delay a reengagement message for. Minimum of ${INPUT_LIMITS.delayHoursMin}.`)}
						type="number"
						onChange={e => this.handleGenericEventHandler(e)}
						value={delayHours}
						invalid={this.isInputValid(delayHours, INPUT_LIMITS.delayHoursMin)}
					/>

					<h3>{t("Appointment Filters")}</h3>

					<Input
						id="apptStates"
						name="apptStates"
						label={t("Appointment States")}
						description={t("Comma separated list of appointment states")}
						type="text"
						onChange={e => this.handleGenericEventHandler(e)}
						value={apptStates}
					/>

					<div>{t("Match Upcoming Appointments")}</div>
					<this.Switch field="matchUpcomingAppointments" checked={matchUpcomingAppointments} onChange={this.onChange}></this.Switch>

					<Input
						id="types"
						name="types"
						label={t("Appointment Types")}
						description={t("Comma separated list of appointment types")}
						type="text"
						onChange={e => this.handleGenericEventHandler(e)}
						value={types}
					/>

					<div>{t("Exact Match for Types")}</div>
					<this.Switch field="typesExactMatch" checked={typesExactMatch} onChange={this.onChange}></this.Switch>

					<Input
						id="assignedReps"
						name="assignedReps"
						label={t("Assigned Reps")}
						description={t("Comma separated list of Assigned Reps")}
						type="text"
						onChange={e => this.handleGenericEventHandler(e)}
						value={assignedReps}
					/>

					<div>{t("Exact Match for Assigned Reps")}</div>
					<this.Switch field="assignedRepsExactMatch" checked={assignedRepsExactMatch} onChange={this.onChange}></this.Switch>

					<div>{t("Status")}</div>
					<Select id={`reengagement-status-select`} options={statusOptions} value={status} placeholder={t("Select Status")} onChange={this.onStatusChange} />

					<div className="modal__actions">
						<div className={`mb-button mb-button--fit ${this.invalid() ? "mb-button--disabled" : ""}`} onClick={this.onSave}>
							{t("Save")}
						</div>
					</div>
				</div>
			</Modal>
		);
	};
}

export default withTranslation(null, { withRef: true })(ReengagementManageModal);
