import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import ToastService from "../../services/ToastService";
import UtilityService from "../../services/UtilityService";

import Spinners from "../../components/common/Spinners";
import Alert from "../../components/common/Alert";
import LoadingButton from "../../components/common/LoadingButton";
import PasswordInput from "../../components/common/PasswordInput";
import withLocation from "../../components/common/WithLocation";

import { LOGIN_METHOD } from "../../constants/Users";
import { KEYS } from "../../constants/Messenger";

import "../../styles/css/scenes/login.css";

class VerifyPin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dialogTitle: "",
			errorMsg: "",

			loading: true,
			verifyToken: this.props.match.params.token,
			pin: ""
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		document.body.classList.add("dh-login-bg");
		UtilityService.checkAndSetDarkBackground();

		this.checkToken();
	}

	checkToken = async () => {
		let { verifyToken } = this.state;
		await this.update({ loading: true });

		let isValidToken = await UserService.verifyTwoFactorToken({ verifyToken });

		if (!isValidToken) {
			this.props.history.push(`/login`);
			return;
		}

		await this.update({ loading: false });
	};

	componentWillUnmount() {
		document.body.classList.remove("dh-login-bg");
		document.body.classList.remove("Common-bg--dark");
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onInputKeyDown = event => {
		if (event.keyCode !== KEYS.enter) {
			return;
		}

		let { pin } = this.state;
		if (pin && pin.trim()) {
			this.onConfirmPin();
		}
	};

	onPinChange = ({ value }) => {
		this.update({ pin: value });
	};

	isPinValid = () => {
		let { pin } = this.state;
		return pin.length === 6;
	};

	onConfirmPin = async () => {
		let { loading, verifyToken, pin } = this.state;

		if (!verifyToken || !this.isPinValid()) {
			ToastService.info("Please enter a valid PIN.");
			return;
		}

		if (loading) {
			return;
		}

		await this.update({ loading: true });

		let isValidToken = await UserService.verifyTwoFactorToken({ verifyToken });

		if (!isValidToken) {
			this.props.history.push(`/login`);
			ToastService.info("Verification PIN Expired.");
			return;
		}

		let user = await UserService.confirmTwoFactorPin({
			verifyToken,
			pin
		});

		if (!user) {
			await this.update({
				loading: false,
				dialogTitle: "Invalid PIN",
				errorMsg: "An error occurred trying validate your verification PIN."
			});

			return;
		}

		// Store in local storage
		UserService.set(user);

		// Add to ga tracking
		GAService.GASetUserId(UserService.get().id);

		await this.update({ loading: false });

		let gotoUrl = `/login`;
		let redirectUrl = new URLSearchParams(this.props.location.search).get("redirect_url");

		if (redirectUrl) {
			gotoUrl = `/login?redirect_url=${redirectUrl}`;
		}

		// We go back to the login page and let the redirect be handled there
		this.props.history.push(gotoUrl);
	};

	render() {
		let { loading } = this.state;

		if (loading) {
			return (
				<div className="login Common__card">
					<div className="login__logo">
						<img className="login__logo__img" src="https://cdn.demandhub.co/img/logo/final-light-png.png" width="60%" alt="login-logo" />
					</div>
					<div className="login__header">Loading DemandHub...</div>
					<div className="login__loading">
						<div className="Common__spinnerdiv--center">
							<Spinners loading={true} type="circle" size="5px" />
						</div>
					</div>
				</div>
			);
		}

		let { errorMsg, dialogTitle } = this.state;

		return (
			<>
				<div className="login Common__card animated fadeIn">
					<div className="login__header">A verification pin has been sent to your preferred communication method.</div>

					<div className="login__form">
						<PasswordInput
							placeholder="Verification PIN"
							id="password"
							onChange={this.onPinChange}
							onKeyDown={this.onInputKeyDown}
							required=""
							autoComplete="current-password"
						/>
						<LoadingButton
							componentClass="mb-button mb-button--slim mb-button--center login__login-btn"
							loading={loading}
							onClick={this.onConfirmPin}
							type="submit"
							buttonId="loadingbutton"
						>
							Confirm PIN
						</LoadingButton>
					</div>
				</div>
				<div className="login__copyright">
					<div className="login__copyright__text">Copyright &copy; {moment().format("YYYY")} DemandHub Inc.</div>
				</div>
				<Alert type="error" show={errorMsg !== ""} title={dialogTitle} confirm="OK" onClose={() => this.update({ errorMsg: "" })}>
					<div>{errorMsg}</div>
				</Alert>
			</>
		);
	}
}

export default withRouter(withLocation(VerifyPin));
