import React, { Component } from "react";
import * as Icon from "react-feather";
import posed, { PoseGroup } from "react-pose";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";

import GROUP_PERMISSIONS from "../../constants/GroupPermissions";
import { MOBILE_WIDTH } from "../../constants/CommonConstants";

import LocationService from "../../services/LocationService";
import BookingRequestService from "../../services/BookingRequestService";
import AppointmentsService from "../../services/AppointmentsService";
import UserService from "../../services/UserService";
import WorkflowService from "../../services/WorkflowService";
import NotificationService from "../../services/NotificationService";
import DashboardService from "../../services/DashboardService";
import CalendarService from "../../services/CalendarService";
import UtilityService from "../../services/UtilityService";

import "../../styles/css/components/commons/nav.css";

import config from "../../config/app/web-app.config";

const NavContainer = posed.div({
	visible: {
		minWidth: 240,
		width: 240,
		opacity: 1
	},
	collapsed: {
		minWidth: 60,
		width: 60,
		opacity: 1
	},
	hidden: {
		opacity: 0
	}
});

const SubMenu = posed.div({
	enter: {
		height: "auto",
		opacity: 1,
		transition: {
			duration: 200
		}
	},
	exit: {
		height: "0px",
		opacity: 0,
		transition: {
			duration: 200
		}
	}
});

class Nav extends Component {
	constructor(props) {
		super(props);
		let isSmallDevice = document.body.clientWidth < MOBILE_WIDTH;

		this.state = {
			isSmallDevice,
			isHidden: isSmallDevice,
			showRedDot: false,
			collapsed: isSmallDevice,
			collapsedHover: false,
			hover: null,
			contactCount: 0,
			teamChatCount: 0,
			bookingRequestCount: 0,
			selected: this.props.history.location.pathname,
			items: this.generateItems()
		};

		this.positions = {};
		this.urlChangeListener = null;

		this.collapsedSubMenu = React.createRef();
		this.navMenuContainer = React.createRef();
	}

	async componentDidMount() {
		NotificationService.subscribe("contactCountUpdate", contactCount => {
			// The all count is handled by platfrom, depending on permissions of the user
			let count = contactCount.all;

			this.setState({
				contactCount: count
			});
		});

		NotificationService.subscribe("teamChatCountUpdate", event => {
			this.setState({
				teamChatCount: event.count
			});
		});

		NotificationService.subscribe("toggleNavHide", () => {
			let { isHidden, isSmallDevice } = this.state;

			if (!isSmallDevice) {
				return;
			}

			this.setState({
				isHidden: !isHidden
			});
		});

		NotificationService.subscribe("collapseNav", () => {
			this.collapseNav();
		});

		NotificationService.subscribe("locationChanged", () => {
			this.setState({
				items: this.generateItems()
			});
			BookingRequestService.clearBookingRequestInterval();
			BookingRequestService.setBookingRequestInterval();
		});

		BookingRequestService.clearBookingRequestInterval();
		BookingRequestService.setBookingRequestInterval();
		NotificationService.subscribeOnce("bookingRequestCount", "bookingRequestCountNav", count => {
			this.setState({ bookingRequestCount: count });
		});

		window.addEventListener("resize", this.onResize);
		document.addEventListener("mousedown", this.onMouseDown, false);

		if (this.props.history) {
			this.urlChangeListener = this.props.history.listen((location, action) => {
				this.update({
					selected: location.pathname
				});

				NotificationService.publish("onUrlChange", { location, action });
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
		document.removeEventListener("mousedown", this.onMouseDown, false);
		BookingRequestService.clearBookingRequestInterval();

		if (this.urlChangeListener) {
			this.urlChangeListener();
			this.urlChangeListener = null;
		}
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onResize = event => {
		let isSmallDevice = document.body.clientWidth < MOBILE_WIDTH;
		this.setState({
			isSmallDevice,
			isHidden: isSmallDevice
		});
	};

	onMouseDown = event => {
		let { collapsed } = this.state;

		// If the menu is collapsed, and the submenu is not clicked, and the nav ment is not cliked, then we will stop showing the submenu
		if (
			collapsed &&
			!(this.collapsedSubMenu && this.collapsedSubMenu.contains && this.collapsedSubMenu.contains(event.target)) &&
			!(this.navMenuContainer && this.navMenuContainer.contains && this.navMenuContainer.contains(event.target))
		) {
			this.setState({ hover: null });
		}
	};

	toggleHover(clickedNavItem, sub) {
		let { hover } = this.state;

		// If there is a sub menu, we still want it to that the nav item is being hovered
		if (sub) {
			return;
		}

		// Determine if the current nav item is the hover element, and if it is toggle it to null
		hover = hover === clickedNavItem ? null : clickedNavItem;

		this.setState({
			hover
		});
	}

	onNavClicked = item => {
		if (item.subMenu) {
			return;
		}

		this.setState({
			selected: item.id
		});

		this.props.history.push(item.id);
	};

	generateItems() {
		let { t } = this.props;

		let user = UserService.get();

		return [
			{
				id: "/dashboard",
				label: t("Dashboard"),
				icon: Icon.Grid,
				enabled: LocationService.isDashboardEnabled()
			},
			{
				id: "/messenger",
				label: t("Messenger"),
				icon: Icon.MessageSquare,
				showRedDot: true,
				enabled: LocationService.isMessengerPermissible()
			},
			{
				id: "/calendar",
				label: t("Calendar"),
				icon: Icon.Calendar,
				showRedDot: false,
				enabled: LocationService.isMessengerPermissible(),
				new: true
			},
			{
				id: "/voicemail",
				label: t("Voicemail"),
				icon: Icon.Voicemail,
				enabled: LocationService.isMessengerPermissible()
			},
			{
				id: "/scheduled-messages",
				label: t("Scheduled Messages"),
				icon: Icon.Clock,
				enabled: LocationService.isScheduledMessagesEnabled()
			},
			{
				id: "/contacts",
				icon: Icon.Users,
				label: t("Contacts"),
				enabled: UserService.get().GroupPermission.view_contacts
			},
			{
				id: "/reviews",
				label: t("Reviews"),
				icon: Icon.Star,
				enabled: LocationService.isReviewsEnabled()
			},
			{
				id: "/invites/review-invites",
				label: t("Invites"),
				icon: Icon.Mail,
				enabled: LocationService.isViewReviewInvitesEnabled()
			},
			{
				id: "/nps",
				label: t("NPS"),
				icon: Icon.Activity
			},
			{
				id: "/appointments",
				label: t("Appointments"),
				icon: Icon.Calendar,
				enabled: AppointmentsService.isPermissible()
			},
			{
				id: "/booking-requests",
				label: t("Booking Requests"),
				icon: Icon.Book,
				showRedDot: true
			},
			{
				id: "/payments",
				label: t("Payments"),
				icon: Icon.DollarSign
			},
			{
				id: "/tasks",
				label: t("Tasks"),
				icon: Icon.Box,
				enabled: LocationService.isLocationTasksPermissible()
			},
			{
				id: "/leaderboard",
				label: t("Leaderboard"),
				icon: Icon.Award,
				enabled: LocationService.isLeaderboardEnabled()
			},
			{
				id: "/analytics",
				label: t("Analytics"),
				icon: Icon.BarChart,
				enabled: UserService.get().GroupPermission.view_analytic_reports,
				subMenu: [
					{
						id: "/analytics/dashboards",
						label: t("Dashboards"),
						enabled: DashboardService.canViewDashboard()
					},
					{
						id: "/analytics/summary",
						label: t("Summary"),
						enabled: LocationService.isReviewsEnabled()
					},
					{
						id: "/analytics/location",
						label: t("Locations"),
						enabled: LocationService.isReviewsEnabled()
					},
					{
						id: "/analytics/review-sites",
						label: t("Review Sites"),
						enabled: LocationService.isReviewsEnabled()
					},
					{
						id: "/analytics/review-insight",
						label: t("Review Insights"),
						enabled: LocationService.isReviewsEnabled()
					},
					{
						id: "/analytics/gmbstats",
						label: t("GMB Stats"),
						enabled: LocationService.isReviewsEnabled()
					},
					{
						id: "/analytics/messenger",
						label: t("Messenger"),
						enabled: LocationService.isMessengerPermissible()
					},
					{
						id: "/analytics/scheduled-messages",
						label: t("Scheduled Messages"),
						enabled: LocationService.isScheduledMessagesEnabled()
					},
					{
						id: "/analytics/contacts",
						label: t("Contacts"),
						enabled: UserService.get().GroupPermission.view_contacts
					},
					{
						id: "/analytics/keywords",
						label: t("Keywords"),
						enabled: WorkflowService.isKeywordsEnabled()
					},
					{
						id: "/analytics/widgets",
						label: t("Widgets"),
						enabled: LocationService.isMessengerPermissible()
					},
					{
						id: "/analytics/conversions",
						label: t("Conversions"),
						enabled: UserService.isSuperAdminOrCustomerSuccessOrAccountOwner()
					}
				]
			},
			{
				id: "manage",
				label: t("Manage"),
				icon: Icon.Settings,
				subMenu: [
					{
						id: "/users",
						label: t("Users"),
						enabled: UserService.get().GroupPermission.view_team
					},
					{
						id: "/locations",
						label: t("Locations"),
						enabled: UserService.isSuperAdminOrCustomerSuccessOrReseller()
					},
					{
						id: "/companies",
						label: t("Companies"),
						enabled: UserService.get().GroupPermission.view_companies
					},
					{
						id: "/manage-team-chat",
						label: t("Team Chat"),
						enabled: UserService.isSuperAdminOrCustomerSuccess()
					},
					{
						id: "/settings",
						label: t("Settings"),
						enabled: UserService.get().GroupPermission.view_settings
					}
				]
			},
			{
				id: "cs",
				label: t("Customer Success"),
				icon: Icon.UserCheck,
				enabled: user.company_id === config.DEMANDHUB.COMPANY.ID,
				subMenu: [
					{
						id: "/customer-success/sign-ups",
						label: t("Sign Ups"),
						enabled: UserService.isSuperAdminOrCustomerSuccess()
					},
					{
						id: "/customer-success/realtime",
						label: t("Realtime"),
						enabled: UserService.get().GroupPermission.view_cs_reports
					},
					{
						id: "/customer-success/lagging-locations",
						label: t("Lagging Locations"),
						enabled: UserService.get().GroupPermission.view_cs_reports
					},
					{
						id: "/customer-success/feature-matrix",
						label: t("Feature Matrix"),
						enabled: UserService.get().GroupPermission.view_cs_reports
					},
					{
						id: "/customer-success/teamchat",
						label: t("Team Chat"),
						enabled: UserService.get().GroupPermission.view_cs_reports
					},
					{
						id: "/customer-success/messenger",
						label: t("Messenger"),
						enabled: UserService.get().GroupPermission.view_cs_reports
					},
					{
						id: "/customer-success/reviews",
						label: t("Reviews"),
						enabled: UserService.get().GroupPermission.view_cs_reports
					},
					{
						id: "/customer-success/messenger-mediums",
						label: t("Messenger Mediums"),
						enabled: UserService.get().GroupPermission.view_cs_reports
					},
					{
						id: "/customer-success/company-email-reports",
						label: t("Company Email Reports"),
						enabled: UserService.get().GroupPermission.view_cs_reports
					},
					{
						id: "/customer-success/location-email-reports",
						label: t("Locations Email Reports"),
						enabled: UserService.get().GroupPermission.view_cs_reports
					},
					{
						id: "/customer-success/hosted-messaging",
						label: t("Hosted Messaging"),
						enabled: UserService.get().GroupPermission.view_cs_reports
					},
					{
						id: "/admin/jobs",
						label: t("Cron Jobs"),
						enabled: UserService.get().GroupPermission.view_cs_reports && UserService.get().GroupPermission.type === GROUP_PERMISSIONS.super_administrator.type
					},
					{
						id: "/reputation-report",
						label: t("Reputation Report"),
						enabled: UserService.get().GroupPermission.view_cs_reports && UserService.get().GroupPermission.view_companies
					},
					{
						id: "/customer-success/map",
						label: t("Map"),
						enabled: UserService.get().GroupPermission.view_locations_map
					}
				]
			}
		];
	}

	renderRedDot(item, collapsed = false) {
		if (!item.showRedDot) {
			return null;
		}

		let className = "dh-nav__item__container__red-dot";

		if (collapsed) {
			className += " dh-nav__item__container__red-dot--collapsed";
		}

		if (item.id !== "/messenger" && item.id !== "/booking-requests") {
			return null;
		}

		if (item.id === "/messenger") {
			let { contactCount, teamChatCount } = this.state;
			let unread = contactCount + teamChatCount > 0;
			if (unread) {
				return <div className={className} />;
			}
		}

		if (item.id === "/booking-requests") {
			let { bookingRequestCount } = this.state;
			if (bookingRequestCount) {
				return <div className={className} />;
			}
		}

		return null;
	}

	renderNavItem(item, sub = false) {
		let { t } = this.props;
		let { hover, selected } = this.state;

		let isHover = hover === item.id;

		if (typeof item.enabled !== "undefined" && !item.enabled) {
			return null;
		}

		let isSelected = selected.indexOf(item.id) > -1;
		return (
			<div key={item.id} className="dh-nav__item" onClick={() => this.toggleHover(item.id, sub)}>
				<div className={`dh-nav__item__container ${isSelected ? "dh-nav__item__container--active" : ""}`} onClick={() => this.onNavClicked(item)}>
					{item.icon && (
						<div className="dh-nav__item__container__icon">
							<item.icon size="16" />
						</div>
					)}
					<div className="dh-nav__item__container__title">{item.label}</div>
					{item.new && <div className="dh-nav__item__container__new">{t("NEW")}</div>}
					{this.renderRedDot(item)}
					{item.subMenu && <div className="dh-nav__item__container__chevron">{isHover ? <Icon.ChevronDown size="12" /> : <Icon.ChevronLeft size="12" />}</div>}
				</div>
				{this.renderSubMenu(item)}
			</div>
		);
	}

	renderSubMenu(item) {
		let { hover } = this.state;
		return (
			<PoseGroup flipMove={false}>
				{hover === item.id && item.subMenu && item.subMenu.length > 0 && (
					<SubMenu key={item.id} className="dh-nav__item__sub">
						{item.subMenu.map(subItem => this.renderNavItem(subItem, true))}
					</SubMenu>
				)}
			</PoseGroup>
		);
	}

	renderCollapsedNavItem(item, sub = false) {
		let { selected, hover } = this.state;

		if (typeof item.enabled !== "undefined" && !item.enabled) {
			return null;
		}

		let isSelected = selected.indexOf(item.id) > -1;
		let isHover = hover === item.id;

		// XXX - Gross since this logic should live at the config level, we should move this at some point
		// let unread = contactCount + teamChatCount > 0;

		let containerStyles = ["dh-nav__item__container"];

		if (isSelected) {
			containerStyles.push("dh-nav__item__container--active");
		}

		if (item.new) {
			containerStyles.push("dh-nav__item__container--new");
		}

		return (
			<div
				key={item.id}
				ref={ref => (this.positions[item.id] = ref)}
				className="dh-nav__item dh-nav__item--relative"
				onClick={() => this.toggleHover(item.id, sub)}
			>
				<div data-tip data-for={`rtt-${item.id}`} className={containerStyles.join(" ")} onClick={() => this.onNavClicked(item)}>
					{item.icon && (
						<div className={`dh-nav__item__container__icon`}>
							<item.icon size="16" />
						</div>
					)}
					{this.renderRedDot(item, true)}
				</div>
				{!isHover && (
					<ReactTooltip id={`rtt-${item.id}`} className="mb-react-tooltip" type="info" effect="solid" place="right" offset={{ right: 10 }} arrowColor="#333">
						{item.label}
					</ReactTooltip>
				)}
				{this.renderCollapsedSubMenu(item)}
			</div>
		);
	}

	renderCollapsedSubMenu(item) {
		let { hover } = this.state;

		let styles = {};
		if (hover === item.id && item.subMenu && this.positions[item.id]) {
			let subMenuHeight = 50 * item.subMenu.length;
			let { top, bottom } = this.positions[item.id].getBoundingClientRect();
			if (top + subMenuHeight > window.innerHeight) {
				styles.bottom = window.innerHeight - bottom;
			}
		}

		return (
			<PoseGroup flipMove={false}>
				{hover === item.id && item.subMenu && item.subMenu.length > 0 && (
					<SubMenu ref={ref => (this.collapsedSubMenu = ref)} key={item.id} className="dh-nav__item__sub__collapsed" style={styles}>
						{item.subMenu.map(subItem => this.renderNavItem(subItem, true))}
					</SubMenu>
				)}
			</PoseGroup>
		);
	}

	collapseNav = () => {
		UtilityService.setNavigationCollapsed(true);
		this.setState({
			collapsed: true
		});

		NotificationService.publish("onNavCollapsed", true);
	};

	toggleCollapse = () => {
		let { collapsed, isSmallDevice } = this.state;

		if (isSmallDevice) {
			return;
		}

		let newCollapsedValue = !collapsed;

		UtilityService.setNavigationCollapsed(newCollapsedValue);

		this.setState({
			collapsed: newCollapsedValue
		});

		NotificationService.publish("onNavCollapsed", newCollapsedValue);
	};

	onCollapseHover(value) {
		let { isSmallDevice } = this.state;

		if (isSmallDevice) {
			return;
		}

		this.setState({
			collapsedHover: value
		});
	}

	render() {
		let { collapsed, collapsedHover, isHidden, items } = this.state;

		let styles = ["dh-nav"];

		if (isHidden) {
			styles.push("dh-nav--hide");
		}

		if (!UtilityService.isSafari()) {
			styles.push("dh-nav--dynamic-height");
		}

		return (
			<NavContainer
				className={styles.join(" ")}
				initialPose="hidden"
				pose={collapsed ? "collapsed" : "visible"}
				style={{ overflow: UtilityService.isSafari() ? "visible" : "auto" }} // Gunda Safari Hack
				ref={ref => (this.navMenuContainer = ref)}
			>
				<div
					className="dh-nav__collapse"
					onClick={this.toggleCollapse}
					onMouseEnter={() => this.onCollapseHover(true)}
					onMouseLeave={() => this.onCollapseHover(false)}
				>
					{collapsed &&
						(!collapsedHover ? (
							<img alt="DemandHub" className="dh-nav__collapsed-logo" src="https://cdn.demandhub.co/img/logo/final-dark-svg.svg" />
						) : (
							<Icon.Menu size="20" />
						))}
					{!collapsed && <img alt="DemandHub" className="dh-nav__logo" src="https://cdn.demandhub.co/img/logo/logo-dark.svg" />}
				</div>

				{collapsed && items.map(item => this.renderCollapsedNavItem(item))}
				{!collapsed && items.map(item => this.renderNavItem(item))}
			</NavContainer>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(Nav));
