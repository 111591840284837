import React, { Component } from "react";
import * as Icon from "react-feather";
import posed from "react-pose";

import Templates from "./Templates";

import "../../styles/css/components/commons/mini-template-selector.css";

const Box = posed.div({
	visible: {
		y: 0,
		x: 0,
		opacity: 1
	},
	hidden: {
		y: 20,
		opacity: 0
	}
});

class MiniTemplateSelector extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showList: false
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount = () => {
		document.addEventListener("mousedown", this.onMouseDown, false);
	};

	componentWillUnmount = () => {
		document.removeEventListener("mousedown", this.onMouseDown, false);
	};

	onMouseDown = async e => {
		let { showList } = this.state;
		let { disabled } = this.props;

		if (disabled) {
			return;
		}

		if (this.contextTrigger && this.contextTrigger.contains && this.contextTrigger.contains(e.target)) {
			return;
		}

		if (this.contextContainer && this.contextContainer.contains && this.contextContainer.contains(e.target) && !showList) {
			await this.update({
				showList: true
			});
		} else if (this.contextTrigger && this.contextTrigger.contains) {
			await this.update({
				showList: false
			});
		}
	};

	onSelect = async template => {
		await this.update({ showList: false });

		if (this.props.onSelect) {
			await this.props.onSelect(template);
		}
	};

	onClose = async () => {
		await this.update({ showList: false });
	};

	renderTemplates = () => {
		let { showList } = this.state;
		let { showEditAction, templateType } = this.props;

		return (
			<Box
				className={`dh-mini-templates ${showList ? "" : "dh-mini-templates--hidden"}`}
				ref={ref => (this.contextTrigger = ref)}
				pose={showList ? "visible" : "hidden"}
			>
				<Templates
					onClose={this.onClose}
					onSelect={this.onSelect}
					templateType={templateType}
					showEditAction={showEditAction}
					typeEditable={false}
					replaceMessageVariables={false}
					showRecentlyUsed={false}
					showFavourites={false}
				/>
			</Box>
		);
	};

	render() {
		return (
			<div ref={ref => (this.contextContainer = ref)} className="dh-mts">
				<div className="dh-mts__icon">
					<Icon.Layers size="16" />
				</div>
				{this.renderTemplates()}
			</div>
		);
	}
}

export default MiniTemplateSelector;
