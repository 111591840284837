import Kichiri from "./KichiriService";
import UserService from "./UserService";

export const GmbService = {
	/**
	 * Check the status of GMB and Fb
	 * @param {Integer} locationId
	 * @return {Object} The status of GMB and Fb
	 */
	async checkGmbAndFbStatus(locationId) {
		try {
			const authToken = UserService.get() ? UserService.get().auth_token : "";
			if (!locationId) {
				locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
			}
			const gmbAndFbStatus = await Kichiri.review_sites.checkGmbAndFbStatus({ locationId }, {}, authToken);
			return gmbAndFbStatus.data;
		} catch (error) {
			throw error;
		}
	}
};

export default GmbService;
