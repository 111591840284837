import React, { PureComponent } from "react";

import TeamChatService from "../../../../services/TeamChatService";
import TeamChatMessageResult from "./TeamChatMessageResult";

import "./team-chat-message-list.css";

class TeamChatMessageResultList extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			searchTerm: "",
			conversationId: null,
			messages: []
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { searchTerm } = this.props;

		if (prevProps.searchTerm !== searchTerm) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { searchTerm, conversationId } = this.props;
		await this.update({ loading: true, searchTerm, conversationId });
		await this.fetchResults();
		await this.update({ loading: false });
	}

	async fetchResults() {
		let { conversationId, searchTerm } = this.state;

		let messages = await TeamChatService.searchConversation(conversationId, searchTerm);

		await this.update({ messages });
	}

	onSelect = async message => {
		await this.update({
			searchTerm: "",
			messages: []
		});

		if (this.props.onSelect) {
			this.props.onSelect(message);
		}
	};

	renderMessageItem(message, index) {
		let { messages, searchTerm } = this.state;
		let previousMessage = messages[index - 1];

		return (
			<TeamChatMessageResult
				key={message.id}
				message={message}
				previousMessage={previousMessage}
				onSelect={() => this.onSelect(message)}
				searchTerm={searchTerm}
			/>
		);
	}

	renderMessageList() {
		let { messages } = this.state;

		return messages.map((message, index) => {
			return this.renderMessageItem(message, index);
		});
	}

	render() {
		let { loading } = this.state;

		return <div className="mb-tcml">{!loading && this.renderMessageList()}</div>;
	}
}

export default TeamChatMessageResultList;
