import UserService from "./UserService";

import moment from "moment";

import Kichiri from "./KichiriService";

import { TEMPLATE_TYPE, MAX_RECENT_TEMPLATES_TO_STORE } from "../constants/TemplateConstants";

let replacements = {};
let replacementsLastUpdated = null;

const TemplateService = {
	/**
	 * Converts a template type into something more user friendly
	 * @param {String} type A template type
	 * @returns {String}
	 */
	getTypeNameFromType(type) {
		return type.replace(/_/g, " ").replace(/(^| )(\w)/g, x => x.toUpperCase());
	},

	/**
	 * Get the message variables that are apropriate for a specific message template type
	 * @param {String} type A template type
	 * @returns {Array} An array of message replacements
	 */
	async getMessageVariablesForType(templateType = TEMPLATE_TYPE.general) {
		try {
			const response = await Kichiri.message_template.getMessageVariables({}, { templateType }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return [];
	},

	/**
	 * Get all message variables needed for front end
	 * @returns {Object} Message variable replacements
	 */
	async getMessageVariables() {
		try {
			if (!replacementsLastUpdated || moment().isAfter(moment(replacementsLastUpdated).add(30, "minutes"), "")) {
				// After 30 min, fetch the latest message variables
				const response = await Kichiri.message_template.getMessageVariables({}, {}, UserService.getAuthToken());
				replacements = response.data;
				replacementsLastUpdated = moment();
			}

			return replacements;
		} catch (error) {
			console.log(error);
		}
		return replacements;
	},

	/**
	 * A generic message replacement method. Automatically gets location, company, and user data replacement variables
	 * Currently only used to replace message variables in messenger as the user is typing
	 * @param {String} msgTemplate
	 * @param {String} contactFull
	 * @param {String} reviewUrl
	 * @param {String} locationSmsNumber
	 * @returns {String}
	 */
	async getMessage(msgTemplate, contactFull, reviewUrl = null, locationSmsNumber) {
		try {
			let user = UserService.get();
			let company = UserService.getActiveCompany();
			let location = UserService.getActiveLocation();

			let locationFullAddress = `${location.address_1} ${location.address_2}, ${location.city}, ${location.state_prov}, ${location.country} ${location.zip_postal}`;

			let fullName = UserService.getCurrentUserFullName();

			let replacements = {
				contactFull: contactFull,
				companyName: company.name,
				locationName: location.name,
				locationPhone: location.phone,
				locationSmsNumber: locationSmsNumber,
				locationAddress: locationFullAddress,
				locationWebsite: location.website,
				userName: fullName,
				userFirstName: user.first_name
			};
			if (reviewUrl) {
				replacements.reviewUrl = reviewUrl;
			}

			return await this.generateMessage(msgTemplate, replacements);
		} catch (error) {
			console.log(`Template get message error: ${error}`);
			return null;
		}
	},

	/**
	 * Replace a message with the specified replacements
	 * @param {String} msgTemplate
	 * @param {Object} messageVariables Object containing all the desired replacements
	 * @returns {String}
	 */
	async generateMessage(msgTemplate, messageVariables) {
		let msg = msgTemplate;
		let customerName = messageVariables.contactFull ? messageVariables.contactFull : "";
		let firstName = "";
		let lastName = "";

		let messageReplacements = await this.getMessageVariables();

		// The name is optional, so if it is empty we should first remove the space before it in the template
		if (customerName.length < 1) {
			msg = msg.replace(new RegExp(messageReplacements.spaceContactFull.allPatterns, "gi"), ":contact_full:"); // Remove any space before the name
			msg = msg.replace(new RegExp(messageReplacements.spaceContactFirstName.allPatterns, "gi"), ":contact:"); // Remove any space before the name
			msg = msg.replace(new RegExp(messageReplacements.spaceContactLastName.allPatterns, "gi"), "%LAST_NAME%"); // Remove any space before the name
		} else if (
			customerName.indexOf("Dr.") >= 0 ||
			customerName.indexOf("Mr.") >= 0 ||
			customerName.indexOf("Mrs.") >= 0 ||
			customerName.indexOf("Ms.") >= 0 ||
			customerName.indexOf("Dr ") >= 0 ||
			customerName.indexOf("Mr ") >= 0 ||
			customerName.indexOf("Mrs ") >= 0 ||
			customerName.indexOf("Ms ") >= 0
		) {
			// If the full name includes any title such as Dr, Mr, Mrs, Ms etc., set the first name to be the full name, and clear the last name (to avoid any repetitions if someone enters %FIRST_NAME% %LAST_NAME%)
			firstName = customerName;
			lastName = "";
		} else {
			// See if we can split the name into first/last
			firstName = customerName
				.split(" ")
				.slice(0, 1)
				.join(" ");
			lastName = customerName
				.split(" ")
				.slice(-1)
				.join(" ");
			// If the first name and last name are the same, set the last name to be blank
			if (firstName === lastName) {
				lastName = "";
			}
		}

		messageVariables.contactFull = customerName;
		messageVariables.contactFirstName = firstName;
		messageVariables.contactLastName = lastName;

		let skip = ["spaceContactFull", "spaceContactFirstName", "spaceContactLastName"];
		for (const property in messageVariables) {
			if (!messageVariables.hasOwnProperty(property)) {
				continue;
			}
			if (skip.includes(property)) {
				continue;
			}
			let value = messageVariables[property];
			const regex = new RegExp(messageReplacements[property].allPatterns, "gi");
			if (!regex) {
				// the property wasn't found in our replacements constants file or the value is undefined
				continue;
			}

			if (!value) {
				value = "";
			}
			msg = msg.replace(regex, value);
		}
		return msg;
	},

	/**
	 * Get a template
	 * @param {Integer} templateId - The id of the Message Template
	 * @param {Boolean} replaceMessageVariables
	 * @param {Number} contactId
	 * @param {Number} locationId
	 * @param {Object} overwriteReplacements
	 *
	 * @returns {Object} Message Template
	 */
	async getTemplate({ templateId, replaceMessageVariables = false, contactId, locationId, overwriteReplacements }) {
		const authToken = UserService.get() ? UserService.get().auth_token : "";
		try {
			const messageTemplateResponse = await Kichiri.message_template.fetch(
				{
					templateId
				},
				{
					replaceMessageVariables,
					contactId,
					locationId,
					overwriteReplacements
				},
				authToken
			);
			return messageTemplateResponse.data;
		} catch (e) {
			console.log(e);
		}
		return null;
	},

	/**
	 * Get all review request for input types
	 * @param {array} types
	 * @returns {array} reviewRequestTemplates
	 */
	async getTemplatesForType(types) {
		const authToken = UserService.get() ? UserService.get().auth_token : "";
		const locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
		try {
			const messageTemplateResponse = await Kichiri.message_template.fetchAll(
				{
					locationId
				},
				{ types },
				authToken
			);
			return messageTemplateResponse.data;
		} catch (e) {
			throw e;
		}
	},

	/**
	 * Fetches all the message templates for a location
	 *
	 * @param {Number} locationId
	 * @param {Array} [types=["general"]]
	 * @param {String} searchTerm
	 * @param {Boolean} showAutoResponseTemplatesOnly
	 * @param {Number} autoResponseMinStar
	 * @param {Number} autoResponseMaxStar
	 * @param {String} sortField
	 * @param {String} sortOrder
	 * @param {Number} limit
	 * @param {Boolean} replaceMessageVariables if you would like the message variables to be replaced
	 * @param {Number} contactId used for message vars
	 * @param {Number} reviewRequestId used for message vars
	 * @param {Object} overwriteReplacements Object with replacements, used to overwrite any replacements if necessary
	 *
	 * @return {Promise}
	 */
	async fetchTemplates({
		locationId,
		types = ["general"],
		searchTerm,
		showAutoResponseTemplatesOnly,
		autoResponseMinStar,
		autoResponseMaxStar,
		sortField,
		sortOrder,
		limit,
		replaceMessageVariables,
		contactId,
		reviewRequestId,
		overwriteReplacements,
		includeMedia = false
	}) {
		try {
			let response = await Kichiri.message_template.fetchAll(
				{
					locationId: locationId
				},
				{
					types,
					searchTerm,
					showAutoResponseTemplatesOnly,
					autoResponseMinStar,
					autoResponseMaxStar,
					sortField,
					sortOrder,
					limit,
					replaceMessageVariables,
					contactId,
					reviewRequestId,
					overwriteReplacements: overwriteReplacements ? JSON.stringify(overwriteReplacements) : null,
					includeMedia
				},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(`An error occured trying to fetch message templates for ${locationId}`);
		}

		return null;
	},

	/**
	 * Create a template
	 * @param {Number} locationId
	 * @param {Number} templateId
	 * @param {String} name
	 * @param {String} type
	 * @param {String} subject
	 * @param {String} text
	 * @param {String} html
	 * @param {Boolean} hasBackup
	 * @param {String} backupText
	 * @param {String} backupHtml
	 * @param {String} status
	 * @param {Boolean} defaultType
	 * @param {String} language
	 * @param {Array} mediaIds
	 *
	 * @returns template
	 */
	async create({ locationId, templateId, name, type, subject, text, html, hasBackup, backupText, backupHtml, status, defaultType, language, mediaIds }) {
		try {
			let response = await Kichiri.message_template.create(
				{ locationId, templateId, name, type, subject, text, html, hasBackup, backupText, backupHtml, status, defaultType, language, mediaIds },
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Update a template
	 * @param {Number} templateId
	 * @param {String} name
	 * @param {String} type
	 * @param {String} subject
	 * @param {String} text
	 * @param {String} html
	 * @param {Boolean} hasBackup
	 * @param {String} backupText
	 * @param {String} backupHtml
	 * @param {String} status
	 * @param {Boolean} defaultType
	 * @param {String} language
	 * @param {Array} mediaIds
	 * @param {Boolean} isFavourite
	 *
	 * @returns template
	 */
	async update({ templateId, name, type, subject, text, html, hasBackup, backupText, backupHtml, status, defaultType, language, mediaIds, isFavourite }) {
		try {
			let response = await Kichiri.message_template.update(
				{ templateId, name, type, subject, text, html, hasBackup, backupText, backupHtml, status, defaultType, language, mediaIds, isFavourite },
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Get message template types
	 * @returns Array
	 */
	async getTypes() {
		try {
			let response = await Kichiri.message_template.getTypes({}, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return [];
	},

	/**
	 * Get recently used message templates from local storage
	 * @returns Array
	 */
	getRecentlyUsedTemplates() {
		try {
			let localStorageRecentTemplates = localStorage.getItem("dh_recently_used_templates");

			if (typeof localStorageRecentTemplates === "undefined" || localStorageRecentTemplates === null) {
				localStorageRecentTemplates = [];
				localStorage.setItem("dh_recently_used_templates", JSON.stringify(localStorageRecentTemplates));
			} else {
				return JSON.parse(localStorageRecentTemplates);
			}

			return localStorageRecentTemplates;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Registers a recently used message templates in local storage
	 * @param templateId
	 * @returns Array
	 */
	registerRecentlyUsedTemplate(templateId) {
		try {
			let recentlyUsedTemplates = this.getRecentlyUsedTemplates();

			// If we are not allowed to store any recently used templates
			if (MAX_RECENT_TEMPLATES_TO_STORE === 0) {
				localStorage.setItem("dh_recently_used_templates", JSON.stringify([]));
				return;
			}
			// Remove excess number of recently stored templates
			else if (recentlyUsedTemplates.length >= MAX_RECENT_TEMPLATES_TO_STORE) {
				// Remove the exceeded elements over the limit
				let numberOfElementsToRemove = recentlyUsedTemplates.length - MAX_RECENT_TEMPLATES_TO_STORE + 1;
				recentlyUsedTemplates.splice(0, numberOfElementsToRemove);
			}

			// Add the new template
			recentlyUsedTemplates.push(templateId);

			// Store the new list in local storage
			localStorage.setItem("dh_recently_used_templates", JSON.stringify(recentlyUsedTemplates));
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Clears recently used message templates in local storage
	 * @returns
	 */
	clearRecentlyUsedTemplates() {
		try {
			localStorage.removeItem("dh_recently_used_templates");
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Gets the default template from a list of templates
	 * @param templates an array of templates
	 * @param language a string to find a default for a type
	 * @returns Object the template that is the default, null otherwise
	 */
	async extractDefaultTemplate(templates, language) {
		if (!templates || templates.length < 1) {
			return null;
		}

		// If we are searching based on language
		if (language) {
			// Try to find a template that matched language and has default_for_type enabled
			for (let i = 0; i < templates.length; i++) {
				let currentTemplate = templates[i];

				if (language === currentTemplate.language && currentTemplate.default_for_type) {
					return currentTemplate;
				}
			}

			// Get the first template that matched the passed in language
			for (let i = 0; i < templates.length; i++) {
				let currentTemplate = templates[i];

				if (language === currentTemplate.language) {
					return currentTemplate;
				}
			}
		}

		// Try to find the first template that has default_for_type enabled
		for (let i = 0; i < templates.length; i++) {
			let currentTemplate = templates[i];

			if (currentTemplate.default_for_type) {
				return currentTemplate;
			}
		}

		// If for some reason we have no default for review invites,
		// And if there are review templates, just choose the first one
		return templates[0];
	},

	/**
	 * Delete a Message Template
	 * @param {Integer} templateId
	 * @returns {Boolean}
	 */
	async deleteTemplateById(templateId) {
		try {
			await Kichiri.message_template.deleteTemplateById({ templateId }, {}, UserService.getAuthToken());
			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	}
};

export default TemplateService;
