import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";

import Kichiri from "../../services/KichiriService";
import UserService from "../../services/UserService";
import UtilityService from "../../services/UtilityService";

import LoadingButton from "../../components/common/LoadingButton";
import Alert from "../../components/common/Alert";
import PasswordInput from "../../components/common/PasswordInput";
import PasswordRequirements from "../../components/common/PasswordRequirements";

import config from "../../config/app/web-app.config";

import "../../styles/css/scenes/reset-password.css";

const SHOW_PASSWORD_ERROR_ON_LENGTH = 4;

class ResetPasswordPage extends Component {
	state = {
		password: "",
		confirmPassword: "",
		savingPassword: false,
		isValidPassword: false,
		saveSuccess: false,
		saveError: false,
		passwordMismatchInline: false,
		token: "",
		recaptchaValue: ""
	};

	componentDidMount() {
		document.body.classList.add("gray-bg");
		UtilityService.checkAndSetDarkBackground();
		if (this.props.location.state && this.props.location.state.token) {
			this.setState({ token: this.props.location.state.token });
		} else {
			this.props.history.push("/login");
		}
	}

	componentWillUnmount() {
		document.body.classList.remove("gray-bg");
		document.body.classList.remove("Common-bg--dark");
	}

	handlePasswordChange = ({ value, passed }) => {
		const { confirmPassword } = this.state;
		let password = value;
		this.setState({
			passwordMismatchInline: confirmPassword !== password,
			isValidPassword: UtilityService.isValidPassword({ password }),
			password
		});
	};

	onCaptchaChange(value) {
		this.setState({ recaptchaValue: value });
	}

	handleConfirmPasswordChange = ({ value, passed }) => {
		const { password } = this.state;
		let confirmPassword = value;
		this.setState({
			passwordMismatchInline: password !== confirmPassword,
			confirmPassword: confirmPassword
		});
	};

	async handleSavePassword(e) {
		let { savingPassword } = this.state;
		if (savingPassword) {
			return;
		}

		if (this.isLoadingButtonDisabled()) {
			return;
		}

		this.setState({ savingPassword: true });
		const { password, token, recaptchaValue } = this.state;

		let success = await UserService.resetPassword({ password, token, recaptchaValue });

		if (!success) {
			this.setState({ saveError: true, saveSuccess: false, savingPassword: false });
			return;
		}

		this.setState({ saveSuccess: true, saveError: false, savingPassword: false });
	}

	isLoadingButtonDisabled = () => {
		const { passwordMismatchInline, isValidPassword, recaptchaValue } = this.state;

		return passwordMismatchInline || !isValidPassword || !recaptchaValue || recaptchaValue.length === 0;
	};

	renderPasswordMessage = () => {
		let { passwordMismatchInline, isValidPassword, confirmPassword } = this.state;
		let { t } = this.props;

		if (passwordMismatchInline && confirmPassword.length >= SHOW_PASSWORD_ERROR_ON_LENGTH) {
			return (
				<div className="text-danger text-center">
					<i className="fa fa-times" />
					&nbsp;{t("Passwords do not match.")}
				</div>
			);
		}

		if (!passwordMismatchInline && isValidPassword) {
			return (
				<div className="reset-password__text-success text-center">
					<i className="fa fa-check" />
					&nbsp;{t("Password is valid.")}
				</div>
			);
		}

		return null;
	};

	render() {
		const { password, confirmPassword, savingPassword, saveError, saveSuccess } = this.state;
		let { t } = this.props;

		if (UserService.get()) {
			this.props.history.push("/");
		}

		return (
			<div className="reset-password reset-password--reset-page Common__card animated fadeIn">
				<h1>{t("Create New Password")}</h1>
				<form
					onSubmit={e => {
						e.preventDefault();
					}}
				>
					<div className="form-group">
						<PasswordInput
							name="form_password"
							id="form_password"
							placeholder={t("New password...")}
							required={true}
							onChange={this.handlePasswordChange}
							value={password}
						/>
						<PasswordRequirements value={password} showRequirements={true} />
					</div>
					<div className="form-group">
						<PasswordInput
							name="form_confirm_password"
							id="form_confirm_password"
							placeholder={t("Confirm password...")}
							required={true}
							onChange={this.handleConfirmPasswordChange}
							value={confirmPassword}
							showToggleView={false}
						/>
					</div>
					<div className="form-group">{this.renderPasswordMessage()}</div>

					<div align="center" className="form-group reset-password__recaptcha--scale">
						<ReCAPTCHA ref="recaptcha" sitekey={config.RECAPTCHA_SITE_KEY} onChange={value => this.onCaptchaChange(value)} />
					</div>

					<div className="reset-password__reset-btn form-group">
						<LoadingButton
							componentClass={`mb-button mb-button--slim mb-button--center ${this.isLoadingButtonDisabled() ? "mb-button--disabled" : ""}`}
							loading={savingPassword}
							onClick={e => this.handleSavePassword(e)}
							type="submit"
							disabled={this.isLoadingButtonDisabled()}
							size="4px"
						>
							{t("Reset Password")}
						</LoadingButton>
					</div>
				</form>
				<Alert type="error" show={saveError} title={t("Sending Error")} confirm={t("OK")} onClose={() => this.setState({ saveError: false })}>
					<div>{t("Sorry, an error was encountered while saving the password. Please try again.")}</div>
				</Alert>
				<Alert type="success" show={saveSuccess} title={t("Password Reset Successful")} confirm={t("OK")} onClose={() => this.props.history.push("/login")}>
					<div>{t("Please login with your new password.")}</div>
				</Alert>
			</div>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(ResetPasswordPage));
