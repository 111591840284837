import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import Preferences from "../../services/PreferenceService";
import GAService from "../../services/GAService";
import CompanyService from "../../services/CompanyService";
import * as Icon from "react-feather";
import { STATUS, COMPANY_COLUMNS, COMPANY_FILTERS } from "../../constants/CompanyConstants";
import { SORT_ORDER } from "../../constants/CommonConstants";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import SearchInput from "../../components/common/SearchInput";
import List from "../../components/common/List";
import Action from "../../components/common/Action";
import Filters from "../../components/common/Filters";
import Alert from "../../components/common/Alert";

import "../../styles/css/components/commons/react-table.css";
import "../../styles/css/scenes/companies.css";

class Companies extends Component {
	constructor(props) {
		super(props);

		let showFavorites = Preferences.get("show_favorites") ? true : false;

		this.state = {
			companies: [],
			limitDefault: 10,
			limit: 25,
			pageSize: 50,
			showFavoritesOnly: showFavorites,
			hasError: false,
			errorText: "",
			infoText: "",
			success: false,
			hasWarning: false,
			warningText: "",
			currentToDeleteRow: undefined,
			currentToInactivateRow: undefined,
			currentToActivateRow: undefined,
			searchTerm: "",
			loading: true,
			loadedAll: false,
			sortField: COMPANY_COLUMNS.name.sortField,
			sortOrder: SORT_ORDER.asc,
			favoriteFilterSelected: showFavorites ? COMPANY_FILTERS.favorites.id : COMPANY_FILTERS.all.id
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.refreshCompaniesList();
	}

	/**
	 * Returns the company from the companies array for the specified id
	 * @param {Number} companyId
	 * @returns {Object|null}
	 */
	getCompanyFromId(companyId) {
		let retVal = null;

		for (let i = 0; i < this.state.companies.length; i++) {
			if (this.state.companies[i].id === companyId) {
				retVal = this.state.companies[i];
				break;
			}
		}

		return retVal;
	}

	handleFavoriteCompanyToggle = async companyId => {
		let company = this.getCompanyFromId(companyId);
		if (company) {
			if (company.UserCompanyFavorites && company.UserCompanyFavorites.length < 1) {
				try {
					// Set the favorite in the backend
					await this.markCompanyFavorite(companyId);
					company.favorite = 1;
					this.forceUpdate();
				} catch (error) {
					console.log("Cannot mark company favorite");
				}
			} else {
				try {
					// Reset the favorite in the backend
					await this.unmarkCompanyFavorite(companyId);
					// Update the flag
					company.favorite = 0;
					this.forceUpdate();
				} catch (error) {
					console.log("Cannot unmark company favorite");
				}
			}
		}
	};

	handleAdminCompanyClick = async row => {
		try {
			await CompanyService.setCompanyAndLocationAsAdmin(row.id, row.name);
			this.forceUpdate();
		} catch (error) {
			console.log(error);
		}
	};

	handleUpdateCompanyClick = row => {
		this.props.history.push(`/companies/${row.id}`);
	};

	confirmCompanyStatusClick(row, status) {
		let { t } = this.props;

		const obj = {
			hasWarning: true
		};
		let warningText = "";
		if (status === STATUS.active) {
			obj.warningText = t("Are you sure you want to activate this company?");
			obj.currentToActivateRow = row;
		} else if (status === STATUS.inactive) {
			obj.warningText = t("Are you sure you want to deactivate this company?");
			obj.currentToInactivateRow = row;
		} else if (status === STATUS.deleted) {
			obj.warningText = t("Are you sure you want to delete this company?");
			obj.currentToDeleteRow = row;
		}

		this.setState(obj);
	}

	async handleChangeCompanyStatus(companyId, status) {
		let { t } = this.props;

		try {
			this.setState({
				hasWarning: false,
				warningText: "",
				currentToInactivateRow: undefined,
				currentToActivateRow: undefined,
				currentToDeleteRow: undefined
			});

			await CompanyService.updateCompanyStatus(companyId, status);

			this.setState({ infoText: t("The company has been updated."), success: true });
			this.refreshCompaniesList();
		} catch (error) {
			console.log(error);

			this.setState({ errorText: t("Error updating the Company."), hasError: true });
		}
	}

	async refreshCompaniesList() {
		let { showFavoritesOnly, searchTerm, sortField, sortOrder, limit } = this.state;
		let companies = await CompanyService.fetchCompanies({ favoritesOnly: showFavoritesOnly, searchTerm, sortField, sortOrder, limit });
		if (!companies) {
			this.setState({ loading: false });
			return;
		}
		this.setState({ companies: companies, loading: false, loadedAll: companies.length < limit });
	}

	async markCompanyFavorite(companyId) {
		await CompanyService.markFavorite(companyId);
		this.refreshCompaniesList();
	}

	async unmarkCompanyFavorite(companyId) {
		await CompanyService.unmarkFavorite(companyId);
		this.refreshCompaniesList();
	}

	generateOptionsFromPermissions(company) {
		let { t } = this.props;
		const options = [];
		const user = UserService.get();

		if (user.GroupPermission.update_companies) {
			options.push(
				{
					label: t("Admin"),
					value: "admin"
				},
				{
					label: t("Update"),
					value: "update"
				}
			);
		}
		if (user.GroupPermission.delete_companies) {
			if (company.status === STATUS.inactive) {
				options.push({
					label: t("Activate"),
					value: "active"
				});
			} else if (company.status === STATUS.active) {
				options.push({
					label: t("Inactivate"),
					value: "inactive"
				});
			}
			options.push({
				label: t("Delete"),
				value: "delete"
			});
		}

		return options;
	}

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		await this.refreshCompaniesList();
	};

	getHeaders = () => {
		let headers = {
			items: COMPANY_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	};

	onRecordClicked = async item => {};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.refreshCompaniesList();
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ loading: true, sortField, sortOrder });
		await this.refreshCompaniesList();
		await this.update({ loading: false });
	};

	onFavoriteFilterSelect = async item => {
		let showFavoritesOnly = item.id === COMPANY_FILTERS.favorites.id;
		await this.update({ loading: true, favoriteFilterSelected: item.id, showFavoritesOnly });
		Preferences.set("show_favorites", showFavoritesOnly);
		await this.refreshCompaniesList();
		await this.update({ loading: false });
	};

	isFavoriteSelected = item => {
		return item === this.state.favoriteFilterSelected;
	};

	getFilters = () => {
		let items = Object.keys(COMPANY_FILTERS)
			.map(item => {
				return { id: COMPANY_FILTERS[item].id, value: COMPANY_FILTERS[item].display, order: COMPANY_FILTERS[item].order };
			})
			.sort((a, b) => a.order - b.order);
		let filters = {
			statuses: {
				title: "",
				items: items,
				onClick: this.onFavoriteFilterSelect,
				isSelected: this.isFavoriteSelected
			}
		};
		return filters;
	};

	renderRecordActions = recordData => {
		let { t } = this.props;
		const user = UserService.get();

		return (
			<div className="companies__actions">
				{/* Only allow admining a company if there is already a location created for it */}
				{user.GroupPermission.view_companies && recordData.locations && recordData.locations.length > 0 && (
					<Action id={`admin-${recordData.id}`} label={t("Admin")} icon={Icon.Airplay} onClick={() => this.handleAdminCompanyClick(recordData)} />
				)}
				{user.GroupPermission.update_companies && (
					<Action id={`edit-company-${recordData.id}`} label={t("Edit")} icon={Icon.Edit} onClick={() => this.handleUpdateCompanyClick(recordData)} />
				)}
				{user.GroupPermission.update_companies && recordData.status === STATUS.inactive && (
					<Action
						id={`inactive-${recordData.id}`}
						label={t("Inactive")}
						icon={Icon.Activity}
						onClick={() => this.confirmCompanyStatusClick(recordData, STATUS.active)}
					/>
				)}
				{user.GroupPermission.update_companies && recordData.status === STATUS.active && (
					<Action
						id={`active-${recordData.id}`}
						label={t("Active")}
						icon={Icon.Activity}
						active={recordData.status === STATUS.active}
						onClick={() => this.confirmCompanyStatusClick(recordData, STATUS.inactive)}
					/>
				)}
				{user.GroupPermission.delete_companies && (
					<Action
						id={`delete-${recordData.id}`}
						label={t("Delete")}
						icon={Icon.Trash2}
						onClick={() => this.confirmCompanyStatusClick(recordData, STATUS.deleted)}
					/>
				)}
			</div>
		);
	};

	renderRecord = recordData => {
		let { t } = this.props;
		const company = UserService.getActiveCompany();
		return [
			UserService.isSuperAdminOrCustomerSuccess() && recordData.id,
			<div className="companies__name">
				<Action
					id="favorite-company-toggle"
					label={recordData.UserCompanyFavorites && recordData.UserCompanyFavorites.length > 0 ? t("Unfavorite") : t("Favorite")}
					icon={Icon.Star}
					key={"favorite-company-toggle-" + recordData.id}
					active={recordData.UserCompanyFavorites && recordData.UserCompanyFavorites.length > 0 ? true : false}
					onClick={() => this.handleFavoriteCompanyToggle(recordData.id)}
				/>
				{recordData.name} {recordData.id === company.id ? <span className="badge">{t("Admin")}</span> : ""}
			</div>,
			this.renderRecordActions(recordData)
		];
	};

	render() {
		const { companies, loading, loadedAll, sortField, sortOrder } = this.state;
		let { t } = this.props;

		let user = UserService.get();

		return (
			<Page>
				<Header title={t("Companies")}>
					{user.GroupPermission.create_companies && (
						<Action id="new-company" label={t("Create")} icon={Icon.Plus} onClick={() => this.props.history.push(`/companies/create`)} />
					)}
				</Header>
				<div className="companies__search">
					<SearchInput placeholder={t("Search ...")} onChange={this.onSearchChange} />
				</div>
				<Filters filters={this.getFilters()} />
				<List
					items={companies}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={t("Companies not found")}
					noDataIcon={<Icon.AlertCircle />}
				/>
				<Alert
					type="error"
					show={this.state.hasError}
					title={t("Error")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ hasError: false, errorText: "" });
					}}
				>
					<div>{this.state.errorText}</div>
				</Alert>
				<Alert
					type="success"
					show={this.state.success}
					title={t("Success")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ success: false, infoText: "" });
					}}
				>
					<div>{this.state.errorText}</div>
				</Alert>
				<Alert
					show={this.state.hasWarning}
					title={t("Success")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={confirmed => {
						if (confirmed) {
							if (this.state.currentToDeleteRow) {
								this.handleChangeCompanyStatus(this.state.currentToDeleteRow.id, STATUS.deleted);
							} else if (this.state.currentToInactivateRow) {
								this.handleChangeCompanyStatus(this.state.currentToInactivateRow.id, STATUS.inactive);
							} else if (this.state.currentToActivateRow) {
								this.handleChangeCompanyStatus(this.state.currentToActivateRow.id, STATUS.active);
							}
						}
						this.setState({ messageDelete: false, hasWarning: false, warningText: "" });
					}}
				>
					<div>{this.state.warningText}</div>
				</Alert>
			</Page>
		);
	}
}

export default withTranslation(null, { withRef: true })(Companies);
