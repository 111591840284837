import React, { Component } from "react";

import "../../styles/css/components/commons/profile-picture.css";

class ProfilePicture extends Component {
	handleUpdateProfilePictureOnChange = async e => {
		if (e.target.files.length === 0) {
			return;
		}

		let newImageFile = e.target.files[0];

		if (this.props.handleProfilePictureOnChange) {
			await this.props.handleProfilePictureOnChange(newImageFile);
		}
	};

	handleDeleteProfilePictureOnChange = async e => {
		if (this.props.handleProfilePictureOnChange) {
			await this.props.handleProfilePictureOnChange("");
		}
	};

	render() {
		const { profileInitials, profilePictureFileUrl } = this.props;

		return (
			<div className="profile-picture__container">
				<div className="profile-picture__circle">
					{profilePictureFileUrl ? (
						<img className="profile-picture__image" src={profilePictureFileUrl} alt="Profile" />
					) : (
						<div className="profile-picture__initials">{profileInitials}</div>
					)}
				</div>
				<div className="profile-picture__controls">
					{profilePictureFileUrl ? (
						<>
							<label htmlFor="image-upload" onClick={e => this.handleDeleteProfilePictureOnChange(e)}>
								<i className="fa fa-times profile-picture__upload_button" />
							</label>
						</>
					) : (
						<>
							<label htmlFor="image-upload">
								<i className="fa fa-pencil profile-picture__upload_button" />
							</label>
							<input
								className="profile-picture__file_upload"
								id="image-upload"
								onChange={e => this.handleUpdateProfilePictureOnChange(e)}
								type="file"
								accept="image/*"
							/>
						</>
					)}
				</div>
			</div>
		);
	}
}

export default ProfilePicture;
