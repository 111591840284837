import React, { Component } from "react";
import moment from "moment";
import "whatwg-fetch";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";

import { EVENT_TYPES, LINK_SHORTEN_MAX_CHAR, DISCONNECTED_CAUSES } from "../../../../constants/Messenger";

import UserService from "../../../../services/UserService";
import ContactService from "../../../../services/ContactService";
import ToastService from "../../../../services/ToastService";
import SupportChatService from "../../../../services/SupportChatService";
import LocationService from "../../../../services/LocationService";

import UpdateContactNotesModal from "../../../Notes/UpdateContactNotesModal";
import Linkify from "../../../../components/common/Linkify";
import Alert from "../../../../components/common/Alert";

import "./message-event.css";

class MessageEvent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isContext: false,
			showUpdateSubscriptionModal: false,

			/* Related to notes */
			showModalNotes: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = async e => {
		if (this.context && this.context.contains && this.context.contains(e.target)) {
			return;
		}

		await this.update({
			isContext: false
		});
	};

	handleUpdateNoteClick = async () => {
		await this.update({ showModalNotes: true });
	};

	handleDeleteNoteClick = async () => {
		let { message } = this.props;

		let response = await ContactService.deleteContactNote({ noteId: message.id, contactId: message.contact_id });

		if (!response) {
			ToastService.error(`Error deleting note.`);
			return;
		}

		ToastService.info(`Successfully deleted note.`);
	};

	onHideNoteModal = async () => {
		await this.update({ showModalNotes: false });
	};

	onNoteSubmit = async () => {
		await this.update({ showModalNotes: false });
	};

	renderContextMenu = () => {
		let { isContext } = this.state;

		let user = UserService.get();

		if (!isContext) {
			return null;
		}

		return (
			<div ref={ref => (this.context = ref)} className={`mb-message-context-menu mb-message-context-menu--note`}>
				{user.GroupPermission.update_contact_notes && (
					<div className="mb-message-context-menu-item" id="mb-message-context-menu-note-update" onClick={this.handleUpdateNoteClick}>
						Update Note
					</div>
				)}
				{user.GroupPermission.delete_contact_notes && (
					<div className="mb-message-context-menu-item" id="mb-message-context-menu-note-delete" onClick={this.handleDeleteNoteClick}>
						Delete Note
					</div>
				)}
			</div>
		);
	};

	onContextClick = () => {
		let { isContext } = this.state;

		this.update({
			isContext: !isContext
		});
	};

	onUpdateSubscriptionModal = async confirm => {
		if (confirm) {
			SupportChatService.showNewMessage("Hi, I would like to set up Voice features. Would you be able to help?");
		}

		await this.update({
			showUpdateSubscriptionModal: false
		});
	};

	renderInfoIcon = () => {
		let { message } = this.props;

		if (!message || !message.content) {
			return null;
		}

		if (message.event_type !== EVENT_TYPES.callDisconnected && message.event_type !== EVENT_TYPES.getVoiceFeature) {
			return null;
		}

		let tooltip = "";
		if (message.event_type === EVENT_TYPES.callDisconnected) {
			// Get the "Cause" from the event
			let cause = message.content.match(/\((.*?)\)/g).map(match => match.slice(1, -1));

			if (!DISCONNECTED_CAUSES[cause]) {
				console.log(`Error: Event cause unknown.`);
				return null;
			}
			tooltip = DISCONNECTED_CAUSES[cause].description;
		} else if (message.event_type === EVENT_TYPES.getVoiceFeature) {
			tooltip = "Receive Voicemail, enable call forwarding, and more. Contact us to find out more.";
		}

		return (
			<>
				<Icon.Info className="mb-message-event-divider-content__disconnect__info" data-tip data-for={`event-${message.public_id}`} size={16} />
				<ReactTooltip id={`event-${message.public_id}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
					{tooltip}
				</ReactTooltip>
			</>
		);
	};

	render() {
		let { showModalNotes, showUpdateSubscriptionModal } = this.state;
		let { message } = this.props;
		let { content, created_at } = message;
		let date = moment(created_at).format("h:mm a");

		// Add styling for conversation notes
		let eventMessageStyling = "";
		let showCallToAction = false;
		let callToActionText = "Get Started";
		if (message.event_type === EVENT_TYPES.conversationNote) {
			eventMessageStyling = "mb-message-event-divider-content--note";
		} else if (message.event_type === EVENT_TYPES.reviewGenerated) {
			eventMessageStyling = "mb-message-event-divider-content--review";
		} else if (message.event_type === EVENT_TYPES.getVoiceFeature) {
			if (!LocationService.isVoiceEnabled()) {
				showCallToAction = true;
				callToActionText = "Enable Voice Features";
				eventMessageStyling = "mb-message-event-divider-content--get-voice";
			} else if (content && content.split(".") && content.split(".").length > 0) {
				// If it's the missed call event. Chop everything after the period.
				content = content.split(".")[0];
			}
		} else if (message.event_type === EVENT_TYPES.voicemailCompleted || message.event_type === EVENT_TYPES.voicemailCaptured) {
			// We don't want to show these since it is a bit verbose for the end user
			return null;
		}

		if (message.status === "deleted") {
			return null;
		}

		return (
			<div key={message.id} className="mb-message-event-divider">
				<div className={`mb-message-event-divider-content ${eventMessageStyling}`}>
					<div className="mb-message-customer-event-context" onClick={this.onContextClick}>
						<Icon.ChevronDown size="20" />
					</div>
					<Linkify charLimit={LINK_SHORTEN_MAX_CHAR}>
						{content.split("\n").map((blurb, index) => {
							let length = content.split("\n").length;

							if (blurb.length === 0) {
								return <br key={index} />;
							}

							if (length === 1) {
								return (
									<p key={index}>
										{blurb} {this.renderInfoIcon()} - {date}
									</p>
								);
							}

							if (length - 1 === index) {
								return (
									<p key={index}>
										{blurb} {this.renderInfoIcon()} - {date}
									</p>
								);
							}

							return <p key={index}>{blurb}</p>;
						})}
					</Linkify>
					{showCallToAction && (
						<div className="mb-message-event-divider-content__get-started" onClick={() => this.update({ showUpdateSubscriptionModal: true })}>
							{callToActionText}
						</div>
					)}
					{this.renderContextMenu()}
					<UpdateContactNotesModal
						show={showModalNotes}
						contactId={message.contact_id}
						noteId={message.id}
						createMode={false}
						onSubmit={this.onNoteSubmit}
						onHide={this.onHideNoteModal}
					/>
					{showCallToAction && (
						<Alert type="info" show={showUpdateSubscriptionModal} title="Upgrade Subscription" confirm="Chat with us" onClose={this.onUpdateSubscriptionModal}>
							To enable Voice features, please chat with us or contact support@demandhub.co.
						</Alert>
					)}
				</div>
			</div>
		);
	}
}

export default MessageEvent;
