import React from "react";
import { withTranslation } from "react-i18next";

import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Tabs from "../../components/common/Tabs";
import Tab from "../../components/common/Tab";

import ChatbotConfiguration from "../../scenes/ChatbotConfiguration/ChatbotConfiguration";
import ChatbotFaqs from "../../scenes/ChatbotFaqs/ChatbotFaqs";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";

import { CHATBOT_TABS } from "../../constants/Settings";
import { GA_CATEGORIES, GA_ACTIONS } from "../../constants/GAConstants";
class Chatbots extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedTab: CHATBOT_TABS.configuration.id
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	onTabSelect = tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.settings.sections.chatbots,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		this.setState({ selectedTab: tab.id });
	};

	render() {
		let { selectedTab } = this.state;
		let { t } = this.props;

		const user = UserService.get();

		return (
			<Page>
				<Header title={t("Chatbots")} />
				<Tabs onSelect={this.onTabSelect} selected={selectedTab}>
					{user.GroupPermission.view_contacts && <Tab id={CHATBOT_TABS.configuration.id} value={CHATBOT_TABS.configuration.value} />}
					{user.GroupPermission.update_chatbot_faqs && <Tab id={CHATBOT_TABS.faq.id} value={CHATBOT_TABS.faq.value} />}
				</Tabs>
				{CHATBOT_TABS.configuration.id === selectedTab && <ChatbotConfiguration />}
				{CHATBOT_TABS.faq.id === selectedTab && <ChatbotFaqs />}
			</Page>
		);
	}
}

export default withTranslation(null, { withRef: true })(Chatbots);
