import Kichiri from "./KichiriService";
import UserService from "./UserService";
import { TAG_TYPES } from "../constants/Tags";

const TagService = {
	/**
	 * Get tags for a location
	 * @param {Integer} locationId
	 * @returns {Promise} All tags for a location
	 */
	async getTags(locationId, params) {
		try {
			if (!locationId) {
				locationId = UserService.getActiveLocation().id;
			}

			let query = {
				locationId: locationId,
				searchTerm: "",
				sortField: "",
				sortOrder: "",
				type: TAG_TYPES.tag,
				limit: 100,
				includeContacts: false
			};

			query = Object.assign(query, params);
			let response = await Kichiri.tag.fetch({}, query, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get the option and assigned tags for a select dropdown
	 * @param {Integer} contactId
	 * @param {String} type is "group" or "tag"
	 * @returns {Object} Returns an object with 2 lists. One list is the list of all the tags (the options), and the other list is all the assigned tags for that user
	 */
	async getTagsForSelect(contactId, type = TAG_TYPES.tag) {
		try {
			// Get the tags for the location depending on the type
			let tags = await this.getTags(null, { type, sortField: "id" });

			// Format into a nice clean object
			let optionTags = tags.map(t => {
				return { value: t.name, label: t.name, tag_id: t.id, contact_id: contactId };
			});

			// Get the tags and groups assigned to us
			let assignedTags = await this.fetchAssignedTags(contactId);
			if (!assignedTags) {
				assignedTags = [];
			}

			// We only want either tags or groups
			assignedTags = assignedTags.filter(tag => tag.type === type);

			// Reformat this new list
			assignedTags = assignedTags.map(t => {
				return { value: t.name, label: t.name, tag_id: t.id, contact_id: contactId };
			});

			// Return all tags, and all tags assigned
			return { optionTags, assignedTags };
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create a tag for a location
	 * @param {Integer} locationId
	 * @param {String} name
	 * @param {String} type is "group" or "tag"
	 * @returns {Promise}
	 */
	async createTag(locationId, name, type = TAG_TYPES.tag) {
		try {
			let response = await Kichiri.tag.create({ locationId, name, type }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a tag
	 * @param {Integer} id
	 * @param {String} name
	 * @returns {Promise}
	 */
	async updateTag(id, name) {
		try {
			let response = await Kichiri.tag.update({ tagId: id, name }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Delete a tag by a tag id
	 * @param {Integer} id
	 * @returns {Promise}
	 */
	async deleteTag(id) {
		try {
			let response = await Kichiri.tag.delete({ tagId: id }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get all of the assigned tags that belong to a contact
	 * @param {Integer} contactId
	 * @returns {Promise}
	 */
	async fetchAssignedTags(contactId) {
		try {
			let response = await Kichiri.contacts.fetchAssignedTags({ contactId: contactId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Assign a tag to a contact
	 * @param {Array} assignedTags The array of all the tags that belong to this contact.
	 * @param {Integer} contactId
	 * @returns {Promise}
	 */
	async assignTags(assignedTags, contactId) {
		try {
			let response = await Kichiri.tag.assignTags({ assignedTags, contactId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Check if a tag exists for a location
	 * @param {Integer} locationId
	 * @param {String} name
	 * @param {String} type is "group" or "tag"
	 * @returns {Promise}
	 */
	async tagExists(locationId, name, type) {
		try {
			if (!locationId) {
				locationId = UserService.getActiveLocation().id;
			}
			let response = await Kichiri.tag.tagExists({}, { locationId, name, type }, UserService.getAuthToken());
			return response.data.tagExists;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Add several tags to several contacts
	 * @param {Array/Integer} tags
	 * @param {Array/Integer} contacts
	 */
	async addTagsForContacts(tags, contacts) {
		try {
			let response = await Kichiri.tag.addTagsForContacts({ tags, contacts }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Overwrite tags for several contacts
	 * @param {Array/Integer} tags
	 * @param {Array/Integer} contacts
	 * @param {String} contacts
	 */
	async overwriteTagsForContacts(tags, contacts, type) {
		try {
			let response = await Kichiri.tag.overwriteTagsForContacts({ tags, contacts, type }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Remove tags for several contacts
	 * @param {Array/Integer} tags
	 * @param {Array/Integer} contacts
	 */
	async removeTagsForContacts(tags, contacts) {
		try {
			let response = await Kichiri.tag.removeTagsForContacts({ tags, contacts }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetches a single tag
	 *
	 * @param {Number} tagId
	 */
	async getTag(tagId) {
		try {
			if (!tagId) {
				return null;
			}
			let response = await Kichiri.tag.get({ tagId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches usage data of a tag
	 *
	 * @param {Number} tagId
	 *
	 * @returns {Object} { scheduledMessages: # of scheduled messages associated to tag, contacts: # number of contacts with this tag}
	 */
	async getTagUsageData(tagId) {
		try {
			if (!tagId) {
				return null;
			}
			let response = await Kichiri.tag.getTagUsageData({ tagId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	}
};

export default TagService;
