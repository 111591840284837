import React, { Component } from "react";
import Select from "react-select";

import DHModal from "../../components/common/DHModal";
import DHSelect from "../../components/common/DHSelect";
import Input from "../../components/common/Input";
import Alert from "../../components/common/Alert";

import ToastService from "../../services/ToastService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";
import UserService from "../../services/UserService";

import { CRM_INTEGRATIONS } from "../../constants/CRMIntegration";
import { STATUS } from "../../constants/CommonConstants";

import "../../styles/css/scenes/connections.css";

class ConnectPTEverywhereModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			selectedIntegration: null,
			selectableIntegrations: [],
			integration: null,
			locations: [],

			tenantId: "",
			email: "",
			password: "",
			crmLocationId: "",
			crmLocationName: "",

			lastTestStatus: false,
			showLocationSelector: false,
			showAlertIntegrationNotFound: false,
			showAlertNoLocationsFound: false,
			showAlertLocationUpdateFailed: false,
			showAlertTestSuccess: false,
			showAlertTestFailed: false,
			showAlertConnectionComplete: false,
			showDisconnectModal: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { integrationId, show } = this.props;

		// If we are not showing the dialog, don't fetch anything
		if (!show) {
			return;
		}

		await this.fetchIntegrations();
		await this.onIntegrationSelected();
	}

	onClickDisconnect = () => {
		this.update({ showDisconnectModal: true });
	};

	disconnectIntegration = async confirm => {
		if (!confirm) {
			await this.update({ showDisconnectModal: false });
			return;
		}

		if (this.props.disconnectIntegration) {
			this.props.disconnectIntegration();
		}

		await this.update({ showDisconnectModal: false });
	};

	fetchIntegrations = async () => {
		let integrations = await CrmIntegrationsService.fetch(UserService.getActiveLocation().id, {
			type: CRM_INTEGRATIONS.type.pteverywhere.name,
			status: [STATUS.active]
		});

		if (!integrations) {
			this.update({
				selectableIntegrations: []
			});
			return;
		}

		let selectableIntegrations = integrations.map(i => {
			return { value: i.id, label: i.name ? i.name : CRM_INTEGRATIONS[i.crm_type].displayName };
		});

		this.update({
			selectableIntegrations
		});
	};

	onIntegrationSelected = async integrationOption => {
		let integrationId = null;

		if (integrationOption) {
			integrationId = integrationOption.value;
		}

		if (!integrationId) {
			// Default to the passed in integration id
			integrationId = this.props.integrationId;
		}

		// Set loading to true, and reset dirty bit
		await this.update({
			loading: true,
			integration: null
		});

		try {
			// Fetch the integration object from the backend
			let integration = await CrmIntegrationsService.fetchRestIntegration(integrationId);

			if (!integration || integration.status === STATUS.deleted) {
				// Show integration not found dialog
				await this.update({
					showAlertIntegrationNotFound: true
				});

				return;
			}

			let email = integration.meta_data.credentials.login ? integration.meta_data.credentials.login : "";
			let password = integration.meta_data.credentials.password ? integration.meta_data.credentials.password : "";
			let crmLocationId = integration.meta_data.locationId ? integration.meta_data.locationId : 0;
			let crmLocationName = integration.meta_data.locationName ? integration.meta_data.locationName : "";

			// Save this integration in our state
			await this.update({
				integration,
				email,
				password,
				crmLocationId,
				crmLocationName,
				selectedIntegration: { value: integration.id, label: integration.name ? integration.name : CRM_INTEGRATIONS[integration.crm_type].displayName }
			});
		} catch (error) {
			console.log(error.message);
		}
		await this.update({ loading: false });
	};

	/**
	 * Fetch the PtEverywhere locations
	 */
	async fetchLocations() {
		let { show } = this.props;
		let { selectedIntegration } = this.state;

		// If we are not showing the dialog, don't fetch anything
		if (!show) {
			return;
		}

		// Fetch the locations from the backend
		try {
			let locations = await CrmIntegrationsService.fetchRestLocations(selectedIntegration.value);
			if (!locations || locations.length === 0) {
				// Show locations not found dialog
				await this.update({
					showAlertNoLocationsFound: true
				});

				return;
			}

			await this.update({ locations, showLocationSelector: true, loading: false });
		} catch (error) {
			console.log(error.message);
			this.update({ showAlertTestFailed: true, showLocationSelector: false, loading: false });
		}
	}

	getLocationSelector() {
		const { locations } = this.state;

		if (!locations || locations.length === 0) {
			return;
		}

		const retVal = locations.map(loc => (
			<option value={loc.id} key={loc.id}>
				{loc.name}
			</option>
		));

		return retVal;
	}

	onEmailChange = async event => {
		await this.update({ email: event.target.value });
	};

	onPasswordChange = async event => {
		await this.update({ password: event.target.value });
	};

	onTestConnection = async event => {
		let { selectedIntegration } = this.state;

		await this.update({ loading: true });

		let testResponse = await CrmIntegrationsService.testRestIntegration({
			integrationId: selectedIntegration.value,
			login: this.state.email,
			password: this.state.password
		});

		if (testResponse.status) {
			ToastService.info(`Connection Successful!`);
			await this.update({ lastTestStatus: testResponse.status });
			await this.fetchLocations();
			return;
		}

		await this.update({ lastTestStatus: testResponse.status, loading: false, showAlertTestFailed: !testResponse.status });
	};

	onConfirmTestSuccess = async event => {
		await this.update({ showAlertTestSuccess: false });

		await this.fetchLocations();
	};

	onConfirmTestFailed = async event => {
		await this.update({ showAlertTestFailed: false });
	};

	onConfirmIntegrationNotFound = async event => {
		await this.update({ showAlertIntegrationNotFound: false });
	};

	onConfirmNoLocationsFound = async event => {
		let { onHide } = this.props;

		await this.update({ showLocationSelector: false, showAlertNoLocationsFound: false, loading: false });

		if (onHide) {
			onHide();
		}
	};

	onConfirmLocationUpdateFailed = async event => {
		await this.update({ showAlertLocationUpdateFailed: false });
	};

	onLocationChange = async selection => {
		await this.update({ crmLocationId: selection ? selection.value : "", crmLocationName: selection.label });
	};

	onSaveLocation = async event => {
		let { crmLocationId, crmLocationName, selectedIntegration } = this.state;

		await this.update({ loading: true });

		let saveLocResponse = await CrmIntegrationsService.updateRestLocation({
			integrationId: selectedIntegration.value,
			crmLocationId: crmLocationId,
			crmLocationName
		});

		if (!saveLocResponse) {
			await this.update({ loading: false, showAlertLocationUpdateFailed: true });
			return;
		}

		// If we get here, show the success message
		await this.update({ showAlertConnectionComplete: true });
	};

	onCancel = async event => {
		let { onHide } = this.props;

		await this.update({ showLocationSelector: false });

		if (onHide) {
			onHide();
		}
	};

	onConfirmConnectionComplete = async event => {
		let { onHide } = this.props;

		// If we get here, we're all done
		await this.update({ loading: false, showLocationSelector: false, showAlertConnectionComplete: false });

		if (onHide) {
			onHide();
		}
	};

	connectDisabled = () => {
		let { loading, email, password } = this.state;

		if (loading) {
			return true;
		}

		if (email === "" || password === "") {
			return true;
		}
	};

	render() {
		let { show, integrationId } = this.props;

		let {
			crmLocationId,
			crmLocationName,
			email,
			password,
			locations,
			showAlertTestSuccess,
			showAlertTestFailed,
			showAlertIntegrationNotFound,
			showAlertNoLocationsFound,
			showAlertLocationUpdateFailed,
			showAlertConnectionComplete,
			showLocationSelector,
			showDisconnectModal,

			selectedIntegration,
			selectableIntegrations
		} = this.state;

		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();
		let showDisconnectButton = integrationId && isSuperOrCs;

		return (
			<>
				<DHModal title="Connect to PtEverywhere" show={show} onHide={this.onCancel}>
					<>
						{selectableIntegrations.length > 1 && selectedIntegration && (
							<div className="Connections-modal__select">
								<div>Integration</div>
								<Select
									id={`selectable-integrations`}
									options={selectableIntegrations}
									value={selectedIntegration}
									onChange={this.onIntegrationSelected}
									placeholder={`${CRM_INTEGRATIONS.type.janeapp.displayName} Integration`}
								/>
							</div>
						)}

						{!showLocationSelector && (
							<>
								{crmLocationId && crmLocationName ? <div>Connected to location "{crmLocationName}"</div> : null}
								<div className="modal__flex-container">
									<div className="Connections-modal__input">
										<Input label="Email" name="pteverywhere-email" id="pteverywhere-email" type="text" onChange={this.onEmailChange} value={email} required />
									</div>

									<div className="Connections-modal__input">
										<Input
											label="Password"
											name="pteverywhere-password"
											id="pteverywhere-password"
											type="password"
											onChange={this.onPasswordChange}
											value={password}
											required
										/>
									</div>
								</div>

								<div className="modal__actions">
									<button
										id="pteverywhere-cancel"
										className={`mb-button mb-button--cancel ${this.state.loading ? "mb-button--disabled" : ""}`}
										disabled={this.state.loading}
										onClick={this.onCancel}
									>
										Cancel
									</button>

									{showDisconnectButton && (
										<button
											id="pteverywhere-disconnect"
											className={`mb-button mb-button--cancel ${this.state.loading ? "mb-button--disabled" : ""}`}
											disabled={this.state.loading}
											onClick={this.onClickDisconnect}
										>
											Disconnect
										</button>
									)}

									<button
										id="pteverywhere-test-connection"
										className={`mb-button ${this.connectDisabled() ? "mb-button--disabled" : ""}`}
										disabled={this.connectDisabled()}
										onClick={this.onTestConnection}
									>
										Test Connection
									</button>
								</div>
							</>
						)}

						{showLocationSelector && (
							<>
								<div className="Connections-modal__input">
									<DHSelect
										label="Locations"
										name="pteverywhere-location"
										id="pteverywhere-location"
										ref={this.locationsRef}
										onChange={this.onLocationChange}
										defaultValue={crmLocationId}
										value={{ value: crmLocationId, label: crmLocationName }}
										options={locations.map(location => {
											return {
												value: location.id,
												label: location.name
											};
										})}
										required={true}
										isClearable={true}
									/>
								</div>

								<div className="modal__actions">
									<button
										id="pteverywhere-cancel-location"
										className={`mb-button mb-button--cancel ${this.state.loading ? "mb-button--disabled" : ""}`}
										disabled={this.state.loading}
										onClick={this.onCancel}
									>
										Cancel
									</button>

									<button
										id="pteverywhere-save-location"
										className={`mb-button ${this.state.loading ? "mb-button--disabled" : ""}`}
										disabled={this.state.loading}
										onClick={this.onSaveLocation}
									>
										Save
									</button>
								</div>
							</>
						)}
					</>
				</DHModal>

				<Alert type="success" show={showAlertTestSuccess} title="Connection Successful" confirm="OK" onClose={this.onConfirmTestSuccess}>
					The connection was successful! Please choose the location.
				</Alert>
				<Alert type="error" show={showAlertTestFailed} title="Connection Failed" confirm="OK" onClose={this.onConfirmTestFailed}>
					The connection failed. Please verify credentials and try again.
				</Alert>
				<Alert type="error" show={showAlertIntegrationNotFound} title="Not Found" confirm="OK" onClose={this.onConfirmIntegrationNotFound}>
					The PtEverywhere integration was not found.
				</Alert>
				<Alert type="error" show={showAlertNoLocationsFound} title="No Locations Found" confirm="OK" onClose={this.onConfirmNoLocationsFound}>
					No PtEverywhere locations were found.
				</Alert>
				<Alert type="error" show={showAlertLocationUpdateFailed} title="Location Failed" confirm="OK" onClose={this.onConfirmLocationUpdateFailed}>
					The location selection failed. Please try again.
				</Alert>
				<Alert type="success" show={showAlertConnectionComplete} title="Connection Complete" confirm="OK" onClose={this.onConfirmConnectionComplete}>
					The PtEverywhere connection is all set up.
				</Alert>
				<Alert type="info" show={showDisconnectModal} title={"Disconnect"} confirm={"Yes"} cancel={"No"} onClose={this.disconnectIntegration}>
					<div>{`Are you sure you would like to disconnect the integration?`}</div>
				</Alert>
			</>
		);
	}
}
export default ConnectPTEverywhereModal;
