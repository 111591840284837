import React from "react";
import PropTypes from "prop-types";

import "../../styles/css/components/commons/status-lights.css";

const StatusLights = ({ children, size, status }) => {
	return (
		<div className="demandhub__status-lights--box">
			<div className={`demandhub__status-lights--${status}`} style={{ height: `${size}px`, width: `${size}px` }}>
				{children}
			</div>
		</div>
	);
};

StatusLights.propTypes = {
	children: PropTypes.node,
	size: PropTypes.number.isRequired,
	status: PropTypes.oneOf(["safe", "warning", "danger", "info"])
};
export default StatusLights;
