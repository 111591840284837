import React, { Component } from "react";
import * as Icon from "react-feather";

import UserService from "../../services/UserService";
import LocationService from "../../services/LocationService";
import ToastService from "../../services/ToastService";

import Spinners from "../../components/common/Spinners";
import withLocation from "../../components/common/WithLocation";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Alert from "../../components/common/Alert";

import "../../styles/css/scenes/facebookIntegration.css";

class FacebookIntegration extends Component {
	constructor(props) {
		super(props);

		this.state = {
			status: "",
			longAccessToken: null,
			loginResponse: null,
			facebookPages: [],
			enabledFbPages: 0,
			loadingFbPages: 0,
			loading: false,
			selectedPage: null,
			showConfirmSubscribe: false,
			showConfirmUnsubscribe: false
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = async () => {
		await this.resetComponent();
	};

	resetComponent = async () => {
		await this.update({
			status: "",
			longAccessToken: null,
			loginResponse: null,
			facebookPages: [],
			enabledFbPages: 0,
			loadingFbPages: 0,
			loading: false,
			selectedPage: null,
			showConfirmSubscribe: false,
			showConfirmUnsubscribe: false
		});

		let { pagesResponse } = this.props;
		if (!pagesResponse) {
			return;
		}

		await this.update({
			longAccessToken: pagesResponse.longAccessToken,
			facebookPages: pagesResponse.pages,
			enabledFbPages: pagesResponse.enabledFbPages,
			loadingFbPages: pagesResponse.loadingFbPages
		});
	};

	/**
	 * Handle when the user clicks the subscribe button on a Facebook Page
	 *
	 * @param {Object} pageObject
	 */
	handlePageSubscribe = async pageObject => {
		const { longAccessToken } = this.state;

		let facebookPages = this.loadingPageSubscription(pageObject.id, true);
		await this.update({
			facebookPages,
			loadingFbPages: facebookPages.filter(p => p.loading).length
		});

		let locationId = UserService.getActiveLocation().id;
		let response = await LocationService.subscribeToFacebookPage({
			userAccessToken: longAccessToken,
			pageAccessToken: pageObject.access_token,
			pageId: pageObject.id,
			locationId: locationId,
			properties: pageObject
		});

		if (response === null) {
			ToastService.error("An error occurred trying to subscribe");
			this.loadingPageSubscription(pageObject.id, false);
			await this.update({
				enabledFbPages: this.state.facebookPages.filter(
					p => p.integrations && p.integrations.facebook.status === "active" && p.integrations.facebook.location_id === locationId
				).length,
				loadingFbPages: this.state.facebookPages.filter(p => p.loading).length,
				showConfirmUnsubscribe: false,
				showConfirmSubscribe: false,
				selectedPage: null
			});
			return;
		}

		facebookPages = this.updatePageSubscription(pageObject.id, true);
		await this.update({
			facebookPages,
			enabledFbPages: facebookPages.filter(
				p => p.integrations && p.integrations.facebook.status === "active" && p.integrations.facebook.location_id === locationId
			).length,
			loadingFbPages: facebookPages.filter(p => p.loading).length,
			showConfirmUnsubscribe: false,
			showConfirmSubscribe: false,
			selectedPage: null
		});
	};

	/**
	 *
	 *
	 * @param  {[type]} pageId  [description]
	 * @param  {[type]} loading [description]
	 * @return {[type]}         [description]
	 */
	loadingPageSubscription(pageId, loading) {
		return this.state.facebookPages.map(function(page) {
			if (page.id === pageId) {
				page.loading = loading;
			}

			return page;
		});
	}

	updatePageSubscription(pageId, subscribed) {
		return this.state.facebookPages.map(function(page) {
			if (page.id === pageId) {
				if (!page.integrations) {
					page.integrations = { facebook: null };
				}
				page.integrations.facebook.status = subscribed ? "active" : null;
				page.integrations.facebook.location_id = subscribed ? UserService.getActiveLocation().id : null;
				page.is_webhooks_subscribed = subscribed;
				page.loading = false;
			}

			return page;
		});
	}

	/**
	 * Handle when the user clicks the unsubscribe button on a Facebook Page
	 *
	 * @param {Object} pageObject
	 */
	handlePageUnsubscribe = async pageObject => {
		const { longAccessToken } = this.state;

		let facebookPages = this.loadingPageSubscription(pageObject.id, true);
		await this.update({
			facebookPages,
			loadingFbPages: facebookPages.filter(p => p.loading).length
		});

		let locationId = UserService.getActiveLocation().id;
		let response = await LocationService.unsubscribeFromFacebookPage({
			userAccessToken: longAccessToken,
			pageAccessToken: pageObject.access_token,
			pageId: pageObject.id,
			locationId
		});

		if (response === null) {
			ToastService.error("An error occurred trying to unsubscribe");
			this.loadingPageSubscription(pageObject.id, false);
			await this.update({
				enabledFbPages: this.state.facebookPages.filter(
					p => p.integrations && p.integrations.facebook.status === "active" && p.integrations.facebook.location_id === locationId
				).length,
				loadingFbPages: this.state.facebookPages.filter(p => p.loading).length,
				showConfirmUnsubscribe: false,
				showConfirmSubscribe: false,
				selectedPage: null
			});

			return;
		}

		facebookPages = this.updatePageSubscription(pageObject.id, false);
		await this.update({
			facebookPages,
			enabledFbPages: facebookPages.filter(
				p => p.integrations && p.integrations.facebook.status === "active" && p.integrations.facebook.location_id === locationId
			).length,
			loadingFbPages: facebookPages.filter(p => p.loading).length,
			showConfirmUnsubscribe: false,
			showConfirmSubscribe: false,
			selectedPage: null
		});
	};

	renderPageSubscribe = (page, isFacebookEnabled, canSubscribe, canUnsubscribe) => {
		if (page.loading) {
			return (
				<div className="fbi__page-subscribe fbi__page-subscribe--loading">
					<Spinners loading={true} type="circle-fade" size="6px" />;
				</div>
			);
		}

		return (
			<div className="fbi__page-subscribe">
				{isFacebookEnabled ? (
					<button
						className={`mb-button ${!canUnsubscribe ? "mb-button--disabled" : ""}`}
						onClick={() => {
							if (!canUnsubscribe) {
								return;
							}
							this.update({ showConfirmUnsubscribe: true, selectedPage: page });
						}}
					>
						Unsubscribe
					</button>
				) : (
					<button
						className={`mb-button ${!canSubscribe ? "mb-button--disabled" : ""}`}
						onClick={() => {
							if (!canSubscribe) {
								return;
							}
							this.update({ showConfirmSubscribe: true, selectedPage: page });
						}}
					>
						Subscribe
					</button>
				)}
			</div>
		);
	};

	renderPage = (page, index) => {
		let locationId = UserService.getActiveLocation().id;
		let { enabledFbPages, loadingFbPages } = this.state;
		let canUnsubscribe = false;
		let canSubscribe = false;
		let showLocationDetails = false;
		let isFacebookEnabled = false;
		let isInstagramEnabled = false;

		if (page.integrations && page.integrations.facebook && typeof page.integrations.facebook.status !== "undefined") {
			isFacebookEnabled = page.integrations.facebook.status === "active";
		}

		if (isFacebookEnabled && page.integrations && page.integrations.facebook && page.integrations.facebook.location_id === locationId && loadingFbPages < 1) {
			canUnsubscribe = true;
		}

		if (!isFacebookEnabled && page.integrations && page.integrations.facebook && page.integrations.facebook.location_id === null) {
			canSubscribe = true;
		}

		if (page.integrations && page.integrations.instagram && page.integrations.instagram.status === "active") {
			isInstagramEnabled = true;
		}

		if (isFacebookEnabled && page.integrations.facebook.location_id !== locationId) {
			showLocationDetails = true;
		}

		return (
			<div className="fbi__page" key={index}>
				<div className="fbi__page__content">
					<div className="fbi__page__content__icon">
						<img src={page.picture.data.url} alt="facebook-icon" />
					</div>
					<div className="fbi__page__content__title">
						<div className="fbi__page__content__title__text">{page.name}</div>
						<div>
							{isFacebookEnabled && <Icon.Facebook size={16} />}
							{isInstagramEnabled && <Icon.Instagram size={16} />}
						</div>
					</div>
					{this.renderPageSubscribe(page, isFacebookEnabled, canSubscribe, canUnsubscribe)}
				</div>

				{showLocationDetails && (
					<div className="fbi__page__subd-to">
						Subscribed to:
						{UserService.isSuperAdminOrCustomerSuccess() && page.integrations ? `${page.integrations.facebook.location_id} - ` : null}
						{page.integrations ? page.integrations.facebook.location_name : "Another DemandHub location."}
					</div>
				)}
			</div>
		);
	};

	renderPages = () => {
		return <div className="fbi__pages">{this.state.facebookPages.map((page, index) => this.renderPage(page, index))}</div>;
	};

	render() {
		let { selectedPage } = this.state;
		return (
			<div className="fbi__content">
				<div className={"fbi__pages-block "}>
					<div className="fbi__pages-label">By subscribing your page, you are allowing DemandHub to send and receive messages through your Facebook Page.</div>
					<div className="fbi__pages-label">
						<strong>Pages</strong>
					</div>
					{this.renderPages()}
				</div>

				<Alert
					type="info"
					show={this.state.showConfirmSubscribe && selectedPage}
					title="Confirm Subscription"
					confirm="Yes"
					cancel="Cancel"
					onClose={confirmed => {
						if (confirmed) {
							this.handlePageSubscribe(selectedPage);
						}
						this.update({ showConfirmSubscribe: false });
					}}
				>
					<div>Are you sure want to subscribe to {selectedPage ? selectedPage.name : "page"}?</div>
				</Alert>

				<Alert
					type="info"
					show={this.state.showConfirmUnsubscribe && selectedPage}
					title="Confirm Unsubscribe"
					confirm="Yes"
					cancel="Cancel"
					onClose={confirmed => {
						if (confirmed) {
							this.handlePageUnsubscribe(selectedPage);
						}
						this.update({ showConfirmUnsubscribe: false });
					}}
				>
					<div>Are you sure want to unsubscribe {selectedPage ? selectedPage.name : "page"}?</div>
				</Alert>
			</div>
		);
	}
}

export default withLocation(FacebookIntegration);
