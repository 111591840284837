import LocalizedStrings from "react-localization";
import englishCanada from "./languages/en_CA";
import englishUS from "./languages/en_US";

const localization = new LocalizedStrings({
	en_CA: englishCanada,
	en_US: englishUS
});

export default localization;
