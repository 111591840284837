export const GBM = {
	interactionType: {
		bot: "BOT",
		human: "HUMAN"
	},
	language: {
		en: "en",
		fr: "fr"
	},
	entryPoint: {
		nonLocal: "NON_LOCAL",
		location: "LOCATION"
	},
	regions: {
		us: "US",
		ca: "CA"
	},
	conversationStarterSuggestion: {
		reply: "reply",
		action: "action"
	},
	agentLogo: {
		sizeLimit: 50000 // 50KB
	},
	nonLocalConfig: {
		contactOptions: {
			webChat: "WEB_CHAT",
			phone: "PHONE",
			email: "EMAIL",
			faqs: "FAQS",
			twitter: "TWITTER",
			whatsapp: "WHATSAPP"
		}
	},
	testUrlSurface: {
		SURFACE_ANDROID_WEB: "Android Web",
		SURFACE_ANDROID_MAPS: "Android Maps",
		SURFACE_IOS_MAPS: "IOS Maps"
	},
	verificationState: {
		VERIFICATION_STATE_UNSPECIFIED: {
			id: "VERIFICATION_STATE_UNSPECIFIED",
			description: "Unspecified state."
		},
		VERIFICATION_STATE_UNVERIFIED: {
			id: "VERIFICATION_STATE_UNVERIFIED",
			description: "Unverified state."
		},
		VERIFICATION_STATE_PENDING: {
			id: "VERIFICATION_STATE_PENDING",
			description: "Verification in review."
		},
		VERIFICATION_STATE_VERIFIED: {
			id: "VERIFICATION_STATE_VERIFIED",
			description: "Verification complete."
		},
		VERIFICATION_STATE_SUSPENDED_IN_GMB: {
			id: "VERIFICATION_STATE_SUSPENDED_IN_GMB",
			description:
				"Indicates the associated Google My Business listing is no longer verified, a requirement for verification in Business Communications. Reverifying in Google My Business automatically reverifies here. Only applicable for locations."
		}
	},
	launchState: {
		LAUNCH_STATE_UNSPECIFIED: {
			id: "LAUNCH_STATE_UNSPECIFIED",
			description: "Unspecified state."
		},
		LAUNCH_STATE_UNLAUNCHED: {
			id: "LAUNCH_STATE_UNLAUNCHED",
			description: "Entity is unlaunched."
		},
		LAUNCH_STATE_PENDING: {
			id: "LAUNCH_STATE_PENDING",
			description: "Launch in review."
		},
		LAUNCH_STATE_LAUNCHED: {
			id: "LAUNCH_STATE_LAUNCHED",
			description: "Launched."
		},
		LAUNCH_STATE_REJECTED: {
			id: "LAUNCH_STATE_REJECTED",
			description: "Launch is rejected."
		},
		LAUNCH_STATE_SUSPENDED: {
			id: "LAUNCH_STATE_SUSPENDED",
			description: "Launch is suspended."
		},
		LAUNCH_STATE_PENDING_UNLAUNCH: {
			id: "LAUNCH_STATE_PENDING_UNLAUNCH",
			description: "Unlaunch in review."
		},
		LAUNCH_STATE_INVALID_IN_GMB: {
			id: "LAUNCH_STATE_INVALID_IN_GMB",
			description:
				"Launch is invalid because the associated Google My Business Listing doesn't support messaging. Reverifying in Google My Business automatically relaunches here. Only applicable for locations."
		}
	}
};

export const GBM_LANGUAGE_OPTIONS = [
	{ value: GBM.language.en, label: "English" },
	{ value: GBM.language.fr, label: "French" }
];

export const GBM_ENTRY_POINT_OPTIONS = [
	{ value: GBM.entryPoint.location, label: "Location" },
	{ value: GBM.entryPoint.nonLocal, label: "Non Local" }
];

// NL = Non Local
export const GBM_NL_CONTACT_OPTIONS = [
	{ value: "WEB_CHAT", label: "WebChat" },
	{ value: "PHONE", label: "Phone" },
	{ value: "EMAIL", label: "Email" },
	{ value: "FAQS", label: "FAQS" },
	{ value: "TWITTER", label: "Twitter" },
	{ value: "WHATSAPP", label: "Whatsapp" }
];

export const GBM_CONVERSATION_STARTER_SUGGESTION_OPTIONS = [
	{ value: GBM.conversationStarterSuggestion.reply, label: "Reply" },
	{ value: GBM.conversationStarterSuggestion.action, label: "Action" }
];

export const GBM_DEFAULTS = {
	privacyPolicyUrl: "https://www.demandhub.co/privacy/",
	welcomeMessage: locationName => `Thanks for reaching ${locationName}. How can we help you today?`
};
