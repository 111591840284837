export const PASSWORD_REQUIREMENTS_GENERATOR = {
	characters: {
		label: function(expected) {
			if (expected > 1) {
				return `Password has at least ${expected} characters`;
			}
			return `Password has at least a single character`;
		},
		test: function(value, expected) {
			return value.length >= expected;
		}
	},
	uppercase: {
		label: function(expected) {
			if (expected > 1) {
				return `Password has at least ${expected} uppercase letters`;
			}
			return `Password has an uppercase letter`;
		},
		test: function(value, expected) {
			return (value.match(/[A-Z]/g) || []).length >= expected;
		}
	},
	lowercase: {
		label: function(expected) {
			if (expected > 1) {
				return `Password has at least ${expected} lowercase letters`;
			}
			return `Password has a lowercase letter`;
		},
		test: function(value, expected) {
			return (value.match(/[a-z]/g) || []).length >= expected;
		}
	},
	number: {
		label: function(expected) {
			if (expected > 1) {
				return `Password has at least ${expected} numbers`;
			}
			return `Password has a number`;
		},
		test: function(value, expected) {
			return value.replace(/\D/g, "").length >= expected;
		}
	},
	special: {
		label: function(expected) {
			if (expected > 1) {
				return `Password has at least ${expected} special characters`;
			}
			return `Password has a special character`;
		},
		test: function(value, expected) {
			return (value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/gi) || []).length >= expected;
		}
	}
};

export const USER_PASSWORD_REQUIREMENTS = { characters: 8, uppercase: 1, lowercase: 1, number: 1, special: 1 };
