import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Select from "react-select";

import * as Icon from "react-feather";

import Action from "../../../components/common/Action";
import Spinners from "../../../components/common/Spinners";
import withLocation from "../../../components/common/WithLocation";
import Alert from "../../../components/common/Alert";

import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";
import ToastService from "../../../services/ToastService";
import LocationService from "../../../services/LocationService";

import { ISO_COUNTRIES } from "../../../constants/Countries";
import {
	TWILIO_A2P_BRAND_STATUSES,
	TWILIO_A2P_BUSINESS_INDUSTRY,
	TWILIO_A2P_BUSINESS_REGISTRATION_NUMBERS,
	TWILIO_A2P_BUSINESS_TYPES,
	TWILIO_A2P_CAMPAIGN_STATUSES,
	TWILIO_A2P_COMPANY_TYPE,
	TWILIO_A2P_CONTACT_POSITIONS,
	TWILIO_A2P_REGIONS_OF_OPERATION
} from "../../../constants/LocationConstants";

import "../../../styles/css/components/commons/dh-details.css";
import "../../../styles/css/scenes/twilioA2P.css";

class TwilioA2P extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			/* Component State Data */
			a2pMetaData: {},
			provider: "twilio",
			fetchingMetaData: false,
			submitting: false,
			showMetaData: false,
			showConfirmSubmitAlert: false,
			refreshTimer: null,

			/* Submission States Data */
			a2pSubmissionStatusText: "",
			a2pSubmissionWaiting: false,
			a2pComplete: false,

			/* A2P Submission Data: Location Name */
			locationName: "",

			/* A2P Submission Data: Contact */
			contactFirstName: "",
			contactLastName: "",
			contactEmail: "",
			contactPhone: "",
			contactBusinessTitle: "",
			contactPosition: "",

			/* A2P Submission Data: Address */
			street: "",
			city: "",
			region: "",
			postalCode: "",
			isoCountry: "",

			/* A2P Submission Data: Business Profile */
			businessWebsiteUrl: "",
			businessRegionsOfOperation: "",
			businessType: "",
			businessIndustry: "",
			businessRegistrationIdentifier: "",
			businessRegistrationNumber: "",

			/* A2P Submission Data: Company Profile */
			companyType: "",
			companyStockExchangeListed: "",
			companyStockTiker: "",

			/* A2P Completion Information */
			accountId: "",
			messagingServiceId: "",
			brandId: "",
			brandStatus: "",
			campaignId: "",
			campaignStatus: "",
			tcrId: "",
			dateSubmitted: ""
		};

		this.submissionInformationDom = null;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	resetComponent = async () => {
		// Clear any update polling that may be waiting to execute
		this.clearPolling();

		await this.update({
			/* Component State Data */
			a2pMetaData: {},
			provider: "twilio",
			fetchingMetaData: false,
			submitting: false,
			showMetaData: false,
			showConfirmSubmitAlert: false,
			refreshTimer: null,

			/* Submission States Data */
			a2pSubmissionStatusText: "",
			a2pSubmissionWaiting: false,
			a2pComplete: false,

			/* A2P Submission Data: Location Name */
			locationName: "",

			/* A2P Submission Data: Contact */
			contactFirstName: "",
			contactLastName: "",
			contactEmail: "",
			contactPhone: "",
			contactBusinessTitle: "",
			contactPosition: "",

			/* A2P Submission Data: Address */
			street: "",
			city: "",
			region: "",
			postalCode: "",
			isoCountry: "",

			/* A2P Submission Data: Business Profile */
			businessWebsiteUrl: "",
			businessRegionsOfOperation: "",
			businessType: "",
			businessIndustry: "",
			businessRegistrationIdentifier: "",
			businessRegistrationNumber: "",

			/* A2P Submission Data: Company Profile */
			companyType: "",
			companyStockExchangeListed: "",
			companyStockTiker: "",

			/* A2P Completion Information */
			accountId: "",
			messagingServiceId: "",
			brandId: "",
			brandStatus: "",
			campaignId: "",
			campaignStatus: "",
			tcrId: "",
			dateSubmitted: ""
		});

		await this.fetchMetaData();
		await this.prefillWithLocationData();
	};

	onLocationChanged = async () => {
		await this.resetComponent();
	};

	componentWillUnmount = () => {
		this.clearPolling();
	};

	prefillWithLocationData = async () => {
		let { a2pSubmissionWaiting, a2pComplete } = this.state;
		let location = UserService.getActiveLocation();

		// If we are in the middle of a submission or are complete
		// we don't need to pre fill anything
		if (a2pSubmissionWaiting || a2pComplete) {
			return;
		}

		// Find the country and set the iso value
		let isoCountryObject = ISO_COUNTRIES.find(theCountry => theCountry.label === location.country);
		let isoCountry = typeof isoCountryObject === "undefined" ? "Unknown Country" : isoCountryObject.value;

		await this.update({
			locationName: location.name,
			contactEmail: location.email,
			contactPhone: location.phone,
			street: location.address_1,
			city: location.city,
			region: location.state_prov,
			postalCode: location.zip_postal,
			isoCountry: isoCountry
		});
	};

	fetchMetaData = async () => {
		await this.update({ fetchingMetaData: true });

		try {
			let { provider } = this.state;
			let locationId = UserService.getActiveLocation().id;

			let a2pMetaData = await LocationService.getA2PMetaData({ locationId, provider });

			if (!a2pMetaData) {
				throw Error("Error fetching a2p metadata");
			}

			await this.update({
				fetchingMetaData: false,
				a2pMetaData
			});

			// Process the metadata
			await this.processUpdatedA2pMetadata();

			// If we need to poll for a a2p status update
			if (this.state.a2pSubmissionWaiting) {
				this.pollUpdate();
			}
		} catch (error) {
			this.update({ fetchingMetaData: false });
			ToastService.error("Sorry, not able to fetch a2p meta data. Please try again.");
			console.log(error);
		}
	};

	// After five seconds, fetch fresh metadata
	pollUpdate = () => {
		let { refreshTimer } = this.state;

		refreshTimer = setTimeout(() => {
			this.fetchMetaData();
		}, 5000);

		this.update({ refreshTimer });
	};

	clearPolling = () => {
		let { refreshTimer } = this.state;
		if (refreshTimer) {
			clearTimeout(refreshTimer);
		}
	};

	processUpdatedA2pMetadata = async () => {
		try {
			let { a2pMetaData } = this.state;

			// Get both partitions of the metadata
			let mockPartition = a2pMetaData.mock;
			let realPartition = a2pMetaData.real;

			// If there is no mock parition or real, then there has never been a submission before
			if (!mockPartition && !realPartition) {
				await this.update({
					a2pSubmissionStatusText: `There are currently no a2p submissions on this location.`,
					a2pSubmissionWaiting: false
				});

				return;
			}

			// Get the brand statuses
			let mockBrandStatus = mockPartition && mockPartition.brand && mockPartition.brand.brandSubmissionStatus && mockPartition.brand.brandSubmissionStatus;
			let realBrandStatus = realPartition && realPartition.brand && realPartition.brand.brandSubmissionStatus && realPartition.brand.brandSubmissionStatus;

			// Get the campaign statuses
			let mockCampaignStatus = mockPartition && mockPartition.campaign && mockPartition.campaign.campaignStatus && mockPartition.campaign.campaignStatus;
			let realCampaignStatus = realPartition && realPartition.campaign && realPartition.campaign.campaignStatus && realPartition.campaign.campaignStatus;

			// Set text friendly statuses so they are not displayed as undefined
			mockBrandStatus = mockBrandStatus || "n/a";
			realBrandStatus = realBrandStatus || "n/a";
			mockCampaignStatus = mockCampaignStatus || "n/a";
			realCampaignStatus = realCampaignStatus || "n/a";

			//
			// Check if the brand submission statuses are pending or failed
			//

			// If the mock or real brand submission is pending
			if (mockBrandStatus === TWILIO_A2P_BRAND_STATUSES.pending || realBrandStatus === TWILIO_A2P_BRAND_STATUSES.pending) {
				await this.update({
					a2pSubmissionStatusText: `We are waiting for results of the brand submission. Mock Brand: ${mockBrandStatus}. Real Brand: ${realBrandStatus}`,
					a2pSubmissionWaiting: true
				});

				return;
			}

			// If the mock of real brand submission failed
			if (mockBrandStatus === TWILIO_A2P_BRAND_STATUSES.failed || realBrandStatus === TWILIO_A2P_BRAND_STATUSES.failed) {
				let failureReason = "";

				if (mockBrandStatus === TWILIO_A2P_BRAND_STATUSES.failed) {
					failureReason = mockPartition.brand.brandSubmissionFailureReason;
				} else if (realBrandStatus === TWILIO_A2P_BRAND_STATUSES.failed) {
					failureReason = realPartition.brand.brandSubmissionFailureReason;
				}

				await this.update({
					a2pSubmissionStatusText: `The brand submission failed. Reason: ${failureReason}. Mock Brand: ${mockBrandStatus}. Real Brand: ${realBrandStatus}.`,
					a2pSubmissionWaiting: false
				});

				return;
			}

			//
			// Check if the campaign submission statuses are pending or failed
			//

			// If the mock or real brand submission is pending
			if (mockCampaignStatus === TWILIO_A2P_CAMPAIGN_STATUSES.inProgress || realCampaignStatus === TWILIO_A2P_CAMPAIGN_STATUSES.inProgress) {
				await this.update({
					a2pSubmissionStatusText: `We are waiting for results of the campaign submission. Mock Campaign: ${mockCampaignStatus}. Real Campaign: ${realCampaignStatus}`,
					a2pSubmissionWaiting: true
				});

				return;
			}

			// If the mock of real brand submission failed
			if (mockCampaignStatus === TWILIO_A2P_CAMPAIGN_STATUSES.failed || realCampaignStatus === TWILIO_A2P_CAMPAIGN_STATUSES.failed) {
				let failureReason = "Please check twilio's console for the reason.";

				await this.update({
					a2pSubmissionStatusText: `The campaign submission failed. Reason: ${failureReason}. Mock Campaign: ${mockCampaignStatus}. Real Campaign: ${realCampaignStatus}.`,
					a2pSubmissionWaiting: false
				});

				return;
			}

			//
			// Check if a real submission was approved
			//

			// If everything went well
			if (realBrandStatus === TWILIO_A2P_BRAND_STATUSES.approved && realCampaignStatus === TWILIO_A2P_CAMPAIGN_STATUSES.verified) {
				// Update the state of the component
				await this.update({
					a2pSubmissionStatusText: "Your location is fully A2P compliant.",
					a2pComplete: true,
					a2pSubmissionWaiting: false
				});

				let realRawSubmissionSection = realPartition.dhInformation.rawSubmissionData;

				// Update the submission data based on the real submission
				await this.update({
					/* A2P Submission Data: Location Name */
					locationName: realRawSubmissionSection ? realRawSubmissionSection.locationName : "",

					/* A2P Submission Data: Contact */
					contactFirstName: realRawSubmissionSection ? realRawSubmissionSection.contactFirstName : "",
					contactLastName: realRawSubmissionSection ? realRawSubmissionSection.contactLastName : "",
					contactEmail: realRawSubmissionSection ? realRawSubmissionSection.contactEmail : "",
					contactPhone: realRawSubmissionSection ? realRawSubmissionSection.contactPhone : "",
					contactBusinessTitle: realRawSubmissionSection ? realRawSubmissionSection.contactBusinessTitle : "",
					contactPosition: realRawSubmissionSection ? realRawSubmissionSection.contactPosition : "",

					/* A2P Submission Data: Address */
					street: realRawSubmissionSection ? realRawSubmissionSection.street : "",
					city: realRawSubmissionSection ? realRawSubmissionSection.city : "",
					region: realRawSubmissionSection ? realRawSubmissionSection.region : "",
					postalCode: realRawSubmissionSection ? realRawSubmissionSection.postalCode : "",
					isoCountry: realRawSubmissionSection ? realRawSubmissionSection.isoCountry : "",

					/* A2P Submission Data: Business Profile */
					businessWebsiteUrl: realRawSubmissionSection ? realRawSubmissionSection.businessWebsiteUrl : "",
					businessRegionsOfOperation: realRawSubmissionSection ? realRawSubmissionSection.businessRegionsOfOperation : "",
					businessType: realRawSubmissionSection ? realRawSubmissionSection.businessType : "",
					businessIndustry: realRawSubmissionSection ? realRawSubmissionSection.businessIndustry : "",
					businessRegistrationIdentifier: realRawSubmissionSection ? realRawSubmissionSection.businessRegistrationIdentifier : "",
					businessRegistrationNumber: realRawSubmissionSection ? realRawSubmissionSection.businessRegistrationNumber : "",

					/* A2P Submission Data: Company Profile */
					companyType: realRawSubmissionSection ? realRawSubmissionSection.companyType : "",
					companyStockExchangeListed: realRawSubmissionSection ? realRawSubmissionSection.companyStockExchangeListed : "",
					companyStockTiker: realRawSubmissionSection ? realRawSubmissionSection.companyStockTiker : ""
				});

				let brandSection = realPartition.brand;
				let campaignSection = realPartition.campaign;

				// Update the submission a2p specific data based on the real submission
				await this.update({
					accountId: campaignSection ? campaignSection.accountId : "",
					messagingServiceId: campaignSection ? campaignSection.messagingServiceId : "",
					brandId: brandSection ? brandSection.brandId : "",
					brandStatus: brandSection ? brandSection.brandSubmissionStatus : "",
					campaignId: campaignSection ? campaignSection.campaignId : "",
					campaignStatus: campaignSection ? campaignSection.campaignStatus : "",
					tcrId: brandSection ? brandSection.tcrId : "",
					dateSubmitted: campaignSection ? campaignSection.dateCreated : ""
				});

				return;
			}

			// We may be in the grey space between finishing a mock submission and starting a real one
			if (mockBrandStatus === TWILIO_A2P_BRAND_STATUSES.approved) {
				await this.update({
					a2pSubmissionStatusText: `The mock brand submission completed succesfully. Waiting for real submission to change brand/campaign state.`,
					a2pSubmissionWaiting: true
				});

				return;
			}

			// If we got this far, there are no pending submissions and no failed submissions.
			await this.update({
				a2pSubmissionStatusText: `There are currently no pending submissions. NOTE: If you are resubmitting, hit the refresh button to re-sync.`,
				a2pSubmissionWaiting: false
			});
		} catch (error) {
			ToastService.error("Error updating a2p status.");
			console.log(error);
		}
	};

	// For <input> changes
	onChangeInput = (fieldName, value) => {
		this.update({
			[fieldName]: value
		});
	};

	handleOnSubmitButtonPress = () => {
		this.update({ showConfirmSubmitAlert: true });
	};

	handleOnResetButtonPress = () => {
		this.update({ showConfirmResetAlert: true });
	};

	handleOnRefreshButtonPress = async () => {
		await this.resetComponent();
	};

	areInputsDisabled = () => {
		let { fetchingMetaData, a2pSubmissionWaiting, a2pComplete } = this.state;
		return fetchingMetaData || a2pSubmissionWaiting || a2pComplete;
	};

	onToggleShowMetadata = () => {
		let { showMetaData } = this.state;
		this.update({ showMetaData: !showMetaData });
	};

	scrollToInformationSection = () => {
		if (this.submissionInformationDom) {
			this.submissionInformationDom.scrollIntoView({ behavior: "smooth" });
		}
	};

	resetA2PSubmission = async () => {
		try {
			let { provider } = this.state;
			let locationId = UserService.getActiveLocation().id;

			let response = await LocationService.resetA2PSubmission({ locationId, provider });

			if (!response) {
				ToastService.error("Error resetting a2p submission.");
			} else {
				ToastService.info("The previous A2P submission was reset.");
			}

			await this.resetComponent();
		} catch (error) {
			ToastService.error("Error resetting a2p submission.");
			console.log(error);
		}
	};

	onSubmit = async () => {
		try {
			/* Component State Data */
			let { provider } = this.state;

			/* A2P Submission Data: Location Name */
			let { locationName } = this.state;

			/* A2P Submission Data: Contact */
			let { contactFirstName, contactLastName, contactEmail, contactPhone, contactBusinessTitle, contactPosition } = this.state;

			/* A2P Submission Data: Address */
			let { street, city, region, postalCode, isoCountry } = this.state;

			/* A2P Submission Data: Business Profile */
			let {
				businessWebsiteUrl,
				businessRegionsOfOperation,
				businessType,
				businessIndustry,
				businessRegistrationIdentifier,
				businessRegistrationNumber
			} = this.state;

			/* A2P Submission Data: Company Profile */
			let { companyType, companyStockExchangeListed, companyStockTiker } = this.state;

			// Declare location id
			let locationId = UserService.getActiveLocation().id;

			// Declare the submission data
			let submissionData = {
				/* A2P Submission Data: Location Name */
				locationName,

				/* A2P Submission Data: Contact */
				contactFirstName,
				contactLastName,
				contactEmail,
				contactPhone,
				contactBusinessTitle,
				contactPosition,

				/* A2P Submission Data: Address */
				street,
				city,
				region,
				postalCode,
				isoCountry,

				/* A2P Submission Data: Business Profile */
				businessWebsiteUrl,
				businessRegionsOfOperation,
				businessType,
				businessIndustry,
				businessRegistrationIdentifier,
				businessRegistrationNumber,

				/* A2P Submission Data: Company Profile */
				companyType,
				companyStockExchangeListed,
				companyStockTiker
			};

			// Make sure that there is a plus on the number
			if (!submissionData.contactPhone.startsWith("+")) {
				ToastService.error("Phone number missing +[country code]");
				return;
			}

			// Scroll to the top of the page where information will be displayed
			await this.scrollToInformationSection();

			// Set the component to currently submit the data
			await this.update({
				a2pSubmissionStatusText: `Currently submitting a2p campaign...`,
				a2pSubmissionWaiting: true
			});

			// Create a mock submission
			let response = await LocationService.submitA2P({ locationId, mock: true, provider, submissionData });

			// If response has an error
			if (!response || response.error) {
				await this.update({
					a2pSubmissionStatusText: `An immediate error occured with the submission: Error: ${response.error}`,
					a2pSubmissionWaiting: false
				});

				ToastService.error("An error occured submitting an a2p application.");
				return;
			}

			await this.update({
				a2pSubmissionStatusText: `The application was succesfully submitted. Please wait a few minutes for the results.`
			});

			// Poll for an update after a certain amount of time
			this.pollUpdate();
		} catch (error) {
			ToastService.error("An error occured submitting an a2p application.");
			console.log(error);
		}
	};

	renderCompletionSummary() {
		/* A2P Complete Information */
		let { accountId, messagingServiceId, brandId, brandStatus, campaignId, campaignStatus, tcrId, dateSubmitted } = this.state;

		return (
			<>
				<div className="dh-details-item">
					<label>Account ID</label>
					<div>{accountId}</div>
				</div>
				<div className="dh-details-item">
					<label>Messaging Service ID</label>
					<div>{messagingServiceId}</div>
				</div>
				<div className="dh-details-item">
					<label>Brand ID</label>
					<div>{brandId}</div>
				</div>
				<div className="dh-details-item">
					<label>Brand Status</label>
					<div>{brandStatus}</div>
				</div>
				<div className="dh-details-item">
					<label>Campaign ID</label>
					<div>{campaignId}</div>
				</div>
				<div className="dh-details-item">
					<label>Campaign Status</label>
					<div>{campaignStatus}</div>
				</div>
				<div className="dh-details-item">
					<label>TCR ID</label>
					<div>{tcrId}</div>
				</div>
				<div className="dh-details-item">
					<label>Date Submitted</label>
					<div>{moment(dateSubmitted).format("MMMM Do YYYY, h:mm A")}</div>
				</div>
			</>
		);
	}

	renderLocationInformation() {
		/* Submission States Data */
		let { a2pComplete } = this.state;

		/* A2P Submission Data: Location Name */
		let { locationName } = this.state;

		let inputsDisabled = this.areInputsDisabled();

		return (
			<>
				<div className="dh-details-title">Location Information</div>

				<div className="dh-details-item">
					<label>Location Name</label>
					{!a2pComplete ? (
						<input
							name="location_name"
							id="location_name"
							onChange={e => this.onChangeInput("locationName", e.target.value)}
							type="text"
							value={locationName}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{locationName}</div>
					)}
				</div>
			</>
		);
	}

	renderAddressInformation() {
		/* Submission States Data */
		let { a2pComplete } = this.state;

		/* A2P Submission Data: Address */
		let { street, city, region, postalCode, isoCountry } = this.state;

		let inputsDisabled = this.areInputsDisabled();

		// Find the country name from the country iso code
		let isoCountryLabel = ISO_COUNTRIES.find(theCountry => theCountry.value === isoCountry);
		isoCountryLabel = typeof isoCountryLabel === "undefined" ? "Unknown Country" : isoCountryLabel.label;

		return (
			<>
				<div className="dh-details-title">Address Information</div>

				<div className="dh-details-item">
					<label>Street</label>
					{!a2pComplete ? (
						<input
							name="location_street"
							id="location_street"
							onChange={e => this.onChangeInput("street", e.target.value)}
							type="text"
							value={street}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{street}</div>
					)}
				</div>
				<div className="dh-details-item">
					<label>City</label>
					{!a2pComplete ? (
						<input
							name="location_city"
							id="location_city"
							onChange={e => this.onChangeInput("city", e.target.value)}
							type="text"
							value={city}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{city}</div>
					)}
				</div>
				<div className="dh-details-item">
					<label>Region (Province or State)</label>
					{!a2pComplete ? (
						<input
							name="location_region"
							id="location_region"
							onChange={e => this.onChangeInput("region", e.target.value)}
							type="text"
							value={region}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{region}</div>
					)}
				</div>
				<div className="dh-details-item">
					<label>Postal Code (or ZIP)</label>
					{!a2pComplete ? (
						<input
							name="location_postalcode"
							id="location_postalcode"
							onChange={e => this.onChangeInput("postalCode", e.target.value)}
							type="text"
							value={postalCode}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{postalCode}</div>
					)}
				</div>
				<div className="dh-details-item">
					<label>Country</label>
					{!a2pComplete ? (
						<Select
							id="location_country"
							name="location_country"
							options={ISO_COUNTRIES}
							value={{ label: isoCountryLabel, value: isoCountry }}
							placeholder="ISO Country"
							onChange={option => {
								this.onChangeInput("isoCountry", option.value);
							}}
							isDisabled={inputsDisabled}
						/>
					) : (
						<div>{isoCountry}</div>
					)}
				</div>
			</>
		);
	}

	renderContactProfile() {
		/* Submission States Data */
		let { a2pComplete } = this.state;

		/* A2P Submission Data: Contact */
		let { contactFirstName, contactLastName, contactEmail, contactPhone, contactBusinessTitle, contactPosition } = this.state;

		let inputsDisabled = this.areInputsDisabled();

		// Find the contact position
		let contactPositionLabel = TWILIO_A2P_CONTACT_POSITIONS.find(theContactPosition => theContactPosition.value === contactPosition);
		contactPositionLabel = typeof contactPositionLabel === "undefined" ? "Unknown Contact Position" : contactPositionLabel.label;

		return (
			<>
				<div className="dh-details-title">Contact Profile</div>

				<div className="dh-details-item">
					<label>Contact First Name</label>
					{!a2pComplete ? (
						<input
							name="contact_first_name"
							id="contact_first_name"
							onChange={e => this.onChangeInput("contactFirstName", e.target.value)}
							type="text"
							value={contactFirstName}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{contactFirstName}</div>
					)}
				</div>
				<div className="dh-details-item">
					<label>Contact Last Name</label>
					{!a2pComplete ? (
						<input
							name="contact_last_name"
							id="contact_last_name"
							onChange={e => this.onChangeInput("contactLastName", e.target.value)}
							type="text"
							value={contactLastName}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{contactLastName}</div>
					)}
				</div>
				<div className="dh-details-item">
					<label>Contact Email</label>
					{!a2pComplete ? (
						<input
							name="contact_email"
							id="contact_email"
							onChange={e => this.onChangeInput("contactEmail", e.target.value)}
							type="text"
							value={contactEmail}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{contactEmail}</div>
					)}
				</div>
				<div className="dh-details-item">
					<label>Contact Phone (Include +1)</label>
					{!a2pComplete ? (
						<input
							name="contact_phone"
							id="contact_phone"
							onChange={e => this.onChangeInput("contactPhone", e.target.value)}
							type="text"
							value={contactPhone}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{contactPhone}</div>
					)}
				</div>
				<div className="dh-details-item">
					<label>Contact Business Title (ex: Owner of Company)</label>
					{!a2pComplete ? (
						<input
							name="contact_business_title"
							id="contact_business_title"
							onChange={e => this.onChangeInput("contactBusinessTitle", e.target.value)}
							type="text"
							value={contactBusinessTitle}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{contactBusinessTitle}</div>
					)}
				</div>
				<div className="dh-details-item">
					<label>Contact Position</label>
					{!a2pComplete ? (
						<Select
							id="contact_position"
							name="contact_position"
							options={TWILIO_A2P_CONTACT_POSITIONS}
							value={{ label: contactPositionLabel, value: contactPosition }}
							placeholder="Contact Position"
							onChange={option => {
								this.onChangeInput("contactPosition", option.value);
							}}
							isDisabled={inputsDisabled}
						/>
					) : (
						<div>{contactPosition}</div>
					)}
				</div>
			</>
		);
	}

	renderBusinessProfile() {
		/* Submission States Data */
		let { a2pComplete } = this.state;

		/* A2P Submission Data: Business Profile */
		let {
			businessWebsiteUrl,
			businessRegionsOfOperation,
			businessType,
			businessIndustry,
			businessRegistrationIdentifier,
			businessRegistrationNumber
		} = this.state;

		let inputsDisabled = this.areInputsDisabled();

		// Find the business registration identifier
		let businessRegistrationIdentifierLabel = TWILIO_A2P_BUSINESS_REGISTRATION_NUMBERS.find(
			theBusinessRegID => theBusinessRegID.value === businessRegistrationIdentifier
		);
		businessRegistrationIdentifierLabel =
			typeof businessRegistrationIdentifierLabel === "undefined" ? "Unknown Business Registration Identifier" : businessRegistrationIdentifierLabel.label;

		// Find the industry
		let businessIndustryLabel = TWILIO_A2P_BUSINESS_INDUSTRY.find(theIndustry => theIndustry.value === businessIndustry);
		businessIndustryLabel = typeof businessIndustryLabel === "undefined" ? "Unknown Business Industry" : businessIndustryLabel.label;

		// Find the business type
		let businessTypeLabel = TWILIO_A2P_BUSINESS_TYPES.find(theBusinessType => theBusinessType.value === businessType);
		businessTypeLabel = typeof businessTypeLabel === "undefined" ? "Unknown Business Type" : businessTypeLabel.label;

		// Find the region of operation
		let businessRegionsOfOperationLabel = TWILIO_A2P_REGIONS_OF_OPERATION.find(
			theRegionOfOperation => theRegionOfOperation.value === businessRegionsOfOperation
		);
		businessRegionsOfOperationLabel =
			typeof businessRegionsOfOperationLabel === "undefined" ? "Unknown Business Region of Operation" : businessRegionsOfOperationLabel.label;

		return (
			<>
				<div className="dh-details-title">Business Profile</div>

				<div className="dh-details-item">
					<label>Business Website (Include www)</label>
					{!a2pComplete ? (
						<input
							name="business_website_url"
							id="business_website_url"
							onChange={e => this.onChangeInput("businessWebsiteUrl", e.target.value)}
							type="text"
							value={businessWebsiteUrl}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{businessWebsiteUrl}</div>
					)}
				</div>

				<div className="dh-details-item">
					<label>Business Region of Operation</label>
					{!a2pComplete ? (
						<Select
							id="business_region_of_operation"
							name="business_region_of_operation"
							options={TWILIO_A2P_REGIONS_OF_OPERATION}
							value={{ label: businessRegionsOfOperationLabel, value: businessRegionsOfOperation }}
							placeholder="Business Region of Operation"
							onChange={option => {
								this.onChangeInput("businessRegionsOfOperation", option.value);
							}}
							isDisabled={inputsDisabled}
						/>
					) : (
						<div>{businessRegionsOfOperation}</div>
					)}
				</div>

				<div className="dh-details-item">
					<label>Business Type (Ownership Structure)</label>
					{!a2pComplete ? (
						<Select
							id="business_type"
							name="business_type"
							options={TWILIO_A2P_BUSINESS_TYPES}
							value={{ label: businessTypeLabel, value: businessType }}
							placeholder="Business Type"
							onChange={option => {
								this.onChangeInput("businessType", option.value);
							}}
							isDisabled={inputsDisabled}
						/>
					) : (
						<div>{businessType}</div>
					)}
				</div>

				<div className="dh-details-item">
					<label>Business Industry</label>
					{!a2pComplete ? (
						<Select
							id="business_registration_identifier"
							name="business_registration_identifier"
							options={TWILIO_A2P_BUSINESS_INDUSTRY}
							value={{ label: businessIndustryLabel, value: businessIndustry }}
							placeholder="Business Industry"
							onChange={option => {
								this.onChangeInput("businessIndustry", option.value);
							}}
							isDisabled={inputsDisabled}
						/>
					) : (
						<div>{businessIndustry}</div>
					)}
				</div>

				<div className="dh-details-item">
					<label>Business Registration Number Identifier</label>
					{!a2pComplete ? (
						<Select
							id="business_registration_identifier"
							name="business_registration_identifier"
							options={TWILIO_A2P_BUSINESS_REGISTRATION_NUMBERS}
							value={{ label: businessRegistrationIdentifierLabel, value: businessRegistrationIdentifier }}
							placeholder="Business Registration Identifier"
							onChange={option => {
								this.onChangeInput("businessRegistrationIdentifier", option.value);
							}}
							isDisabled={inputsDisabled}
						/>
					) : (
						<div>{businessRegistrationIdentifier}</div>
					)}
				</div>

				<div className="dh-details-item">
					<label>Business Registration Number (ex: the EIN number)</label>
					{!a2pComplete ? (
						<input
							name="business_registration_number"
							id="business_registration_number"
							onChange={e => this.onChangeInput("businessRegistrationNumber", e.target.value)}
							type="text"
							value={businessRegistrationNumber}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{businessRegistrationNumber}</div>
					)}
				</div>
			</>
		);
	}

	renderCompanyProfile() {
		/* Submission States Data */
		let { a2pComplete } = this.state;

		/* A2P Submission Data: Company Profile */
		let { companyType, companyStockExchangeListed, companyStockTiker } = this.state;

		let inputsDisabled = this.areInputsDisabled();

		// Find the company type
		let companyTypeLabel = TWILIO_A2P_COMPANY_TYPE.find(theCompanyType => theCompanyType.value === companyType);
		companyTypeLabel = typeof companyTypeLabel === "undefined" ? "Unknown Company Type" : companyTypeLabel.label;

		return (
			<>
				<div className="dh-details-title">Company Profile</div>

				<div className="dh-details-item">
					<label>Company Type</label>
					{!a2pComplete ? (
						<Select
							id="company_type"
							name="company_type"
							options={TWILIO_A2P_COMPANY_TYPE}
							value={{ label: companyTypeLabel, value: companyType }}
							placeholder="Company Type"
							onChange={option => {
								this.onChangeInput("companyType", option.value);
							}}
							isDisabled={inputsDisabled}
						/>
					) : (
						<div>{companyType}</div>
					)}
				</div>

				<div className="dh-details-item">
					<label>Company Stock Exchange (ex: NASDAQ; only if public)</label>
					{!a2pComplete ? (
						<input
							name="company_exchange_listed"
							id="company_exchange_listed"
							onChange={e => this.onChangeInput("companyStockExchangeListed", e.target.value)}
							type="text"
							value={companyStockExchangeListed}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{companyStockExchangeListed}</div>
					)}
				</div>

				<div className="dh-details-item">
					<label>Company Stock Tiker (ex: TSLA; only if public)</label>
					{!a2pComplete ? (
						<input
							name="company_stock_tiker"
							id="company_stock_tiker"
							onChange={e => this.onChangeInput("companyStockTiker", e.target.value)}
							type="text"
							value={companyStockTiker}
							className="form-control Common__input"
							disabled={inputsDisabled}
						/>
					) : (
						<div>{companyStockTiker}</div>
					)}
				</div>
			</>
		);
	}

	render() {
		/* Component State Data */
		let { a2pMetaData, showMetaData, showConfirmSubmitAlert, showConfirmResetAlert } = this.state;

		/* Submission States Data */
		let { a2pSubmissionStatusText, a2pSubmissionWaiting, a2pComplete } = this.state;

		let inputsDisabled = this.areInputsDisabled();

		// Get the twilio service id
		let twilioMessageServiceSid = UserService.getActiveLocation().twilio_message_service_sid;

		return (
			<div className="Common__flex-grow twilio-a2p">
				<div className="twilio-a2p__actions">
					<Action id="refresh-a2p" label="Refresh A2P Submission Data" icon={Icon.RefreshCcw} onClick={this.handleOnRefreshButtonPress} />
					<Action id="reset-a2p" label="Reset A2P Submission" icon={Icon.Trash2} onClick={this.handleOnResetButtonPress} />
				</div>
				<div className="dh-details-info">
					<div ref={ref => (this.submissionInformationDom = ref)} className="dh-details-title">
						A2P Submission Information
					</div>
					<>
						<div className="dh-details-item">
							<label>Submission Status</label>
							<div>
								{a2pSubmissionWaiting && (
									<div className="twilio-a2p__spinner-container">
										<Spinners loading={true} size="8px" type="tail-fade" />
									</div>
								)}
								<span>{a2pSubmissionStatusText}</span>
							</div>
						</div>
						<div className="dh-details-item">
							<label>Twilio Message Service SID</label>
							<div>
								<span>
									{twilioMessageServiceSid
										? twilioMessageServiceSid
										: "You must have an associated twilio service on this location to submit. To get this, add a twilio number to this location."}
								</span>
							</div>
						</div>

						{a2pComplete && this.renderCompletionSummary()}

						<div className="dh-details-item">
							<label>Metadata</label>
							<div className="dh-details-item">
								<div id="showMetaDataButton" className="mb-button mb-button--fit twilio-a2p__metadata_button" onClick={this.onToggleShowMetadata}>
									{showMetaData ? "Hide Metadata" : "Show Metadata"}
								</div>
							</div>
							{showMetaData && <pre className="twilio-a2p__metadata">{JSON.stringify(a2pMetaData, null, 2)}</pre>}
						</div>
					</>

					{this.renderLocationInformation()}
					{this.renderAddressInformation()}
					{this.renderContactProfile()}
					{this.renderBusinessProfile()}
					{this.renderCompanyProfile()}

					{!inputsDisabled && (
						<div className="dh-details__save">
							<div id="submit" className="mb-button" onClick={this.handleOnSubmitButtonPress}>
								Submit
							</div>
						</div>
					)}

					<Alert
						type="info"
						show={showConfirmSubmitAlert}
						title="Are you sure?"
						confirm="Yes"
						cancel="Cancel"
						onClose={confirmed => {
							if (confirmed) {
								this.onSubmit();
							}
							this.update({ showConfirmSubmitAlert: false });
						}}
					>
						<div>You are about to make a Twilio A2P brand and campaign submission. Are you sure you would like to proceed?</div>
					</Alert>

					<Alert
						type="info"
						show={showConfirmResetAlert}
						title="Are you sure?"
						confirm="Yes"
						cancel="Cancel"
						onClose={confirmed => {
							if (confirmed) {
								this.resetA2PSubmission();
							}
							this.update({ showConfirmResetAlert: false });
						}}
					>
						<div>You are about to reset the state of Twilio A2P back to its original state. Are you sure you would like to proceed?</div>
					</Alert>
				</div>
			</div>
		);
	}
}

export default withRouter(withLocation(TwilioA2P));
