import React, { Component } from "react";

import Result from "../Result/Result";

import "./result-list.css";

class ResultList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: null
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handler = async (result, index) => {
		let { onResultClicked } = this.props;

		await this.update({
			selected: index
		});

		onResultClicked(result);
	};

	render() {
		let { title, results, selected } = this.props;

		return (
			<div className="mb-result-list">
				<div className="mb-result-list-title">
					<span className="mb-result-list-title-content">{title}</span>
				</div>
				{results.map((result, index) => (
					<Result key={index} data={result} handler={result => this.handler(result, index)} selected={selected === index} />
				))}
			</div>
		);
	}
}

export default ResultList;
