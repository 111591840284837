import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Kichiri from "../../services/KichiriService";
import StringService from "../../services/StringService";
import UserService from "../../services/UserService";
import UtilityService from "../../services/UtilityService";

import "../../styles/css/scenes/reset-password.css";

class VerifyEmailToken extends Component {
	constructor(props) {
		super(props);
		let { t } = this.props;

		this.state = {
			tokenFailed: false,
			exceededVerifyMessage: t("verify exceeded"),
			tokenVerifyTryExceeded: false,
			tokenErrorTitle: "",
			tokenErrorMessage: ""
		};
	}

	componentDidMount() {
		document.body.classList.add("gray-bg");
		UtilityService.checkAndSetDarkBackground();
		UserService.clear();
		this.verifyToken();
	}
	componentWillUnmount() {
		document.body.classList.remove("gray-bg");
		document.body.classList.remove("Common-bg--dark");
	}

	async verifyToken() {
		const { exceededVerifyMessage } = this.state;
		let { t } = this.props;

		try {
			const queryStringObject = StringService.parseQueryString(this.props.location.search);

			let data = await UserService.verifyToken({ token: queryStringObject.token });

			this.props.history.push({ pathname: "/password/reset", state: { token: data.token } });
			return;
		} catch (error) {
			if (error.response.data.error === exceededVerifyMessage) {
				this.setState({
					tokenVerifyTryExceeded: true,
					tokenErrorTitle: t("Verification Limit Exceeded"),
					tokenErrorMessage: t(
						"You have exceeded the number of times that the reset link can be accessed in a day. Please try again in 24 hours or contact us at support@demandhub.co"
					)
				});
			} else {
				this.setState({
					tokenFailed: true,
					tokenErrorTitle: t("Link Invalid or Expired"),
					tokenErrorMessage: t(
						"Please try again by clicking on the email link. If more than 24 hours have passed since the link was requested, please restart the password reset process or contact us at support@demandhub.co"
					)
				});
			}
		}
	}

	render() {
		const { tokenFailed, tokenVerifyTryExceeded, tokenErrorTitle, tokenErrorMessage } = this.state;
		let { t } = this.props;

		return (
			<div>
				{(tokenFailed || tokenVerifyTryExceeded) && (
					<div className="reset-password Common__card">
						<h2 className="font-bold">{tokenErrorTitle}</h2>
						<p>{tokenErrorMessage}</p>
						<br />
						<div className="form-group">
							<button className="mb-button mb-button--slim mb-button--fit mb-button--center" onClick={e => this.props.history.push("/login")} type="submit">
								{t("Back to Login")}
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(VerifyEmailToken));
