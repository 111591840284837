import { EMOJI_POSITIONS } from "../constants/Emojis";

let globalLightbox = null;

const LightboxService = {
	/**
	 * Stores a reference to the lightbox we will use in this service
	 *
	 * @param {Component} globalLightboxRef
	 */
	storeReference(globalLightboxRef) {
		globalLightbox = globalLightboxRef;
	},

	/**
	 * Opens an global lightbox
	 *
	 * @param {Component} positionBeside
	 * @param {String} position
	 * @param {Function} onEmojiSelect
	 * @returns
	 */
	open() {
		if (!globalLightbox) {
			return;
		}

		globalLightbox.show();
	},

	/**
	 * Set the media data
	 * @param {Array} media
	 * @param {Number} mediaIndex The index that we want to set the the lightbox to
	 * @returns
	 */
	setMedia({ media, mediaIndex = 0 }) {
		if (!globalLightbox) {
			return;
		}

		globalLightbox.setMedia({ media, mediaIndex });
	}
};

export default LightboxService;
