import React, { Component } from "react";
import moment from "moment";
import c3 from "c3";
import { CSVLink } from "react-csv";

import UserService from "../../../services/UserService";
import GAService from "../../../services/GAService";
import { CsvService } from "../../../services/CsvService";
import { AnalyticsService } from "../../../services/AnalyticsService";

import MessagesAnalytics from "./MessagesAnalytics";
import MessageStates from "./MessageStates";
import FailedMessageDeliverability from "./FailedMessageDeliverability";
import DashboardCard from "../../Dashboard/DashboardCard";

import "../../../App.css";
import "../../../styles/css/scenes/analytics.css";
import "react-day-picker/lib/style.css";

class MessengerAnalytics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate(),
			messageDeliveryStats: null,
			deliveryStatesCsv: null,
			deliveryCodesCsv: null,
			deliveryDescriptionCsv: null,
			messageDeliveryRate: null
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchMessagesStats();
	}

	async fetchMessagesStats() {
		try {
			this.setState({ loading: true });
			const { start, end } = this.state;

			const msgDeliveryStates = await AnalyticsService.getMessageDeliveryAnalytics(UserService.getActiveLocation().id, start, end);
			const messageDeliveryRate = await AnalyticsService.getMessageDeliveryRate(UserService.getActiveLocation().id, start, end);

			await this.update({ messageDeliveryStats: msgDeliveryStates, messageDeliveryRate, loading: false });
			this.updateMessageStatesChart();

			return;
		} catch (error) {
			console.log(error);
		}
	}

	updateMessageStatesChart() {
		try {
			this.setState({ loading: false });

			const { messageDeliveryStats } = this.state;

			if (!messageDeliveryStats) {
				return;
			}

			let deliveryStates = messageDeliveryStats.deliveryStates;
			let deliveryWaitingX = deliveryStates.map(item => item.date);
			deliveryWaitingX.unshift("x");
			let deliveryWaitingY = deliveryStates.map(item => item.waiting);
			deliveryWaitingY.unshift("Waiting");
			let deliveredY = deliveryStates.map(item => item.delivered);
			deliveredY.unshift("Delivered");
			let notDeliveredY = deliveryStates.map(item => item.notDelivered);
			notDeliveredY.unshift("Not Delivered");

			let deliveryStateColumn = [deliveryWaitingX, deliveryWaitingY, deliveredY, notDeliveredY];

			let deliveryCodeStats = messageDeliveryStats.deliveryCodeStats;
			let deliveryCodeX = deliveryCodeStats.map(item => item.date);
			deliveryCodeX.unshift("x");
			let deliveryCodeColumns = [deliveryCodeX];

			let statsPerCode = {};
			for (let i = 0; i < deliveryCodeStats.length; i++) {
				const item = deliveryCodeStats[i];
				for (const key in item) {
					if (key === "date") {
						continue;
					}
					const count = item[key];
					if (!statsPerCode[key]) {
						statsPerCode[key] = [`${key}`];
					}
					statsPerCode[key].push(count);
				}
			}
			for (const key in statsPerCode) {
				const stat = statsPerCode[key];
				deliveryCodeColumns.push(stat);
			}

			let deliveryDescriptionStats = messageDeliveryStats.deliveryDescriptionStats;
			let descriptionX = deliveryDescriptionStats.map(item => item.date);
			descriptionX.unshift("x");
			let descriptionColumns = [descriptionX];

			let statsPerDescription = {};
			for (let i = 0; i < deliveryDescriptionStats.length; i++) {
				const item = deliveryDescriptionStats[i];
				for (const key in item) {
					if (key === "date") {
						continue;
					}
					const count = item[key];
					if (!statsPerDescription[key]) {
						statsPerDescription[key] = [`${key}`];
					}
					statsPerDescription[key].push(count);
				}
			}
			for (const key in statsPerDescription) {
				const stat = statsPerDescription[key];
				descriptionColumns.push(stat);
			}

			// Transpose data for CSV
			let deliveryStatesCsv = [...deliveryStateColumn];
			deliveryStatesCsv = deliveryStatesCsv[0].map((col, i) => deliveryStatesCsv.map(row => row[i])); // transpose the data
			if (deliveryStatesCsv && deliveryStatesCsv.length > 0) {
				deliveryStatesCsv[0][0] = "Date";
			}
			let deliveryCodesCsv = [...deliveryCodeColumns];
			deliveryCodesCsv = deliveryCodesCsv[0].map((col, i) => deliveryCodesCsv.map(row => row[i])); // transpose the data
			if (deliveryCodesCsv && deliveryCodesCsv.length > 0) {
				deliveryCodesCsv[0][0] = "Date";
			}

			let deliveryDescriptionCsv = [...descriptionColumns];
			deliveryDescriptionCsv = deliveryDescriptionCsv[0].map((col, i) => deliveryDescriptionCsv.map(row => row[i])); // transpose the data
			if (deliveryDescriptionCsv && deliveryDescriptionCsv.length > 0) {
				deliveryDescriptionCsv[0][0] = "Date";
			}

			this.setState({
				deliveryStatesCsv,
				deliveryCodesCsv,
				deliveryDescriptionCsv
			});

			this.generateChart("#delivery-states-analytics-chart", deliveryStateColumn);
			this.generateChart("#delivery-codes-analytics-chart", deliveryCodeColumns);
			this.generateChart("#delivery-descriptions-analytics-chart", descriptionColumns);
		} catch (error) {
			console.log(error.stack);
		}
	}

	generateChart(id, columns) {
		try {
			const { start, end } = this.state;
			const isMonth = moment(end).diff(moment(start), "days") >= 90;
			c3.generate({
				bindto: id,
				data: {
					x: "x",
					columns: columns,
					type: "bar"
				},
				axis: {
					x: {
						label: "Day",
						type: "timeseries",
						tick: {
							format: function(x) {
								if (isMonth) {
									const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
									return "" + x.getFullYear() + " " + monthString[x.getMonth()];
								}
								return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
							},
							rotate: window.innerWidth > 768 ? 0 : 75,
							multiline: false
						}
					},
					y: {
						label: "Counts"
					}
				}
			});
		} catch (error) {
			console.log(`Error generate a chart - ${error.stack}`);
		}
	}

	render() {
		const { loading, messageDeliveryStats, deliveryStatesCsv, deliveryCodesCsv, deliveryDescriptionCsv } = this.state;
		const locationName = CsvService.filterActiveLocationName();

		return (
			<div>
				<div className="row wrapper border-bottom white-bg page-heading">
					<div className="ReviewSitesAnalytics__page--header">
						<div className="col-sm-6 col-md-8 col-lg-9">
							<h2>
								Messenger Analytics<span className="hidden-xxs"> - {UserService.getActiveLocation().name}</span>
							</h2>
						</div>
					</div>
				</div>

				<div className="wrapper wrapper-content analytics-messenger">
					<DashboardCard>
						<MessagesAnalytics isCard={true}></MessagesAnalytics>
					</DashboardCard>
					<DashboardCard>
						<MessageStates isCard={true}></MessageStates>
					</DashboardCard>

					{UserService.isSuperAdmin() && (
						<>
							<DashboardCard>
								<div className="dashboard__card__header__name">Delivery States</div>
								{loading ? (
									<h2>Loading...</h2>
								) : !messageDeliveryStats ? (
									<h2>
										Sorry, no data was found{" "}
										<span role="img" aria-label="sad-face">
											😞
										</span>
									</h2>
								) : (
									<>
										<div id="delivery-states-analytics-chart" />
										<div className="Common_flex-pull-right">
											{deliveryStatesCsv && (
												<CSVLink data={deliveryStatesCsv} filename={`${locationName}-messenger_analytics_delivery_states.csv`} target="_self">
													<button className="btn btn-default Common_download-btn">
														<img src={`/img/icons/svg/csv-outline.svg`} alt="download csv" style={{ width: "25px" }} />
													</button>
												</CSVLink>
											)}
										</div>
									</>
								)}
							</DashboardCard>
							<DashboardCard>
								<div className="dashboard__card__header__name">Delivery Codes</div>
								{loading ? (
									<h2>Loading...</h2>
								) : !messageDeliveryStats ? (
									<h2>
										Sorry, no data was found{" "}
										<span role="img" aria-label="sad-face">
											😞
										</span>
									</h2>
								) : (
									<>
										<div id="delivery-codes-analytics-chart" />
										<div className="Common_flex-pull-right">
											{deliveryCodesCsv && (
												<CSVLink data={deliveryCodesCsv} filename={`${locationName}-messenger_analytics_delivery_codes.csv`} target="_self">
													<button className="btn btn-default Common_download-btn">
														<img src={`/img/icons/svg/csv-outline.svg`} alt="download csv" style={{ width: "25px" }} />
													</button>
												</CSVLink>
											)}
										</div>
									</>
								)}
							</DashboardCard>
							<DashboardCard>
								<div className="dashboard__card__header__name">Delivery Descriptions</div>
								{loading ? (
									<h2>Loading...</h2>
								) : !messageDeliveryStats ? (
									<h2>
										Sorry, no data was found{" "}
										<span role="img" aria-label="sad-face">
											😞
										</span>
									</h2>
								) : (
									<>
										<div id="delivery-descriptions-analytics-chart" />
										<div className="Common_flex-pull-right">
											{deliveryDescriptionCsv && (
												<CSVLink data={deliveryDescriptionCsv} filename={`${locationName}-messenger_analytics_delivery_description.csv`} target="_self">
													<button className="btn btn-default Common_download-btn">
														<img src={`/img/icons/svg/csv-outline.svg`} alt="download csv" style={{ width: "25px" }} />
													</button>
												</CSVLink>
											)}
										</div>
									</>
								)}
							</DashboardCard>
						</>
					)}

					<DashboardCard>
						<FailedMessageDeliverability isCard={true}></FailedMessageDeliverability>
					</DashboardCard>
				</div>
			</div>
		);
	}
}

export default MessengerAnalytics;
