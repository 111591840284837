import React, { Component } from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import UtilityService from "../../services/UtilityService";
import UserService from "../../services/UserService";
import NumberService from "../../services/NumberService";

import NumbersDropdown from "./NumbersDropdown";
import Alert from "./Alert";
import DHModal from "./DHModal";

import { DATE_FORMAT, SHORT_TIME_FORMAT } from "../../constants/CommonConstants";

class ManageNumbers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			companyId: this.props.companyId,
			locationId: this.props.locationId,
			numbers: this.props.numbers ? this.props.numbers : [],
			unassignedNumberObject: null,
			newNumberNumber: "",
			newNumberApi: "bandwidthV2",
			noteComment: "",
			showNoteSection: false,
			noteAction: "",
			showNotesModal: false,
			selectedNumberObject: { number: "", notes: [] },
			numbersError: false,
			errorText: "",
			showUnassignModal: false,
			unassignModalText: "",
			selectedUnassignNumber: null,

			showAssignPrimaryToContactsModal: false,
			assignPrimaryNumberToContacts: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handleUnassignedNumbersChange = async selectedNumber => {
		const number = selectedNumber ? selectedNumber.id : "";

		await this.update({
			unassignedNumberObject: selectedNumber,
			number
		});

		if (this.props.hasOwnProperty("onChange")) {
			this.props.onChange(this.state.numbers);
		}
	};

	handleNewNumberApiChange = async event => {
		await this.update({ newNumberApi: event.target.value });

		if (this.props.hasOwnProperty("onChange")) {
			this.props.onChange(this.state.numbers);
		}
	};

	handleNewNumberNumberChange = async event => {
		await this.update({ newNumberNumber: event.target.value });

		if (this.props.hasOwnProperty("onChange")) {
			this.props.onChange(this.state.numbers);
		}
	};

	handleToggleBurnClick = async number => {
		document.activeElement.blur();

		let numbers = this.state.numbers;
		numbers.forEach(num => {
			if (num.number === number.number) {
				num.burned = !number.burned;
			}
		});

		await this.update({ numbers: numbers });

		if (this.props.hasOwnProperty("onChange")) {
			this.props.onChange(this.state.numbers);
		}
	};

	handleWriteNoteChange = async event => {
		await this.update({ noteComment: event.target.value });
	};

	handleNotesOnHide = async () => {
		await this.update({
			showNotesModal: false
		});
	};

	handleAssignNumberOnClick = async () => {
		const { locationId, companyId, numbers } = this.state;

		if (this.state.unassignedNumberObject === null) {
			return;
		}

		let assignableNumber = this.state.unassignedNumberObject;

		// if the unassigned number (that is now being assigned) is currently in the locations numbers, return
		if (this.state.numbers.filter(n => n.number === assignableNumber.number).length > 0) {
			return;
		}

		let newNumbers = numbers.slice();
		newNumbers.push({
			api: assignableNumber.api,
			burned: assignableNumber.burned,
			company_id: companyId,
			created_at: assignableNumber.created_at,
			location_id: locationId,
			notes: assignableNumber.notes,
			number: assignableNumber.number,
			safe: assignableNumber.safe,
			status: assignableNumber.status,
			updated_at: assignableNumber.updated_at
		});

		await this.update({
			numbers: newNumbers,
			unassignedNumberObject: null
		});

		if (this.props.hasOwnProperty("onChange")) {
			this.props.onChange(this.state.numbers);
		}
	};

	handleAddNewNumberOnClick = async () => {
		let { t } = this.props;

		if (this.state.newNumberNumber === "" || this.state.newNumberApi === "") {
			return;
		}

		if (this.state.numbers.filter(n => n.number === this.state.newNumberNumber).length > 0) {
			console.log(`ManageNumbers: New number already in list of numbers`); // more and better checks in DH 954
			await this.update({
				newNumberNumber: "",
				newNumberApi: "bandwidthV2"
			});
			return;
		}

		const { locationId, companyId } = this.state;

		let newNumber = {
			number: this.state.newNumberNumber,
			company_id: companyId,
			location_id: locationId,
			api: this.state.newNumberApi,
			// if it's the first number and it was just created and assigned then make it the primary number
			safe: this.state.numbers.length === 0 ? 1 : 0,
			burned: 0,
			notes: [],
			status: "active",
			value: this.state.newNumberNumber,
			label: `${this.state.newNumberNumber} (${this.state.newNumberApi})`,
			new: true
		};

		if (!UtilityService.isMobilePhoneValid(this.state.newNumberNumber)) {
			this.showErrorModal(t("Please enter a valid phone number please"));
			return;
		}

		let newNumbers = this.state.numbers.slice();
		newNumbers = newNumbers.concat(newNumber);

		await this.update({
			numbers: newNumbers,
			newNumberNumber: "",
			newNumberApi: "bandwidthV2"
		});

		if (this.props.hasOwnProperty("onChange")) {
			this.props.onChange(this.state.numbers);
		}
	};

	handleAssignedNumberApiChange = async (event, number) => {
		number.api = event.target.value;

		await this.update({ numbers: this.state.numbers });

		if (this.props.hasOwnProperty("onChange")) {
			this.props.onChange(this.state.numbers);
		}
	};

	assignPrimaryNumberToContacts = () => {
		return this.state.assignPrimaryNumberToContacts;
	};

	onCloseAssignToContactModal = async confirm => {
		if (confirm) {
			await this.update({ assignPrimaryNumberToContacts: true });
		} else {
			await this.update({ assignPrimaryNumberToContacts: false });
		}

		await this.update({ showAssignPrimaryToContactsModal: false });
	};

	handleSetSafeNumberClick = async number => {
		// Create modal to ask if all contacts should have this number set as their out number
		await this.update({ showAssignPrimaryToContactsModal: true });

		this.state.numbers.forEach(num => {
			num.safe = num.number === number.number ? 1 : 0;
		});

		await this.update({ numbers: this.state.numbers });

		if (this.props.hasOwnProperty("onChange")) {
			this.props.onChange(this.state.numbers);
		}
	};

	handleUnassignNumberOnClick = async (number, checkNumRelatedConvos = true) => {
		let { t } = this.props;
		let { numbers } = this.state;
		let newNumbers = numbers.slice();

		let numberIndex = newNumbers.findIndex(n => n.number === number.number);
		let unassignNumber = newNumbers[numberIndex];
		if (unassignNumber.new) {
			newNumbers.splice(numberIndex, 1);
		} else {
			if (checkNumRelatedConvos) {
				let data = await NumberService.countConversations({ number: number.number, locationId: this.state.locationId });

				if (data.count > 0) {
					let phoneNumber = number.number;
					let numberOfConversations = data.count;

					let modalText = t(
						"The phone number {{phoneNumber}} has been involved in {{number}} conversations. If this number is unassigned it could create a negative user experience for end user customers. Do you wish to continue?",
						{ phoneNumber: phoneNumber, number: numberOfConversations }
					);

					await this.update({
						showUnassignModal: true,
						unassignModalText: modalText,
						selectedUnassignNumber: unassignNumber
					});
					return;
				}
			}
			unassignNumber.location_id = null;
			unassignNumber.company_id = null;
			unassignNumber.value = unassignNumber.number;
			unassignNumber.label = `${unassignNumber.number} (${unassignNumber.api}) ${unassignNumber.burned ? "- " + t("burned") : ""}`;
			newNumbers[numberIndex] = unassignNumber;
		}

		await this.update({ numbers: newNumbers });

		if (this.props.hasOwnProperty("onChange")) {
			this.props.onChange(this.state.numbers);
		}
	};

	handleToggleHostedMessaging = async (event, index) => {
		event.preventDefault();
		event.stopPropagation();
		let { numbers } = this.state;
		let number = numbers[index];
		if (number.hosted_messaging) {
			number.hosted_messaging = false;
		} else {
			number.hosted_messaging = true;
		}
		numbers[index] = number;

		await this.update({ numbers: numbers });

		if (this.props.hasOwnProperty("onChange")) {
			this.props.onChange(this.state.numbers);
		}
	};

	handleToggleA2PCampaign = async (event, index) => {
		event.preventDefault();
		event.stopPropagation();
		let { numbers } = this.state;

		let newNumbers = numbers.slice();

		let number = newNumbers[index];
		if (number.us_a2p_campaign) {
			number.us_a2p_campaign = false;
		} else {
			number.us_a2p_campaign = true;
		}
		newNumbers[index] = number;

		await this.update({ numbers: newNumbers });

		if (this.props.hasOwnProperty("onChange")) {
			this.props.onChange(this.state.numbers);
		}
	};

	modifyNumber = async (number, action, event) => {
		event.preventDefault();
		event.stopPropagation();

		await this.update({
			selectedNumberObject: number,
			noteAction: action
		});
	};

	notesModalTitle = () => {
		let { t } = this.props;
		let { selectedNumberObject } = this.state;
		return (
			<>
				{t("Notes For Number")} {selectedNumberObject !== null ? selectedNumberObject.number : ""}{" "}
				{selectedNumberObject !== null && selectedNumberObject.safe ? (
					<span className="label label-primary locations__sms-numbers-label">{t("Primary")}</span>
				) : null}
				{selectedNumberObject !== null && selectedNumberObject.burned ? (
					<span className="label label-danger locations__sms-numbers-label">{t("Burned")}</span>
				) : null}
			</>
		);
	};

	renderNotesModal() {
		let { t } = this.props;
		let notes = this.state.selectedNumberObject !== null && this.state.selectedNumberObject.notes.length > 0 ? this.state.selectedNumberObject.notes : [];

		return (
			<DHModal title={this.notesModalTitle()} show={this.state.showNotesModal} onHide={() => this.handleNotesOnHide()}>
				{notes.length > 0
					? notes
							.slice(0)
							.reverse()
							.map(note => {
								var fullName = UserService.createFullName({ firstName: note.first_name, lastName: note.last_name });
								return (
									<div key={note.created_at} style={{ width: "100%" }}>
										<p>{note.comment}</p>
										<span className="pull-left">
											{t("Commented at:")}
											{moment(note.created_at).format(DATE_FORMAT) + " at " + moment(note.created_at).format(SHORT_TIME_FORMAT)}
										</span>
										<span className="pull-right">{fullName}</span>
										<br />
										<hr />
									</div>
								);
							})
					: t("No notes found")}
			</DHModal>
		);
	}

	showErrorModal(message) {
		let { t } = this.props;

		if (!message) {
			message = t("Sorry, there was an error with the numbers. Please try again.");
		}

		this.setState({
			numbersError: true,
			errorText: message
		});
	}

	retrievePopoverTitle() {
		let { t } = this.props;
		let { selectedNumberObject, noteAction } = this.state;

		if (!selectedNumberObject) {
			return "";
		}

		let title = t("Confirm action");

		if (!noteAction) {
			return title;
		}

		let numberToChange = selectedNumberObject.number;

		if (noteAction === "unassign") {
			title = t("Unassign {{phoneNumber}}", { phoneNumber: numberToChange });
		} else if (noteAction === "setprimary") {
			title = t("Set {{phoneNumber}} As Primary", { phoneNumber: numberToChange });
		} else if (noteAction === "burn") {
			title = t("Burn {{phoneNumber}}", { phoneNumber: numberToChange });
		} else if (noteAction === "unburn") {
			title = t("Unburn {{phoneNumber}}", { phoneNumber: numberToChange });
		}

		return title;
	}

	render() {
		let { t } = this.props;
		let firstName = UserService.get() ? UserService.get().first_name : t("Me");
		let lastName = UserService.get() ? UserService.get().last_name : "";

		let title = this.retrievePopoverTitle();

		const popover = (
			<Popover id="confirm-popover" title={title}>
				<p>{t("This action may have serious consequences. If you wish to continue please leave a note.")}</p>
				<p>{t("Note:")}</p>
				<textarea id="sms_number_note" name="sms_number_note" rows="3" onChange={this.handleWriteNoteChange} value={this.state.noteComment} />
				<br />
				<hr style={{ margin: "5px 0" }} />
				<div className="locations__popover-buttons">
					<button
						id="sms_number_note_confirm"
						name="sms_number_note_confirm"
						className="btn btn-primary inline btn-sm"
						type="button"
						onClick={() => {
							// add to numbers
							document.body.click();

							switch (this.state.noteAction) {
								case "unassign":
									this.handleUnassignNumberOnClick(this.state.selectedNumberObject);
									break;
								case "burn":
								case "unburn":
									this.handleToggleBurnClick(this.state.selectedNumberObject);
									break;
								case "setprimary":
									this.handleSetSafeNumberClick(this.state.selectedNumberObject);
									break;
								default:
									break;
							}

							let numbers = this.state.numbers;
							numbers.forEach(number => {
								if (number.number === this.state.selectedNumberObject.number) {
									number.notes.push({
										comment: this.state.noteComment,
										created_at: moment(),
										first_name: firstName,
										last_name: lastName
									});
								}
								number.notes = number.notes.sort((n1, n2) => n1.created_at - n2.created_at);
							});

							this.setState({
								selectedNumberObject: null,
								noteAction: "",
								noteComment: "",
								numbers: numbers
							});
						}}
						style={{ margin: "5px 7px" }}
						disabled={this.state.noteComment === "" || this.state.noteComment.length < 15 ? true : false}
					>
						{t("Confirm")}
					</button>
					<button
						className="btn btn-default inline btn-sm"
						type="button"
						onClick={() => {
							document.body.click();
							this.setState({
								selectedNumberObject: null,
								noteAction: "",
								noteComment: ""
							});
						}}
						style={{ margin: "5px 7px" }}
					>
						{t("Close")}
					</button>
				</div>
			</Popover>
		);

		return (
			<div>
				<Alert
					type="error"
					show={this.state.showUnassignModal}
					title={t("Unassign warning")}
					confirm={t("Yes")}
					cancel={t("Cancel")}
					onClose={confirmed => {
						if (confirmed) {
							this.handleUnassignNumberOnClick(this.state.selectedUnassignNumber, false);
						}
						this.setState({ showUnassignModal: false, unassignModalText: "" });
					}}
				>
					<div>{this.state.unassignModalText}</div>
				</Alert>

				<Alert
					type="info"
					show={this.state.showAssignPrimaryToContactsModal}
					title={t("Assign Number To All Contacts")}
					confirm={t("Yes")}
					cancel={t("No")}
					onClose={this.onCloseAssignToContactModal}
				>
					{t("Would you like to set this number as the primary number for all of this location's contacts?")}
				</Alert>
				<div className="form-group">
					<div className="row">
						<div className="col-sm-12 text-center">
							<h3>{t("SMS Numbers")}</h3>
							<br />
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12">
							<div className="row">
								<div className="col-lg-7 col-md-12">
									<label>{t("Add new number")}</label>
									<div className="locations__add-new-number">
										<input
											name="new_sms_number"
											id="new_sms_number"
											placeholder={t("Eg: +11234567890")}
											onChange={this.handleNewNumberNumberChange}
											value={this.state.newNumberNumber}
											className="form-control"
											style={{ height: "35px" }}
										/>
										<span>
											<select id="new_number_api" className="form-control" value={this.state.newNumberApi} onChange={this.handleNewNumberApiChange}>
												<option value="bandwidthV2">bandwidthV2</option>
												<option value="bandwidth">bandwidth</option>
												<option value="twilio">twilio</option>
												<option value="unifonic">unifonic</option>
											</select>
										</span>
										<span>
											<button id="new_number_create_and_assign" className="btn btn-primary" type="button" onClick={() => this.handleAddNewNumberOnClick()}>
												{t("Create and Assign")}
											</button>
										</span>{" "}
									</div>
								</div>
								<div className="col-lg-5 col-md-12">
									<label>{t("Add from unassigned numbers")}</label>
									<div className="input-group">
										<NumbersDropdown
											id="numbers"
											value={this.state.unassignedNumberObject}
											onChange={e => this.handleUnassignedNumbersChange(e)}
											assignedNumbers={this.state.numbers ? this.state.numbers : []}
											required
										/>
										<span className="input-group-btn">
											<button className="btn btn-primary" type="button" onClick={() => this.handleAssignNumberOnClick()} style={{ zIndex: "0" }}>
												{t("Assign")}
											</button>
										</span>{" "}
									</div>
								</div>
							</div>
							<br />
							<div className="row">
								<ul className="list-group col-lg-12" style={{ padding: "10px 15px" }}>
									{this.state.numbers.map(
										(number, index) =>
											number.location_id != null && (
												<li key={number.number} className="list-group-item locations__numbers-list" style={{ padding: "10px 15px" }}>
													<span>{number.number}</span>{" "}
													<span className="">
														<span className="input-group-addon" style={{ width: "0px", paddingLeft: "0px", paddingRight: "0px", border: "none" }} />
														<select
															className="form-control"
															value={number.api}
															onChange={e => {
																this.handleAssignedNumberApiChange(e, number);
															}}
															style={{ display: "inline", width: "auto", marginTop: "-30px" }}
														>
															<option value="bandwidthV2">bandwidthV2</option>
															<option value="bandwidth">bandwidth</option>
															<option value="twilio">twilio</option>
															<option value="unifonic">unifonic</option>
														</select>
													</span>{" "}
													{number.safe ? <span className="label label-primary locations__sms-numbers-label">{t("Primary")}</span> : ""}
													{number.burned ? <span className="label label-danger locations__sms-numbers-label">{t("Burned")}</span> : ""}{" "}
													{number.us_a2p_campaign ? <span className="label label-info locations__sms-numbers-label">{t("US A2P Campaign")}</span> : ""}
													{number.hosted_messaging ? <span className="label label-info locations__sms-numbers-label">{t("Hosted Messaging")}</span> : ""}
													{number.notes !== null && number.notes.length > 0 && (
														<span>
															<span className="text-muted locations__note-comment">
																<span>
																	<i className="fa fa-sticky-note fa-lg" />
																</span>{" "}
																<span>"</span>
																<span>{number.notes[number.notes.length - 1].comment} </span>
																<span>"</span>
																<span className="locations__note-name">
																	-{" "}
																	{UserService.createFullName({
																		firstName: number.notes[number.notes.length - 1].first_name,
																		lastName: number.notes[number.notes.length - 1].last_name
																	})}
																</span>
															</span>
														</span>
													)}
													<div className="locations__sms-numbers-container">
														{number.safe ? (
															""
														) : !number.burned ? (
															<OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose>
																<button
																	id="burn-number"
																	className="btn btn-warning btn-outline pull-right locations__sms-numbers-button"
																	onClick={e => {
																		this.modifyNumber(number, "burn", e);
																	}}
																>
																	{t("Mark as burned")}
																</button>
															</OverlayTrigger>
														) : number.burned ? (
															<OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose>
																<button
																	id="unburn-number"
																	className="btn btn-warning btn-outline pull-right locations__sms-numbers-button"
																	onClick={e => {
																		this.modifyNumber(number, "unburn", e);
																	}}
																>
																	{t("Unburn Number")}
																</button>
															</OverlayTrigger>
														) : (
															""
														)}
														{!number.safe && !number.burned ? (
															<OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose>
																<button
																	id="set-primary-number"
																	className="btn btn-primary btn-outline pull-right locations__sms-numbers-button"
																	onClick={e => {
																		this.modifyNumber(number, "setprimary", e);
																	}}
																>
																	{t("Mark as primary")}
																</button>
															</OverlayTrigger>
														) : (
															""
														)}
														{!number.safe ? (
															<OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose>
																<button
																	id="unassign-number"
																	className="btn btn-danger btn-outline pull-right locations__sms-numbers-button"
																	onClick={e => {
																		this.modifyNumber(number, "unassign", e);
																	}}
																>
																	{t("Unassign")}
																</button>
															</OverlayTrigger>
														) : (
															""
														)}

														<button
															id="view-notes"
															data-tip
															data-for="view-all-notes"
															className="btn btn-default btn-outline pull-right locations__sms-numbers-button"
															onClick={e => {
																e.preventDefault();
																this.setState({
																	showNotesModal: true,
																	selectedNumberObject: number
																});
															}}
														>
															{t("View Notes")}
														</button>
														<ReactTooltip id="view-all-notes" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
															{t("View All Notes")}
														</ReactTooltip>

														<button
															id="a2p-campaign"
															data-tip
															data-for="a2p-campaign-rtt"
															className="btn btn-outline btn-info locations__sms-numbers-button "
															onClick={event => this.handleToggleA2PCampaign(event, index)}
														>
															{number.us_a2p_campaign ? t("Disable A2P") : t("Enable A2P")}
														</button>
														<ReactTooltip id="a2p-campaign-rtt" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
															{t("Toggle US A2P Campaign")}
														</ReactTooltip>

														<button
															id="hosted-messaging"
															data-tip
															data-for="hosted-messaging-rtt"
															className="btn btn-outline btn-info locations__sms-numbers-button"
															onClick={event => this.handleToggleHostedMessaging(event, index)}
														>
															{number.hosted_messaging ? "Disable Hosted Messaging" : "Enable Hosted Messaging"}
														</button>
														<ReactTooltip id="hosted-messaging-rtt" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
															{t("Toggle Hosted Messaging")}
														</ReactTooltip>
													</div>
												</li>
											)
									)}
								</ul>
							</div>
						</div>
					</div>
				</div>
				{this.renderNotesModal()}
				<Alert
					type="error"
					show={this.state.numbersError}
					title={t("Numbers Error")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ numbersError: false });
					}}
				>
					<div>{this.state.errorText}</div>
				</Alert>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(ManageNumbers);
