import React, { Component } from "react";
import Select from "react-select";

import Checkbox from "./Checkbox";
import "../../styles/css/scenes/manage-user-profile.css";

class MUPNotificationCard extends Component {
	constructor(props) {
		super(props);
	}

	onChange = e => {
		const { onChange } = this.props;
		if (onChange) {
			onChange(e);
		}
	};

	render() {
		const { locationNotification, field, title, description, select, options, value, placeholder, isDisabled } = this.props;
		return (
			<div className="mup-notifications-card" style={{ borderColor: locationNotification[field] ? "#3B88FC" : "#8b8c8c" }}>
				<strong>{title}</strong>
				{!select && <Checkbox id={field} name={field} checked={locationNotification[field]} float="right" onChange={this.onChange} />}
				{select && (
					<Select
						id={field}
						name={field}
						options={options}
						value={value}
						className="mup-notifications-card__select"
						placeholder={placeholder}
						onChange={this.onChange}
						isDisabled={isDisabled}
					/>
				)}
				<br />
				{description}
			</div>
		);
	}
}

export default MUPNotificationCard;
