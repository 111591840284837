import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import NumberService from "../../services/NumberService";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";

import GAService from "../../services/GAService";

import "../../styles/css/components/commons/hosted-messaging.css";

class HostedMessaging extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkImportLoading: false,
			importLoading: false,
			checkImportOrderLoading: false,
			phoneNumber: "",
			isPhoneImportable: null,
			importOrderStatus: null,
			importResponse: null,
			name: "",
			streetNumber: "",
			streetName: "",
			city: "",
			stateCode: "",
			zip: "",
			loaPerson: ""
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
	}

	handleGenericEventHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handlePhoneNumberOnChange = event => {
		const reg = /^[0-9]+$/;

		if (event.target.value === "" || reg.test(event.target.value)) {
			this.setState({ [event.target.name]: event.target.value, isPhoneImportable: null });
		}
	};

	isImportNumberDisabled() {
		let isDisabled = true;

		const { checkImportOrderLoading, importLoading, checkImportLoading } = this.state;

		if (checkImportOrderLoading || importLoading || checkImportLoading) {
			return isDisabled;
		}

		const { name, streetNumber, streetName, city, stateCode, zip, loaPerson } = this.state;
		// eslint-disable-next-line
		let stringRegex = /[a-zA-z\s\.-]+/gm;
		let numberRegex = /[0-9]+/gm;
		if (
			(stringRegex.test(name) &&
				numberRegex.test(streetNumber) &&
				stringRegex.test(streetName) &&
				stringRegex.test(city) &&
				stringRegex.test(stateCode) &&
				stringRegex.test(zip),
			stringRegex.test(loaPerson))
		) {
			isDisabled = false;
		}

		return isDisabled;
	}

	isCheckImportabilityDisabled() {
		let isDisabled = true;

		let { phoneNumber, checkImportOrderLoading, importLoading } = this.state;
		if (phoneNumber.length > 6 && !checkImportOrderLoading && !importLoading) {
			isDisabled = false;
		}

		return isDisabled;
	}

	isCheckImportOrderDisabled() {
		let isDisabled = true;

		let { phoneNumber, checkImportLoading, importLoading } = this.state;
		if (phoneNumber.length > 6 && !checkImportLoading && !importLoading) {
			isDisabled = false;
		}

		return isDisabled;
	}

	checkImportability = async () => {
		if (this.isCheckImportabilityDisabled()) {
			return;
		}
		this.setState({ checkImportLoading: true });
		let isImportable = await NumberService.checkImportability(this.state.phoneNumber);

		this.setState({ checkImportLoading: false, isPhoneImportable: isImportable, importOrderStatus: null });
	};

	checkImportOrderStatus = async () => {
		if (this.isCheckImportOrderDisabled()) {
			return;
		}
		this.setState({ checkImportOrderLoading: true });

		let importOrderStatus = await NumberService.checkImportOrderStatus(this.state.phoneNumber);

		this.setState({ checkImportOrderLoading: false, importOrderStatus: importOrderStatus, isPhoneImportable: null });
	};

	importNumber = async () => {
		if (!this.state.isPhoneImportable) {
			return;
		}

		this.setState({ importLoading: true });
		const { phoneNumber, name, streetNumber, streetName, city, stateCode, zip, loaPerson } = this.state;

		let importResponse = await NumberService.importNumber(phoneNumber, name, streetNumber, streetName, city, stateCode, zip, loaPerson);
		this.setState({ importLoading: false, importResponse });
	};

	renderCheckImportability() {
		const { checkImportLoading, phoneNumber, isPhoneImportable, checkImportOrderLoading, importOrderStatus } = this.state;

		return (
			<>
				<div className="hosted-messaging__check-phone-importability">
					<div className="hosted-messaging__check-phone-importability-input form-group">
						<label className="control-label">
							<span data-tip data-for="phone-number-tooltip">
								Phone Number <Icon.Info />
							</span>
							<ReactTooltip id="phone-number-tooltip" className="mb-react-tooltip text-left" arrowColor="#333" type="info" effect="solid" place="right">
								According to the API this is used to "check if the number can be brought in to your Bandwidth account". However, "A non-errored response ...
								doesn't necessarily mean that the order will succeed, but is a good indicator of success."
							</ReactTooltip>
						</label>
						<input
							id="phone-number"
							name="phoneNumber"
							className="form-control"
							type="text"
							onChange={e => this.handlePhoneNumberOnChange(e)}
							autoComplete="off"
							value={phoneNumber}
						/>
					</div>
					<button
						className="btn btn-success hosted-messaging__check-phone-importability-check-btn"
						disabled={this.isCheckImportabilityDisabled()}
						onClick={this.checkImportability}
					>
						{checkImportLoading ? "Loading..." : "Check Importability"}
					</button>
					<button
						className="btn btn-success hosted-messaging__check-phone-importability-check-btn"
						disabled={this.isCheckImportOrderDisabled()}
						onClick={this.checkImportOrderStatus}
					>
						{checkImportOrderLoading ? "Loading..." : "Check Import Order Status"}
					</button>
				</div>
				<div className="hosted-messaging__check-phone-importability-icon">
					{isPhoneImportable !== null && (
						<>
							{isPhoneImportable ? (
								<div className="success">
									<Icon.CheckCircle /> Success! This number supports Hosted Messaging!
								</div>
							) : (
								<div className="fail">
									<Icon.XCircle /> Fail. This number does not support Hosted Messaging.
								</div>
							)}
						</>
					)}
					{importOrderStatus !== null && (
						<>
							{!importOrderStatus.error ? (
								<div className="success">
									<Icon.CheckCircle /> {importOrderStatus.message} Status: "{importOrderStatus.status}"
								</div>
							) : (
								<div className="fail">
									<Icon.XCircle /> {importOrderStatus.message}
								</div>
							)}
						</>
					)}
				</div>
				{isPhoneImportable !== null && (
					<small>
						Please double check that the phone number entered is a <b>landline</b> number.
					</small>
				)}
			</>
		);
	}

	renderImportNumber() {
		const { importLoading, isPhoneImportable, importResponse } = this.state;

		return (
			<>
				{isPhoneImportable !== null && (
					<div className="hosted-messaging__import-number form-horizontal">
						<div>
							<h3>Landline Information</h3>
						</div>
						<div className="form-group">
							<div className="form-group">
								<label className="col-sm-2 control-label">Business Name</label>
								<div className="col-sm-10">
									<input
										name="name"
										id="name"
										type="text"
										onChange={this.handleGenericEventHandler}
										value={this.state.name}
										className="form-control"
										required
									/>
								</div>
							</div>
							<div className="form-group">
								<label className="col-sm-2 control-label">Street Address Number</label>
								<div className="col-sm-10">
									<input
										name="streetNumber"
										id="street-number"
										type="text"
										onChange={this.handleGenericEventHandler}
										value={this.state.streetNumber}
										className="form-control"
										required
									/>
								</div>
							</div>
							<div className="form-group">
								<label className="col-sm-2 control-label">Street Name</label>
								<div className="col-sm-10">
									<input
										name="streetName"
										id="street-name"
										type="text"
										onChange={this.handleGenericEventHandler}
										value={this.state.streetName}
										className="form-control"
										required
									/>
								</div>
							</div>
							<div className="form-group">
								<label className="col-sm-2 control-label">City</label>
								<div className="col-sm-10">
									<input
										name="city"
										id="city"
										type="text"
										onChange={this.handleGenericEventHandler}
										value={this.state.city}
										className="form-control"
										required
									/>
								</div>
							</div>
							<div className="form-group">
								<label className="col-sm-2 control-label">State Code (2 letters)</label>
								<div className="col-sm-10">
									<input
										name="stateCode"
										id="state-code"
										type="text"
										onChange={this.handleGenericEventHandler}
										value={this.state.stateCode}
										className="form-control"
										required
									/>
								</div>
							</div>
							<div className="form-group">
								<label className="col-sm-2 control-label">Zip</label>
								<div className="col-sm-10">
									<input name="zip" id="zip" type="text" onChange={this.handleGenericEventHandler} value={this.state.zip} className="form-control" required />
								</div>
							</div>
							<div className="form-group">
								<label className="col-sm-2 control-label">Full name of person who authorized LOA (i)</label>
								<div className="col-sm-10">
									<input
										name="loaPerson"
										id="loaPerson"
										type="text"
										onChange={this.handleGenericEventHandler}
										value={this.state.loaPerson}
										className="form-control"
										required
									/>
								</div>
							</div>
						</div>
						<button className="btn btn-success" onClick={this.importNumber} disabled={this.isImportNumberDisabled()}>
							{importLoading ? "Loading..." : "Import Number"}
						</button>
						<div className="hosted-messaging__check-phone-importability-icon">
							{importResponse !== null && (
								<>
									{!importResponse.error ? (
										<div className="success">
											<Icon.CheckCircle /> {importResponse.message} Status: "{importResponse.status}"
										</div>
									) : (
										<div className="fail">
											<Icon.XCircle /> {importResponse.message}
										</div>
									)}
								</>
							)}
						</div>
					</div>
				)}
			</>
		);
	}

	render() {
		return (
			<div>
				<div className="row wrapper border-bottom white-bg page-heading">
					<div className="ReviewSitesAnalytics__page--header">
						<div className="col-sm-6 col-md-8 col-lg-9">
							<h2>Hosted Messaging</h2>
						</div>
					</div>
				</div>
				<div className="wrapper wrapper-content">
					<div className="row">
						<div className="col-lg-12">
							<div className="ibox">
								<div className="ibox-content">
									<div>
										<h3>Check phone number for Hosted Messaging support</h3>
										{this.renderCheckImportability()}
										{this.renderImportNumber()}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(HostedMessaging);
