import axios from "axios";
import AppConfig from "../config/app/web-app.config.js";

const StatusService = {
	/**
	 * Checks if the backend status is reachable, if not, we don't have internet
	 *
	 * @return {Object}
	 */
	async checkBackendStatus() {
		try {
			let response = await axios({ method: "get", url: AppConfig.APPLICATION_STATUS });

			return {
				primaryStatus: response.data.primary_status,
				webAppStatus: response.data.environments[0].subsystems.web_app.status
			};
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Checks if the backend status is reachable, if not, we don't have internet
	 *
	 * @return {Boolean}
	 */
	async checkOnlineStatus() {
		try {
			await axios({ method: "get", url: AppConfig.APPLICATION_STATUS });
			return true;
		} catch (error) {
			return false;
		}
	}
};

export default StatusService;
