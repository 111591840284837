import APIDoc from "../api/api.js";
import Kichiri from "react-kichiri";
import { loadServices } from "service-fetch";
import AppConfig from "../config/app/web-app.config.js";

loadServices({
	apiDoc: APIDoc,
	host: AppConfig.API_SERVER + "/api"
});

// HACK: Temporarily adding /api until we phase out older APIServer calls.
export default new Kichiri(APIDoc, AppConfig.API_SERVER + "/api", false).api;
