import React from "react";
import Modal from "react-responsive-modal";

import "../../styles/css/components/commons/dh-modal.css";

class DHModal extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let { children, title, show, onHide, className } = this.props;

		return (
			<Modal
				open={show}
				onClose={onHide}
				classNames={{
					overlay: "modal__overlay",
					modal: `modal__content ${className ? className : ""}`
				}}
			>
				<div ref={this.setModalRef} className="modal__content-container">
					{title && <div className="modal__content__header">{title}</div>}
					<div className="modal__content__body">{children}</div>
				</div>
			</Modal>
		);
	}
}

export default DHModal;
