import React, { Component } from "react";
import isEmail from "validator/lib/isEmail";
import _ from "lodash";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import Dropdown from "./Dropdown";
import Modal from "./DHModal";
import TextBubble from "./TextBubble";

import UtilityService from "../../services/UtilityService";
import LocationService from "../../services/LocationService";
import TemplateService from "../../services/TemplateService";

import ScheduledMessageModal from "../../scenes/ScheduledMessages/ScheduledMessageModal";

import { SEND_REVIEW_INVITES_MODAL } from "../../constants/Invites";
import { SM_TYPES } from "../../constants/ScheduledMessages";

import "../../styles/css/components/commons/invite-remind-modal.css";

class SendReviewInviteModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			reviewTemplates: [],
			defaultReviewTemplate: 0,
			showBulkUploadModal: false,
			location: null,
			templateText: "",
			hasBackup: false,
			submitting: false
		};

		this.debouncedGetTemplateText = _.debounce(
			async (templateId, name) => {
				await this.getTemplateText(templateId, name);
			},
			1000,
			{
				leading: false,
				trailing: true
			}
		);
	}

	componentDidMount() {
		this.getRequestTemplates();
		this.fetchLocation();
	}

	async shouldComponentUpdate(nextProps) {
		if (!this.props.showModal && nextProps.showModal) {
			await this.getRequestTemplates();
			return true;
		}
		return false;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async getRequestTemplates() {
		const locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
		const types = [this.props.reviewType];

		let reviewTemplates = await TemplateService.fetchTemplates({ locationId, types });

		if (!reviewTemplates) {
			reviewTemplates = [];
		}

		await this.update({ reviewTemplates });

		// Find the default review template
		let defaultTemplate = null;

		if (this.props.type === SEND_REVIEW_INVITES_MODAL.type.reviewReminder && this.props.parentTemplate) {
			defaultTemplate = await TemplateService.extractDefaultTemplate(reviewTemplates, this.props.parentTemplate.language);
		} else {
			defaultTemplate = await TemplateService.extractDefaultTemplate(reviewTemplates);
		}

		if (defaultTemplate) {
			await this.update({ defaultReviewTemplate: defaultTemplate.id });
		}

		if (typeof this.props.templateId !== "undefined" && this.props.templateId !== null) {
			let template = reviewTemplates.find(r => r.id === this.props.templateId);
			if (!template) {
				template = reviewTemplates.find(r => r.id === this.state.defaultReviewTemplate);
			}
			if (template) {
				await this.getTemplateText(template.id, this.props.name);
			}
		}
	}

	handleOnSubmit = async () => {
		const { handleSubmit, phoneOrEmail, name, t } = this.props;
		let { defaultReviewTemplate } = this.state;

		if (this.isDisabled()) {
			return;
		}

		if (name === "") {
			this.nameInput.setCustomValidity(t("Name field is required."));
			this.nameInput.reportValidity();
			return;
		}

		// If the phone or email input has not been filled out
		if (phoneOrEmail === "") {
			this.phoneOrEmailInput.setCustomValidity(t("Phone or Email field is required."));
			this.phoneOrEmailInput.reportValidity();
			return;
		}

		// Confirm that the phone or email input it valid
		if (!isEmail(phoneOrEmail)) {
			if (!UtilityService.isMobilePhoneValid(phoneOrEmail)) {
				this.phoneOrEmailInput.setCustomValidity(t("Phone or Email field value is invalid."));
				this.phoneOrEmailInput.reportValidity();
				return;
			}
		}

		if (!handleSubmit) {
			return;
		}

		try {
			await this.update({ submitting: true });
			await handleSubmit(this.props.templateId === 0 ? defaultReviewTemplate : this.props.templateId);
		} catch (error) {
			console.log(error);
		}
		await this.update({ submitting: false });
	};

	async fetchLocation() {
		let location = await LocationService.fetchLocation();
		await this.update({ location: location });
	}

	isDisabled() {
		let { showSending } = this.props;
		let { submitting } = this.state;

		return showSending || submitting;
	}

	onNameChange = async event => {
		const { handleNameChange } = this.props;

		if (handleNameChange) {
			handleNameChange(event);
		}
		await this.debouncedGetTemplateText(this.props.templateId, event.target.value);
	};

	onTemplateChange = async event => {
		let templateId = parseInt(event.target.value, 10);

		if (this.props.handleChangeTemplate) {
			this.props.handleChangeTemplate(templateId);
		}
		await this.getTemplateText(templateId, this.props.name);
	};

	getTemplateText = async (templateId, name) => {
		let overwriteReplacements = {};

		if (name && name.length > 0) {
			overwriteReplacements.contactFull = name;
		}

		if (!templateId) {
			templateId = this.state.reviewTemplates.find(r => r.id === this.state.defaultReviewTemplate).id;
		}

		if (this.state.reviewTemplates.length === 1) {
			templateId = this.state.reviewTemplates[0].id;
		}

		let template = await TemplateService.getTemplate({
			templateId,
			replaceMessageVariables: true,
			locationId: UserService.getActiveLocation().id,
			overwriteReplacements
		});

		if (!template) {
			return;
		}

		await this.update({ templateText: template.msg_text, hasBackup: template.has_backup });
	};

	closeBulkModal = async () => {
		await this.update({ showBulkUploadModal: false });
	};

	renderForm() {
		const { handleOnHide, duplicateNumber, duplicateDate, phoneOrEmail, handlePhoneOrEmailChange, name, type, showPreview, t } = this.props;
		let { templateText, hasBackup } = this.state;

		let showSendMany =
			type.id === SEND_REVIEW_INVITES_MODAL.type.invite.id &&
			LocationService.isCreateReviewsInvitesEnabled() &&
			LocationService.isScheduledReviewInvitesEnabled();

		return (
			<div className="modal__flex-container">
				<div className="modal__field">
					{t("Name")}
					<span className="text-danger">*</span>
				</div>
				<input
					id="name-field"
					type="text"
					onChange={this.onNameChange}
					placeholder={t("Name")}
					value={name}
					className="Common__input"
					ref={input => (this.nameInput = input)}
					onInvalid={() => this.nameInput.setCustomValidity(t("Name field is required."))}
					onInput={() => this.nameInput.setCustomValidity("")}
					required
					autoFocus
				/>

				<div className="modal__field">
					{t("Phone or Email")}
					<span className="text-danger">*</span>
				</div>
				<div id="last-sent" className={duplicateNumber ? "text-danger" : "text-success"}>
					({t("Last Sent:")} {duplicateNumber ? new Date(duplicateDate).toDateString() : t("Never")})
				</div>
				<input
					id="contact-field"
					type="text"
					onChange={event => handlePhoneOrEmailChange({ target: { value: event.target.value } })}
					placeholder={t("Phone number or email")}
					ref={input => (this.phoneOrEmailInput = input)}
					value={phoneOrEmail}
					className="Common__input"
					onInput={() => this.phoneOrEmailInput.setCustomValidity("")}
				/>

				{this.state.reviewTemplates.length > 1 && (
					<>
						<div className="modal__field">{t("Template")}</div>
						<Dropdown
							cClass="Common__input fnctst-template-selector"
							cStyle={{ marginBottom: "3.5%" }}
							data={this.state.reviewTemplates}
							value={this.props.templateId === 0 ? this.state.defaultReviewTemplate : this.props.templateId}
							onChange={this.onTemplateChange}
						/>
					</>
				)}

				{showPreview && (
					<div className="send-review-invite-modal__text-bubble">
						{hasBackup && (
							<span className="send-review-invite-modal__info" data-tip data-for="template-has-backup">
								<Icon.Info size={14} />
								<ReactTooltip id="template-has-backup" className="mb-react-tooltip text-left" arrowColor="#333" type="info" effect="solid" place="top">
									{t("This template has a backup. In cases where a message variable can't be used the template backup will be used...")}
								</ReactTooltip>
							</span>
						)}

						<TextBubble text={templateText && templateText.length > 0 ? templateText : t("Loading ...")} blue={true} />
					</div>
				)}

				<button id="final-send-invite" className={`mb-button ${this.isDisabled() ? "mb-button--disabled" : ""}`} onClick={this.handleOnSubmit}>
					{t("Send")}
					{" " + type.name}
				</button>

				{showSendMany && (
					<div
						id="bulk-send-button"
						onClick={() => {
							handleOnHide();
							this.setState({
								showBulkUploadModal: true
							});
						}}
						className="text-center"
					>
						{t("Send Many")}
					</div>
				)}
			</div>
		);
	}

	render() {
		const { showModal, handleOnHide, title } = this.props;
		let { showBulkUploadModal } = this.state;

		return (
			<>
				<Modal show={showModal} onHide={handleOnHide} title={title} className="send-review-invite-modal">
					{this.renderForm()}
				</Modal>

				<ScheduledMessageModal show={showBulkUploadModal} onHide={this.closeBulkModal} type={SM_TYPES.reviewInvite} />
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(SendReviewInviteModal);
