import React, { Component } from "react";
import Select from "react-select";
import ReactSwitch from "react-switch";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";
import * as Icon from "react-feather";

import Action from "../../../components/common/Action";

import UserService from "../../../services/UserService";
import LocationService from "../../../services/LocationService";

import "../../../styles/css/components/commons/workflows.css";

class ActionConfig extends Component {
	constructor(props) {
		super(props);

		this.state = {
			checked: false,
			options: [],
			value: "",
			selectedValue: null,
			isMultiSelectable: false
		};
	}

	componentDidMount = async () => {
		await this.resetComponent();
	};

	componentDidUpdate(prevProps) {
		let { workflow, field } = this.props;

		if (JSON.stringify(prevProps.workflow) !== JSON.stringify(workflow)) {
			this.resetComponent();
		}
		if (field && prevProps.field && field.id !== prevProps.field.id) {
			this.resetComponent();
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({
			checked: false,
			options: [],
			value: "",
			isMultiSelectable: false
		});
		await this.fetchOptions();
	};

	isReadOnly = () => {
		let user = UserService.get();
		return !user.GroupPermission.update_workflows;
	};

	fetchOptions = async () => {
		try {
			let { workflow, field, workflowsConfig } = this.props;

			if (!workflow || !field) {
				return;
			}

			let value = ""; // Field value
			let options = []; // The options if any
			let selectedValue = null; // Used for the custom fields selection
			let isMultiSelectable = false; // Whether the dropdown should allow selecting multiple options

			if (field.inputType === "select") {
				options = field.options;

				// If we have a currently set value, find it in our options
				if (workflow.action_config && workflow.action_config[field.field]) {
					value = options.find(o => o.value === workflow.action_config[field.field]);
				} else {
					value = options.find(o => o.value === field.default);
				}

				// Check if we are multi select
				if (typeof field.isMultiSelectable !== "undefined") {
					isMultiSelectable = field.isMultiSelectable;
				}
			} else if (field.inputType === "text") {
				value = workflow.action_config && typeof workflow.action_config[field.field] !== "undefined" ? workflow.action_config[field.field] : field.default;
			} else if (field.inputType === "switch") {
				value = workflow.action_config && typeof workflow.action_config[field.field] !== "undefined" ? workflow.action_config[field.field] : field.default;
			} else if (field.inputType === "object") {
				value = workflow.action_config && typeof workflow.action_config[field.field] !== "undefined" ? workflow.action_config[field.field] : field.default;

				if (!value) {
					value = {};
				}

				selectedValue = [];

				if (this.isActionSetCustomFields()) {
					let customFields = await LocationService.getCustomFields({ locationId: UserService.getActiveLocation().id, limit: 25 });
					if (!customFields) {
						customFields = [];
					}

					options = customFields.map(cF => {
						return { label: cF.name, value: cF.field, id: cF.id };
					});

					selectedValue = options.filter(op => value[op.id]);
				}
			}

			await this.update({
				options,
				value,
				selectedValue,
				isMultiSelectable
			});
		} catch (error) {
			console.log(error);
		}
	};

	isInputTypeObject = () => {
		let { field } = this.props;

		return field.inputType === "object";
	};

	isActionSetCustomFields = () => {
		let { workflow, field, workflowsConfig } = this.props;

		return (
			this.isInputTypeObject() &&
			workflowsConfig &&
			workflow.action_type === workflowsConfig.actionInfo.set_contact_custom_field.id &&
			field.id === workflowsConfig.actionConfig.customFields.id
		);
	};

	onChange = (field, value) => {
		this.update({
			value
		});

		if (this.props.onChange) {
			this.props.onChange(value, field);
		}
	};

	onRemoveKeyFromObject = (field, key) => {
		let { value, selectedValue } = this.state;

		if (!value) {
			value = {};
		}

		delete value[key];

		if (this.isActionSetCustomFields()) {
			selectedValue = selectedValue.filter(sV => sV.id !== key);
			this.update({ selectedValue });
		}

		this.onChange(field, value);
	};

	onAddKeyToObject = (field, option) => {
		let { value, selectedValue, options } = this.state;

		if (!value) {
			value = {};
		}

		let keys = Object.keys(value);

		if (option) {
			let newValue = {};

			options.map(o => {
				if (option.find(op => op.id === o.id)) {
					newValue[o.id] = value[o.id] || "";
				} else {
					delete newValue[o.id];
				}
			});
			value = newValue;

			selectedValue = option;
			this.update({ selectedValue });
		} else {
			value[`key-${keys.length + 1}`] = { key: `key-${keys.length + 1}`, value: "" };
		}

		this.onChange(field, value);
	};

	onObjectKeyChange = (field, key, newKey) => {
		let { value } = this.state;

		if (!value) {
			value = {};
		}

		value[newKey] = value[key];
		delete value[key];

		this.onChange(field, value);
	};

	isNumeric = value => {
		return value === "0" || value === 0 || parseInt(value);
	};

	onObjectKeyValueChange = (field, key, keyValue) => {
		let { value } = this.state;

		if (!value) {
			value = {};
		}

		if (this.isNumeric(keyValue)) {
			value[key] = parseInt(keyValue);
		} else {
			value[key] = keyValue;
		}

		this.onChange(field, value);
	};

	onSelectChange = (field, option) => {
		let value = null;

		if (Array.isArray(option)) {
			value = option.map(o => o.value);
		} else if (option) {
			value = option.value;
		}

		if (this.props.onChange) {
			this.props.onChange(value, field);
		}

		this.update({ value: option });
	};

	render() {
		let { workflow, field, t } = this.props;
		let { value, options, isMultiSelectable, selectedValue } = this.state;

		let allowObjectKeyEdit = this.isActionSetCustomFields();

		return (
			<div className="wac">
				<div className="modal__field wac__field" data-tip data-for={`rtt-${field.id}`}>
					{field.required && <div className="wac__field__required">*</div>}
					<div>{field.name}</div>
					{this.isInputTypeObject() && !this.isActionSetCustomFields() && (
						<Action
							className="wac__field__action"
							key={`action-config-add-key-${field.id}`}
							id={`action-config-add-key-${field.id}`}
							label="Add"
							icon={Icon.PlusCircle}
							onClick={() => this.onAddKeyToObject(field.field)}
						/>
					)}
				</div>
				<ReactTooltip id={`rtt-${field.id}`} className="mb-react-tooltip wac__tooltip" type="info" effect="solid" place="top" arrowColor="#333">
					{field.description}
				</ReactTooltip>
				{field.inputType === "select" && (
					<Select
						id={`${field.id}-select`}
						options={options}
						value={value}
						onChange={option => this.onSelectChange(field.field, option)}
						placeholder={t("Not Assigned")}
						isDisabled={this.isReadOnly()}
						isClearable={isMultiSelectable}
						isMulti={isMultiSelectable || false}
					/>
				)}
				{field.inputType === "text" && (
					<input
						id={`${field.id}-txt`}
						name={field.field}
						className="Common__input"
						placeholder={t("Value ...")}
						value={value}
						onChange={event => this.onChange(field.field, event.target.value)}
						disabled={this.isReadOnly()}
					/>
				)}
				{field.inputType === "switch" && (
					<ReactSwitch
						id={`${field.id}-switch`}
						checked={value || false}
						uncheckedIcon={false}
						checkedIcon={false}
						onColor="#4A90E2"
						onChange={checked => this.onChange(field.field, checked)}
						disabled={this.isReadOnly()}
					/>
				)}
				{this.isActionSetCustomFields() && (
					<div>
						<div>Custom fields</div>
						<Select
							id={`${field.id}-set-custom-fields-select`}
							options={options}
							defaultValue={selectedValue}
							value={selectedValue}
							onChange={option => this.onAddKeyToObject(field.field, option)}
							placeholder="Select custom field..."
							isDisabled={this.isReadOnly()}
							isClearable={true}
							isMulti={true}
						/>
					</div>
				)}
				{this.isInputTypeObject() && (
					<div className="wac__object">
						{options &&
							options.map((option, index) => {
								if (typeof value[option.id] === "undefined") {
									return null;
								}
								return (
									<div key={index} className="wac__object__field">
										<div className="wac__object__field__input">
											<input
												className="Common__input"
												value={option.label || ""}
												placeholder="Custom field"
												onChange={event => this.onObjectKeyChange(field.field, option.id, event.target.value)}
												disabled={allowObjectKeyEdit || this.isReadOnly()}
											/>
										</div>
										<div className="wac__object__field__input">
											<input
												className="Common__input"
												value={value[option.id] || ""}
												placeholder="Set To Value"
												onChange={event => this.onObjectKeyValueChange(field.field, option.id, event.target.value)}
												disabled={this.isReadOnly()}
											/>
										</div>
										{!this.isReadOnly() && (
											<div className="wac__object__field__remove">
												<Action
													className=""
													key={`action-config-field-${field.id}-${index}`}
													id={`action-config-field-${field.id}-${index}`}
													label="Delete"
													icon={Icon.MinusCircle}
													onClick={() => this.onRemoveKeyFromObject(field.field, option.id)}
												/>
											</div>
										)}
									</div>
								);
							})}
					</div>
				)}
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(ActionConfig);
