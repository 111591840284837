import React, { Component } from "react";
import * as Icon from "react-feather";
import { Collapse } from "react-bootstrap";
import _ from "lodash";

import Linkify from "../../../../components/common/Linkify";
import Modal from "../../../../components/common/DHModal";
import SearchInput from "../../../../components/common/SearchInput";
import ThreadMedia from "../Media/ThreadMedia";

import UserService from "../../../../services/UserService";
import UtilityService from "../../../../services/UtilityService";
import TeamChatService from "../../../../services/TeamChatService";
import MessagesService from "../../../../services/MessagesService";

import { CONVERSATION } from "../../../../constants/Messenger";

import "./forward-message.css";
import TextArea from "../../../../components/common/TextArea";
import ToastService from "../../../../services/ToastService";

class ForwardMessage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sending: false,
			forwardMessage: null,
			searchTerm: "",
			noteMessage: "",
			selectedConversation: null,
			channelConversations: [],
			channelsExpanded: true,

			dmConversations: [],
			dmsExpanded: true
		};

		this.debouncedFetchConversations = _.debounce(
			() => {
				this.fetchConversations();
			},
			1000,
			{
				leading: true,
				trailing: true
			}
		);
	}

	componentDidMount() {
		this.resetComponent();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	genericTextChangeHandler = async event => {
		await this.update({ [event.target.name]: event.target.value, changesMade: true });
	};

	resetComponent = () => {
		this.update({
			sending: false,
			forwardMessage: null,
			noteMessage: "",
			selectedConversation: null,
			channelConversations: [],
			channelsExpanded: true,

			dmConversations: [],
			dmsExpanded: true
		});
		this.fetchConversations();
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		this.debouncedFetchConversations();
	};

	async fetchConversations() {
		let { channelConversations, dmConversations, searchTerm } = this.state;
		let user = UserService.get();
		channelConversations = await TeamChatService.fetchConversations({ userId: user.id, type: CONVERSATION.channel, searchTerm });

		dmConversations = await TeamChatService.fetchConversations({ userId: user.id, type: CONVERSATION.dm, searchTerm });
		await this.update({
			channelConversations,
			dmConversations
		});
	}

	onForwardMessage = async () => {
		let { selectedConversation, noteMessage, sending } = this.state;
		let { message } = this.props;

		if (sending) {
			return;
		}

		await this.update({
			sending: true
		});

		let responseMessage = await MessagesService.sendInternalMessage({
			locationId: UserService.getActiveLocation().id,
			conversationId: selectedConversation.id,
			content: noteMessage,
			forward: {
				id: message.id,
				contactId: message.Contact.id,
				name: message.Contact.name,
				content: message.content,
				mediaId: message.media && message.media.length > 0 ? message.media[0].id : null,
				toConvoName: selectedConversation.name,
				toConvoType: selectedConversation.type
			}
		});

		if (!responseMessage) {
			ToastService.error("An error occurred trying to share this message.");
			await this.update({
				sending: false
			});
			return;
		}

		this.resetComponent();

		if (this.props.onSend) {
			this.props.onSend();
		}

		ToastService.info("Shared message");
	};

	onBack = () => {
		let { sending } = this.state;

		if (sending) {
			return;
		}

		this.update({ selectedConversation: null });
	};

	onConversationHeaderClick = type => {
		if (type === CONVERSATION.channel) {
			this.update({ channelsExpanded: !this.state.channelsExpanded });
		} else {
			this.update({ dmsExpanded: !this.state.dmsExpanded });
		}
	};

	renderConversationList = type => {
		let list = this.state.channelConversations;
		let isExpanded = this.state.channelsExpanded;
		let header = "Channels";
		let nonFound = "No Channels";

		if (type === CONVERSATION.dm) {
			list = this.state.dmConversations;
			isExpanded = this.state.dmsExpanded;
			header = "Direct";
			nonFound = "No Direct Messages";
		}

		return (
			<>
				<div className="fm-modal__convos__header" onClick={() => this.onConversationHeaderClick(type)}>
					<div>{header}</div> <div>{isExpanded ? <Icon.ChevronDown size={15} /> : <Icon.ChevronUp size={15} />}</div>
				</div>
				<Collapse in={isExpanded}>
					<div className="fm-modal__convos__list">
						{list &&
							list.length > 0 &&
							list.map(convo => (
								<div key={convo.id} className="fm-modal__convos__list__item" onClick={() => this.update({ selectedConversation: convo })}>
									{convo.name}
								</div>
							))}
						{(!list || list.length < 1) && <div className="fm-modal__convos__list__item">{nonFound}</div>}
					</div>
				</Collapse>
			</>
		);
	};

	renderSelectConversation = () => {
		return (
			<>
				<div className="fm-modal__search-input">
					<SearchInput placeholder="Search ..." onChange={this.onSearchChange} />
				</div>
				<div className="fm-modal__convos">
					{this.renderConversationList(CONVERSATION.channel)}
					{this.renderConversationList(CONVERSATION.dm)}
				</div>
			</>
		);
	};

	renderContent(message) {
		let { id, content, direction } = message;

		return content.split("\n").map((blurb, index) => {
			let key = parseInt(`${id}${index}`, 10);

			if (blurb.length === 0) {
				return <br key={key} />;
			}

			let languageDirection = UtilityService.detectLanguageDirection(blurb);

			return (
				<p className="mb-message-content-blurb" dir={languageDirection} key={key}>
					<Linkify classNames={direction === "out" ? ["mb-message-content-link--out"] : []}>{blurb}</Linkify>
				</p>
			);
		});
	}

	renderConfirmForward = () => {
		let { selectedConversation, noteMessage, sending } = this.state;
		let { message } = this.props;

		let header = "Share with ";
		if (selectedConversation.type === CONVERSATION.channel) {
			header += `${selectedConversation.name} channel`;
		} else {
			header += `${selectedConversation.name}`;
		}

		return (
			<div className="fm-modal__confirm">
				<div className="fm-modal__confirm__header">{header}</div>
				<div className="fm-modal__confirm__message">
					{message.media && message.media.length > 0 && (
						<ThreadMedia key={message.media[0].id} media={message.media[0]} maxHeightOrWidth={40} idPrefix="forward" />
					)}
					{this.renderContent(message)}
				</div>
				<div className="fm-modal__confirm__note-message">
					<TextArea
						name="noteMessage"
						id="noteMessage"
						type="text"
						height={50}
						rows={1}
						value={noteMessage}
						showFeedbackFaces={false}
						showFeedbackLength={false}
						showVariables={false}
						showMessageTemplates={false}
						blueBorder={true}
						placeholder="A message..."
						onChange={e => this.genericTextChangeHandler(e)}
					/>
				</div>
				<div className="fm-modal__confirm__buttons">
					<div className={`mb-button mb-button--cancel ${sending ? "mb-button--disabled" : ""}`} onClick={this.onBack}>
						Back
					</div>
					<div className={`mb-button ${sending ? "mb-button--disabled" : ""}`} onClick={this.onForwardMessage}>
						{sending ? "Sending..." : "Send"}
					</div>
				</div>
			</div>
		);
	};

	render() {
		let { selectedConversation } = this.state;
		let { onHide } = this.props;
		return (
			<Modal show={true} onHide={onHide} title="Share Message">
				<div className="fm-modal">
					{!selectedConversation && this.renderSelectConversation()}
					{selectedConversation && this.renderConfirmForward()}
				</div>
			</Modal>
		);
	}
}

export default ForwardMessage;
