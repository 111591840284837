import React from "react";
import { withRouter } from "react-router-dom";
import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import GAService from "../../../services/GAService";
import { withTranslation } from "react-i18next";

import AllPermissions from "./AllPermissions";

class GroupPermissions extends React.Component {
	componentDidMount = async () => {
		GAService.GAPageView({ page: this.props.location.pathname });
	};

	render() {
		let { t } = this.props;

		return (
			<Page>
				<Header title={t("All Group Permissions")} />
				<AllPermissions />
			</Page>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(GroupPermissions));
