import React, { Component } from "react";
import moment from "moment";
import ReactTable from "react-table";

import { AnalyticsService } from "../../../services/AnalyticsService";
import ToastService from "../../../services/ToastService";

import { DATE_FORMAT, SHORT_TIME_FORMAT } from "../../../constants/CommonConstants";
import Spinners from "../../../components/common/Spinners";
import NoDataComponent from "../../../components/common/NoDataComponent";
import matchSorter from "match-sorter";
import PreferenceService from "../../../services/PreferenceService";
import ReactSwitch from "react-switch";
import * as Icon from "react-feather";
import StatusLights from "../../../components/common/StatusLights";
import Alert from "../../../components/common/Alert";

import "../../../styles/css/components/commons/react-table.css";
import "../../../styles/css/scenes/lagging-automations.css";

class CSMessengerAnalytics extends Component {
	state = {
		loading: true,
		messengerStats: [],
		filteredMessengerStats: [],
		cacheTime: null,
		searchString: "",
		companyDesc: true,
		locationDesc: false,
		dateDesc: true,
		daysSinceSentDesc: true,
		lastMessageSentDesc: true,
		daysSinceMessageSentDesc: true,
		openConversationDesc: true,
		unreadMessagesDesc: true,
		openUnrepliedMessagesDesc: true,
		showFavoritesOnly: PreferenceService.get("show_favorites") ? true : false,
		safeDaysSinceLastMessageSent: 3, // show the safe light if a message has been sent between 0-3 days since that last message
		warningDaysSinceLastMessageSent: 6, // 4-6 days since last sent shows the warning, else (7+) show the danger light
		showRefreshWarning: false
	};
	toggleFavoritesOnlySwitch() {
		PreferenceService.set("show_favorites", !this.state.showFavoritesOnly);
		this.setState({ showFavoritesOnly: !this.state.showFavoritesOnly }, this.fetchMessengerStats);
	}
	async fetchMessengerStats(clearCache = false) {
		this.setState({ loading: true, messengerStats: [], cacheTime: null });

		try {
			let messengerStatsData = await AnalyticsService.fetchCSMessengerData({ favoritesOnly: this.state.showFavoritesOnly, clearCache });

			if (!messengerStatsData) {
				ToastService.info(`No data found. Please try again.`);
				throw new Error(`No data found`);
			}

			this.setState({ messengerStats: messengerStatsData.messengerStats, cacheTime: messengerStatsData.cacheTime });
		} catch (e) {
			console.log(e);
		}
		this.setState({ loading: false });
	}
	componentDidMount() {
		this.fetchMessengerStats();
	}
	handleGeneralSearchOnChange(event) {
		const searchString = event.target.value;
		const filteredMessengerStats = [{ id: "all", value: searchString }];

		// NOTE: this completely clears any COLUMN filters
		this.setState({ searchString, filteredMessengerStats });
	}
	render() {
		const {
			cacheTime,
			messengerStats,
			loading,
			filteredMessengerStats,
			searchString,
			showFavoritesOnly,
			safeDaysSinceLastMessageSent,
			warningDaysSinceLastMessageSent,
			showRefreshWarning
		} = this.state;
		return (
			<div>
				<div className="row wrapper white-bg page-heading">
					<div className="col-sm-6 col-md-8 col-lg-9">
						<h2>
							Messenger Analytics{" "}
							<span
								id="refresh"
								style={{ marginLeft: "10px", cursor: "pointer", userSelect: "none", transform: "translate(0px, 3px)", position: "absolute" }}
								onClick={() => this.setState({ showRefreshWarning: true })}
							>
								<Icon.RefreshCcw />
							</span>
							<br />
							<br />
							<input
								style={{ width: "400px" }}
								type="text"
								placeholder="Filter Messenger Analytics..."
								className="form-control filter-textbox"
								value={searchString}
								onChange={e => this.handleGeneralSearchOnChange(e)}
							/>
						</h2>
						{messengerStats && (
							<div className="lagging-automations__last-msg-sent">
								<div>Last Ran At: {cacheTime ? `(${moment(cacheTime).format("MMM D - h:mm a")})` : null}</div>
								<h4>Last Message Sent State Count</h4>
								<div className="lagging-automations__last-msg-sent__counts">
									<div className="lagging-automations__last-msg-sent__counts__count">
										<StatusLights size={17} status="safe" />{" "}
										{
											messengerStats.filter(
												stat => stat.days_since_last_message_sent !== "N/A" && stat.days_since_last_message_sent <= safeDaysSinceLastMessageSent
											).length
										}
									</div>
									<div className="lagging-automations__last-msg-sent__counts__count">
										<StatusLights size={17} status="warning" />{" "}
										{
											messengerStats.filter(
												stat =>
													stat.days_since_last_message_sent !== "N/A" &&
													stat.days_since_last_message_sent > safeDaysSinceLastMessageSent &&
													stat.days_since_last_message_sent <= warningDaysSinceLastMessageSent
											).length
										}
									</div>
									<div className="lagging-automations__last-msg-sent__counts__count">
										<StatusLights size={17} status="danger" />{" "}
										{
											messengerStats.filter(
												stat =>
													stat.days_since_last_message_sent !== "N/A" &&
													stat.days_since_last_message_sent > safeDaysSinceLastMessageSent &&
													stat.days_since_last_message_sent > warningDaysSinceLastMessageSent
											).length
										}
									</div>
								</div>
							</div>
						)}
					</div>
					<div
						className="col-sm-6 col-md-4 col-lg-3"
						style={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							marginTop: "20px",
							paddingRight: "20px"
						}}
					>
						<div>
							<b>All</b>
						</div>
						<div style={{ marginRight: "5px", marginLeft: "5px" }}>
							<ReactSwitch
								id="show_favorites_only"
								onChange={() => this.toggleFavoritesOnlySwitch()}
								checked={showFavoritesOnly}
								uncheckedIcon={false}
								checkedIcon={false}
								onColor="#4A90E2"
								type="text"
							/>
						</div>
						<div>
							<b>Favourites</b>
						</div>
					</div>
				</div>
				<div className="wrapper wrapper-content">
					<div className="ibox">
						<div className="row">
							<div className="col-sm-12">
								<div className="ibox-content">
									<ReactTable
										data={messengerStats}
										defaultSorted={[
											{
												id: "location",
												desc: false
											}
										]}
										onSortedChange={sorted => {
											if (sorted.length !== 1) {
												return;
											}
											this.setState({ [sorted[0].id + "Desc"]: sorted[0].desc });
										}}
										filtered={filteredMessengerStats}
										onFilteredChange={filteredMessengerStats => this.setState({ filteredMessengerStats })}
										columns={[
											{
												Header: () => (
													<div className="DHReactTable__header--container">
														Company {this.state.companyDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
													</div>
												),
												headerClassName: "DHReactTable__row--header",
												accessor: "company_name",
												className: "DHReactTable__cell",
												id: "company",
												Cell: row => <span className="DHReactTable__row">{row.value}</span>
											},
											{
												Header: () => (
													<div className="DHReactTable__header--container">
														Location {this.state.locationDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
													</div>
												),
												headerClassName: "DHReactTable__row--header",
												accessor: "name",
												className: "DHReactTable__cell",
												id: "location",
												Cell: row => <span className="DHReactTable__row">{row.value}</span>
											},
											{
												Header: "Last Message Sent",
												headerClassName: "DHReactTable__row--header",
												columns: [
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Date {this.state.lastMessageSentDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "last_non_auto_message",
														className: "DHReactTable__cell",
														id: "lastMessageSent",
														Cell: row => {
															if (row.value === "N/A") {
																return "N/A";
															}
															return (
																<div className="DHReactTable__row">
																	{`${moment(row.value).format(DATE_FORMAT)}`}
																	<span className="hidden-md hidden-sm hidden-xs">{` at ${moment(row.value).format(SHORT_TIME_FORMAT)}`}</span>
																</div>
															);
														},
														sortMethod: (a, b) => {
															a = a === null || a === undefined || a === "N/A" || !a ? -Infinity : moment(a).unix();
															b = b === null || b === undefined || b === "N/A" || !b ? -Infinity : moment(b).unix();
															// Return either 1 or -1 to indicate a sort priority
															if (a > b) {
																return 1;
															}
															if (a < b) {
																return -1;
															}
															// Returning 0, undefined or any falsey value will use subsequent sorts or the index as a tiebreaker
															return 0;
														}
													},
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Days Since Sent {this.state.daysSinceMessageSentDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "days_since_last_message_sent",
														className: "DHReactTable__cell",
														id: "daysSinceMessageSent",
														sortMethod: (a, b) => {
															// Force null and undefined to the bottom
															a = a === null || a === undefined || a === "N/A" ? -Infinity : a;
															b = b === null || b === undefined || b === "N/A" ? -Infinity : b;
															// Force any string values to lowercase
															a = a === "string" ? a.toLowerCase() : a;
															b = b === "string" ? b.toLowerCase() : b;
															// Return either 1 or -1 to indicate a sort priority
															if (a > b) {
																return 1;
															}
															if (a < b) {
																return -1;
															}
															// returning 0, undefined or any falsey value will use subsequent sorts or the index as a tiebreaker
															return 0;
														},
														filterable: true,
														filterMethod: (filter, row) => {
															const days = row.daysSinceMessageSent === -1 ? 0 : row.daysSinceMessageSent;
															if (filter.value === "all") return true;
															if (filter.value === "7") return days >= 7 && days < 10;
															if (filter.value === "10") return days >= 10 && days < 13;
															if (filter.value === "14") return days >= 14;
														},
														Filter: ({ filter, onChange }) => (
															<select
																className="form-control"
																onChange={event => onChange(event.target.value)}
																style={{ width: "100%" }}
																value={filter ? filter.value : "all"}
															>
																<option value="all">Show All</option>
																<option value="7">Last 7 days</option>
																<option value="10">Last 10 days</option>
																<option value="14">Last 14 days</option>
															</select>
														),
														Cell: row => {
															let statusLight = "";
															if (row.value <= safeDaysSinceLastMessageSent) {
																statusLight = <StatusLights size={17} status="safe" />;
															} else if (row.value <= warningDaysSinceLastMessageSent) {
																statusLight = <StatusLights size={17} status="warning" />;
															} else {
																statusLight = <StatusLights size={17} status="danger" />;
															}
															return (
																<div className="DHReactTable__row">
																	{row.value !== "N/A" && <span>{statusLight}</span>}
																	<span style={{ marginLeft: "8px" }}>{row.value === -1 ? 0 : row.value}</span>
																</div>
															);
														}
													}
												]
											},
											{
												Header: "Conversations",
												headerClassName: "DHReactTable__row--header",
												columns: [
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Open {this.state.openConversationDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "open_conversation_count",
														className: "DHReactTable__cell",
														id: "openConversation",
														width: 80,
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? 0 : row.value} </span>
													},
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Unread {this.state.unreadMessagesDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "unread_messages_count",
														className: "DHReactTable__cell",
														id: "unreadMessages",
														width: 80,
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? 0 : row.value} </span>
													},
													{
														Header: () => (
															<div className="DHReactTable__header--container">
																Open & Unreplied {this.state.openUnrepliedMessagesDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															</div>
														),
														headerClassName: "DHReactTable__row--header",
														accessor: "unreplied_open_conversations_count",
														className: "DHReactTable__cell",
														id: "openUnrepliedMessages",
														width: 150,
														resizable: false,
														Cell: row => <span className="DHReactTable__row">{row.value === -1 ? 0 : row.value} </span>
													}
												]
											},
											{
												Header: "All",
												id: "all",
												width: 0,
												headerClassName: "hide",
												resizable: false,
												sortable: false,
												Filter: () => {},
												getProps: () => {
													return {
														// style: { padding: "0px"}
													};
												},
												filterMethod: (filter, rows) => {
													// using match-sorter
													// it will take the content entered into the "filter"
													// and search for it in EITHER the firstName or lastName
													const result = matchSorter(rows, filter.value, {
														keys: ["company", "location"],
														threshold: matchSorter.rankings.CONTAINS
													});
													return result;
												},
												filterAll: true
											}
										]}
										LoadingComponent={() => (
											<div className="Common__spinnerdiv--center">
												<Spinners loading={loading} type="tail-fade" size="120px" />
											</div>
										)}
										NoDataComponent={() => <NoDataComponent loading={loading} />}
										showPagination={messengerStats && messengerStats.length > 10}
										PreviousComponent={props => (
											<button {...props} className="btn btn-default btn-outline btn-sm pagination-item">
												<Icon.ChevronLeft style={{ paddingTop: "4px" }} size="20" />
											</button>
										)}
										NextComponent={props => (
											<button {...props} className="btn btn-default btn-outline btn-sm pagination-item">
												<Icon.ChevronRight style={{ paddingTop: "4px" }} size="20" />
											</button>
										)}
										defaultPageSize={10}
										className="-striped -highlight"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Alert
					type="Info"
					show={showRefreshWarning}
					title="Warning"
					cancel="Cancel"
					confirm="Refresh"
					onClose={confirmed => {
						this.setState({ showRefreshWarning: false });
						if (confirmed) {
							this.fetchMessengerStats(true);
						}
					}}
				>
					<div>This will refresh the data and clear any cached data. This is a heavier query. Would you like to proceed?</div>
				</Alert>
			</div>
		);
	}
}

export default CSMessengerAnalytics;
