import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as Icon from "react-feather";
import c3 from "c3";
import { CSVLink } from "react-csv";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

import GAService from "../../services/GAService";
import { CsvService } from "../../services/CsvService";
import { AnalyticsService } from "../../services/AnalyticsService";
import UserService from "../../services/UserService";
import ToastService from "../../services/ToastService";

import withLocation from "../../components/common/WithLocation";
import Header from "../../components/common/Header";
import Page from "../../components/common/Page";
import List from "../../components/common/List";
import Spinners from "../../components/common/Spinners";
import EditContactModal from "../../components/common/EditContactModal";
import Action from "../../components/common/Action";

import { CHART_COLORS, SORT_ORDER } from "../../constants/CommonConstants";
import { STATUS, CONVERSION_APPOINTMENTS_COLUMNS } from "../../constants/Appointments";

class ConversionAnalytics extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			conversions: [],
			conversionsCsvData: null,
			conversionAnalyticsLoading: false,
			reengagements: [],
			appointments: [],
			appointmentsCsv: [],
			appointmentsLoading: false,
			start: moment()
				.subtract(30, "days")
				.startOf("day")
				.toDate(),
			end: moment()
				.endOf("day")
				.toDate(),
			showEditContactModal: false,
			showContactDetailsContactId: null
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	resetComponent = async () => {
		await this.fetchData();
		this.updateCharts();
		await this.fetchAppointments();
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = location => {
		this.resetComponent();
	};

	fetchData = async () => {
		await this.update({ conversionAnalyticsLoading: true });

		let { start, end } = this.state;

		let result = await AnalyticsService.getConversionsAnalytics({ locationId: UserService.getActiveLocation().id, start, end });

		if (!result) {
			ToastService.error("Error getting converstion data. Please try again.");
			return;
		}

		let csvData = [];
		let conversions = result.conversions;
		let reengagements = result.reengagements;

		for (let i = 0; i < conversions.length; i++) {
			const conversation = conversions[i];

			csvData.push({
				Date: conversation.date,
				["Widget Conversion Count"]: conversation.count,
				["Reengagement Count"]: reengagements[i].count
			});
		}

		await this.update({
			conversionAnalyticsLoading: false,
			conversions: conversions,
			conversionsCsvData: csvData,
			reengagements: reengagements
		});
	};

	fetchAppointments = async () => {
		let { start, end } = this.state;
		await this.update({ appointmentsLoading: true });
		let result = await AnalyticsService.getConversionsAppointments({
			locationId: UserService.getActiveLocation().id,
			start,
			end
		});

		if (!result) {
			await this.update({ appointmentsLoading: false });
			return;
		}

		let appointmentsCsv = result.map(aData => {
			return {
				Name: UserService.createFullName({ firstName: aData.first_name, lastName: aData.last_name }),
				Phone: aData.phone,
				Email: aData.email,
				"Booked At": aData.booking_at,
				State: STATUS[aData.state] ? STATUS[aData.state].display : aData.state,
				Lead: aData.lead_source
			};
		});

		await this.update({ appointments: result, appointmentsCsv, appointmentsLoading: false });
	};

	onContactDetailsClosed = async contact => {
		await this.update({
			showEditContactModal: false
		});
	};

	onContactDetailsSaved = async contact => {
		await this.update({
			showEditContactModal: false
		});
		await this.fetchAppointments();
	};

	updateCharts = () => {
		let { conversions, reengagements, start, end } = this.state;

		if (!conversions || conversions.length < 1) {
			return;
		}

		let dates = conversions.map(item => item.date);
		dates.unshift("x");

		let counts = conversions.map(item => item.count);
		counts.unshift("Widget Conversions");

		let reengagementCounts = reengagements.map(item => item.count);
		reengagementCounts.unshift("Reengagements");

		let columns = [dates, counts, reengagementCounts];

		// Generate a color pattern for other charts
		let colors = [...CHART_COLORS];

		const isMonth = moment(end).diff(moment(start), "days") >= 90;

		this.generateChart(
			"#conversion-analytics-chart",
			{
				x: "x",
				columns: columns,
				type: "bar",
				labels: true
			},
			{
				x: {
					label: "Day",
					type: "timeseries",
					tick: {
						format: function(x) {
							if (isMonth) {
								const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
								return "" + x.getFullYear() + " " + monthString[x.getMonth()];
							}
							return "" + x.getFullYear() + "-" + (x.getMonth() + 1) + "-" + x.getDate();
						},
						rotate: window.innerWidth > 768 ? 0 : 75,
						multiline: false
					}
				},
				y: {
					label: "Counts"
				}
			},
			{
				pattern: colors
			}
		);
	};

	generateChart(id, data, axis = {}, color = {}, legend = {}) {
		try {
			c3.generate({
				bindto: id,
				data: data,
				axis: axis,
				color: color,
				legend: legend
			});
		} catch (error) {
			console.log(`Error generate a chart - ${error.stack}`);
		}
	}

	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.update({ start });
	};

	handleToChange = end => {
		this.update({ end }, this.showFromMonth);
	};

	getHeaders() {
		let headers = {
			items: CONVERSION_APPOINTMENTS_COLUMNS,
			sortBy: () => {}
		};

		return headers;
	}

	onRecordClicked = () => {
		// nothing for now
	};

	renderRecord = recordData => {
		try {
			if (!recordData) {
				return null;
			}
			var fullName = UserService.createFullName({ firstName: recordData.first_name, lastName: recordData.last_name });

			return [
				fullName,
				STATUS[recordData.state] ? STATUS[recordData.state].display : recordData.state,
				recordData.booking_at ? moment(recordData.booking_at).format("MMM Do YYYY hh:mm a") : "",
				recordData.lead_source,
				<Icon.MoreVertical
					size="22"
					style={{ cursor: "pointer", color: "hsla(230,8%,70%,1)" }}
					onClick={() => {
						this.update({
							showEditContactModal: true,
							showContactDetailsContactId: recordData.contact_id
						});
					}}
				/>
			];
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	renderDatePicker = () => {
		const { start, end } = this.state;
		const { isCard, cardName } = this.props;
		const modifiers = { start, end };

		return (
			<div className={`form-group dh-page__datepicker ${isCard ? "dh-page__datepicker--card" : ""}`}>
				<label>Date Range&nbsp;&nbsp;</label>
				<div className="input-group" id="datepicker">
					<div className="InputFromTo">
						<DayPickerInput
							value={start}
							placeholder=" From"
							format="LL"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [start, { from: start, to: end }],
								disabledDays: { after: end },
								toMonth: end,
								modifiers,
								numberOfMonths: 2,
								onDayClick: () => this.to.getInput().focus()
							}}
							onDayChange={start => this.handleFromChange(start)}
						/>{" "}
						<span className="InputFromTo-to">
							<DayPickerInput
								ref={el => (this.to = el)}
								value={end}
								placeholder=" To"
								format="LL"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [start, { from: start, to: end }],
									disabledDays: { before: start },
									modifiers,
									month: start,
									fromMonth: start,
									numberOfMonths: 2
								}}
								onDayChange={this.handleToChange}
							/>
						</span>
					</div>
				</div>{" "}
				<div id="date-update" className="mb-button" onClick={this.resetComponent}>
					Update Date
				</div>
			</div>
		);
	};

	renderAppointments = () => {
		let { appointments, appointmentsCsv, appointmentsLoading, start, end } = this.state;

		const locationName = CsvService.filterActiveLocationName();

		return (
			<>
				<div className="Common__chart-container" style={{ marginTop: 15 }}>
					<div className="dh-page__chart-title">
						<div>Appointments</div>
						{appointmentsCsv && (
							<CSVLink
								data={appointmentsCsv}
								filename={`${locationName}-conversion-appointments-${moment(start).format("YYYY-MM-DD")}-${moment(end).format("YYYY-MM-DD")}_analytics.csv`}
								target="_self"
							>
								<Action label="Download" icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
				</div>

				<List
					items={appointments}
					loading={appointmentsLoading}
					loadedAll={true}
					sortField={"booking_at"}
					sortOrder={SORT_ORDER.desc}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					noDataTitle={"No appointments found..."}
					noDataIcon={<Icon.AlertCircle />}
				/>
			</>
		);
	};

	renderBody = () => {
		const { conversionAnalyticsLoading, start, end, conversionsCsvData, showEditContactModal, showContactDetailsContactId } = this.state;
		const { isEnabled, isPermissible } = this.props;
		const locationName = CsvService.filterActiveLocationName();

		if (conversionAnalyticsLoading) {
			return (
				<div className="Common__spinnerdiv--center">
					<Spinners loading={true} type="tail-fade" size="120px" />
				</div>
			);
		}

		if (isEnabled && !isEnabled()) {
			return (
				<>
					<br />
					<div>Conversions is not enabled. Contact support to get started.</div>
				</>
			);
		}

		if (isPermissible && !isPermissible()) {
			return (
				<>
					<br />
					<div>Widget data is restricted.</div>
				</>
			);
		}

		return (
			<>
				{this.renderDatePicker()}

				<div className="Common__chart-container">
					<div className="dh-page__chart-title">
						<div>Conversions</div>
						{conversionsCsvData && (
							<CSVLink
								data={conversionsCsvData}
								filename={`${locationName}-conversions-${moment(start).format("YYYY-MM-DD")}-${moment(end).format("YYYY-MM-DD")}_analytics.csv`}
								target="_self"
							>
								<Action label="Download" icon={Icon.Download} className="Common__csv-link">
									<Icon.Download />
								</Action>
							</CSVLink>
						)}
					</div>
					<div id="conversion-analytics-chart" />
				</div>

				{this.renderAppointments()}

				<EditContactModal
					show={showEditContactModal}
					contactId={showContactDetailsContactId}
					onClose={this.onContactDetailsClosed}
					onSave={this.onContactDetailsSaved}
				/>
			</>
		);
	};

	render = () => {
		const { isCard, cardName } = this.props;

		if (isCard) {
			return (
				<div className="dashboard__card dashboard__card--h-15 dashboard__card--keyword-usage">
					<div className="dashboard__card__header dashboard__card__header--full-width">
						<div className="dashboard__card__header__name">{cardName ? cardName : "Conversion Analytics"}</div>
					</div>
					<div>{this.renderBody()}</div>
				</div>
			);
		}

		return (
			<Page>
				<Header title="Conversion Analytics" />
				{this.renderBody()}
			</Page>
		);
	};
}

export default withRouter(withLocation(ConversionAnalytics));
