import React, { PureComponent } from "react";
import moment from "moment";
import * as Icon from "react-feather";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import "whatwg-fetch";
import DOMPurify from "dompurify";
import ReactHtmlParser from "react-html-parser";
import Iframe from "react-frame-component";

import { MEDIA_TYPES, DIRECTION, MESSAGE_STATES, MEDIUM, MESSAGE_DELIVERY_STATES, MEDIA_ICON } from "../../../../constants/Messenger";

import MessagesService from "../../../../services/MessagesService";
import UtilityService from "../../../../services/UtilityService";
import UserService from "../../../../services/UserService";
import RealtimeService from "../../../../services/WebsocketsConnection";
import ToastService from "../../../../services/ToastService";
import TeamChatService from "../../../../services/TeamChatService";
import LocationService from "../../../../services/LocationService";

import MessageEvent from "../MessageEvent/MessageEvent";
import MessageError from "./MessageError";
import ThreadMedia from "../Media/ThreadMedia";
import Linkify from "../../../../components/common/Linkify";

import "./message.css";

const EMAIL_IFRAME_STYLES = {
	border: "none",
	borderRadius: "8px",
	width: "calc(100vw - 1000px)",
	minWidth: "300px",
	height: "400px",
	background: "white"
};

class Message extends PureComponent {
	constructor(props) {
		super(props);

		let { message, previousMessage } = props;

		this.context = null;

		this.state = {
			message: message,
			previousMessage: previousMessage,
			isMedia: false,
			media: [],
			isContext: false,
			deleted: false,
			isSpam: false,

			showHTML: false,
			showQuotedEmail: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		document.addEventListener("mousedown", this.handleClick, false);
		await this.buildMediaList();
	}

	buildMediaList = async () => {
		let { message } = this.state;

		let isMedia = message.media.length > 0;

		if (!isMedia) {
			return;
		}

		await this.update({
			media: message.media,
			isMedia
		});
	};

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = e => {
		if (this.context && this.context.contains && this.context.contains(e.target)) {
			return;
		}

		if (this.emojiSelector && this.emojiSelector.contains && this.emojiSelector.contains(e.target)) {
			return;
		}

		this.update({
			isContext: false,
			showEmojiSelector: false
		});
	};

	onContextClick = () => {
		let { isContext } = this.state;

		this.update({
			isContext: !isContext
		});
	};

	handleSpamClick = async () => {
		let { message } = this.state;
		let { id } = message;

		await MessagesService.markMessageAsSpam(id);

		toast.info("Messaged marked as spam.", {
			position: "bottom-center",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "mb-toast"
		});

		this.update({
			isSpam: true,
			isContext: false
		});
	};

	handleMarkReadClick = async () => {
		let { message } = this.state;

		let contactId = message.contact_id;

		let response = await MessagesService.markEntireConversationRead(contactId);

		if (!response) {
			ToastService.info(`Failed to mark messages as read.`);
			return;
		}

		ToastService.info(`All messages in the conversation have been marked as read.`);

		this.update({
			isContext: false
		});
	};

	onToggleHtml = async () => {
		let { showHtml } = this.state;

		this.update({
			showHtml: !showHtml,
			isContext: false
		});
	};

	onshowQuotedEmailToggle = async () => {
		let { showQuotedEmail } = this.state;

		this.update({
			showQuotedEmail: !showQuotedEmail,
			isContext: false
		});
	};

	handleMarkUnreadClick = async () => {
		let { message } = this.state;

		let contactId = message.contact_id;

		let unreadMessagesUpdated = await MessagesService.markMessagesAsUnread(message.id, contactId);

		if (!unreadMessagesUpdated) {
			ToastService.info(`Failed to mark messages as unread.`);
			return;
		}

		ToastService.info(`Messages have been marked as unread.`);

		this.update({
			isContext: false
		});

		// Update the message in the message list to be unread
		if (this.props.onMarkAsUnread) {
			this.props.onMarkAsUnread(message);
		}
	};

	handleCancelMessageClick = async () => {
		let { message } = this.state;
		let { id } = message;

		let response = await MessagesService.updateMessage({ messageId: id, status: "deleted" });

		if (!response) {
			ToastService.error("Failed to cancel message. Please try again.");
			return;
		}

		RealtimeService.sendMessageUpdated(id);

		ToastService.info("Message canceled.");

		await this.update({
			deleted: true,
			isContext: false
		});
	};

	handleDeleteMessageClick = async () => {
		let { message } = this.state;
		let { id } = message;

		let content = (
			<div className="mb-toast-undo">
				<div>Message deleted!</div>
				<div className="mb-toast-undo-button" onClick={() => (toastInstance = null)}>
					{" "}
					Undo{" "}
				</div>
			</div>
		);

		let toastInstance = toast.info(content, {
			position: "bottom-center",
			autoClose: 5000,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "mb-toast",
			onClose: async () => {
				if (toastInstance) {
					await MessagesService.deleteMessage(id);
					await RealtimeService.sendMessageUpdated(id);
				} else {
					this.update({
						deleted: false
					});
				}
			}
		});

		this.update({
			deleted: true,
			isContext: false
		});
	};

	handleForwardMessageClick = () => {
		let { message } = this.state;

		this.update({
			isContext: false
		});

		if (this.props.onForwardMessage) {
			this.props.onForwardMessage(message);
		}
	};

	renderMedia() {
		let { media } = this.state;
		let { onMediaClicked } = this.props;

		return (
			<div className="mb-message-customer__media">
				{media.map(m => {
					return <ThreadMedia key={m.id} media={m} onMediaClicked={onMediaClicked} />;
				})}
			</div>
		);
	}

	renderHTMLEmail() {
		let { message } = this.state;
		let { content_html } = message;

		let cleanHTML = DOMPurify.sanitize(content_html);

		return <Iframe style={EMAIL_IFRAME_STYLES}>{ReactHtmlParser(cleanHTML)}</Iframe>;
	}

	renderContent() {
		let { message, showHtml, showQuotedEmail } = this.state;
		let { id, content, direction, created_at, medium } = message;

		let contentStyle = {};

		if (showHtml) {
			return this.renderHTMLEmail();
		}

		let isTwoMinutesOld = moment(created_at).add(2, "minutes");
		let isMessageLessThanTwoMinsOld = moment().isAfter(isTwoMinutesOld);

		// check message created at, if its less than
		if (content === MEDIA_ICON.audio && direction === DIRECTION.in && LocationService.isVoicemailTranscriptionEnabled() && !isMessageLessThanTwoMinsOld) {
			return (
				<div class="mb-message__transcription-loader">
					Transcribing
					<span class="mb-message__transcription-loader__dot">.</span>
					<span class="mb-message__transcription-loader__dot">.</span>
					<span class="mb-message__transcription-loader__dot">.</span>
				</div>
			);
		} else if (content === MEDIA_ICON.audio && direction === DIRECTION.in) {
			contentStyle.fontSize = 48;
		}

		// If it's a normal message, we will split the contents
		let blurbableContent = content;

		// Check if we want to only show emails with their threads hidden
		let hasParsedLatestEmailMessage = medium === MEDIUM.email.key && typeof message.email_parsed_latest_message !== "undefined";
		if (hasParsedLatestEmailMessage && !showQuotedEmail) {
			blurbableContent = message.email_parsed_latest_message.trim();
		}

		return blurbableContent.split("\n").map((blurb, index) => {
			let key = parseInt(`${id}${index}`, 10);

			if (blurb.length === 0) {
				return <br key={key} />;
			}

			let languageDirection = UtilityService.detectLanguageDirection(blurb);

			return (
				<p className="mb-message-content-blurb" style={contentStyle} dir={languageDirection} key={key}>
					<Linkify classNames={direction === DIRECTION.out ? ["mb-message-content-link--out"] : []}>{blurb}</Linkify>
				</p>
			);
		});
	}

	renderReadReceiptTooltip(message, date) {
		if (date) {
			return `Read at: ${date}`;
		} else if (message.message_state === MESSAGE_STATES.sent && message.delivery_state === MESSAGE_DELIVERY_STATES.delivered) {
			return "Sent Successfully";
		} else if (message.message_state === MESSAGE_STATES.sent) {
			return "Sent";
		}
		return "";
	}

	renderReadReceipt() {
		let { message } = this.state;

		if (!message) {
			return null;
		}

		if (message.medium !== MEDIUM.email.key || message.direction === DIRECTION.in) {
			// Read receipts for email only right now
			return null;
		}

		let date = message.contact_read_at ? moment(message.contact_read_at).format("h:mm a YYYY-MM-DD") : null;

		return (
			<>
				<span
					className={`mb-message-customer-bottom__read-receipt ${
						date ? "mb-message-customer-bottom__read-receipt--read" : "mb-message-customer-bottom__read-receipt--sent"
					}`}
					data-tip
					data-for={`contact-receipt-tooltip-${message.id}`}
				>
					{message.message_state === MESSAGE_STATES.sent && <Icon.Check size="15" />}
					{message.delivery_state === MESSAGE_DELIVERY_STATES.delivered && (
						<Icon.Check size="15" className="mb-message-customer-bottom__read-receipt__sent-icon" />
					)}
				</span>
				<ReactTooltip id={`contact-receipt-tooltip-${message.id}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
					{this.renderReadReceiptTooltip(message, date)}
				</ReactTooltip>
			</>
		);
	}

	renderUnreadDivider() {
		let { message, previousMessage } = this.props;

		// Neither is the current and previous message exist
		let showUnread = false;
		if (!previousMessage && message) {
			showUnread = message.unread;
		} else if (previousMessage && message) {
			showUnread = !previousMessage.unread && message.unread;
		}

		return (
			showUnread && (
				<div key={`${message.id}-date`} className="mb-message-list-divider" ref={ref => (this.newLine = ref)}>
					<div className="mb-message-list-divider-line mb-message-list-divider-line--danger" />
					<div className="mb-message-list-divider-date mb-message-list-divider-date--danger">NEW</div>
					<div className="mb-message-list-divider-line mb-message-list-divider-line--danger" />
				</div>
			)
		);
	}

	renderCustomerMessage() {
		let { hideContextMenu } = this.props;
		let { message, isMedia, isSpam, deleted } = this.state;
		let { id, content, direction, created_at, sender_user_name, reactions, send_after, medium } = message;

		let { localTimeZone, localTimeZoneShortHand } = UtilityService.getTimeZoneHelpers();

		let sendAfter = moment(send_after);
		let now = moment();
		let showAsSendingLater = now < sendAfter;
		let isSendingLater = message.is_scheduled_message;

		let senderName = "Sent by " + sender_user_name.split(" ")[0] + " - ";
		let createdAt = moment(created_at).format("h:mm a");

		let rawDate = createdAt;
		if (isSendingLater) {
			createdAt = moment(created_at).format("ddd, MMM Do YYYY @ h:mm a");
			let sendAfterText = sendAfter.format("ddd, MMM Do YYYY @ h:mm a");
			rawDate = `Scheduled for ${sendAfterText}. Created on ${createdAt} (Timezone: ${localTimeZoneShortHand})`;
		}

		let date = direction === DIRECTION.in ? rawDate : senderName + rawDate;
		let isDeleted = deleted || message.status === "deleted";

		let boxStyle = {};
		let contentStyle = {};

		if (showAsSendingLater) {
			boxStyle = {
				opacity: 0.8
			};
		}

		// Highlight the unread messages for cs and supers visiting the other company
		let isSuperOrCsVisitingAnotherCompany = UserService.isSuperOrCsVisitingAnotherCompany();

		// If the content is simply one emoji, then increase the size of the emoji to font size 48
		if (content.length === 2 && UtilityService.isSingleEmoji(content) && content !== `${MEDIA_ICON.audio}`) {
			contentStyle = {
				fontSize: 48
			};
		}

		let isSecureChat = medium === MEDIUM.secure.key;
		let secureChatCss = "";
		if (isSecureChat) {
			if (direction === DIRECTION.out) {
				secureChatCss = "mb-message-customer-box--secure-out";
			} else {
				secureChatCss = "mb-message-customer-box--secure-in";
			}
		}

		return (
			<>
				{this.renderUnreadDivider()}
				<div
					className={`mb-message-customer 
				${direction === DIRECTION.out ? "mb-message-customer--out" : "mb-message-customer--in"}
				
				`}
				>
					<MessageError message={message} />
					{!isSpam && !isDeleted && (
						<div
							className={`mb-message-customer-box ${direction === DIRECTION.out ? "mb-message-customer-box--out" : ""} ${
								message.unread && isSuperOrCsVisitingAnotherCompany ? "mb-message-customer-box--unread" : ""
							} ${secureChatCss}`}
							style={boxStyle}
						>
							<div className="mb-message-customer-top">
								<div>
									{isMedia && this.renderMedia(message)}
									{content && (
										<div className="mb-message-customer-content" style={contentStyle}>
											{this.renderContent(content, direction, id, reactions)}
										</div>
									)}
								</div>
								{!hideContextMenu && (
									<div className="mb-message-customer-context" onClick={this.onContextClick}>
										<Icon.ChevronDown size="20" />
									</div>
								)}
							</div>
							<div className="mb-message-customer-bottom">
								<ReactTooltip id="timezone-info-messenger" type="info" className="mb-react-tooltip" effect="solid" place="right" arrowColor="#333">
									The time is in your local timezone <br /> {localTimeZone} ({localTimeZoneShortHand})
								</ReactTooltip>
								<span className="fnctst-sending-status" data-tip data-for="timezone-info-messenger">
									{date}
								</span>
								{this.renderReadReceipt()}
							</div>
						</div>
					)}
					{isDeleted && this.renderDeletedMessage()}
					{!hideContextMenu && this.renderContextMenu()}
				</div>
			</>
		);
	}

	renderContextMenu() {
		let user = UserService.get();
		let { isContext, message, showHtml, showQuotedEmail } = this.state;
		let { direction, send_after } = message;

		let sendAfter = moment(send_after);
		let now = moment();
		let isSendingLater = now < sendAfter;

		if (!isContext) {
			return null;
		}

		let modifier = direction === DIRECTION.out ? "mb-message-context-menu--out" : "mb-message-context-menu--in";

		// Add the option to mark as read for cs and supers visiting this other company
		let isSuperOrCsVisitingAnotherCompany = UserService.isSuperOrCsVisitingAnotherCompany();
		let allowMarkAsRead = isSuperOrCsVisitingAnotherCompany && message.unread;
		let allowedToForward = TeamChatService.isForwardMessageEligible();

		let hasParsedLatestEmailMessage = typeof message.email_parsed_latest_message !== "undefined";

		return (
			<div ref={ref => (this.context = ref)} className={`mb-message-context-menu ${modifier}`}>
				<>
					{/*{mode === MODE.customer && [
						<div className="mb-message-context-menu-item" onClick={this.handleSpamClick}>
							Mark as Spam
						</div>
					]}*/}
					<div className="mb-message-context-menu-item" id="mb-message-context-menu-mark-unread" onClick={this.handleMarkUnreadClick}>
						Mark Message As Unread
					</div>
					{allowMarkAsRead && (
						<div className="mb-message-context-menu-item" id="mb-message-context-menu-mark-read" onClick={this.handleMarkReadClick}>
							Mark All Messages As Read
						</div>
					)}
					{direction === DIRECTION.in && allowedToForward && (
						<div className="mb-message-context-menu-item" id="mb-message-context-menu-forward" onClick={this.handleForwardMessageClick}>
							Share With Team
						</div>
					)}
					{isSendingLater && user.GroupPermission.update_customer_messages && (
						<div className="mb-message-context-menu-item" id="mb-message-context-menu-cancel" onClick={this.handleCancelMessageClick}>
							Cancel Message
						</div>
					)}
					{user.GroupPermission.delete_customer_messages && (
						<div className="mb-message-context-menu-item" id="mb-message-context-menu-delete" onClick={this.handleDeleteMessageClick}>
							Delete Message
						</div>
					)}
					{message.medium === MEDIUM.email.key && (
						<div className="mb-message-context-menu-item" id="mb-message-context-menu-html" onClick={this.onToggleHtml}>
							{showHtml ? "Hide HTML Email" : "Show HTML Email"}
						</div>
					)}
					{message.medium === MEDIUM.email.key && hasParsedLatestEmailMessage && (
						<div className="mb-message-context-menu-item" id="mb-message-context-menu-quoted" onClick={this.onshowQuotedEmailToggle}>
							{showQuotedEmail ? "Hide Quoted Email" : "Show Quoted Email"}
						</div>
					)}
				</>
			</div>
		);
	}

	renderDeletedMessage() {
		let { message } = this.state;
		let { direction } = message;

		return (
			<div className={`mb-message-box ${direction === DIRECTION.out ? "mb-message-box--out" : ""} mb-message-box--removed`}>This message has been deleted.</div>
		);
	}

	render() {
		let { message } = this.state;

		let isEvent = message.event_type !== "message";

		if (isEvent) {
			return <MessageEvent message={message} />;
		}

		return this.renderCustomerMessage();
	}
}

export default Message;
