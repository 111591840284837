import React, { Component } from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import TagService from "../../services/TagService";
import UserService from "../../services/UserService";

import Modal from "../../components/common/DHModal";

import { TAG_TYPES } from "../../constants/Tags";

class UpdateTagsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: this.props.show ? this.props.show : false,
			name: this.props.data ? this.props.data.name : "",
			originalName: this.props.data ? this.props.data.name : "",
			tagExists: false,
			showTagExistsText: false,
			loading: false
		};

		this.debouncedCheck = _.debounce(
			async () => {
				await this.checkIfExists();
			},
			1000,
			{
				leading: true,
				trailing: true
			}
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.show !== this.props.show) {
			this.setState({ show: this.props.show });
		}
		if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
			this.setState({ name: this.props.data ? this.props.data.name : "", originalName: this.props.data ? this.props.data.name : "" });
		}
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	isFormValid = () => {
		return !(this.state.name === "" || this.state.tagExists);
	};

	handleOnSubmit = () => {
		if (!this.isFormValid()) {
			return;
		}

		if (this.props.onSubmit) {
			const { name } = this.state;
			const { data, createMode } = this.props;
			this.props.onSubmit(createMode, createMode ? 0 : data.id, name);
			this.setState({ name: "" });
		}
	};

	handleNameChange = async event => {
		try {
			await this.update({ name: event.target.value, tagExists: true, loading: true });
			this.debouncedCheck();
		} catch (error) {
			console.log(error);
		}
	};

	checkIfExists = async () => {
		await this.update({ loading: false });

		let { name } = this.state;
		name = name.toLowerCase();
		let locationId = UserService.getActiveLocation().id;
		let exists = false;

		let { type } = this.props;

		// Only check if the tag exists if there is anything in the name field
		if (name && name.trim() !== "") {
			exists = await TagService.tagExists(locationId, name, type || TAG_TYPES.tag);
			if (!exists) {
				exists = false;
			}
		}

		await this.update({ tagExists: exists, showTagExistsText: exists ? true : false });
	};

	onHide = () => {
		this.setState({ name: "", tagExists: false });

		if (this.props.onHide) {
			this.props.onHide();
		}
	};

	renderTitle = () => {
		const { createMode, type, t } = this.props;

		let tagType = type || TAG_TYPES.tag;

		let title = "";

		if (createMode) {
			if (tagType === TAG_TYPES.tag) {
				title = t("Create a tag");
			} else {
				title = t("Create a group");
			}
		} else {
			if (tagType === TAG_TYPES.tag) {
				title = t("Update a tag");
			} else {
				title = t("Update a group");
			}
		}

		return title;
	};

	render() {
		let { data, createMode, type, t } = this.props;
		const { name, show, loading, tagExists, showTagExistsText, originalName } = this.state;

		let tagType = type || TAG_TYPES.tag;

		return (
			<Modal show={show} onHide={() => this.onHide()} title={this.renderTitle()}>
				{(data || createMode) && (
					<div className="modal__flex-container">
						<input
							name="name"
							id={tagType === TAG_TYPES.tag ? "tag-name" : "group-name"}
							type="text"
							onChange={this.handleNameChange}
							value={name}
							className="Common__input"
							autoComplete="off"
							placeholder={tagType === TAG_TYPES.tag ? t("Tag Name") : t("Group Name")}
						/>

						<p className="text-danger" style={{ paddingLeft: 5, paddingTop: 3 }}>
							{originalName !== name && tagExists && showTagExistsText ? (
								<span>{tagType === TAG_TYPES.tag ? t("Tag with this name already exists") : t("Group with this name already exists")} </span>
							) : (
								<span>&nbsp;</span>
							)}
						</p>

						<div className="modal__actions">
							<div
								id={createMode ? "create" : "update"}
								className={`mb-button ${this.isFormValid() ? "" : "mb-button--disabled"}`}
								onClick={e => this.handleOnSubmit(e)}
							>
								{loading ? t("Loading...") : t("Save")}
							</div>
						</div>
					</div>
				)}
			</Modal>
		);
	}
}
export default withTranslation(null, { withRef: true })(UpdateTagsModal);
