import React, { PureComponent } from "react";
import * as Icon from "react-feather";
import { PlayCircle, PauseCircle } from "lucide-react";
import "whatwg-fetch";
import WaveSurfer from "wavesurfer.js";

import { DOWNLOAD_STATUS, DOWNLOAD_TEXT, MEDIA_TYPES } from "../../../../constants/Messenger";
import { VIDEO_TYPES } from "../../../../constants/Media";

import UtilityService from "../../../../services/UtilityService";
import ToastService from "../../../../services/ToastService";

import Spinners from "../../../../components/common/Spinners";

import "./media.css";

class Media extends PureComponent {
	constructor(props) {
		super(props);

		let { media, maxHeightOrWidth } = props;
		let { width, height } = this.calculateImageDimensions(media.width, media.height, maxHeightOrWidth);

		this.state = {
			fileName: media.file_name,
			type: media.type,
			downloadUrl: UtilityService.appendQueryAuthToken(media.download_url),
			extension: media.extension,
			width,
			height,

			loading: true,
			downloadStatus: DOWNLOAD_STATUS.idle,

			playing: false
		};

		this.waveform = null;
	}

	componentDidMount() {
		this.onSetupMedia();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onSetupMedia() {
		let { media } = this.props;

		if (media && media.type === MEDIA_TYPES.audio) {
			this.setupAudioMedia();
		}
	}

	setupAudioMedia() {
		let { media, idPrefix, soundWaveHeight } = this.props;
		let { downloadUrl } = this.state;

		let containerId = typeof idPrefix === "undefined" ? "" : idPrefix;

		this.waveform = WaveSurfer.create({
			barWidth: 3,
			barRadius: 3,
			barGap: 2,
			barMinHeight: 1,
			cursorWidth: 1,
			container: `#waveform-${containerId}-${media ? media.id : null}`,
			backend: "WebAudio",
			height: soundWaveHeight || 90,
			barHeight: 5,
			progressColor: "#60A9FF",
			responsive: true,
			waveColor: "#C4C4C4",
			cursorColor: "transparent"
		});

		this.waveform.on("ready", () => {
			this.update({ loading: false });
		});
		this.waveform.on("finish", () => {
			this.waveform.stop();
			this.update({ loading: false, playing: false });
		});
		this.waveform.load(downloadUrl);
	}

	renderVideoMedia() {
		let { extension, downloadUrl } = this.state;

		// Won't play mov but it will if it's mp4
		if (extension === VIDEO_TYPES.mov) {
			extension = VIDEO_TYPES.mp4;
		}

		return (
			<video className="mb-media__video" controls>
				<source src={downloadUrl} type={extension ? `video/${extension}` : ""} />
			</video>
		);
	}

	calculateImageDimensions = (width, height, maxHeightOrWidth = 400) => {
		// Quick and dirty way to maintain aspect ratios for images with a specified max width or height depending on which one is bigger
		let w = 0;
		let h = 0;

		// Determine the correct aspect ratio based on landscape or portrait
		let aR = Math.min(width, height) / Math.max(width, height);

		// Set the width and height based on the calculated aspect ratio
		if (width > height) {
			w = Math.min(maxHeightOrWidth, width);
			h = w * aR;
		} else {
			h = Math.min(maxHeightOrWidth, height);
			w = h * aR;
		}

		return {
			width: w,
			height: h
		};
	};

	renderImageMedia() {
		let { width, height, downloadUrl } = this.state;
		let { onMediaClicked } = this.props;

		return (
			<img
				alt=""
				width={width}
				height={height}
				className="mb-media__image"
				src={downloadUrl}
				onClick={onMediaClicked ? () => onMediaClicked(downloadUrl) : () => {}}
				onLoad={() => this.update({ loading: false })}
			/>
		);
	}

	startDownload = async () => {
		let { fileName, downloadUrl, downloadStatus } = this.state;

		if (downloadStatus === DOWNLOAD_STATUS.downloading) {
			return;
		}

		await this.update({
			downloadStatus: DOWNLOAD_STATUS.downloading
		});

		// Sometimes men wear strechy pants... it's for fun.
		await UtilityService.timeout(2000);

		try {
			let response = await fetch(downloadUrl, {
				method: "GET"
			});

			let blob = await response.blob();

			// Tis a ghetto little thing
			let url = window.URL.createObjectURL(blob);
			let a = document.createElement("a");
			a.href = url;
			a.target = "_blank";
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			a.remove();

			await this.update({
				downloadStatus: DOWNLOAD_STATUS.complete
			});

			ToastService.info("Download Complete!");
		} catch (error) {
			console.log(error);

			await this.update({
				downloadStatus: DOWNLOAD_STATUS.failed
			});

			ToastService.error("Download Failed.");
		}
	};

	renderFileMedia() {
		let { downloadStatus, extension } = this.state;
		let { readOnly } = this.props;

		if (readOnly) {
			return (
				<div className="mb-media__file">
					<div className="mb-media__file__file_extension">{extension}</div>
				</div>
			);
		}

		return (
			<div className="mb-media__file" onClick={() => this.startDownload()}>
				<Icon.Download size={25} />
				<div className="mb-media__file__file_extension">{extension}</div>
				<div className="mb-media__file__title">{DOWNLOAD_TEXT[downloadStatus]}</div>
			</div>
		);
	}

	onPlayPauseAudio() {
		try {
			this.update({ playing: !this.state.playing });
			if (this.waveform) {
				this.waveform.playPause();
			}
		} catch (error) {
			console.log(`audio error`, error);
		}
	}

	renderAudioMedia() {
		let { playing, loading, downloadStatus, extension } = this.state;
		let { media, idPrefix, readOnly, soundWaveHeight } = this.props;

		let containerId = typeof idPrefix === "undefined" ? "" : idPrefix;
		let downloading = downloadStatus === DOWNLOAD_STATUS.downloading;
		let hideAudioWave = loading || downloading || readOnly;

		let styles = {};

		if (soundWaveHeight) {
			styles = {
				height: soundWaveHeight,
				minHeight: soundWaveHeight
			};
		}

		return (
			<div className="mb-media__audio" style={styles}>
				{!readOnly && this.renderAudioContent(downloading)}
				{readOnly && (
					<div className="mb-media__file" onClick={this.startDownload}>
						<Icon.Volume2 size={25} />
						<div className="mb-media__file__file_extension">{extension}</div>
					</div>
				)}

				<div
					id={`waveform-${containerId}-${media ? media.id : null}`}
					style={{ height: `${soundWaveHeight || 90}px` }}
					className={`mb-media__audio__wave ${hideAudioWave ? "mb-media__audio__wave--hide" : ""}`}
				/>
			</div>
		);
	}

	renderAudioContent(downloading) {
		let { playing, loading } = this.state;
		let { playButtonSize } = this.props;

		return (
			<>
				{!downloading && playing && (
					<div className="mb-media__audio__button">
						<PauseCircle color="#60A9FF" width={playButtonSize || 42} height={playButtonSize || 42} onClick={() => this.onPlayPauseAudio()} />
					</div>
				)}
				{!downloading && !playing && (
					<div className="mb-media__audio__button">
						<PlayCircle color="#60A9FF" width={playButtonSize || 42} height={playButtonSize || 42} onClick={() => this.onPlayPauseAudio()} />
					</div>
				)}
				{downloading && (
					<div className="mb-media__audio__download">
						<div className="mb-media__audio__download__text">{DOWNLOAD_TEXT.downloading}</div> <Spinners loading={true} type="circle" size="2px" />
					</div>
				)}
				{loading && (
					<div className="mb-media__audio__spinner">
						<Spinners loading={true} type="circle" size="2px" />
					</div>
				)}
			</>
		);
	}

	render() {
		let { type } = this.state;

		return (
			<React.Fragment>
				{type === MEDIA_TYPES.image && this.renderImageMedia()}
				{type === MEDIA_TYPES.audio && this.renderAudioMedia()}
				{type === MEDIA_TYPES.video && this.renderVideoMedia()}
				{type === MEDIA_TYPES.file && this.renderFileMedia()}
			</React.Fragment>
		);
	}
}

export default Media;
