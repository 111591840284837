export const CONFIRM_BLOCK_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: false,
		width: 1
	},
	created: {
		id: "created",
		value: "Created",
		sortable: false,
		width: 1
	},
	status: {
		id: "status",
		value: "Status",
		sortable: false,
		width: 1
	}
};
