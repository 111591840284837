import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { motion } from "framer-motion";

import { IFRAME_STYLES } from "../../../constants/WidgetConstants";

import withLocation from "../../../components/common/WithLocation";

import WidgetConfigService from "../../../services/WidgetConfigService";
import NotificationService from "../../../services/NotificationService";

import "react-toastify/dist/ReactToastify.css";
import "../../../styles/css/scenes/web-chat-config.css";

class WebchatPreviewTooltip extends Component {
	constructor(props) {
		super(props);

		let { showTooltip } = this.props;

		this.state = {
			showTooltip,
			showClose: false,
			bubbleWidth: 0
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		this.updateDimensions();

		NotificationService.subscribeOnce("bubbleIframeStyleUpdate", "_tooltipComponent", width => {
			this.update({ bubbleWidth: width });
			this.updateDimensions();
		});
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
			this.updateDimensions();
		}
		if (this.props.showTooltip !== prevProps.showTooltip) {
			this.update({ showTooltip: this.props.showTooltip });
		}
	}

	updateDimensions = () => {
		let { position } = this.props;
		let { bubbleWidth } = this.state;
		let iframeStyles = { ...IFRAME_STYLES.tooltip };

		iframeStyles = WidgetConfigService.extendStylesForPositioning({
			position: position.value,
			styles: iframeStyles,
			offset: {
				bottom: 75,
				top: 75,
				left: position.value === "left" ? bubbleWidth : 0,
				right: position.value === "right" ? bubbleWidth : 0
			}
		});

		this.update({ iframeStyles });
	};

	onTooltipClose = async () => {
		await this.update({ showTooltip: false });
	};

	render() {
		let { chatHead, tooltipGreeting, tooltipImage, tooltipBackground, tooltipForeground, boldTooltip, language, position } = this.props;
		let { showClose, showTooltip, iframeStyles } = this.state;

		if (!showTooltip) {
			return null;
		}
		position = position.value || IFRAME_STYLES.positions.bottomRight;

		let tooltipContainerClasses = ["tooltip__container", `tooltip__container--${position}`];
		let tooltipArrowClasses = ["tooltip__arrow", `tooltip__arrow--${position}`];
		let tooltipLayerClasses = ["tooltip__layer", `tooltip__layer--${position}`];
		let tooltipCloseClasses = ["tooltip__close", `tooltip__close--${position}`];

		let tooltipContainerStyles = {};
		let tooltipTextStyles = {};
		let tooltipArrowStyles = {};

		if (tooltipBackground) {
			tooltipContainerStyles.backgroundColor = tooltipBackground;
			tooltipArrowStyles.backgroundColor = tooltipBackground;
		}

		if (boldTooltip) {
			tooltipTextStyles.weight = "800";
		}

		if (tooltipForeground) {
			tooltipTextStyles.color = tooltipForeground;
		}

		return (
			<div id="dh-webchat-tooltip-iframe" style={iframeStyles}>
				<motion.div
					onMouseEnter={() => this.update({ showClose: true })}
					onMouseLeave={() => this.update({ showClose: false })}
					className="tooltip"
					animate={{ opacity: 1, y: 0 }}
					initial={{ opacity: 0, y: 20 }}
					transition={{ duration: 0.3, ease: "easeOut" }}
				>
					<div className={tooltipContainerClasses.join(" ")} style={tooltipContainerStyles}>
						<div className="tooltip__container__content">
							{chatHead && (
								<div className="tooltip__container__content__profile">
									<img src={tooltipImage ? tooltipImage : WidgetConfigService.getDefaultTooltipUrl()} alt="dh-webchat-tooltip" />
								</div>
							)}

							<div className={`tooltip__container__content__message`} style={tooltipTextStyles}>
								{tooltipGreeting && tooltipGreeting[language] ? tooltipGreeting[language] : WidgetConfigService.getDefaultGreeting()}
							</div>
						</div>
					</div>

					<div className={tooltipArrowClasses.join(" ")} style={tooltipArrowStyles}></div>
					<div className={tooltipLayerClasses.join(" ")}></div>

					{showClose && (
						<div onClick={this.onTooltipClose} className={tooltipCloseClasses.join(" ")}>
							<span>{"Close"}</span>
						</div>
					)}
				</motion.div>
			</div>
		);
	}
}

export default withRouter(withLocation(WebchatPreviewTooltip));
