import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";

import UserService from "../../services/UserService";
import GAService from "../../services/GAService";

import ContactSelector from "../../scenes/ContactSelector/ContactSelector";
import Tags from "../../scenes/Tags/Tags";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Tabs from "../../components/common/Tabs";

import Groups from "./Groups";
import CustomFields from "./CustomFields";

import { GA_CATEGORIES, GA_ACTIONS } from "../../constants/GAConstants";
import { CONTACT_TABS } from "../../constants/Contacts";

import "../../styles/css/scenes/contacts.css";

class Contacts extends Component {
	constructor(props) {
		super(props);

		let tabs = Object.values(CONTACT_TABS);
		let routes = tabs.map(tab => tab.route);
		let routeIndex = routes.indexOf(this.props.location.pathname);

		if (routeIndex < 0) {
			routeIndex = 0;
		}

		let selectedTab = tabs[routeIndex].id;

		this.state = {
			selectedTab
		};
		this.contactSelector = null;
	}

	componentDidMount = async () => {
		GAService.GAPageView({ page: this.props.location.pathname });
	};

	onTabSelect = tab => {
		GAService.GAEvent({
			category: GA_CATEGORIES.contacts.name,
			action: GA_ACTIONS.generic.tabSelect,
			label: `Selected tab: ${tab.value}`
		});
		this.setState({ selectedTab: tab.id });
		this.props.history.push(CONTACT_TABS[tab.id].route);
	};

	render() {
		let { selectedTab } = this.state;
		let { t } = this.props;
		const user = UserService.get();

		if (!this.props.match.params.tab) {
			return <Redirect to={CONTACT_TABS.contacts.route} />;
		}

		return (
			<Page>
				<Header title={t("Contacts")} />
				<Tabs onSelect={this.onTabSelect} selected={selectedTab} routing={true} tabs={Object.values(CONTACT_TABS)}>
					<Switch>
						<Route key="contact-selector" path={CONTACT_TABS.contacts.route}>
							<ContactSelector
								ref={selector => {
									if (selector) {
										this.contactSelector = selector.wrappedComponent;
									}
								}}
								showActions={true}
							/>
						</Route>
						<Route key="contact-groups" path={CONTACT_TABS.groups.route}>
							<Groups />
						</Route>
						<Route key="contact-tags" path={CONTACT_TABS.tags.route}>
							<Tags />
						</Route>
						<Route key="contact-custom-fields" path={CONTACT_TABS.customFields.route}>
							<CustomFields />
						</Route>
					</Switch>
				</Tabs>
			</Page>
		);
	}
}

export default withTranslation(null, { withRef: true })(Contacts);
