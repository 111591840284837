import React, { Component } from "react";
import { SketchPicker } from "react-color";
import PropTypes from "prop-types";

class Sketch extends Component {
	state = {
		displayColorPicker: false
	};

	handleClick = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker });
	};

	handleClose = () => {
		this.setState({ displayColorPicker: false });
	};

	handleChange = color => {
		this.props.handleChange(color.hex);
	};

	render() {
		const styles = {
			pickerContainer: {
				position: "relative"
			},
			color: {
				width: "36px",
				height: "14px",
				borderRadius: "2px",
				background: this.props.value
			},
			swatch: {
				padding: "5px",
				background: "#fff",
				borderRadius: "1px",
				boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
				display: "inline-block",
				cursor: "pointer"
			},
			popover: {
				position: "absolute",
				zIndex: "2"
			},
			cover: {
				position: "fixed",
				top: "0px",
				right: "0px",
				bottom: "0px",
				left: "0px"
			}
		};

		return (
			<div style={styles.pickerContainer}>
				<div style={styles.swatch} onClick={this.handleClick} className="fnctst-colour-sketch-picker">
					<div style={styles.color} />
				</div>
				{this.state.displayColorPicker ? (
					<div style={styles.popover}>
						<div style={styles.cover} onClick={this.handleClose} />
						<SketchPicker color={this.props.value} onChange={this.handleChange} disableAlpha={this.props.disableAlpha} />
					</div>
				) : null}
			</div>
		);
	}
}

Sketch.propTypes = {
	disableAlpha: PropTypes.bool,
	handleChange: PropTypes.func.isRequired,
	value: function(props, propName, componentName) {
		if (!props[propName]) {
			return new Error("The prop `" + propName + "` is marked as required in `" + componentName + "`, but its value is `" + props[propName] + "`.");
		} else if (!/^#[0-9a-f]{6}/i.test(props[propName])) {
			// eslint-disable-next-line
			return new Error("Invalid prop `" + propName + "` with value `" + props[propName] + "` supplied to" + " `" + componentName + "`. Validation failed.");
		}
	}
};
export default Sketch;
