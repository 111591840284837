import React, { Component } from "react";
import * as Icon from "react-feather";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import ReactTooltip from "react-tooltip";
import UAParser from "ua-parser-js";
import queryString from "query-string";

import LocationService from "../../services/LocationService";
import UserService from "../../services/UserService";
import InviteService from "../../services/InviteService";
import ToastService from "../../services/ToastService";
import ReviewService from "../../services/ReviewService";
import UtilityService from "../../services/UtilityService";

import withLocation from "../../components/common/WithLocation";
import Page from "../../components/common/Page";
import List from "../../components/common/List";
import Action from "../../components/common/Action";
import { PAGE_SIZE_OPTIONS, SORT_ORDER } from "../../constants/CommonConstants";
import SearchInput from "../../components/common/SearchInput";
import Select from "../../components/common/DHSelect";
import ScheduledMessageModal from "../ScheduledMessages/ScheduledMessageModal";
import EditContactModal from "../../components/common/EditContactModal";
import SendReviewInviteModal from "../../components/common/SendReviewInviteModal";
import Alert from "../../components/common/Alert";

import {
	INVITES_COLUMNS,
	INVITES_STATUS_OPTIONS,
	INVITES_REMINDABLE_OPTIONS,
	UNKNOWN_NAMES_OPTIONS,
	SEND_REVIEW_INVITES_MODAL,
	INVITES_DEADLINE_ACTION,
	INVITE_STATUS,
	INVITE_STATUS_CLASS
} from "../../constants/Invites";
import { SM_TYPES } from "../../constants/ScheduledMessages";

import "../../styles/css/scenes/invites.css";

class Invites extends Component {
	constructor(props) {
		super(props);
		let { searchTerm, sortField, sortOrder, statusFilter, remindableFilter, unknownNamesFilter, dateRangeStart, dateRangeEnd } = queryString.parse(
			this.props.location.search
		);

		let reviewsInviteQueueEnabled = LocationService.isInviteQueuePermissible();

		if (statusFilter) {
			statusFilter = INVITES_STATUS_OPTIONS[statusFilter];
		}
		// If invites queued is on, the queued status filter should be the default
		if (!statusFilter && reviewsInviteQueueEnabled) {
			statusFilter = INVITES_STATUS_OPTIONS.pending;
		} else if (!statusFilter) {
			statusFilter = INVITES_STATUS_OPTIONS.all;
		}

		if (remindableFilter) {
			remindableFilter = INVITES_REMINDABLE_OPTIONS[remindableFilter];
		}

		if (unknownNamesFilter) {
			unknownNamesFilter = UNKNOWN_NAMES_OPTIONS[unknownNamesFilter];
		}

		if (dateRangeStart) {
			dateRangeStart = moment(dateRangeStart).toDate();
		}

		if (dateRangeEnd) {
			dateRangeEnd = moment(dateRangeEnd).toDate();
		}

		if (!dateRangeStart) {
			dateRangeStart = moment()
				.subtract(90, "days")
				.toDate();
		}

		if (!dateRangeEnd) {
			dateRangeEnd = moment()
				.add(45, "days")
				.toDate();
		}

		this.state = {
			invites: [],
			loading: false,

			// Filtering
			searchTerm: searchTerm || "",
			dateRangeStart,
			dateRangeEnd,
			statusFilter: statusFilter,
			remindableFilter: remindableFilter || INVITES_REMINDABLE_OPTIONS.all,
			unknownNamesFilter: unknownNamesFilter || UNKNOWN_NAMES_OPTIONS.all,
			sortOrder: sortOrder || SORT_ORDER.desc,
			sortField: sortField || INVITES_COLUMNS.sendAfter.sortField,

			limit: PAGE_SIZE_OPTIONS[10], // The amount of invites per page
			offset: 0,
			invitesPage: 0, // The current page we are on
			inviteCount: 0, // Total amount of review invites

			// Invite Queue Details
			inviteQueueDetails: null,

			// Modals and Alerts
			showScheduledMessageModal: false,
			showEditContactModal: false,
			showContactDetailsContactId: null,

			showReviewReminderFailMessage: false,
			reminderFailedMessage: "The review reminder could not be sent. Please check the number/email and try again.",
			showSendInviteErrorModal: false,

			// Review reminder modal feilds
			showSendReviewReminderModal: false,
			showReviewReminderSending: false,
			templateId: 0,
			name: "",
			phoneOrEmail: "",
			parent: 0,
			parentTemplate: null,
			duplicateDate: "",
			duplicateNumber: false
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = () => {
		this.resetComponent();
	};

	resetComponent = () => {
		this.fetchData();
		this.fetchInviteQueueDetails();
	};

	fetchInviteQueueDetails = async () => {
		let inviteQueueDetails = await LocationService.fetchInviteQueueDeadline();
		this.update({ inviteQueueDetails });
	};

	refreshRecentInvitesCount = async ({ searchTerm, status, remindersOnly, remindableOnly, unknownNames, sendAfterStartDate, sendAfterEndDate, version }) => {
		let inviteCount = await InviteService.countReviewRequests({
			searchTerm,
			status,
			remindersOnly,
			remindableOnly,
			unknownNames,
			sendAfterStartDate,
			sendAfterEndDate,
			version
		});

		if (!inviteCount && inviteCount !== 0) {
			return;
		}

		this.update({ inviteCount: inviteCount });
	};

	fetchData = async () => {
		let { searchTerm, statusFilter, remindableFilter, unknownNamesFilter, dateRangeStart, dateRangeEnd, limit, offset, sortOrder, sortField } = this.state;

		await this.update({ loading: true });

		let remindersOnly = this.props.reminders;
		let remindableOnly = false;
		let unknownNames = false;

		let status = [];

		if (statusFilter && statusFilter.value !== "all") {
			status = [statusFilter.value];
		}

		if (remindableFilter && remindableFilter.value !== "all") {
			remindableOnly = true;
		}

		if (unknownNamesFilter && unknownNamesFilter.value !== "all") {
			unknownNames = true;
		}

		let fetchParams = {
			searchTerm,
			status,
			remindersOnly,
			remindableOnly,
			unknownNames,
			sendAfterStartDate: dateRangeStart,
			sendAfterEndDate: dateRangeEnd,
			sortOrder,
			sortField,
			limit: limit.value,
			offset,
			version: 2
		};

		this.refreshRecentInvitesCount(fetchParams);

		let invites = await InviteService.fetch(fetchParams);

		if (invites) {
			invites.forEach(invite => {
				invite.notes = invite.notes !== null ? JSON.parse(invite.notes) : [];
				invite.notes = invite.notes.sort((n1, n2) => n1.created_at - n2.created_at);
			});
		}

		await this.update({ loading: false, invites: invites });
	};

	setUrlParams() {
		if (!this.props.history) {
		}

		let { statusFilter, remindableFilter, unknownNamesFilter, dateRangeStart, dateRangeEnd, searchTerm, sortField, sortOrder } = this.state;

		let params = {};

		if (statusFilter && statusFilter.value) {
			params.statusFilter = statusFilter.value;
		}

		if (remindableFilter && remindableFilter.value) {
			params.remindableFilter = remindableFilter.value;
		}

		if (unknownNamesFilter && unknownNamesFilter.value) {
			params.unknownNamesFilter = unknownNamesFilter.value;
		}

		if (searchTerm) {
			params.searchTerm = searchTerm;
		}

		if (sortField) {
			params.sortField = sortField;
		}

		if (sortOrder) {
			params.sortOrder = sortOrder;
		}

		if (dateRangeStart) {
			params.dateRangeStart = moment(dateRangeStart).format("YYYY-MM-DD");
		}

		if (dateRangeEnd) {
			params.dateRangeEnd = moment(dateRangeEnd).format("YYYY-MM-DD");
		}

		params = new URLSearchParams(params);

		this.props.history.replace({
			pathname: this.props.location.pathname,
			search: params.toString()
		});
	}

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		this.setUrlParams();
		this.fetchData();
	};

	getHeaders = () => {
		let columns = INVITES_COLUMNS;

		let { localTimeZone, localTimeZoneShortHand } = UtilityService.getTimeZoneHelpers();

		columns.sendAfter.tooltip = `The time is in your local timezone ${localTimeZone} (${localTimeZoneShortHand})`;

		let headers = {
			items: columns,
			sortBy: this.sortBy
		};

		return headers;
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ sortField, sortOrder });
		this.setUrlParams();
		await this.fetchData();
	};

	isRemindableFilterSelected = item => {
		return item === this.state.remindableFilter;
	};

	onFilterSelected = async item => {
		await this.update({ statusFilter: item.id });
		this.setUrlParams();
		await this.fetchData();
	};

	isFilterSelected = item => {
		return item === this.state.statusFilter;
	};

	onStatusFilterChange = async statusFilter => {
		await this.update({ statusFilter, remindableFilter: INVITES_REMINDABLE_OPTIONS.all });
		this.setUrlParams();
		this.fetchData();
	};

	onRemindableFilterChange = async remindableFilter => {
		await this.update({
			remindableFilter,
			statusFilter: INVITES_STATUS_OPTIONS.sent,
			dateRangeStart: moment()
				.subtract(5, "days")
				.toDate(),
			dateRangeEnd: moment().toDate()
		});
		this.setUrlParams();
		this.fetchData();
	};

	unknownNameFilterChange = async unknownNamesFilter => {
		await this.update({ unknownNamesFilter });
		this.setUrlParams();
		this.fetchData();
	};

	handleFromChange = async dateRangeStart => {
		let { dateRangeEnd } = this.state;

		if (moment(dateRangeEnd).diff(moment(dateRangeStart), "days") >= 365) {
			dateRangeEnd = moment(dateRangeStart)
				.add(365, "days")
				.toDate();
		}

		await this.update({ dateRangeStart, dateRangeEnd });
		this.setUrlParams();
		await this.fetchData();
	};

	handleToChange = async dateRangeEnd => {
		let { dateRangeStart } = this.state;

		if (moment(dateRangeEnd).diff(moment(dateRangeStart), "days") >= 365) {
			dateRangeStart = moment(dateRangeEnd)
				.subtract(365, "days")
				.toDate();
		}

		await this.update({ dateRangeStart, dateRangeEnd });
		this.setUrlParams();
		await this.fetchData();
	};

	onRefreshInvites = async () => {
		let offset = this.state.invitesPage * this.state.limit;
		await this.update({ offset: offset });
		this.fetchData();
	};

	onPaginateChange = async newPage => {
		let offset = newPage * this.state.limit.value;
		await this.update({ invitesPage: newPage, offset });
		this.fetchData();
	};

	onLimitChange = async limitOption => {
		await this.update({ limit: limitOption, invitesPage: 0, offset: 0 });
		this.fetchData();
	};

	showScheduledMessageModal = () => {
		this.update({
			showScheduledMessageModal: true
		});
	};

	closeScheduledMessageModal = () => {
		this.update({
			showScheduledMessageModal: false
		});
	};

	showContactDetailsModal = async contactId => {
		await this.update({
			showEditContactModal: true,
			showContactDetailsContactId: contactId
		});
	};

	onContactDetailsClosed = async contact => {
		await this.update({
			showEditContactModal: false
		});
	};

	onContactDetailsSaved = async contact => {
		await this.update({
			showEditContactModal: false
		});
		this.fetchData();
	};

	/**
	 *
	 * Action buttons
	 *
	 */

	handleReviewUncancelButton = async reviewRequest => {
		let { id, name, notes } = reviewRequest;
		let reviewRequestId = id;

		let success = await InviteService.uncancelReviewRequest({ reviewRequestId });

		if (!success) {
			ToastService.error("Review invite could not be re-queued. Please try again");
			this.fetchData();
			return;
		}

		let firstName = UserService.get() ? UserService.get().first_name : "Me";
		let lastName = UserService.get() ? UserService.get().last_name : "";

		await this.checkAndAddNoteToRequest(reviewRequestId, notes, `${firstName} ${lastName} reactivated this review invite.`, firstName, lastName);

		ToastService.info("Review request to " + name + " has been queued!", "mb-toast mb-toast--small");

		this.fetchData();
	};

	checkAndAddNoteToRequest = async (reviewRequestId, notes, comment, firstName, lastName) => {
		try {
			let user = UserService.get();
			if (!notes) {
				notes = [];
			}
			notes.push({
				comment: comment,
				created_at: moment(),
				first_name: firstName,
				last_name: lastName,
				user_id: user.id
			});

			await ReviewService.updateReviewRequestNotes({
				reviewRequestId: reviewRequestId,
				notes: notes
			});
		} catch (error) {
			throw error;
		}
	};

	handleReviewCancelButton = async reviewRequest => {
		let { id, name, notes } = reviewRequest;
		let reviewRequestId = id;

		let success = await InviteService.cancelReviewRequest({ reviewRequestId });

		if (!success) {
			ToastService.error("Failed to cancel review invite. Please try again.");
			this.fetchData();
			return;
		}

		if (notes) {
			let firstName = UserService.get() ? UserService.get().first_name : "Me";
			let lastName = UserService.get() ? UserService.get().last_name : "";
			await this.checkAndAddNoteToRequest(reviewRequestId, notes, `${firstName} ${lastName} cancelled this review invite.`, firstName, lastName);
		}

		ToastService.info("Review request to " + name + " cancelled successfully!", "mb-toast mb-toast--small");

		this.fetchData();
	};

	handleReviewReminderButton = async reviewRequest => {
		let { id, name, phone, email, medium } = reviewRequest;

		// Set the initial state of the last sent field
		let location = UserService.getActiveLocation();

		// Check if the number or email was already sent a message
		let phoneOrEmail = email ? email : phone;

		try {
			let data = await LocationService.lastSentReviewRequest(location.id, phoneOrEmail);

			if (data.last_sent) {
				await this.update({
					name: name,
					phoneOrEmail: phoneOrEmail,
					parent: id,
					duplicateNumber: true,
					duplicateDate: data.created_at,
					parentTemplate: data.MessageTemplate
				});
				await this.update({ showSendReviewReminderModal: true });
			} else {
				await this.update({ name: name, phoneOrEmail: phoneOrEmail, parent: parent, duplicateNumber: false, duplicateDate: "", parentTemplate: null });
				await this.update({ showSendReviewReminderModal: true });
			}
		} catch (error) {
			console.log(error);
		}
	};

	handleCancelAutoRemind = async reviewRequest => {
		try {
			let response = await InviteService.cancelReviewRequest({ reviewRequestId: reviewRequest.id, cancelAutoReminder: true });

			if (!response) {
				ToastService.error("Failed to cancel reminder for review request to " + reviewRequest.name, "mb-toast mb-toast--error mb-toast--small");
				return;
			}

			ToastService.info("Auto reminder for review request to " + reviewRequest.name + " cancelled successfully!", "mb-toast mb-toast--small");

			this.fetchData();
		} catch (error) {
			console.log(error);
		}
	};

	handleSendInviteNow = async reviewRequest => {
		let reviewRequestId = reviewRequest.id;
		let name = reviewRequest.name;

		reviewRequest.cancelable = true;

		this.update({
			invites: this.state.invites
		});

		// Send request in 30 seconds
		let updatedReviewRequest = await InviteService.sendReviewRequest({ reviewRequestId });

		if (!updatedReviewRequest) {
			this.update({ showSendInviteErrorModal: true });
			return;
		}

		setTimeout(() => {
			this.handleSetAsSent(reviewRequest.id);
		}, 32000);

		ToastService.info(`Review request to ${name} will send in 30 seconds!`, "mb-toast mb-toast--small");
		reviewRequest.send_after = updatedReviewRequest.send_after;
		reviewRequest.cancelable = true;

		this.update({
			invites: this.state.invites
		});
	};

	handleSetAsSent = async reviewRequestId => {
		try {
			let invites = this.state.invites;
			for (let i = 0; i < invites.length; i++) {
				const invite = invites[i];
				if (
					invite.id === reviewRequestId &&
					invite.cancelable &&
					invite.status !== INVITE_STATUS.sent &&
					moment(invite.send_after).isSameOrBefore(moment(), "seconds")
				) {
					// if this review request is still cancelable that means that it hasn't been sent yet
					// therefore, we can send it
					invite.status = INVITE_STATUS.sent;
					invite.awaiting_action = false;
					ToastService.info(`Review request to ${invite.name} sent successfully!`, "mb-toast mb-toast--small");
				}
			}

			this.update({
				invites: this.state.invites
			});
		} catch (error) {
			this.update({ showSendInviteErrorModal: true });
			console.log(error);
		}
	};

	handleCancelInviteNow = async (reviewRequest, setToAwaitingAction) => {
		// If we want to keep the invite as an invite that is awaiting action
		if (setToAwaitingAction) {
			reviewRequest.cancelable = false;
			reviewRequest.awaiting_action = true;
			reviewRequest.status = INVITE_STATUS.pending;

			this.update({
				invites: this.state.invites
			});
		}

		let success = await InviteService.cancelReviewRequest({ reviewRequestId: reviewRequest.id, setToAwaitingAction: true });

		if (!success) {
			ToastService.error("Failed to cancel review invite. Please try again.");
			this.fetchData();
			return;
		}

		ToastService.info("Review request to " + reviewRequest.name + " cancelled  successfully!", "mb-toast mb-toast--small");

		this.fetchData();
	};

	/***
	 *
	 * Send Review Invites Modal
	 *
	 */

	handlePhoneOrEmailChange = async event => {
		// Check for duplicate numbers if we have a sufficient number of digits
		if (event.target.value.length <= 5) {
			this.update({ duplicateNumber: false, phoneOrEmail: event.target.value });
			return;
		}

		let location = UserService.getActiveLocation();
		let phoneOrEmail = event.target.value;

		let data = await LocationService.lastSentReviewRequest(location.id, phoneOrEmail);

		if (data && data.last_sent) {
			this.update({ duplicateNumber: true, duplicateDate: data.created_at });
		} else {
			this.update({ duplicateNumber: false, duplicateDate: "" });
		}

		this.update({ phoneOrEmail: event.target.value });
	};

	handleNameChange = event => {
		this.update({ name: event.target.value });
	};

	handleSendReviewReminderSubmit = async templateId => {
		const phoneOrEmail = this.state.phoneOrEmail;

		await this.update({ templateId });

		let medium = phoneOrEmail.indexOf("@") > -1 ? "email" : "phone";
		let data = { name: this.state.name, parent: this.state.parent, medium, phoneOrEmail, templateId: this.state.templateId };

		try {
			await InviteService.followUp(data);
		} catch (error) {
			console.log(error);
			if (error.response && error.response.data.error) {
				if (error.response.data.error === "!contact.receive_transactional_sms") {
					await this.update({ reminderFailedMessage: "Sending SMS to this contact is disabled." });
				}
				if (error.response.data.error === "!contact.receive_transactional_email") {
					await this.update({ reminderFailedMessage: "Sending email to this contact is disabled." });
				}
			} else {
				await this.update({ reminderFailedMessage: "The review reminder could not be sent. Please check the number/email and try again." });
			}
			await this.update({ showSendReviewReminderModal: false, templateId: 0 });
			await this.update({ showReviewReminderSending: false, showReviewReminderFailMessage: true });
			return;
		}

		await this.update({ showSendReviewReminderModal: false, templateId: 0 });
		await this.update({ showReviewReminderSending: false });

		ToastService.info("Review reminder sent!");
		this.fetchData();
	};

	handleChangeTemplate = templateId => {
		this.update({ templateId });
	};

	renderInviteQueueDetails = () => {
		let { inviteQueueDetails } = this.state;

		if (this.props.reminders) {
			return null;
		}

		const canCreateReviewInvites = LocationService.isCreateReviewsInvitesEnabled();
		let location = UserService.getActiveLocation();

		if (!canCreateReviewInvites) {
			return null;
		}

		if (!inviteQueueDetails || inviteQueueDetails.inviteCount < 1) {
			return null;
		}

		return (
			<div className="invites__invite-queue-message">
				<div data-tip data-for="invite-queue-deadline-tooltip">
					{location.invite_queue_deadline_action === INVITES_DEADLINE_ACTION.send
						? `${inviteQueueDetails.inviteCount} invite${inviteQueueDetails.inviteCount > 1 ? "s" : ""} will send starting on `
						: `${inviteQueueDetails.inviteCount} invite${inviteQueueDetails.inviteCount > 1 ? "s" : ""} will expire starting on `}
					<br className="hidden-md hidden-sm " />
					{moment(inviteQueueDetails.deadlineDateTime).format("MMMM Do [at] hh:mm a")} <Icon.Info size={15} />
				</div>
				<ReactTooltip id="invite-queue-deadline-tooltip" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="right">
					<div style={{ padding: "8px" }}>
						Invite Queue Deadline is set <br /> to every {moment(inviteQueueDetails.deadlineDateTime).format("dddd [at] hh:mm a")}
					</div>
				</ReactTooltip>
			</div>
		);
	};

	renderFilters = () => {
		let { searchTerm, statusFilter, remindableFilter, unknownNamesFilter } = this.state;
		let isReminders = this.props.reminders;
		let isScheduledReviewInvitesEnabled = LocationService.isReviewInvitesEnabled() && LocationService.isScheduledReviewInvitesEnabled();

		return (
			<>
				<div className="invites__filters">
					<div className="invites__filters__search">
						<SearchInput placeholder={"Search ..."} onChange={this.onSearchChange} initValue={searchTerm} leading={false} />
					</div>

					<div className="invites__filters__actions">
						{isScheduledReviewInvitesEnabled && !isReminders && (
							<Action key={`send-many`} id={`send-many`} label={"Send Many"} icon={Icon.Send} onClick={() => this.showScheduledMessageModal()} />
						)}
						<Action id={`refresh`} label={"Refresh"} icon={Icon.RefreshCcw} onClick={() => this.onRefreshInvites()} />
					</div>
				</div>

				<div className="invites__filters invites__filters--options">
					{this.renderDateRangeFilter()}

					<Select
						id="status-filter"
						name="status-filter"
						label="Status"
						className="invites__filters__select"
						value={statusFilter}
						defaultValue={statusFilter}
						options={Object.values(INVITES_STATUS_OPTIONS)}
						onChange={this.onStatusFilterChange}
						isClearable={true}
					/>

					{!isReminders && (
						<Select
							id="remindable-filter"
							name="remindable-filter"
							label="Remindable"
							className="invites__filters__select"
							value={remindableFilter}
							defaultValue={remindableFilter}
							options={Object.values(INVITES_REMINDABLE_OPTIONS)}
							onChange={this.onRemindableFilterChange}
							isClearable={true}
						/>
					)}

					<Select
						id="unknown-filter"
						name="unknown-filter"
						label="Unknown Names"
						className="invites__filters__select"
						value={unknownNamesFilter}
						defaultValue={unknownNamesFilter}
						options={Object.values(UNKNOWN_NAMES_OPTIONS)}
						onChange={this.unknownNameFilterChange}
						isClearable={true}
					/>
				</div>
			</>
		);
	};

	renderDateRangeFilter = () => {
		let { dateRangeStart, dateRangeEnd } = this.state;
		const modifiers = { dateRangeStart, dateRangeEnd };

		return (
			<div className="invites__filters__datepicker">
				<div className="common__datepicker__date-range__text" data-tip data-for="date-range-selector-rtt">
					Date Range <Icon.Info size={13} />
					<ReactTooltip id="date-range-selector-rtt" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
						Filter data based on the specified date range. When selecting custom date ranges there is a limit of a 45 day date range.
					</ReactTooltip>
				</div>
				<div className="input-group">
					<div className="InputFromTo">
						<DayPickerInput
							value={moment(dateRangeStart).toDate()}
							placeholder=" From"
							format="ll"
							formatDate={formatDate}
							parseDate={parseDate}
							dayPickerProps={{
								selectedDays: [dateRangeStart, { from: dateRangeStart, to: dateRangeEnd }],
								disabledDays: { after: dateRangeEnd },
								toMonth: dateRangeEnd,
								modifiers,
								numberOfMonths: 2,
								onDayClick: () => this.datePickerTo.getInput().focus()
							}}
							onDayChange={this.handleFromChange}
						/>{" "}
						<span>
							<DayPickerInput
								ref={el => (this.datePickerTo = el)}
								value={moment(dateRangeEnd).toDate()}
								placeholder=" To"
								format="ll"
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									selectedDays: [dateRangeStart, { from: dateRangeStart, to: dateRangeEnd }],
									disabledDays: { before: dateRangeStart },
									modifiers,
									month: dateRangeStart,
									fromMonth: dateRangeStart,
									numberOfMonths: 2
								}}
								onDayChange={this.handleToChange}
							/>
						</span>
					</div>
				</div>
			</div>
		);
	};

	/**
	 *
	 * Render record methods
	 *
	 */

	renderRecord = recordData => {
		try {
			let statusClassName = `invites__label ${INVITE_STATUS_CLASS[recordData.status]}`;

			return [
				recordData.name,
				recordData.phone || recordData.email,
				<div className={statusClassName}>{recordData.status}</div>,
				this.renderSendAfter(recordData),
				recordData.lead_source,
				this.renderClicked(recordData),
				recordData.Requester ? UserService.createFullName({ firstName: recordData.Requester.first_name, lastName: recordData.Requester.last_name }) : null,
				this.renderActions(recordData)
			];
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	renderSendAfter = recordData => {
		if (!recordData.send_after) {
			return null;
		}
		let location = UserService.getActiveLocation();
		let reviewsInviteQueueEnabled = LocationService.isInviteQueuePermissible();
		let status = recordData.status;

		return (
			<div>
				<span>{moment(recordData.send_after).format("MMM Do h:mm A")}</span>
				{reviewsInviteQueueEnabled &&
				recordData.awaiting_action &&
				location.invite_queue_deadline_action &&
				location.invite_queue_deadline_action === INVITES_DEADLINE_ACTION.expire &&
				status === INVITE_STATUS.pending ? (
					<span> (expires)</span>
				) : reviewsInviteQueueEnabled && status === INVITE_STATUS.pending ? (
					<span> (queued)</span>
				) : null}
			</div>
		);
	};

	renderClicked = recordData => {
		if (!recordData.clicked_at) {
			return null;
		}

		let lastEventData = recordData.event_metadata;

		try {
			lastEventData = JSON.parse(lastEventData);
		} catch (error) {
			lastEventData = null;
		}

		let lastEvent = "None";
		let lastDescription = "None";
		let lastTimestamp = "None";

		// If we have event data
		if (lastEventData && lastEventData.length > 0) {
			const lastEventDataItem = lastEventData[lastEventData.length - 1];

			if (lastEventDataItem.event) {
				lastEvent = lastEventDataItem.event;
			}

			if (lastEventDataItem.event_description) {
				lastDescription = lastEventDataItem.event_description;
			}

			if (lastEventDataItem.timestamp) {
				lastTimestamp = moment(lastEventDataItem.timestamp).format("MMM Do h:mm A");
			}
		}

		let tip = `Invitation Info\nClicked at: ${moment(recordData.clicked_at).format("MMM Do h:mm A")}`;

		if (lastEventData && UserService.isSuperAdminOrCustomerSuccess()) {
			tip += `\n\nLast Event: ${lastEvent}`;
			tip += `\nLast Event Description: ${lastDescription}`;
			tip += `\nLast Event Time: ${lastTimestamp}`;
			tip += `\nDevice: ${recordData.user_agent ? UAParser(recordData.user_agent).browser.name + " on " + UAParser(recordData.user_agent).os.name : "unknown"}`;
		}

		return (
			<div className="dh-tip dh-tip--large" tip={tip}>
				<Icon.Check />
			</div>
		);
	};

	renderActions = recordData => {
		let actions = [];

		let user = UserService.get();
		let location = UserService.getActiveLocation();
		let { reviews_auto_reminders } = location.LocationFeature;

		let canCreateReviewInvites = LocationService.isCreateReviewsInvitesEnabled();

		let reviewsInviteQueueEnabled = LocationService.isInviteQueuePermissible();

		if (this.props.reminders) {
			return this.renderReminderActions(recordData);
		}

		if (recordData.contact_id) {
			// Always add the contact mo
			actions.push(
				<Action
					key={`contact-details-${recordData.id}`}
					id={`contact-details-${recordData.id}`}
					label={"Contact Details"}
					icon={Icon.User}
					onClick={() => this.showContactDetailsModal(recordData.contact_id)}
				/>
			);
		}

		if (
			recordData.status !== INVITE_STATUS.pending &&
			recordData.status !== INVITE_STATUS.cancelled &&
			recordData.status !== INVITE_STATUS.failed &&
			//
			recordData.clicked_at === null &&
			recordData.parent_request_id === 0 &&
			recordData.followup_count === 0 &&
			// If the send after is recent.
			moment(recordData.send_after).isSameOrAfter(moment().subtract(5, "days"), "day") &&
			// If a user can send a reminder from this invite
			!(reviews_auto_reminders && recordData.is_remindable) &&
			user.GroupPermission.remind_invites &&
			canCreateReviewInvites
		) {
			actions.push(
				<Action
					key={`send-reminder-${recordData.id}`}
					id={`send-reminder-${recordData.id}`}
					className={"fnctst-reminder-button"}
					label={"Send Reminder"}
					icon={Icon.Send}
					onClick={() => this.handleReviewReminderButton(recordData)}
				/>
			);
		} else if (recordData.status === INVITE_STATUS.pending && reviewsInviteQueueEnabled && recordData.awaiting_action) {
			// Cancelable is a field added on the front end for invites are still able to be cancelled before x seconds
			if (recordData.cancelable) {
				actions.push(
					<Action
						key={`cancel-${recordData.id}`}
						id={`cancel-${recordData.id}`}
						className={"fnctst-cancel-button"}
						label={"Cancel Invite"}
						icon={Icon.X}
						onClick={() => this.handleCancelInviteNow(recordData, true)}
					/>
				);
			} else {
				actions.push(
					<Action
						key={`send-now-${recordData.id}`}
						id={`send-now-${recordData.id}`}
						className={"fnctst-send-now-button"}
						label={"Send Now"}
						icon={Icon.Send}
						onClick={() => this.handleSendInviteNow(recordData)}
					/>
				);
				actions.push(
					<Action
						key={`cancel-${recordData.id}`}
						id={`cancel-${recordData.id}`}
						className={"fnctst-cancel-button"}
						label={"Cancel Invite"}
						icon={Icon.X}
						onClick={() => this.handleCancelInviteNow(recordData, false)}
					/>
				);
			}
		} else if (recordData.status === INVITE_STATUS.pending) {
			actions.push(
				<Action
					key={`cancel-${recordData.id}`}
					id={`cancel-${recordData.id}`}
					className={"fnctst-cancel-button"}
					label={"Cancel Invite"}
					icon={Icon.X}
					onClick={() => this.handleReviewCancelButton(recordData)}
				/>
			);
		} else if (recordData.status === INVITE_STATUS.cancelled || recordData.status === INVITE_STATUS.failed) {
			actions.push(
				<Action
					key={`resend-invite-${recordData.id}`}
					id={`resend-invite-${recordData.id}`}
					className={"fnctst-resend-button"}
					label={"Resend Invite"}
					icon={Icon.Send}
					onClick={() => this.handleReviewUncancelButton(recordData)}
				/>
			);
		}

		return <div className="contacts__actions ">{actions}</div>;
	};

	renderReminderActions = recordData => {
		let actions = [];

		// The contact details modal
		if (recordData.contact_id) {
			actions.push(
				<Action
					key={`contact-details-${recordData.id}`}
					id={`contact-details-${recordData.id}`}
					label={"Contact Details"}
					icon={Icon.User}
					onClick={() => this.showContactDetailsModal(recordData.contact_id)}
				/>
			);
		}

		if (recordData.status === INVITE_STATUS.pending) {
			actions.push(
				<Action
					key={`cancel-reminder-${recordData.id}`}
					id={`cancel-reminder-${recordData.id}`}
					label={"Cancel Reminder"}
					icon={Icon.X}
					onClick={() => this.handleReviewCancelButton(recordData)}
				/>
			);
		} else if (recordData.status === INVITE_STATUS.cancelled || recordData.status === INVITE_STATUS.failed) {
			actions.push(
				<Action
					key={`resend-reminder-${recordData.id}`}
					id={`resend-reminder-${recordData.id}`}
					label={"Resend Reminder"}
					icon={Icon.Send}
					onClick={() => this.handleReviewUncancelButton(recordData)}
				/>
			);
		}

		return <div className="contacts__actions ">{actions}</div>;
	};

	renderAlerts = () => {
		return (
			<Alert
				type="error"
				show={this.state.showReviewReminderFailMessage}
				title="Reminder Error"
				confirm="OK"
				onClose={() => {
					this.update({ showReviewReminderFailMessage: false });
				}}
			>
				<div>{this.state.reminderFailedMessage}</div>
			</Alert>
		);
	};

	renderSendReminderModal = () => {
		let { showSendReviewReminderModal, duplicateNumber, duplicateDate, phoneOrEmail, name, showReviewReminderSending, templateId, parentTemplate } = this.state;

		return (
			<SendReviewInviteModal
				key="invite-remind-modal"
				showModal={showSendReviewReminderModal}
				handleOnHide={() => this.update({ showSendReviewReminderModal: false })}
				duplicateNumber={duplicateNumber}
				duplicateDate={duplicateDate}
				phoneOrEmail={phoneOrEmail}
				handlePhoneOrEmailChange={this.handlePhoneOrEmailChange}
				handleNameChange={e => this.handleNameChange(e)}
				name={name}
				showSending={showReviewReminderSending}
				handleSubmit={this.handleSendReviewReminderSubmit}
				type={SEND_REVIEW_INVITES_MODAL.type.reviewReminder}
				title="Send Review Reminder"
				handleChangeTemplate={this.handleChangeTemplate}
				templateId={templateId}
				parentTemplate={parentTemplate}
				reviewType="review_reminder"
				showPreview={true}
			/>
		);
	};

	render() {
		let {
			invites,
			showScheduledMessageModal,
			sortOrder,
			sortField,
			inviteCount,
			invitesPage,
			limit,
			showEditContactModal,
			showContactDetailsContactId
		} = this.state;
		return (
			<Page>
				{this.renderInviteQueueDetails()}
				{this.renderFilters()}

				<List
					id="review-invites-list"
					pagination={true}
					pages={Math.ceil(inviteCount / limit.value)}
					totalCount={inviteCount}
					currentPage={invitesPage}
					onPaginateChange={this.onPaginateChange}
					limit={limit}
					onLimitChange={this.onLimitChange}
					items={invites}
					loading={false}
					loadedAll={true}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle={"No review invites..."}
					noDataIcon={<Icon.AlertCircle />}
				/>
				<ScheduledMessageModal show={showScheduledMessageModal} onHide={this.closeScheduledMessageModal} type={SM_TYPES.reviewInvite} />

				{this.renderAlerts()}

				<EditContactModal
					show={showEditContactModal}
					contactId={showContactDetailsContactId}
					onClose={this.onContactDetailsClosed}
					onSave={this.onContactDetailsSaved}
				/>
				{this.renderSendReminderModal()}
			</Page>
		);
	}
}

export default withRouter(withLocation(Invites));
