import React from "react";
import PropTypes from "prop-types";

/**
 * This component returns an action dropdrown.
 * @prop {string} id
 * @prop {string} bsStyle
 * @prop {string} title
 * @prop {array} options
 * @prop {object} handleOnClick
 * @prop {any} data
 */

const ActionDropdown = ({ id, title, options, handleOnClick, data }) => (
	<select
		id={id}
		className="form-control"
		onChange={e => {
			e.preventDefault();
			handleOnClick[e.target.value](data);
			e.target.value = title;
		}}
	>
		<option id={title} value={title}>
			{title}
		</option>
		{options.map(anOption => (
			<option key={anOption.label} id={anOption.label} value={anOption.value}>
				{anOption.label}
			</option>
		))}
	</select>
);

ActionDropdown.propTypes = {
	title: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
	handleOnClick: PropTypes.objectOf(PropTypes.func).isRequired,
	data: PropTypes.any
};

export default ActionDropdown;
