import React from "react";
import Select from "react-select";
import { withTranslation } from "react-i18next";

import { COUNTRIES } from "../../constants/Countries";

class CountryDropdown extends React.Component {
	render() {
		const { value, required, id, onChange, t } = this.props;
		return (
			<Select
				id={id}
				options={COUNTRIES.active}
				onChange={newValue => onChange(newValue)}
				value={value}
				focusedOption={value}
				placeholder={t("Please select a Country")}
				required={required || false}
				isClearable
			/>
		);
	}
}

export default withTranslation(null, { withRef: true })(CountryDropdown);
