import Kichiri from "./KichiriService";
import UserService from "./UserService";

const NumberService = {
	/**
	 * Converts the specified huge number into a hex string but don't know how!!
	 * @param {Number}
	 */
	largeDecimalTohex(number) {
		let dec = number.toString().split(""),
			sum = [],
			hex = [],
			i,
			s;
		while (dec.length) {
			s = 1 * dec.shift();
			for (i = 0; s || i < sum.length; i++) {
				s += (sum[i] || 0) * 10;
				sum[i] = s % 16;
				s = (s - sum[i]) / 16;
			}
		}
		while (sum.length) {
			hex.push(sum.pop().toString(16));
		}
		return hex.join("");
	},

	/**
	 * Check the if a phone number is importable for bandwidth
	 * @param {Number} phoneNumber
	 */
	async checkImportability(phoneNumber) {
		try {
			let response = await Kichiri.number.checkImportability({ phone: phoneNumber }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return false;
	},

	/**
	 * Import a phone number
	 * @param {String} phoneNumber
	 * @param {String} name
	 * @param {String} houseNumber
	 * @param {String} streetName
	 * @param {String} city
	 * @param {String} stateCode
	 * @param {String} zip
	 * @param {String} authorizingPerson
	 * @return {Object} response data
	 */
	async importNumber(phoneNumber, name, houseNumber, streetName, city, stateCode, zip, authorizingPerson) {
		try {
			let response = await Kichiri.number.importNumber(
				{ phoneNumber, name, houseNumber, streetName, city, stateCode, zip, authorizingPerson },
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Check the status of an import order
	 * @param {String} phoneNumber
	 * @return {Object} response data
	 */
	async checkImportOrderStatus(phoneNumber) {
		try {
			let response = await Kichiri.number.checkImportOrderStatus({}, { phone: phoneNumber }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
			return {
				error: true,
				message: "An error occured trying to check the import status of an order."
			};
		}
	},

	/**
	 * Order a phone number
	 * @param {String} phone
	 * @param {String} provider like bandwidth or twilio
	 * @returns
	 */
	async orderNumber(phone, provider) {
		try {
			let response = await Kichiri.number.orderANumber({ phone, provider }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch numbers from a location or company
	 * @param {Number} companyId
	 * @param {Number} locationId
	 * @param {String} status
	 * @param {Boolean} safe
	 * @param {String} api bandwidthV2 or twilio
	 * @returns
	 */
	async fetchNumbers({ companyId, locationId, status, safe, api }) {
		try {
			let response = await Kichiri.number.fetchNumbers({}, { companyId, locationId, status, safe, api }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Count the number of conversations that a number is associated to
	 * @param {Integer} locationId
	 * @param {String} number
	 */
	async countConversations({ locationId, number }) {
		try {
			let result = await Kichiri.number.countConversations({}, { number, locationId }, UserService.getAuthToken());

			return result.data;
		} catch (error) {
			console.log(error);
			throw error;
		}
	},

	/**
	 * Fetch available numbers
	 * @param {String} areaCode
	 * @param {String} provider
	 * @param {String} countryCode
	 * @returns {Array}
	 */
	async fetchAvailableNumbers({ areaCode, provider, countryCode }) {
		try {
			const response = await Kichiri.number.fetchAvailableNumbers({}, { areaCode, provider, countryCode }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch assignable phone numbers
	 * @param {Integer} locationId
	 * @returns {Array}
	 */
	async fetchAssignableNumbers({ locationId }) {
		try {
			let response = await Kichiri.number.fetchAssignable(
				{},
				{
					locationId
				},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	}
};

export default NumberService;
