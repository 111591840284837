import React, { PureComponent } from "react";
import moment from "moment";
import { RRule, rrulestr } from "rrule";
import DatePicker from "react-datepicker";

import Input from "../../components/common/Input";
import DHSelect from "../../components/common/DHSelect";

import "./rrule.css";

const FREQUENCIES = {
	daily: {
		value: "daily",
		label: "Daily"
	},
	weekly: {
		value: "weekly",
		label: "Weekly"
	},
	monthly: {
		value: "monthly",
		label: "Monthly"
	},
	// TODO - DH-3612 - Add "yearly" frequency to RRuleEditor
	// yearly: {
	// 	value: "yearly",
	// 	label: "Yearly"
	// },
	never: {
		value: "never",
		label: "Never"
	}
};

const WEEKDAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const RRULE_MONTH_WEEK = {
	first: {
		value: "first",
		label: "First",
		rrule: "BYSETPOS=1"
	},
	second: {
		value: "second",
		label: "Second",
		rrule: "BYSETPOS=2"
	},
	third: {
		value: "third",
		label: "Third",
		rrule: "BYSETPOS=3"
	},
	fourth: {
		value: "fourth",
		label: "Fourth",
		rrule: "BYSETPOS=4"
	},
	last: {
		value: "last",
		label: "Last",
		rrule: "BYSETPOS=-1"
	}
};

export const RRULE_MONTH_WEEK_DAY = [
	{
		value: "sunday",
		label: "Sunday",
		rrule: "SU"
	},
	{
		value: "monday",
		label: "Monday",
		rrule: "MO"
	},
	{
		value: "tuesday",
		label: "Tuesday",
		rrule: "TU"
	},
	{
		value: "wednesday",
		label: "Wednesday",
		rrule: "WE"
	},
	{
		value: "thursday",
		label: "Thursday",
		rrule: "TH"
	},
	{
		value: "friday",
		label: "Friday",
		rrule: "FR"
	},
	{
		value: "saturday",
		label: "Saturday",
		rrule: "SA"
	},
	{
		value: "day",
		label: "Day",
		rrule: "MO,TU,WE,TH,FR,SA,SU"
	},
	{
		value: "weekday",
		label: "Weekday",
		rrule: "MO,TU,WE,TH,FR"
	},
	{
		value: "weekendDay",
		label: "Weekend Day",
		rrule: "SA,SU"
	}
];

export const RRULE_END_TYPES = [
	{
		value: "never",
		label: "Never"
	},
	{
		value: "after",
		label: "After"
	},
	{
		value: "on",
		label: "On"
	}
];

export const RRULE_BYSETPOS = {
	"1": RRULE_MONTH_WEEK.first,
	"2": RRULE_MONTH_WEEK.second,
	"3": RRULE_MONTH_WEEK.third,
	"4": RRULE_MONTH_WEEK.fourth,
	"-1": RRULE_MONTH_WEEK.last
};

const RRULE_MONTH_TYPE = {
	day: "day",
	week: "week"
};

class RRuleEditor extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			frequencies: Object.values(FREQUENCIES),
			frequency: FREQUENCIES.daily,

			// Frequency: Daily
			days: 1,

			// Frequency: Weekly
			weeks: 1,
			selectedWeekdays: {},

			// Frequency: Months
			months: 1,
			monthType: RRULE_MONTH_TYPE.day,
			monthDay: { value: 1, label: 1 },
			monthWeek: RRULE_MONTH_WEEK.first,
			monthWeekDay: RRULE_MONTH_WEEK_DAY[0],

			endType: RRULE_END_TYPES[0],
			endExecutions: 0,
			endDate: new Date()
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	convertDateToRRuleFormat({ date }) {
		return (
			moment(date)
				.utc()
				.format("YYYYMMDDTHHmmss") + "Z"
		);
	}

	componentDidMount() {
		this.processRRuleProp();
	}

	componentDidUpdate(prevProps) {
		let { rrule } = this.props;

		if (rrule !== prevProps.rrule) {
			this.processRRuleProp();
		}
	}

	processRRuleProp = async () => {
		// Grab the RRule from the props
		let { rrule } = this.props;

		if (!rrule || rrule.length === 0) {
			return;
		}

		// Use the rrule library to parse and interpret the rrule string
		let rruleObject = rrulestr(rrule);

		// Grab the original options, since this will be the main object we will be using to construct our component state from the RRule string
		let options = rruleObject.origOptions;
		// Get the frequency
		let frequency = options.freq;

		let endDate = options.until;
		let endExecutions = options.count;

		// For the DAILY frequency, all we need is the days
		if (frequency === RRule.DAILY) {
			await this.update({
				frequency: FREQUENCIES.daily,
				days: options.interval
			});
		}
		// If the frequency is WEEKLY
		else if (frequency === RRule.WEEKLY) {
			// Retreive the byweekday from the rrule object (eg. BYWEEKDAY=MO,TU,WE)
			// Tis is a list of RRule Weekday Objects in which is the following anatomy = [{ weekday: 0 }, { weekday: 1 } ... ]
			let byweekday = options.byweekday;
			// Create a state specific selectedWeekdays object
			let selectedWeekdays = {};

			// Go through each item in the RRule object byweekday array, map it to a day and then set the key in the selectedWeekday object
			// eg. [{ weekday: 0 }, { weekday: 2 }]  --->  { Monday: true, Wednesday: true }
			for (let item of byweekday) {
				selectedWeekdays[WEEKDAYS[item.weekday]] = true;
			}

			await this.update({
				frequency: FREQUENCIES.weekly,
				weeks: options.interval,
				selectedWeekdays
			});
		} else if (frequency === RRule.MONTHLY) {
			// Retrieve the by month day from the RRule object eg. BYMONTHDAY=3
			let bymonthday = options.bymonthday;
			// Determine if the by month day attribute exists
			let doesByMonthDayExist = typeof bymonthday !== "undefined";

			// If the BYMONTHDAY attribute exists, then this is a monthly frequency RRule with specified days
			let monthType = doesByMonthDayExist ? RRULE_MONTH_TYPE.day : RRULE_MONTH_TYPE.week;

			if (monthType === RRULE_MONTH_TYPE.day) {
				await this.update({
					frequency: FREQUENCIES.monthly,
					months: options.interval,

					monthType,
					monthDay: { id: bymonthday, name: bymonthday }
				});
			} else if (monthType === RRULE_MONTH_TYPE.week) {
				// Using the value of the BYSETPOS we can know which we can know which of the FIRST, SECOND, THIRD, FOURTH, LAST positions need to be set for this RRule
				let monthWeek = RRULE_BYSETPOS[options.bysetpos.toString()];

				// Gross starts here
				// Using the embedded byweekday array in the RRule Object, we can determine the weekdays that this monthly frequency rule applies for
				// eg. [0,2,4] ---> [MO,WE,FR]
				let value = rruleObject.options.byweekday.map(day => {
					return WEEKDAYS[day].substring(0, 2).toUpperCase();
				});

				// Generate the match string for the RRule
				// eg. [MO,WE,FR]  --->  "MO,WE,FR"
				let rruleValue = value.join(",");
				let rruleSelected = null;

				// Iterate over all the Month Week Day possible rules and match the rrule BYWEEKDAY code to determine the right option for the UI
				// eg. "SA,SU"  --->  { id: "weekendDay", name: "Weekend Day", rrule: "SA,SU" }
				for (let rule of RRULE_MONTH_WEEK_DAY) {
					if (rule.rrule === rruleValue) {
						rruleSelected = rule;
						break;
					}
				}
				// Gross ends here

				await this.update({
					frequency: FREQUENCIES.monthly,
					months: options.interval,

					monthType,

					monthWeek,
					monthWeekDay: rruleSelected
				});
			}
		}

		if (endDate) {
			await this.update({
				endType: RRULE_END_TYPES[2],
				endExecutions: 0,
				endDate: endDate
			});
		}

		if (endExecutions) {
			await this.update({
				endType: RRULE_END_TYPES[1],
				endExecutions: endExecutions,
				endDate: null
			});
		}
	};

	generateRRule = () => {
		let {
			frequency,

			days,

			weeks,
			selectedWeekdays,

			months,
			monthType,
			monthDay,
			monthWeek,
			monthWeekDay,

			endType,
			endExecutions,
			endDate
		} = this.state;

		let { startDate } = this.props;

		let startDateInUTC = this.convertDateToRRuleFormat({ date: startDate });

		let rrule = `DTSTART:${startDateInUTC}\nRRULE:`;

		if (frequency.value === FREQUENCIES.daily.value) {
			rrule = `${rrule}FREQ=DAILY;INTERVAL=${days}`;
		} else if (frequency.value === FREQUENCIES.weekly.value) {
			rrule = `${rrule}FREQ=WEEKLY;INTERVAL=${weeks}`;

			if (Object.keys(selectedWeekdays).length > 0) {
				let weekDays = Object.keys(selectedWeekdays).map(selectedWeekday => {
					return selectedWeekday.substring(0, 2).toUpperCase();
				});

				let weekDayRRule = weekDays.join(",");

				rrule = `${rrule};BYDAY=${weekDayRRule}`;
			}
		} else if (frequency.value === FREQUENCIES.monthly.value) {
			rrule = `${rrule}FREQ=MONTHLY;INTERVAL=${months}`;

			if (monthType === RRULE_MONTH_TYPE.day) {
				rrule = `${rrule};BYMONTHDAY=${monthDay.value}`;
			} else if (monthType === RRULE_MONTH_TYPE.week) {
				let bySetPos = monthWeek.rrule;
				let byDay = monthWeekDay.rrule;

				rrule = `${rrule};${bySetPos};BYDAY=${byDay}`;
			}
		}

		// End Type is "After"
		if (endType.value === RRULE_END_TYPES[1].value) {
			rrule = `${rrule};COUNT=${endExecutions}`;
		}
		// End type is "On Date"
		else if (endType.value === RRULE_END_TYPES[2].value) {
			let endDateInUTC = this.convertDateToRRuleFormat({ date: endDate });

			rrule = `${rrule};UNTIL=${endDateInUTC}`;
		}

		// If the frequency defined is "Never", then set the rrule to an empty string
		if (frequency.value === FREQUENCIES.never.value) {
			rrule = "";
		}

		if (this.props.onChange) {
			this.props.onChange({ rrule });
		}
	};

	onInputChange = async event => {
		await this.update({ [event.target.name]: event.target.value });

		this.generateRRule();
	};

	onRepeatChange = async frequency => {
		await this.update({
			frequency
		});

		this.generateRRule();
	};

	renderRepeat() {
		let { frequency, frequencies } = this.state;

		return (
			<DHSelect
				label="Repeats"
				name="rrule-repeat"
				id="rrule-repeat"
				onChange={this.onRepeatChange}
				defaultValue={frequency}
				value={frequency}
				options={frequencies}
				required={true}
				isClearable={false}
			/>
		);
	}

	renderDaily() {
		let { days } = this.state;
		return (
			<div className="rrule__frequency">
				<div className="rrule__frequency__row">
					<div className="rrule__frequency__row__item">every</div>
					<Input label="" name="days" id="days" type="number" onChange={this.onInputChange} value={days} />
					<div className="rrule__frequency__row__item">day(s)</div>
				</div>
			</div>
		);
	}

	onWeekdaySelected = async ({ weekday }) => {
		let { selectedWeekdays } = this.state;

		if (typeof selectedWeekdays[weekday] !== "undefined") {
			delete selectedWeekdays[weekday];
		} else {
			selectedWeekdays[weekday] = true;
		}

		await this.update({
			selectedWeekdays
		});

		this.forceUpdate();

		this.generateRRule();
	};

	renderWeekly() {
		let { weeks } = this.state;
		return (
			<div className="rrule__frequency">
				<div className="rrule__frequency__row">
					<div className="rrule__frequency__row__item">every</div>
					<Input label="" name="weeks" onChange={this.onInputChange} value={weeks} type="number" />
					<div className="rrule__frequency__row__item">week(s)</div>
				</div>
				{this.renderWeekDays()}
			</div>
		);
	}

	renderWeekDays() {
		let { selectedWeekdays } = this.state;

		return (
			<>
				<div className="rrule__frequency__pills">
					{WEEKDAYS.map(weekday => {
						return (
							<div
								className={`rrule__frequency__pills__item ${selectedWeekdays[weekday] ? "rrule__frequency__pills__item--active" : ""} `}
								onClick={() => this.onWeekdaySelected({ weekday })}
							>
								{weekday}
							</div>
						);
					})}
				</div>
			</>
		);
	}

	onMonthDayChange = async monthDay => {
		await this.update({ monthDay });
		this.generateRRule();
	};

	onMonthWeekChange = async monthWeek => {
		await this.update({ monthWeek });
		this.generateRRule();
	};

	onMonthWeekDayChange = async monthWeekDay => {
		await this.update({ monthWeekDay });
		this.generateRRule();
	};

	onSelectMonthType = async ({ type }) => {
		await this.update({ monthType: type });
		this.generateRRule();
	};

	generateDays() {
		let days = Array.from({ length: 31 }, (_, i) => i + 1);
		let monthDays = days.map(day => ({ value: day, label: day }));

		return monthDays;
	}

	renderMonthly() {
		let { months, monthType, monthDay, monthWeek, monthWeekDay } = this.state;

		return (
			<div className="rrule__frequency">
				<div className="rrule__frequency__row">
					<div className="rrule__frequency__row__item">every</div>
					<Input label="" name="months" onChange={this.onInputChange} value={months} type="number" />
					<div className="rrule__frequency__row__item">month</div>
				</div>

				<div className="rrule__frequency__row">
					<div
						className={`rrule__frequency__row__select ${monthType === RRULE_MONTH_TYPE.day ? "rrule__frequency__row__select--active" : ""}`}
						onClick={() => this.onSelectMonthType({ type: RRULE_MONTH_TYPE.day })}
					/>
					<div className="rrule__frequency__row__item">on day</div>
					<DHSelect
						className="rrule__frequency__row__month-day-select"
						label=""
						name="monthDay"
						id="monthDay"
						onChange={this.onMonthDayChange}
						defaultValue={1}
						value={monthDay}
						options={this.generateDays()}
						required={true}
						isClearable={false}
					/>
				</div>

				<div className="rrule__frequency__row">
					<div
						className={`rrule__frequency__row__select ${monthType === RRULE_MONTH_TYPE.week ? "rrule__frequency__row__select--active" : ""}`}
						onClick={() => this.onSelectMonthType({ type: RRULE_MONTH_TYPE.week })}
					/>
					<div className="rrule__frequency__row__item">on the</div>

					<div className="rrule__frequency__row__select-wrapper">
						<DHSelect
							label=""
							name="monthWeek"
							id="monthWeek"
							onChange={this.onMonthWeekChange}
							defaultValue={Object.values(RRULE_MONTH_WEEK)[0]}
							value={monthWeek}
							options={Object.values(RRULE_MONTH_WEEK)}
							required={true}
							isClearable={false}
						/>
					</div>
					<div className="rrule__frequency__row__select-wrapper">
						<DHSelect
							label=""
							name="monthWeekDay"
							id="monthWeekDay"
							onChange={this.onMonthWeekDayChange}
							defaultValue={RRULE_MONTH_WEEK_DAY[0]}
							value={monthWeekDay}
							options={RRULE_MONTH_WEEK_DAY}
							required={true}
							isClearable={false}
						/>
					</div>
				</div>
			</div>
		);
	}

	onEndTypeChange = async endType => {
		await this.update({ endType });
		this.generateRRule();
	};

	onEndDateChange = async endDate => {
		await this.update({ endDate });
		this.generateRRule();
	};

	renderEndDate() {
		let { endType, endExecutions, endDate } = this.state;

		let occurrenceLabel = endExecutions > 1 ? "occurrences" : "occurrence";

		return (
			<div className="rrule__end">
				<div className="rrule__end__item">and end</div>
				<div className="rrule__end__end-type-select">
					<DHSelect
						label=""
						name="endType"
						id="endType"
						onChange={this.onEndTypeChange}
						defaultValue={RRULE_END_TYPES[0]}
						value={endType}
						options={RRULE_END_TYPES}
						required={true}
						isClearable={false}
					/>
				</div>

				{/* End Type is "After" */}
				{endType.value === RRULE_END_TYPES[1].value && (
					<>
						<Input label="" name="endExecutions" onChange={this.onInputChange} value={endExecutions} type="number" />
						<div className="rrule__end__item">{occurrenceLabel}</div>
					</>
				)}

				{/* End Type is "On Date" */}
				{endType.value === RRULE_END_TYPES[2].value && (
					<div className="rrule__end__end-date">
						<DatePicker
							className="Common__input"
							selected={endDate}
							onChange={this.onEndDateChange}
							timeFormat="HH:mm"
							timeIntervals={15}
							dateFormat="MMMM d, yyyy"
							timeCaption="time"
							autoComplete="off"
						/>
					</div>
				)}
			</div>
		);
	}

	isFrequencyNever = () => {
		let { frequency } = this.state;

		return frequency.value === FREQUENCIES.never.value;
	};

	render() {
		let { frequency } = this.state;

		return (
			<div className="rrule">
				{this.renderRepeat()}
				{frequency.value === FREQUENCIES.daily.value && this.renderDaily()}
				{frequency.value === FREQUENCIES.weekly.value && this.renderWeekly()}
				{frequency.value === FREQUENCIES.monthly.value && this.renderMonthly()}
				{/* TODO - DH-3612 - Add Yearly Frequency */}
				{!this.isFrequencyNever() && this.renderEndDate()}
			</div>
		);
	}
}

export default RRuleEditor;
