import React from "react";
import "../../styles/css/components/commons/filters.css";

const Filter = ({ id, badge, selected, onSelect, children }) => {
	let isSelected = id === selected;
	return (
		<div key={id} className={`dh-filters__item ${isSelected ? "dh-filters__items--selected" : ""}`} onClick={() => onSelect({ id, children })}>
			<span className="dh-filters__item--text">{children}</span>
			{badge && <span className="dh-filters__badge">{badge}</span>}
		</div>
	);
};

export default Filter;
