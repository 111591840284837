import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactSwitch from "react-switch";
import moment from "moment";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import Spinners from "../../components/common/Spinners";
import TimeDropdown from "../../components/common/TimeDropdown";
import Alert from "../../components/common/Alert";
import withLocation from "../../components/common/WithLocation";

import GAService from "../../services/GAService";
import UserService from "../../services/UserService";
import JSONService from "../../services/JSONService";
import LocationService from "../../services/LocationService";

import { SCHEDULE } from "../../constants/LocationConstants";

import "../../styles/css/components/commons/schedule-hours.css";

class ScheduleHours extends Component {
	state = {
		schedules: [],
		locationId: UserService.getActiveLocation().id,
		gmbConnected: false,

		fetchError: false,
		hoursLoading: true,
		savingHours: false,
		updateError: false,
		updateSuccess: false,
		timeInvalid: false
	};

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		await this.getSchedulesData();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async getSchedulesData() {
		const { locationId } = this.state;
		let { type } = this.props;

		if (!locationId) {
			this.setState({ fetchError: true, hoursLoading: false });
			return;
		}
		let response = await LocationService.getSchedulesForLocation(locationId, type);

		if (!response) {
			this.update({ fetchError: true, hoursLoading: false });
			return;
		}

		let schedules = response;

		let gmbConnected = (await LocationService.hasIntegrations(locationId)).gmb ? true : false;

		await this.update({
			schedules: JSONService.sortByDay(schedules, "day", "ASC"),
			gmbConnected,
			hoursLoading: false
		});
	}

	onLocationChanged = async location => {
		let locationId = UserService.getActiveLocation().id;
		await this.update({ locationId });
		await this.getSchedulesData();
	};

	handleClosedOnChange = (day, checked) => {
		let { schedules } = this.state;

		// Make a copy of schedules
		let newSchedules = JSON.parse(JSON.stringify(schedules));

		// Find the index of the day
		let indexOfSchedule = newSchedules.findIndex(s => s.day === day);

		// Update the open or close time
		newSchedules[indexOfSchedule].closed = !checked;

		this.update({
			schedules: newSchedules
		});
	};

	handleDropdownSelect = (day, timingIndex, openOrCloseTime, newValue) => {
		let { t } = this.props;

		if (!newValue) {
			return;
		}

		newValue = newValue.target.value;

		let isValid = true;
		let hours = parseInt(newValue.split(":")[0]);
		let minutes = parseInt(newValue.split(":")[1]);

		if (!(0 <= hours && hours <= 24)) {
			isValid = false;
		}
		if (!(0 <= minutes && minutes <= 59)) {
			isValid = false;
		}

		if (!isValid) {
			this.setState({ [day + timingIndex + "OpenError"]: `${t("Invalid time entered")} ${hours} ${minutes}` });
		} else {
			this.setState({ [day + timingIndex + "OpenError"]: "" });
		}

		let { schedules } = this.state;

		// Make a copy of schedules
		let newSchedules = JSON.parse(JSON.stringify(schedules));

		// Find the index of the day
		let indexOfSchedule = newSchedules.findIndex(s => s.day === day);

		// Update the open or close time
		if (openOrCloseTime === "open") {
			newSchedules[indexOfSchedule].timingsArray[timingIndex].open = newValue;
		} else {
			newSchedules[indexOfSchedule].timingsArray[timingIndex].close = newValue;
		}

		this.update({
			schedules: newSchedules
		});
	};

	/**
	 * Check if a start and end time indicate that the hours are night hours.
	 * For example: 10pm to 3am.
	 *
	 * @param {String} startTime - A string representing the message start time (in HH:mm format) (eg. "09:00")
	 * @param {String} endTime - A string representing the message end time (in HH:mm format) (eg. "20:00")
	 * @returns {Boolean}
	 *
	 */
	areNightHours({ startTime, endTime }) {
		// Extract the range's start time
		let startHour = parseInt(startTime.split(":")[0], 10);
		let startMinute = parseInt(startTime.split(":")[1], 10);

		// Extract the range's end time
		let endHour = parseInt(endTime.split(":")[0], 10);
		let endMinute = parseInt(endTime.split(":")[1], 10);

		// A business either has
		// A: Day hours where the startTime is less than the endTime: Like 3pm open and 5pm close
		// B: Night hours where the startTime is more than the endTime: Like 11pm open and 2am close
		// Note: If the hour is the same, check to see if the start minute is before the end minute
		const isStartTimeLargerThanEndTime = startHour > endHour || (startHour === endHour && startMinute >= endMinute);

		return isStartTimeLargerThanEndTime;
	}

	isTimeValid = () => {
		try {
			let { schedules } = this.state;
			let { t } = this.props;

			// Loop through each day
			for (let i = 0; i < schedules.length; i++) {
				let aSchedule = schedules[i];

				let dayName = aSchedule.day; // ex: Wednesday
				// let closed = aSchedule.closed; // 0 or 1
				let timingsArray = aSchedule.timingsArray; // [ { open: "00:00", close: "02:00" }, ... ]

				// Go through all the timings
				for (let j = 0; j < timingsArray.length; j++) {
					let timePair = timingsArray[j];
					let openTime = timePair.open;
					let closeTime = timePair.close;

					// Make sure the opening time is a proper time
					const isOpenValid = moment(openTime, ["h:mm A", "HH:mm", "HH:mm A", "h:mm", "HH:mmA", "h:mmA"], true).isValid();
					if (!isOpenValid) {
						throw new Error(t("Invalid timing on {{dayName}}: {{openTime}} is not valid", { dayName: dayName, openTime: openTime }));
					}

					// Make sure the closing time is a proper time
					const isCloseValid = moment(closeTime, ["h:mm A", "HH:mm", "HH:mm A", "h:mm", "HH:mmA", "h:mmA"], true).isValid();
					if (!isCloseValid) {
						throw new Error(t("Invalid timing on {{dayName}}: {{closeTime}} is not valid", { dayName: dayName, closeTime: closeTime }));
					}

					// Make sure the start time is always less than the end time
					let isNightHours = this.areNightHours({ startTime: openTime, endTime: closeTime });
					if (isNightHours) {
						throw new Error(
							t("Invalid timing on {{dayName}}: The close time {{closeTime}} must be later than the open time {{openTime}}", {
								dayName: dayName,
								closeTime: closeTime,
								openTime: openTime
							})
						);
					}
				}
			}

			return true;
		} catch (e) {
			console.log(e.message);
			return false;
		}
	};

	addHoursRow = day => {
		let { schedules } = this.state;

		// Make a copy of schedules
		let newSchedules = JSON.parse(JSON.stringify(schedules));

		// Find the index of the day
		let indexOfSchedule = newSchedules.findIndex(s => s.day === day);

		// Declare a blank new row
		let blankPair = { open: "00:00", close: "00:00" };

		// Add this row to the timings
		newSchedules[indexOfSchedule].timingsArray.push(blankPair);

		this.update({
			schedules: newSchedules
		});
	};

	removeHoursRow = (day, timingsIndex) => {
		let { schedules } = this.state;

		// Make a copy of schedules
		let newSchedules = JSON.parse(JSON.stringify(schedules));

		// Find the index of the day
		let indexOfSchedule = newSchedules.findIndex(s => s.day === day);

		// Remove the timing pair
		newSchedules[indexOfSchedule].timingsArray.splice(timingsIndex, 1);

		this.update({
			schedules: newSchedules
		});
	};

	async handleScheduleOnSave(e) {
		const { schedules, locationId } = this.state;
		let { type } = this.props;

		if (!this.isTimeValid()) {
			this.setState({ timeInvalid: true });
			return;
		}

		await this.update({ savingHours: true });

		let response = await LocationService.updateSchedule({
			locationId,
			type,
			schedule: schedules
		});

		if (!response) {
			await this.update({ updateError: true, savingHours: false });
		}

		await this.update({ savingHours: false });

		await this.getSchedulesData();
	}

	getPermissions = () => {
		let { gmbConnected } = this.state;
		let { type } = this.props;

		let user = UserService.get();

		let allowUpdate = false;

		if (type === SCHEDULE.types.regularBusinessHours) {
			allowUpdate = !gmbConnected && user.GroupPermission.update_business_hours;
		} else if (type === SCHEDULE.types.messagingHours) {
			allowUpdate = user.GroupPermission.update_business_hours;
		}

		return { allowUpdate };
	};

	render() {
		let { type, t } = this.props;
		const { schedules, gmbConnected } = this.state;
		const { fetchError, hoursLoading, savingHours, updateError, timeInvalid } = this.state;

		let { allowUpdate } = this.getPermissions();

		if (hoursLoading || savingHours) {
			return (
				<div className="Common__spinnerdiv--center">
					<Spinners type="tail-fade" loading={true} size="120px" />
				</div>
			);
		}

		if (fetchError) {
			return (
				<div className="schedule-hours__container">
					<div>
						<h2>
							{t("Sorry, no data was found")}{" "}
							<span role="img" aria-label="sad-face">
								😞
							</span>
						</h2>
						<button className="mb-button" onClick={() => this.props.history.push("/settings")}>
							{t("Go Back")}
						</button>
					</div>
				</div>
			);
		}

		let descriptionText = "";
		if (type === SCHEDULE.types.regularBusinessHours) {
			descriptionText = gmbConnected
				? t("Business hours will be pulled from your Google My Business settings.")
				: t("Business hours will be used to dictate after hours responses and lead management.");
		} else if (type === SCHEDULE.types.messagingHours) {
			descriptionText = t("Messaging hours will be used to dictate when auto responses are sent (excluding after hours responses).");
		}

		return (
			<div className="schedule-hours__container">
				<div className="schedule-hours__box schedule-hours__table--responsive">
					<div className="business-hours__description">{descriptionText}</div>
					<table className="table schedule-hours__table">
						<thead>
							<tr>
								<th className="schedule-hours__table--borderless">{t("Day")}</th>
								<th className="schedule-hours__table--borderless">{t("Status")}</th>
								<th className="schedule-hours__table--borderless" />
								<th className="schedule-hours__table--borderless ">{t("Open & Close Times")}</th>
							</tr>
						</thead>
						<tbody>
							{schedules.map(aSchedule => {
								let day = aSchedule.day; // ex: Wednesday
								let closed = aSchedule.closed; // 0 or 1
								let timingsArray = aSchedule.timingsArray; // [ { open: "00:00", close: "02:00" }, ... ]

								return (
									<tr key={day}>
										<td className="schedule-hours__table--borderless schedule-hours__table--tdPadding">
											<span>{day}</span>
										</td>
										<td className="schedule-hours__table--borderless schedule-hours__table--tdPadding">{closed ? t("Closed") : t("Open")}</td>
										<td className="schedule-hours__table--borderless">
											<ReactSwitch
												id={"schedule-open-switch-" + day}
												checked={!closed}
												uncheckedIcon={false}
												checkedIcon={false}
												onColor="#4A90E2"
												onChange={checked => this.handleClosedOnChange(day, checked)}
												disabled={!allowUpdate}
											/>
										</td>
										<td className="schedule-hours__table--borderless ">
											<>
												{timingsArray.map((aOpeningHoursPair, timingIndex) => {
													// A opening and close time for this day
													let openTime = aOpeningHoursPair.open;
													let closeTime = aOpeningHoursPair.close;

													return (
														<div key={`schedule-timing-row-${timingIndex}`} className="schedule-hours__table__timingRow">
															<div className="schedule-hours__table__timingRow__timing">
																<TimeDropdown
																	id={`schedule-open-time-${day}-${timingIndex}`}
																	isDisabled={closed || !allowUpdate}
																	handleChange={newValue => this.handleDropdownSelect(day, timingIndex, "open", newValue)}
																	value={openTime}
																/>
																<span className="text-danger">{this.state[day + timingIndex + "OpenError"]}</span>
															</div>
															<div className="schedule-hours__table__timingRow__divider">{t("To")}</div>
															<div className="schedule-hours__table__timingRow__timing">
																<TimeDropdown
																	id={`schedule-close-time-${day}-${timingIndex}`}
																	isDisabled={closed || !allowUpdate}
																	handleChange={newValue => this.handleDropdownSelect(day, timingIndex, "close", newValue)}
																	value={closeTime}
																	allow24thHour={true}
																/>
																<span className="text-danger">{this.state[day + timingIndex + "CloseError"]}</span>
															</div>
															{allowUpdate && timingIndex !== 0 && (
																<div className="schedule-hours__table__timingRow__delete">
																	<Icon.X onClick={() => this.removeHoursRow(day, timingIndex)} />
																</div>
															)}
														</div>
													);
												})}
												{allowUpdate && (
													<div className="schedule-hours__table__add-hours" onClick={() => this.addHoursRow(day)}>
														<Icon.PlusCircle size="16" />
													</div>
												)}
											</>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					{allowUpdate && (
						<div className="schedule-hours__save">
							<button id="save" className="mb-button" onClick={() => this.handleScheduleOnSave()}>
								{t("Save")}
							</button>
						</div>
					)}
				</div>
				<Alert
					type="error"
					show={updateError}
					title={t("Error updating business hours")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ updateError: false });
					}}
				>
					<div>{t("Please verify the data and try again or contact us directly at support@demandhub.co.")}</div>
				</Alert>
				<Alert
					type="error"
					show={timeInvalid}
					title={t("Invalid Time Entered")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ timeInvalid: false });
					}}
				>
					<div>{t("Please verify the data entered and try again or contact us directly at support@demandhub.co.")}</div>
				</Alert>
			</div>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(ScheduleHours)));
