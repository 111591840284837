import React, { Component } from "react";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import ContentLoader from "react-content-loader";

import { STATE, CUSTOMER_FILTER, CUSTOMER_LIST_LIMIT, MEDIUM, CONVERSATION_FILTERS } from "../../../constants/Messenger";
import { GA_CATEGORIES, GA_ACTIONS } from "../../../constants/GAConstants";

import GAService from "../../../services/GAService";
import MessagesService from "../../../services/MessagesService";
import UserService from "../../../services/UserService";
import NotificationService from "../../../services/NotificationService";
import ToastService from "../../../services/ToastService";
import ContactService from "../../../services/ContactService";

import Conversation from "./Conversation/Conversation";
import SearchResults from "./SearchResults/SearchResults";
import NewConversationModal from "./NewConversationModal/NewConversationModal";

import AssignedUser from "../Thread/AssignedUser/AssignedUser";
import FilterConversations from "../Thread/FilterConversations/FilterConversations";

import "./list.css";
class List extends Component {
	constructor(props) {
		super(props);

		let { locationId, activeContactId } = this.props;

		this.state = {
			loading: false,
			locationId: locationId,
			activeContactId: activeContactId,
			conversations: [],
			filter: CUSTOMER_FILTER.all,
			inboxId: null,

			currentStatus: STATE.open,
			searchVisible: false,
			searchInput: "",

			newConversationModalVisible: false,

			show: true,
			loadingArray: Array.apply(null, Array(15)),

			limit: CUSTOMER_LIST_LIMIT,
			offset: 0,
			showLoadMore: false,

			filterUserIds: [],
			assignedUserListVisible: false,

			conversationFilters: []
		};

		this.searchInput = null;
		this.conversationComponent = null;
		this.relativeTimeInterval = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		this.resetComponent();

		NotificationService.subscribeOnce("contactUpdated", "list_componentDidMount", contact => {
			this.updateContact(contact);
		});
	}

	componentDidUpdate(prevProps) {
		let { locationId, activeContactId, show } = this.props;

		let locationChange = prevProps.locationId !== locationId;
		let showChange = prevProps.show !== show;
		let contactChange = prevProps.activeContactId !== activeContactId;

		let resetList = locationChange || showChange || contactChange;
		if (resetList) {
			this.resetComponent(resetList);
		}
	}

	async resetComponent(resetList) {
		let { locationId, activeContactId, show } = this.props;

		await this.update({
			locationId: locationId,
			activeContactId: activeContactId,
			show: show,
			conversations: [],
			inboxId: null
		});

		// Fetch convos
		await this.fetchConversations(true, resetList);
		this.resetRelativeTimeMonitor();

		/* Todo: We have a problem. DH-2381.
		When we first load messenger beta, we should auto open the first thing in the list via onConvoSelect. However this causes
		the reactor to open. Which means we have two reactors open.
		
		// If we are showing the list component, fetch conversations and auto select the first
		if (show) {
			// Fetch convos
			await this.fetchConversations(true, resetList);
			this.resetRelativeTimeMonitor();

			// By default we will select the first conversation in the list component
			let { conversations } = this.state;
			let conversation = conversations[0];

			// If we want to open to a specific conversation
			if (activeContactId) {
				conversation = conversations.find(convo => {
					return parseInt(convo.contact_id) === parseInt(activeContactId);
				});
			}

			await this.onConvoSelect(conversation, true);
		}
		*/
	}

	async triggerSearch(searchTerm) {
		await this.onSearchToggle();
		await this.update({
			searchInput: searchTerm
		});
	}

	async updateContact(contact) {
		let { conversations } = this.state;

		conversations = conversations.slice();

		// Go through each conversation currently
		for (let i = 0; i < conversations.length; i++) {
			// Get the current conversation in our list
			let conversation = conversations[i];

			// If we've found the conversation related to our contact that's being updated
			if (conversation.contact_id === contact.id) {
				// If the contact associated with this conversation is blocked or deleted
				if (contact.is_blocked || contact.status === "deleted") {
					// Remove the conversation from our list
					conversations.splice(i, 1);
				} else {
					// Update the conversation row associated with the contact state change
					conversations[i].name = contact.name;
					conversations[i].assigned_user_id = contact.assigned_user_id;
					conversations[i].assigned_user = contact.assigned_user;
					conversations[i].medium = contact.preferred_medium;
					conversations[i].last_message_content = contact.last_message_content;
					conversations[i].last_message_direction = contact.last_message_direction;
					conversations[i].last_message_user_id = contact.last_message_user_id;
					conversations[i].last_message_user_name = contact.last_message_user_name;

					// XXX -- Gunda This is a fake attribute of contact we've added
					// So that the list component will update it's unread count
					if (contact.unread_updated) {
						conversations[i].unread = "" + contact.unread_updated;
					}
				}
				break;
			}
		}

		await this.update({ conversations });
	}

	onMessageUnreads = async (contactId, unreadCount) => {
		let contact = await ContactService.getContact(contactId);
		contact.unread_updated = unreadCount; // xxx gunda hack
		await this.updateContact(contact);
	};

	async setFilter(filter, inboxId) {
		let newState = {
			filter,
			show: true,
			inboxId: null
		};

		if (inboxId) {
			newState.inboxId = inboxId;
		}

		await this.update(newState);
	}

	async fetchConversations(showLoading = true, clearList = false) {
		let { locationId, filter, currentStatus, inboxId, limit, offset, conversations, filterUserIds, conversationFilters } = this.state;

		if (showLoading) {
			await this.update({
				loading: true
			});
		}

		let newConversations = [];
		// By default, assume they can only view their own messages
		let fetchFilter = CUSTOMER_FILTER.assigned_to_me;
		if (
			this.canViewAllConversations() ||
			filter === CUSTOMER_FILTER.assigned_to_me ||
			(this.canViewUnassignedConversations() && filter === CUSTOMER_FILTER.unassigned)
		) {
			fetchFilter = filter;
		} else if (this.canViewConversationsAssignedToOthers() && this.canViewUnassignedConversations()) {
			// If the user does not have permissions to view their own messages
			fetchFilter = CUSTOMER_FILTER.assigned_to_others_and_unassigned;
		} else if (this.canViewConversationsAssignedToOthers()) {
			// For now we assume they have permissions to view their own messages
			fetchFilter = CUSTOMER_FILTER.assigned_to_me_and_others;
		} else if (this.canViewUnassignedConversations()) {
			// For now we assume they have permissions to view their own messages
			fetchFilter = CUSTOMER_FILTER.assigned_to_me_and_unassigned;
		}

		newConversations = await MessagesService.fetchConversations({
			locationId,
			status: currentStatus,
			filter: fetchFilter,
			inboxId,
			filterUserIds,
			limit,
			offset,
			conversationFilters
		});

		if (clearList) {
			conversations = newConversations;
		} else {
			conversations = conversations.concat(newConversations);
		}

		await this.update({
			filter,
			conversations,
			loading: false,
			showLoadMore: conversations.length >= offset + limit
		});
	}

	canViewAllConversations() {
		let user = UserService.get();
		return (
			user.GroupPermission.view_customer_messages && user.GroupPermission.view_messages_assigned_to_others && user.GroupPermission.view_unassigned_messages
		);
	}

	canViewUnassignedConversations() {
		let user = UserService.get();
		return user.GroupPermission.view_unassigned_messages;
	}

	canViewConversationsAssignedToOthers() {
		let user = UserService.get();
		return user.GroupPermission.view_messages_assigned_to_others;
	}

	onChangeFilter = async ({ status, contactId = null, didClose = false }) => {
		let previousConvos = JSON.parse(JSON.stringify(this.state.conversations));

		await this.update({
			currentStatus: status,
			offset: 0,
			limit: CUSTOMER_LIST_LIMIT,
			conversations: []
		});
		await this.fetchConversations();

		let { conversations } = this.state;

		// Choose the first conversation by default
		let conversation = conversations[0];

		if (didClose) {
			let conversationIndex = previousConvos.findIndex(convo => {
				return parseInt(convo.contact_id) === parseInt(contactId);
			});

			// Get the next conversation
			if (conversationIndex > 0) {
				// Note this will be +1 already since the convo was removed
				conversation = conversations[conversationIndex];
			}
		} else if (contactId) {
			conversation = conversations.find(convo => {
				return parseInt(convo.contact_id) === parseInt(contactId);
			});
		}

		await this.onConvoSelect(conversation, false);

		if (status === STATE.open) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.list,
				action: GA_ACTIONS.messenger.list.switchedToOpenFilter,
				label: GA_ACTIONS.messenger.list.switchedToOpenFilter
			});
		} else {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.list,
				action: GA_ACTIONS.messenger.list.switchedToCloseFilter,
				label: GA_ACTIONS.messenger.list.switchedToCloseFilter
			});
		}
	};

	onConvoListSelect = async convo => {
		await this.onConvoSelect(convo);

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.list,
			action: GA_ACTIONS.messenger.list.clickContact,
			label: GA_ACTIONS.messenger.list.clickContact
		});
	};

	onConvoSelect = async (convo, scroll = false) => {
		if (convo) {
			await this.update({
				activeContactId: convo.contact_id
			});

			if (scroll) {
				this.scrollToConversation();
			}
		}

		if (this.props.onConvoSelect) {
			this.props.onConvoSelect(convo);
		}
	};

	async onStatusChange(contactId, status) {
		let { searchInput } = this.state;

		let didClose = status === STATE.closed;

		await this.update({ searchInput: "" });
		await this.update({ searchInput }); // this will update the search results in the conversation/message list

		// Force the list component to show open conversations
		await this.onChangeFilter({ status: STATE.open, contactId: contactId, didClose });
	}

	getCurrentStatusClass(status) {
		let { currentStatus } = this.state;
		return currentStatus === status ? " mb-list-option--active" : "";
	}

	onSearchToggle = async () => {
		let { searchVisible } = this.state;

		let showSearch = !searchVisible;

		await this.update({
			searchVisible: showSearch,
			searchInput: ""
		});

		if (this.searchInput) {
			this.searchInput.focus();
		}

		if (showSearch) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.list,
				action: GA_ACTIONS.messenger.list.openedSearchContact,
				label: GA_ACTIONS.messenger.list.openedSearchContact
			});
		}
	};

	onSearchInput = async event => {
		let searchInput = event.target.value;

		await this.update({
			searchInput: searchInput
		});
	};

	onNewConversationModal = async () => {
		let user = UserService.get();
		if (!user.GroupPermission.create_messages) {
			return;
		}
		let { newConversationModalVisible } = this.state;

		await this.update({
			newConversationModalVisible: !newConversationModalVisible
		});

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.list,
			action: GA_ACTIONS.messenger.list.openedCreateNewContactModal,
			label: GA_ACTIONS.messenger.list.openedCreateNewContactModal
		});
	};

	onConversationCreated = async (conversation, createMode) => {
		if (createMode) {
			await this.onChangeFilter({ status: conversation.Contact.status, contactId: conversation.contact_id });
		}

		await this.onSearchToggle();
		await this.update({
			searchInput: conversation.Contact.preferred_medium === MEDIUM.sms.key ? conversation.Contact.phone : conversation.Contact.email
		});

		let convo = {
			name: conversation.Contact.name,
			assigned_user_id: conversation.Contact.assigned_user_id,
			contact_id: conversation.contact_id,
			email: conversation.Contact.email,
			medium: conversation.Contact.preferred_medium,
			phone: conversation.Contact.phone,
			status: conversation.Contact.status,
			receive_transactional_sms: conversation.Contact.receive_transactional_sms,
			receive_transactional_emails: conversation.Contact.receive_transactional_emails,
			receive_feedback_sms: conversation.Contact.receive_feedback_sms,
			receive_feedback_emails: conversation.Contact.receive_feedback_emails
		};

		await this.onConvoSelect(convo);

		await this.update({
			newConversationModalVisible: false
		});

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.list,
			action: GA_ACTIONS.messenger.list.createdCreateNewContactModal,
			label: GA_ACTIONS.messenger.list.createdCreateNewContactModal
		});
	};

	onConversationClosed = async () => {
		await this.update({
			newConversationModalVisible: false
		});
	};

	onNewMessage = async (message, unread = 0) => {
		let { conversations, currentStatus, inboxId, filter, activeContactId } = this.state;
		let user = UserService.get();

		// If we are currently in the owner inboxes
		if (inboxId === null) {
			// If we are in the unassigned inbox, but the message was assigned to someone, remove it from the list if possible
			if (filter === CUSTOMER_FILTER.unassigned && message.Contact.assigned_user_id !== 0) {
				conversations = MessagesService.removeFromConversationList(conversations, message);

				await this.update({ conversations: conversations });

				return;
			}
			// If we are in the assigned to me inbox, but the user is not assigned to us, remove it from the list if possible
			else if (filter === CUSTOMER_FILTER.assigned_to_me && message.Contact.assigned_user_id !== user.id) {
				conversations = MessagesService.removeFromConversationList(conversations, message);

				await this.update({ conversations: conversations });

				return;
			}
		}
		// If we are currently in a smart inbox, but not the inbox the message is destined for, or if it's assigned inbox changed, remove it from the list if possible
		else if (message.Contact.inbox_id !== inboxId) {
			conversations = MessagesService.removeFromConversationList(conversations, message);

			await this.update({ conversations: conversations });

			return;
		}

		// If we are in the closed conversations filter and a new message comes in, that conversation is now open
		// Meaning we want to remove it from the closed section and move it to the open
		// We only want to do this for an incoming message. For an outbound, the convo should stay closed
		if (currentStatus === STATE.closed && message.direction === "in") {
			conversations = MessagesService.removeFromConversationList(conversations, message);

			await this.update({ conversations: conversations });

			// If we had the conversation open, its now in the open section so we should go to it there
			if (activeContactId === message.contact_id) {
				this.onChangeFilter({ status: STATE.open, contactId: message.contact_id });

				// Display a toast to let the user know they've been switched to open section
				ToastService.info("This conversation has been opened!");
			}
			return;
		}

		// Check if this conversation is already in our current list of conversations
		let exists = conversations.find(conversation => {
			return conversation.contact_id === message.contact_id;
		});

		// If we don't have permission to view all messages, and a new message comes in
		// for a conversation we are no longer assigned to, remove it from our list component.
		// Or if the user does not have permission to view unassigned messages then remove it from our list component
		if (
			(!user.GroupPermission.view_messages_assigned_to_others && message.Contact.assigned_user_id !== user.id) ||
			(!user.GroupPermission.view_unassigned_messages && message.Contact.assigned_user_id === 0) ||
			(!user.GroupPermission.view_customer_messages && message.Contact.assigned_user_id === user.id)
		) {
			conversations = MessagesService.removeFromConversationList(conversations, message);

			await this.update({ conversations: conversations });

			return;
		}

		// Only update the list if the recipient Contact has the same open or close status of the current list
		if (currentStatus === message.Contact.status) {
			// If it's a totally new message, append it to the top of our conversations list
			if (!exists) {
				conversations = MessagesService.appendConversation(conversations, message);
			} else {
				// If it already exists in our list, update it with the new message
				conversations = MessagesService.updateConversationList(conversations, message, unread);
			}
		}

		// Sort the conversations list
		conversations = MessagesService.sortConversationList(conversations);

		await this.update({ conversations: conversations });
	};

	onTypingEvent = typingEvent => {
		// TODO: Implement X is Typing in the list view similar to Whatsapp
	};

	onLoadMore = async () => {
		let { limit, offset } = this.state;

		await this.update({
			offset: offset + limit
		});

		this.fetchConversations(false);

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.list,
			action: GA_ACTIONS.messenger.list.clickedLoadMoreContacts,
			label: GA_ACTIONS.messenger.list.clickedLoadMoreContacts
		});
	};

	onAssignedUserSelect = async filterUser => {
		await this.update({
			filterUserIds: filterUser
		});

		await this.fetchConversations(true, true);

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.list,
			action: GA_ACTIONS.messenger.list.selectedUserFilter,
			label: GA_ACTIONS.messenger.list.selectedUserFilter
		});
	};

	onFilterSelected = async filterId => {
		let { conversationFilters } = this.state;

		let activeFilters = conversationFilters.slice();

		// If it's already an active filter, remove it
		const filterIndex = activeFilters.indexOf(filterId);

		// If we are using the all filter, clear the array
		if (filterId === CONVERSATION_FILTERS.all.id) {
			activeFilters = [];
		}
		// If the filter already exists
		else if (filterIndex > -1) {
			activeFilters.splice(filterIndex, 1);
		}
		// New filter
		else {
			activeFilters.push(filterId);
		}

		await this.update({
			conversationFilters: activeFilters
		});

		await this.fetchConversations(true, true);

		GAService.GAEvent({
			category: GA_CATEGORIES.messenger.sections.list,
			action: GA_ACTIONS.messenger.list.selectedFilter,
			label: GA_ACTIONS.messenger.list.selectedFilter
		});
	};

	onAssignedUserShowList = async showList => {
		this.update({
			assignedUserListVisible: showList
		});

		if (showList) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.list,
				action: GA_ACTIONS.messenger.list.openedUserFilter,
				label: GA_ACTIONS.messenger.list.openedUserFilter
			});
		}
	};

	onFilterShowList = async showList => {
		if (showList) {
			GAService.GAEvent({
				category: GA_CATEGORIES.messenger.sections.list,
				action: GA_ACTIONS.messenger.list.openedFilter,
				label: GA_ACTIONS.messenger.list.openedFilter
			});
		}
	};

	renderNewConversationModal() {
		let { newConversationModalVisible } = this.state;
		return (
			<NewConversationModal
				show={newConversationModalVisible}
				onCreate={this.onConversationCreated}
				onClose={this.onConversationClosed}
				title={"Start a customer conversation ..."}
				actionText={"Start Conversation"}
			/>
		);
	}

	renderSearchResults() {
		let { searchInput, locationId, searchVisible } = this.state;

		if (!searchVisible) {
			return null;
		}

		return <SearchResults searchTerm={searchInput} locationId={locationId} onResultClicked={this.onConvoListSelect} debounce={true} />;
	}

	scrollToConversation() {
		if (this.conversationComponent) {
			// TODO: Kind of ugly, there is supposed to be a method to forward refs easily.
			this.conversationComponent.conversationComponent.scrollIntoView(true);
		}
	}

	createActiveConversationReference = (contactId, ref) => {
		let { state } = this;
		let { activeContactId } = state;

		if (contactId === activeContactId) {
			this.conversationComponent = ref;
		}
	};

	updateConversationsRelativeTime = () => {
		let { conversations } = this.state;
		this.update({
			conversations: conversations
		});
	};

	resetRelativeTimeMonitor = () => {
		clearInterval(this.relativeTimeInterval);
		this.relativeTimeInterval = setInterval(this.updateConversationsRelativeTime, 5000);
	};

	renderClassyLoader() {
		let { loadingArray } = this.state;
		return (
			<ContentLoader speed={1} width={400} height={900} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
				{loadingArray.map((item, index) => {
					return (
						<React.Fragment key={index}>
							<circle cx="30" cy={(index * 90 + 30).toString()} r="20" />
							<rect x="60" y={(index * 90 + 15).toString()} rx="5" ry="5" width="170" height="20" />
							<rect x="60" y={(index * 90 + 55).toString()} rx="5" ry="5" width="325" height="20" />
						</React.Fragment>
					);
				})}
			</ContentLoader>
		);
	}

	renderConversations() {
		let { conversations, searchVisible, activeContactId, loading, showLoadMore } = this.state;

		if (searchVisible) {
			return null;
		}

		if (loading) {
			return this.renderClassyLoader();
		}

		if (conversations.length === 0) {
			return (
				<div className="mb-list-no-conversations">
					<Icon.Coffee size="48" />
					<div className="mb-list-no-conversations-label">{`Great work!`}</div>
					<div className="mb-list-no-conversations-label">{`You have closed all of your conversations!`}</div>
				</div>
			);
		}

		return (
			<div className="mb-list-conversations mb-list-tour">
				{conversations.map(convo => {
					return (
						<Conversation
							key={convo.contact_id}
							ref={ref => this.createActiveConversationReference(convo.contact_id, ref)}
							convo={convo}
							onSelect={this.onConvoListSelect}
							activeContactId={activeContactId}
						/>
					);
				})}
				{showLoadMore && (
					<div className="mb-list-load-more">
						<div className="mb-button" onClick={this.onLoadMore}>
							Load More
						</div>
					</div>
				)}
			</div>
		);
	}

	render() {
		let { searchVisible, searchInput, show, filterUserIds, locationId, assignedUserListVisible, filter, conversationFilters } = this.state;
		let user = UserService.get();

		let keepOpenAdditionalOptions = conversationFilters.length > 0 || assignedUserListVisible || (filterUserIds && filterUserIds.length > 0);

		return (
			<div className={`mb-list ${show ? "" : "mb-list--hide"}`}>
				<div className="mb-list-header-container mb-list-header-container-tour">
					{!searchVisible && (
						<div className="mb-list-header mb-tour-4">
							<div className={`mb-list-option ${this.getCurrentStatusClass(STATE.open)}`} onClick={() => this.onChangeFilter({ status: STATE.open })}>
								Open
							</div>
							<div className={`mb-list-option ${this.getCurrentStatusClass(STATE.closed)}`} onClick={() => this.onChangeFilter({ status: STATE.closed })}>
								Closed
							</div>
							<div className="mb-list-controls">
								{filter === CUSTOMER_FILTER.all && (
									<div className={`mb-list-header__filter ${keepOpenAdditionalOptions ? "mb-list-header__filter--force-width" : ""}`}>
										<AssignedUser
											includeAllFilter={true}
											locationId={locationId}
											assignedUserId={filterUserIds}
											onSelect={this.onAssignedUserSelect}
											onShowList={this.onAssignedUserShowList}
											showLabel={false}
											multiSelect={true}
										/>
									</div>
								)}
								{filter === CUSTOMER_FILTER.all && (
									<div className={`mb-list-header__filter ${keepOpenAdditionalOptions ? "mb-list-header__filter--force-width" : ""}`}>
										<FilterConversations activeFilters={conversationFilters} onFilterSelected={this.onFilterSelected} onShowList={this.onFilterShowList} />
									</div>
								)}
								<div data-tip data-for="search-tooltip" className={`mb-list-icon mb-tour-5`} onClick={this.onSearchToggle}>
									<Icon.Search size="22" />
								</div>
								<div
									id="mb-create-new-conversation"
									data-tip
									data-for="new-convo-tooltip"
									className={`mb-list-icon mb-list-icon--right-most mb-tour-6 ${user.GroupPermission.create_messages ? "" : "mb-list-icon--disabled"}`}
									onClick={this.onNewConversationModal}
								>
									<Icon.Edit3 size="22" />
								</div>
								<ReactTooltip id="new-convo-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="bottom">
									New
								</ReactTooltip>
								<ReactTooltip id="search-tooltip" type="info" className="mb-react-tooltip" arrowColor="#333" effect="solid" place="bottom">
									Search
								</ReactTooltip>
							</div>
						</div>
					)}
					{searchVisible && (
						<div className="mb-list-search">
							<input
								ref={ref => (this.searchInput = ref)}
								className="mb-list-search-input"
								placeholder="Search ..."
								value={searchInput}
								onChange={this.onSearchInput}
							/>
							<div className="mb-list-icon" onClick={this.onSearchToggle}>
								<Icon.XCircle size="22" />
							</div>
						</div>
					)}
				</div>
				{this.renderConversations()}
				{this.renderSearchResults()}
				{this.renderNewConversationModal()}
			</div>
		);
	}
}

export default List;
