import React from "react";

import "../../styles/css/components/commons/menu-option.css";

const MenuOption = ({ icon, title, onSelect }) => {
	let DIcon = icon;
	return (
		<div className="menu-option" onClick={onSelect}>
			<div className="menu-option__icon">
				<DIcon size={18} />
			</div>
			<div className="menu-option__title">{title}</div>
		</div>
	);
};

export default MenuOption;
