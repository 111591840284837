// Libraries
import React, { Component } from "react";
import * as Icon from "react-feather";
import { Calendar as FCalendar } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import iCalPlugin from "@fullcalendar/icalendar";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import rrulePlugin from "@fullcalendar/rrule";

// Services
import UserService from "../../services/UserService";
import CalendarService from "../../services/CalendarService";
import GAService from "../../services/GAService";
import NotificationService from "../../services/NotificationService";
import ToastService from "../../services/ToastService";
import SupportChatService from "../../services/SupportChatService";

// Components
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import Action from "../../components/common/Action";
import CalendarEventModal from "./CalendarEventModal";
import CalendarFiltersSideModal from "./CalendarFiltersSideModal";
import EditBookingRequestModal from "../../components/common/EditBookingRequestModal";
import LandingPage from "../LandingPage/LandingPage";

// Constants
import { CALENDAR_EVENT_TYPES } from "../../constants/Calendar";

// Styles
import "./calendar.css";

class Calendar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showCalendarFilterSideModal: false,
			showCalendarEventModal: false,
			initialEvent: {
				startAt: null,
				endAt: null
			},

			currentCalendarEventId: null,
			calendarEvents: [],

			// Filter Variables
			filterCalendarIds: [],
			filteredContactIds: [],
			filterUserIds: [],
			filterTypes: Object.values(CALENDAR_EVENT_TYPES),

			// Filter Date Range
			startAt: null,
			endAt: null,

			selectedBookingRequestId: null
		};

		this.contactSelector = null;
		this.calendarRef = React.createRef();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount = async () => {
		GAService.GAPageView({ page: this.props.location.pathname });

		// FullCalendar seems to listen to window resizes fine, it's only when the parent resizes, it doesn't seem to know
		// That's why we tell FullCalendar to re-render by triggering a window resize event
		NotificationService.subscribe("onNavCollapsed", collapsed => {
			setTimeout(() => {
				// XXX - Super ghetto, but the only way since refetchEvents doesn't trigger the resize
				window.dispatchEvent(new Event("resize"));
			}, 250);
		});
	};

	getFullCalendarAPI = () => {
		return this.calendarRef.current.getApi();
	};

	onToggleCalendarFiltersModal = async () => {
		let { showCalendarFilterSideModal } = this.state;
		await this.update({
			showCalendarFilterSideModal: !showCalendarFilterSideModal
		});
	};

	onCloseCalendarFilterSideModal = async () => {
		await this.update({
			showCalendarFilterSideModal: false
		});
	};

	onCloseCalendarEventModal = async () => {
		await this.update({
			showCalendarEventModal: false
		});

		this.fetchCalendarEvents();
	};

	onRangeSelect = async date => {
		await this.update({
			currentCalendarEventId: null,
			showCalendarEventModal: true,
			initialEvent: {
				startAt: date.start,
				endAt: date.end
			}
		});
	};

	onVisibleRangeSet = async data => {
		await this.update({
			startAt: data.start,
			endAt: data.end
		});

		await this.fetchCalendarEvents();
	};

	onEventClicked = async eventData => {
		let selectedType = CALENDAR_EVENT_TYPES.calendarEvents.value;

		if (eventData && eventData.event && eventData.event.extendedProps && eventData.event.extendedProps.type) {
			selectedType = eventData.event.extendedProps.type;
		}

		if (selectedType === CALENDAR_EVENT_TYPES.calendarEvents.value) {
			this.onCalendarEventClicked({ eventData });
		} else if (selectedType === CALENDAR_EVENT_TYPES.bookingRequests.value) {
			this.onBookingRequestClicked({ eventData });
		} else if (selectedType === CALENDAR_EVENT_TYPES.syncedAppointments.value) {
			this.onSyncedAppointmentClicked({ eventData });
		}
	};

	onCalendarEventClicked = async ({ eventData }) => {
		let calendarEventId = null;

		if (eventData && eventData.event && eventData.event.extendedProps && eventData.event.extendedProps.internalId) {
			calendarEventId = eventData.event.extendedProps.internalId;
		}

		await this.update({
			currentCalendarEventId: calendarEventId,
			showCalendarEventModal: true
		});
	};

	onBookingRequestClicked = async ({ eventData }) => {
		let bookingRequestId = null;

		if (eventData && eventData.event && eventData.event.extendedProps && eventData.event.extendedProps.internalId) {
			bookingRequestId = eventData.event.extendedProps.internalId;
		}

		await this.update({
			selectedBookingRequestId: bookingRequestId,
			showBookingRequestModal: true
		});
	};

	onSyncedAppointmentClicked = async ({ eventData }) => {
		// TODO - DH-3604 Add a modal to show Synced Appointments
	};

	onFiltersChanged = async filters => {
		let { calendars, contacts, users, types } = filters;

		let filterCalendarIds = [];
		let filteredContactIds = [];
		let filteredUserIds = [];

		if (calendars.length > 0) {
			filterCalendarIds = calendars.map(calendarItem => {
				return calendarItem.value;
			});
		}

		if (contacts.length > 0) {
			filteredContactIds = contacts.map(contact => {
				return contact.value;
			});
		}

		if (users.length > 0) {
			filteredUserIds = users.map(user => {
				return user.value;
			});
		}

		await this.update({
			showCalendarFilterSideModal: false,

			filterCalendarIds,
			filteredContactIds,
			filteredUserIds,
			filterTypes: types
		});

		await this.fetchCalendarEvents();
	};

	onEventTimeChange = async ({ event: newEvent, oldEvent }) => {
		let { start, end } = newEvent;

		let calendarEventId = null;

		if (newEvent && newEvent.extendedProps && newEvent.extendedProps.internalId) {
			calendarEventId = newEvent.extendedProps.internalId;
		}

		let { error } = await CalendarService.updateCalendarEvent({
			body: {
				startAt: start,
				endAt: end
			},
			params: {
				calendarEventId
			}
		});

		if (error) {
			ToastService.error("An error occurred trying to update event.");
		} else {
			ToastService.info("Event updated!");
		}
	};

	onBookingWidgetModalClose = async () => {
		await this.update({
			selectedBookingRequestId: null,
			showBookingRequestModal: false
		});
	};

	fetchCalendarEvents = async () => {
		let { filteredCalendarIds, filteredContactIds, filteredUserIds, startAt, endAt, filterTypes } = this.state;

		let location = UserService.getActiveLocation();

		let calendarEvents = [];

		for (let type of filterTypes) {
			let { data: items, error } = await CalendarService.fetchCalendarEvents({
				query: {
					locationId: location.id,
					userIds: filteredUserIds,
					contactIds: filteredContactIds,
					calendarIds: filteredCalendarIds,
					startAt,
					endAt,
					type: type.value
				}
			});

			// If this fetch fails, just skip to the next type
			if (error) {
				continue;
			}

			if (type.value === CALENDAR_EVENT_TYPES.calendarEvents.value) {
				items = CalendarService.parseEventsForFullCalendar({ events: items });
			} else if (type.value === CALENDAR_EVENT_TYPES.bookingRequests.value) {
				items = CalendarService.parseBookingRequestsForFullCalendar({ bookingRequests: items });
			} else if (type.value === CALENDAR_EVENT_TYPES.syncedAppointments.value) {
				items = CalendarService.parseSyncedAppointmentsForFullCalendar({ syncedAppointments: items });
			}

			calendarEvents = [...calendarEvents, ...items];
		}

		await this.update({
			calendarEvents
		});

		// TODO - DH-3613 - POC is done for this, we just need a UI to support managing Calendars, and the Calendar object should support iCal links
		// await this.fetchAdditionalEvents();
	};

	async fetchAdditionalEvents() {
		let { calendarEvents } = this.state;

		// We can fetch Calendars and process their links
		let newEvents = await CalendarService.getICalendarData({ url: "https://calendar.google.com/calendar/ical/sameid%40demandhub.co/public/basic.ics" });

		calendarEvents = [...calendarEvents, ...newEvents];

		await this.update({
			calendarEvents
		});
	}

	renderCalendar() {
		let { calendarEvents } = this.state;

		return (
			<div className="calendar">
				<FullCalendar
					ref={this.calendarRef}
					datesSet={this.onVisibleRangeSet}
					plugins={[interactionPlugin, timeGridPlugin, dayGridPlugin, listPlugin, rrulePlugin, iCalPlugin]}
					initialView="timeGridWeek"
					headerToolbar={{
						left: "prev,next",
						center: "title",
						end: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
					}}
					buttonText={{
						today: "Today",
						month: "Month",
						week: "Week",
						day: "Day",
						list: "List"
					}}
					eventSources={[calendarEvents]}
					selectable
					selectMirror
					select={this.onRangeSelect}
					nowIndicator
					eventClick={this.onEventClicked}
					eventDrop={this.onEventTimeChange}
					eventResize={this.onEventTimeChange}
					handleWindowResize
				/>
			</div>
		);
	}

	onGetStarted = () => {
		SupportChatService.showNewMessage("Hi, I would like to use DemandHub Calendar. Would you be able to help?");
	};

	renderLandingPage() {
		return (
			<Page>
				<LandingPage
					title="DemandHub Calendar"
					text="Keep track of appointments, booking requests, and events all from DemandHub."
					buttonText="Get Started"
					onGetStartedClicked={this.onGetStarted}
					beta={true}
					newFeature={false}
				>
					<div className="landing-page__more">
						<div className="landing-page__block">
							<div className="landing-page__block__item">
								<img
									className="landing-page__block__item__img"
									alt="Accept Incoming Booking Requests"
									src="https://cdn.demandhub.co/web-app/assets/calendar-1.svg"
								/>
							</div>
							<div className="landing-page__block__item">
								<div className="landing-page__block__item__text">Manage appointments and events for your business in a simple calendar view. </div>
							</div>
						</div>
						<div className="landing-page__block">
							<div className="landing-page__block__item">
								<img className="landing-page__block__item__img" alt="Communicate with customers" src="https://cdn.demandhub.co/web-app/assets/calendar-2.svg" />
							</div>
							<div className="landing-page__block__item">
								<div className="landing-page__block__item__text">Connect your other calendar feeds into DemandHub, to stay up to date on everything.</div>
							</div>
							<div className="landing-page__block__item__text" />
						</div>

						<div className="landing-page__block">
							<div className="landing-page__block__item">
								<img className="landing-page__block__item__img" alt="Stay Organized" src="https://cdn.demandhub.co/img/misc/Voicemail-revenue.png" />
							</div>
							<div className="landing-page__block__item">
								<div className="landing-page__block__item__text">Improve customer communication and satisfaction.</div>
							</div>
						</div>
					</div>
				</LandingPage>
			</Page>
		);
	}

	render() {
		let {
			showCalendarFilterSideModal,
			initialEvent,

			currentCalendarEventId,
			showCalendarEventModal,

			selectedBookingRequestId,
			showBookingRequestModal
		} = this.state;

		let isCalendarEnabled = CalendarService.isCalendarEnabled();

		if (!isCalendarEnabled) {
			return this.renderLandingPage();
		}

		return (
			<Page>
				<Header title="Calendar" />

				<div className="calendar__actions">
					<Action id="filterCalendarEvents" label="Filter Calendar Events" icon={Icon.Sliders} onClick={this.onToggleCalendarFiltersModal} />
				</div>

				{this.renderCalendar()}

				<CalendarEventModal
					title={"New Event"}
					calendarEventId={currentCalendarEventId}
					show={showCalendarEventModal}
					initialEvent={initialEvent}
					onClose={this.onCloseCalendarEventModal}
				/>
				<CalendarFiltersSideModal show={showCalendarFilterSideModal} onHide={this.onCloseCalendarFilterSideModal} onFiltersChanged={this.onFiltersChanged} />
				<EditBookingRequestModal show={showBookingRequestModal} bookingRequestId={selectedBookingRequestId} onClose={this.onBookingWidgetModalClose} />
			</Page>
		);
	}
}

export default Calendar;
