import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";

import LoadingButton from "../../components/common/LoadingButton";
import Alert from "../../components/common/Alert";

import "../../styles/css/scenes/reset-password.css";

class ResetMediumEntry extends Component {
	constructor(props) {
		super(props);
		let { t } = this.props;

		this.state = {
			phoneOrEmail: "",
			phoneText: t(
				"Please enter the mobile phone number associated with your DemandHub account. We will then send you a text message with a 6-digit code to reset your password."
			),
			emailText: t("Please enter the email address associated with your DemandHub account. We will then send you a secure link to reset your password."),
			sendingToken: false,
			tokenSent: false,
			tokenError: false,
			exceededTries: false,
			exceededTriesMessage: t("tries exceeded")
		};
	}

	handlePhoneOrEmailInputChange(e) {
		this.setState({ phoneOrEmail: e.target.value });
	}

	async handleSendToken(e) {
		const { medium } = this.props;
		const { phoneOrEmail, exceededTriesMessage, sendingToken } = this.state;

		if (sendingToken) {
			return;
		}

		this.setState({ sendingToken: true });

		try {
			await UserService.sendPasswordToken({ medium, phoneOrEmail });
			this.setState({ tokenSent: true, tokenError: false, sendingToken: false });
		} catch (error) {
			if (error.response && error.response.data.error === exceededTriesMessage) {
				this.setState({ tokenSent: false, exceededTries: true, sendingToken: false });
			}
			this.setState({ tokenSent: false, tokenError: true, sendingToken: false });
		}
	}
	render() {
		const { medium, t } = this.props;
		const { phoneOrEmail, sendingToken, tokenSent, tokenError, exceededTries } = this.state;

		if (!tokenSent) {
			return (
				<>
					<div className="reset-password Common__card">
						<h2 className="font-bold">{t("Forgot password")}</h2>
						<p className="text-left">{this.state[medium + "Text"]}</p>
						<div className="form-group text-left">
							<label className="text-capitalize">{medium === "phone" ? t("Enter phone") : t("Enter email")}</label>
							<input type={medium} className="form-control Common__input" value={phoneOrEmail} onChange={e => this.handlePhoneOrEmailInputChange(e)} required />
						</div>

						<LoadingButton
							componentClass={`mb-button mb-button--slim ${phoneOrEmail.length <= 0 ? "mb-button--disabled" : ""}`}
							loading={sendingToken}
							onClick={e => this.handleSendToken(e)}
							type="submit"
							disabled={phoneOrEmail.length <= 0}
							size="4px"
						>
							{medium === "phone" ? t("Send Reset Code") : t("Send Email")}
						</LoadingButton>
					</div>
					<Alert
						type="error"
						show={tokenError}
						title={t("Invalid Account")}
						confirm={t("OK")}
						onClose={() => {
							this.setState({ tokenError: false });
						}}
					>
						<div>
							{medium === "phone"
								? t("Sorry, that phone was not associated with a valid account. ")
								: t("Sorry, that email was not associated with a valid account. ")}
							{t("Please try again.")}
						</div>
					</Alert>
					<Alert
						type="error"
						show={exceededTries}
						title={t("Password Reset Error")}
						confirm={t("OK")}
						onClose={() => {
							this.setState({ exceededTries: false });
						}}
					>
						<div>
							{t("You have exceeded the number of password reset attempts allowed in a day. Please try again in 24 hours or contact support@demandhub.co")}
						</div>
					</Alert>
				</>
			);
		}

		return [
			<Alert
				type="success"
				show={tokenSent && medium === "email"}
				title={t("Link Sent Successfully")}
				confirm={t("OK")}
				onClose={() => this.props.history.push("/login")}
			>
				<div>
					{t("The password reset link has been successfully sent to the email address you provided. Please follow the instructions given in the email.")}
				</div>
			</Alert>,
			<div key="phone-token">{medium === "phone" && this.props.history.push("/forgot/password/verify-token/phone")}</div>
		];
	}
}

export default withRouter(withTranslation(null, { withRef: true })(ResetMediumEntry));
