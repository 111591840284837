import React from "react";
import { withRouter } from "react-router-dom";
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import * as Icon from "react-feather";
import _ from "lodash";
import Select from "react-select";
import { Collapse } from "react-bootstrap";

import Page from "../../components/common/Page";
import SearchInput from "../../components/common/SearchInput";

import GAService from "../../services/GAService";
import ToastService from "../../services/ToastService";
import LocationService from "../../services/LocationService";
import CompanyService from "../../services/CompanyService";
import UserService from "../../services/UserService";

import { LOCATIONS_MAP_STATUS_OPTIONS, LOCATION_FEATURES, LOCATIONS_MAP_MIDNIGHT_COMMANDER } from "../../constants/LocationConstants";
import { STATUS } from "../../constants/CommonConstants";

import config from "../../config/app/web-app.config";

import "../../styles/css/components/commons/map.css";

const defaultProps = {
	center: {
		lat: 43.612,
		lng: -79.68
	},
	zoom: 11
};

const GOOGLE_MAPS_API_KEY = config.GOOGLE.GOOGLE_MAPS.KEY;

class LocationsMap extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			count: 0,
			loading: false,

			isMenuExpanded: false,
			isDarkMode: false,
			showMap: true,
			mapOptions: {},

			searchTerm: "",
			companies: [],
			companyOptions: [],
			status: null,
			statusOptions: [],
			vertical: null,
			verticalOptions: [],
			locationFeatures: null,
			locationFeatureOptions: [],

			limit: 2000,

			selectedLocation: null
		};

		this.debouncedFetch = _.debounce(
			async () => {
				await this.fetchLocations();
			},
			1000,
			{
				leading: false,
				trailing: true
			}
		);
	}

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchVerticals();
		this.fetchLocationFeatures();
		this.fetchCompanies();
		this.fetchLocations();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	fetchLocations = async () => {
		let { searchTerm, status, vertical, locationFeatures, companies, limit, loading } = this.state;

		if (!loading) {
			await this.update({ loading: true });
		}

		let statusFilter = [];
		let verticalFilter = null;
		let locationFeaturesFilter = null;
		let companyFilters = null;

		if (status === null) {
			statusFilter = [STATUS.active];
		} else if (status.value === "all") {
			statusFilter = [STATUS.active, STATUS.inactive];
		} else {
			statusFilter = [status.value];
		}
		if (vertical) {
			verticalFilter = vertical.map(v => v.value);
		}
		if (locationFeatures) {
			locationFeaturesFilter = locationFeatures.map(v => v.value);
		}
		if (companies) {
			companyFilters = companies.map(c => c.value);
		}

		let data = await LocationService.fetchAllLocations({
			companyIds: companyFilters,
			searchTerm,
			status: statusFilter,
			vertical: verticalFilter,
			locationFeatures: locationFeaturesFilter,
			limit
		});

		if (!data) {
			ToastService.error("Unable to fetch locations. Please try again.");
			await this.update({ data: [], count: 0, loading: false });
			return;
		}

		if (data.length < 1) {
			ToastService.info("No locations found");
			await this.update({ data: [], count: 0, loading: false });
			return;
		}

		await this.update({ data, count: data.length, loading: false });
	};

	fetchCompanies = async () => {
		let { limit } = this.state;
		let companyOptions = await CompanyService.fetchAllCompanies({ limit });

		if (!companyOptions) {
			ToastService.error("Error fetching companies.");
			return;
		}

		companyOptions = companyOptions.map(company => {
			return { value: company.id, label: company.name };
		});

		await this.update({ companyOptions });
	};

	fetchVerticals = async () => {
		let verticalOptions = await LocationService.getVerticals();

		verticalOptions = Object.values(verticalOptions).map(item => {
			return { value: item.id, label: item.id };
		});

		await this.update({ verticalOptions });
	};

	fetchLocationFeatures = async () => {
		let locationFeatureOptions = Object.values(LOCATION_FEATURES).map(lf => {
			return { value: lf.id, label: lf.name };
		});

		await this.update({ locationFeatureOptions });
	};

	onSearchChange = async value => {
		await this.update({
			searchTerm: value,
			loading: true
		});
		await this.debouncedFetch();
	};

	onChangeSelect = async (state, value) => {
		await this.update({
			[`${state}`]: value,
			loading: true
		});
		await this.debouncedFetch();
	};

	onLocationSelected = location => {
		let selectedLocation = null;
		if (location) {
			try {
				location.meta_data = JSON.parse(location.meta_data);
				selectedLocation = location;
			} catch (error) {
				console.log(error);
				return;
			}
		}

		this.update({ selectedLocation });
	};

	onToggleDarkMode = async () => {
		let { isDarkMode, mapOptions } = this.state;

		await this.update({ showMap: false });

		isDarkMode = !isDarkMode;

		if (isDarkMode) {
			mapOptions.styles = LOCATIONS_MAP_MIDNIGHT_COMMANDER;
		} else {
			delete mapOptions["styles"];
		}

		await this.update({ isDarkMode, showMap: true });
	};

	renderMap = () => {
		let { data, selectedLocation, showMap, mapOptions } = this.state;

		return (
			<div className="dh-map">
				<LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
					{showMap && (
						<GoogleMap mapContainerStyle={{ width: "100%", height: "100%" }} center={defaultProps.center} zoom={defaultProps.zoom} options={mapOptions}>
							{data &&
								data.map(location => (
									<Marker
										key={location.id}
										onClick={() => this.onLocationSelected(location)}
										position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }}
									/>
								))}

							{selectedLocation && (
								<InfoWindow
									onCloseClick={() => this.onLocationSelected(null)}
									position={{ lat: parseFloat(selectedLocation.latitude), lng: parseFloat(selectedLocation.longitude) }}
								>
									<div className="dh-map__info-window">
										<div className="dh-map__info-window__name">{selectedLocation.name}</div>
										<div className="dh-map__info-window__item">Company: {selectedLocation.Company.name}</div>
										<div className="dh-map__info-window__item">Vertical: {selectedLocation.vertical}</div>
										{selectedLocation.website && selectedLocation.website.length > 0 && (
											<div className="dh-map__info-window__item">
												Site:
												<a href={selectedLocation.website}>
													<Icon.Globe size={14} />
												</a>
											</div>
										)}
										{selectedLocation.meta_data && selectedLocation.meta_data.google_place_cid && selectedLocation.meta_data.google_place_cid.length > 0 && (
											<div className="dh-map__info-window__item">
												Google Maps:
												<a href={`https://maps.google.com/?cid=${selectedLocation.meta_data.google_place_cid}`}>
													<Icon.MapPin size={14} />
												</a>
											</div>
										)}
										{selectedLocation.meta_data && selectedLocation.meta_data.google_place_url && selectedLocation.meta_data.google_place_url.length > 0 && (
											<div className="dh-map__info-window__item">
												Google:
												<a href={selectedLocation.meta_data.google_place_url}>
													<Icon.Map size={14} />
												</a>
											</div>
										)}
									</div>
								</InfoWindow>
							)}
						</GoogleMap>
					)}
				</LoadScript>
			</div>
		);
	};

	renderMenu = () => {
		let {
			isMenuExpanded,
			isDarkMode,
			count,
			loading,
			status,
			vertical,
			verticalOptions,
			locationFeatures,
			locationFeatureOptions,
			companies,
			companyOptions
		} = this.state;

		return (
			<div className="dh-locations-map__menu">
				<div className="dh-locations-map__menu__header">
					<div className="dh-locations-map__menu__header__text">Filter Locations</div>
					<div className="dh-locations-map__menu__header__buttons">
						<div className="dh-locations-map__menu__header__buttons__button" onClick={this.onToggleDarkMode}>
							{isDarkMode ? <Icon.Sun size={20} /> : <Icon.Moon size={20} />}
						</div>
						<div className="dh-locations-map__menu__header__buttons__button" onClick={() => this.update({ isMenuExpanded: !isMenuExpanded })}>
							{isMenuExpanded ? <Icon.ChevronUp size={20} /> : <Icon.ChevronDown size={20} />}
						</div>
					</div>
				</div>

				<Collapse in={isMenuExpanded}>
					<div className="dh-locations-map__menu__options">
						<div className="dh-locations-map__menu__options__search">
							<SearchInput placeholder="Search ..." onChange={this.onSearchChange} />
						</div>

						<div className="dh-locations-map__menu__options__filter">
							<div>Vertical</div>
							<Select
								id="vertical"
								name="vertical"
								options={verticalOptions}
								value={vertical}
								className=""
								placeholder="Vertical"
								isMulti={true}
								onChange={vertical => {
									this.onChangeSelect("vertical", vertical);
								}}
							/>
						</div>

						<div className="dh-locations-map__menu__options__filter">
							<div>Company</div>
							<Select
								id="companies"
								name="companies"
								options={companyOptions}
								value={companies}
								className=""
								placeholder="Companies"
								isMulti={true}
								onChange={companies => {
									this.onChangeSelect("companies", companies);
								}}
							/>
						</div>

						<div className="dh-locations-map__menu__options__filter">
							<div>Feature</div>
							<Select
								id="feature"
								name="feature"
								options={locationFeatureOptions}
								value={locationFeatures}
								className=""
								placeholder="Feature"
								isMulti={true}
								onChange={locationFeatures => {
									this.onChangeSelect("locationFeatures", locationFeatures);
								}}
							/>
						</div>

						<div className="dh-locations-map__menu__options__filter">
							<div>Status</div>
							<Select
								id="status"
								name="status"
								options={LOCATIONS_MAP_STATUS_OPTIONS}
								value={status}
								className=""
								placeholder="Status"
								onChange={newStatus => {
									this.onChangeSelect("status", newStatus);
								}}
							/>
						</div>
					</div>
				</Collapse>

				{UserService.isSuperAdminOrCustomerSuccess() && <div className="dh-locations-map__menu__count">{!loading ? `Count: ${count}` : null}</div>}

				<div className="dh-locations-map__menu__count">{loading ? `Loading...` : null}</div>
			</div>
		);
	};

	render() {
		return (
			<Page>
				<div className="dh-locations-map">
					{this.renderMenu()}
					{this.renderMap()}
				</div>
			</Page>
		);
	}
}

export default withRouter(LocationsMap);
