export const BOOKING_REQUESTS_FILTERS = {
	awaiting: {
		id: "awaiting",
		display: "Awaiting",
		order: 1
	},
	confirmed: {
		id: "confirmed",
		display: "Confirmed",
		order: 2
	},
	completed: {
		id: "completed",
		display: "Completed",
		order: 3
	},
	cancelled: {
		id: "cancelled",
		display: "Cancelled",
		order: 4
	},
	deleted: {
		id: "deleted",
		display: "Deleted",
		order: 5
	}
};

export const BOOKING_REQUESTS_COLUMNS = {
	date: {
		id: "date",
		value: "Date",
		sortable: true,
		sortField: "date",
		width: 1
	},
	time: {
		id: "time",
		value: "Time",
		sortable: false,
		sortField: "time",
		width: 1,
		widthMd: 0
	},
	reason: {
		id: "reason",
		value: "Reason",
		sortable: true,
		sortField: "reason",
		width: 1,
		widthMd: 0
	},
	note: {
		id: "note",
		value: "Note",
		sortable: true,
		sortField: "note",
		width: 1
	},
	requestedBy: {
		id: "requestedBy",
		value: "Requested By",
		sortable: false,
		sortField: "requested_by",
		width: 1
	},
	phone: {
		id: "phone",
		value: "Phone",
		sortable: false,
		sortField: "phone",
		width: 1
	},
	email: {
		id: "email",
		value: "Email",
		sortable: false,
		sortField: "email",
		width: 1
	},
	offeredBy: {
		id: "offeredBy",
		value: "Offered By",
		sortable: false,
		sortField: "offered_by",
		width: 1,
		widthMd: 0
	},
	name: {
		id: "name",
		value: "Service",
		sortable: false,
		sortField: "name",
		width: 1,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 1,
		notClickable: true
	}
};

export const BOOKING_REQUESTS_STATUS = {
	awaiting: "awaiting",
	confirmed: "confirmed",
	completed: "completed",
	cancelled: "cancelled",
	deleted: "deleted"
};

export const EDIT_BOOKING_REQUESTS_TABS = {
	details: "details",
	actions: "actions"
};
