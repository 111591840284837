import React from "react";
import { withTranslation } from "react-i18next";

import Checkbox from "./Checkbox";
import Alert from "./Alert";

import { BOOKING_REQUESTS_STATUS } from "../../constants/BookingRequests";

class BookingRequestStatusAlert extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sendNotificationMessage: false
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onClose = async confirm => {
		let { sendNotificationMessage } = this.state;

		// If no changes were made, just tell the parent to close us
		if (this.props.onClose) {
			await this.props.onClose(confirm, sendNotificationMessage);
		}
		await this.update({
			sendNotificationMessage: false
		});
	};

	onConfirm = async () => {
		let { sendNotificationMessage } = this.state;

		await this.onClose(true, sendNotificationMessage);
	};

	renderSwitch = () => {
		let { toStatus, t } = this.props;
		let { sendNotificationMessage } = this.state;

		if (toStatus !== BOOKING_REQUESTS_STATUS.confirmed && toStatus !== BOOKING_REQUESTS_STATUS.cancelled) {
			return null;
		}

		return (
			<div className="modal__field__row">
				<div className="modal__field__row__item">{t("Send a notification to this contact")} </div>
				<div className="modal__field__row__item brsc-modal__checkbox">
					<Checkbox
						id="send_notification_message"
						name="send_notification_message"
						checked={sendNotificationMessage}
						onChange={event => {
							this.update({ sendNotificationMessage: event.target.checked });
						}}
					/>
				</div>
			</div>
		);
	};

	render = () => {
		let { show, toStatus, t } = this.props;

		return (
			<Alert type="warning" show={show} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")} onClose={confirm => this.onClose(confirm)}>
				<div>
					{t("Are you sure you would like this update this booking request?")}
					{this.renderSwitch()}
				</div>
			</Alert>
		);
	};
}

export default withTranslation(null, { withRef: true })(BookingRequestStatusAlert);
