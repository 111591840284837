import Kichiri from "./KichiriService";
import UserService from "./UserService";

const FirewallService = {
	/**
	 * Fetch firewall rules
	 * @param {Integer} locationId
	 * @param {Integer} companyId
	 * @param {Boolean} includeGlobal If we should also fetch global firewall rules
	 *
	 * @returns {Promise} Firewall rules
	 */
	async fetch({ locationId, companyId, includeGlobal }) {
		try {
			let response = await Kichiri.admin.fetchFirewallRules({}, { locationId, companyId, includeGlobal }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch a single firewall rule
	 * @param {Integer} firewallId
	 *
	 * @returns {Promise} Firewall rule
	 */
	async fetchFirewallRule({ firewallId }) {
		try {
			let response = await Kichiri.admin.fetchFirewallRule({ firewallId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create a firewall rule
	 * @param {Integer} locationId
	 * @param {Integer} companyId
	 * @param {String} name
	 * @param {String} description
	 * @param {String} value
	 * @param {String} field
	 * @param {String} matchType
	 *
	 * @returns {Promise} Firewall rule
	 */
	async create({ locationId, companyId, name, description, value, field, matchType }) {
		try {
			let response = await Kichiri.admin.createFirewallRule(
				{ locationId, companyId, name, description, value, field, matchType },
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a firewall rule
	 * @param {Integer} firewallId
	 * @param {Integer} locationId
	 * @param {Integer} companyId
	 * @param {String} name
	 * @param {String} description
	 * @param {String} value
	 * @param {String} field
	 * @param {String} matchType
	 * @param {String} status
	 *
	 * @returns {Promise} Firewall rule
	 */
	async update({ firewallId, locationId, companyId, name, description, value, field, matchType, status }) {
		try {
			let response = await Kichiri.admin.updateFirewallRule(
				{ firewallId, locationId, companyId, name, description, value, field, match_type: matchType, status },
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	}
};

export default FirewallService;
