import UserService from "./UserService";
import Kichiri from "./KichiriService";

export const A2PService = {
	/**
	 * Fetches all a2p brands
	 *
	 * @param {Number} companyId
	 * @returns {Array}
	 */
	async fetchBrands({ companyId }) {
		try {
			const response = await Kichiri.a2p.fetchBrands({}, { companyId }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetches a single brand
	 *
	 * @param {Number} brandId
	 * @returns {Object}
	 */
	async fetchBrand({ brandId }) {
		try {
			const response = await Kichiri.a2p.fetchBrand({ brandId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Creates a brand in demandhub
	 *
	 * @param {Number} companyId
	 * @param {Object} brandData
	 * @returns {Promise}
	 */
	async createBrand({ companyId, brandData }) {
		try {
			const response = await Kichiri.a2p.createBrand({ companyId, brandData }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Updates a brand in demandhub
	 *
	 * @param {Number} brandId
	 * @param {Object} brandData
	 * @returns {Promise}
	 */
	async updateBrand({ brandId, brandData }) {
		try {
			const response = await Kichiri.a2p.updateBrand({ brandId, brandData }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetches all a2p campaigns
	 *
	 * @param {Number} locationId
	 * @returns {Array}
	 */
	async fetchCampaigns({ locationId }) {
		try {
			const response = await Kichiri.a2p.fetchCampaigns({}, { locationId }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Fetches a campaign in demandhub
	 *
	 * @param {Number} campaignId
	 * @returns {Object}
	 */
	async fetchCampaign({ campaignId }) {
		try {
			const response = await Kichiri.a2p.fetchCampaign({ campaignId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Creates a campaign in demandhub
	 *
	 * @param {Number} locationId
	 * @param {Object} campaignData
	 * @returns {Promise}
	 */
	async createCampaign({ locationId, campaignData }) {
		try {
			const response = await Kichiri.a2p.createCampaign({ locationId, campaignData }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Updates a campaign in demandhub
	 *
	 * @param {Number} campaignId
	 * @param {Object} campaignData
	 * @returns {Promise}
	 */
	async updateCampaign({ campaignId, campaignData }) {
		try {
			const response = await Kichiri.a2p.updateCampaign({ campaignId, campaignData }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetch a2p submission in demandhub
	 *
	 * @param {Number} companyId
	 * @returns {Array}
	 */
	async fetchSubmissions({ companyId }) {
		try {
			const response = await Kichiri.a2p.fetchSubmissions({}, { companyId }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Fetch a single a2p submission in demandhub
	 *
	 * @param {Number} submissionId
	 * @returns {Promise}
	 */
	async fetchSubmission({ submissionId }) {
		try {
			const response = await Kichiri.a2p.fetchSubmission({ submissionId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Creates a single a2p submission in demandhub
	 *
	 * @param {Number} locationId
	 * @param {Object} submissionData
	 * @returns {Promise}
	 */
	async createSubmission({ locationId, submissionData }) {
		try {
			const response = await Kichiri.a2p.createSubmission({ locationId, submissionData }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Updates a single a2p submission in demandhub
	 *
	 * @param {Number} submissionId
	 * @param {Object} submissionData
	 * @returns {Promise}
	 */
	async updateSubmission({ submissionId, submissionData }) {
		try {
			const response = await Kichiri.a2p.updateSubmission({ submissionId, submissionData }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Passes information from an a2p submission to the tcr
	 *
	 * @param {Number} submissionId
	 * @returns {Promise}
	 */
	async submitSubmission({ submissionId }) {
		try {
			const response = await Kichiri.a2p.submitSubmission({ submissionId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Fetches webhook events related to a company
	 *
	 * @param {Number} companyId
	 * @returns {Promise}
	 */
	async fetchWebhookEvents({ companyId }) {
		try {
			const response = await Kichiri.a2p.fetchWebhookEvents({}, { companyId }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Fetches the a2p constant from the backend
	 *
	 * @returns {Object}
	 */
	async fetchConfig() {
		try {
			const response = await Kichiri.a2p.fetchConfig({}, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Fetches all a2p numbers associations
	 *
	 * @param {Number} companyId
	 * @returns {Array}
	 */
	async fetchNumberAssociations({ companyId }) {
		try {
			const response = await Kichiri.a2p.fetchNumberAssociations({}, { companyId }, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetches a single number association
	 *
	 * @param {Number} numberAssociationId
	 * @returns {Object}
	 */
	async fetchNumberAssociation({ numberAssociationId }) {
		try {
			const response = await Kichiri.a2p.fetchNumberAssociation({ numberAssociationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Creates a number association in demandhub
	 *
	 * @param {Number} companyId
	 * @param {String} phoneNumber
	 * @param {String} tcrCampaignId
	 * @param {String} cnpID
	 * @returns {Promise}
	 */
	async createNumberAssociation({ companyId, phoneNumber, tcrCampaignId, cnpID }) {
		try {
			const response = await Kichiri.a2p.createNumberAssociation({ companyId, phoneNumber, tcrCampaignId, cnpID }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Updates a number association in demandhub
	 *
	 * @param {Number} numberAssociationId
	 * @param {String} phoneNumber
	 * @param {String} tcrCampaignId
	 * @param {String} cnpID
	 * @returns {Promise}
	 */
	async updateNumberAssociation({ numberAssociationId, phoneNumber, tcrCampaignId, cnpID }) {
		try {
			const response = await Kichiri.a2p.updateNumberAssociation({ numberAssociationId, phoneNumber, tcrCampaignId, cnpID }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Passes information from an number & campaign association to a campaign & number provider
	 *
	 * @param {Number} numberAssociationId
	 * @returns {Promise}
	 */
	async submitNumberAssociation({ numberAssociationId }) {
		try {
			const response = await Kichiri.a2p.submitNumberAssociation({ numberAssociationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Remove a number association for a2p
	 *
	 * @param {Number} numberAssociationId
	 * @returns {Promise}
	 */
	async removeNumberAssociation({ numberAssociationId }) {
		try {
			const response = await Kichiri.a2p.removeNumberAssociation({ numberAssociationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			throw error;
		}
	}
};

export default A2PService;
