export const INBOX_TYPES = {
	general: "general",
	reviews: "reviews",
	leads: "leads",
	sales: "sales",
	service: "service",
	parts: "parts",
	reengagements: "reengagements"
};

export const INBOX_TYPES_OPTIONS = [
	{ label: "General", value: INBOX_TYPES.general },
	{ label: "Reviews", value: INBOX_TYPES.reviews },
	{ label: "Leads", value: INBOX_TYPES.leads },
	{ label: "Sales", value: INBOX_TYPES.sales },
	{ label: "Service", value: INBOX_TYPES.service },
	{ label: "Parts", value: INBOX_TYPES.parts },
	{ label: "Reengagements", value: INBOX_TYPES.reengagements }
];

export const INBOX_RULE_METADATA = {
	user: "userId",
	inbox: "inboxId",
	conversation: "conversationId"
};

export const INBOX_RULE_ATTRIBUTES = {
	delay: "delay",
	category: "category",
	action: "action",
	isRepeated: "is_repeated",
	metadata: "metadata"
};

export const INBOX_USERS_HEADERS = {
	name: {
		id: "name",
		value: "Name",
		sortable: false,
		width: 3
	},
	email: {
		id: "email",
		value: "Email",
		sortable: false,
		width: 3
	},
	available: {
		id: "available",
		value: "View Only",
		sortable: false,
		width: 2
	},
	actions: {
		id: "remove",
		value: "",
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const INBOX_TABS = {
	details: "details",
	users: "users",
	rules: "rules"
};
