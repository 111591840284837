import React, { Component } from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import { withTranslation } from "react-i18next";

import ManageUserPreferences from "../../components/common/ManageUserPreferences";
import Alert from "../../components/common/Alert";
import Input from "../../components/common/Input";
import DHSelect from "../../components/common/DHSelect";
import Spinners from "../../components/common/Spinners";
import Toggle from "../../components/common/Toggle";
import Signature from "../../components/common/Signature";
import ProfilePicture from "../../components/common/ProfilePicture";
import PasswordInput from "../../components/common/PasswordInput";
import Checkbox from "../../components/common/Checkbox";

import UserService from "../../services/UserService";
import Kichiri from "../../services/KichiriService";
import GAService from "../../services/GAService";
import GroupPermissionService from "../../services/GroupPermissionService";
import UtilityService from "../../services/UtilityService";
import TeamChatService from "../../services/TeamChatService";

import { CODES_TO_LABEL, LANGUAGES } from "../../constants/LanguageConstants";
import { LANGUAGES_DROPDOWN } from "../../constants/LanguageConstants.js";
import { USER_PASSWORD_REQUIREMENTS } from "../../constants/Password";
import { CONVERSATION } from "../../constants/Messenger";
import { EMAIL_RECIPIENT_TYPE } from "../../constants/Users";

import "../../styles/css/scenes/manage-user.css";
import CompanyService from "../../services/CompanyService";

class ManageUser extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: null,
			group: {},
			email: "",
			firstName: "",
			lastName: "",
			phone: "",
			language: { value: LANGUAGES.enCA, label: CODES_TO_LABEL[LANGUAGES.enCA] },

			receiveCompanyAlerts: false,
			receiveCompanyReports: false,

			showEnableTeamchat: false,
			enableTeamchat: true,

			locations: [],
			activeLocation: {},
			assignedLocations: [],

			saveSuccess: false,
			saveError: false,
			loading: true,
			loadingFailed: false,

			password: "",
			confirmPassword: "",
			passwordMismatch: false,
			passwordRequirements: USER_PASSWORD_REQUIREMENTS,
			passwordPassedRequirements: true, // true since by default the field is empty
			isTwoFactorEnabled: true,

			notes: "",

			subordinates: [],
			groupPermissions: [],

			enableMessengerSignature: false,
			messengerSignature: "",
			messengerHtmlSignature: "",
			showHTMLSignaturePreview: false,

			messengerClickToSend: false,

			messengerConversationNudging: false,

			teamchatChannelsList: [],
			teamchatChannelsSelectedList: [],

			messengerShowReplySuggestions: true,
			profilePictureError: false,
			profilePictureUrlOriginal: "",
			profilePictureFileNew: "",
			profilePictureChanged: false,
			enableTours: true
		};

		this.emailRef = React.createRef();
		this.locationsRef = React.createRef();
		this.confirmPasswordRef = React.createRef();
	}

	async componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		await this.fetchGroupPermissions();
		await this.fetchSubAccounts();
		await this.fetchUserData();
		await this.fetchTeamChatChannels();
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	fetchTeamChatChannels = async () => {
		let { showEnableTeamchat, enableTeamchat } = this.state;

		if (!showEnableTeamchat || !enableTeamchat) {
			return;
		}

		let user = UserService.get();
		let teamchatChannelsList = await TeamChatService.fetchConversations({ userId: user.id, type: CONVERSATION.channel });

		if (!teamchatChannelsList) {
			return;
		}

		await this.update({ teamchatChannelsList });
	};

	fetchGroupPermissions = async () => {
		let groupPermissions = await GroupPermissionService.fetchGroupPermissions();
		await this.update({ groupPermissions });
	};

	fetchSubAccounts = async () => {
		let subordinates = await GroupPermissionService.fetchSubordinateAccounts();

		subordinates = subordinates.map(s => {
			return {
				value: s.id,
				label: s.name,
				type: s.type
			};
		});

		await this.update({
			subordinates
		});
	};

	handleGroupChange = async value => {
		await this.update({
			group: value
		});

		let { group, assignedLocations, groupPermissions } = this.state;
		let permission = groupPermissions.find(gp => gp.type === group.type);

		if (permission.view_messages_assigned_to_others && permission.view_unassigned_messages) {
			return;
		}

		let locations = [...assignedLocations];
		for (const location of locations) {
			location.contact_unassigned_desktop = permission.view_unassigned_messages;
			location.contact_unassigned_email = permission.view_unassigned_messages;
			location.contact_unassigned_push = permission.view_unassigned_messages;
			location.contact_assigned_to_others_desktop = permission.view_messages_assigned_to_others;
			location.contact_assigned_to_others_push = permission.view_messages_assigned_to_others;
			location.contact_assigned_to_others_email = permission.view_messages_assigned_to_others;
		}
		await this.update({ assignedLocations: [] });
		await this.update({ assignedLocations: locations });
	};

	handlePasswordChange = async ({ value, passed }) => {
		let { confirmPassword } = this.state;
		let passwordMismatch = confirmPassword !== value;

		await this.update({
			password: value,
			passwordPassedRequirements: passed,
			passwordMismatch
		});
	};

	handleConfirmPasswordChange = async ({ value, passed }) => {
		let { password } = this.state;
		let passwordMismatch = password !== value;

		await this.update({
			confirmPassword: value,
			passwordPassedRequirements: passed,
			passwordMismatch
		});
	};

	onInputChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleLocationChange = value => {
		this.setState({ activeLocation: value });
	};

	handleLocationAssign = () => {
		if (this.state.activeLocation && typeof this.state.activeLocation.value !== "undefined") {
			this.addLocationToAssigned(this.state.activeLocation.value);
		}
	};

	handleLocationUnassign = (event, locationId) => {
		event.preventDefault();
		event.stopPropagation();

		if (locationId > 0) {
			this.removeLocationFromAssigned(locationId);
		}
	};

	// Gets the index of the specified location in the locations[] array
	// Returns -1 if not found
	findLocationIndex(locationId) {
		for (let i = 0; i < this.state.locations.length; i++) {
			if (this.state.locations[i].id === locationId) {
				return i;
			}
		}

		return -1;
	}

	// Adds the specified location object to the assigned locations, without duplicates
	addLocationToAssigned(locationId) {
		const locationIndex = this.findLocationIndex(locationId);

		if (locationIndex >= 0) {
			// Make sure it doesn't already exist in the assigned list
			for (let i = 0; i < this.state.assignedLocations.length; i++) {
				if (this.state.assignedLocations[i].id === locationId) {
					return;
				}
			}

			// If we get here, it's not in the list so add it
			const newAssignedLocations = this.state.assignedLocations.slice();
			let newData = this.state.locations[locationIndex];

			let { group, groupPermissions } = this.state;
			let permission = groupPermissions.find(gp => gp.type === group.type);

			// default values when a a new location is assigned
			newData.email_recipient_type = EMAIL_RECIPIENT_TYPE.to.value;
			newData.daily_review_digest = true;
			newData.weekly_location_report = true;
			newData.show_on_leaderboard = true;
			newData.queue_deadline_report = true;

			newData.notify_after_hours = false;
			newData.notify_inbox_only = false;

			newData.contact_assigned_to_me_desktop = true;
			newData.contact_assigned_to_me_email = true;
			newData.contact_assigned_to_me_push = true;
			newData.contact_unassigned_desktop = permission.view_unassigned_messages;
			newData.contact_unassigned_email = permission.view_unassigned_messages;
			newData.contact_unassigned_push = permission.view_unassigned_messages;
			newData.contact_assigned_update_desktop = true;
			newData.contact_assigned_update_email = true;
			newData.contact_assigned_update_push = true;
			newData.contact_assigned_to_others_desktop = permission.view_messages_assigned_to_others;
			newData.contact_assigned_to_others_push = permission.view_messages_assigned_to_others;
			newData.contact_assigned_to_others_email = permission.view_messages_assigned_to_others;

			newAssignedLocations.push(newData);
			this.setState({
				assignedLocations: newAssignedLocations
			});
		}
	}

	onteamChatJoinCheckmarkEvent = ({ teamchatId, checked }) => {
		let { teamchatChannelsSelectedList } = this.state;

		let teamchatChannelsSelectedListCopy = teamchatChannelsSelectedList.slice();

		if (checked) {
			teamchatChannelsSelectedListCopy.push(teamchatId);
		} else {
			var index = teamchatChannelsSelectedListCopy.indexOf(teamchatId);
			if (index !== -1) {
				teamchatChannelsSelectedListCopy.splice(index, 1);
			}
		}

		this.update({ teamchatChannelsSelectedList: teamchatChannelsSelectedListCopy });
	};

	// Removes the specific location object from the assigned list
	removeLocationFromAssigned = locationId => {
		// Find the location in the assigned list
		for (let i = 0; i < this.state.assignedLocations.length; i++) {
			if (this.state.assignedLocations[i].id === locationId) {
				// We found it, so create a new array with the element removed
				const newAssignedLocations = this.state.assignedLocations.slice();
				newAssignedLocations.splice(i, 1);
				this.setState({
					assignedLocations: newAssignedLocations
				});
				return;
			}
		}
	};

	onSave = async event => {
		let { assignedLocations, password, confirmPassword, passwordPassedRequirements } = this.state;
		let { t } = this.props;

		event.preventDefault();
		event.stopPropagation();

		let isCreate = this.isCreate();

		if (assignedLocations.length === 0) {
			UtilityService.validateField(this.locationsRef, t("Please assign at least 1 location to this user."));
			return;
		}

		if (!UtilityService.isEmail(this.state.email)) {
			UtilityService.validateField(this.emailRef, t("Please enter a valid email address."));
			return;
		}

		if (password !== "" && password !== confirmPassword) {
			UtilityService.validateField(this.confirmPasswordRef, t("Confirmation password does not match."));

			await this.update({ passwordMismatch: true, loading: false });
			return;
		}

		if (password !== "" && !passwordPassedRequirements) {
			return;
		}

		let userId = this.props.match.params.userId;
		const company = UserService.getActiveCompany();

		await this.update({ loading: true });

		// Set up the user data (but don't send the password by default)
		const userData = {
			company: company.id,
			group: this.state.group.value,
			email: this.state.email.replace(/\s/g, ""),
			first_name: this.state.firstName.trim(),
			last_name: this.state.lastName.trim(),
			phone: this.state.phone,
			notes: this.state.notes,
			receive_company_alerts: this.state.receiveCompanyAlerts,
			receive_company_reports: this.state.receiveCompanyReports,
			assigned_user_locations: this.state.assignedLocations,
			enable_teamchat: this.state.enableTeamchat,
			status: "active",
			language: this.state.language.value,
			admin_update: true,
			enable_messenger_signature: this.state.enableMessengerSignature,
			messenger_signature: this.state.messengerSignature,
			messenger_html_signature: this.state.messengerHtmlSignature,
			messenger_click_to_send: this.state.messengerClickToSend ? true : false,
			messenger_conversation_nudging: this.state.messengerConversationNudging ? true : false,
			messenger_show_reply_suggestions: this.state.messengerShowReplySuggestions ? true : false,
			enable_tours: this.state.enableTours,
			is_two_factor_enabled: this.state.isTwoFactorEnabled
		};

		// Only send the password if it is not blank
		if (this.state.password !== "") {
			userData.password = this.state.password;
		}

		// Team Chat channels
		if (
			isCreate &&
			this.state.showEnableTeamchat &&
			this.state.enableTeamchat &&
			this.state.teamchatChannelsSelectedList &&
			this.state.teamchatChannelsSelectedList.length > 0
		) {
			userData.teamchatChannels = this.state.teamchatChannelsSelectedList;
		}

		try {
			if (isCreate) {
				//delete properties not required for creating user for cleanliness
				delete userData.status;
				delete userData.admin_update;

				let newUser = await UserService.createUser(userData);
				userId = newUser.id;
			} else {
				userData.userId = userId;
				await UserService.updateUser(userData);
			}

			// Image related conditional logic
			if (this.state.profilePictureChanged) {
				try {
					// Delete the original profile image if it exists
					if (this.state.profilePictureUrlOriginal) {
						await UserService.removeProfilePicture(userId);
					}

					// If we have a new one
					if (this.state.profilePictureFileNew) {
						await UserService.uploadProfilePicture(userId, this.state.profilePictureFileNew);
					}
				} catch (error) {
					console.log(error);
					this.setState({ profilePictureError: true, saving: false });
					return;
				}
			}

			await this.update({ saving: false, saveSuccess: true, loading: false });
		} catch (error) {
			console.log(error);
			await this.update({ saving: false, saveError: true, loading: false });
		}
	};

	async fetchUserData() {
		const company = UserService.getActiveCompany();
		const userId = this.props.match.params.userId;

		let companyData = await CompanyService.fetchCompany(company.id);

		// Get the list of locations that the current admin user has access to
		// (which will be used to assign locations to new users)
		try {
			let data = await CompanyService.fetchLocations({
				companyId: UserService.getActiveCompany().id
			});

			if (data) {
				this.setState({ locations: data });
			}
		} catch (error) {
			console.log(error);
		}

		if (this.isCreate()) {
			this.setState({ loading: false, showEnableTeamchat: companyData.enable_teamchat });
			return;
		}
		try {
			let userData = await UserService.fetchUser(userId);

			let userLocations = await UserService.getUserLocations(userId);

			this.setState({
				user: userData,
				group: { value: userData.group_id, label: userData.GroupPermission.name, type: userData.GroupPermission.type },
				email: userData.email,
				password: "",
				firstName: userData.first_name,
				lastName: userData.last_name,
				phone: userData.phone,
				notes: userData.notes,
				receiveCompanyAlerts: userData.receive_company_alerts,
				receiveCompanyReports: userData.receive_company_reports,
				enableTeamchat: userData.enable_teamchat,
				assignedLocations: userLocations,
				language: { value: userData.language, label: CODES_TO_LABEL[userData.language] },
				showEnableTeamchat: companyData.enable_teamchat,
				enableMessengerSignature: userData.enable_messenger_signature,
				messengerSignature: userData.messenger_signature,
				messengerHtmlSignature: userData.messenger_html_signature,
				messengerClickToSend: userData.messenger_click_to_send,
				messengerConversationNudging: userData.messenger_conversation_nudging,
				messengerShowReplySuggestions: userData.messenger_show_reply_suggestions,
				loading: false,
				profilePictureUrlOriginal: userData.profile_picture_url,
				enableTours: userData.enable_tours,
				isTwoFactorEnabled: userData.is_two_factor_enabled
			});
		} catch (error) {
			this.setState({ loading: false, loadingFailed: true });
			console.log(error);
		}
	}

	// Save any changes to the assigned locations
	handleManagePreferencesChange = locationPreferences => {
		let assignedLocations = this.state.assignedLocations;
		for (let i = 0; i < assignedLocations.length; i++) {
			const location = assignedLocations[i];
			location.email_recipient_type = locationPreferences[`${location.id}`].email_recipient_type;
			location.daily_review_digest = locationPreferences[`${location.id}`].daily_review_digest;
			location.weekly_location_report = locationPreferences[`${location.id}`].weekly_location_report;
			location.show_on_leaderboard = locationPreferences[`${location.id}`].show_on_leaderboard;
			location.queue_deadline_report = locationPreferences[`${location.id}`].queue_deadline_report;

			location.notify_after_hours = locationPreferences[`${location.id}`].notify_after_hours;
			location.notify_inbox_only = locationPreferences[`${location.id}`].notify_inbox_only;

			Object.keys(location).forEach(key => {
				if (key.indexOf("contact_") === 0) {
					location[key] = locationPreferences[`${location.id}`].notifications[key];
				}
			});
		}
		this.setState({
			assignedLocations: assignedLocations
		});
	};

	handleProfilePictureOnChange(profilePictureFileNew) {
		this.setState({
			profilePictureFileNew: profilePictureFileNew,
			profilePictureChanged: true
		});
	}

	isCreate() {
		const userId = this.props.match.params.userId;
		return userId === "create";
	}

	onPlainTextSignatureChange = async newValue => {
		await this.update({ messengerSignature: newValue });
	};

	onHtmlSignatureChange = async newValue => {
		await this.update({ messengerHtmlSignature: newValue });
	};

	renderForm() {
		let {
			subordinates,
			group,

			email,
			password,
			confirmPassword,
			passwordMismatch,
			isTwoFactorEnabled,
			firstName,
			lastName,
			phone,
			notes,
			language,
			receiveCompanyAlerts,
			receiveCompanyReports,
			showEnableTeamchat,
			enableTeamchat,
			enableMessengerSignature,
			messengerSignature,
			messengerHtmlSignature,
			messengerClickToSend,
			messengerConversationNudging,
			messengerShowReplySuggestions,

			locations,
			activeLocation,
			assignedLocations,
			groupPermissions,

			profilePictureUrlOriginal,
			profilePictureFileNew,
			profilePictureChanged,

			teamchatChannelsList,
			teamchatChannelsSelectedList,

			passwordRequirements,
			enableTours
		} = this.state;
		let { t } = this.props;

		let permission = null;
		if (groupPermissions) {
			permission = groupPermissions.find(gp => gp.type === group.type);
		}

		let viewMessagesAssignedToOthersPermissions = permission && permission.view_messages_assigned_to_others;
		let viewUnassignedMessagesPermissions = permission && permission.view_unassigned_messages;

		var firstNameInitial = firstName ? firstName.slice(0, 1) : "";
		var lastNameInitial = lastName ? lastName.slice(0, 1) : "";

		let profileInitials = firstNameInitial + lastNameInitial;

		let profilePictureFileUrl = "";
		if (profilePictureChanged) {
			profilePictureFileUrl = profilePictureFileNew ? URL.createObjectURL(profilePictureFileNew) : "";
		} else if (profilePictureUrlOriginal) {
			profilePictureFileUrl = profilePictureUrlOriginal;
		}

		let isSuperOrCsVisitingAnotherCompany = UserService.isSuperOrCsVisitingAnotherCompany();
		let showTeamchatChannels =
			this.isCreate() && !isSuperOrCsVisitingAnotherCompany && showEnableTeamchat && enableTeamchat && teamchatChannelsList && teamchatChannelsList.length > 0;

		return (
			<form className="manage-user" onSubmit={this.onSave}>
				<DHSelect label={t("Group")} id="group" name="group" options={subordinates} value={group} onChange={this.handleGroupChange} required={true} />

				<div className="hr-line-dashed" />
				<Input label={t("First Name")} name="firstName" id="firstName" type="text" onChange={this.onInputChange} value={firstName} required />
				<Input label={t("Last Name")} name="lastName" id="lastName" type="text" onChange={this.onInputChange} value={lastName} required />

				<div className="hr-line-dashed" />
				<Input label={t("Phone")} name="phone" id="phone" type="text" onChange={this.onInputChange} value={phone} />
				<Input ref={this.emailRef} label={t("Email")} name="email" id="email" type="email" onChange={this.onInputChange} value={email} required />

				<div className="hr-line-dashed" />
				<div className="manage-user__row">
					<div className="manage-user__label">
						<div className="manage-user__label__title">{t("Password")}</div>
						<div className="manage-user__label__description">{t("Leave these fields blank if you are not creating or updating your password.")}</div>
					</div>
					<PasswordInput
						name="password"
						id="password"
						onChange={this.handlePasswordChange}
						value={password}
						required={this.isCreate()}
						requirements={passwordRequirements}
					/>
					<div className="manage-user__row-spacer" />
					<div className="manage-user__label">
						<div className="manage-user__label__description">{t("Confirm Password")}</div>
					</div>
					<PasswordInput
						ref={this.confirmPasswordRef}
						name="form_confirm_password"
						id="confirmPassword"
						onChange={this.handleConfirmPasswordChange}
						value={confirmPassword}
						required={this.isCreate()}
					/>
					{passwordMismatch && <div className="text-danger">{t("Password does not match")}</div>}

					<Toggle
						id="twoFactorAuth"
						label={t("Enable Two-Factor Authentication")}
						description={t("Enable two-factor authentication in order to keep your DemandHub account safe. Highly recommended.")}
						checked={isTwoFactorEnabled}
						onChange={isTwoFactorEnabled => this.update({ isTwoFactorEnabled })}
					/>
				</div>
				<div className="hr-line-dashed" />
				<div className="manage-user__row">
					<div className="manage-user__label">
						<div className="manage-user__label__title">{t("Profile Image")}</div>
						<div className="manage-user__label__description">{t("This photo can be used as the image for the webchat widget.")}</div>
					</div>
					<ProfilePicture
						profileInitials={profileInitials}
						profilePictureFileUrl={profilePictureFileUrl}
						handleProfilePictureOnChange={profilePictureFile => this.handleProfilePictureOnChange(profilePictureFile)}
					/>
				</div>
				<Input
					label={t("Notes")}
					description={t("Anything you would like to let us know about the account?")}
					name="notes"
					id="notes"
					type="textarea"
					value={notes}
					onChange={this.onInputChange}
				/>

				<DHSelect
					label={t("Language")}
					name="language"
					id="language"
					onChange={language => this.update({ language })}
					value={language}
					options={LANGUAGES_DROPDOWN}
					required
				/>
				<div className="hr-line-dashed" />
				<Toggle
					id="receiveCompanyAlerts"
					label={t("Receive Company Alerts")}
					description={t("Turning on Company Alerts will opt-in this user for any global account related alerts.")}
					checked={receiveCompanyAlerts}
					onChange={receiveCompanyAlerts => this.update({ receiveCompanyAlerts })}
				/>
				<Toggle
					id="receiveCompanyReports"
					label={t("Receive Company Reports")}
					description={t("Turning on Company Reports will opt-in this user for any global account related reports.")}
					checked={receiveCompanyReports}
					onChange={receiveCompanyReports => this.update({ receiveCompanyReports })}
				/>
				{showEnableTeamchat && (
					<Toggle
						id="enableTeamChat"
						label={t("Enable Team Chat")}
						description={t("Turn on Team Chat for this user, allowing them to communicate with their teammates.")}
						checked={enableTeamchat}
						onChange={enableTeamchat => this.update({ enableTeamchat })}
					/>
				)}
				<Toggle
					id="enableMessengerSignature"
					label={t("Enable Message Signature")}
					description={t("Allow this user to sign each message with a custom signature.")}
					checked={enableMessengerSignature}
					onChange={enableMessengerSignature => this.update({ enableMessengerSignature })}
				/>
				{enableMessengerSignature && (
					<Signature
						plainTextSignature={messengerSignature}
						htmlSignature={messengerHtmlSignature}
						onPlainTextSignatureChange={this.onPlainTextSignatureChange}
						onHtmlSignatureChange={this.onHtmlSignatureChange}
					/>
				)}
				<Toggle
					id="messengerClickToSend"
					label={t("Enable Messenger Click To Send")}
					description={t("Enabling Click To Send will allow this user to hit the 'Enter' key to create a new line instead of instantly sending the message.")}
					checked={messengerClickToSend}
					onChange={messengerClickToSend => this.update({ messengerClickToSend })}
				/>

				<Toggle
					id="messengerConversationNudging"
					label={t("Messenger Conversation Nudging")}
					description={t("A reminder will be shown in messenger if a follow up or response might be needed.")}
					checked={messengerConversationNudging}
					onChange={messengerConversationNudging => this.update({ messengerConversationNudging })}
				/>

				<Toggle
					id="messengerShowReplySuggestions"
					label={t("Messenger Show Reply Suggestions")}
					description={t("Show reply suggestion options for customer chat.")}
					checked={messengerShowReplySuggestions}
					onChange={messengerShowReplySuggestions => this.update({ messengerShowReplySuggestions })}
				/>

				<Toggle
					id="enableTours"
					label={t("Product Tours")}
					description={t("Show product tours when using the DemandHub app")}
					checked={enableTours}
					onChange={enableTours => this.update({ enableTours })}
				/>

				<div className="hr-line-dashed" />

				{showTeamchatChannels && (
					<>
						<div className="manage-user__teamchat-channels manage-user__row">
							<div className="manage-user__label">
								<div className="manage-user__label__title">{t("Team Chat Channels")}</div>
								<div className="manage-user__label__description">{t("Add this user to the following Team Chat channels:")}</div>
							</div>

							<div className="manage-user__teamchat-channels">
								{teamchatChannelsList.map(convo => (
									<div key={convo.id} className="manage-user__teamchat-channels-row">
										<div className="manage-user__teamchat-channels-row__action">
											<Checkbox
												id={`${convo.id}-checkbox`}
												name={convo.name}
												checked={teamchatChannelsSelectedList.includes(convo.id) ? true : false}
												onChange={event => {
													this.onteamChatJoinCheckmarkEvent({ teamchatId: convo.id, checked: event.target.checked });
												}}
											/>
										</div>
										<div className="manage-user__teamchat-channels-row__name">{convo.name}</div>
									</div>
								))}
							</div>
						</div>
						<div className="hr-line-dashed" />
					</>
				)}

				<div className="manager-user__locations">
					<DHSelect
						label={t("Locations")}
						name="locations"
						id="locations"
						ref={this.locationsRef}
						onChange={this.handleLocationChange}
						value={activeLocation}
						options={locations.map(location => {
							return {
								value: location.id,
								label: location.name + ", " + location.address_1 + ", " + location.city
							};
						})}
						required={this.isCreate()}
						disabled={group.value === undefined}
						isClearable={true}
					/>
					<div id="assign_button" className="mb-button" onClick={this.handleLocationAssign} isDisabled={group.value === undefined}>
						{t("Assign")}
					</div>
					{group.value === undefined && <span className="text-danger">{t("You must first assign the user to a group role.")} </span>}
					<ManageUserPreferences
						locations={assignedLocations}
						handleManagePreferencesChange={this.handleManagePreferencesChange}
						removeLocationFromAssigned={this.removeLocationFromAssigned}
						showOnLeaderboardOption={true}
						locationsRemovable={true}
						isContactAssignedToOthersDisabled={!viewMessagesAssignedToOthersPermissions}
						isUnassignedPushDisabled={!viewUnassignedMessagesPermissions}
					/>
				</div>

				<div className="hr-line-dashed" />
				<div className="manage-user__actions">
					<input id="save" className="mb-button" type="submit" value="Save" />
				</div>
			</form>
		);
	}

	render() {
		let { t } = this.props;

		const user = UserService.get();
		if (this.state.saveSuccess) {
			return <Redirect to="/users" />;
		}
		if (this.state.loading) {
			return (
				<div className="manage-user-spinner">
					<Spinners className="manage-user-spinner" loading={true} type="tail-fade" size="120px" />
				</div>
			);
		}

		if (this.isCreate() && !user.GroupPermission.create_team) {
			return <Redirect to="/dashboard" />;
		}

		if (!user.GroupPermission.update_team || !user.GroupPermission.delete_team) {
			this.props.history.push("/users");
		}

		return (
			<>
				{this.state.loadingFailed && (
					<div>
						<h2>
							{t("Sorry, no data was found")}{" "}
							<span role="img" aria-label="sad-face">
								😞
							</span>
						</h2>
						<Link to="/users" className="btn btn-warning">
							{t("Go Back")}
						</Link>
					</div>
				)}
				{!this.state.loadingFailed && this.renderForm()}
				<Alert
					type="error"
					show={this.state.profilePictureError}
					title={t("Profile Image Change")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ profilePictureError: false });
					}}
				>
					<div>{t("Sorry, an error was encountered while changing the profile image. Please try again.")}</div>
				</Alert>
				<Alert
					type="error"
					show={this.state.saveError}
					title={t("Saving Error")}
					confirm={t("OK")}
					onClose={() => {
						this.setState({ saveError: false });
					}}
				>
					<div>{t("Sorry, an error was encountered while saving this user. Please try again.")}</div>
				</Alert>
			</>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(ManageUser));
