import StringService from "./StringService";
import translations from "../translations/Translations";

const UnknownTranslationService = {
	/**
	 * Get error message from error code
	 * @param {integer} errorCode
	 * @param {string} field
	 * @param {string} length
	 * @returns {string}
	 */
	getErrorMessage(errorCode, field, length) {
		if (translations[errorCode]) {
			return this.generateMessage(translations[errorCode], field, length);
		}
		return this.generateMessage(translations["ERR-0000"], field, length);
	},
	/**
	 * Generate user friendly message
	 * @param {string} text
	 * @param {string} field
	 * @param {string} length
	 */
	generateMessage(text, field, length) {
		return text.replace("%FIELD%", StringService.replaceUnderscoreWithSpacesAndCapitalize(field)).replace("%LENGTH%", length);
	}
};
export default UnknownTranslationService;
