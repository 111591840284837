import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import "../../styles/css/components/commons/unsaved-changes.css";

class UnsavedChanges extends Component {
	render() {
		let { text, t } = this.props;

		return (
			<div className="unsaved-changes__container">
				<div className="unsaved-changes__toast">
					<div className="unsaved-changes__toast-body">
						<div className="unsaved-changes__toast-body__text">{text ? text : t("There are unsaved changes.")}</div>
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(UnsavedChanges);
