import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { withRouter } from "react-router-dom";

import UserService from "../../services/UserService";
import LocationService from "../../services/LocationService";
import GAService from "../../services/GAService";
import DashboardService from "../../services/DashboardService";
import ToastService from "../../services/ToastService";

import withLocation from "../../components/common/WithLocation";
import ManageDashboard from "./ManageDashboard";
import AccountSetup from "./AccountSetup";

import "../../App.css";
import "../../styles/css/scenes/dashboard.css";

class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			showAccountSetup: false,
			dashboardId: null,
			isDashboardEnabled: LocationService.isDashboardEnabled()
		};

		this.accountSetup = null;
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({
			dashboardId: null,
			isDashboardEnabled: LocationService.isDashboardEnabled(),
			loading: true,
			showAccountSetup: false
		});

		let showAccountSetup = await LocationService.showAccountSetup();

		let dashboards = await DashboardService.fetch({
			locationId: UserService.getActiveLocation().id,
			isDefault: true,
			status: ["active"]
		});

		if (!dashboards || dashboards.length < 1) {
			ToastService.error("Failed to fetch dashboard.");
			this.update({
				loading: false,
				showAccountSetup
			});
			return;
		}

		let dashboard = dashboards[0];

		await this.update({
			dashboardId: dashboard.id,
			showAccountSetup,
			loading: false
		});

		this.forceUpdate();
	};

	onLocationChanged = location => {
		this.resetComponent();
	};

	renderLoading = () => {
		return (
			<div className="manage-dashboard__loading manage-dashboard__loading--dashboard">
				<ContentLoader viewBox="0 0 100% 550" height={580} width={"100%"}>
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="100" />
					<rect x="0" y="120" rx="5" ry="5" width="100%" height="100" />
					<rect x="0" y="240" rx="5" ry="5" width="100%" height="100" />
					<rect x="0" y="360" rx="5" ry="5" width="100%" height="100" />
					<rect x="0" y="480" rx="5" ry="5" width="100%" height="100" />
				</ContentLoader>
			</div>
		);
	};

	render() {
		let { isDashboardEnabled, dashboardId, loading, showAccountSetup } = this.state;

		if (!isDashboardEnabled) {
			return <Redirect to="/contacts" />;
		}

		if (loading) {
			return this.renderLoading();
		}

		return (
			<div className="dashboard">
				{showAccountSetup && <AccountSetup />}
				{dashboardId && <ManageDashboard dashboardId={dashboardId}></ManageDashboard>}
			</div>
		);
	}
}

export default withRouter(withLocation(Dashboard));
