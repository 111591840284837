export const NPS_TABS = {
	suggestions: {
		id: "suggestions",
		value: "Suggestions",
		route: "/nps/suggestions"
	},
	analytics: {
		id: "analytics",
		value: "Analytics",
		route: "/nps/analytics"
	}
};
