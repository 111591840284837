export const COUNTRIES = {
	active: [
		{ value: "Australia", label: "Australia" },
		{ value: "Canada", label: "Canada" },
		{ value: "Ireland", label: "Ireland" },
		{ value: "United Kingdom", label: "United Kingdom" },
		{ value: "United States", label: "United States" }
	],
	inactive: [
		{ value: "Afghanistan", label: "Afghanistan" },
		{ value: "Åland Islands", label: "Åland Islands" },
		{ value: "Albania", label: "Albania" },
		{ value: "Algeria", label: "Algeria" },
		{ value: "American Samoa", label: "American Samoa" },
		{ value: "Andorra", label: "Andorra" },
		{ value: "Angola", label: "Angola" },
		{ value: "Anguilla", label: "Anguilla" },
		{ value: "Antarctica", label: "Antarctica" },
		{ value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
		{ value: "Argentina", label: "Argentina" },
		{ value: "Armenia", label: "Armenia" },
		{ value: "Aruba", label: "Aruba" },
		{ value: "Austria", label: "Austria" },
		{ value: "Azerbaijan", label: "Azerbaijan" },
		{ value: "Bahamas", label: "Bahamas" },
		{ value: "Bahrain", label: "Bahrain" },
		{ value: "Bangladesh", label: "Bangladesh" },
		{ value: "Barbados", label: "Barbados" },
		{ value: "Belarus", label: "Belarus" },
		{ value: "Belgium", label: "Belgium" },
		{ value: "Belize", label: "Belize" },
		{ value: "Benin", label: "Benin" },
		{ value: "Bermuda", label: "Bermuda" },
		{ value: "Bhutan", label: "Bhutan" },
		{ value: "Bolivia", label: "Bolivia" },
		{ value: "", label: "Bosnia and Herzegovina" },
		{ value: "", label: "Botswana" },
		{ value: "", label: "Bouvet Island" },
		{ value: "", label: "Brazil" },
		{ value: "", label: "British Indian Ocean Territory" },
		{ value: "", label: "Brunei Darussalam" },
		{ value: "", label: "Bulgaria" },
		{ value: "", label: "Burkina Faso" },
		{ value: "", label: "Burundi" },
		{ value: "", label: "Cambodia" },
		{ value: "", label: "Cameroon" },
		{ value: "", label: "Cape Verde" },
		{ value: "", label: "Cayman Islands" },
		{ value: "", label: "Central African Republic" },
		{ value: "", label: "Chad" },
		{ value: "", label: "Chile" },
		{ value: "", label: "China" },
		{ value: "", label: "Christmas Island" },
		{ value: "", label: "Cocos (Keeling) Islands" },
		{ value: "", label: "Colombia" },
		{ value: "", label: "Comoros" },
		{ value: "", label: "Congo" },
		{ value: "", label: "Congo, The Democratic Republic of the" },
		{ value: "", label: "Cook Islands" },
		{ value: "", label: "Costa Rica" },
		{ value: "", label: "Cote D'Ivoire" },
		{ value: "", label: "Croatia" },
		{ value: "", label: "Cuba" },
		{ value: "", label: "Cyprus" },
		{ value: "", label: "Czech Republic" },
		{ value: "", label: "Denmark" },
		{ value: "", label: "Djibouti" },
		{ value: "", label: "Dominica" },
		{ value: "", label: "Dominican Republic" },
		{ value: "", label: "Ecuador" },
		{ value: "", label: "Egypt" },
		{ value: "", label: "El Salvador" },
		{ value: "", label: "Equatorial Guinea" },
		{ value: "", label: "Eritrea" },
		{ value: "", label: "Estonia" },
		{ value: "", label: "Ethiopia" },
		{ value: "", label: "Falkland Islands (Malvinas)" },
		{ value: "", label: "Faroe Islands" },
		{ value: "", label: "Fiji" },
		{ value: "", label: "Finland" },
		{ value: "", label: "France" },
		{ value: "", label: "French Guiana" },
		{ value: "", label: "French Polynesia" },
		{ value: "", label: "French Southern Territories" },
		{ value: "", label: "Gabon" },
		{ value: "", label: "Gambia" },
		{ value: "", label: "Georgia" },
		{ value: "", label: "Germany" },
		{ value: "", label: "Ghana" },
		{ value: "", label: "Gibraltar" },
		{ value: "", label: "Greece" },
		{ value: "", label: "Greenland" },
		{ value: "", label: "Grenada" },
		{ value: "", label: "Guadeloupe" },
		{ value: "", label: "Guam" },
		{ value: "", label: "Guatemala" },
		{ value: "", label: "Guernsey" },
		{ value: "", label: "Guinea" },
		{ value: "", label: "Guinea-Bissau" },
		{ value: "", label: "Guyana" },
		{ value: "", label: "Haiti" },
		{ value: "", label: "Heard Island and Mcdonald Islands" },
		{ value: "", label: "Holy See (Vatican City State)" },
		{ value: "", label: "Honduras" },
		{ value: "", label: "Hong Kong" },
		{ value: "", label: "Hungary" },
		{ value: "", label: "Iceland" },
		{ value: "", label: "India" },
		{ value: "", label: "Indonesia" },
		{ value: "", label: "Iran, Islamic Republic Of" },
		{ value: "", label: "Iraq" },
		{ value: "", label: "Isle of Man" },
		{ value: "", label: "Israel" },
		{ value: "", label: "Italy" },
		{ value: "", label: "Jamaica" },
		{ value: "", label: "Japan" },
		{ value: "", label: "Jersey" },
		{ value: "", label: "Jordan" },
		{ value: "", label: "Kazakhstan" },
		{ value: "", label: "Kenya" },
		{ value: "", label: "Kiribati" },
		{ value: "", label: "Korea, Democratic People's Republic of" },
		{ value: "", label: "Korea, Republic of" },
		{ value: "", label: "Kuwait" },
		{ value: "", label: "Kyrgyzstan" },
		{ value: "", label: "Lao People's Democratic Republic" },
		{ value: "", label: "Latvia" },
		{ value: "", label: "Lebanon" },
		{ value: "", label: "Lesotho" },
		{ value: "", label: "Liberia" },
		{ value: "", label: "Libyan Arab Jamahiriya" },
		{ value: "", label: "Liechtenstein" },
		{ value: "", label: "Lithuania" },
		{ value: "", label: "Luxembourg" },
		{ value: "", label: "Macao" },
		{ value: "", label: "Macedonia, The Former Yugoslav Republic of" },
		{ value: "", label: "Madagascar" },
		{ value: "", label: "Malawi" },
		{ value: "", label: "Malaysia" },
		{ value: "", label: "Maldives" },
		{ value: "", label: "Mali" },
		{ value: "", label: "Malta" },
		{ value: "", label: "Marshall Islands" },
		{ value: "", label: "Martinique" },
		{ value: "", label: "Mauritania" },
		{ value: "", label: "Mauritius" },
		{ value: "", label: "Mayotte" },
		{ value: "", label: "Mexico" },
		{ value: "", label: "Micronesia, Federated States of" },
		{ value: "", label: "Moldova, Republic of" },
		{ value: "", label: "Monaco" },
		{ value: "", label: "Mongolia" },
		{ value: "", label: "Montserrat" },
		{ value: "", label: "Morocco" },
		{ value: "", label: "Mozambique" },
		{ value: "", label: "Myanmar" },
		{ value: "", label: "Namibia" },
		{ value: "", label: "Nauru" },
		{ value: "", label: "Nepal" },
		{ value: "", label: "Netherlands" },
		{ value: "", label: "Netherlands Antilles" },
		{ value: "", label: "New Caledonia" },
		{ value: "", label: "New Zealand" },
		{ value: "", label: "Nicaragua" },
		{ value: "", label: "Niger" },
		{ value: "", label: "Nigeria" },
		{ value: "", label: "Niue" },
		{ value: "", label: "Norfolk Island" },
		{ value: "", label: "Northern Mariana Islands" },
		{ value: "", label: "Norway" },
		{ value: "", label: "Oman" },
		{ value: "", label: "Pakistan" },
		{ value: "", label: "Palau" },
		{ value: "", label: "Palestinian Territory, Occupied" },
		{ value: "", label: "Panama" },
		{ value: "", label: "Papua New Guinea" },
		{ value: "", label: "Paraguay" },
		{ value: "", label: "Peru" },
		{ value: "", label: "Philippines" },
		{ value: "", label: "Pitcairn" },
		{ value: "", label: "Poland" },
		{ value: "", label: "Portugal" },
		{ value: "", label: "Puerto Rico" },
		{ value: "", label: "Qatar" },
		{ value: "", label: "Reunion" },
		{ value: "", label: "Romania" },
		{ value: "", label: "Russian Federation" },
		{ value: "", label: "Rwanda" },
		{ value: "", label: "Saint Helena" },
		{ value: "", label: "Saint Kitts and Nevis" },
		{ value: "", label: "Saint Lucia" },
		{ value: "", label: "Saint Pierre and Miquelon" },
		{ value: "", label: "Saint Vincent and the Grenadines" },
		{ value: "", label: "Samoa" },
		{ value: "", label: "San Marino" },
		{ value: "", label: "Sao Tome and Principe" },
		{ value: "", label: "Saudi Arabia" },
		{ value: "", label: "Senegal" },
		{ value: "", label: "Serbia and Montenegro" },
		{ value: "", label: "Seychelles" },
		{ value: "", label: "Sierra Leone" },
		{ value: "", label: "Singapore" },
		{ value: "", label: "Slovakia" },
		{ value: "", label: "Slovenia" },
		{ value: "", label: "Solomon Islands" },
		{ value: "", label: "Somalia" },
		{ value: "", label: "South Africa" },
		{ value: "", label: "South Georgia and the South Sandwich Islands" },
		{ value: "", label: "Spain" },
		{ value: "", label: "Sri Lanka" },
		{ value: "", label: "Sudan" },
		{ value: "", label: "Surilabel" },
		{ value: "", label: "Svalbard and Jan Mayen" },
		{ value: "", label: "Swaziland" },
		{ value: "", label: "Sweden" },
		{ value: "", label: "Switzerland" },
		{ value: "", label: "Syrian Arab Republic" },
		{ value: "", label: "Taiwan" },
		{ value: "", label: "Tajikistan" },
		{ value: "", label: "Tanzania, United Republic of" },
		{ value: "", label: "Thailand" },
		{ value: "", label: "Timor-Leste" },
		{ value: "", label: "Togo" },
		{ value: "", label: "Tokelau" },
		{ value: "", label: "Tonga" },
		{ value: "", label: "Trinidad and Tobago" },
		{ value: "", label: "Tunisia" },
		{ value: "", label: "Turkey" },
		{ value: "", label: "Turkmenistan" },
		{ value: "", label: "Turks and Caicos Islands" },
		{ value: "", label: "Tuvalu" },
		{ value: "", label: "Uganda" },
		{ value: "", label: "Ukraine" },
		{ value: "", label: "United Arab Emirates" },
		{ value: "", label: "United States Minor Outlying Islands" },
		{ value: "", label: "Uruguay" },
		{ value: "", label: "Uzbekistan" },
		{ value: "", label: "Vanuatu" },
		{ value: "", label: "Venezuela" },
		{ value: "", label: "Vietnam" },
		{ value: "", label: "Virgin Islands, British" },
		{ value: "", label: "Virgin Islands, U.S." },
		{ value: "", label: "Wallis and Futuna" },
		{ value: "", label: "Western Sahara" },
		{ value: "", label: "Yemen" },
		{ value: "", label: "Zambia" },
		{ value: "", label: "Zimbabwe" }
	]
};

export const ISO_COUNTRIES = [
	{ value: "AF", label: "Afghanistan" },
	{ value: "AX", label: "Åland Islands" },
	{ value: "AL", label: "Albania" },
	{ value: "DZ", label: "Algeria" },
	{ value: "AS", label: "American Samoa" },
	{ value: "AD", label: "Andorra" },
	{ value: "AO", label: "Angola" },
	{ value: "AI", label: "Anguilla" },
	{ value: "AQ", label: "Antarctica" },
	{ value: "AG", label: "Antigua and Barbuda" },
	{ value: "AR", label: "Argentina" },
	{ value: "AM", label: "Armenia" },
	{ value: "AW", label: "Aruba" },
	{ value: "AU", label: "Australia" },
	{ value: "AT", label: "Austria" },
	{ value: "AZ", label: "Azerbaijan" },
	{ value: "BS", label: "Bahamas" },
	{ value: "BH", label: "Bahrain" },
	{ value: "BD", label: "Bangladesh" },
	{ value: "BB", label: "Barbados" },
	{ value: "BY", label: "Belarus" },
	{ value: "BE", label: "Belgium" },
	{ value: "BZ", label: "Belize" },
	{ value: "BJ", label: "Benin" },
	{ value: "BM", label: "Bermuda" },
	{ value: "BT", label: "Bhutan" },
	{ value: "BO", label: "Bolivia, Plurinational State of" },
	{ value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
	{ value: "BA", label: "Bosnia and Herzegovina" },
	{ value: "BW", label: "Botswana" },
	{ value: "BV", label: "Bouvet Island" },
	{ value: "BR", label: "Brazil" },
	{ value: "IO", label: "British Indian Ocean Territory" },
	{ value: "BN", label: "Brunei Darussalam" },
	{ value: "BG", label: "Bulgaria" },
	{ value: "BF", label: "Burkina Faso" },
	{ value: "BI", label: "Burundi" },
	{ value: "KH", label: "Cambodia" },
	{ value: "CM", label: "Cameroon" },
	{ value: "CA", label: "Canada" },
	{ value: "CV", label: "Cape Verde" },
	{ value: "KY", label: "Cayman Islands" },
	{ value: "CF", label: "Central African Republic" },
	{ value: "TD", label: "Chad" },
	{ value: "CL", label: "Chile" },
	{ value: "CN", label: "China" },
	{ value: "CX", label: "Christmas Island" },
	{ value: "CC", label: "Cocos (Keeling) Islands" },
	{ value: "CO", label: "Colombia" },
	{ value: "KM", label: "Comoros" },
	{ value: "CG", label: "Congo" },
	{ value: "CD", label: "Congo, the Democratic Republic of the" },
	{ value: "CK", label: "Cook Islands" },
	{ value: "CR", label: "Costa Rica" },
	{ value: "CI", label: "Côte d'Ivoire" },
	{ value: "HR", label: "Croatia" },
	{ value: "CU", label: "Cuba" },
	{ value: "CW", label: "Curaçao" },
	{ value: "CY", label: "Cyprus" },
	{ value: "CZ", label: "Czech Republic" },
	{ value: "DK", label: "Denmark" },
	{ value: "DJ", label: "Djibouti" },
	{ value: "DM", label: "Dominica" },
	{ value: "DO", label: "Dominican Republic" },
	{ value: "EC", label: "Ecuador" },
	{ value: "EG", label: "Egypt" },
	{ value: "SV", label: "El Salvador" },
	{ value: "GQ", label: "Equatorial Guinea" },
	{ value: "ER", label: "Eritrea" },
	{ value: "EE", label: "Estonia" },
	{ value: "ET", label: "Ethiopia" },
	{ value: "FK", label: "Falkland Islands (Malvinas)" },
	{ value: "FO", label: "Faroe Islands" },
	{ value: "FJ", label: "Fiji" },
	{ value: "FI", label: "Finland" },
	{ value: "FR", label: "France" },
	{ value: "GF", label: "French Guiana" },
	{ value: "PF", label: "French Polynesia" },
	{ value: "TF", label: "French Southern Territories" },
	{ value: "GA", label: "Gabon" },
	{ value: "GM", label: "Gambia" },
	{ value: "GE", label: "Georgia" },
	{ value: "DE", label: "Germany" },
	{ value: "GH", label: "Ghana" },
	{ value: "GI", label: "Gibraltar" },
	{ value: "GR", label: "Greece" },
	{ value: "GL", label: "Greenland" },
	{ value: "GD", label: "Grenada" },
	{ value: "GP", label: "Guadeloupe" },
	{ value: "GU", label: "Guam" },
	{ value: "GT", label: "Guatemala" },
	{ value: "GG", label: "Guernsey" },
	{ value: "GN", label: "Guinea" },
	{ value: "GW", label: "Guinea-Bissau" },
	{ value: "GY", label: "Guyana" },
	{ value: "HT", label: "Haiti" },
	{ value: "HM", label: "Heard Island and McDonald Islands" },
	{ value: "VA", label: "Holy See (Vatican City State)" },
	{ value: "HN", label: "Honduras" },
	{ value: "HK", label: "Hong Kong" },
	{ value: "HU", label: "Hungary" },
	{ value: "IS", label: "Iceland" },
	{ value: "IN", label: "India" },
	{ value: "ID", label: "Indonesia" },
	{ value: "IR", label: "Iran, Islamic Republic of" },
	{ value: "IQ", label: "Iraq" },
	{ value: "IE", label: "Ireland" },
	{ value: "IM", label: "Isle of Man" },
	{ value: "IL", label: "Israel" },
	{ value: "IT", label: "Italy" },
	{ value: "JM", label: "Jamaica" },
	{ value: "JP", label: "Japan" },
	{ value: "JE", label: "Jersey" },
	{ value: "JO", label: "Jordan" },
	{ value: "KZ", label: "Kazakhstan" },
	{ value: "KE", label: "Kenya" },
	{ value: "KI", label: "Kiribati" },
	{ value: "KP", label: "Korea, Democratic People's Republic of" },
	{ value: "KR", label: "Korea, Republic of" },
	{ value: "KW", label: "Kuwait" },
	{ value: "KG", label: "Kyrgyzstan" },
	{ value: "LA", label: "Lao People's Democratic Republic" },
	{ value: "LV", label: "Latvia" },
	{ value: "LB", label: "Lebanon" },
	{ value: "LS", label: "Lesotho" },
	{ value: "LR", label: "Liberia" },
	{ value: "LY", label: "Libya" },
	{ value: "LI", label: "Liechtenstein" },
	{ value: "LT", label: "Lithuania" },
	{ value: "LU", label: "Luxembourg" },
	{ value: "MO", label: "Macao" },
	{ value: "MK", label: "Macedonia, the Former Yugoslav Republic of" },
	{ value: "MG", label: "Madagascar" },
	{ value: "MW", label: "Malawi" },
	{ value: "MY", label: "Malaysia" },
	{ value: "MV", label: "Maldives" },
	{ value: "ML", label: "Mali" },
	{ value: "MT", label: "Malta" },
	{ value: "MH", label: "Marshall Islands" },
	{ value: "MQ", label: "Martinique" },
	{ value: "MR", label: "Mauritania" },
	{ value: "MU", label: "Mauritius" },
	{ value: "YT", label: "Mayotte" },
	{ value: "MX", label: "Mexico" },
	{ value: "FM", label: "Micronesia, Federated States of" },
	{ value: "MD", label: "Moldova, Republic of" },
	{ value: "MC", label: "Monaco" },
	{ value: "MN", label: "Mongolia" },
	{ value: "ME", label: "Montenegro" },
	{ value: "MS", label: "Montserrat" },
	{ value: "MA", label: "Morocco" },
	{ value: "MZ", label: "Mozambique" },
	{ value: "MM", label: "Myanmar" },
	{ value: "NA", label: "Namibia" },
	{ value: "NR", label: "Nauru" },
	{ value: "NP", label: "Nepal" },
	{ value: "NL", label: "Netherlands" },
	{ value: "NC", label: "New Caledonia" },
	{ value: "NZ", label: "New Zealand" },
	{ value: "NI", label: "Nicaragua" },
	{ value: "NE", label: "Niger" },
	{ value: "NG", label: "Nigeria" },
	{ value: "NU", label: "Niue" },
	{ value: "NF", label: "Norfolk Island" },
	{ value: "MP", label: "Northern Mariana Islands" },
	{ value: "NO", label: "Norway" },
	{ value: "OM", label: "Oman" },
	{ value: "PK", label: "Pakistan" },
	{ value: "PW", label: "Palau" },
	{ value: "PS", label: "Palestine, State of" },
	{ value: "PA", label: "Panama" },
	{ value: "PG", label: "Papua New Guinea" },
	{ value: "PY", label: "Paraguay" },
	{ value: "PE", label: "Peru" },
	{ value: "PH", label: "Philippines" },
	{ value: "PN", label: "Pitcairn" },
	{ value: "PL", label: "Poland" },
	{ value: "PT", label: "Portugal" },
	{ value: "PR", label: "Puerto Rico" },
	{ value: "QA", label: "Qatar" },
	{ value: "RE", label: "Réunion" },
	{ value: "RO", label: "Romania" },
	{ value: "RU", label: "Russian Federation" },
	{ value: "RW", label: "Rwanda" },
	{ value: "BL", label: "Saint Barthélemy" },
	{ value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
	{ value: "KN", label: "Saint Kitts and Nevis" },
	{ value: "LC", label: "Saint Lucia" },
	{ value: "MF", label: "Saint Martin (French part)" },
	{ value: "PM", label: "Saint Pierre and Miquelon" },
	{ value: "VC", label: "Saint Vincent and the Grenadines" },
	{ value: "WS", label: "Samoa" },
	{ value: "SM", label: "San Marino" },
	{ value: "ST", label: "Sao Tome and Principe" },
	{ value: "SA", label: "Saudi Arabia" },
	{ value: "SN", label: "Senegal" },
	{ value: "RS", label: "Serbia" },
	{ value: "SC", label: "Seychelles" },
	{ value: "SL", label: "Sierra Leone" },
	{ value: "SG", label: "Singapore" },
	{ value: "SX", label: "Sint Maarten (Dutch part)" },
	{ value: "SK", label: "Slovakia" },
	{ value: "SI", label: "Slovenia" },
	{ value: "SB", label: "Solomon Islands" },
	{ value: "SO", label: "Somalia" },
	{ value: "ZA", label: "South Africa" },
	{ value: "GS", label: "South Georgia and the South Sandwich Islands" },
	{ value: "SS", label: "South Sudan" },
	{ value: "ES", label: "Spain" },
	{ value: "LK", label: "Sri Lanka" },
	{ value: "SD", label: "Sudan" },
	{ value: "SR", label: "Suriname" },
	{ value: "SJ", label: "Svalbard and Jan Mayen" },
	{ value: "SZ", label: "Swaziland" },
	{ value: "SE", label: "Sweden" },
	{ value: "CH", label: "Switzerland" },
	{ value: "SY", label: "Syrian Arab Republic" },
	{ value: "TW", label: "Taiwan, Province of China" },
	{ value: "TJ", label: "Tajikistan" },
	{ value: "TZ", label: "Tanzania, United Republic of" },
	{ value: "TH", label: "Thailand" },
	{ value: "TL", label: "Timor-Leste" },
	{ value: "TG", label: "Togo" },
	{ value: "TK", label: "Tokelau" },
	{ value: "TO", label: "Tonga" },
	{ value: "TT", label: "Trinidad and Tobago" },
	{ value: "TN", label: "Tunisia" },
	{ value: "TR", label: "Turkey" },
	{ value: "TM", label: "Turkmenistan" },
	{ value: "TC", label: "Turks and Caicos Islands" },
	{ value: "TV", label: "Tuvalu" },
	{ value: "UG", label: "Uganda" },
	{ value: "UA", label: "Ukraine" },
	{ value: "AE", label: "United Arab Emirates" },
	{ value: "GB", label: "United Kingdom" },
	{ value: "US", label: "United States" },
	{ value: "UM", label: "United States Minor Outlying Islands" },
	{ value: "UY", label: "Uruguay" },
	{ value: "UZ", label: "Uzbekistan" },
	{ value: "VU", label: "Vanuatu" },
	{ value: "VE", label: "Venezuela, Bolivarian Republic of" },
	{ value: "VN", label: "Viet Nam" },
	{ value: "VG", label: "Virgin Islands, British" },
	{ value: "VI", label: "Virgin Islands, U.S." },
	{ value: "WF", label: "Wallis and Futuna" },
	{ value: "EH", label: "Western Sahara" },
	{ value: "YE", label: "Yemen" },
	{ value: "ZM", label: "Zambia" },
	{ value: "ZW", label: "Zimbabwe" }
];
