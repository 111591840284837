import React, { Component } from "react";
import * as Icon from "react-feather";

import UserService from "../../../../services/UserService";
import EmojiService from "../../../../services/EmojiService";
import RealtimeService from "../../../../services/WebsocketsConnection";

import { EMOJI_POSITIONS } from "../../../../constants/Emojis";

import "./reactions.css";

// Reactions Object consists of Keys that are the actual reactions to a Message and the value will be a list of objects with the user's id and name that has reacted with that respective emoji
//
// Example:
//
// reactions: {
// 	"👍": [{ id: 92, name: "Sameid" }, { id: 2, name: "Shahzad" }],
// 	"👆": [{ id: 92, name: "Sameid" }]
// }

class Reactions extends Component {
	constructor(props) {
		super(props);

		let { messageId, reactions } = props;

		reactions = this.parseReactions(reactions);

		this.state = {
			messageId,
			reactions,
			showSelector: false
		};

		this.addNewReactionButton = null;
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidUpdate(prevProps) {
		let { reactions } = this.props;

		if (reactions !== prevProps.reactions) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { reactions } = this.props;

		reactions = this.parseReactions(reactions);

		await this.update({
			reactions
		});
	}

	parseReactions(reactionsString) {
		let reactions = {};

		try {
			reactions = JSON.parse(reactionsString);

			if (reactions === null) {
				reactions = {};
			}
		} catch (error) {
			console.log(error);
		}

		return reactions;
	}

	onAddOrRemoveReaction = key => {
		let { reactions, messageId } = this.state;
		let user = UserService.get();

		// Get the list of users that have the same emoji as a reaction
		let users = reactions[key];

		// If the reaction already exists
		if (users) {
			// Determine if the current user has already reacted with this emoji
			let found = users.some(u => u.id === user.id);

			if (found) {
				// If the user has alread reacted with this emoji, then remove the reaction
				let index = users.findIndex(r => r.id === user.id);
				users.splice(index, 1);

				// If the current use was the only one to react with this emoji and they remove the reaction then kill the key
				if (users.length === 0) {
					delete reactions[key];

					this.update({
						reactions
					});

					RealtimeService.sendMessageUpdated(messageId, reactions);

					return;
				}
			}
			// If the reaction exists but the user has not reacted with the emoji, then add them to the list of reactions
			else {
				users.push({ id: user.id, name: user.first_name });
			}
		}
		// If no one has reacted with this emoji, then create a new list of reactions for this emoji and add the current user
		else {
			users = [{ id: user.id, name: user.first_name }];
		}

		reactions[key] = users;

		RealtimeService.sendMessageUpdated(messageId, reactions);

		this.update({
			reactions
		});
	};

	onShowSelector = () => {
		EmojiService.openEmojiSelector({
			positionBeside: this.addNewReactionButton,
			position: EMOJI_POSITIONS.right,
			onEmojiSelect: this.onEmojiSelected
		});
	};

	onEmojiSelected = emoji => {
		this.onAddOrRemoveReaction(emoji.native);
	};

	render() {
		let { reactions } = this.state;

		if (Object.keys(reactions).length < 1) {
			return null;
		}

		return (
			<div className="mb-reactions">
				{Object.keys(reactions).map((r, index) => {
					return (
						<div key={index} className="mb-reactions-group" onClick={() => this.onAddOrRemoveReaction(r)}>
							<div className="mb-reactions-users">
								{reactions[r]
									.map(user => {
										return user.name;
									})
									.join(", ")}
							</div>
							<div className="mb-reactions-group-emoji">{r}</div>
							<div className="mb-reactions-group-count">{reactions[r].length}</div>
						</div>
					);
				})}
				<div className="mb-reactions-icon" onClick={this.onShowSelector} ref={ref => (this.addNewReactionButton = ref)}>
					<Icon.Smile size="16" />
					<Icon.Plus size="14" />
				</div>
			</div>
		);
	}
}

export default Reactions;
