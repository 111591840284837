import React, { Component } from "react";
import CreatableSelect from "react-select/lib/Creatable";
import ReactTooltip from "react-tooltip";
import { withTranslation, Trans } from "react-i18next";

import ChatbotService from "../../services/ChatbotService";
import Checkbox from "../../components/common/Checkbox";

import "../../styles/css/scenes/chatbot-faqs.css";

class FaqModifier extends Component {
	constructor(props) {
		super(props);

		this.state = {
			edited: false,
			faq: this.props.toEditFaq,
			question: this.props.toEditFaq.question,
			answer: this.props.toEditFaq.answer,
			alternativeQuestions: this.props.toEditFaq.alternative_questions ? JSON.parse(this.props.toEditFaq.alternative_questions) : [],
			endConvoFlag: this.props.toEditFaq.end_convo,
			status: "active",
			locationId: this.props.locationId,
			fetchError: false,
			saveError: false,
			saveSuccess: false
		};
	}

	handleQuestionOnChange = e => {
		this.setState({
			question: e.target.value,
			edited: true
		});
	};

	handleAnswerOnChange = e => {
		this.setState({
			answer: e.target.value,
			edited: true
		});
	};

	handleAlternativeQuestionsOnChange = newValue => {
		let newAlternativeQuestions = newValue.map(item => item.value);
		this.setState({
			alternativeQuestions: newAlternativeQuestions,
			edited: true
		});
	};

	handleEndConvoFlagChange = e => {
		this.setState({
			endConvoFlag: e.target.checked
		});
	};

	handleOnSubmit = async e => {
		const { faq, question, answer, alternativeQuestions, endConvoFlag } = this.state;
		try {
			let faqDetails = {
				faqId: faq.id,
				question: question,
				answer: answer,
				alternativeQuestions: alternativeQuestions,
				endConvo: endConvoFlag
			};
			await ChatbotService.createOrUpdateFaq(faqDetails);
			this.setState({ saveSuccess: true }, () => {
				if (this.props.handleEditCreateModalOnHide) {
					this.props.handleEditCreateModalOnHide();
				}
				if (this.props.fetchData) {
					this.props.fetchData();
				}
			});
		} catch (error) {
			console.error(error);
			if (this.props.handleEditCreateModalOnHide) {
				this.props.handleEditCreateModalOnHide();
			}
			this.setState({ saveError: true });
		}
	};

	render() {
		const { question, answer, alternativeQuestions, endConvoFlag } = this.state;
		let { t } = this.props;

		const alternativeQuestionsOptions = ChatbotService.convertAlternativeQuestionsToOptions(alternativeQuestions);
		return (
			<div className="modal__flex-container">
				<div className="modal__field">{t("Question")}</div>
				<input
					name="question"
					id="question"
					type="text"
					onChange={e => this.handleQuestionOnChange(e)}
					value={question}
					className="form-control"
					required
					autoComplete="off"
				/>
				{/* <div className="form-group">
					<label className="col-sm-3 control-label Chatbot__Faqs-edit-label">Question</label>
					<div className="col-sm-9">
						<input
							name="question"
							id="question"
							type="text"
							onChange={e => this.handleQuestionOnChange(e)}
							value={question}
							className="form-control"
							required
							autoComplete="off"
						/>
					</div>
				</div> */}

				<div className="modal__field">{t("Answer")}</div>
				<input name="answer" id="answer" type="text" onChange={e => this.handleAnswerOnChange(e)} value={answer} className="form-control" required />
				{/* <div className="form-group">
					<label className="col-sm-3 control-label Chatbot__Faqs-edit-label">Answer</label>
					<div className="col-sm-9">
						<input name="answer" id="answer" type="text" onChange={e => this.handleAnswerOnChange(e)} value={answer} className="form-control" required />
					</div>
				</div> */}

				<div className="modal__field--row">
					<div className="modal__field">
						{t("Alternative Questions")}{" "}
						<span className="ChatbotFaqs__info-text">
							<i data-tip data-for="tooltip-1" className="fa fa-info-circle" aria-hidden="true" />
							<ReactTooltip id="tooltip-1" className="mb-react-tooltip text-left" arrowColor="#333" type="info" effect="solid" place="right">
								<Trans
									i18nKey="The more alternative phrasings of the question you add, <0/> the better the chatbot will perform!"
									components={[<br key="0" />]}
								>
									The more alternative phrasings of the question you add, <br /> the better the chatbot will perform!
								</Trans>
							</ReactTooltip>
						</span>
					</div>
				</div>
				<div className="fnctst-alt-question-container" style={{ width: "100%" }}>
					<CreatableSelect
						isMulti
						value={alternativeQuestionsOptions}
						onChange={this.handleAlternativeQuestionsOnChange}
						options={alternativeQuestionsOptions}
					/>
				</div>
				{/* <div className="form-group">
					<label className="col-sm-3 control-label Chatbot__Faqs-edit-label">
						Alternative Questions
						<div className="ChatbotFaqs__info-text">
							<i data-tip data-for="tooltip-1" className="fa fa-info-circle" aria-hidden="true" />
							<ReactTooltip id="tooltip-1" className="mb-react-tooltip text-left" arrowColor="#333" type="info" effect="solid" place="right">
								The more alternative phrasings of the question you add, <br /> the better the chatbot will perform!
							</ReactTooltip>
						</div>
					</label>
					<div className="col-sm-9">
						<CreatableSelect
							isMulti
							value={alternativeQuestionsOptions}
							onChange={this.handleAlternativeQuestionsOnChange}
							options={alternativeQuestionsOptions}
						/>
					</div>
				</div> */}

				<div className="modal__field--row">
					<div className="modal__field">
						{t("Conversation Ender")}{" "}
						<span className="ChatbotFaqs__info-text">
							<i data-tip data-for="tooltip-2" className="fa fa-info-circle" aria-hidden="true" />
							<ReactTooltip id="tooltip-2" className="mb-react-tooltip text-left" arrowColor="#333" type="info" effect="solid" place="right">
								<Trans i18nKey="Checking this box will gracefully end the <0 /> conversation once this question is asked" components={[<br key="0" />]}>
									Checking this box will gracefully end the <br /> conversation once this question is asked
								</Trans>
							</ReactTooltip>
						</span>
					</div>
					<div className="ChatbotFaqs__conversation-ender">
						<Checkbox name="endConvoFlag" checked={endConvoFlag} onChange={this.handleEndConvoFlagChange} />
					</div>
				</div>

				{/* <div className="form-group">
					<label className="col-sm-3 control-label Chatbot__Faqs-edit-label">
						Conversation Ender
						<div className="ChatbotFaqs__info-text">
							<i data-tip data-for="tooltip-2" className="fa fa-info-circle" aria-hidden="true" />
							<ReactTooltip id="tooltip-2" className="mb-react-tooltip text-left" arrowColor="#333" type="info" effect="solid" place="right">
								Checking this box will gracefully end the <br /> conversation once this question is asked
							</ReactTooltip>
						</div>
					</label>
					<div className="col-sm-9">
						<span />
						<Checkbox name="endConvoFlag" checked={endConvoFlag} onChange={this.handleEndConvoFlagChange} />
					</div>
				</div> */}

				<div className="modal__actions">
					<div id="save" className="mb-button" onClick={e => this.handleOnSubmit(e)}>
						{t("Save")}
					</div>
				</div>

				{/* <div className="form-group">
					<div className="col-sm-9 col-sm-offset-3">
						<button className="btn btn-primary" type="submit" disabled={!edited} >
							Save
						</button>
						<button className="btn btn-warning btn-space-between" onClick={() => handleEditCreateModalOnHide()} type="button">
							Cancel
						</button>
					</div>
				</div> */}
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(FaqModifier);
