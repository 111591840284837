import React from "react";
import moment from "moment";
import ContentLoader from "react-content-loader";
import ReactSwitch from "react-switch";
import Select from "react-select";
import { withTranslation } from "react-i18next";

import Modal from "../../../components/common/DHModal";
import Input from "../../../components/common/Input";
import DHSelect from "../../../components/common/DHSelect";
import Toggle from "../../../components/common/Toggle";

import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import A2PService from "../../../services/A2PService";
import NumberService from "../../../services/NumberService";
import NUMBERS from "../../../constants/Numbers";

class EditNumberAssociationModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			config: null,

			tcrCampaignId: "",
			phoneNumber: "",
			cnpID: "BANDW",

			selectableTcrCampaigns: [],
			selectableCNPs: [],
			assignableNumbers: []
		};
	}

	componentDidUpdate = async prevProps => {
		if (prevProps.show !== this.props.show) {
			await this.resetComponent();
		}
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		await this.update({ loading: true });

		await this.update({
			// Modal Data
			config: null,

			tcrCampaignId: "",
			phoneNumber: "",
			cnpID: "BANDW"
		});

		await this.fetchConfig();
		await this.fetchNumbers();
		await this.fetchNumberAssociation();
		await this.buildFormOptions();

		await this.update({ loading: false });
	};

	buildFormOptions = async () => {
		let { config } = this.state;
		let { t } = this.props;

		let companyId = UserService.getActiveCompany().id;

		let selectableTcrCampaigns = [];
		let selectableCNPs = [];

		let submissions = await A2PService.fetchSubmissions({ companyId });

		// Create cnps
		let cnps = config.enums.cnp;
		for (const key in cnps) {
			const cnp = cnps[key];
			const cnpId = cnp.id;
			const cnpName = cnp.name;

			selectableCNPs.push({ label: cnpName, value: cnpId });
		}

		// Add default campaigns
		let temporaryDHCampaigns = config.dhEnums.temporaryDHCampaigns;
		for (const key in temporaryDHCampaigns) {
			const temporaryDHCampaign = temporaryDHCampaigns[key];
			const campaignTcrId = temporaryDHCampaign.id;
			const campaignName = temporaryDHCampaign.name;

			selectableTcrCampaigns.push({ label: `${campaignName} (${campaignTcrId})`, value: campaignTcrId });
		}

		// Add campaigns avaliable from this company
		submissions.forEach(submission => {
			if (
				submission.submission_type === config.dhEnums.submissionTypes.campaign &&
				submission.tcr_campaign_share_status === config.dhEnums.campaignShareStatus.accept &&
				submission.mock === false
			) {
				selectableTcrCampaigns.push({
					value: submission.tcr_campaign_id,
					label: `${t("ID")}: ${submission.id} - ${t("Campaign")} ${submission.tcr_campaign_id} (${t("Updated")} ${moment(submission.updated_at).format(
						"MMM Do hh:mm a"
					)})`
				});
			}
		});

		selectableTcrCampaigns.unshift({
			value: "",
			label: t("Select a campaign")
		});

		await this.update({
			selectableTcrCampaigns,
			selectableCNPs
		});
	};

	fetchConfig = async () => {
		let config = await A2PService.fetchConfig();
		await this.update({ config });
	};

	fetchNumberAssociation = async () => {
		let { t } = this.props;

		if (!this.props.numberAssociationId) {
			return;
		}

		let numberAssociation = await A2PService.fetchNumberAssociation({ numberAssociationId: this.props.numberAssociationId });

		if (!numberAssociation) {
			ToastService.error(t("Could not fetch number association."));
			return;
		}

		await this.update({
			tcrCampaignId: numberAssociation.tcr_campaign_id,
			phoneNumber: numberAssociation.phone_number,
			cnpID: numberAssociation.cnp_id
		});
	};

	fetchNumbers = async () => {
		let { config, cnpID } = this.state;

		let companyId = UserService.getActiveCompany().id;

		let api = "";
		if (cnpID === config.enums.cnp.bandwidth.id) {
			api = NUMBERS.api.bandwidthV2;
		} else if (cnpID === config.enums.cnp.twilio.id) {
			api = NUMBERS.api.twilio;
		}

		let numbers = await NumberService.fetchNumbers({ companyId, status: "active", api });

		if (!numbers) {
			return;
		}

		let assignableNumbers = [];

		numbers.map(number => {
			assignableNumbers.push({
				value: number.number,
				label: number.number
			});
		});

		assignableNumbers.unshift({ value: "", label: `Select a ${api} number` });

		await this.update({ assignableNumbers, phoneNumber: "" });
	};

	onCNPChange = async cnpID => {
		await this.update({ cnpID });
		await this.fetchNumbers();
	};

	canSave = () => {
		let { tcrCampaignId, phoneNumber, cnpID } = this.state;

		return tcrCampaignId && phoneNumber && cnpID;
	};

	onSave = async () => {
		let { numberAssociationId, t } = this.props;
		let { config, tcrCampaignId, phoneNumber, cnpID } = this.state;

		if (!this.canSave()) {
			return;
		}

		let companyId = UserService.getActiveCompany().id;

		let response = null;
		if (numberAssociationId) {
			response = await A2PService.updateNumberAssociation({ numberAssociationId, phoneNumber, tcrCampaignId, cnpID });
		} else {
			response = await A2PService.createNumberAssociation({ companyId, phoneNumber, tcrCampaignId, cnpID });
		}

		if (response) {
			ToastService.info(numberAssociationId ? t("Number association updated.") : t("Successfully created."));
		} else {
			ToastService.error(t("Failed to save. Please try again."));
			return;
		}

		if (this.props.onSave) {
			await this.props.onSave();
		}
	};

	// For <input> changes
	onChangeInput = ({ fieldName, value }) => {
		this.update({
			[fieldName]: value
		});
	};

	onSwitchChange = fieldName => {
		this.update({
			[fieldName]: !this.state[fieldName]
		});
	};

	onHide = () => {
		if (this.props.onHide) {
			this.props.onHide();
		}
	};

	render = () => {
		let { show, numberAssociationId, onHide, t } = this.props;
		let { loading, config } = this.state;
		let { selectableTcrCampaigns, selectableCNPs, assignableNumbers } = this.state;
		let { tcrCampaignId, phoneNumber, cnpID } = this.state;

		// Find the phone number
		let phoneNumberLabel = assignableNumbers.find(number => number.value === phoneNumber);
		phoneNumberLabel = typeof phoneNumberLabel === "undefined" ? phoneNumber : phoneNumberLabel.label;

		if (loading || !config) {
			return (
				<Modal show={show} onHide={onHide} title={numberAssociationId ? t("Update Number Association") : t("Create Number Association")}>
					<div className="a2p-modal">
						<ContentLoader height={650} width={"100%"}>
							{/* The tabs */}
							<rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />

							{/* Rows */}
							<rect x="0" y="70" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="150" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="230" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="310" rx="5" ry="5" width="100%" height="50" />
							<rect x="0" y="390" rx="5" ry="5" width="100%" height="50" />
						</ContentLoader>
					</div>
				</Modal>
			);
		}

		// Find the campaign
		let tcrCampaignLabel = selectableTcrCampaigns.find(campaign => campaign.value === tcrCampaignId);
		tcrCampaignLabel = typeof tcrCampaignLabel === "undefined" ? t("Unknown Campaign") : tcrCampaignLabel.label;

		// Find the cnp
		let cnpLabel = selectableCNPs.find(cnp => cnp.value === cnpID);
		cnpLabel = typeof cnpLabel === "undefined" ? t("Unknown CNP") : cnpLabel.label;

		return (
			<Modal show={show} onHide={onHide} title={numberAssociationId ? t("Update Number Association") : t("Create Number Association")}>
				<div style={{ width: "100%" }}>
					<DHSelect
						label={t("TCR Campaign ID")}
						id="tcr_campaign_id"
						name="tcr_campaign_id"
						options={selectableTcrCampaigns}
						value={{ label: tcrCampaignLabel, value: tcrCampaignId }}
						placeholder={t("Campaign")}
						onChange={option => {
							this.onChangeInput({ fieldName: "tcrCampaignId", value: option.value });
						}}
						required
					/>

					<DHSelect
						label={t("CNP ID")}
						id="cnp_id"
						name="cnp_id"
						options={selectableCNPs}
						value={{ label: cnpLabel, value: cnpID }}
						placeholder={t("CNP")}
						onChange={option => {
							this.onCNPChange(option.value);
						}}
						required
					/>

					<DHSelect
						label={t("Phone Number (Include +1)")}
						name="phone_number"
						id="phone_number"
						type="text"
						options={assignableNumbers}
						value={{ label: phoneNumberLabel, value: phoneNumber }}
						onChange={option => {
							this.onChangeInput({ fieldName: "phoneNumber", value: option.value });
						}}
						required
					/>

					<div className="modal__actions">
						<div className={`mb-button mb-button--fit ${!this.canSave() ? "mb-button--disabled" : ""}`} onClick={this.onSave}>
							{t("Save")}
						</div>
					</div>
				</div>
			</Modal>
		);
	};
}

export default withTranslation(null, { withRef: true })(EditNumberAssociationModal);
