import React, { Component } from "react";
import ReactTable from "react-table";
import * as Icon from "react-feather";
import moment from "moment";
import { withTranslation } from "react-i18next";

import ActionDropdown from "../../components/common/ActionDropdown";
import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import CompanyService from "../../services/CompanyService";

import Spinners from "../../components/common/Spinners";
import NoDataComponent from "../../components/common/NoDataComponent";

import "../../styles/css/components/commons/react-table.css";
import "../../styles/css/scenes/companies.css";

class CompareToolLeads extends Component {
	constructor(props) {
		super(props);

		this.state = {
			leads: [],
			loading: false,
			nameDesc: false,
			created_atDesc: true
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.refreshLeadsList();
	}

	async refreshLeadsList() {
		let leads = await CompanyService.fetchAllCompareToolLeads();

		if (!leads) {
			this.setState({ loading: false, hasError: true });
			return;
		}

		this.setState({
			leads
		});
	}

	generateOptionsFromPermissions() {
		let { t } = this.props;

		const options = [];
		const user = UserService.get();

		if (user.GroupPermission.update_leads) {
			options.push({
				label: t("Manage"),
				value: "manage"
			});
		}
		// if (user.GroupPermission.delete_leads) {
		// 	options.push({
		// 		label: "Delete",
		// 		value: "delete"
		// 	});
		// }

		return options;
	}

	handleManageLeadClick = row => {
		this.props.history.push(`/reputation-report/manage/${row.id}`);
	};

	render() {
		let { t } = this.props;
		let user = UserService.get();

		return (
			<div className="wrapper wrapper-content main-table-margin companies__table--padding">
				<div className="row">
					<div className="col-lg-12">
						<div className="ibox float-e-margins">
							<div className="ibox-content">
								<div>
									{user.GroupPermission.view_leads && (
										<ReactTable
											data={this.state.leads}
											onSortedChange={sorted => {
												if (sorted.length !== 1) {
													return;
												}
												this.setState({ [sorted[0].id + "Desc"]: sorted[0].desc });
											}}
											columns={[
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															{t("Name")} {this.state.nameDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "name",
													className: "DHReactTable__cell",
													id: "name",
													Cell: ({ value }) => {
														return <span>{value}</span>;
													}
												},
												{
													Header: () => <div className="DHReactTable__header--container">{t("Contact Name")}</div>,
													headerClassName: "DHReactTable__row--header hidden-md hidden-sm hidden-xs",
													accessor: "contact_name",
													className: "DHReactTable__cell hidden-md hidden-sm hidden-xs",
													id: "contact-name",
													sortable: false,
													Cell: ({ value }) => {
														return <span>{value}</span>;
													}
												},
												{
													Header: () => <div className="DHReactTable__header--container">{t("Avg Rating")}</div>,
													headerClassName: "DHReactTable__row--header",
													accessor: "average_rating",
													className: "DHReactTable__cell",
													id: "average_rating",
													sortable: false,
													Cell: ({ value }) => {
														return <span>{value}</span>;
													}
												},
												{
													Header: () => <div className="DHReactTable__header--container">{t("Total Reviews")}</div>,
													headerClassName: "DHReactTable__row--header",
													accessor: "total_reviews",
													className: "DHReactTable__cell",
													id: "total_reviews",
													sortable: false,
													Cell: ({ value }) => {
														return <span>{value}</span>;
													}
												},
												{
													Header: () => <div className="DHReactTable__header--container">{t("Website")}</div>,
													headerClassName: "DHReactTable__row--header hidden-md hidden-sm hidden-xs",
													accessor: "website",
													className: "DHReactTable__cell hidden-md hidden-sm hidden-xs",
													id: "website",
													sortable: false,
													Cell: ({ value }) => {
														return (
															<a href={`${value}`} target="_blank" rel="noopener noreferrer">
																<Icon.ArrowUpRight />
															</a>
														);
													}
												},
												{
													Header: () => <div className="DHReactTable__header--container">{t("Google Place Url")}</div>,
													headerClassName: "DHReactTable__row--header hidden-md hidden-sm hidden-xs",
													accessor: "google_place_url",
													className: "DHReactTable__cell hidden-md hidden-sm hidden-xs",
													id: "google_place_url",
													sortable: false,
													Cell: ({ value }) => {
														return (
															<a href={`${value}`} target="_blank" rel="noopener noreferrer">
																<Icon.ArrowUpRight />
															</a>
														);
													}
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															{t("Created At")}
															{this.state.created_atDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "created_at",
													className: "DHReactTable__cell",
													id: "created_at",
													// sortable: false,
													Cell: ({ value }) => {
														return <span>{moment(value).format("YYYY-MM-DD")}</span>;
													}
												},
												{
													Header: () => <div className="DHReactTable__header--container">{t("Options")}</div>,
													headerClassName: "DHReactTable__row--header",
													accessor: row => row,
													className: "DHReactTable__cell",
													id: "select-option",
													sortable: false,
													Cell: row => {
														return (
															<div>
																{this.generateOptionsFromPermissions().length > 0 && (
																	<ActionDropdown
																		options={this.generateOptionsFromPermissions()}
																		bsStyle="success"
																		title={t("Select")}
																		data={row.value}
																		handleOnClick={{
																			manage: this.handleManageLeadClick
																		}}
																	/>
																)}
															</div>
														);
													}
												}
											]}
											LoadingComponent={() => (
												<div className="Common__spinnerdiv--center">
													<Spinners loading={this.state.loading} type="tail-fade" size="120px" />
												</div>
											)}
											NoDataComponent={() => <NoDataComponent loading={this.state.loading} />}
											PreviousComponent={props => (
												<button {...props} className="btn btn-default btn-outline btn-sm pagination-item">
													<Icon.ChevronLeft style={{ paddingTop: "4px" }} size="20" />
												</button>
											)}
											NextComponent={props => (
												<button {...props} className="btn btn-default btn-outline btn-sm pagination-item">
													<Icon.ChevronRight style={{ paddingTop: "4px" }} size="20" />
												</button>
											)}
											showPagination={this.state.leads.length > 10}
											defaultPageSize={10}
											className="-striped -highlight"
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(CompareToolLeads);
