export const CONTACT_COLUMNS = {
	contactSelect: {
		id: "contactSelect",
		value: "",
		sortable: false,
		width: "none",
		notClickable: true
	},
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 2
	},
	phone: {
		id: "phone",
		value: "Phone",
		sortable: true,
		sortField: "phone",
		width: 2,
		widthSm: 0
	},
	email: {
		id: "email",
		value: "Email",
		sortable: true,
		sortField: "email",
		width: 1,
		widthSm: 0
	},
	status: {
		id: "status",
		value: "Status",
		sortable: true,
		sortField: "status",
		width: 1,
		widthMd: 0
	},
	is_blocked: {
		id: "is_blocked",
		value: "Blocked",
		sortable: true,
		sortField: "is_blocked",
		width: 1,
		widthSm: 0
	},
	tags: {
		id: "tags",
		value: "Tags",
		sortable: false,
		sortField: "tags",
		width: 2,
		widthMd: 0
	},
	groups: {
		id: "groups",
		value: "Groups",
		sortable: false,
		sortField: "groups",
		width: 2,
		widthMd: 0
	},
	last_message_sent: {
		id: "last_message_sent",
		value: "Last Message Sent/Received",
		sortable: true,
		sortField: "last_message_sent",
		width: 2,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const SELECTED_CONTACTS_COLUMNS = {
	contactSelect: {
		id: "contactSelect",
		value: "",
		sortable: false,
		width: "none",
		notClickable: true
	},
	name: {
		id: "name",
		value: "Name",
		sortable: false,
		sortField: "name",
		width: 2
	},
	phone: {
		id: "phone",
		value: "Phone",
		sortable: false,
		sortField: "phone",
		width: 2,
		widthSm: 0
	},
	email: {
		id: "email",
		value: "Email",
		sortable: false,
		sortField: "email",
		width: 1,
		widthSm: 0
	},
	status: {
		id: "status",
		value: "Status",
		sortable: false,
		sortField: "status",
		width: 1,
		widthMd: 0,
		widthSm: 0
	},
	is_blocked: {
		id: "is_blocked",
		value: "Blocked",
		sortable: false,
		sortField: "is_blocked",
		width: 1,
		widthSm: 0
	},
	tags: {
		id: "tags",
		value: "Tags",
		sortable: false,
		sortField: "tags",
		width: 2,
		widthMd: 0
	},
	groups: {
		id: "groups",
		value: "Groups",
		sortable: false,
		sortField: "groups",
		width: 2,
		widthMd: 0
	},
	last_message_sent: {
		id: "last_message_sent",
		value: "Last Message Sent/Received",
		sortable: false,
		sortField: "last_message_sent",
		width: 2,
		widthMd: 0
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const CONTACT_STATES = {
	active: "active",
	inactive: "inactive",
	deleted: "deleted"
};

export const CONTACT_STATES_OPTIONS = [
	{ value: "all", label: "Show All" },
	{ value: CONTACT_STATES.active, label: "Open" },
	{ value: CONTACT_STATES.inactive, label: "Closed" }
];

export const CONTACT_BLOCK_OPTIONS = [
	{ value: "all", label: "Show All" },
	{ value: "blocked", label: "Blocked" },
	{ value: "not_blocked", label: "Not Blocked" }
];

export const CONTACT_TABS = {
	contacts: {
		id: "contacts",
		value: "Contacts",
		route: "/contacts/selector"
	},
	groups: {
		id: "groups",
		value: "Groups",
		route: "/contacts/tags"
	},
	tags: {
		id: "tags",
		value: "Tags",
		route: "/contacts/groups"
	},
	customFields: {
		id: "customFields",
		value: "Custom Fields",
		route: "/contacts/custom-fields"
	}
};

export const CONTACT_SELECTOR_FILTERS = {
	all: {
		id: "all",
		display: "All",
		order: 1
	},
	selectedContacts: {
		id: "selectedContacts",
		display: "Selected Contacts",
		order: 2
	}
};

export const EDIT_CONTACT_TABS = {
	basics: "basics",
	advanced: "advanced",
	notes: "notes",
	actions: "actions",
	communication: "communication",
	appointments: "appointments",
	media: "media"
};

export const EDIT_CONTACT_ADD_NEW_MEDIUM_ERRORS = {
	email: {
		invalid: "Email is invalid.",
		invalidFormat: "Invalid email format.",
		alreadyExists: "Email belongs to another contact."
	},
	phone: {
		invalid: "Phone is invalid.",
		invalidFormat: "Invalid phone format.",
		alreadyExists: "Phone belongs to another contact."
	}
};

export const EDIT_CONTACT_APPOINTMENTS_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 2
	},
	state: {
		id: "state",
		value: "State",
		sortable: true,
		sortField: "state",
		width: 1
	},
	booking_at: {
		id: "booking_at",
		value: "Booking At",
		sortable: true,
		sortField: "booking_at",
		width: 2
	}
};

export const CONTACT_ANALYTICS_TABS = {
	leadSource: {
		id: "leadSource",
		value: "Lead Source"
	},
	subscriber: {
		id: "subscriber",
		value: "Subscriber"
	}
};

export const MERGE_CONTACT_WIZARD_STEPS = {
	findContact: {
		step: 1,
		header: "Find Contact"
	},
	findSecondContact: {
		step: 2,
		header: "Find A Second Contact To Merge With"
	},
	mergeContact: {
		step: 3,
		header: "Merge Contact"
	},
	confirmDetails: {
		step: 4,
		header: "Confirm Details"
	}
};

export const MERGE_CONTACT_FIELDS = [
	"name",
	"phone",
	"email",
	"preferred_medium",
	"address_1",
	"address_2",
	"city",
	"state_prov",
	"country",
	"zip_postal",
	"dob",
	"language",
	"receive_marketing_emails",
	"receive_transactional_emails",
	"receive_feedback_emails",
	"receive_marketing_sms",
	"receive_transactional_sms",
	"receive_feedback_sms",
	"assigned_rep",
	"lead_source"
];

export const MERGE_CONTACT_FIELDS_LABELS = {
	name: "Name",
	phone: "Phone",
	email: "email",
	preferred_medium: "Preferred Medium",
	address_1: "Address 1",
	address_2: "Address 2",
	city: "City",
	state_prov: "State/Province",
	country: "Country",
	zip_postal: "Zip/Postal Code",
	dob: "Date Of Birth",
	language: "Language",
	receive_marketing_emails: "Receive Marketing Emails",
	receive_transactional_emails: "Receive Transactional Emails",
	receive_feedback_emails: "Receive Feedback Emails",
	receive_marketing_sms: "Receive Marketing Sms",
	receive_transactional_sms: "Receive Transactional Sms",
	receive_feedback_sms: "Receive Feedback Sms",
	assigned_rep: "Assigned Rep",
	lead_source: "Lead Source"
};
