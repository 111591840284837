import React from "react";
import ContentLoader from "react-content-loader";
import { withTranslation } from "react-i18next";

import ContactService from "../../services/ContactService";
import ToastService from "../../services/ToastService";

import Modal from "../../components/common/DHModal";

import { MERGE_CONTACT_WIZARD_STEPS, MERGE_CONTACT_FIELDS, MERGE_CONTACT_FIELDS_LABELS } from "../../constants/Contacts";
import ContactSearch from "./ContactSearch";
import Checkbox from "./Checkbox";

import "../../styles/css/components/commons/merge-contact-modal.css";

class MergeContactModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			firstContact: null,
			secondContact: null,
			fieldsFromSecondContact: [],
			wizardStep: MERGE_CONTACT_WIZARD_STEPS.findContact,
			mergeContactLoading: false
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	resetComponent = async () => {
		let { contactId, t } = this.props;

		await this.update({
			firstContact: null,
			wizardStep: MERGE_CONTACT_WIZARD_STEPS.findContact,
			secondContact: null,
			mergeContactLoading: false
		});

		let contact = await ContactService.getContact(contactId);

		if (!contact) {
			ToastService.error(t("Error fetching contact. Please try again."));
			return;
		}

		await this.update({
			firstContact: contact,
			wizardStep: MERGE_CONTACT_WIZARD_STEPS.findSecondContact
		});
	};

	onHide = () => {
		let { mergeContactLoading } = this.state;

		if (mergeContactLoading) {
			return;
		}

		let { onHide } = this.props;

		this.resetComponent();

		if (onHide) {
			onHide();
		}
	};

	onFirstContactSelected = conversation => {
		if (!conversation) {
			return;
		}

		this.update({ firstContact: conversation.Contact, wizardStep: MERGE_CONTACT_WIZARD_STEPS.findSecondContact });
	};

	onSecondContactSelected = async conversation => {
		let { t } = this.props;

		if (!conversation) {
			return;
		}

		let { firstContact } = this.state;

		if (firstContact.id === conversation.Contact.id) {
			ToastService.info(t("{{name}} is already selected.", { name: firstContact.name }));
			return;
		}

		await this.update({ secondContact: conversation.Contact, wizardStep: MERGE_CONTACT_WIZARD_STEPS.mergeContact });

		this.determineFieldsFromSecondContact();
	};

	determineFieldsFromSecondContact = async () => {
		let { firstContact, secondContact } = this.state;

		let fields = [];

		for (const field of MERGE_CONTACT_FIELDS) {
			if (!firstContact[field] && secondContact[field]) {
				fields.push(field);
			}
		}

		await this.update({
			fieldsFromSecondContact: fields
		});
	};

	onCheckboxChange = (checked, field) => {
		let { fieldsFromSecondContact } = this.state;

		let index = fieldsFromSecondContact.indexOf(field);

		if (index >= 0) {
			fieldsFromSecondContact.splice(index, 1);
		} else {
			fieldsFromSecondContact.push(field);
		}

		this.update({
			fieldsFromSecondContact
		});
	};

	onBackFromSecondContactClicked = async () => {
		await this.update({ secondContact: null, wizardStep: MERGE_CONTACT_WIZARD_STEPS.findContact });
	};

	onMergeContactClicked = async () => {
		if (this.isMergeDisabled()) {
			return;
		}

		await this.update({ wizardStep: MERGE_CONTACT_WIZARD_STEPS.confirmDetails });
	};

	onBackFromMergeContactClicked = async () => {
		await this.update({ secondContact: null, wizardStep: MERGE_CONTACT_WIZARD_STEPS.findSecondContact });
	};

	onBackFromConfirmClicked = async () => {
		await this.update({ wizardStep: MERGE_CONTACT_WIZARD_STEPS.mergeContact });
	};

	onSubmitClicked = async () => {
		let { t } = this.props;
		let { firstContact, secondContact, fieldsFromSecondContact } = this.state;

		if (this.isMergeDisabled()) {
			return;
		}

		await this.update({
			mergeContactLoading: true
		});

		let contact = await ContactService.mergeContact({ contactId: firstContact.id, secondaryContactId: secondContact.id, fields: fieldsFromSecondContact });

		if (!contact) {
			ToastService.error(t("An error occurred trying to merge. Please try again."));
		} else {
			ToastService.info(t("Contact merged!"));
		}

		await this.update({
			mergeContactLoading: false
		});

		let { onMerged } = this.props;

		if (onMerged) {
			onMerged();
		}
	};

	isMergeDisabled = () => {
		// Ensure that the selected phone and emails feilds are not both empty
		let { firstContact, secondContact, fieldsFromSecondContact } = this.state;

		/**
		 * Ensure that at least a phone or email is filled in
		 */
		let isEmptyPhone = true;
		if (fieldsFromSecondContact.includes("phone")) {
			if (secondContact.phone) {
				isEmptyPhone = false;
			}
		} else if (firstContact.phone) {
			isEmptyPhone = false;
		}

		let isEmptyEmail = true;
		if (fieldsFromSecondContact.includes("email")) {
			if (secondContact.email) {
				isEmptyEmail = false;
			}
		} else if (firstContact.email) {
			isEmptyEmail = false;
		}

		if (isEmptyEmail && isEmptyPhone) {
			return true;
		}

		/**
		 * Ensure that the preferred medium will still have a filled in value
		 */

		if (fieldsFromSecondContact.includes("preferred_medium")) {
			if (secondContact.preferred_medium === "sms" && isEmptyPhone) {
				return true;
			} else if (secondContact.preferred_medium === "email" && isEmptyEmail) {
				return true;
			}
		} else {
			if (firstContact.preferred_medium === "sms" && isEmptyPhone) {
				return true;
			} else if (firstContact.preferred_medium === "email" && isEmptyEmail) {
				return true;
			}
		}

		return false;
	};

	renderTitle = () => {
		let { wizardStep } = this.state;

		return wizardStep.header;
	};

	renderFindContact = () => {
		return <ContactSearch onConversationSelected={this.onFirstContactSelected} disableCreateContact={true} />;
	};

	renderFindSecondContact = () => {
		let { contactId, t } = this.props;

		return (
			<>
				<ContactSearch onConversationSelected={this.onSecondContactSelected} disableCreateContact={true} />
				{!contactId && (
					<div className="modal__actions modal__actions--space-between">
						<div className="mb-button" onClick={this.onBackFromSecondContactClicked}>
							{t("Back")}
						</div>
					</div>
				)}
			</>
		);
	};

	renderText(value) {
		let { t } = this.props;
		if (typeof value === "undefined" || value === "") {
			return "";
		}

		if (typeof value === "boolean") {
			return value ? t("Yes") : t("No");
		}

		return value;
	}

	renderMergeContact = () => {
		let { firstContact, secondContact, fieldsFromSecondContact } = this.state;
		let { t } = this.props;

		return (
			<>
				<div className="mcm__description">{t("Choose data from the fields to transfer fields from the second contact to the first contact.")}</div>
				<div className="mcm__description">{t("The second contact will be deleted.")}</div>
				<div className="mcm__merge-list">
					<div className="mcm__merge-list__item">
						<div className="mcm__merge-list__item__field">{t("Field")}</div>
						<div className="mcm__merge-list__item__value">
							<div className="mcm__merge-list__item__value__text">{t("Contact 1")}</div>
						</div>
						<div className="mcm__merge-list__item__value">
							<div className="mcm__merge-list__item__value__text">{t("Contact 2")}</div>
						</div>
					</div>
					{MERGE_CONTACT_FIELDS.map((field, index) => (
						<div key={field} className="mcm__merge-list__item">
							<div className="mcm__merge-list__item__field dh-tip" tip={MERGE_CONTACT_FIELDS_LABELS[field] || field}>
								{MERGE_CONTACT_FIELDS_LABELS[field] || field}
							</div>
							<div className="mcm__merge-list__item__value">
								<div>
									<Checkbox
										id={`${field}_${index}_a`}
										checked={!fieldsFromSecondContact.includes(field)}
										onChange={event => this.onCheckboxChange(event.target.checked, field)}
									/>
								</div>
								<div className="mcm__merge-list__item__value__text dh-tip" tip={this.renderText(firstContact[field])}>
									{this.renderText(firstContact[field])}
								</div>
							</div>
							<div className="mcm__merge-list__item__value">
								<div>
									<Checkbox
										id={`${field}_${index}_b`}
										checked={fieldsFromSecondContact.includes(field)}
										onChange={event => this.onCheckboxChange(event.target.checked, field)}
									/>
								</div>

								<div className="mcm__merge-list__item__value__text dh-tip" tip={this.renderText(secondContact[field])}>
									{this.renderText(secondContact[field])}
								</div>
							</div>
						</div>
					))}
				</div>
				<div className="modal__actions modal__actions--space-between">
					<div className="mb-button" onClick={this.onBackFromMergeContactClicked}>
						{t("Back")}
					</div>
					<div className={`mb-button ${this.isMergeDisabled() ? "mb-button--disabled" : ""}`} onClick={this.onMergeContactClicked}>
						{t("Next")}
					</div>
				</div>
			</>
		);
	};

	renderConfirmDetails = () => {
		let { t } = this.props;
		let { firstContact, secondContact, fieldsFromSecondContact, mergeContactLoading } = this.state;

		if (mergeContactLoading) {
			return (
				<>
					<div className="mcm__description">{t("Choose data from the fields to transfer fields from the second contact to the first contact.")}</div>
					<div className="mcm__description">{t("The second contact will be deleted.")}</div>
					<ContentLoader height={"620"} width={"100%"}>
						<rect x="0" y="10" rx="5" ry="5" width="100%" height="100" />
						<rect x="0" y="130" rx="5" ry="5" width="100%" height="100" />
						<rect x="0" y="245" rx="5" ry="5" width="100%" height="100" />
						<rect x="0" y="360" rx="5" ry="5" width="100%" height="100" />
					</ContentLoader>
				</>
			);
		}

		return (
			<>
				<div className="mcm__merge-list">
					{MERGE_CONTACT_FIELDS.map((field, index) => (
						<div key={field} className="mcm__merge-list__item">
							<div className="mcm__merge-list__item__field dh-tip" tip={MERGE_CONTACT_FIELDS_LABELS[field] || field}>
								{MERGE_CONTACT_FIELDS_LABELS[field] || field}
							</div>
							<div className="mcm__merge-list__item__value">
								<div
									className={`mcm__merge-list__item__value__text ${
										fieldsFromSecondContact.includes(field) ? "mcm__merge-list__item__value__text--change" : ""
									} dh-tip`}
									tip={this.renderText(fieldsFromSecondContact.includes(field) ? secondContact[field] : firstContact[field])}
								>
									{this.renderText(fieldsFromSecondContact.includes(field) ? secondContact[field] : firstContact[field])}
								</div>
							</div>
						</div>
					))}
				</div>
				<div className="modal__actions modal__actions--space-between">
					<div className="mb-button" onClick={this.onBackFromConfirmClicked}>
						{t("Back")}
					</div>
					<div className="mb-button" onClick={this.onSubmitClicked}>
						{t("Merge Contact")}
					</div>
				</div>
			</>
		);
	};

	renderBody = () => {
		let { wizardStep } = this.state;

		if (wizardStep.step === MERGE_CONTACT_WIZARD_STEPS.findContact.step) {
			return this.renderFindContact();
		} else if (wizardStep.step === MERGE_CONTACT_WIZARD_STEPS.findSecondContact.step) {
			return this.renderFindSecondContact();
		} else if (wizardStep.step === MERGE_CONTACT_WIZARD_STEPS.mergeContact.step) {
			return this.renderMergeContact();
		} else if (wizardStep.step === MERGE_CONTACT_WIZARD_STEPS.confirmDetails.step) {
			return this.renderConfirmDetails();
		}

		return null;
	};

	render = () => {
		let { show } = this.props;

		return (
			<Modal show={show} onHide={this.onHide} title={this.renderTitle()} className="mcm-modal">
				<div className="mcm">{this.renderBody()}</div>
			</Modal>
		);
	};
}

export default withTranslation(null, { withRef: true })(MergeContactModal);
