export const AnalyticsConstants = {
	APP_NAME: {
		webapp: "webapp",
		platform: "platform",
		shareComments: "share_comments",
		webchat: "webchat",
		reviewsWidget: "reviews_widget",
		workers: "workers",
		dailyDigestReports: "daily_digest_reports",
		companyReports: "company_reports",
		locationReports: "location_reports",
		contactAssignedReports: "contact_assigned_reports",
		newMessageReports: "new_message_reports",
		emailCustomerMessageReports: "email_customer_message_reports",
		bookingWidget: "booking_widget"
	},
	EVENT_NAME: {
		webchatOpened: "webchat_opened",
		webchatLoaded: "webchat_loaded",
		webchatNotLoaded: "webchat_not_loaded",
		webchatMessageSent: "webchat_message_sent",
		reviewsWidgetLoaded: "reviews_widget_loaded",
		reviewsWidgetNotLoaded: "reviews_widget_not_loaded",
		reviewsWidgetOpened: "reviews_widget_opened",
		reviewsWidgetClosed: "reviews_widget_closed",
		reviewsWidgetAddReviewClicked: "reviews_widget_add_review_clicked",
		gmbConnectionActive: "gmb_connection_active",
		gmbConnectionError: "gmb_connection_error",
		fbConnectionActive: "fb_connection_active",
		fbConnectionError: "fb_connection_error",
		conversationStart: "conversation_start",
		conversationFirstResponse: "conversation_first_response",
		reportClicked: "report_clicked",
		reportOpened: "report_opened",
		keywordTriggered: "keyword_triggered",
		userAutoAssignedToContact: "user_auto_assigned_to_contact",
		userTerminatedAutoAssignment: "user_terminated_auto_assignment",
		bookingWidgetOpened: "booking_widget_opened",
		bookingWidgetClosed: "booking_widget_closed",
		bookingWidgetSubmitted: "booking_widget_submitted"
	}
};

export const OVERALL_SNAPSHOT_COLUMNS = {
	name: {
		id: "name",
		value: "Location",
		sortable: true,
		sortField: "name",
		width: 3
	},
	rating: {
		id: "rating",
		value: "Rating",
		sortable: true,
		sortField: "rating",
		width: 1
	},
	reviews: {
		id: "reviews",
		value: "Reviews",
		sortable: true,
		sortField: "reviews",
		width: 1
	},
	invites_sent: {
		id: "invites_sent",
		value: "Invites Sent",
		sortable: true,
		sortField: "invites_sent",
		width: 1
	},
	invites_clicked: {
		id: "invites_clicked",
		value: "Invites Clicked",
		sortable: true,
		sortField: "invites_clicked",
		width: 1
	},
	ctr: {
		id: "ctr",
		value: "CTR",
		sortable: true,
		sortField: "ctr",
		width: 1
	},
	reviews_received: {
		id: "reviews_received",
		value: "Reviews Received",
		sortable: true,
		sortField: "reviews_received",
		width: 1
	},
	conversion_rate: {
		id: "conversion_rate",
		value: "Conversion Rate",
		sortable: true,
		sortField: "conversion_rate",
		width: 1
	}
};

export const FAILED_MESSAGES_COLUMNS = {
	contact_name: {
		id: "contact_name",
		value: "Contact Name",
		sortable: true,
		sortField: "contact_name",
		width: 1
	},
	contact_phone: {
		id: "contact_phone",
		value: "To Phone",
		sortable: true,
		sortField: "contact_phone",
		width: 1
	},
	content: {
		id: "content",
		value: "Message",
		sortable: true,
		sortField: "content",
		width: 1
	},
	sent_at: {
		id: "sent_at",
		value: "Sent At",
		sortable: true,
		sortField: "sent_at",
		width: 1
	},
	sender_user_name: {
		id: "sender_user_name",
		value: "Sent By",
		sortable: true,
		sortField: "sender_user_name",
		width: 1
	},
	tags: {
		id: "tags",
		value: "Tags",
		sortable: true,
		sortField: "tags",
		width: 1
	}
};
