import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import UserService from "../../services/UserService";
import ReviewSiteService from "../../services/ReviewSiteService";
import UtilityService from "../../services/UtilityService";
import GmbService from "../../services/GmbService";
import { AnalyticsService } from "../../services/AnalyticsService";

import withLocation from "../../components/common/WithLocation";

import { REVIEW_SITE_IDS_BY_NAME, REVIEW_SITES } from "../../constants/ReviewSitesConstants";

import "../../styles/css/scenes/reviews-summary.css";

class ReviewSummary extends Component {
	state = { reviewsSummary: [], gmbActive: false, fbActive: false, loading: true };

	componentDidMount() {
		this.fetchData();
	}

	onLocationChanged = () => {
		this.fetchData();
	};

	fetchData = async () => {
		let { isEnabled, isPermissible } = this.props;
		this.setState({ loading: true });

		if (isEnabled && isEnabled() && isPermissible && isPermissible()) {
			await this.fetchReviewSummary();
			await this.fetchConnectionSatus();
		}

		this.setState({ loading: false });
	};

	fetchConnectionSatus = async () => {
		const locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
		let data = await GmbService.checkGmbAndFbStatus(locationId);
		if (!data) {
			this.setState({
				fbActive: false,
				gmbActive: false
			});
			return;
		}
		this.setState({
			fbActive: data.fb_active,
			gmbActive: data.gmb_active
		});
	};

	fetchReviewSummary = async () => {
		const locationId = UserService.getActiveLocation() ? UserService.getActiveLocation().id : "";
		let data = await AnalyticsService.getReviewsSummary(locationId);
		if (!data) {
			this.setState({ reviewsSummary: [] });
			return;
		}
		// Only show up to the first 3
		let reviewsSummary = data.slice(0, 3);

		this.setState({ reviewsSummary });
	};

	renderGoogleIsInactive = key => {
		let { isEnabled } = this.props;

		let isReviewsInvitesEnabled = isEnabled();

		return (
			<Link key={key} to="/settings/connections">
				<div className="reviews-summary__list__connect-google">
					<div className="reviews-summary__list__connect-google__data">
						{isReviewsInvitesEnabled && (
							<>
								<b>Google (Total Reviews: 113)</b>
								<br />
								<b>DemandHub Effect: </b> +100 Reviews
								<br />
								<b>Before DemandHub: </b> 13 Reviews
							</>
						)}
						<span className="pull-right">
							<b>
								4.6
								<i style={{ transform: "scale(1.2)" }} className="fa fa-star review-star-yellow" />
							</b>
						</span>
						<div className="progress progress-large">
							<div className={"progress-bar reviews-summary__list__connect-google__progress__bar" + ReviewSiteService.getReviewSiteStyleClass(2)} />
						</div>
					</div>
					<div className="reviews-summary__list-no-connection">
						<span>
							{" "}
							<span className="reviews-summary__list__connect-google-no-connection__icon">
								<Icon.AlertCircle color="red" size="20" />
							</span>{" "}
							Please connect to GMB now to view this data.
						</span>
					</div>
				</div>
			</Link>
		);
	};

	renderFacebookIsInactive = key => {
		let { isEnabled } = this.props;

		let isReviewsInvitesEnabled = isEnabled();

		return (
			<Link key={key} to="/settings/connections">
				<div className="reviews-summary__list__connect-facebook">
					<div className="reviews-summary__list__connect-facebook__data">
						{isReviewsInvitesEnabled && (
							<>
								<b>Facebook (Total Reviews: 166)</b>
								<br />
								<b>DemandHub Effect: </b> +141 Reviews
								<br />
								<b>Before DemandHub: </b> 25 Reviews
							</>
						)}
						<span className="pull-right">
							<b>
								4.9 <i style={{ transform: "scale(1.2)" }} className="fa fa-star review-star-yellow" />
							</b>
						</span>
						<div className="progress progress-large">
							<div className={"progress-bar reviews-summary__list__connect-facebook__progress__bar " + ReviewSiteService.getReviewSiteStyleClass(3)} />
						</div>
					</div>
					<div className="reviews-summary__list-no-connection">
						<span>
							{" "}
							<span className="reviews-summary__list__connect-facebook-no-connection__icon">
								<Icon.AlertCircle color="red" size="20" />
							</span>{" "}
							Please connect to Facebook now to view this data.
						</span>
					</div>
				</div>
			</Link>
		);
	};

	render() {
		const { reviewsSummary, loading } = this.state;
		const { cardName, isEnabled, isPermissible } = this.props;

		// If we don't have review invites enabled at a location level, then we should hide the demandhub effect
		let isReviewsInvitesEnabled = isEnabled();

		return (
			<>
				<div className="reviews-summary__header">{cardName ? cardName : "Review Summary"}</div>
				{!isPermissible() && (
					<>
						<br></br>
						<div>Review data is restricted.</div>
					</>
				)}
				<div className="reviews-summary__list">
					{reviewsSummary.map((aReviewSummary, key) => {
						if (aReviewSummary.name === REVIEW_SITES.google.name && !this.state.gmbActive && !loading) {
							return this.renderGoogleIsInactive(key);
						}

						if (aReviewSummary.name === REVIEW_SITES.facebook.name && !this.state.fbActive && !loading) {
							return this.renderFacebookIsInactive(key);
						}

						const effect = aReviewSummary.review_count - aReviewSummary.start_review_count;
						return (
							<div key={key}>
								<span className="text-capitalize">
									<b>
										{aReviewSummary.name} (Total Reviews: {aReviewSummary.review_count})
									</b>{" "}
									{REVIEW_SITE_IDS_BY_NAME.direct !== aReviewSummary.site_id && [
										<span key="span"> </span>,
										aReviewSummary.view_review_url && (
											<FontAwesomeIcon
												key="fa-icon"
												icon={faExternalLinkAlt}
												style={{ cursor: "pointer" }}
												onClick={() => {
													window.open(aReviewSummary.view_review_url);
												}}
											/>
										)
									]}
									{isReviewsInvitesEnabled && (
										<>
											<br />
											<b>DemandHub Effect: </b>
											{effect < 0 ? "-" + Math.abs(effect) : "+" + effect} Reviews{" "}
											<FontAwesomeIcon data-tip data-for="demandhub effect" icon={faInfoCircle} className="text-success" />
											<ReactTooltip id="demandhub effect" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="top">
												Reviews received after joining DemandHub
											</ReactTooltip>
											<br />
											<b>Before DemandHub: </b>
											{aReviewSummary.start_review_count} Reviews
										</>
									)}
								</span>
								<span className="pull-right">
									<b>
										{aReviewSummary.avg_rating.toFixed(1)} <i style={{ transform: "scale(1.2)" }} className="fa fa-star review-star-yellow" />
									</b>
								</span>
								<div className="progress progress-large">
									<div
										style={{ width: aReviewSummary.avg_rating * 2 * 10 + "%" }}
										className={"progress-bar " + ReviewSiteService.getReviewSiteStyleClass(aReviewSummary.site_id)}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</>
		);
	}
}

export default withLocation(ReviewSummary);
