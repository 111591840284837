import React, { Component } from "react";
import * as Icon from "react-feather";
import ReactSwitch from "react-switch";
import { withTranslation } from "react-i18next";

import WorkflowService from "../../../services/WorkflowService";
import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import MessagesService from "../../../services/MessagesService";

import TextArea from "../../../components/common/TextArea";
import Modal from "../../../components/common/DHModal";
import AttachmentItem from "../../../components/common/AttachmentItem";

import { STATUS_FILTER } from "../../../constants/KeywordsConstants";
import { MODE, MEDIUM } from "../../../constants/Messenger";

import "../../../styles/css/scenes/keywords.css";

class ManageKeywords extends Component {
	constructor(props) {
		super(props);

		this.state = {
			workflow: null,
			loading: false,
			saving: false,
			keywordInput: "",
			response: "",

			attachmentsChanged: false,
			mediaIds: []
		};
	}

	componentDidUpdate = prevProps => {
		if (prevProps.workflowId !== this.props.workflowId) {
			this.fetchWorkFlow();
		}
	};

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	fetchWorkFlow = async () => {
		if (this.isCreateMode()) {
			await this.update({
				workflow: {
					conditions: [{ params: { words: [] } }]
				},
				response: "",
				attachmentsChanged: false,
				mediaIds: []
			});
			return;
		}

		await this.update({ loading: true });

		let workflow = await WorkflowService.fetchKeyword({ workflowId: this.props.workflowId });
		if (!workflow) {
			return;
		}

		let mediaIds = [];

		// For now we only have the ability to send one attachment at a time
		let media = null;

		// If this scheduled message has any media attachments
		if (workflow.MessageTemplate && workflow.MessageTemplate.Media && workflow.MessageTemplate.Media.length > 0) {
			media = workflow.MessageTemplate.Media[0];
		}

		if (media) {
			// For now, we only allow one media
			mediaIds = [workflow.MessageTemplate.Media[0].id];
		}

		await this.update({
			workflow,
			loading: false,
			response: workflow.MessageTemplate.msg_text,
			mediaIds,
			attachmentsChanged: false
		});
	};

	isCreateMode = () => {
		const { workflowId } = this.props;
		return workflowId === "create";
	};

	onInputChange = event => {
		let value = event.target.value;

		if (/[\s]+/g.test(value)) {
			return;
		}

		this.setState({
			keywordInput: value.toLowerCase()
		});
	};

	handleGenericEventHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	ableToAddWord = () => {
		let { workflow, keywordInput } = this.state;
		let { wordLimit } = this.props;

		let limit = wordLimit || 1;
		return workflow.conditions[0].params.words.length < limit && keywordInput.length > 0;
	};

	addWord = () => {
		let { workflow, keywordInput } = this.state;

		if (!workflow.conditions[0].params) {
			workflow.conditions[0].params = {};
		}

		if (!workflow.conditions[0].params.words) {
			workflow.conditions[0].params.words = [];
		}

		if (!this.ableToAddWord()) {
			return;
		}

		workflow.conditions[0].params.words.unshift(keywordInput);
		this.setState({ keywordInput: "", workflow });
	};

	removeWord = index => {
		let { workflow } = this.state;

		workflow.conditions[0].params.words.splice(index, 1);
		this.setState({ workflow });
	};

	onStatusChange = value => {
		if (this.isCreateMode()) {
			return;
		}
		let { workflow } = this.state;
		workflow.status = value ? STATUS_FILTER.active.id : STATUS_FILTER.inactive.id;
		this.setState({ workflow });
	};

	onFileAdded = async files => {
		let file = files[0];
		let localMediaId = MessagesService.storeLocalMedia({ file }).id;
		let { mediaIds } = this.state;

		// For now, we only support one media attachment
		mediaIds = [];
		mediaIds.push(localMediaId);

		await this.update({
			mediaIds,
			attachmentsChanged: true
		});
	};

	onHideAttachment = async () => {
		await this.update({
			attachmentsChanged: true,
			mediaIds: []
		});
	};

	isSaveDisabled = () => {
		let { workflow, response, mediaIds, saving } = this.state;

		if (saving) {
			return true;
		}

		if (!workflow.conditions[0].params.words || workflow.conditions[0].params.words.length < 1) {
			return true;
		}

		if ((!response || response.trim().length < 1) && (!mediaIds || mediaIds.length < 1)) {
			return true;
		}
		return false;
	};

	onSave = async () => {
		let { workflow, response, mediaIds, attachmentsChanged } = this.state;

		if (this.isSaveDisabled()) {
			return;
		}

		await this.update({ saving: true });

		let locationId = UserService.getActiveLocation().id;
		let companyId = UserService.getActiveCompany().id;

		let newMediaIds = [];
		if (attachmentsChanged && mediaIds) {
			// For now, we only support 1 media
			let uploadedMedia = await MessagesService.uploadLocalMedia({
				mediaIds: mediaIds,
				mode: MODE.customer,
				medium: MEDIUM.sms.key,
				limit: 1,
				companyId,
				locationId
			});
			if (!uploadedMedia) {
				ToastService.error("Error uploading media.");
				await this.update({ saving: false });
				return;
			}
			newMediaIds = uploadedMedia.map(m => m.id);
			MessagesService.clearLocalMedia();

			mediaIds = newMediaIds;
		}

		if (this.isCreateMode()) {
			let locationId = UserService.getActiveLocation().id;

			let saved = await WorkflowService.createKeyword({ locationId, words: workflow.conditions[0].params.words, responseText: response, mediaIds });
			if (!saved) {
				ToastService.error(`Error creating Keyword`);
				return;
			}

			this.setState({
				keywordInput: "",
				response: "",
				workflow: {
					conditions: [{ params: { words: [] } }]
				},
				saving: false,
				attachmentsChanged: false,
				mediaIds
			});

			ToastService.info(`Keyword created`);

			if (this.props.onUpdate) {
				this.props.onUpdate();
			}
			return;
		}

		let saved = await WorkflowService.updateKeyword({
			workflowId: workflow.id,
			words: workflow.conditions[0].params.words,
			responseText: response,
			status: workflow.status,
			mediaIds
		});

		await this.update({ saving: false, attachmentsChanged: false, mediaIds });

		if (!saved) {
			ToastService.error(`Error saving Keyword`);
			return;
		}

		ToastService.info(`Keyword saved`);

		if (this.props.onUpdate) {
			this.props.onUpdate();
		}
	};

	renderBody = () => {
		let { workflow, response, keywordInput, saving, mediaIds } = this.state;
		const { t } = this.props;

		if (!workflow) {
			return null;
		}

		return (
			<div className="keywords-modal__body">
				<div className="keywords-modal__input">
					<input className="Common__input" placeholder={t("Keyword ...")} value={keywordInput} onChange={this.onInputChange} />
					{this.ableToAddWord() && (
						<div className={`mb-button`} onClick={this.addWord}>
							{t("Add")}
						</div>
					)}
				</div>
				<div className="keywords-modal__body__words">
					{workflow.conditions[0].params.words.map((word, index) => (
						<div key={index} className="keywords-modal__body__word">
							<div className="keywords-modal__body__word__text">{word}</div>
							<Icon.X onClick={() => this.removeWord(index)} />
						</div>
					))}
				</div>
				<div className="keywords-modal__body__response">
					<label>{t("Response")}</label>
					<TextArea
						id="response"
						name="response"
						type="text"
						value={response}
						onChange={e => this.handleGenericEventHandler(e)}
						style={{ resize: "none" }}
						placeholder={t("A response for the incoming keyword")}
						blueBorder={true}
						showFooter={true}
						showMessageTemplates={false}
						attachments={true}
						onFileAdded={this.onFileAdded}
					/>
				</div>

				{mediaIds && mediaIds.length > 0 && (
					<div className="modal__attachments">
						<div className="modal__field">{t("Attachment")}</div>
						{mediaIds.map(mediaId => (
							<AttachmentItem key={mediaId} mediaId={mediaId} hide={this.onHideAttachment} />
						))}
					</div>
				)}

				{!this.isCreateMode() && workflow && (
					<div className="keywords-modal__body__status">
						<div className="keywords-modal__body__status__text">{workflow.status === STATUS_FILTER.active.id ? t("On") : t("Off")}</div>
						<div>
							<ReactSwitch
								id="status"
								onChange={this.onStatusChange}
								checked={workflow.status === STATUS_FILTER.active.id ? true : false}
								uncheckedIcon={false}
								checkedIcon={false}
								onColor="#4A90E2"
							/>
						</div>
					</div>
				)}
				<div className="keywords-modal__body__save">
					<div className={`mb-button ${this.isSaveDisabled() ? "mb-button--disabled" : ""}`} onClick={this.onSave}>
						{saving ? t("Saving...") : t("Save")}
					</div>
				</div>
			</div>
		);
	};

	render() {
		const { show, onCancel, t } = this.props;
		const { loading } = this.state;

		return (
			<Modal show={show} title={this.isCreateMode() ? t("Create Keyword") : t("Update Keyword")} onHide={onCancel}>
				<div className="modal__flex-container">
					{loading && <div className="text-center">{t("loading ...")}</div>}
					{!loading && this.renderBody()}
				</div>
			</Modal>
		);
	}
}

export default withTranslation(null, { withRef: true })(ManageKeywords);
