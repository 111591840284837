import React, { PureComponent } from "react";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";

import Action from "./Action";
import Select from "./DHSelect";

import { PAGE_SIZE_OPTIONS } from "../../constants/CommonConstants";

class ListPaginationHeader extends PureComponent {
	render() {
		let { id, pages, currentPage, totalCount, limit, onPaginateChange, onLimitChange } = this.props;

		let page = currentPage;

		if (!page) {
			page = 0;
		}

		page = currentPage + 1;

		if (!pages) {
			pages = 0;
		}

		if (pages === 0) {
			page = 1;
			pages = 1;
		}

		return (
			<div className="dh-list-pagination-header">
				<div className="dh-list-pagination-header__limit-select">
					<Select
						id="limit-select"
						name="limit-select"
						value={limit}
						defaultValue={limit}
						options={Object.values(PAGE_SIZE_OPTIONS)}
						onChange={onLimitChange}
					/>
				</div>
				<div className="dh-list-pagination-header__page-count" data-tip={true} data-for={`dh-list-pagination-header-${id}__rtt`}>
					Page {page} of {pages}
				</div>
				<div className="dh-list-pagination-header__actions">
					<div className="dh-list-pagination-header__actions__previous">
						<Action
							id="previos-page"
							label={`Previous Page`}
							onClick={() => {
								if (currentPage < 1) {
									return;
								}
								onPaginateChange(currentPage - 1);
							}}
							icon={Icon.ChevronLeft}
							transparent={true}
							outline={true}
							disabled={currentPage < 1}
						/>
					</div>
					<div className="dh-list-pagination-header__actions__next">
						<Action
							id="next-page"
							label={`Next Page`}
							onClick={() => {
								if (currentPage >= pages - 1) {
									return;
								}
								onPaginateChange(currentPage + 1);
							}}
							icon={Icon.ChevronRight}
							transparent={true}
							outline={true}
							disabled={currentPage >= pages}
						/>
					</div>
				</div>

				<ReactTooltip
					id={`dh-list-pagination-header-${id}__rtt`}
					className="mb-react-tooltip text-left"
					arrowColor="#333"
					type="info"
					effect="solid"
					place="top"
				>
					Total of {totalCount} items in the list.
				</ReactTooltip>
			</div>
		);
	}
}

export default ListPaginationHeader;
