import UserService from "./UserService";
import Kichiri from "./KichiriService";
import LocationService from "./LocationService";
import { LOCATION_FEATURES } from "../constants/LocationConstants";

const InboxService = {
	/**
	 * Fetches a list of inboxes
	 *
	 * @param {Number} locationId
	 * @param {Number} userId
	 * @return {Promise}
	 */
	async fetchInboxes(locationId, userId) {
		try {
			let { auth_token } = UserService.get();
			let response = await Kichiri.inbox.fetchInboxes({}, { locationId, userId }, auth_token);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Fetches a single inbox
	 *
	 * @param {Number} inboxId
	 * @return {Promise}
	 */
	async fetchInbox(inboxId) {
		try {
			if (!inboxId) {
				return null;
			}

			let { auth_token } = UserService.get();
			let response = await Kichiri.inbox.fetchInbox({ inboxId }, {}, auth_token);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Updates an inbox with a set of users
	 *
	 * @param {Object} contactDetails
	 * @return {Promise}
	 */
	async updateInbox(inboxDetails) {
		try {
			let { auth_token } = UserService.get();

			let response = await Kichiri.inbox.update(inboxDetails, {}, auth_token);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Creates an inbox for a location
	 * @param {Number} locationId
	 * @param {String} name
	 * @param {String} type Inbox type
	 * @param {Boolean} isPublic
	 * @param {Boolean} autoDefaultAssignment
	 */
	async createInbox({ locationId, name, type, isPublic, autoDefaultAssignment }) {
		try {
			let { auth_token } = UserService.get();
			let response = await Kichiri.inbox.create({ locationId, name, type, is_public: isPublic, autoDefaultAssignment }, {}, auth_token);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Indicates whether or not the current user can move contacts from one inbox to the other
	 */
	canMoveContactToInbox() {
		try {
			let user = UserService.get();
			let location = UserService.getActiveLocation();

			let permissions = user.GroupPermission;
			let features = location.LocationFeature;

			if (!permissions || !features) {
				return false;
			}

			let { view_inboxes, update_inboxes, update_contacts } = permissions;
			let { messenger_smart_inboxes } = features;

			return view_inboxes && update_inboxes && update_contacts && messenger_smart_inboxes;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Indicates whether or not the Auto Assignment Feature is enabled
	 *
	 * @return {Boolean}
	 */
	isAutoAssignmentLocationFeatureEnabled() {
		return (
			LocationService.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_smart_inboxes_auto_assignment.id) &&
			LocationService.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_smart_inboxes.id)
		);
	},

	/*
	 * Check if a round robin is enabled for an inbox
	 * @param {Number} inboxId
	 */
	async isAutoAssignmentEnabled(inboxId) {
		try {
			let { auth_token } = UserService.get();
			let response = await Kichiri.inbox.fetchInbox({ inboxId }, {}, auth_token);
			let inbox = response.data;

			return this.isAutoAssignmentLocationFeatureEnabled() && inbox.auto_assignment_enabled;
		} catch (error) {
			console.log(error);
		}
		return false;
	},

	/**
	 * Fetches inbox metadata
	 *
	 * @return {Promise}
	 */
	async fetchMetadata() {
		try {
			let { auth_token } = UserService.get();
			let response = await Kichiri.inbox.fetchMetadata({}, {}, auth_token);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Delete inbox and assign those contact to a different inbox
	 * @param {Number} inboxId
	 * @param {Number} contactsToInboxId
	 * @returns
	 */
	async deleteInbox({ inboxId, contactsToInboxId }) {
		try {
			let { auth_token } = UserService.get();

			let response = await Kichiri.inbox.update({ inboxId, status: "deleted", contactsToInboxId }, {}, auth_token);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	}
};

export default InboxService;
