import React from "react";
import Tooltip from "react-tooltip";

const TooltipService = {
	/**
	 * Genetate an info tooltip
	 * @param {String} id
	 * @param {String} place
	 * @param {String} effect
	 * @param {String} content
	 * @param {String} className
	 * @returns {import("react").ReactElement}
	 */
	info(id, place, effect, content, className = "mb-react-tooltip") {
		return (
			<Tooltip id={id} className={className} type="info" effect={effect} place={place}>
				{content}
			</Tooltip>
		);
	}
};

export default TooltipService;
