import ConnectMindbodyModal from "../scenes/Locations/ConnectMindbodyModal";
import ConnectJaneappModal from "../scenes/Locations/ConnectJaneappModal";
import ConnectSimplePractice from "../scenes/Locations/ConnectSimplePractice";
import ConnectDrChronoModal from "../scenes/Locations/ConnectDrChronoModal";
import ConnectPTEverywhereModal from "../scenes/Locations/ConnectPTEverywhereModal";
import ConnectAthenaHealthModal from "../scenes/Locations/ConnectAthenaHealthModal";
import ConnectSalesforceModal from "../scenes/Locations/ConnectSalesforceModal";
import ConnectBookerModal from "../scenes/Locations/ConnectBookerModal";
import ConnectOdOfficeProModal from "../scenes/Locations/ConnectOdOfficeProModal";
import ConnectClioModal from "../scenes/Locations/ConnectClioModal";

import config from "../config/app/web-app.config";

export const CRM_INTEGRATIONS = {
	feeds: {
		sales: "sales",
		service: "service",
		customers: "customers"
	},
	type: {
		cdk: {
			name: "cdk",
			feeds: [
				{
					name: "Sales",
					id: "sales"
				},
				{
					name: "Service",
					id: "service"
				},
				{
					name: "Customers",
					id: "customers"
				}
			]
		},
		cdk180: {
			name: "cdk_180",
			feeds: [
				{
					name: "Sales",
					value: "sales"
				},
				{
					name: "Service",
					value: "service"
				}
			]
		},
		cdk_180_rest: {
			name: "cdk_180_rest",
			feeds: [
				{
					name: "Sales",
					id: "sales"
				}
			]
		},
		dealervault: {
			name: "dealervault",
			feeds: [
				{
					name: "Sales",
					value: "sales"
				},
				{
					name: "Service",
					value: "service"
				}
			]
		},
		demandhub: { name: "demandhub" },
		abeldent: { name: "abeldent" },
		opendental: { name: "opendental" },
		dentrix: { name: "dentrix" },
		tracker: { name: "tracker" },
		paradigm: { name: "paradigm" },
		eaglesoft: { name: "eaglesoft" },
		liveddm: { name: "liveddm" },
		adfEmail: { name: "adf_email" },
		dealertrack: { name: "dealertrack" },
		dealersocket: { name: "dealersocket" },
		asaTiremaster: "asa_tiremaster",
		bayiq: { name: "bayiq" },
		omnique: { name: "omnique" },
		shopboss: { name: "shopboss" },
		juvonno: { name: "juvonno" },
		cliniko: { name: "cliniko" },
		janeapp: { name: "janeapp", displayName: "Jane App", imgUrl: `${config.CDN_URL}web-app/assets/logos/janeapp-logo.svg` },
		mindbody: { name: "mindbody", displayName: "Mindbody", imgUrl: `${config.CDN_URL}web-app/assets/logos/mindbody-logo.svg` },
		simplepractice: { name: "simplepractice", displayName: "Simple Practice", imgUrl: `${config.CDN_URL}web-app/assets/logos/simplepractice-logo.svg` },
		dr_chrono: {
			name: "dr_chrono",
			displayName: "DrChrono",
			imgUrl: `${config.CDN_URL}web-app/assets/logos/dr-chrono-logo.svg`
		},
		pteverywhere: {
			name: "pteverywhere",
			displayName: "PtEverywhere",
			imgUrl: `${config.CDN_URL}web-app/assets/logos/pteverywhere-logo.png`
		},
		athena_health: {
			name: "athena_health",
			displayName: "AthenaHealth",
			imgUrl: `${config.CDN_URL}web-app/assets/logos/athena-health-logo.png`
		},
		salesforce: {
			name: "salesforce",
			displayName: "Salesforce",
			imgUrl: `${config.CDN_URL}web-app/assets/logos/salesforce.png`
		},
		booker: { name: "booker", displayName: "Booker", imgUrl: `${config.CDN_URL}web-app/assets/logos/booker-icon.png` },
		clio: { name: "clio", displayName: "Clio", imgUrl: `${config.CDN_URL}web-app/assets/logos/clio-logo.svg` },
		odofficepro: { name: "odofficepro", displayName: "OD Office Pro", imgUrl: `${config.CDN_URL}web-app/assets/logos/odofficepro.png` },
		noterro: { name: "noterro", displayName: "Noterro", imgUrl: `${config.CDN_URL}web-app/assets/logos/noterro-logo.png` }
	}
};

export const CRM_INTEGRATION_UPDATE_MODAL_TABS = {
	general: {
		id: "general",
		display: "General"
	},
	schedules: {
		id: "schedules",
		display: "Schedules"
	}
};

export const CRM_INTEGRATION_COLUMNS = {
	name: {
		id: "name",
		value: "Name",
		sortable: true,
		sortField: "name",
		width: 2
	},
	crm_type: {
		id: "crm_type",
		value: "CRM Type",
		sortable: true,
		sortField: "crm_type",
		width: 1
	},
	crm_version: {
		id: "crm_version",
		value: "Crm Version",
		sortable: true,
		sortField: "crm_version",
		width: 1
	},
	integration_type: {
		id: "integration_type",
		value: "Integration Type",
		sortable: true,
		sortField: "integration_type",
		width: 1
	},
	direction: {
		id: "direction",
		value: "Direction",
		sortable: true,
		sortField: "direction",
		width: 1
	},
	meta_data: {
		id: "meta_data",
		value: "Meta Data",
		sortable: true,
		sortField: "meta_data",
		width: 1
	},
	status: {
		id: "status",
		value: "Status",
		sortable: true,
		sortField: "status",
		width: 1
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		width: 1,
		notClickable: true
	}
};

export const CONNECTION_CARD_STATES = {
	active: {
		id: "active",
		name: "Online"
	},
	inactive: {
		id: "inactive",
		name: "Offline"
	},
	partiallyActive: {
		id: "partiallyActive",
		name: "Partially Online"
	}
};

export const CRM_LOGO_URL = "web-app/assets/integrations/";

export const CRM_COMPONENT_MAP = {
	mindbody: ConnectMindbodyModal,
	janeapp: ConnectJaneappModal,
	simplepractice: ConnectSimplePractice,
	dr_chrono: ConnectDrChronoModal,
	pteverywhere: ConnectPTEverywhereModal,
	athena_health: ConnectAthenaHealthModal,
	salesforce: ConnectSalesforceModal,
	booker: ConnectBookerModal,
	odofficepro: ConnectOdOfficeProModal,
	clio: ConnectClioModal
};
