import React, { Component } from "react";
import * as Icon from "react-feather";
import _ from "lodash";

import "../../styles/css/components/commons/search-input.css";

class SearchInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchInput: this.props.initValue ? this.props.initValue : "",
			showSearchIcon: typeof this.props.showSearchIcon !== "undefined" ? this.props.showSearchIcon : true,
			debounce: typeof this.props.debounce !== "undefined" ? this.props.debounce : true,
			overrideOnChange: typeof this.props.overrideOnChange !== "undefined" ? this.props.overrideOnChange : false
		};

		this.input = null;

		this.debouncedChanged = _.debounce(
			value => {
				this.props.onChange(value);
			},
			1000,
			{
				leading: typeof this.props.leading !== "undefined" ? this.props.leading : true,
				trailing: true
			}
		);
	}

	onClose = () => {
		this.setState({
			searchInput: ""
		});

		if (this.props.onChange) {
			this.props.onChange("");
		}
	};

	onChange = event => {
		let { overrideOnChange } = this.state;
		if (!overrideOnChange) {
			this.setState({
				searchInput: event.target.value
			});
		}

		if (this.props.onChange) {
			if (this.state.debounce) {
				this.debouncedChanged(event.target.value);
			} else {
				this.props.onChange(event.target.value);
			}
		}
	};

	setSearchInput = value => {
		this.setState({
			searchInput: value
		});
	};

	onKeyDown = e => {
		if (this.props.onKeyDown) {
			this.props.onKeyDown(e);
		}
	};

	focus() {
		if (this.input) {
			this.input.focus();
		}
	}

	render() {
		let { searchInput, showSearchIcon } = this.state;
		let { id, fullWidth } = this.props;
		return (
			<div className={`dh-search ${fullWidth ? "dh-search--full-width" : ""}`}>
				<input
					id={id}
					ref={ref => {
						this.input = ref;
					}}
					type="text"
					className="dh-search__input"
					placeholder={this.props.placeholder}
					value={searchInput}
					onChange={this.onChange}
					onKeyDown={this.onKeyDown}
				/>
				<div className="dh-search__close" onClick={this.onClose}>
					{searchInput.length > 0 && <Icon.X size={16} />}
					{searchInput.length < 1 && showSearchIcon && <Icon.Search size={16} />}
				</div>
			</div>
		);
	}
}

export default SearchInput;
