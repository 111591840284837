import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import posed from "react-pose";

import Kichiri from "../../services/KichiriService";
import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import NotificationService from "../../services/NotificationService";

import { ROUTES } from "../../constants/Settings";

import withLocation from "../../components/common/WithLocation";

import "../../scenes/MessengerBeta/Navbar/navbar.css";
import "../../styles/css/scenes/settings.css";
import LocationService from "../../services/LocationService";

const Container = posed.div({
	visible: {
		y: 0,
		opacity: 1
	},
	hidden: {
		y: 0,
		opacity: 0
	}
});

class Settings extends Component {
	constructor(props) {
		super(props);

		let selectedSetting = ROUTES.general.id;
		let routes = Object.keys(ROUTES);
		for (let i = 0; i < routes.length; i++) {
			const route = ROUTES[routes[i]];
			if (route.id === this.props.match.params.setting) {
				selectedSetting = route.id;
				break;
			}
		}

		this.state = {
			selectedSetting: selectedSetting,
			locationData: null,
			loading: true,
			monthlyInviteGoal: 0,
			monthlyReviewGoal: 0,
			saveError: false,
			saveSuccess: false,
			successText: "",
			errorText: ""
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchLocation();

		NotificationService.subscribe("onUrlChange", ({ location, action }) => {
			let selectedSetting = ROUTES.general.id;
			let routes = Object.keys(ROUTES);

			for (let i = 0; i < routes.length; i++) {
				const route = ROUTES[routes[i]];
				if (route.route === location.pathname) {
					selectedSetting = route.id;
					break;
				}
			}
			this.update({
				selectedSetting
			});
		});
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	handleSaveClick = async event => {
		let { t } = this.props;

		event.preventDefault();
		event.stopPropagation();

		const { monthlyInviteGoal, monthlyReviewGoal } = this.state;

		const locationData = {};
		locationData.locationId = UserService.getActiveLocation().id;
		locationData.company = UserService.getActiveCompany().id;
		locationData.monthly_invite_goal = monthlyInviteGoal;
		locationData.monthly_review_goal = monthlyReviewGoal;

		let success = await LocationService.updateLocation(locationData);

		if (!success) {
			this.setState({ saveError: true, loading: false, errorText: t("Sorry, an error was encountered while saving the settings. Please try again.") });
			return;
		}

		this.setState({ saveSuccess: true, loading: false, successText: t("Location saved successfully.") });
		this.fetchLocation();
	};

	onLocationChanged() {
		this.fetchLocation();
	}

	async fetchLocation() {
		this.setState({ loading: true });

		let data = await LocationService.fetchLocation(UserService.getActiveLocation().id);

		if (!data) {
			this.setState({ loading: false });
			return;
		}

		this.setState({
			locationData: data,
			loading: false,
			monthlyInviteGoal: data.monthly_invite_goal,
			monthlyReviewGoal: data.monthly_review_goal,
			phone: data.phone
		});
	}

	handleMonthlyInviteGoalOnChange(e) {
		this.setState({ monthlyInviteGoal: parseInt(e.target.value, 10) });
	}
	handleMonthlyReviewGoalOnChange(e) {
		this.setState({ monthlyReviewGoal: parseInt(e.target.value, 10) });
	}
	onClick = selectedSetting => {
		let route = ROUTES[selectedSetting].route;
		this.setState({ selectedSetting: ROUTES[selectedSetting].id });
		this.props.history.push(route);
	};
	render() {
		let { selectedSetting } = this.state;
		let { t } = this.props;

		return (
			<Container className="settings" initialPose="hidden" pose="visible">
				<div className="settings__navbar">
					<div className="settings__navbar__header">{t("SETTINGS")}</div>
					<div className="settings__navbar__list">
						{Object.keys(ROUTES).map(
							key =>
								ROUTES[key].enabled() &&
								!ROUTES[key].hideFromMenu && (
									<div
										key={key}
										id={key}
										className={`${selectedSetting === ROUTES[key].id ? "settings__navbar__list__item--active" : ""} settings__navbar__list__item`}
										onClick={() => this.onClick(key)}
									>
										{ROUTES[key].name}
									</div>
								)
						)}
					</div>
				</div>
				<Switch>
					{Object.keys(ROUTES).map(key => ROUTES[key].enabled() && <Route key={key} exact path={ROUTES[key].route} component={ROUTES[key].component} />)}
					<Route key={"not-found"} path={"/*"}>
						<div className="settings-page__not-found">
							<div className="settings-page__not-found--text">{t("Sorry, settings page not found.")}</div>
						</div>
					</Route>
				</Switch>
			</Container>
		);
	}
}

export default withLocation(withTranslation(null, { withRef: true })(Settings));
